import React, { useEffect, useMemo, useState } from "react";
import moment from "moment";
import ReactGA from "react-ga4";

import Report from "./Report";
import { renderCell, sortValueCell } from "../../lib/tableHelpers";
import { useSelector } from "react-redux";
import { displaySeason } from "../../utils/helpers";

const columns = [
  {
    id: "cancelledAtAction",
    label: "תאריך ביצוע",
    preset: "date",
    minWidth: 100,
  },
  { id: "createdAt", label: "רישום", preset: "date", minWidth: 100 },
  { id: "startDate", label: "התחלה", preset: "date", minWidth: 100 },
  { id: "cancelledAt", label: "פרישה", preset: "date", minWidth: 100 },
  { id: "clubPlayer", label: "ספורטאי", preset: "link" },
  { id: "team", label: "קבוצה", preset: "link" },
  { id: "season", label: "עונה", baseRenderer: true },
  { id: "cancellationReason", label: "סיבת פרישה", baseRenderer: true },
  { id: "totalPrice", label: "עלות", preset: "price" },
  { id: "paid", label: "שולם", preset: "price" },
  { id: "debt", label: "חוב", preset: "price" },
  { id: "comments", label: "הערות", baseRenderer: true },
];

const filtersStructure = [
  { type: "date" },
  { preset: "season" },
  { preset: "teams" },
  {
    name: "dateType",
    type: "select",
    options: {
      cancelledAt: "תאריך סיום פעילות",
      cancelledAtAction: "תאריך ביצוע הפעולה",
    },
    label: "סוג תאריך",
  },
  {
    name: "cancellationType",
    type: "select",
    options: { cancellation: "פרישה", changeTeam: "מעבר קבוצה" },
    label: "סוג",
    emptyLabel: "כל הסוגים",
  },
];

export default function Quitters() {
  const [initialFiltersState] = useState({
    date: { from: moment().startOf("day"), to: moment().endOf("day") },
    teams: { all: true },
    cancellationType: "all",
    dateType: "cancelledAtAction",
    season: "all",
  });
  const seasons = useSelector((state) => state.general.seasons);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/reports/quitters",
      title: "Quitters Report",
    });
  }, []);

  const seasonsDic = useMemo(
    () => Object.fromEntries(seasons.map((s) => [s._id, s.year])),
    [seasons]
  );

  const baseRenderer = (columnId, subscription) => {
    switch (columnId) {
      case "clubPlayer":
        return {
          to: `../players/${subscription.clubPlayer._id}`,
          label: subscription.clubPlayer.name,
        };

      case "team":
        return {
          to: `../teams/${subscription.team._id}`,
          label: subscription.team.name,
        };

      case "comments":
        return subscription.subStatus === "teamChange" ? "מעבר קבוצה" : "";

      case "season":
        return seasonsDic[subscription.season];

      default:
        return subscription[columnId];
    }
  };

  const displayRenderer = (columnId, subscription, value) => {
    switch (columnId) {
      case "season":
        return displaySeason(value);

      default:
        return subscription[columnId];
    }
  };

  return (
    <Report
      title="דו״ח פורשים ומעברי קבוצה"
      url="/store/subscriptions/quitters?"
      initialFiltersState={initialFiltersState}
      filtersStructure={filtersStructure}
      renderCell={renderCell(columns, false, baseRenderer, displayRenderer)}
      sortValueCell={sortValueCell(columns, baseRenderer)}
      columns={columns}
      exportFileName="פורשים ומעברי קבוצה"
      renderCellExport={renderCell(
        columns,
        true,
        baseRenderer,
        displayRenderer
      )}
      defaultSortBy={0}
      defaultSortDirection={"desc"}
    />
  );
}
