import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";

import axios from "../../../../utils/axios";
import ButtonSx from "../../../../components/base/ButtonSx";

function DeleteSubscriptionProductDialog(props) {
  const [error, setError] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setError(null);
    setLoading(false);
  }, [props.subscriptionProduct]);

  const handleDelete = async () => {
    try {
      setLoading(true);
      await axios.delete(
        `/store/subscriptionProducts/${props.subscriptionProduct._id}`
      );
      props.onComplete();
    } catch (error) {
      if (
        error.response &&
        error.response.status === 422 &&
        error.response.data.activePlayers
      ) {
        setError({
          type: "activePlayers",
          players: error.response.data.activePlayers,
        });
      } else if (
        error.response &&
        error.response.status === 422 &&
        error.response.data.activeTeams
      ) {
        setError({
          type: "activeTeams",
          teams: error.response.data.activeTeams,
        });
      } else {
        setError({ type: "unknown" });
      }
    } finally {
      setLoading(false);
    }
  };

  const renderContent = () => {
    if ((error || {}).type === "activePlayers") {
      return `לא ניתן להשלים את הפעולה מאחר ולמסגרת זו יש ${error.players} ספורטאים פעילים`;
    } else if ((error || {}).type === "activeTeams") {
      return (
        <div>
          על מנת להשלים את המחיקה יש להעביר את כל הקבוצות השייכות למסגרת זו
          לסטטוס ארכיון.
          <div>נמצאו {error.teams} קבוצות בססטוס פעיל או פעילות הסתיימה</div>
        </div>
      );
    } else if (error) {
      return `לא ניתן לבצע פעולה זו כרגע `;
    } else {
      return ` האם ברצונך למחוק את ״${
        (props.subscriptionProduct || {}).title
      }״?`;
    }
  };

  const handleClose = () => {
    props.onClose();
  };

  const renderActions = () => {
    if (error) {
      return <ButtonSx onClick={props.onClose}>חזרה</ButtonSx>;
    } else {
      return (
        <>
          <ButtonSx onClick={props.onClose} disabled={loading}>
            ביטול
          </ButtonSx>
          <ButtonSx onClick={handleDelete} disabled={loading}>
            מחיקה
          </ButtonSx>
        </>
      );
    }
  };

  return (
    <Dialog
      dir="rtl"
      fullWidth
      maxWidth="sm"
      onClose={handleClose}
      open={props.open}
    >
      <DialogTitle id="form-dialog-title">מחיקת מסגרת</DialogTitle>
      <DialogContent dividers>{renderContent()}</DialogContent>
      <DialogActions>{renderActions()}</DialogActions>
    </Dialog>
  );
}

export default DeleteSubscriptionProductDialog;
