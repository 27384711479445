import React from "react";
import { TableRow, TableCell, IconButton, Collapse } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const styles = {
  root: {
    borderTop: "1px solid #EFEFEF",
    "& > *": {
      borderBottom: "unset",
    },
  },
  openTopRow: {
    borderTop: "2px solid #CCC",
  },
};

export default function CollapsableEnhancedRow(props) {
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <TableRow
        onClick={() => setOpen(!open)}
        tabIndex={-1}
        sx={{ ...styles.root, ...(open ? styles.openTopRow : {}) }}
      >
        <TableCell align="center" sx={{ width: 10, m: 0, p: 0 }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {props.headers.map((header, index) => {
          return (
            <TableCell key={index} align={header.alignment || "left"}>
              {props.renderCell(header.id, props.row)}
            </TableCell>
          );
        })}
      </TableRow>
      <TableRow>
        <TableCell
          colSpan={props.headers.length + 1}
          sx={{
            p: 0,
            borderBottom: "unset",
            ...(open ? { borderBottom: "2px solid #CCC !important" } : {}),
          }}
        >
          <Collapse in={open} timeout="auto" mountOnEnter>
            <props.CollapsableComponent
              row={props.row}
              {...props.collapsableComponentProps}
            />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
