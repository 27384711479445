import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Dialog,
  IconButton,
  InputAdornment,
  Popover,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import EventIcon from "@mui/icons-material/Event";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { PickersDay } from "@mui/x-date-pickers/PickersDay/PickersDay";
import { styled } from "@mui/material/styles";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import moment from "moment";

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) =>
    [
      "isSelected",
      "isHoveredEnd",
      "isInRange",
      "isStartDay",
      "isEndDay",
      "today",
      "isHovered",
    ].indexOf(prop) === -1,
})(({ theme, isSelected, today, disabled }) => ({
  ...(today && { fontWeight: "bold" }),
  backgroundColor: "transparent !important",
  color: disabled ? "#9e9e9e !important" : "#000 !important",
  ...(isSelected &&
    !disabled && {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      "&:hover, &:focus": {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
      },
    }),
}));

const PickerDayWrapper = styled("div", {
  shouldForwardProp: (prop) =>
    prop !== "isStartDay" &&
    prop !== "isEndDay" &&
    prop !== "isHovered" &&
    prop !== "isInRange" &&
    prop !== "outsideCurrentMonth",
})(({ isStartDay, isEndDay, isInRange, day, outsideCurrentMonth }) => {
  return {
    ...(isStartDay && {
      borderRadius: "50%",
      borderBottomRightRadius: 0,
      borderTopRightRadius: 0,
    }),
    ...(isEndDay && {
      borderRadius: "50%",
      borderBottomLeftRadius: 0,
      borderTopLeftRadius: 0,
    }),

    ...(isInRange &&
      !outsideCurrentMonth && {
        backgroundColor: "#cfe0f6",
      }),

    ...((day.day() === 0 || day.date() === 1 || isStartDay) && {
      borderTopLeftRadius: "50%",
      borderBottomLeftRadius: "50%",
    }),
    ...((day.day() === 6 ||
      day.date() === moment(day).daysInMonth() ||
      isEndDay) && {
      borderTopRightRadius: "50%",
      borderBottomRightRadius: "50%",
    }),
  };
});

function RangeDay(props) {
  const {
    day,
    outsideCurrentMonth,
    startDate,
    endDate,
    hoverDate,
    today,
    setHoverDate,
    ...other
  } = props;

  const isStartDay = day.isSame(startDate);
  const isEndDay = endDate ? day.isSame(endDate) : day.isSame(hoverDate);
  const isInRange =
    startDate &&
    (endDate || hoverDate) &&
    day.isBetween(startDate, endDate || hoverDate, undefined, "[]");
  const isHovered = isInRange && hoverDate;

  return (
    <div
      onPointerEnter={() => !outsideCurrentMonth && setHoverDate(day)}
      onPointerLeave={() => !outsideCurrentMonth && setHoverDate(null)}
    >
      <PickerDayWrapper
        isStartDay={isStartDay}
        isEndDay={isEndDay}
        isInRange={isInRange}
        isHovered={isHovered}
        day={day}
        outsideCurrentMonth={outsideCurrentMonth}
      >
        <CustomPickersDay
          isSelected={isStartDay || isEndDay}
          disableMargin
          outsideCurrentMonth={outsideCurrentMonth}
          day={day}
          isInRange={isInRange}
          isStartDay={isStartDay}
          isEndDay={isEndDay}
          today={today}
          isHovered={isHovered}
          {...other}
        />
      </PickerDayWrapper>
    </div>
  );
}

const CustomCalendarHeaderRoot = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  padding: "8px 16px",
  alignItems: "center",
});

function CustomCalendarHeader(props) {
  const { currentMonth, onMonthChange, side } = props;

  const selectNextMonth = () => onMonthChange(1);
  const selectPreviousMonth = () => onMonthChange(-1);
  const showPrevMonthButton =
    side === "right" && moment().startOf("month").isBefore(currentMonth);

  return (
    <CustomCalendarHeaderRoot>
      <Stack spacing={1} direction="row" sx={{ width: 50, height: 40 }}>
        <IconButton
          onClick={selectPreviousMonth}
          title="Previous month"
          sx={showPrevMonthButton ? {} : { display: "none" }}
        >
          <ChevronRight />
        </IconButton>
      </Stack>
      <Typography variant="body2">
        {currentMonth.format("MMMM YYYY")}
      </Typography>
      <Stack spacing={1} direction="row" sx={{ width: 50 }}>
        <IconButton
          onClick={selectNextMonth}
          title="Next month"
          sx={side === "right" ? { display: "none" } : {}}
        >
          <ChevronLeft />
        </IconButton>
      </Stack>
    </CustomCalendarHeaderRoot>
  );
}

export default function DateRangePicker(props) {
  const is700Up = useMediaQuery((t) => t.breakpoints.up("700"), {
    noSsr: true,
  });
  const [popupOpen, setPopupOpen] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [hoverDate, setHoverDate] = useState("");
  const [currentMonth, setCurrentMonth] = useState({
    right: moment().startOf("month"),
    left: moment().startOf("month").add(1, "month"),
  });
  const anchorRef = useRef(null);

  const handleDateChange = (date) => {
    if (!startDate || date.isBefore(startDate) || endDate) {
      setStartDate(date);
      setEndDate("");
    } else {
      setEndDate(date);
      if (props.autoClose) {
        props.onDatesChanged(startDate, date);
        setPopupOpen(false);
      }
    }
  };

  useEffect(() => {
    setStartDate(props.startDate || "");
    setEndDate(props.endDate || "");
  }, [props.startDate, props.endDate]);

  const renderPopupContent = () => {
    return (
      <Stack direction="row">
        <DateCalendar
          onChange={handleDateChange}
          slots={{ day: RangeDay, calendarHeader: CustomCalendarHeader }}
          disableHighlightToday
          value={currentMonth.right}
          views={["day"]}
          disablePast
          maxDate={maxDate}
          slotProps={{
            day: () => ({
              startDate,
              endDate,
              hoverDate,
              setHoverDate,
            }),
            calendarHeader: {
              side: "right",
              onMonthChange: (a) => {
                setCurrentMonth((prev) => ({
                  right: moment(prev.right).add(a, "month"),
                  left: moment(prev.left).add(a, "month"),
                }));
              },
            },
          }}
        />

        <DateCalendar
          onChange={handleDateChange}
          slots={{ day: RangeDay, calendarHeader: CustomCalendarHeader }}
          value={currentMonth.left}
          disableHighlightToday
          views={["day"]}
          disablePast
          maxDate={maxDate}
          slotProps={{
            day: () => ({
              startDate,
              endDate,
              hoverDate,
              setHoverDate,
            }),
            calendarHeader: {
              side: "left",
              onMonthChange: (a) => {
                setCurrentMonth((prev) => ({
                  right: moment(prev.right).add(a, "month"),
                  left: moment(prev.left).add(a, "month"),
                }));
              },
            },
          }}
        />
      </Stack>
    );
  };

  const handleClose = () => {
    setPopupOpen(false);
  };

  const value = useMemo(() => {
    if (props.mode === "startEnd") {
      return `${
        props.startDate ? props.startDate.format("DD/MM/YY") : "תאריך התחלה"
      } - ${props.endDate ? props.endDate.format("DD/MM/YY") : "תאריך סיום"}`;
    }
    if (props.mode === "start") {
      return props.startDate ? props.startDate.format("DD/MM/YY") : "";
    }

    return props.endDate ? props.endDate.format("DD/MM/YY") : "";
  }, [props.startDate, props.endDate]);

  const maxDate = useMemo(
    () => moment().startOf("day").add(1, "year").add(-1, "day"),
    []
  );

  const handleOpenPopup = () => setPopupOpen(true);

  return (
    <>
      <TextField
        variant="standard"
        readOnly
        margin="dense"
        fullWidth
        value={value}
        {...props.textFieldProps}
        onClick={!props.disable && !props.readOnly ? handleOpenPopup : null}
        ref={anchorRef}
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                disabled={props.readOnly}
                onClick={
                  !props.disable && !props.readOnly ? handleOpenPopup : null
                }
              >
                <EventIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      {is700Up ? (
        <Popover
          dir="rtl"
          open={popupOpen}
          anchorEl={anchorRef.current}
          onClose={handleClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          transformOrigin={{ vertical: "top", horizontal: "center" }}
        >
          {renderPopupContent()}
        </Popover>
      ) : (
        <Dialog
          onClose={handleClose}
          open={popupOpen}
          title="הפסקת פעילות"
          fullScreen
        >
          {renderPopupContent()}
        </Dialog>
      )}
    </>
  );
}
