import React, { useEffect, useState } from "react";
import { Grid, TableContainer } from "@mui/material";

import axios from "../../../utils/axios";
import { displayDate, displayDateTime } from "../../../utils/helpers";
import Table from "../../../components/table/Table";
import {
  exportTable,
  renderCell,
  sortValueCell,
} from "../../../lib/tableHelpers";
import Dialog from "../../../components/Dialog";

export default function DepositDetailsDialog(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [transactions, setTransactions] = useState([]);

  useEffect(() => {
    if (props.open) {
      loadData();
    } else {
      setIsLoading(false);
    }
  }, [props.open]);

  const loadData = async () => {
    setIsLoading(true);

    const res = await axios.get(`/payments/deposits/${props.deposit._id}`);
    setTransactions([
      ...(res.data.cashTransactions || []),
      ...(res.data.checkTransactions || []),
    ]);
    setIsLoading(false);
  };

  const columns = [
    {
      id: "createdAt",
      label: "תאריך",
      minWidth: 180,
      baseRenderer: true,
    },
    { id: "details", label: "פרטים", baseRenderer: true, minWidth: 160 },
    { id: "amount", label: "סכום", preset: "price", baseRenderer: true },
    { id: "transactionId", label: "קבלה", preset: "link" },
    { id: "customer", label: "לקוח", baseRenderer: true },
  ];

  const baseRenderer = (columnId, record) => {
    const check = record.checks;
    switch (columnId) {
      case "createdAt":
        return record.kind === "CheckTransaction"
          ? displayDate(record.checks.date)
          : displayDateTime(record.createdAt);
      case "transactionId":
        return {
          to: `../payments/transactions/${record._id}`,
          label: record.receiptNumber,
        };

      case "customer":
        return record.payer?.name;

      case "details":
        return record.kind === "CheckTransaction"
          ? `צ׳ק מספר ${check.checkNumber}, ${check.bank}-${check.branch}-${check.accountNumber}`
          : "מזומן";

      case "amount":
        return record.kind === "CheckTransaction"
          ? check.amount
          : record.amount;

      default:
        return record[columnId];
    }
  };

  const sortRenderer = (columnId, record) => {
    switch (columnId) {
      case "createdAt":
        return record.kind === "CheckTransaction"
          ? record.checks.date
          : record.createdAt;

      default:
        return record[columnId];
    }
  };

  const handleExportTable = () => {
    return exportTable(
      transactions,
      columns,
      renderCell(columns, true, baseRenderer),
      "csv",
      "פרטי הפקדה"
    );
  };

  return (
    <Dialog
      onClose={props.onClose}
      open={props.open}
      fullScreen
      sx={styles.dialog}
      title="פרטי הפקדה"
      closeButton
    >
      <Grid container spacing={2} sx={{ p: 1 }}>
        <Grid item xs={12}>
          <TableContainer>
            <Table
              columns={columns}
              renderCell={renderCell(columns, false, baseRenderer)}
              sortValueCell={sortValueCell(columns, baseRenderer, sortRenderer)}
              rows={transactions}
              title={"פריטים"}
              isLoading={isLoading}
              stickyHeader
              onExport={handleExportTable}
            />
          </TableContainer>
        </Grid>
      </Grid>
    </Dialog>
  );
}

const styles = {
  dialog: {
    width: { xs: undefined, md: 900 },
    zIndex: 1110,
  },
};
