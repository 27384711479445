import React from "react";
import {
  Grid,
  TextField,
  FormControl,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormHelperText,
  Checkbox,
} from "@mui/material";
import moment from "moment";
import _ from "lodash";

import { classes as playerClasses } from "../../../utils/dictionaries";
import MenuItem from "../../../components/base/MenuItem";
import TextFieldValidator from "../../../components/base/TextFieldValidator";
import PassportNumberTextField from "./PassportNumberTextField";

export default function NewPlayerForm(props) {
  const currentYear = moment().year();
  const isNewPlayer = props.state.clubPlayerId === "";

  const handleChange = (field, value) => (event) => {
    props.onChange({ [field]: value || event.target.value });
  };

  const defaultParams = (field) => {
    const error =
      Object.prototype.hasOwnProperty.call(props.state.errors, field) &&
      (props.state[`${field}InstaValidate`] || props.state.instaValidate);

    return {
      inputProps: {
        readOnly: !isNewPlayer,
      },
      id: field,
      name: field,
      fullWidth: true,
      value: props.state[field],
      onChange: handleChange(field),
      error,
      helperText: error ? props.state.errors[field] : "",
    };
  };

  const defaultParamsValidator = (field, textFieldParams) => {
    return {
      textFieldProps: {
        ...defaultParams(field),
        ...textFieldParams,
      },
      validating: props.state[`is${_.capitalize(field)}Unique`] === "loading",
      valid: !Object.prototype.hasOwnProperty.call(props.state.errors, field),
      validate: true,
      softValidate:
        !props.state[`${field}InstaValidate`] && !props.state.instaValidate,
      onBlur: isNewPlayer
        ? handleChange(`${field}InstaValidate`, true)
        : undefined,
    };
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          {props.state.notIsraeli ? (
            <PassportNumberTextField
              value={props.state.passportNumber}
              onPassportNumberChanged={props.onPassportNumberChanged}
              error={props.state.errors.passportNumber}
              userType="clubPlayer"
            />
          ) : (
            <TextFieldValidator
              {...defaultParamsValidator("id", {
                label: "תעודת זהות",
                required: true,
              })}
            />
          )}
        </Grid>
        <Grid item xs={6}>
          <TextField
            variant="standard"
            {...defaultParams("phone")}
            label="טלפון"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            variant="standard"
            {...defaultParams("firstName")}
            label="שם פרטי"
            required
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            variant="standard"
            {...defaultParams("lastName")}
            label="שם משפחה"
            required
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            variant="standard"
            {...defaultParams("gender")}
            required
            label="מין"
            select
          >
            <MenuItem value="male">זכר</MenuItem>
            <MenuItem value="female">נקבה</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <TextField
                label="תאריך לידה"
                variant="standard"
                required
                {...defaultParams("dobYear")}
                InputLabelProps={{ shrink: true }}
                select
                value={props.state.dobYear}
                onChange={(e) => props.onChange({ dobYear: e.target.value })}
                fullWidth
                error={Object.prototype.hasOwnProperty.call(
                  props.state.errors,
                  "dob"
                )}
                helperText={props.state.errors.dob}
              >
                {[...Array(100).keys()].map((year) => (
                  <MenuItem key={year} value={currentYear - year}>
                    {currentYear - year}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={4}>
              <TextField
                margin="normal"
                variant="standard"
                required
                {...defaultParams("dobMonth")}
                select
                value={props.state.dobMonth}
                onChange={(e) => props.onChange({ dobMonth: e.target.value })}
                fullWidth
                error={Object.prototype.hasOwnProperty.call(
                  props.state.errors,
                  "dob"
                )}
              >
                {[...Array(12).keys()].map((month) => (
                  <MenuItem key={month} value={month + 1}>
                    {month + 1}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={4}>
              <TextField
                variant="standard"
                required
                margin="normal"
                {...defaultParams("dobDay")}
                select
                value={props.state.dobDay}
                onChange={(e) => props.onChange({ dobDay: e.target.value })}
                fullWidth
                error={Object.prototype.hasOwnProperty.call(
                  props.state.errors,
                  "dob"
                )}
              >
                {[...Array(31).keys()].map((day) => (
                  <MenuItem key={day} value={day + 1}>
                    {day + 1}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <TextField
            variant="standard"
            {...defaultParams("school")}
            label="בית ספר"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            variant="standard"
            {...defaultParams("class")}
            required
            label="כיתה"
            select
          >
            {Object.entries(playerClasses).map(([id, title]) => (
              <MenuItem key={id} value={id}>
                {title}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        {props.remoteClubConfig.registrationWithPassport && (
          <Grid item xs={12} sm={6}>
            <FormControlLabel
              label="רישום באמצעות מספר דרכון"
              control={
                <Checkbox
                  checked={props.state.notIsraeli}
                  onChange={(e) => {
                    const params = { notIsraeli: e.target.checked };
                    if (e.target.checked) {
                      params.id = "";
                      params.isIdUnique = null;
                    } else {
                      params.passportNumber = "";
                      params.isPassportNumberUnique = null;
                    }
                    props.onChange(params);
                  }}
                />
              }
            />
          </Grid>
        )}

        <Grid item xs={12} style={{ marginTop: 30 }}>
          <FormControl
            variant="standard"
            component="fieldset"
            error={Object.prototype.hasOwnProperty.call(
              props.state.errors,
              "hasMedicalIssues"
            )}
            required
          >
            <FormLabel color="primary">
              האם לספורטאי יש בעיות רפואיות ו/או רגישויות ו/או מגבלות גופניות
              אחרות?
            </FormLabel>
            <RadioGroup
              name="medical"
              value={
                props.state.hasMedicalIssues === null
                  ? ""
                  : props.state.hasMedicalIssues.toString()
              }
              onChange={(e) => {
                if (isNewPlayer) {
                  const updates = {
                    hasMedicalIssues: e.target.value === "true",
                  };
                  if (!updates.hasMedicalIssues) {
                    updates.medicalIssues = "";
                  }
                  props.onChange(updates);
                }
              }}
              row
            >
              <FormControlLabel
                value="true"
                control={<Radio color="primary" />}
                label="כן"
              />
              <FormControlLabel
                value="false"
                control={<Radio color="primary" />}
                label="לא"
              />
            </RadioGroup>
            <FormHelperText>
              {props.state.errors.hasMedicalIssues}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="standard"
            {...defaultParams("medicalIssues")}
            label="אם כן, אנא פרט אותן:"
            multiline
            maxRows={3}
            disabled={props.state.hasMedicalIssues !== true}
            style={{ marginBottom: 20 }}
          />
        </Grid>
        <Grid item xs={12}>
          * שדה חובה
        </Grid>
      </Grid>
    </>
  );
}
