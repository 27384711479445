import React from "react";
import { Grid } from "@mui/material";
import { connect } from "react-redux";

import axios from "../../utils/axios";
import TransactionDetails from "./TransactionDetails";
import PurchaseProducts from "./PurchaseProducts";
import AccountDetails from "../Accounts/AccountDetails";
import VoidDialog from "../../components/store/VoidDialog";
import RefundDialog from "../../components/store/Refund/RefundDialog";
import InfoRow from "../../components/InfoRows/InfoRow";
import TransactionRefunds from "./TransactionRefunds";
import withRouter from "../../components/routing/withRouter";
import TlPaper from "../../components/TlPaper";

class Transaction extends React.Component {
  state = {
    isLoading: true,
    transaction: {},
    isVoidDialogVisible: false,
    isRefundDialogVisible: false,
    processing: false,
    sendReceiptSuccess: null,
  };

  componentDidMount = async () => {
    this.loadData();
  };

  componentDidUpdate = async (prevProps) => {
    if (prevProps.params.transactionId !== this.props.params.transactionId) {
      this.loadData();
    }
  };

  loadData = async () => {
    this.setState({ isLoading: true });
    let res = await axios.get(
      `/payments/transactions/${this.props.params.transactionId}?granularity=payment&granularity=purchase&granularity=children&&granularity=parent&granularity=deposit`
    );
    const transaction = res.data;
    const refunds = transaction.children.filter(
      (t) => ["declined", "error"].indexOf(t.status) === -1
    );

    res = await axios.get(`/store/purchases/${transaction.purchaseId}`);
    const purchase = res.data;

    let clubParent;
    if (this.props.user.permissions.hr && transaction.clubAccountId) {
      res = await axios.get(
        `/clubParents?clubAccountId=${transaction.clubAccountId}`
      );
      clubParent = res.data[0];
    }

    this.setState({
      isLoading: false,
      transaction,
      refunds,
      clubParent,
      purchase,
    });
  };

  handleResendReceipt = async (email) => {
    try {
      const res = await axios.post(
        `/payments/transactions/${this.props.params.transactionId}/emailReceipt`,
        { email }
      );
      return res.data.success;
    } catch (error) {
      return false;
    }
  };

  render() {
    const hasRegistrationAdminPermission =
      this.props.user.permissions.registrationAdmin;

    const hasHrPermission = this.props.user.permissions.hr;

    return (
      <>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6}>
            <TransactionDetails
              isLoading={this.state.isLoading}
              transaction={this.state.transaction}
              onVoid={() => this.setState({ isVoidDialogVisible: true })}
              onRefund={() => this.setState({ isRefundDialogVisible: true })}
              onSendReceipt={() =>
                this.setState({ chooseEmailDialogOpen: true })
              }
              hasRegistrationAdminPermission={hasRegistrationAdminPermission}
              onResendReceipt={this.handleResendReceipt}
            />
          </Grid>
          {this.state.transaction._id && (
            <>
              <Grid item xs={12} lg={6}>
                {this.state.clubParent ? (
                  <AccountDetails
                    clubParent={this.state.clubParent}
                    hasHrPermission={hasHrPermission}
                  />
                ) : (
                  <TlPaper
                    title="פרטי המשלם"
                    sx={{ height: "100%" }}
                    titleBackground
                  >
                    <InfoRow
                      title="שם הלקוח"
                      value={this.state.transaction.payer.name}
                    />
                    <InfoRow
                      title="טלפון"
                      value={this.state.transaction.payer.phone}
                    />
                    <InfoRow
                      title="אימייל"
                      value={this.state.transaction.payer.email}
                    />
                    <InfoRow
                      title="כתובת"
                      value={this.state.transaction.payer.address}
                    />
                  </TlPaper>
                )}
              </Grid>
              {this.state.transaction.amount > 0 && (
                <Grid item xs={12}>
                  <TlPaper noMinHeight title="החזרים" titleBackground>
                    <TransactionRefunds
                      refunds={this.state.refunds}
                      key={`${this.state.transaction._id}${this.state.transaction.status}`}
                    />
                    {/* ^^^ status is required so when the transaction is voided the component will be re-rendered */}
                  </TlPaper>
                </Grid>
              )}
              {this.state.transaction.purchaseId && (
                <Grid item xs={12}>
                  <TlPaper title="פריטים" titleBackground>
                    <PurchaseProducts
                      transaction={this.state.transaction}
                      purchase={this.state.purchase}
                      key={`${this.state.transaction._id}${this.state.transaction.status}`}
                      onPurchaseUpdated={this.loadData}
                      hasHrPermission={hasHrPermission}
                      hasRegistrationAdminPermission={
                        hasRegistrationAdminPermission
                      }
                    />
                    {/* ^^^ status is required so when the transaction is voided the component will be re-rendered */}
                  </TlPaper>
                </Grid>
              )}
            </>
          )}
          {this.state.transaction.status === "approved" && (
            <VoidDialog
              open={this.state.isVoidDialogVisible}
              onClose={() => this.setState({ isVoidDialogVisible: false })}
              onComplete={() => {
                this.loadData();
                this.setState({ isVoidDialogVisible: false });
              }}
              transaction={this.state.transaction}
            />
          )}
          {this.state.transaction.status === "settled" && (
            <RefundDialog
              open={this.state.isRefundDialogVisible}
              onClose={() => this.setState({ isRefundDialogVisible: false })}
              onComplete={() => {
                this.loadData();
                this.setState({ isRefundDialogVisible: false });
              }}
              transaction={this.state.transaction}
              allowMerchandisePartialRefund={
                this.props.selectedClub.clubConfig.partialMerchandiseRefunds
              }
            />
          )}
        </Grid>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.auth.user,
    selectedClub: state.auth.selectedClub,
  };
}

export default connect(mapStateToProps)(withRouter(Transaction));
