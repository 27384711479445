import queryString from "query-string";
import moment from "moment";
import _ from "lodash";

const defaultOptions = {
  manualFields: [],
};

export const prepareFilters = (
  filterValues,
  filtersStructure,
  options = {}
) => {
  const allOptions = { ...defaultOptions, ...options };
  const manual = [...allOptions.manualFields, "amount", "date", "singleDate"];
  const filterStructureMap = new Map();
  filtersStructure.forEach((fs) => {
    filterStructureMap.set(fs.name, fs);
  });

  const rawFilters = Object.entries(filterValues).filter(
    ([filterName, value]) => {
      const filterStructure = filterStructureMap.get(filterName) || {};
      if (manual.indexOf(filterName) === 1) {
        return false;
      }

      const includeAll =
        options.includeAll !== false && filterStructure.includeAll;

      return filterStructure.type === "multiSelect"
        ? includeAll ||
            (!value.all &&
              Object.keys(value).length !==
                Object.keys(filterStructure.options).length)
        : (includeAll || value !== "all") &&
            (filterStructure.includeEmpty || value !== "");
    }
  );

  const filters = [];
  rawFilters.forEach(([filterName, value]) => {
    let filterStructure = filterStructureMap.get(filterName) || {};
    if (filterStructure.type === "multiSelect") {
      Object.keys(value).forEach((v) => filters.push(`${filterName}=${v}`));
    } else if (Array.isArray(value)) {
      value.forEach((v) => filters.push(`${filterName}=${v}`));
    } else {
      filters.push(`${filterName}=${value}`);
    }
  });

  if (filterValues.amount) {
    if (filterValues.amount.from !== "") {
      filters.push(`amountFrom=${filterValues.amount.from}`);
    }
    if (filterValues.amount.to !== "") {
      filters.push(`amountTo=${filterValues.amount.to}`);
    }
  }

  if (filterValues.date) {
    filters.push(
      `dateFrom=${filterValues.date.from.startOf("day").toISOString()}`
    );
    filters.push(`dateTo=${filterValues.date.to.endOf("day").toISOString()}`);
  }

  if (filterValues.singleDate) {
    filters.push(`dateFrom=${filterValues.singleDate.format("YYYY-MM-DD")}`);
  }

  return filters;
};

export const getFiltersFromUrl = (location, filters, filtersStructure) => {
  const qs = queryString.parse(location.search);
  const newFilters = { ...filters };
  filtersStructure.forEach((filterStructure) => {
    if (qs[filterStructure.name]) {
      if (filterStructure.type === "multiSelect") {
        const values = _.flatten([qs[filterStructure.name]]);
        newFilters[filterStructure.name] = Object.fromEntries(
          values.map((val) => [val, true])
        );
      } else {
        newFilters[filterStructure.name] = qs[filterStructure.name];
      }
    }
  });

  if (qs.dateFrom && qs.dateTo) {
    newFilters.date = {};
    newFilters.date.from = moment(qs.dateFrom).startOf("day");
    newFilters.date.to = moment(qs.dateTo).startOf("day");
  }

  if (qs.amountFrom || qs.amountTo) {
    newFilters.amount = {};
    newFilters.amount.from = qs.amountFrom || "";
    newFilters.amount.to = qs.amountTo || "";
  }

  return newFilters;
};
