import React, { useState } from "react";
import { List, ListItem, Stack, Typography } from "@mui/material";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import _ from "lodash";
import AddIcon from "@mui/icons-material/Add";

import AbsoluteCenter from "../../../../components/AbsoluteCenter";
import { DnDreorder } from "../../../../utils/helpers";
import Paper2Sx from "../../../../components/Paper2Sx";
import SubscriptionProductFeeItem from "./SubscriptionProductFeeItem";
import ButtonSx from "../../../../components/base/ButtonSx";

export default function SubscriptionProductFeesForm({
  requireAccountingExportNumber,
  subscriptionProduct,
  onFeesChanged,
  errors,
  maxFees,
  editable,
  completed,
}) {
  const [selectedCell, setSelectedCell] = useState({
    index: null,
    cell: null,
  });

  const { fees } = subscriptionProduct;
  const feesErrors = errors.fees || [];

  const handleCellSelected = (index) => (cell) => {
    setSelectedCell({ index, cell });
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const items = DnDreorder(fees, source.index, destination.index);
      onFeesChanged(items);
    }
  };

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "lightblue" : "transparent",
    padding: 8,
    width: "100%",
    height: "100%",
    overflow: "auto",
    borderRadius: 5,
  });

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    ...draggableStyle,
    borderWidth: 0,
  });

  const handleAddNewItem = () => {
    onFeesChanged([
      {
        price: "",
        priceStr: "",
        description: "",
        aen: "",
        deliverable: false,
        _id: _.uniqueId("local"),
      },
      ...fees,
    ]);
  };

  const handleDeleteFee = (index) => () => {
    onFeesChanged(fees.filter((f, i) => i !== index));
  };

  const handleFeeChange = (field, index) => (e) => {
    const newFees = [...fees];
    newFees[index] = { ...newFees[index], [field]: e.target.value };
    onFeesChanged(newFees);
  };

  const handleFeeDeliverableChanged = (index) => (e) => {
    const newFees = [...fees];
    newFees[index] = { ...newFees[index], deliverable: e.target.checked };
    onFeesChanged(newFees);
  };

  const handlePriceChange = (index) => (priceStr, price) => {
    const newFees = [...fees];
    newFees[index] = { ...newFees[index], priceStr, price };
    onFeesChanged(newFees);
  };

  const title = (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="flex-end"
      sx={{ position: "relative" }}
    >
      דמי רישום
      <ButtonSx
        onClick={handleAddNewItem}
        disabled={maxFees === fees.length || !editable}
        variant="none"
        startIcon={<AddIcon />}
        color="primary"
        sx={{ position: "absolute", right: 0, top: 0, height: 30 }}
        buttonProps={{ size: "small" }}
      >
        הוספת פריט
      </ButtonSx>
    </Stack>
  );

  return (
    <Paper2Sx
      externalTitle={title}
      sx={styles.paper}
      paperSx={styles.paperContent}
    >
      {fees.length === 0 && (
        <AbsoluteCenter>
          <Typography sx={styles.noFeesText}>ללא דמי רישום</Typography>
        </AbsoluteCenter>
      )}

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="options2">
          {(provided, snapshot) => (
            <List
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {fees.length > 0 && (
                <ListItem disableGutters disablePadding>
                  <SubscriptionProductFeeItem
                    header
                    requireAccountingExportNumber={
                      requireAccountingExportNumber
                    }
                  />
                </ListItem>
              )}
              {fees.map((fee, index) => (
                <Draggable
                  key={fee._id}
                  draggableId={fee._id}
                  index={index}
                  isDragDisabled={!editable || maxFees === 1}
                >
                  {(provided, snapshot) => (
                    <ListItem
                      disableGutters
                      disablePadding
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      <SubscriptionProductFeeItem
                        fee={fee}
                        index={index}
                        onDelete={handleDeleteFee(index)}
                        onChange={handleFeeChange}
                        onDeliverabilityChange={handleFeeDeliverableChanged}
                        onPriceChange={handlePriceChange}
                        requireAccountingExportNumber={
                          requireAccountingExportNumber
                        }
                        errors={feesErrors[index]}
                        editable={editable}
                        completed={completed}
                        onCellSelected={handleCellSelected(index)}
                        selectedCell={
                          index === selectedCell.index
                            ? selectedCell.cell
                            : null
                        }
                      />
                    </ListItem>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </List>
          )}
        </Droppable>
      </DragDropContext>
    </Paper2Sx>
  );
}

const styles = {
  paper: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  paperContent: {
    height: "100%",
    position: "relative",
    minHeight: 110,
  },
  noFeesText: {
    fontSize: 20,
  },
};
