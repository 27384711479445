import React, { useEffect, useState } from "react";
import { Box, Tab, Tabs } from "@mui/material";
import queryString from "query-string";
import { useLocation, useNavigate } from "react-router-dom";

import TlPaper from "../../components/TlPaper";
import Attendance from "./Attendance";
import Alerts from "./Alerts";
import PlayerPurchasedMerchandise from "./PlayerPurchasedMerchandise";

const tabs = ["merchandise", "attendance", "alerts"];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box pt={2}>{children}</Box>
    </div>
  );
}

export default function PlayerTabs({ player, user, actions }) {
  const [value, setValue] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();

  const handleChange = (_, newValue) => {
    setValue(newValue);
    navigate(`?tab=${tabs[newValue]}`, { replace: true });
  };

  useEffect(() => {
    const qs = queryString.parse(location.search);
    if (qs.tab) {
      const index = tabs.indexOf(qs.tab);
      setValue(index > -1 ? index : 0);
    }
  }, [location.search]);

  return (
    <TlPaper
      titleBackground
      titleComponent={
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          sx={{ flex: 1 }}
        >
          <Tab sx={{ fontSize: 16 }} label="רכישות מוצרים בחנות" />
          <Tab sx={{ fontSize: 16 }} label="נוכחות" />
          <Tab sx={{ fontSize: 16 }} label="התראות" />
        </Tabs>
      }
    >
      <TabPanel value={value} index={0}>
        <PlayerPurchasedMerchandise
          player={player}
          viewSingleTransaction={user.permissions.viewSingleTransaction}
          visible={value === 0}
        />
      </TabPanel>

      <TabPanel value={value} index={1}>
        <Attendance playerId={player._id} visible={value === 1} />
      </TabPanel>

      <TabPanel value={value} index={2}>
        <Alerts
          player={player}
          onUpdate={actions.handleAlertsChanged}
          hasHrPlayerParentAdminPermission={
            user.permissions.hrPlayerParentAdmin
          }
          visible={value === 2}
        />
      </TabPanel>
    </TlPaper>
  );
}
