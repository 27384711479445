import React, { useMemo, useState } from "react";
import NextIcon from "@mui/icons-material/NavigateNext";
import PrevIcon from "@mui/icons-material/NavigateBefore";
import FilterIcon from "@mui/icons-material/FilterList";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Grid,
  Typography,
  IconButton,
  Badge,
  ClickAwayListener,
  Grow,
  Box,
  Stack,
} from "@mui/material";

import ToolBarFilterDialog from "./ToolBarFilterDialog";
import WeekStatus from "../../containers/Calendar/WeekStatus";
import UnseenUpdates from "../../containers/Calendar/Notifications/UnseenUpdates";
import UnsentNotifications from "../../containers/Calendar/Notifications/UnsentNotifications";

export default function ToolbarMobile(props) {
  const [isFiltersDialogOpen, setIsFiltersDialogOpen] = useState(false);
  const [isMoreIconVisible, setIsMoreIconVisible] = useState(false);

  const renderDate = () => {
    let text;
    if (props.view === "day") {
      text = `יום ${props.date.format("dd, DD/MM")}`;
    } else {
      text = `${props.dateRange.start.format(
        "ll"
      )} - ${props.dateRange.end.format("ll")}`;
    }

    return (
      <Typography variant="h5" sx={{ minWidth: 130 }}>
        {text}
      </Typography>
    );
  };

  const filtersSelected = useMemo(
    () =>
      !(
        props.eventTypesProps.selectedEventTypes.all ||
        Object.keys(props.eventTypesProps.selectedEventTypes).length === 4
      ) ||
      !(
        props.clubCoachesProps.selectedClubCoaches.all ||
        Object.keys(props.clubCoachesProps.selectedClubCoaches).length ===
          props.clubCoachesProps.clubCoaches.length
      ) ||
      !(
        props.teamsProps.selectedTeams.all ||
        Object.keys(props.teamsProps.selectedTeams).length ===
          props.teamsProps.teams.length
      ) ||
      !(
        props.resourcesProps.selectedResources.all ||
        Object.keys(props.resourcesProps.selectedResources).length ===
          props.resourcesProps.resources.filter((r) => !r.parent).length
      ),

    [props.clubCoachesProps, props.eventTypesProps, props.resourcesProps]
  );

  const toggleMoreIcons = () => {
    setIsMoreIconVisible((prev) => !prev);
  };

  const closeMoreIcons = () => {
    setIsMoreIconVisible(false);
  };

  return (
    <Grid container sx={styles.container}>
      <Grid
        item
        xs={12}
        container
        justifyContent="space-between"
        alignItems="center"
      >
        <ClickAwayListener onClickAway={closeMoreIcons}>
          <Box sx={styles.moreIconsContainer}>
            {props.admin && (
              <>
                <IconButton size="small" onClick={toggleMoreIcons}>
                  <MoreVertIcon />
                </IconButton>
                <Grow in={isMoreIconVisible}>
                  <Stack
                    sx={styles.moreIcons}
                    spacing={1}
                    justifyContent="center"
                  >
                    <WeekStatus
                      mobile
                      {...props.weekStatusProps}
                      onClose={closeMoreIcons}
                    />

                    <UnseenUpdates
                      {...props.unreadUpdatesProps}
                      onClose={closeMoreIcons}
                      mobile
                    />

                    <UnsentNotifications
                      {...props.unsentNotificationsProps}
                      onClose={closeMoreIcons}
                      mobile
                    />
                  </Stack>
                </Grow>
              </>
            )}
          </Box>
        </ClickAwayListener>
        <Box sx={styles.dateContainer}>
          <IconButton
            aria-label="Prev"
            onClick={() => props.onDateChanged("prev")}
            size="large"
          >
            <NextIcon />
          </IconButton>
          {renderDate()}
          <IconButton
            aria-label="Next"
            onClick={() => props.onDateChanged("next")}
            size="large"
          >
            <PrevIcon />
          </IconButton>
        </Box>
        <IconButton
          style={{ width: 30 }}
          size="small"
          onClick={() => setIsFiltersDialogOpen(true)}
        >
          <Badge
            overlap="rectangular"
            badgeContent="  "
            variant="dot"
            invisible={!filtersSelected}
            color="primary"
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <FilterIcon />
          </Badge>
        </IconButton>
      </Grid>
      <ToolBarFilterDialog
        open={isFiltersDialogOpen}
        onClose={() => setIsFiltersDialogOpen(false)}
        filterProps={{
          isMobile: true,
          view: props.view,
          resourceView: props.resourceView,
          weekViewType: props.weekViewType,
          resources: props.resources,
          onViewChanged: props.onViewChanged,
          onResourceViewChanged: props.onResourceViewChanged,
          eventTypesProps: props.eventTypesProps,
          clubCoachesProps: props.clubCoachesProps,
          teamsProps: props.teamsProps,
          resourcesProps: props.resourcesProps,
        }}
      />
    </Grid>
  );
}

const styles = {
  container: {
    paddingLeft: "10px",
    paddingRight: "10px",
    width: "100%",
  },
  selected: {
    backgroundColor: "#EFEFEF",
  },
  moreIcons: {
    position: "absolute",
    left: -10,
    zIndex: 100,
    border: "1px solid #EEE",
    borderRadius: "30px",
    padding: "15px",
    backgroundColor: "background.paper",
    width: 200,
  },
  moreIconsContainer: {
    position: "relative",
  },
  dateContainer: {
    display: "flex",
    alignItems: "center",
  },
};
