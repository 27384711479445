import React from "react";
import moment from "moment";
import { connect } from "react-redux";
import ReactGA from "react-ga4";
import { Box } from "@mui/material";

import axios from "../../../utils/axios";
import TransactionsTable from "./TransactionsTable";
import TransactionsSum from "./TransactionsSum";
import FiltersBar from "../../../components/filters/FiltersBar";
import { transactionKinds } from "../../../utils/dictionaries";
import {
  prepareFilters,
  getFiltersFromUrl,
} from "../../../components/filters/prepareFilters";
import clubConfig from "../../../config/clubs";
import withRouter from "../../../components/routing/withRouter";
import ResponsiveContainer from "../../../components/ResponsiveContainer";
import TlPaper from "../../../components/TlPaper";

const filtersStructure = [
  { type: "date", calendarProps: { disableFuture: true } },
  { type: "amount" },
  {
    name: "transactionType",
    type: "select",
    options: transactionKinds,
    emptyLabel: "כל אמצעי התשלום",
    label: "אמצעי תשלום",
  },
  {
    name: "status",
    type: "select",
    options: {
      ok: "עסקאות תקינות",
      cancelled: "עסקאות מבוטלות",
      declined: "עסקאות שנכשלו",
    },
    emptyLabel: "כל הסטטוסים",
    label: "סטטוס עסקה",
    includeAll: true,
  },
  {
    name: "receiptNumber",
    type: "text",
    label: "מספר קבלה",
  },
  {
    name: "payerName",
    type: "text",
    label: "שם הלקוח",
  },
];

class PaymentsReport extends React.Component {
  state = {
    transactions: [],
    filters: {
      status: "ok",
      transactionType: "all",
      amount: { from: "", to: "" },
      date: { from: moment().startOf("day"), to: moment().endOf("day") },
      transactionId: "",
      receiptNumber: "",
      payerName: "",
    },
    isLoading: false,
  };

  componentDidMount = async () => {
    ReactGA.send({
      hitType: "pageview",
      page: "/payments/transactions",
      title: "Transactions",
    });

    const filters = getFiltersFromUrl(
      this.props.location,
      this.state.filters,
      filtersStructure
    );
    await this.setState({ filters });
    this.loadData(false);
  };

  loadData = async (updateUrl = true) => {
    let filters = prepareFilters(this.state.filters, filtersStructure, {
      includeAll: false,
    });
    this.setState({ isLoading: true });
    const res = await axios.get(`/payments/transactions?${filters.join("&")}`);
    const transactions = res.data;
    this.setState({ transactions, isLoading: false });

    if (updateUrl) {
      filters = prepareFilters(this.state.filters, filtersStructure);
      this.props.navigate(`?${filters.join("&")}`, { replace: true });
    }
  };

  handleFilterChange = (filterName, value) => {
    this.setState({ filters: { ...this.state.filters, [filterName]: value } });
  };

  render() {
    const config = clubConfig[this.props.selectedClub.internalName];
    const hideTotals =
      config.hideTotals === true &&
      this.props.user &&
      this.props.user.level && //until auto reloading userDetails
      this.props.user.level !== "admin";

    return (
      <TlPaper title="תשלומים" fullPage titleBackground>
        <FiltersBar
          filters={filtersStructure}
          onFilterChanged={this.handleFilterChange}
          values={this.state.filters}
          onSearch={this.loadData}
          isSearching={this.state.isLoading}
        />
        {!hideTotals && (
          <ResponsiveContainer
            desktopContent={
              <TransactionsSum transactions={this.state.transactions} />
            }
          />
        )}
        <Box sx={{ mt: 1 }}>
          <TransactionsTable
            transactions={this.state.transactions}
            isLoading={this.state.isLoading}
          />
        </Box>
      </TlPaper>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedClub: state.auth.selectedClub,
    user: state.auth.user,
  };
}

export default connect(mapStateToProps)(withRouter(PaymentsReport));
