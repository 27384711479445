import React from "react";
import {
  TableRow as MuiTableRow,
  TableCell as MuiTableCell,
} from "@mui/material";
import { styled } from "@mui/material/styles";

const TableCell = styled(MuiTableCell, {
  shouldForwardProp: (prop) => prop !== "selected",
})(({ selected }) => {
  const base = {
    border: "0px",
    borderTop: "1px solid #EFEFEF",
  };
  if (selected) {
    base.fontWeight = "bold";
  }

  return base;
});

const TableRow = styled(MuiTableRow)({
  "&:nth-of-type(even)": {
    backgroundColor: "rgba(0, 0, 0, .03)",
  },
  "&:hover": {
    backgroundColor: "#e0f3ff !important",
  },
});

export default function EnhancedRow(props) {
  const handleOnClick = () => {
    props.onRowClick && props.onRowClick(props.row);
  };

  return (
    <TableRow onClick={handleOnClick} hover tabIndex={-1}>
      {props.headers.map((header) => {
        return (
          <TableCell
            key={header.id}
            align={header.alignment || "left"}
            padding={header.padding || "normal"}
            selected={props.selected}
          >
            {props.renderCell(header.id, props.row, header)}
          </TableCell>
        );
      })}
    </TableRow>
  );
}
