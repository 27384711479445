import React from "react";
import moment from "moment";
import {
  CircularProgress,
  Grid,
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  Box,
} from "@mui/material";
import { connect } from "react-redux";
import { Buffer } from "buffer";

import axios from "../../../utils/axios";
import FiltersBar from "../../../components/filters/FiltersBar";
import {
  prepareFilters,
  getFiltersFromUrl,
} from "../../../components/filters/prepareFilters";
import { Browser } from "../../../utils";
import staticClubsConfig from "../../../config/clubs.js";
import withRouter from "../../../components/routing/withRouter";
import ButtonSx from "../../../components/base/ButtonSx.js";
import TlPaper from "../../../components/TlPaper.js";

const getMaxDate = () => {
  const date = moment().add(-2, "day").endOf("day");
  const cutOffTime = moment().set({ h: 13, m: 35, s: 0 });
  if (moment().isAfter(cutOffTime)) {
    date.add(1, "days");
  }

  return date;
};

class AccountingDocuments extends React.Component {
  state = {
    isLoading: false,
    filters: {
      date: {
        from: getMaxDate().startOf("month"),
        to: getMaxDate(),
      },
      type: null,
    },
    isDialogOpen: false,
    filtersStructure: [
      {
        type: "date",
        calendarProps: { maxDate: getMaxDate() },
        filterProps: { noPresets: true },
      },
    ],
  };

  componentDidMount = async () => {
    const filters = getFiltersFromUrl(
      this.props.location,
      this.state.filters,
      this.state.filtersStructure
    );

    const filtersStructure = [...this.state.filtersStructure];

    if (
      this.props.selectedClub.serverConfig.accountingExport.customerToProduct
        .itemsLevel
    ) {
      filtersStructure.push({
        name: "type",
        type: "select",
        options: {
          moveinRegisterToCustomer: "פקודות יומן",
          imoveinCustomerToProduct: "מסמכי מלאי",
        },
        label: "סוג קובץ",
      });

      if (!filters.type) {
        filters.type = "moveinRegisterToCustomer";
      }
    }
    await this.setState({ filters, filtersStructure });
  };

  export = async (updateUrl = true) => {
    const filters = prepareFilters(
      this.state.filters,
      this.state.filtersStructure
    );

    this.setState({ isLoading: true, isDialogOpen: true });
    const currentClub = this.props.selectedClub;
    const baseUrl =
      "/payments/accountingDocuments" +
      (staticClubsConfig[currentClub.internalName].payments
        .accountingDocumentsExportOld
        ? "/old"
        : "");
    const res = await axios.get(`${baseUrl}?${filters.join("&")}`, {
      responseType: "arraybuffer",
    });
    const result = Buffer.from(res.data, "binary").toString("base64");
    if (result.length > 0) {
      const type =
        this.state.filters.type === "imoveinCustomerToProduct"
          ? "imovein"
          : "movein";
      const fileName = `${type}_${this.state.filters.date.from.format(
        "DD-MM-YY"
      )}_${this.state.filters.date.to.format("DD-MM-YY")}.zip`;
      Browser.download("data:application/zip;base64," + result, fileName);
    }
    this.setState({
      hasData: result.length > 0,
      isLoading: false,
    });

    if (updateUrl) {
      this.props.navigate(`?${filters.join("&")}`, { replace: true });
    }
  };

  downloadIndex = async () => {
    this.setState({ isLoading: true, isDialogOpen: true });
    const res = await axios.get(`/payments/accountingDocuments/index`, {
      responseType: "arraybuffer",
    });
    const result = Buffer.from(res.data, "binary").toString("base64");
    if (result.length > 0) {
      const fileName = `customersIndex.zip`;
      Browser.download("data:application/zip;base64," + result, fileName);
    }
    this.setState({
      hasData: result.length > 0,
      isLoading: false,
    });
  };

  handleFilterChange = (filterName, value) => {
    this.setState({
      filters: {
        ...this.state.filters,
        [filterName]: value,
      },
    });
  };

  renderStatus = () => {
    return this.state.isLoading ? (
      <Box sx={styles.dialogContent}>
        <Typography>יצוא הקבצים להנהלת חשבונות החל</Typography>
        <CircularProgress />
      </Box>
    ) : (
      <Box sx={styles.dialogContent}>
        <Typography>
          {this.state.hasData
            ? "קבצי הנהלת החשבונות מוכנים!"
            : "לא נמצאו נתונים לתאריך זה"}
        </Typography>
      </Box>
    );
  };

  handleCloseDialog = () => {
    this.setState({ isDialogOpen: false });
  };

  renderIndexExportButton = () => {
    const customerLevel =
      this.props.selectedClub.serverConfig.accountingExport.customerLevel;

    return (
      ["clubParent", "clubPlayer"].indexOf(customerLevel) > -1 && (
        <ButtonSx
          onClick={this.downloadIndex}
          debounce
          sx={{ width: undefined }}
        >
          אינדקס לקוחות
        </ButtonSx>
      )
    );
  };

  render() {
    return (
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <TlPaper
            title="יצוא להנהלת חשבונות"
            noMinHeight
            leftItem={this.renderIndexExportButton()}
            titleBackground
          >
            <div>
              <FiltersBar
                filters={this.state.filtersStructure}
                onFilterChanged={this.handleFilterChange}
                values={this.state.filters}
                onSearch={this.export}
                isSearching={this.state.isLoading}
                searchButtonTitle="יצוא"
              />
            </div>

            <Dialog
              dir="rtl"
              fullWidth
              maxWidth="xs"
              onClose={this.handleCloseDialog}
              open={this.state.isDialogOpen}
            >
              <DialogContent>{this.renderStatus()}</DialogContent>
              <DialogActions>
                <ButtonSx onClick={this.handleCloseDialog}>סגור</ButtonSx>
              </DialogActions>
            </Dialog>
          </TlPaper>
        </Grid>
      </Grid>
    );
  }
}

const styles = {
  dialogContent: {
    height: 100,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
};

function mapStateToProps(state) {
  return {
    selectedClub: state.auth.selectedClub,
  };
}

export default connect(mapStateToProps, null)(withRouter(AccountingDocuments));
