import React, { useState } from "react";
import { CircularProgress } from "@mui/material";
import { styled } from "@mui/material/styles";

import InfoRow from "../../../components/InfoRows/InfoRow";
import ButtonSx from "../../../components/base/ButtonSx";
import { AdvancedImage } from "@cloudinary/react";
import { cloudinaryImage } from "../../../utils/cloudinary";

const StyledImage = styled(AdvancedImage)({
  width: 150,
  height: 150,
});

export default function LogoSettings({ onChange, isLoading, clubConfig }) {
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUploadFile = async () => {
    await onChange(selectedFile);
    setSelectedFile(null);
  };

  if (clubConfig) {
    const logo = clubConfig.general.logo;
    const imgObject = cloudinaryImage(logo.name, {
      version: logo.version,
      size: "thumbnail",
    });

    const img = (
      <div>
        <StyledImage cldImg={imgObject} alt="Logo" />
        <input
          accept=".png"
          type="file"
          onChange={handleFileChange}
          id="upload-logo-input"
          style={{ display: "none" }}
        />
        <label htmlFor="upload-logo-input">
          <ButtonSx sx={{ width: null }} buttonProps={{ component: "span" }}>
            Choose File
          </ButtonSx>
        </label>

        {selectedFile && (
          <div>
            {selectedFile.name}{" "}
            <div>
              <ButtonSx debounce onClick={handleUploadFile}>
                Upload
              </ButtonSx>
            </div>
          </div>
        )}

        {isLoading && <CircularProgress />}
      </div>
    );

    return <InfoRow title="לוגו" value={img} />;
  }

  return <></>;
}
