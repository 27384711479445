import React from "react";
import {
  Grid,
  RadioGroup,
  Radio,
  Typography,
  FormControlLabel,
  Box,
} from "@mui/material";

import WireTransferForm from "../WireTransferForm";
import CheckRefund from "./CheckRefund";
import CancelChecks from "./CancelChecks";
import CreditCardRefundForm from "./CreditCardRefundForm";
import InfoRow from "../../InfoRows/InfoRow";
import { displayPrice } from "../../../utils/helpers";
import Paper2Sx from "../../Paper2Sx";

const refundPaymentMethods = {
  creditCard: "כרטיס אשראי",
  check: "צ׳ק (מהמועדון להורה)",
  cash: "מזומן",
  wireTransfer: "העברה בנקאית",
};

export default function RefundPaymentMethod(props) {
  const paymentMethod = props.form;

  const paymentMethods = ["check", "cash", "wireTransfer"];
  if (props.transaction.kind === "CreditCardTransaction") {
    paymentMethods.splice(0, 0, "creditCard");
  }

  const { totalCancelledChecksAmount } = paymentMethod;
  const additionalRefundRequired =
    props.refundAmount - totalCancelledChecksAmount > 0;

  const renderPaymentMethods = () => {
    return (
      <>
        <RadioGroup
          row
          value={paymentMethod.type}
          onChange={(e) => props.onChange({ type: e.target.value })}
        >
          {paymentMethods.map((paymentMethodOption) => {
            return (
              <FormControlLabel
                key={paymentMethodOption}
                disabled={
                  (paymentMethod.cancelledChecks.length > 0 &&
                    paymentMethodOption !== "check") ||
                  !additionalRefundRequired
                }
                value={paymentMethodOption}
                control={<Radio />}
                label={refundPaymentMethods[paymentMethodOption]}
              />
            );
          })}
        </RadioGroup>

        {paymentMethod.type === "check" && additionalRefundRequired && (
          <CheckRefund
            date={paymentMethod.date}
            checkNumber={paymentMethod.checkNumber}
            accountNumber={paymentMethod.accountNumber}
            branch={paymentMethod.branch}
            bank={paymentMethod.bank}
            onChange={props.onChange}
            disabled={props.disabled}
            errors={paymentMethod.errors}
          />
        )}
        {paymentMethod.type === "wireTransfer" && additionalRefundRequired && (
          <WireTransferForm state={paymentMethod} onChange={props.onChange} />
        )}

        {paymentMethod.type === "creditCard" && additionalRefundRequired && (
          <CreditCardRefundForm
            paymentMethod={paymentMethod}
            onChange={props.onChange}
          />
        )}
      </>
    );
  };

  const title = props.returnChecks ? "בחירת צ׳קים חוזרים" : "אמצעי תשלום להחזר";
  return (
    <Paper2Sx
      externalTitle={title}
      paperSx={{ p: { xs: 1, sm: 3 } }}
      fullHeight
    >
      <Grid container>
        {props.transaction.kind === "CheckTransaction" && (
          <>
            <Grid item xs={12} md={5}>
              <Paper2Sx
                fullHeight
                externalTitle={
                  !props.returnChecks && (
                    <Typography variant="subtitle1">
                      <b>
                        ביטול צ׳קים - {displayPrice(totalCancelledChecksAmount)}
                      </b>
                    </Typography>
                  )
                }
              >
                <CancelChecks
                  transaction={props.transaction}
                  onChange={props.onCancelledCheckChange}
                  cancelledChecks={paymentMethod.cancelledChecks}
                  refundAmount={props.refundAmount}
                  totalCancelledChecksAmount={totalCancelledChecksAmount}
                  returnChecks={props.returnChecks}
                  errors={props.form.errors}
                />
              </Paper2Sx>
            </Grid>
            {!props.returnChecks && (
              <Grid item xs={12} sm={2}>
                <Box sx={styles.andOrContainer}>
                  <b> ו/או</b>
                </Box>
              </Grid>
            )}
          </>
        )}

        {!props.returnChecks && (
          <Grid
            item
            xs={12}
            md={props.transaction.kind === "CheckTransaction" ? 5 : 12}
          >
            {props.transaction.kind === "CheckTransaction" ? (
              <Paper2Sx
                fullHeight
                externalTitle={
                  <Typography variant="subtitle1">
                    <b>
                      אמצעי תשלום נוסף -{" "}
                      {displayPrice(
                        props.refundAmount - totalCancelledChecksAmount
                      )}
                    </b>
                  </Typography>
                }
              >
                {renderPaymentMethods()}
                <InfoRow
                  title="סכום"
                  value={displayPrice(
                    props.refundAmount - totalCancelledChecksAmount
                  )}
                />
              </Paper2Sx>
            ) : (
              renderPaymentMethods()
            )}
          </Grid>
        )}
      </Grid>
    </Paper2Sx>
  );
}

const styles = {
  noBorder: {
    border: "0px solid black",
  },
  andOrContainer: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 20,
    minHeight: 50,
    marginTop: "10px",
  },
  additionalRefund: {
    height: "100%",
    minHeight: "50px",
    border: "0px solid black",
  },
};
