export const validateSingleField = (
  formSettings,
  initialValues,
  instantValidate,
  currentFormErrors,
  field,
  value
) => {
  const formErrors = { ...currentFormErrors };
  if (instantValidate) {
    if (validateField(formSettings, initialValues, field, value)) {
      delete formErrors[field];
    } else {
      if (value === "" || value === null) {
        formErrors[field] = "required";
      } else {
        formErrors[field] = "invalid";
      }
    }
  }

  return formErrors;
};

export const validateField = (formSettings, initialValues, field, value) => {
  if (
    formSettings[field] &&
    formSettings[field].required === false &&
    value === initialValues[field]
  ) {
    return true;
  }

  if (formSettings[field] && formSettings[field].validator) {
    return formSettings[field].validator(value);
  } else if (formSettings[field] && formSettings[field].initialValue) {
    return formSettings[field].initialValue !== value;
  } else {
    return value !== "";
  }
};

export const validateForm = (form, formSettings, initialValues) => {
  const formErrors = {};
  let valid = true;
  Object.keys(form).forEach((field) => {
    const fieldValid = validateField(
      formSettings,
      initialValues,
      field,
      form[field]
    );
    if (!fieldValid) {
      if (form[field] === "" || form[field] === null) {
        formErrors[field] = "required";
      } else {
        formErrors[field] = "invalid";
      }
    }
    valid = valid && fieldValid;
  });

  return { valid, formErrors };
};

export const validateFormNg = (form, formSettings) => {
  const formErrors = {};
  let valid = true;
  Object.keys(formSettings).forEach((field) => {
    const error = validateFieldNg(formSettings[field], form[field]);
    if (error) {
      formErrors[field] = error;
      valid = false;
    }
  });

  return { valid, formErrors };
};

export const validateFieldNg = (fieldSetting, value) => {
  if (
    fieldSetting.required === false &&
    value === (fieldSetting.initialValue || "")
  ) {
    return;
  }

  if (value === (fieldSetting.initialValue || "")) {
    return "required";
  } else if (fieldSetting.validator) {
    return fieldSetting.validator(value) ? null : "invalid";
  }
};

export const generateErrorMessage = (type, formErrors, errorMessages = {}) => {
  if (type === "phone" && formErrors.phone === "unique") {
    return "מספר הטלפון קיים במערכת";
  }

  if (type === "email" && formErrors.email === "unique") {
    return "כתובת האימייל קיימת במערכת";
  }

  if (formErrors[type] === "required") {
    return errorMessages.required || "שדה חובה";
  }

  if (formErrors[type] === "invalid") {
    return errorMessages.invalid || "שדה לא תקין";
  }

  return formErrors[type];
};

export const normalizeServerErrors = (serverErrors) => {
  const errors = serverErrors.player || {};

  if (serverErrors.parent) {
    Object.keys(serverErrors.parent).forEach((error) => {
      errors[`parent${error}`] = serverErrors.parent[error];
    });
  }

  return errors;
};

export const normalizeServerValidationErrors = (serverErrors) => {
  if (Array.isArray(serverErrors)) {
    const errors = {};
    serverErrors.forEach((e) => (errors[e.param] = "invalid"));
    return errors;
  }

  if (serverErrors) {
    if (serverErrors.phone === "unique") {
      serverErrors.phone = "מספר הטלפון קיים במערכת";
    }

    if (serverErrors.id === "unique") {
      serverErrors.id = "מספר תעודת הזהות קיים במערכת";
    }
  }

  return serverErrors;
};
