import React from "react";
import { Grid } from "@mui/material";

import ButtonSx from "../../../components/base/ButtonSx";

const styles = {
  button: {
    width: "100%",
  },
  disabled: {
    pointerEvents: "none",
  },
};

const SimpleButton = (props) => {
  return (
    <ButtonSx
      variant={props.selected ? "contained" : "outlined"}
      sx={{
        ...styles.button,
        ...(props.disabled ? styles.disabled : {}),
      }}
      onClick={props.onClick}
    >
      {props.title}
    </ButtonSx>
  );
};

export default function EventTypeSelectDesktop(props) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <SimpleButton
          disabled={props.disabled}
          title="אימון"
          selected={props.eventType === "practice"}
          onClick={() => props.onEventTypeChange("practice")}
        />
      </Grid>

      <Grid item xs={3}>
        <SimpleButton
          disabled={props.disabled}
          title="משחק בית"
          selected={props.eventType === "game" && props.gameType === "home"}
          onClick={() => {
            props.onEventTypeChange("game");
            props.onGameTypeChange("home");
          }}
        />
      </Grid>
      <Grid item xs={3}>
        <SimpleButton
          disabled={props.disabled}
          title="משחק חוץ"
          selected={props.eventType === "game" && props.gameType === "away"}
          onClick={() => {
            props.onEventTypeChange("game");
            props.onGameTypeChange("away");
          }}
        />
      </Grid>
      <Grid item xs={3}>
        <SimpleButton
          disabled={props.disabled}
          title="אחר"
          selected={props.eventType === "other"}
          value="other"
          onClick={() => props.onEventTypeChange("other")}
        />
      </Grid>
    </Grid>
  );
}
