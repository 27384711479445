import React from "react";
import CheckIcon from "@mui/icons-material/Check";
import { Box } from "@mui/material";
import { sxx } from "../utils/helpers";

export default function PasswordHelperText(props) {
  const pwd = props.password || "";
  const number = pwd.match(/\d/);
  const lower = pwd.match(/[a-z]/);
  const capital = pwd.match(/[A-Z]/);
  const length = pwd.match(/^[0-9a-zA-Z]{8,}$/);

  const renderRow = (text, isMatch) => (
    <Box sx={sxx(styles.row, [[!isMatch, styles.noMatch]])}>
      <CheckIcon sx={sxx({}, [[!isMatch, styles.noMatchIcon]])} />
      {text}
    </Box>
  );

  return (
    <div>
      על הסיסמה להכיל:
      {renderRow("אות גדולה", capital !== null)}
      {renderRow("אות קטנה", lower !== null)}
      {renderRow("ספרה", number !== null)}
      {renderRow("לפחות 8 תווים", length !== null)}
    </div>
  );
}

const styles = {
  row: {
    display: "flex",
    alignItems: "center",
  },
  noMatch: {
    color: "text.disabled",
  },
  noMatchIcon: {
    color: "#DADADA",
  },
};
