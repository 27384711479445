import React from "react";
import {
  Button,
  TextField,
  DialogActions,
  DialogContent,
  Alert,
} from "@mui/material";

import { displayPrice } from "../../utils/helpers";
import Dialog from "../Dialog";

export default function PartialPaymentDialog(props) {
  const [amount, setAmount] = React.useState(props.amount);
  const [amountError, setAmountError] = React.useState(false);

  React.useEffect(() => {
    setAmount(props.amount);
    setAmountError(false);
  }, [props.amount]);

  return (
    <Dialog
      fullWidth
      onClose={props.onClose}
      open={props.open}
      title="תשלום חלקי"
    >
      <DialogContent dividers>
        <TextField
          variant="standard"
          required
          fullWidth
          label="סכום לתשלום"
          type="number"
          value={amount}
          onChange={(e) => {
            const val = e.target.value;
            val > props.maxAmount || val < props.minAmount
              ? setAmountError(true)
              : setAmountError(false);
            setAmount(parseInt(e.target.value));
          }}
          error={amountError}
          helperText={`על הסכום להיות בין ${displayPrice(
            props.minAmount
          )} ו ${displayPrice(props.maxAmount)}`}
        />

        <Alert severity="info" style={{ marginTop: 20 }}>
          שימו לב, פעולה זו איננה הנחה אלא גביית תשלום חלקי.
          <div>לאחר שינוי המחיר תיווצר יתרת חוב לרישום זה</div>
        </Alert>
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" onClick={props.onClose}>
          ביטול
        </Button>
        <Button
          variant="outlined"
          disabled={amountError}
          onClick={() => props.onComplete(amount)}
        >
          אישור
        </Button>
      </DialogActions>
    </Dialog>
  );
}
