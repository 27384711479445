import React from "react";
import { Grid, TextField } from "@mui/material";

export default function NewPlayerForm(props) {
  const handleChange = (field, value) => (event) => {
    props.onChange({ [field]: value || event.target.value });
  };

  const defaultParams = (field) => {
    const error = Object.prototype.hasOwnProperty.call(
      props.state.errors,
      field
    );

    return {
      id: field,
      name: field,
      fullWidth: true,
      value: props.state[field],
      onChange: handleChange(field),
      error,
      helperText: error ? props.state.errors[field] : "",
    };
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <TextField
          variant="standard"
          {...defaultParams("guestName")}
          label="שם מלא"
          required
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          variant="standard"
          {...defaultParams("guestPhone")}
          label="טלפון"
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          variant="standard"
          {...defaultParams("guestTeam")}
          label="קבוצה"
        />
      </Grid>
    </Grid>
  );
}
