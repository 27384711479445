import React, { useState, useEffect } from "react";
import {
  Box,
  DialogActions,
  DialogContent,
  Stack,
  TextField,
} from "@mui/material";

import Dialog from "../../../components/Dialog";
import ButtonSx from "../../../components/base/ButtonSx";

export default function EnterPassportNumberDialog(props) {
  const [passportNumber, setPassportNumber] = useState("");
  const [error, setError] = useState(false);
  const [instaValidate, setInstaValidate] = useState(false);
  const [isValidating, setIsValidating] = useState(false);

  useEffect(() => {
    setPassportNumber("");
    setError(false);
    setInstaValidate(false);
    setIsValidating(false);
  }, [props.open]);

  const validate = (value) => {
    const isValid = value.trim().match(/^[0-9a-zA-Z]{7,}$/) !== null;
    setError(!isValid);
    return isValid;
  };

  const handleComlete = async () => {
    setInstaValidate(true);
    const isValid = validate(passportNumber);
    if (isValid) {
      setIsValidating(true);
      await props.onComplete(passportNumber);
      setIsValidating(false);
    }
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      title="רישום באמצעות דרכון"
    >
      <DialogContent dividers>
        <Stack>
          <Box sx={{ mb: 1, fontWeight: "bold", width: 300 }}>
            הזינו את מספר הדרכון של{" "}
            {props.userType === "clubPlayer" ? "הספורטאי" : "ההורה"}:
          </Box>
          <TextField
            autoFocus
            value={passportNumber}
            error={error}
            onChange={(e) => {
              setPassportNumber(e.target.value);
              if (instaValidate) {
                validate(e.target.value);
              }
            }}
            placeholder="מספר דרכון"
            helperText={error ? "יש להזין מספר דרכון תקין" : ""}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <ButtonSx onClick={props.onClose} disabled={isValidating}>
          ביטול
        </ButtonSx>
        <ButtonSx
          isLoading={isValidating}
          disabled={error}
          onClick={handleComlete}
          debounce
        >
          המשך
        </ButtonSx>
      </DialogActions>
    </Dialog>
  );
}
