import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Link, Typography } from "@mui/material";
import Paper2Sx from "../../components/Paper2Sx";

export default function Confirmation(props) {
  return (
    <>
      <Paper2Sx>
        <Typography variant="h5" gutterBottom>
          ההרשמה הסתיימה בהצלחה!
        </Typography>

        <Typography variant="subtitle1">
          {props.noPayment ? (
            <Link
              component={RouterLink}
              color="primary"
              to={`../players/${props.playerId}`}
              underline="hover"
            >
              מעבר לעמוד הספורטאי
            </Link>
          ) : (
            <>
              <div>
                מספר אסמכתא:{" "}
                <Link
                  component={RouterLink}
                  color="primary"
                  to={`../payments/transactions/${props.transactionInternalId}`}
                  underline="hover"
                >
                  {props.transactionId}.
                </Link>
              </div>

              {props.email && <div>אישור ההרשמה נשלח לכתובת {props.email}</div>}
            </>
          )}
        </Typography>
      </Paper2Sx>
      <div style={{ height: 30 }} />
    </>
  );
}
