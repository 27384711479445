import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";

import axios from "../../../../utils/axios";
import ButtonSx from "../../../../components/base/ButtonSx";

function DeletePlayerAlertDialog(props) {
  const [error, setError] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [completed, setCompleted] = React.useState(null);

  React.useEffect(() => {
    setError(null);
    setLoading(false);
    setCompleted(null);
  }, [props.playerAlert]);

  const handleDelete = async () => {
    if (completed) {
      props.onComplete();
    } else {
      try {
        setLoading(true);
        await axios.delete(`/settings/playerAlerts/${props.playerAlert._id}`);
        setCompleted(true);
      } catch (error) {
        setError({ type: "unknown" });
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const renderContent = () => {
    if (completed) {
      return "ההתראה נמחקה בהצלחה";
    } else if (error) {
      return `לא ניתן לבצע פעולה זו כרגע `;
    } else {
      return <div>האם ברצונך למחוק את ״{(props.playerAlert || {}).text}״?</div>;
    }
  };

  const renderActions = () => {
    if (completed) {
      return <ButtonSx onClick={props.onComplete}>חזרה</ButtonSx>;
    } else if (error) {
      return <ButtonSx onClick={props.onClose}>חזרה</ButtonSx>;
    } else {
      return (
        <>
          <ButtonSx onClick={props.onClose} disabled={loading}>
            ביטול
          </ButtonSx>
          <ButtonSx onClick={handleDelete} disabled={loading}>
            מחיקה
          </ButtonSx>
        </>
      );
    }
  };

  return (
    <Dialog
      dir="rtl"
      fullWidth
      maxWidth="sm"
      onClose={props.onClose}
      open={props.open}
    >
      <DialogTitle id="form-dialog-title">מחיקת התראה</DialogTitle>
      <DialogContent dividers>{renderContent()}</DialogContent>
      <DialogActions>{renderActions()}</DialogActions>
    </Dialog>
  );
}

export default DeletePlayerAlertDialog;
