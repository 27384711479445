import React, { useState, useRef } from "react";
import {
  Typography,
  Slide,
  Dialog,
  AppBar,
  IconButton,
  Toolbar,
  Fab,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ReactCrop from "react-image-crop";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import SaveIcon from "@mui/icons-material/SaveOutlined";

import "react-image-crop/dist/ReactCrop.css";
import PictureSelector from "../../../components/PictureSelector";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const initialCropState = () => ({
  unit: "px",
  width: 100,
  height: 100,
});

export default function SelectImage(props) {
  const [baseImageUrl, setBaseImageUrl] = useState(null);
  const [crop, setCrop] = useState(initialCropState());
  const imageRef = useRef(null);
  const fileUrlRef = useRef(null);

  const onCropChange = (newCrop) => {
    setCrop(newCrop);
  };

  const makeClientCrop = async (crop) => {
    if (imageRef.current && crop.width && crop.height) {
      const croppedImageUrl = await getCroppedImg(
        imageRef.current,
        crop,
        "newFile.png"
      );
      return croppedImageUrl;
    }
  };

  const getCroppedImg = (image, crop, fileName) => {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          return;
        }
        blob.name = fileName;

        window.URL.revokeObjectURL(fileUrlRef.current);
        fileUrlRef.current = window.URL.createObjectURL(blob);
        resolve({ url: fileUrlRef.current, blob });
      }, "image/jpeg");
    });
  };

  const handleAddImage = async () => {
    const image = await makeClientCrop(crop);
    props.onImageAdded(image);
    handleClose();
  };

  const handleClose = () => {
    props.onClose();
    setCrop(initialCropState());
    setBaseImageUrl(null);
  };

  return (
    <Dialog
      dir="rtl"
      fullScreen
      open={props.open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={styles.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="Close"
            size="large"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" sx={styles.title}>
            בחירת תמונה
          </Typography>
        </Toolbar>
      </AppBar>
      {baseImageUrl ? (
        <Box sx={styles.container}>
          <ReactCrop
            crop={crop}
            onChange={onCropChange}
            ruleOfThirds
            aspect={1}
          >
            <img src={baseImageUrl} ref={imageRef} />
          </ReactCrop>
        </Box>
      ) : (
        <PictureSelector
          onPictureSelected={(imageUrl) => setBaseImageUrl(imageUrl)}
        />
      )}
      {baseImageUrl && (
        <Box sx={styles.buttonsContainer}>
          <Fab
            size="small"
            color="primary"
            aria-label="add"
            onClick={handleAddImage}
          >
            <SaveIcon />
          </Fab>
          <Box sx={styles.buttonSpacer} />
          <Fab
            size="small"
            color="primary"
            aria-label="add"
            onClick={() => setBaseImageUrl(null)}
          >
            <DeleteIcon />
          </Fab>
        </Box>
      )}
    </Dialog>
  );
}

const styles = {
  container: {
    display: "flex",
    flex: 1,
    margin: "10px",
    width: "70%",
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "center",
  },
  buttonsContainer: {
    position: "absolute",
    right: 20,
    bottom: 20,
    display: "flex",
  },
  buttonSpacer: {
    width: 10,
  },
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: 2,
    flex: 1,
  },
};
