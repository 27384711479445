import React from "react";
import moment from "moment";
import { Link as RouterLink } from "react-router-dom";
import { Link } from "@mui/material";

import { displayDate, displayPrice } from "../../utils/helpers";
import Table from "../../components/table/Table";
import { downloadCsv } from "../../lib/tableHelpers";
import { creditCardTypes, transactionKinds } from "../../utils/dictionaries";

const columns = [
  { id: "transactionDate", label: "תאריך עסקה" },
  { id: "paymentDate", label: "תאריך פירעון" },
  { id: "amount", label: "סכום", alignment: "right" },
  { id: "receiptNumber", label: "מספר קבלה" },
  { id: "payerName", label: "שם הלקוח" },
  { id: "transactionType", label: "סוג עסקה" },
  { id: "details", label: "פרטים נוספים" },
];

export default function IncomeTable(props) {
  const renderCell = (columnId, transaction) => {
    let details;

    switch (columnId) {
      case "amount":
        return displayPrice(transaction.amount);

      case "transactionDate":
      case "paymentDate":
        return displayDate(transaction[columnId]);

      case "receiptNumber":
        return (
          <Link
            component={RouterLink}
            color="primary"
            to={`../payments/transactions/${transaction._id}`}
            underline="hover">
            {transaction.receiptNumber}
          </Link>
        );

      case "transactionType":
        return transactionKinds[transaction.transactionType];

      case "details":
        details = transaction.details;
        switch (transaction.transactionType) {
          case "CreditCardTransaction":
            return `תשלום ${details.paymentNumber} מתוך ${
              details.numberOfPayments
            } בכרטיס אשראי ${creditCardTypes[details.cardType]} המסתיים ב - ${
              details.last4
            } `;
          case "CheckTransaction":
            return `תשלום בצ׳ק מספר ${details.checkNumber}. פרטי החשבון: ${details.bank}-${details.branch}-${details.accountNumber}`;

          case "WireTransferTransaction":
            return `תשלום בהעברה בנקאית מחשבון ${details.bank}-${details.bankBranch}-${details.bankAccount}`;

          default:
            return "";
        }

      default:
        return transaction[columnId];
    }
  };

  const sortValueCell = (columnId, transaction) => {
    switch (columnId) {
      case "date":
        return moment(transaction.date).unix();

      case "receiptNumber":
        return transaction.receiptNumber;

      default:
        return transaction[columnId];
    }
  };

  const handleExportTable = async () => {
    const csv = await props.onExport();
    downloadCsv(csv, "פירעון תשלומים");
  };

  const renderKey = (transaction) => {
    return (
      (transaction.details || {}).checkId ||
      (transaction.details || {}).paymentId ||
      transaction._id
    );
  };

  return (
    <Table
      columns={columns}
      renderCell={renderCell}
      sortValueCell={sortValueCell}
      rows={props.transactions}
      title="תשלומים"
      onExport={handleExportTable}
      isLoading={props.isLoading}
      renderKey={renderKey}
      pagination
    />
  );
}
