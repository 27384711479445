import React from "react";
import {
  TextField,
  InputAdornment,
  CircularProgress,
  Box,
} from "@mui/material";

import CheckIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorIcon from "@mui/icons-material/ErrorOutline";
import { sxx } from "../../utils/helpers";

export default function TextFieldValidator(props) {
  const renderIcon = () => {
    if (props.validating) {
      return (
        <Box sx={styles.iconWrapper}>
          <CheckIcon
            sx={{
              ...styles.tranparent,
              ...(props.textFieldProps.disabled ? styles.disabled : {}),
            }}
          />
          <CircularProgress size={20} sx={styles.progress} />
        </Box>
      );
    }

    return (
      <Box sx={styles.iconWrapper}>
        {props.validate &&
          (props.valid ? (
            <CheckIcon
              sx={sxx({}, [
                [props.softValidate, styles.validLight, styles.valid],
                [props.textFieldProps.disabled, styles.disabled],
              ])}
            />
          ) : (
            <ErrorIcon
              sx={sxx(styles.invalidStrong, [
                [props.textFieldProps.disabled, styles.disabled],
              ])}
            />
          ))}
      </Box>
    );
  };

  const textFieldProps = props.textFieldProps || {};

  return (
    <TextField
      variant="standard"
      {...textFieldProps}
      onBlur={props.onBlur}
      InputProps={{
        endAdornment: (
          <InputAdornment position="start">{renderIcon()}</InputAdornment>
        ),
      }}
      inputProps={{
        readOnly:
          props.validating || (textFieldProps.inputProps || {}).readOnly,
      }}
    />
  );
}

const styles = {
  invalidLight: {
    color: "action.selected",
  },
  invalidStrong: {
    color: "error.main",
  },
  valid: {
    color: "success.main",
  },
  validLight: {
    color: "action.selected",
  },
  iconWrapper: {
    position: "relative",
  },
  progress: {
    color: "green",
    position: "absolute",
    top: 2,
    bottom: 0,
    left: 2,
    right: 0,
    zIndex: 1,
  },
  tranparent: {
    color: "transparent",
  },
  disabled: {
    color: "text.disabled",
  },
};
