import React, { useEffect, useRef, useState } from "react";
import { Badge, Box, IconButton, Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import CommentIcon from "@mui/icons-material/Comment";
import ModeCommentIcon from "@mui/icons-material/ModeComment";
import { styled } from "@mui/material/styles";

import * as Actions from "../../store/actions";

const StyledBadge = styled(Badge, {
  shouldForwardProp: (prop) => prop !== "secondaryColor",
})(({ secondaryColor }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: secondaryColor || "#2196f3",
    border: "1px solid white",
    color: "white",
    top: -2,
  },
}));

function NotificationsBar(props) {
  const [isFocused, setIsFocused] = useState(true);
  const intervalHandler = useRef();

  const onFocus = () => {
    setIsFocused(true);
  };

  // User has switched away from the tab (AKA tab is hidden)
  const onBlur = () => {
    setIsFocused(false);
  };

  useEffect(() => {
    window.addEventListener("focus", onFocus);
    window.addEventListener("blur", onBlur);

    return () => {
      window.removeEventListener("focus", onFocus);
      window.removeEventListener("blur", onBlur);
      // when changing clubs the component re-mounted - but the next useEffect (isFocused) is not being called so we must clear the interval here
      if (intervalHandler.current) {
        clearInterval(intervalHandler.current);
      }
    };
  }, []);

  useEffect(() => {
    if (isFocused && !intervalHandler.current) {
      props.loadNotifications(true);
      intervalHandler.current = setInterval(() => {
        props.loadNotifications(true);
      }, 5 * 60 * 1000);
    } else if (!isFocused && intervalHandler.current) {
      clearInterval(intervalHandler.current);
      intervalHandler.current = null;
    }
  }, [isFocused]);

  const clubConfig = props.selectedClub.clubConfig;

  const showOtherPaymentMethod =
    clubConfig.parentsConfig?.paymentMethods === "all" ||
    clubConfig.parentsConfig?.paymentMethods === "other";

  const color = props.textColor || "#FFF";

  return (
    <Box sx={styles.container}>
      {clubConfig.general.discountRequests && (
        <IconButton
          component={Link}
          to="subscriptions/discountRequests"
          size="large"
        >
          <StyledBadge
            badgeContent={props.notifications.discountRequests}
            secondaryColor={props.secondaryColor}
          >
            <Tooltip title="בקשות מיוחדות">
              <CommentIcon sx={{ color }} />
            </Tooltip>
          </StyledBadge>
        </IconButton>
      )}

      {showOtherPaymentMethod && (
        <IconButton
          component={Link}
          to="subscriptions/otherPaymentMethodRequests"
          size="large"
        >
          <StyledBadge
            badgeContent={props.notifications.otherPaymentMethodRequests}
            secondaryColor={props.secondaryColor}
          >
            <Tooltip title="בקשות לתשלום במזומן / צ׳ק">
              <Box sx={styles.shekelContainer}>
                <ModeCommentIcon sx={{ color }} />
                <Box
                  sx={{
                    ...styles.shekelSymbol,
                    color: props.backgroundColor || "primary.main",
                  }}
                >{`\u20AA`}</Box>
              </Box>
            </Tooltip>
          </StyledBadge>
        </IconButton>
      )}
    </Box>
  );
}

const styles = {
  container: {
    margin: "0px 30px",
  },
  shekelSymbol: {
    position: "absolute",
    top: -2,
    fontSize: 15,
    fontWeight: "900",
  },
  shekelContainer: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
};

function mapStateToProps(state) {
  return {
    notifications: state.general.notificationBarCounters,
    selectedClub: state.auth.selectedClub,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loadNotifications: (limitRequests) =>
      dispatch(Actions.general.loadNotificationBarCounters(limitRequests)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsBar);
