import React from "react";
import {
  Grid,
  Button,
  Divider,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";

const styles = {
  closeButton: {
    position: "absolute",
    top: 10,
    left: 5,
  },
  grid: {
    maxWidth: 700,
  },
  button: {
    width: { xs: 60, sm: 120 },
    margin: 1,
    "&:hover": { backgroundColor: "transparent" },
  },
};

const presets = {
  day: [
    { preset: "day", title: "היום" },
    { preset: "week", title: "השבוע" },
    { preset: "month", title: "החודש" },
    { preset: "year", title: "השנה" },
  ],
  month: [
    { preset: "lastMonth", title: "חודש שעבר" },
    { preset: "month", title: "החודש" },
    { preset: "lastYear", title: "שנה שעברה" },
    { preset: "year", title: "השנה" },
  ],
};

export default function DatePickerAdvancedPopupContent(props) {
  const isSmUp = useMediaQuery((t) => t.breakpoints.up("sm"), { noSsr: true });

  const handlePresetSelected = (preset) => async () => {
    let params = {};
    switch (preset) {
      case "day":
      case "week":
      case "month":
      case "year":
        params.from = moment().startOf(preset);
        params.to = moment().endOf(preset);
        break;

      case "lastMonth":
        params.from = moment().add(-1, "month").startOf("month");
        params.to = moment().add(-1, "month").endOf("month");
        break;

      case "lastYear":
        params.from = moment().add(-1, "year").startOf("year");
        params.to = moment().add(-1, "year").endOf("year");
        break;

      default:
        break;
    }

    props.onPresetSelected(params);
  };

  return (
    <div dir="rtl">
      <Grid container sx={styles.grid}>
        <Grid
          item
          container
          xs={12}
          justifyContent={
            !props.isSingleMode && !props.noPresets ? "space-evenly" : undefined
          }
        >
          {props.onClose && (
            <IconButton onClick={props.onClose}>
              <CloseIcon />
            </IconButton>
          )}
          {!props.isSingleMode &&
            !props.noPresets &&
            presets[props.type].map(({ title, preset }) => (
              <Button
                key={preset}
                sx={styles.button}
                onClick={handlePresetSelected(preset)}
                disableRipple
              >
                {title}
              </Button>
            ))}
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item container>
          <Grid item xs={12} sm>
            <DateCalendar
              {...(isSmUp ? {} : { style: { height: 290 } })}
              label="התחלה"
              value={props.dates.from}
              onChange={props.onDateChanged("from")}
              {...props.calendarProps}
              {...(props.type === "month" ? { views: ["month", "year"] } : {})}
            />
          </Grid>
          {!props.isSingleMode && (
            <>
              <Grid item>
                <Divider orientation="vertical" />
              </Grid>
              <Grid item xs={12} sm>
                <DateCalendar
                  value={props.dates.to}
                  {...(isSmUp ? {} : { style: { height: 290 } })}
                  onChange={props.onDateChanged("to")}
                  {...props.calendarProps}
                  {...(props.type === "month"
                    ? { views: ["month", "year"] }
                    : {})}
                />
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </div>
  );
}
