import React, { useState } from "react";
import {
  FormHelperText,
  Grid,
  TextField,
  useMediaQuery,
  Box,
} from "@mui/material";

import Table from "../../../components/table/Table";
import { classes as classesDic } from "../../../utils/dictionaries";
import ExistingPlayerSearchResultList from "./ExistingPlayerSearchResultList";
import ButtonSx from "../../../components/base/ButtonSx";

const columns = [
  { id: "name", label: "ספורטאי" },
  { id: "id", label: "תעודת זהות" },
  { id: "class", label: "כיתה" },
  { id: "phone", label: "טלפון" },
  { id: "parentName", label: "שם ההורה" },
  { id: "action", label: "" },
];

export default function ExistingPlayerForm(props) {
  const isSmUp = useMediaQuery((theme) => theme.breakpoints.up("sm"), {
    noSsr: true,
  });

  const [error, setError] = useState(null);
  const form = props.existingPlayerSearchForm;

  const defaultParams = (field) => ({
    value: form[field],
    onChange: (e) =>
      props.onChange({
        existingPlayerSearchForm: { ...form, [field]: e.target.value },
      }),
  });

  const handleSearch = () => {
    if (!form.name.trim() && !form.id.trim() && !form.phone.trim()) {
      setError("יש להזין לפחות שדה חיפוש אחד");
    } else {
      setError(null);
      props.onSearch(form.name, form.id, form.phone);
    }
  };

  const renderCell = (columnId, clubPlayer) => {
    switch (columnId) {
      case "parentName":
        return clubPlayer.parent.name;

      case "class":
        return classesDic[clubPlayer.class];

      case "action":
        return (
          <ButtonSx
            onClick={() => props.onClubPlayerSelected(clubPlayer)}
            isLoading={
              props.isLoadingSiblings &&
              clubPlayer._id === props.state.clubPlayerId
            }
            disabled={
              props.state.isSearchingClubPlayers || props.isLoadingSiblings
            }
            fullWidth
          >
            בחירה
          </ButtonSx>
        );

      default:
        return clubPlayer[columnId];
    }
  };

  const renderResults = () => {
    if (!props.state.searchClubPlayersResults) {
      return;
    }

    return (
      <Box sx={{ mt: 4 }}>
        {props.state.searchClubPlayersResults.length > 0 ? (
          isSmUp ? (
            <Table
              noHeader
              columns={columns}
              renderCell={renderCell}
              rows={props.state.searchClubPlayersResults}
              pagination={false}
              isLoading={props.state.isSearchingClubPlayers}
              sort={false}
              selected={props.state.clubPlayerId}
            />
          ) : (
            <ExistingPlayerSearchResultList
              players={props.state.searchClubPlayersResults}
              isLoading={props.state.isSearchingClubPlayers}
              selected={props.state.clubPlayerId}
              onClubPlayerSelected={props.onClubPlayerSelected}
            />
          )
        ) : (
          <strong>לא נמצאו ספורטאים</strong>
        )}
      </Box>
    );
  };

  return (
    <Box>
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={6} sm={3}>
          <TextField
            variant="standard"
            {...defaultParams("name")}
            label="שם"
            fullWidth
          />
        </Grid>
        <Grid item xs={6} sm={3}>
          <TextField
            variant="standard"
            {...defaultParams("id")}
            label="ת.ז"
            fullWidth
          />
        </Grid>
        <Grid item xs={6} sm={3}>
          <TextField
            variant="standard"
            {...defaultParams("phone")}
            label="טלפון"
            fullWidth
          />
        </Grid>
        <Grid item xs={6} sm={3}>
          <ButtonSx
            variant="contained"
            onClick={handleSearch}
            fullWidth
            debounce
            isLoading={props.state.isSearchingClubPlayers}
            disabled={props.isLoadingSiblings}
          >
            חיפוש
          </ButtonSx>
        </Grid>

        {error && (
          <Grid item xs={12}>
            <FormHelperText error>{error}</FormHelperText>
          </Grid>
        )}
      </Grid>

      {renderResults()}
    </Box>
  );
}
