import React, { useEffect, useRef, useState } from "react";

import ToolTip from "./base/ToolTip";

export default function EllipsisTooltip(props) {
  const textElementRef = useRef();
  const [hoverStatus, setHoverStatus] = useState(false);

  const compareSize = () => {
    setHoverStatus(
      textElementRef.current.scrollWidth > textElementRef.current.clientWidth
    );
  };

  // compare once and add resize listener on "componentDidMount"
  useEffect(() => {
    compareSize();
    window.addEventListener("resize", compareSize);

    return () => {
      window.removeEventListener("resize", compareSize);
    };
  }, []);

  return (
    <ToolTip title={props.children} disableHoverListener={!hoverStatus}>
      <div
        ref={textElementRef}
        style={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {props.children}
      </div>
    </ToolTip>
  );
}
