import React, { useState } from "react";
import { Fab, Tooltip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DoneIcon from "@mui/icons-material/DoneOutline";
import ArchiveIcon from "@mui/icons-material/ArchiveOutlined";
import { styled } from "@mui/material/styles";

import InfoRow from "../../components/InfoRows/InfoRow";
import { genders, teamStatuses, teamTypes } from "../../utils/dictionaries";
import TeamDialog from "../../components/TeamDialog";
import ArchiveTeamDialog from "./ArchiveTeamDialog";
import CompleteActivityDialog from "./CompleteActivityDialog";
import { displaySeason } from "../../utils/helpers";
import TlPaper from "../../components/TlPaper";

const FabsContainer = styled("div")({
  position: "absolute",
  right: 10,
  bottom: 5,
});

const styles = {
  infoRowTitle: { width: 160 },
};

export default function TeamDetails(props) {
  const { team } = props;
  const [isEditing, setIsEditing] = useState(false);
  const [isArchiveDialogOpen, setIsArchiveDialogOpen] = useState(false);
  const [isCompleteActivityDialogOpen, setIsCompleteActivityDialogOpen] =
    useState(false);

  const clubCoach = team.clubCoach || {};

  const handleArchive = async () => {
    await props.onArchive();
    setIsArchiveDialogOpen(false);
  };

  const handleCompleteActivity = async () => {
    await props.onCompleteActivity();
    setIsCompleteActivityDialogOpen(false);
  };

  // const handleDelete = async () => {
  //   setDeleteStatus("loading");
  //   const res = await props.onDelete();
  //   setDeleteStatus(res ? "success" : "failure");
  // };

  const getRegistrationStatus = () => {
    if (team) {
      if (!team.season?.openForRegistration) {
        return "סגור - עונה סגורה לרישום";
      }
      if (team.product) {
        if (!team.product.active) {
          return "סגור - מסגרת לא פעילה";
        }

        if (team.product.expired) {
          return "סגור - מסגרת הסתיימה";
        }
      }

      if (team.status !== "active") {
        return "סגור - הפעילות הסתיימה";
      }

      if (team.isFull) {
        return "סגור - קבוצה מלאה";
      }
      if (!team.isRegistrationOpen) {
        return "סגור";
      }

      return "פתוח";
    }
  };

  const content = (
    <>
      <FabsContainer>
        {props.user.permissions.hrCoachTeamAdmin && (
          <>
            {team.status === "active" && (
              <Tooltip title="עדכון פרטי הקבוצה ">
                <Fab
                  color="primary"
                  aria-label="Add"
                  size="small"
                  onClick={() => setIsEditing(true)}
                >
                  <EditIcon />
                </Fab>
              </Tooltip>
            )}

            {team.status === "active" && (
              <Tooltip title="סיום פעילות">
                <Fab
                  color="primary"
                  aria-label="Add"
                  size="small"
                  onClick={() => setIsCompleteActivityDialogOpen(true)}
                  style={{ marginRight: 10 }}
                >
                  <DoneIcon />
                </Fab>
              </Tooltip>
            )}

            {team.status === "completed" && (
              <Tooltip title="העברה לארכיון">
                <Fab
                  color="primary"
                  aria-label="Add"
                  size="small"
                  onClick={() => setIsArchiveDialogOpen(true)}
                  style={{ marginRight: 10 }}
                >
                  <ArchiveIcon />
                </Fab>
              </Tooltip>
            )}
          </>
        )}
      </FabsContainer>
      {team.status === "active" && (
        <TeamDialog
          key={team._id}
          team={team}
          open={isEditing}
          onClose={() => setIsEditing(false)}
          onComplete={() => {
            setIsEditing(false);
            props.onChange();
          }}
          clubCategory={props.clubCategory}
          registeredPlayers={props.registeredPlayers}
        />
      )}
      <ArchiveTeamDialog
        team={team}
        open={isArchiveDialogOpen}
        onClose={() => setIsArchiveDialogOpen(false)}
        onArchive={handleArchive}
      />
      <CompleteActivityDialog
        team={team}
        open={isCompleteActivityDialogOpen}
        onClose={() => setIsCompleteActivityDialogOpen(false)}
        onCompleteActivity={handleCompleteActivity}
      />

      <InfoRow
        titleSx={styles.infoRowTitle}
        title="שם הקבוצה"
        value={team.name}
      />
      <InfoRow
        titleSx={styles.infoRowTitle}
        title="עונה"
        value={displaySeason(team.season?.year)}
      />
      <InfoRow
        titleSx={styles.infoRowTitle}
        title="סטטוס"
        value={teamStatuses[props.team.status]}
      />
      <InfoRow
        titleSx={styles.infoRowTitle}
        title="מאמן"
        value={clubCoach.name || "-"}
        linkTo={`../coaches/${clubCoach._id}`}
      />
      <InfoRow
        titleSx={styles.infoRowTitle}
        title="עוזרי מאמן"
        value={team.assistants?.map((a) => a.name).join(", ") || "-"}
      />
      <InfoRow
        titleSx={styles.infoRowTitle}
        title="סוג"
        value={team.teamType}
        editType="select"
        options={teamTypes[props.clubCategory] || teamTypes.default}
      />
      {team.teamType === "league" && (
        <InfoRow
          titleSx={styles.infoRowTitle}
          title="מס׳ קבוצה באיגוד"
          value={team.igud.id || "-"}
        />
      )}
      <InfoRow
        titleSx={styles.infoRowTitle}
        title="מין"
        value={team.gender}
        editType="select"
        options={genders}
      />

      {team.teamLevel && (
        <InfoRow
          titleSx={styles.infoRowTitle}
          title="קטגוריה"
          value={`${team.teamLevel.name}${
            team.teamSubLevel ? ` / ${team.teamSubLevel.name}` : ""
          }`}
        />
      )}
      <InfoRow
        titleSx={styles.infoRowTitle}
        title="סטטוס רישום"
        value={getRegistrationStatus()}
      />
      <InfoRow
        titleSx={styles.infoRowTitle}
        title="מספר נרשמים מקסימלי"
        value={team.subscriptionsLimit || "אין הגבלה"}
      />
    </>
  );

  return (
    <TlPaper title="פרטי הקבוצה" isLoading={props.isLoading} titleBackground>
      {content}
    </TlPaper>
  );
}
