import React from "react";
import {
  AppBar as MuiAppBar,
  Toolbar,
  IconButton,
  useMediaQuery,
  Box,
  Link,
  Collapse,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";
import { Link as RouterLink } from "react-router-dom";

import ClubSelect from "./ClubSelect";
import NotificationsBar from "./NotificationsBar";

const drawerWidth = 220;

const StyledAppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) =>
    ["open", "selectedClub", "isMdUp", "giladProd"].indexOf(prop) === -1,
})(({ theme, open, selectedClub, isMdUp, giladProd }) => {
  const base = {
    color: selectedClub?.clubConfig.mainTextColor || theme.appBar.color,
    backgroundColor: giladProd
      ? "red"
      : selectedClub?.clubConfig.mainBackgroundColor ||
        theme.appBar.backgroundColor,
  };

  if (isMdUp) {
    base.transition = open
      ? theme.transitions.create(["margin", "width"], {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        })
      : theme.transitions.create(["margin", "width"], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        });
    base.marginLeft = open ? 0 : drawerWidth;

    // open can also be null. it's important to set width even if open is null in order to prevent the transation on page load
    if (open !== false) {
      base.width = `calc(100% - ${drawerWidth}px)`;
    }
  }

  return base;
});

export default function AppBar(props) {
  const isMdUp = useMediaQuery((theme) => theme.breakpoints.up("md"), {
    noSsr: true,
  });

  return (
    <>
      <StyledAppBar
        position="fixed"
        open={props.isDrawerOpen}
        selectedClub={props.selectedClub}
        isMdUp={isMdUp}
        giladProd={props.giladProd}
      >
        <Toolbar sx={{ px: 0 }}>
          <Box sx={{ display: "flex", flex: 1, alignItems: "center" }}>
            <Collapse
              orientation="horizontal"
              in={props.isDrawerOpen === false || !isMdUp}
            >
              <IconButton color="inherit" onClick={props.onDrawerToggle}>
                <MenuIcon />
              </IconButton>
            </Collapse>
            <ClubSelect textColor={props.textColor} />
            {props.user.permissions.registrationAdmin && isMdUp && (
              <NotificationsBar
                textColor={props.textColor}
                secondaryColor={props.secondaryColor}
                backgroundColor={props.backgroundColor}
                key={props.selectedClub?._id}
              />
            )}
          </Box>
          {isMdUp && (
            <Link
              component={RouterLink}
              color="secondary"
              to={`/logout`}
              sx={(theme) => ({
                textDecoration: "none",
                color:
                  props.selectedClub?.clubConfig.mainTextColor ||
                  theme.appBar.color,
              })}
            >
              התנתק
            </Link>
          )}
        </Toolbar>
      </StyledAppBar>
    </>
  );
}
