import React from "react";

import { Typography } from "@mui/material";
import Chart from "react-apexcharts";
import _ from "lodash";
import { useNavigate } from "react-router-dom";

export default function PlayersAttendance(props) {
  const navigate = useNavigate();

  const d = props.data.attendanceRate;
  const data = {
    low: _.sum(d.slice(0, 6)),
    medium: _.sum(d.slice(6, 8)),
    high: _.sum(d.slice(8, 10)),
  };

  const total = _.sum(props.data.attendanceRate);

  const labels = [
    "נוכחות נמוכה - פחות מ 60%",
    "נוכחות בינונית - בין 60% ל 80%",
    "נוכחות גבוהה - למעלה מ 80%",
    "אין נתונים - אין מספיק דוחות נוכחות",
  ];

  const series = [
    {
      name: "",
      data: [
        data.low,
        data.medium,
        data.high,
        props.data.registeredPlayers - total,
      ],
    },
  ];

  const generateOptions = () => ({
    chart: {
      type: "bar",
      height: 260,
      toolbar: { show: false },
      events: {
        dataPointSelection: () => {
          navigate("attendance?tab=players");
        },
      },
    },
    plotOptions: {
      bar: {
        barHeight: "100%",
        distributed: true,
        horizontal: true,
      },
    },
    stroke: {
      width: 1,
      colors: ["#fff"],
    },
    colors: ["#d32f2f", "#f57c00", "#388e3c", "#D3D3D3"],
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    xaxis: {
      categories: ["נמוכה", "בינונית", "גבוהה", "אין נתונים"],
    },
    yaxis: {
      labels: {
        offsetX: -50,
      },
    },
    tooltip: {
      x: {
        formatter: function (value, { dataPointIndex }) {
          return labels[dataPointIndex];
        },
      },

      y: {
        formatter: function (value) {
          return ` ${value} ספורטאים `;
        },
      },
    },
  });

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        letterSpacing: "normal",
      }}
    >
      <Chart
        options={generateOptions()}
        series={series}
        type="bar"
        height={260}
        width="100%"
      />
      <Typography variant="h6" style={{ alignSelf: "center" }}>
        נוכחות ספורטאים
      </Typography>
    </div>
  );
}
