import React from "react";
import {
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from "@mui/material";
import ButtonSx from "../../../components/base/ButtonSx";

export default function ExistingPlayerSearchResultList(props) {
  const renderDesc = (player) => (
    <span>
      {player.id && <div>ת.ז {player.id || "-"}</div>}
      {player.phone && <div>טלפון {player.phone || "-"}</div>}
    </span>
  );

  const onClubPlayerSelected = (clubPlayer) => () => {
    props.onClubPlayerSelected(clubPlayer);
  };

  return (
    <List>
      {props.players.map((player) => (
        <ListItem key={player._id} sx={styles.listItem}>
          <ListItemText
            primary={player.name}
            secondary={renderDesc(player)}
            secondaryTypographyProps={{ component: "span" }}
          />
          <ListItemSecondaryAction>
            <ButtonSx onClick={onClubPlayerSelected(player)}>בחירה</ButtonSx>
          </ListItemSecondaryAction>
        </ListItem>
      ))}
    </List>
  );
}

const styles = {
  listItem: {
    border: "1px solid #EEE",
    borderRadius: "10px",
    marginBottom: "5px",
  },
};
