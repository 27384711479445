import React, { useState } from "react";
import { Grid, TextField, Collapse } from "@mui/material";
import moment from "moment";

import MenuItem from "../../../components/base/MenuItem";
import DatePicker from "../../../components/DatePicker";
import Paper2Sx from "../../../components/Paper2Sx";
import ButtonSx from "../../../components/base/ButtonSx";
import FirstMonthAutoPriceUpdateDialog from "./FirstMonthAutoPriceUpdateDialog";
import { roundPrice } from "../../../utils/helpers";

export default function SubscriptionDetailsForm(props) {
  const [
    isFirstMonthPriceUpdateDialogOpen,
    setIsFirstMonthPriceUpdateDialogOpen,
  ] = useState(false);
  const selectedSubscriptionProduct = props.cart.subscription?.product || {};

  const external = selectedSubscriptionProduct._id === "external";
  let seasonStartDate, seasonEndDate;
  if (selectedSubscriptionProduct._id && !external) {
    const subscriptionDetails = selectedSubscriptionProduct.subscriptionDetails;
    seasonStartDate = moment(subscriptionDetails.startDate);
    seasonEndDate = moment(subscriptionDetails.endDate).endOf("day");
  }

  const handleStartDateChanged = (startDate) => {
    const oldStartDate = props.state.startDate;
    if (
      props.state.product?._id !== "external" &&
      startDate.date() > 14 &&
      !(
        oldStartDate &&
        moment(oldStartDate).date() > 14 &&
        moment(oldStartDate).month() === startDate.month()
      ) &&
      ["season", "camp"].indexOf(
        props.state.product.subscriptionDetails.subscriptionType
      ) > -1
    ) {
      setIsFirstMonthPriceUpdateDialogOpen(true);
    }

    props.onChange({ startDate });
  };

  const handleUpdateFirstMonthPrice = () => {
    const newPrice = roundPrice(props.state.pricing.unitPrice / 2);
    const monthlyPrices = props.state.monthlyPrices.map((p, i) => {
      if (i === 0) {
        return { ...p, price: newPrice, priceStr: newPrice.toString() };
      } else {
        return { ...p };
      }
    });

    props.onCartSubscriptionChange({ monthlyPrices });
    props.onChange({ monthlyPrices });

    setIsFirstMonthPriceUpdateDialogOpen(false);
  };

  const handlePricingChanged = (e) => {
    props.onChange({ pricingId: e.target.value });
  };

  const showSubLabel =
    props.state.level !== "" && props.state.level.subLevels.length > 1;

  const teamStatus = (team) => {
    const alreadyRegistered =
      props.clubPlayer &&
      props.clubPlayer.activeTeams &&
      props.clubPlayer.activeTeams.indexOf(team._id) > -1;

    let text = team.name;
    if (alreadyRegistered) {
      text += " - הספורטאי רשום לקבוצה זו";
    } else if (team.isFull) {
      text += " - הקבוצה מלאה";
    } else if (!team.isRegistrationOpen) {
      text += " - ההרשמה נסגרה";
    }

    return { text, alreadyRegistered };
  };

  return (
    <Paper2Sx
      externalTitle="קבוצה"
      sx={styles.paperContainer}
      paperSx={{ flex: 1 }}
    >
      <Grid container>
        <Grid item xs={12}>
          <TextField
            variant="standard"
            fullWidth
            select
            margin="dense"
            required
            error={Object.prototype.hasOwnProperty.call(
              props.state.errors,
              "level"
            )}
            label="קטגוריה"
            value={props.state.level ? props.state.level._id : ""}
            onChange={(event) => {
              const level = props.levels.find(
                (l) => l._id === event.target.value
              );
              props.onChange({
                level,
                subLevel:
                  level.subLevels.length === 1 ? level.subLevels[0] : "",
                teamId: "",
                startDate: null,
              });
            }}
            helperText={props.state.errors.level ? "שדה חובה" : ""}
          >
            {props.levels.map((level) => (
              <MenuItem key={level._id} value={level._id}>
                {level.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <Collapse in={showSubLabel}>
            <TextField
              variant="standard"
              fullWidth
              select
              margin="dense"
              required
              error={Object.prototype.hasOwnProperty.call(
                props.state.errors,
                "subLevel"
              )}
              label="תת קטגוריה"
              value={props.state.subLevel ? props.state.subLevel._id : ""}
              onChange={(event) => {
                props.onChange({
                  subLevel: props.state.level.subLevels.find(
                    (sl) => sl._id === event.target.value
                  ),
                  teamId: "",
                  startDate: null,
                });
              }}
              helperText={props.state.errors.subLevel ? "שדה חובה" : ""}
            >
              {props.state.level ? (
                props.state.level.subLevels.map((subLevel) => (
                  <MenuItem key={subLevel._id} value={subLevel._id}>
                    {subLevel.name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem />
              )}
            </TextField>
          </Collapse>
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="standard"
            fullWidth
            disabled={!props.state.subLevel}
            required
            error={Object.prototype.hasOwnProperty.call(
              props.state.errors,
              "teamId"
            )}
            label="קבוצה"
            select
            margin="dense"
            value={props.state.teamId}
            onChange={(event) =>
              props.onChange({ teamId: event.target.value, startDate: null })
            }
            helperText={props.state.errors.teamId ? "שדה חובה" : ""}
          >
            {props.state.subLevel ? (
              props.state.subLevel.teams.map((team) => {
                const { text, alreadyRegistered } = teamStatus(team);
                return (
                  <MenuItem
                    key={team._id}
                    value={team._id}
                    disabled={alreadyRegistered}
                  >
                    {text}
                  </MenuItem>
                );
              })
            ) : (
              <MenuItem />
            )}
          </TextField>
        </Grid>

        {props.state.product?.subscriptionDetails?.singlePricing === false && (
          <Grid item xs={12}>
            <TextField
              variant="standard"
              fullWidth
              disabled={!props.state.team}
              required
              error={Object.prototype.hasOwnProperty.call(
                props.state.errors,
                "pricing"
              )}
              label="סוג רישום"
              select
              margin="dense"
              value={props.state.pricingId}
              onChange={handlePricingChanged}
              helperText={props.state.errors.pricing ? "שדה חובה" : ""}
            >
              {props.state.product.subscriptionDetails.pricings.map(
                (pricing) => (
                  <MenuItem
                    key={pricing._id}
                    value={pricing._id}
                    disabled={!pricing.active}
                  >
                    {pricing.title}
                  </MenuItem>
                )
              )}
            </TextField>
          </Grid>
        )}

        <Grid item xs={12}>
          <DatePicker
            disabled={!props.state.pricing && !external}
            label="תאריך תחילת פעילות"
            textFieldProps={{
              error: Object.prototype.hasOwnProperty.call(
                props.state.errors,
                "startDate"
              ),
              helperText: props.state.errors.startDate,
            }}
            {...(!external
              ? { minDate: seasonStartDate, maxDate: seasonEndDate }
              : {})}
            value={props.state.startDate}
            onChange={handleStartDateChanged}
          />
        </Grid>
        <Grid item xs={6} sm container>
          <ButtonSx
            fullWidth
            disabled={
              !external &&
              (!props.state.pricing ||
                !moment().isBetween(seasonStartDate, seasonEndDate))
            }
            sx={styles.dateButton}
            onClick={() => handleStartDateChanged(moment())}
          >
            היום
          </ButtonSx>
        </Grid>
        <Grid item xs={6} sm>
          <ButtonSx
            fullWidth
            disabled={!props.state.pricing || external}
            sx={styles.dateButton}
            onClick={() => handleStartDateChanged(seasonStartDate)}
          >
            תחילת המסגרת
          </ButtonSx>
        </Grid>
      </Grid>
      <FirstMonthAutoPriceUpdateDialog
        open={isFirstMonthPriceUpdateDialogOpen}
        subscription={props.state}
        onClose={() => setIsFirstMonthPriceUpdateDialogOpen(false)}
        onComplete={handleUpdateFirstMonthPrice}
      />
    </Paper2Sx>
  );
}

const styles = {
  paperContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  dateButton: {
    minWidth: 150,
    mr: 1,
  },
};
