import React from "react";
import { Button, TextField, Grid } from "@mui/material";
import _ from "lodash";

import TextFieldValidator from "../../../components/base/TextFieldValidator";
import ExistingParent from "./ExistingParent";
import ParentFoundDialog from "./ParentFoundDialog";
import GuestParentForm from "./GuestParentForm";
import StepTitle from "../StepTitle";
import Paper2Sx from "../../../components/Paper2Sx";
import PassportNumberTextField from "../Player/PassportNumberTextField";

export default function ParentForm(props) {
  const handleChange = (field) => (event) => {
    props.onChange({ [field]: event.target.value });
  };

  const handleBlur = (field) => () => {
    props.onChange({
      [`${field}InstaValidate`]: true,
      ...(props.state[field] ? {} : { [field]: props.state[field] }), // when the field is empty -> we still want it to be invalid. this line causes field validation
    });
  };

  const defaultParams = (field) => {
    const error = Object.prototype.hasOwnProperty.call(
      props.state.errors,
      field
    );

    return {
      InputProps: {
        readOnly: props.state.clubParentId !== "",
      },
      fullWidth: true,
      value: props.state[field],
      onChange: handleChange(field),
      error,
      helperText: error ? props.state.errors[field] : "",
    };
  };

  const defaultParamsValidator = (field, textFieldParams) => {
    return {
      textFieldProps: {
        ...defaultParams(field),
        ...textFieldParams,
      },

      validating: props.state[`is${_.capitalize(field)}Unique`] === "loading",
      valid: !Object.prototype.hasOwnProperty.call(props.state.errors, field),
      validate: true,
      softValidate:
        !props.state[`${field}InstaValidate`] && !props.state.instaValidate,
      onBlur: handleBlur(field),
    };
  };

  // this func can be called automatically by the dialog (clicking outside) and approved can be event in this case
  const handleCloseParentFoundDialog = (approved) => {
    approved === true
      ? props.onExistingParentSelected()
      : props.onExistingParentNotSelected();
  };

  const parentForm = () => (
    <>
      <Grid item xs={12} sm={6}>
        <TextFieldValidator
          {...defaultParamsValidator("phone", {
            label: "טלפון ההורה",
            required: true,
            autoFocus: true,
          })}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        {props.registerWithPassport ? (
          <PassportNumberTextField
            value={props.state.passportNumber}
            onPassportNumberChanged={props.onPassportNumberChanged}
            error={props.state.errors.passportNumber}
            userType="clubParent"
          />
        ) : (
          <TextFieldValidator
            {...defaultParamsValidator("id", {
              label: "תעודת זהות",
              required: true,
            })}
          />
        )}
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          variant="standard"
          {...defaultParams("firstName")}
          label="שם פרטי"
          required
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          variant="standard"
          {...defaultParams("lastName")}
          label="שם משפחה"
          required
        />
      </Grid>
      {!props.guestMode && (
        <>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="standard"
              {...defaultParams("street")}
              label="רחוב"
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="standard"
              {...defaultParams("houseNumber")}
              label="מספר בית"
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="standard"
              {...defaultParams("city")}
              label="עיר"
              required
            />
          </Grid>
        </>
      )}

      {!props.guestMode && (
        <Grid item xs={12} sm={6}>
          <TextField
            variant="standard"
            {...defaultParams("email")}
            label="אימייל"
            required
          />
        </Grid>
      )}

      {!props.guestMode && (
        <>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="standard"
              {...defaultParams("secondParentName")}
              label="שם הורה ב׳"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="standard"
              {...defaultParams("secondParentPhone")}
              label="טלפון הורה ב׳"
            />
          </Grid>
        </>
      )}
    </>
  );

  const renderContent = () => {
    if (props.guest) {
      return <GuestParentForm state={props.state} onChange={props.onChange} />;
    }

    if (props.state.clubParentId) {
      return (
        <>
          <Grid item xs={12}>
            <ExistingParent parent={props.state} />
          </Grid>
          {!props.existingPlayer && (
            <Grid item xs={12}>
              <Button variant="outlined" onClick={props.onClearSelectedParent}>
                עריכת פרטי ההורה
              </Button>
            </Grid>
          )}
        </>
      );
    }

    return parentForm();
  };

  return (
    <>
      <StepTitle
        title={`פרטי ההורה ${props.guest ? " - אורח" : ""}`}
        purchaseType={props.purchaseType}
      />
      <Paper2Sx>
        <Grid container spacing={3}>
          {renderContent()}
        </Grid>
      </Paper2Sx>
      <ParentFoundDialog
        open={props.state.showParentFoundDialog}
        onClose={handleCloseParentFoundDialog}
        parent={props.state.clubParentSearchResult}
        foundBy={props.state.showParentFoundField}
        invalidParentConnection={props.state.invalidParentConnection}
      />
    </>
  );
}
