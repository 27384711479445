import React, { useState, useEffect } from "react";
import {
  Checkbox,
  List,
  ListItem,
  ListItemText,
  Paper,
  Popover,
  Select,
  InputLabel,
  FormControl,
  ListItemButton,
} from "@mui/material";

import MenuItem from "./MenuItem";

export default function MultipleSelect(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  useEffect(() => {
    //replace "all" selected value with the actual values
    if (props.selectedItems.all && props.options && props.options.length > 0) {
      props.onChange(
        props.options.reduce((hash, opt) => {
          hash[opt.key] = true;
          return hash;
        }, {})
      );
    }
  }, [props.selectedItems, props.options]);

  const handleClose = () => {
    if (!props.mustSelectAtLeastOne || Object.keys(selectedItems).length > 0) {
      setIsOpen(false);
    }
  };

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setIsOpen(!isOpen);
  };

  const handleItemToggle = (item) => () => {
    let selectedItems = { ...props.selectedItems };
    if (item === "all") {
      if (Object.keys(selectedItems).length === props.options.length) {
        selectedItems = {};
      } else {
        selectedItems = props.options.reduce((hash, opt) => {
          hash[opt.key] = true;
          return hash;
        }, {});
      }
    } else if (selectedItems[item.key]) {
      delete selectedItems[item.key];
    } else {
      selectedItems[item.key] = true;
    }

    props.onChange(selectedItems);
  };

  const options = props.options || [];
  const selectedItems = props.selectedItems || {};
  const allSelected = Object.keys(selectedItems).length === options.length;

  const renderValue = () => {
    if (props.isLoading) {
      return "טוען...";
    }
    if (props.selectedItems.all) {
      return "";
    }
    if (allSelected) {
      return props.allTitle;
    }

    const keys = Object.keys(props.selectedItems);
    if (keys.length === 1) {
      const opt = props.options.find((opt) => opt.key === keys[0]);
      return opt.value;
    }
    return `${keys.length} ${props.itemTitle} נבחרו`;
  };
  const labelId = `${props.id}-label`;

  return (
    <>
      <FormControl fullWidth={props.fullWidth} sx={props.sx}>
        <InputLabel id={labelId}>{props.label}</InputLabel>
        <Select
          id={props.id}
          labelId={labelId}
          sx={props.selectSx}
          onOpen={handleOpen}
          open={false}
          value={"ph"} // must provide something to triger renderValue
          renderValue={renderValue}
          label={props.label}
          size="small"
        >
          <MenuItem value="ph"></MenuItem>
        </Select>
      </FormControl>
      <Popover
        open={!props.isLoading && isOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Paper sx={{ minWidth: 200 }}>
          <List dense disablePadding>
            {props.allOption && (
              <ListItem divider disablePadding>
                <ListItemButton onClick={handleItemToggle("all")}>
                  <ListItemText>
                    {props.allTitle}
                    <Checkbox
                      checked={allSelected}
                      onChange={handleItemToggle("all")}
                    />
                  </ListItemText>
                </ListItemButton>
              </ListItem>
            )}
            {options.map((opt) => (
              <ListItem key={opt.key} disablePadding>
                <ListItemButton onClick={handleItemToggle(opt)}>
                  <ListItemText>
                    {opt.value}
                    <Checkbox
                      checked={selectedItems[opt.key] === true}
                      onChange={handleItemToggle(opt)}
                    />
                  </ListItemText>
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Paper>
      </Popover>
    </>
  );
}
