import React from "react";
import {
  Avatar,
  Button,
  Grid,
  Box,
  Typography,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  Paper,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import queryString from "query-string";
import moment from "moment";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

import CopyRight from "../../components/CopyRight";
import * as Actions from "../../store/actions";
import axios from "../../utils/axios";
import { login } from "../Auth/loginHelper";
import PasswordTextField from "../../components/PasswordTextField";
import PasswordHelperText from "../../components/PasswordHelperText";
import AuthLayout from "../Layout/AuthLayout";
import { getAxiosErrorData } from "../../utils/helpers";
import withRouter from "../../components/routing/withRouter";
import ButtonSx from "../../components/base/ButtonSx";

function ActivateUser(props) {
  const [errors, setErrors] = React.useState({});
  const [token, setToken] = React.useState(null);
  const [password, setPassword] = React.useState("");
  const [password2, setPassword2] = React.useState("");
  const [isInvalidTokenDialogOpen, setIsInvalidTokenDialogOpen] =
    React.useState(false);
  const [instaValidate, setInstaValidate] = React.useState(false);
  const [manager, setManager] = React.useState(null);
  const [isSignUpCompleted, setIsSignUpCompleted] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const setters = {
    password: setPassword,
    password2: setPassword2,
  };

  const navigate = useNavigate();

  React.useEffect(() => {
    const qs = queryString.parse(props.location.search);
    const expiresAt = moment(qs.e, "x");
    if (moment().isAfter(expiresAt)) {
      setIsInvalidTokenDialogOpen(true);
    } else {
      setToken(qs.t);
    }
  }, [props.location.search]);

  // can't use password and password2 since they are not updated yet when insta validating
  const validate = ({ password, password2 }) => {
    const currentPass = password;
    const currentPass2 = password2;
    const errors = {};

    if (currentPass === "") {
      errors.password = "שדה חובה";
    } else if (
      currentPass.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/) ===
      null
    ) {
      errors.password = "שדה לא תקין";
    }

    if (currentPass2 === "") {
      errors.password2 = "שדה חובה";
    } else if (currentPass2 !== currentPass) {
      errors.password2 = "הסיסמאות לא זהות";
    }

    setErrors(errors);
    setInstaValidate(true);
    return Object.keys(errors).length === 0;
  };

  const handleSignUp = async (e) => {
    e.preventDefault();
    const valid = validate({ password, password2 });

    if (valid) {
      try {
        setIsLoading(true);
        const res = await axios.post("/managers/activate", { password, token });
        setManager(res.data);
        setIsSignUpCompleted(true);
      } catch (error) {
        const errorData = (getAxiosErrorData(error) || {}).error;
        if (["expired", "invalidToken"].indexOf(errorData) > -1) {
          setIsInvalidTokenDialogOpen(true);
        }
      }
      setIsLoading(false);
    }
  };

  const handleLogin = async () => {
    setIsLoading(true);
    await login(props.login, password, manager.email);
    navigate("/");
  };

  const handleChange = (field) => (e) => {
    setters[field](e.target.value);
    if (instaValidate) {
      validate({ password, password2, [field]: e.target.value });
    }
  };

  return (
    <AuthLayout>
      <Container component="main" maxWidth="xs">
        <Paper sx={styles.paper}>
          <Avatar sx={styles.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            צור את חשבון ה TrainLit שלך
          </Typography>
          <form
            style={{
              width: "100%", // Fix IE 11 issue.
              marginTop: 3,
            }}
            noValidate
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <PasswordTextField
                  name="password"
                  variant="outlined"
                  required
                  fullWidth
                  id="password"
                  label="סיסמה"
                  autoFocus
                  value={password}
                  onChange={handleChange("password")}
                  error={errors.password !== undefined}
                  helperText={errors.password}
                />
              </Grid>
              <Grid item xs={12}>
                <PasswordTextField
                  name="passwordConfirmation"
                  variant="outlined"
                  required
                  fullWidth
                  id="passwordConfirmation"
                  label="סיסמה"
                  value={password2}
                  onChange={handleChange("password2")}
                  error={errors.password2 !== undefined}
                  helperText={errors.password2}
                />
              </Grid>
              <Grid item xs={12}>
                <PasswordHelperText password={password} />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={styles.submit}
              onClick={handleSignUp}
              disabled={isLoading}
            >
              צור חשבון
            </Button>
          </form>
        </Paper>
        <Box mt={5}>
          <CopyRight />
        </Box>
      </Container>
      <Dialog dir="rtl" fullWidth maxWidth="sm" open={isInvalidTokenDialogOpen}>
        <DialogContent>
          <Box sx={styles.expired}>
            <Box sx={styles.expiredTitle}>הלינק שנשלח לך פג תוקף</Box>
            אנא פנו למנהל המועדון לשליחת הזמנה חדשה
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog dir="rtl" fullWidth maxWidth="sm" open={isSignUpCompleted}>
        <DialogContent>
          <Box sx={styles.expired}>
            <Box sx={styles.expiredTitle}>החשבון שלך נוצר בהצלחה!</Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <ButtonSx onClick={handleLogin} disabled={isLoading}>
            המשך
          </ButtonSx>
        </DialogActions>
      </Dialog>
    </AuthLayout>
  );
}

const styles = {
  paper: {
    marginTop: 5,
    padding: 3,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: 1,
    backgroundColor: "primary.main",
  },
  submit: {
    mt: 3,
    mb: 2,
  },
  expired: {
    textAlign: "center",
    fontSize: 20,
    margin: "15px",
  },
  expiredTitle: {
    fontWeight: 500,
    fontSize: 22,
    margin: "10px",
  },
};

const mapStateToProps = () => {};

function mapDispatchToProps(dispatch) {
  return {
    login: (user, token, clubs, userClubId) =>
      dispatch(Actions.auth.login(user, token, clubs, userClubId)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ActivateUser));
