import React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import ReactGA from "react-ga4";

import axios from "../../utils/axios";
import { teamStatuses, teamTypes } from "../../utils/dictionaries";
import {
  prepareFilters,
  getFiltersFromUrl,
} from "../../components/filters/prepareFilters";
import withRouter from "../../components/routing/withRouter";
import ResponsiveContainer from "../../components/ResponsiveContainer";
import TeamsTable from "./TeamsTable";
import TeamDialog from "../../components/TeamDialog";
import TeamSettings from "./Settings/TeamSettings";
import FiltersBar from "../../components/filters/FiltersBar";
import MobileContainer from "../../components/MobileContainer";
import TeamsList from "./TeamsList";
import SnackbarNg from "../../components/base/SnackbarNg";
import TlPaper from "../../components/TlPaper";

function TeamsDesktop(props) {
  return (
    <TlPaper title="קבוצות" titleBackground>
      <FiltersBar
        filters={props.filtersStructure || []}
        onFilterChanged={props.onFilterChanged}
        values={props.filters}
        onSearch={props.onSearch}
        isSearching={props.isLoading}
      />
      <TeamsTable
        teams={props.teams}
        onAddItem={props.onAddItem}
        onOpenSettings={props.onOpenSettings}
        showExtra={props.user?.email === "gilad+superclub@trainlitapp.com"}
      />
    </TlPaper>
  );
}

function TeamsMobile(props) {
  return (
    <MobileContainer title="קבוצות">
      <TeamsList
        teams={props.teams}
        onAddItem={props.onAddItem}
        onOpenSettings={props.onOpenSettings}
      />
    </MobileContainer>
  );
}

class Teams extends React.Component {
  state = {
    teams: [],
    isLoading: true,
    isDialogOpen: false,
    snackbar: null,
    filters: { type: "all", status: "active", season: "" },
    isSettingsOpen: false,
  };

  componentDidMount = async () => {
    ReactGA.send({
      hitType: "pageview",
      page: "/coaches",
      title: "Coaches",
    });

    this.filtersStructure = [
      {
        name: "type",
        type: "select",
        options: teamTypes[this.props.clubCategory] || teamTypes.default,
        emptyLabel: "כל הסוגים",
        label: "סוג קבוצה",
      },
      {
        name: "season",
        type: "select",
        options: _.orderBy(this.props.seasons, "year", "desc").reduce(
          (prev, curr) => {
            prev[curr._id] = curr.name;
            return prev;
          },
          {}
        ),
        emptyLabel: "כל העונות",
        label: "עונה",
        includeAll: true,
      },
      {
        name: "status",
        type: "select",
        options: teamStatuses,
        emptyLabel: "כל הסטטוסים",
        label: "סטטוס",
      },
      // {
      //   name: "category",
      //   type: "select",
      //   options: [],
      //   emptyLabel: "כל הקטגוריות",
      //   isLoading: true,
      //   label: "קטגוריה",
      // },
    ];

    const filters = getFiltersFromUrl(
      this.props.location,
      this.state.filters,
      this.filtersStructure
    );

    await this.setState(
      {
        filters: {
          ...filters,
          season: filters.season || this.props.seasons.find((s) => s.main)?._id,
        },
      },
      () => this.loadTeams(false, false)
    );
  };

  loadTeams = async (silence, updateUrl = true) => {
    this.setState({ isLoading: !silence });
    let filtersBase = prepareFilters(
      this.state.filters,
      this.filtersStructure,
      { includeAll: false }
    );
    const filters = filtersBase.join("&");
    const response = await axios.get(
      `/teams?granularity=clubCoach&granularity=playersStats&granularity=income&granularity=seasonYear&granularity=assistants${
        filtersBase.length > 0 ? "&" + filters : ""
      }`
    );

    this.setState({ teams: _.sortBy(response.data, "name"), isLoading: false });

    if (updateUrl) {
      filtersBase = prepareFilters(this.state.filters, this.filtersStructure);
      this.props.navigate(`?${filtersBase.join("&")}`, { replace: true });
    }
  };

  onAddItem = () => {
    this.setState({ isDialogOpen: true });
  };

  handleTeamCreated = async (newTeam) => {
    this.setState({ isDialogOpen: false });
    this.setState({ snackbar: newTeam._id });
    this.loadTeams(true, false);
  };

  handleFilterChange = (filterName, value) => {
    this.setState({
      filters: { ...this.state.filters, [filterName]: value },
    });
  };

  openSettings = () => {
    this.setState({ isSettingsOpen: true });
  };

  handleSettingsChanged = () => {
    this.setState({ isSettingsOpen: false });
    this.loadTeams(true, false);
  };

  render() {
    const admin = this.props.user.permissions.hrCoachTeamAdmin;

    const params = {
      filtersStructure: this.filtersStructure,
      admin,
      filters: this.state.filters,
      onSearch: () => this.loadTeams(false, true),
      teams: this.state.teams,
      isLoading: this.state.isLoading,
      onFilterChanged: this.handleFilterChange,
      onOpenSettings: admin ? this.openSettings : null,
      onAddItem: admin ? this.onAddItem : null,
      user: this.props.user,
    };

    return (
      <>
        <ResponsiveContainer
          DesktopComponent={TeamsDesktop}
          MobileComponent={TeamsMobile}
          {...params}
        />
        <TeamDialog
          open={this.state.isDialogOpen}
          onClose={() => this.setState({ isDialogOpen: false })}
          clubCategory={this.props.clubCategory}
          onComplete={this.handleTeamCreated}
        />
        <TeamSettings
          open={this.state.isSettingsOpen}
          onClose={this.handleSettingsChanged}
        />
        <SnackbarNg
          snackKey={this.state.snackbar}
          open={this.state.snackbar !== null}
          onClose={() => this.setState({ snackbar: null })}
          message={"הפעולה בוצעה בהצלחה"}
        />
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedClub: state.auth.selectedClub,
    user: state.auth.user,
    seasons: state.general.seasons,
  };
}

export default connect(mapStateToProps)(withRouter(Teams));
