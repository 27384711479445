import React from "react";
import moment from "moment";
import { PickersDay } from "@mui/x-date-pickers/PickersDay/PickersDay";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { Skeleton, Stack } from "@mui/material";

import theme from "./../../theme/muiTheme";
import axios from "../../utils/axios";
import AttendancePie from "./AttendancePie";

function CustomDay(props) {
  const { attendances, day, outsideCurrentMonth, ...other } = props;

  const attendance = attendances.find((att) => day.isSame(att.date));

  return (
    <PickersDay
      {...other}
      disableRipple
      readOnly
      outsideCurrentMonth={outsideCurrentMonth}
      day={day}
      disabled={attendance === undefined}
      sx={
        attendance
          ? {
              fontWeight: "bold",
              color: "#fff",
              backgroundColor: attendance.attended
                ? "success.light"
                : "error.main",
            }
          : {}
      }
    />
  );
}

export default class Attendance extends React.Component {
  state = {
    attendances: null,
    pieData: [],
    isLoading: true,
  };

  componentDidMount = async () => {
    if (this.props.visible) {
      this.loadData();
    }
  };

  loadData = async () => {
    try {
      this.setState({ isLoading: true });
      const response = await axios.get(
        `/clubPlayers/${this.props.playerId}/attendances`
      );

      let attended = 0;
      let lastMonthAttended = 0;
      let lastMonthMissed = 0;
      let lastMonth = moment().subtract(1, "month");

      const attendances = response.data
        .filter((att) => att.attended || att.reason !== "limitedSubscription")
        .map((att) => {
          const date = moment(att.date, "MM/DD/YYYY");

          if (att.attended) attended += 1;
          if (date.isSameOrAfter(lastMonth)) {
            att.attended ? (lastMonthAttended += 1) : (lastMonthMissed += 1);
          }

          return {
            attended: att.attended,
            date,
          };
        });

      const pieData = {
        total: {
          attended,
          missed: attendances.length - attended,
        },
        lastMonth: {
          attended: lastMonthAttended,
          missed: lastMonthMissed,
        },
      };

      this.setState({ attendances, pieData, isLoading: false });
    } catch (error) {
      console.log(error);
    }
  };

  //lazy load coaches
  componentDidUpdate = async (prevProps) => {
    if (
      !prevProps.visible &&
      this.props.visible &&
      this.state.attendances === null
    ) {
      this.loadData();
    }
  };

  attended = (date) => {
    const attendance = this.state.attendances.find((att) =>
      date.isSame(att.date)
    );
    return attendance ? attendance.attended : null;
  };

  render() {
    let pieData = {};
    if (!this.state.isLoading) {
      Object.keys(this.state.pieData).forEach((period) => {
        pieData[period] = [
          {
            name: "Attended",
            value: this.state.pieData[period].attended,
            color: theme.palette.primary.main,
          },
          {
            name: "Missed",
            value: this.state.pieData[period].missed,
            color: theme.palette.secondary.main,
          },
        ];
      });
    }

    if (!this.props.visible) {
      return <div />;
    }

    if (this.state.isLoading) {
      return (
        <div>
          {[1, 2, 3, 4, 5].map((i) => (
            <Skeleton
              key={i}
              variant="rounded"
              width={"100%"}
              height={30}
              sx={{ mb: 2 }}
            />
          ))}
          <Stack direction="row">
            <Skeleton
              variant="rounded"
              width="50%"
              height={150}
              sx={{ mr: 1 }}
            />
            <Skeleton
              variant="rounded"
              width="50%"
              height={150}
              sx={{ ml: 1 }}
            />
          </Stack>
        </div>
      );
    }

    return (
      <>
        <DateCalendar
          readOnly
          disableHighlightToday
          slots={{ day: CustomDay }}
          slotProps={{ day: { attendances: this.state.attendances } }}
        />
        <Stack
          direction={{ xs: "column", sm: "row" }}
          justifyContent="space-evenly"
        >
          <div>
            מתחילת העונה:
            <AttendancePie data={pieData.total} />
          </div>
          <div>
            חודש אחרון:
            <AttendancePie data={pieData.lastMonth} />
          </div>
        </Stack>
      </>
    );
  }
}
