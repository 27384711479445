import React from "react";
import { connect } from "react-redux";

import * as Actions from "./../../store/actions";
import axios from "../../utils/axios";

class Logout extends React.Component {
  componentDidMount = async () => {
    try {
      await axios.post(`/users/${this.props.user._id}/logout`);
    } catch (error) {
      console.error("logout failed");
    }

    this.props.logout();
  };

  render() {
    return <div />;
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    logout: () => dispatch(Actions.auth.logout()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
