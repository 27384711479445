import React from "react";
import Chart from "react-apexcharts";
import moment from "moment";

export default function TeamAttendanceChart(props) {
  const series = [
    {
      name: "",
      data: [
        ...props.events.map((rec, index) => {
          let y = -1000;
          if (rec.attendance) {
            y = (rec.attendance.attended / rec.attendance.players) * 100;
          }
          return {
            event: rec.event,
            attendance: rec.attendance,
            attended: rec.attendance ? rec.attendance.attended : null,
            total: rec.attendance ? rec.attendance.players : null,
            x: index,
            y,
          };
        }),
      ],
    },
  ];

  const options = {
    chart: {
      height: 60,
      type: "heatmap",
      toolbar: {
        show: false,
      },
    },
    grid: {
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      },
    },
    yaxis: {
      labels: {
        show: false,
      },
    },
    xaxis: {
      labels: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    tooltip: {
      custom: function ({ seriesIndex, dataPointIndex, w }) {
        const { event, attended, total, attendance } = w.config.series[
          seriesIndex
        ].data[dataPointIndex];
        const att = attendance ? `${attended}/${total}` : "דוח נוכחות חסר";
        return (
          '<div class="arrow_box" style="padding:10px;">' +
          "<span>אירוע: " +
          event.title +
          "</span>" +
          "<div>תאריך: " +
          moment(event.start).format("DD/MM/YY") +
          "</div>" +
          "<div>נוכחות: " +
          att +
          "</div>" +
          "</div>"
        );
      },
    },
    colors: ["#E0E0E0"],
    stroke: {
      width: 1,
    },
    plotOptions: {
      heatmap: {
        enableShades: false,
        shadeIntensity: 1,
        colorScale: {
          inverse: true,
          ranges: [
            {
              from: 0,
              to: 60,
              color: "#d32f2f",
              inverse: true,
            },
            {
              from: 60,
              to: 80,
              color: "#f57c00",
            },
            {
              from: 80,
              to: 100,
              color: "#388e3c",
            },
          ],
        },
      },
    },
  };

  return (
    <div style={{ border: "0px solid black" }}>
      <Chart
        options={options}
        series={series}
        type="heatmap"
        height={60}
        width={props.events.length * 18}
        style={{ padding: 0, marginLeft: 10, border: "0px solid black" }}
      />
    </div>
  );
}
