import React from "react";
import { TextField } from "@mui/material";

// the PriceTextInput component has 2 problems
// 1. when value is 1.12, type 9 and the value will change to 1.13
// 2. can't enter values like xx.0x
// 3. can enter values like 12.--

// prefare priceInputNg

export default function PriceTextInput({ onChange, value, ...props }) {
  const handleChange = (e) => {
    let val = e.target.value;
    val = Math.round(val * 100) / 100;
    if (isNaN(val)) {
      val = 0;
    }
    onChange(val);
  };
  return (
    <TextField
      variant="standard"
      value={value ? value.toString() : value}
      onChange={handleChange}
      type="number"
      {...props}
    />
  );
}
