import React from "react";
import { Grid } from "@mui/material";

import SubscriptionDetailsForm from "./SubscriptionDetailsForm";
import SubscriptionCostForm from "./SubscriptionCostForm";
import StepTitle from "../StepTitle";
import { handleSubscriptionFieldsChanged } from "../../../utils/subscriptionHelpers";

export default function SubscriptionForm(props) {
  const handleFieldChange = (updates) => {
    const updatedSub = handleSubscriptionFieldsChanged(
      updates,
      props.state,
      props.subscriptionProducts,
      props.state.subLevel.teams
    );

    if (updates.teamId === "") {
      props.onRemoveSubscription();
    } else if (updates.teamId) {
      props.onNewSubscriptionProductSelected(updatedSub.product);
    } else if (updates.pricingId || updates.startDate) {
      props.onCartSubscriptionChange({
        monthlyPrices: updatedSub.monthlyPrices,
      });
    }

    props.onChange(updatedSub);
  };

  return (
    <>
      <StepTitle title="בחירת קבוצה" purchaseType={props.purchaseType} />
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <SubscriptionDetailsForm
            levels={props.levels}
            state={props.state}
            cart={props.cart}
            onChange={handleFieldChange}
            clubPlayer={props.clubPlayer}
            onCartSubscriptionChange={props.onCartSubscriptionChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SubscriptionCostForm
            state={props.state}
            hasPayments={props.hasPayments}
            cart={props.cart}
            onChange={handleFieldChange}
            onCartSubscriptionChange={props.onCartSubscriptionChange}
          />
        </Grid>
      </Grid>
    </>
  );
}
