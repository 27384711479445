import React from "react";
import { useMediaQuery } from "@mui/material";

export default function withBreakpoints(selector, Component) {
  return function Wrapper(props) {
    const matches = useMediaQuery(
      (theme) => {
        switch (selector) {
          case "mdDown":
            return theme.breakpoints.down("md");

          case "mdUp":
            return theme.breakpoints.up("md");

          case "smDown":
            return theme.breakpoints.down("sm");

          case "smUp":
            return theme.breakpoints.up("sm");

          default:
            return theme.breakpoints.down("xs");
        }
      },
      { noSsr: true }
    );

    return <Component {...props} isMatching={matches} />;
  };
}
