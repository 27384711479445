import React from "react";
import { TextField, DialogActions, DialogContent } from "@mui/material";
import validator from "validator";

import axios from "../../utils/axios";
import {
  generateErrorMessage,
  normalizeServerValidationErrors,
  validateForm,
  validateSingleField,
} from "../../lib/formHelpers";
import Dialog from "../../components/Dialog";
import ButtonSx from "../../components/base/ButtonSx";
import AutoComplete from "../../components/base/AutoComplete";

const certifications = {
  basketball: ["מאמן", "מדריך"],
  soccer: ["מאמן", "מדריך", "UEFA 1"],
  gymnastics: ["מאמן/ת", "מאמן/ת נבחרת", "מאמן/ת בכירה", "מדריך/כה"],
  default: ["מאמן", "מדריך"],
};

export default class CoachDialog extends React.Component {
  initialFormValues = {
    name: "",
    email: "",
    phone: "",
    certification: "",
    id: "",
  };

  resetForm = () => {
    const coach = { ...this.props.coach } || {};
    return {
      email: coach.email || "",
      phone: coach.phone || "",
      certification: coach.certification || "",
      name: coach.name || "",
      id: coach.id || "",
    };
  };

  state = {
    loading: false,
    instantValidate: false, // validate only after first submit try
    form: this.resetForm(),
    formErrors: {},
  };

  formSettings = {
    email: {
      validator: validator.isEmail,
      required: false,
    },
    phone: {
      validator: (phone) => validator.isMobilePhone(phone.trim(), "he-IL"),
    },
    name: {
      // isAlpha doesn't support hebrew
      validator: (name) => name.trim().length >= 5,
    },
    certification: {
      validator: (cert) => cert !== "",
    },
    id: {
      validator: (id) => validator.isIdentityCard(id, "he-IL"),
      required: false,
    },
  };

  handleFormChange = (field, event) => {
    const formErrors = validateSingleField(
      this.formSettings,
      this.initialFormValues,
      this.state.instantValidate,
      this.state.formErrors,
      field,
      event.target.value
    );

    this.setState({
      ...this.state,
      form: {
        ...this.state.form,
        [field]: event.target.value,
      },
      formErrors,
    });
  };

  handleSubmit = async () => {
    const { valid, formErrors } = validateForm(
      this.state.form,
      this.formSettings,
      this.initialFormValues
    );

    this.setState({ formErrors, instantValidate: true });

    if (valid) {
      this.setState({ loading: true });
      const data = { ...this.state.form };

      try {
        let res;
        if (this.props.coach) {
          res = await axios.patch(`/clubCoaches/${this.props.coach._id}`, data);
        } else {
          res = await axios.post("/clubCoaches/", data);
        }
        this.setState({
          loading: false,
          formErrors: {},
          form: this.resetForm(),
        });
        this.setState({ loading: false });
        this.props.onComplete(res.data);
      } catch (error) {
        //TODO: show error message if not 422
        if (
          error.response &&
          error.response.status === 422 &&
          error.response.data.errors
        ) {
          this.setState({
            formErrors: normalizeServerValidationErrors(
              error.response.data.errors
            ),
          });
        }
        this.setState({ loading: false });
      }
    }
  };

  render() {
    return (
      <Dialog
        maxWidth="xs"
        onClose={this.props.onClose}
        open={this.props.open}
        title={this.props.coach ? "עריכת פרטי מאמן" : "הוספת מאמן"}
      >
        <DialogContent dividers>
          <TextField
            variant="standard"
            required
            error={Object.prototype.hasOwnProperty.call(
              this.state.formErrors,
              "name"
            )}
            disabled={this.state.loading}
            value={this.state.form.name}
            onChange={(event) => this.handleFormChange("name", event)}
            autoFocus
            margin="dense"
            id="name"
            label="שם מלא"
            helperText={generateErrorMessage("name", this.state.formErrors)}
            fullWidth
          />

          <TextField
            variant="standard"
            error={Object.prototype.hasOwnProperty.call(
              this.state.formErrors,
              "email"
            )}
            disabled={this.state.loading}
            value={this.state.form.email}
            onChange={(event) => this.handleFormChange("email", event)}
            margin="dense"
            id="email"
            label="אימייל"
            type="email"
            helperText={generateErrorMessage("email", this.state.formErrors)}
            fullWidth
          />

          <TextField
            variant="standard"
            required
            error={Object.prototype.hasOwnProperty.call(
              this.state.formErrors,
              "phone"
            )}
            value={this.state.form.phone}
            onChange={(event) => this.handleFormChange("phone", event)}
            margin="dense"
            id="phone"
            label="טלפון"
            helperText={generateErrorMessage("phone", this.state.formErrors)}
            fullWidth
            disabled={this.props.coach?._id !== undefined}
          />

          <AutoComplete
            options={
              certifications[this.props.clubCategory] || certifications.default
            }
            value={this.state.form.certification}
            label="הסמכה"
            onChange={(newValue) =>
              this.handleFormChange("certification", {
                target: { value: newValue },
              })
            }
            onInputChange={(newValue) =>
              this.handleFormChange("certification", {
                target: { value: newValue },
              })
            }
            freeSolo
            fullWidth
            error={Object.prototype.hasOwnProperty.call(
              this.state.formErrors,
              "certification"
            )}
            textFieldParams={{
              required: true,
              helperText: generateErrorMessage(
                "certification",
                this.state.formErrors
              ),
            }}
          />

          <TextField
            variant="standard"
            error={Object.prototype.hasOwnProperty.call(
              this.state.formErrors,
              "id"
            )}
            disabled={this.state.loading}
            value={this.state.form.id}
            onChange={(event) => this.handleFormChange("id", event)}
            autoFocus
            margin="dense"
            id="id"
            label="ת.ז"
            helperText={generateErrorMessage("id", this.state.formErrors)}
            fullWidth
          />
        </DialogContent>

        <DialogActions>
          <ButtonSx
            onClick={() => {
              this.setState({
                formErrors: {},
                form: this.resetForm(),
              });
              this.props.onClose();
            }}
            disabled={this.state.loading}
            sx={{ width: 100 }}
          >
            ביטול
          </ButtonSx>
          <div>
            <ButtonSx
              isLoading={this.state.loading}
              onClick={this.handleSubmit}
              debounce
              sx={{ width: 100 }}
            >
              אישור
            </ButtonSx>
          </div>
        </DialogActions>
      </Dialog>
    );
  }
}
