import _ from "lodash";
import { roundPrice } from "../../../utils/helpers";

export const init = () => ({
  subscription: null,
  merchandise: {},
  totals: {
    costs: {
      merchandise: 0,
      subscription: 0,
      fees: 0,
      subscriptionPlusFees: 0,
    },
    paymentAmounts: {
      merchandise: 0,
      subscription: 0,
      fees: 0,
      subscriptionPlusFees: 0,
    },
    totalCost: 0,
    totalPaymentAmounts: 0,
  },
});

const cloneCart = (cart) => {
  return {
    ...cart,
    merchandise: { ...cart.merchandise },
    subscription: cart.subscription ? { ...cart.subscription } : null,
  };
};

export const calcMerchandiseTotal = (cart) => {
  return roundPrice(
    _.sum(Object.values(cart.merchandise).map((p) => p.price * p.quantity))
  );
};

// type: price OR paymentAmount
const calcFeesTotal = (cart, type) => {
  return (
    roundPrice(_.sum(cart.subscription?.fees.map((fee) => fee[type]))) || 0
  );
};

export const handleMerchandiseChange = (
  prevCart,
  product,
  price,
  clubPlayer,
  optionId,
  quantityDiff
) => {
  const cart = cloneCart(prevCart);
  const recordKey = `${product._id}||${clubPlayer._id}||${optionId}||${price}`;
  let record = cart.merchandise[recordKey];

  if (!record) {
    record = {
      quantity: 0,
      product,
      clubPlayer,
      optionId,
      optionTitle: product.options.find((o) => o._id === optionId)?.title || "",
      price,
    };
    cart.merchandise[recordKey] = record;
  }

  record.quantity += quantityDiff;
  if (record.quantity === 0) {
    delete cart.merchandise[recordKey];
  }

  calcTotals(cart);
  return cart;
};

const calcTotals = (cart) => {
  cart.totals = {
    costs: {
      subscription: cart.subscription?.price || 0,
      fees: calcFeesTotal(cart, "price"),
      merchandise: calcMerchandiseTotal(cart),
    },
    paymentAmounts: {
      subscription: cart.subscription?.paymentAmount || 0,
      fees: calcFeesTotal(cart, "paymentAmount"),
      merchandise: calcMerchandiseTotal(cart),
    },
  };

  cart.totals.costs.subscriptionPlusFees = roundPrice(
    cart.totals.costs.subscription + cart.totals.costs.fees
  );

  cart.totals.totalCost = roundPrice(
    cart.totals.costs.merchandise +
      cart.totals.costs.subscription +
      cart.totals.costs.fees
  );

  cart.totals.paymentAmounts.subscriptionPlusFees = roundPrice(
    cart.totals.paymentAmounts.subscription + cart.totals.paymentAmounts.fees
  );

  cart.totals.totalPaymentAmounts = roundPrice(
    cart.totals.paymentAmounts.merchandise +
      cart.totals.paymentAmounts.subscription +
      cart.totals.paymentAmounts.fees
  );
};

export const removeSubscription = (prevCart) => {
  const cart = cloneCart(prevCart);
  cart.subscription = null;
  calcTotals(cart);
  return cart;
};

export const setSubscription = (prevCart, product) => {
  const cart = cloneCart(prevCart);

  if (product._id === "external") {
    cart.subscription = {
      product,
      price: 0,
      paymentAmount: 0,
      fees: [],
    };
  } else {
    const fees = product.subProducts?.map((feeProduct) => {
      return {
        product: feeProduct,
        price: feeProduct.price,
        paymentAmount: feeProduct.price,
      };
    });

    cart.subscription = {
      product,
      price: 0,
      // every time the subscription is changes we reset the current payment amount
      paymentAmount: 0,
      fees,
    };
  }

  calcTotals(cart);
  return cart;
};

export const updateSubscriptionFields = (prevCart, updates) => {
  const cart = cloneCart(prevCart);
  if (Object.prototype.hasOwnProperty.call(updates, "monthlyPrices")) {
    cart.subscription.price = roundPrice(
      _.sumBy(updates.monthlyPrices, "price")
    );
  }

  if (Object.prototype.hasOwnProperty.call(updates, "fees")) {
    for (let index = 0; index < cart.subscription.fees.length; index++) {
      const cartFee = cart.subscription.fees[index];
      if (cartFee.product._id !== updates.fees[index].feeProductId) {
        throw new Error("fee doesnt match");
      }
      cart.subscription.fees[index].price = updates.fees[index].price;
      cart.subscription.fees[index].paymentAmount = updates.fees[index].price;
    }
  }

  // when updating any of the prices we have to reset ALL prices payment amount.
  // in case of doing a partial payment and then going back and chaning the prices we must reset the partial payment
  if (
    Object.prototype.hasOwnProperty.call(updates, "monthlyPrices") ||
    Object.prototype.hasOwnProperty.call(updates, "fees")
  ) {
    cart.subscription.paymentAmount = cart.subscription.price;
    for (let index = 0; index < cart.subscription.fees.length; index++) {
      cart.subscription.fees[index].paymentAmount =
        cart.subscription.fees[index].price;
    }
  }

  calcTotals(cart);
  return cart;
};

export const handleSubscriptionPartialPayment = (prevCart, paymentAmount) => {
  const cart = cloneCart(prevCart);

  let amountLeft = paymentAmount;
  cart.subscription.paymentAmount = paymentAmount;
  cart.subscription.fees.forEach((fee) => {
    fee.paymentAmount = Math.min(amountLeft, fee.price);
    amountLeft -= fee.paymentAmount;
  });

  cart.subscription.paymentAmount = amountLeft;
  calcTotals(cart);

  return cart;
};

export const removeOTP = (prevCart, otpId) => {
  const cart = cloneCart(prevCart);

  cart.merchandise = Object.fromEntries(
    Object.entries(cart.merchandise).filter(
      ([, data]) => data.product._id !== otpId
    )
  );

  calcTotals(cart);
  return cart;
};
