import React from "react";
import { Link as RouterLink } from "react-router-dom";
import moment from "moment";
import { Link, Skeleton } from "@mui/material";

import EnhancedTable from "../../components/table/EnhancedTable";
import ActionSelect from "../../components/ActionSelect";
import InfoRow from "../../components/InfoRows/InfoRow";
import TlPaper from "../../components/TlPaper";

export default function TeamPlayers(props) {
  const { players } = props;

  const columns = [
    { id: "name", label: "שם" },
    { id: "phone", label: "טלפון" },
    { id: "trialAtttendances", label: "שיעורי ניסיון" },
    // { id: "actions", label: "פעולות", width: 100 },
  ];

  const sortValueCell = (columnId, player) => {
    switch (columnId) {
      case "team":
        return player.team.name;

      case "notifications":
        return player.notifications.length;

      case "status":
        return player.status === "trial" ? "ניסיון" : "רשום";

      default:
        return player[columnId];
    }
  };

  const renderActions = () => {
    return (
      <ActionSelect
        title="פעולות"
        //onAction={(action) => this.handleCheckAction(action, check)}
        options={[
          { title: "רישום לקבוצה", value: "registration" },
          { title: "מחיקה", value: "delete" },
        ]}
      />
    );
  };

  const renderCell = (columnId, player) => {
    switch (columnId) {
      case "name":
        return (
          <Link
            component={RouterLink}
            color="primary"
            to={`../players/${player._id}`}
            underline="hover"
          >
            {player.name}
          </Link>
        );

      case "team":
        return player.team.name;

      case "dateOfBirth":
        return player.dateOfBirth
          ? moment(player.dateOfBirth).format("DD/MM/YYYY")
          : "";

      case "status":
        return player.status === "trial" ? "ניסיון" : "רשום";

      case "actions":
        return renderActions(player);

      case "trialAtttendances":
        return player.trialInfo ? player.trialInfo.attendances || 0 : 0;

      case "phone":
        return player.trialInfo.phone;

      default:
        // const field = tableHeaders[columnId].field || tableHeaders[columnId].id;
        return player[columnId];
    }
  };

  const renderContent = () => (
    <>
      <InfoRow title="מספר ספורטאים" value={players.length || 0} />
      <div style={{ height: "10px" }} />

      {players.length > 0 && (
        <EnhancedTable
          size="small"
          headers={columns}
          renderCell={renderCell}
          sortValueCell={sortValueCell}
          rows={props.players}
          pagination={props.pagination}
        />
      )}
    </>
  );

  const renderLoading = () => (
    <div>
      <Skeleton animation="wave" height={30} width={160} />
      <Skeleton animation="wave" height={40} />
      <Skeleton animation="wave" height={25} />
      <Skeleton animation="wave" height={25} />
      <Skeleton animation="wave" height={25} />
      <Skeleton animation="wave" height={25} />
    </div>
  );

  return (
    <TlPaper
      title="ספורטאים לא רשומים"
      // isLoading={props.isLoading}
      titleBackground
      noMinHeight
    >
      {props.isLoading ? renderLoading() : renderContent()}
    </TlPaper>
  );
}
