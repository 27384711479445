import React, { useState } from "react";

import InfoRow from "./InfoRow";
import ButtonSx from "../base/ButtonSx";

export default function InfoRowDownload(props) {
  const [downloading, setDownloading] = useState(false);

  const download = async () => {
    setDownloading(true);
    await props.onDownload();
    setDownloading(false);
  };

  const value = (
    <ButtonSx isLoading={downloading} debounce onClick={download}>
      {props.downloadText}
    </ButtonSx>
  );

  return <InfoRow title={props.title} value={value} titleSx={props.titleSx} />;
}
