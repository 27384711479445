import React from "react";
import NextIcon from "@mui/icons-material/NavigateNext";
import PrevIcon from "@mui/icons-material/NavigateBefore";
import { Grid, Typography, IconButton, useMediaQuery } from "@mui/material";

import WeekStatus from "../../containers/Calendar/WeekStatus";
import UnsentNotifications from "../../containers/Calendar/Notifications/UnsentNotifications";
import UnseenUpdates from "../../containers/Calendar/Notifications/UnseenUpdates";
import CalendarSettings from "../../containers/Calendar/Settings/CalendarSettings";
import ToolbarFilters from "./ToolbarFilters";
import ButtonSx from "../base/ButtonSx";

export default function Toolbar(props) {
  const isXl = useMediaQuery((t) => t.breakpoints.up("xl"), {
    noSsr: true,
  });

  const renderDate = () => {
    let text;
    if (props.view === "day") {
      text = `יום ${props.date.format("dddd, ll")}`;
    } else {
      text = `${props.dateRange.start.format(
        "ll"
      )} - ${props.dateRange.end.format("ll")}`;
    }

    return text;
  };

  const renderNavigation = () => {
    return (
      <div>
        <IconButton
          aria-label="Prev"
          onClick={() => props.onDateChanged("prev")}
          size="large"
        >
          <NextIcon />
        </IconButton>

        <IconButton
          aria-label="Next"
          onClick={() => props.onDateChanged("next")}
          size="large"
        >
          <PrevIcon />
        </IconButton>
        {isXl && (
          <ButtonSx
            onClick={() => props.onDateChanged("today")}
            sx={styles.field}
          >
            {props.view === "day" ? "היום" : "השבוע"}
          </ButtonSx>
        )}
      </div>
    );
  };

  return (
    <Grid container sx={styles.container} spacing={1}>
      <Grid
        item
        xs={12}
        container
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={3} sx={styles.rightSide}>
          {renderNavigation()}
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h5" sx={styles.center}>
            {renderDate()}
          </Typography>
        </Grid>
        <Grid item xs={3} sx={styles.leftSide}>
          {props.admin && (
            <>
              <WeekStatus {...props.weekStatusProps} />
              <div style={{ width: 10 }} />
              <UnseenUpdates {...props.unreadUpdatesProps} />
              <div style={{ width: 10 }} />
              <UnsentNotifications {...props.unsentNotificationsProps} />

              <div style={{ width: 10 }} />
              <CalendarSettings {...props.calendarSettingsProps} />
            </>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12} container spacing={1} style={{ marginBottom: 10 }}>
        <ToolbarFilters
          view={props.view}
          resourceView={props.resourceView}
          weekViewType={props.weekViewType}
          resources={props.resources}
          onViewChanged={props.onViewChanged}
          onResourceViewChanged={props.onResourceViewChanged}
          eventTypesProps={props.eventTypesProps}
          clubCoachesProps={props.clubCoachesProps}
          teamsProps={props.teamsProps}
          resourcesProps={props.resourcesProps}
          onWeekViewTypeChanged={props.onWeekViewTypeChanged}
        />
      </Grid>
    </Grid>
  );
}

const styles = {
  container: {
    paddingLeft: "20px",
    paddingRight: "20px",
    width: "100%",
  },
  selected: {
    backgroundColor: "#EFEFEF",
  },
  field: {
    width: 140,
  },
  viewSelect: {
    marginRight: "20px",
    height: 35,
    fontSize: "0.875rem",
  },
  leftSide: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  rightSide: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};
