import React from "react";
import { Box } from "@mui/material";
import { AdvancedImage } from "@cloudinary/react";
import { cloudinaryImage } from "../../utils/cloudinary";
import Rtl from "../../layout/Rtl";

export default function AuthLayout(props) {
  const img = cloudinaryImage("/general/TrainLitLogo", { size: "original" });
  return (
    <Rtl>
      <Box dir="rtl">
        <Box sx={styles.logoContainer}>
          <AdvancedImage
            cldImg={img}
            style={{ margin: 5, width: 400 }}
            alt="Logo"
          />
        </Box>
        {props.children}
      </Box>
    </Rtl>
  );
}

const styles = {
  logoContainer: {
    margin: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};
