import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import moment from "moment";
import ReactGA from "react-ga4";
import { Select } from "@mui/material";

import axios from "../../../utils/axios";
import InfoRow from "../../../components/InfoRows/InfoRow";
import MenuItem from "../../../components/base/MenuItem";
import clubsConfig from "../../../config/clubs.js";
import InfoRowDownload from "../../../components/InfoRows/InfoRowDownload";
import { Browser } from "../../../utils";
import * as Actions from "../../../store/actions";
import BooleanSetting from "./BooleanSetting";
import ThemeSettings from "./ThemeSettings";
import LogoSettings from "./LogoSettings";
import SnackbarNg from "../../../components/base/SnackbarNg.js";
import TlPaper from "../../../components/TlPaper.js";

function GeneralSettings(props) {
  const clubConfig = clubsConfig[props.selectedClub.internalName] || {};
  const paymentsConfig = clubConfig.payments;
  const [dashboardTransactionStartDate, setDashboardTransactionStartDate] =
    useState(null);
  const [snackbar, setSnackbar] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [remoteClubConfig, setRemoteClubConfig] = useState(null);

  useEffect(() => {
    setDashboardTransactionStartDate(
      moment(props.selectedClub.clubConfig.dashboardTransactionStartDate)
    );
  }, [props.selectedClub]);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/settings/generalSettings",
      title: "General Settings",
    });
  }, []);

  const loadData = async () => {
    setIsLoading(true);
    const res = await axios.get(`/clubConfigs/${props.selectedClub._id}`);
    setRemoteClubConfig(res.data);
    setIsLoading(false);
  };

  React.useEffect(() => {
    loadData();
  }, []);

  const downloadCustomerFile = (level) => async () => {
    const res = await axios.get(`/reports/customers?level=${level}`, {
      responseType: "blob",
    });
    const prefix = level === "team" ? "ספורטאים ברמה קבוצתית" : "ספורטאים";
    const fileName = `${prefix} ${moment().format("DD-MM-YY")}.xlsx`;

    Browser.download(
      window.URL.createObjectURL(new Blob([res.data])),
      fileName
    );
  };

  const updateDashboardTransactionDate = async (date) => {
    setDashboardTransactionStartDate(date);
    props.updateClubConfig(props.selectedClub.internalName, {
      ...props.selectedClub.clubConfig,
      dashboardTransactionStartDate: date.format(),
    });
    await axios.put(
      `/clubs/${props.selectedClub.internalName}/dashboardTransactionStartDate`,
      { date: date.toISOString() }
    );
  };

  const handleLogoChanged = async (selectedFile) => {
    setIsLoading(true);
    const formData = new FormData();

    formData.append("logo", selectedFile);

    await axios.patch(`/clubConfigs/${props.selectedClub._id}/logo`, formData);
    loadData();
    setSnackbar({ key: moment().format(), type: "success" });
  };

  const handleConfigChanged = async (
    configKey,
    value,
    updatedConfig,
    shouldUpdateStoreConfig = true
  ) => {
    try {
      await axios.patch(`/clubConfigs/${props.selectedClub._id}`, {
        [configKey]: value,
      });
      setSnackbar({ key: moment().format(), type: "success" });
      setRemoteClubConfig({ ...remoteClubConfig, ...updatedConfig });

      if (shouldUpdateStoreConfig) {
        props.updateClubConfig(props.selectedClub.internalName, {
          ...props.clubConfig,
          ...updatedConfig,
        });
      }
    } catch (error) {
      setSnackbar({ key: moment().format(), type: "error" });
    }
  };

  return (
    <TlPaper title="הגדרות כלליות" noMinHeight titleBackground>
      <InfoRow
        title="אתר הורים"
        value={`https://clubs.trainlitapp.com/clubs/${props.selectedClub.internalName}`}
        linkTo={`https://clubs.trainlitapp.com/clubs/${props.selectedClub.internalName}`}
        externalLink
        target="_blank"
        titleSx={styles.infoRowTitle}
      />
      <InfoRow
        title="מודול תשלומים"
        value={paymentsConfig && paymentsConfig.enabled ? "פעיל" : "לא פעיל"}
        externalLink
        target="_blank"
        titleSx={styles.infoRowTitle}
      />
      <InfoRowDownload
        title="דו״ח פרטי ספורטאים - כללי"
        downloadText="הורדת נתונים"
        onDownload={downloadCustomerFile("all")}
        titleSx={styles.infoRowTitle}
        info="יצוא קובץ המכיל את פרטי כל הספורטאים למעט ספורטאי ניסיון"
      />
      <InfoRowDownload
        title="דו״ח פרטי ספורטאים - לפי קבוצות"
        downloadText="הורדת נתונים"
        onDownload={downloadCustomerFile("team")}
        titleSx={styles.infoRowTitle}
        info="יצוא קובץ המכיל את פרטי כל הספורטאים הפעילים. במידה וספורטאי רשום ליותר מקבוצה אחת, תופיע שורה עבור כל אחת מהקבוצות"
      />
      <InfoRow
        title="דשבורד / סה״כ עסקאות - תאריך התחלה"
        displayValue={(val) => (val ? val.format("LL") : "")}
        value={dashboardTransactionStartDate}
        editType="date"
        editable
        onChange={(val) => updateDashboardTransactionDate(val)}
        titleSx={styles.infoRowTitle}
        contentSx={{ flex: null }}
      />
      {props.user.email.match(/.*@trainlitapp.com/g) && (
        <>
          <InfoRow
            title="בקשות מיוחדות"
            value={
              <BooleanSetting
                checked={props.clubConfig.general.discountRequests}
                onChange={(e) => {
                  handleConfigChanged("discountRequests", e.target.checked, {
                    general: {
                      ...props.clubConfig.general,
                      discountRequests: e.target.checked,
                    },
                  });
                }}
              />
            }
            titleSx={styles.infoRowTitle}
          />
          <InfoRow
            title="חנות (צד הורים)"
            value={
              <BooleanSetting
                checked={props.clubConfig.parentsConfig.store}
                onChange={(e) => {
                  handleConfigChanged("parents.store", e.target.checked, {
                    parentsConfig: {
                      ...props.clubConfig.parentsConfig,
                      store: e.target.checked,
                    },
                  });
                }}
              />
            }
            titleSx={styles.infoRowTitle}
          />
          <InfoRow
            title="רכישת מוצרים כאורח"
            value={
              <BooleanSetting
                checked={props.clubConfig.general.guestMerchandise}
                onChange={(e) => {
                  handleConfigChanged("guestMerchandise", e.target.checked, {
                    general: {
                      ...props.clubConfig.general,
                      guestMerchandise: e.target.checked,
                    },
                  });
                }}
              />
            }
            titleSx={styles.infoRowTitle}
          />

          <InfoRow
            title="דרישות תשלום"
            value={
              <BooleanSetting
                checked={props.clubConfig.general.dealReceipts}
                onChange={(e) => {
                  handleConfigChanged("dealReceipts", e.target.checked, {
                    general: {
                      ...props.clubConfig.general,
                      dealReceipts: e.target.checked,
                    },
                  });
                }}
              />
            }
            titleSx={styles.infoRowTitle}
          />

          <InfoRow
            title="ייצוא הפקדות לחשבשבת"
            value={
              <BooleanSetting
                checked={props.clubConfig.general.exportDepositsToHRP}
                onChange={(e) => {
                  handleConfigChanged("exportDepositsToHRP", e.target.checked, {
                    general: {
                      ...props.clubConfig.general,
                      exportDepositsToHRP: e.target.checked,
                    },
                  });
                }}
              />
            }
            titleSx={styles.infoRowTitle}
          />

          <InfoRow
            title="אתר הורים"
            value={
              <BooleanSetting
                checked={!props.clubConfig.general.disabled}
                onChange={(e) => {
                  handleConfigChanged("disabled", !e.target.checked, {
                    general: {
                      ...props.clubConfig.general,
                      disabled: !e.target.checked,
                    },
                  });
                }}
              />
            }
            titleSx={styles.infoRowTitle}
          />

          <InfoRow
            title="אמצעי תשלום"
            value={
              <Select
                variant="standard"
                value={props.clubConfig.parentsConfig.paymentMethods}
                onChange={(e) => {
                  handleConfigChanged(
                    "parents.paymentMethods",
                    e.target.value,
                    {
                      parentsConfig: {
                        ...props.clubConfig.parentsConfig,
                        paymentMethods: e.target.value,
                      },
                    }
                  );
                }}
              >
                <MenuItem value="all">אשראי, מזומן וצ׳קים</MenuItem>
                <MenuItem value="cc">אשראי</MenuItem>
                <MenuItem value="other">מזומן וצ׳קים</MenuItem>
                <MenuItem value="none">ללא תשלומים</MenuItem>
              </Select>
            }
            titleSx={styles.infoRowTitle}
          />

          <InfoRow
            title="שליחת אימייל לבקשות מיוחדות"
            value={
              <BooleanSetting
                checked={remoteClubConfig?.server.specialRequestEmails || false}
                onChange={(e) => {
                  handleConfigChanged(
                    "server.specialRequestEmails",
                    e.target.checked,
                    {
                      server: {
                        ...props.clubConfig.server,
                        specialRequestEmails: e.target.checked,
                      },
                    }
                  );
                }}
              />
            }
            titleSx={styles.infoRowTitle}
          />

          <InfoRow
            title="התראת חוב אוטומטית למאמנים"
            value={
              <BooleanSetting
                checked={props.clubConfig.general.autoDebtAlert}
                onChange={(e) => {
                  handleConfigChanged("autoDebtAlert", e.target.checked, {
                    general: {
                      ...props.clubConfig.general,
                      autoDebtAlert: e.target.checked,
                    },
                  });
                }}
              />
            }
            titleSx={styles.infoRowTitle}
          />

          <InfoRow
            title="החזר חלקי למוצרים"
            value={
              <BooleanSetting
                checked={
                  remoteClubConfig?.admin.partialMerchandiseRefunds || false
                }
                onChange={(e) => {
                  handleConfigChanged(
                    "admin.partialMerchandiseRefunds",
                    e.target.checked,
                    {
                      admin: {
                        ...remoteClubConfig.admin,
                        partialMerchandiseRefunds: e.target.checked,
                      },
                    }
                  );
                }}
              />
            }
            titleSx={styles.infoRowTitle}
          />

          <InfoRow
            title="קבלות על תרומה"
            value={
              <BooleanSetting
                checked={remoteClubConfig?.admin.donationReceipts || false}
                onChange={(e) => {
                  handleConfigChanged(
                    "admin.donationReceipts",
                    e.target.checked,
                    {
                      admin: {
                        ...remoteClubConfig.admin,
                        donationReceipts: e.target.checked,
                      },
                    }
                  );
                }}
              />
            }
            titleSx={styles.infoRowTitle}
          />

          <InfoRow
            title="החזרי אשראי בתשלומים"
            value={
              <BooleanSetting
                checked={
                  remoteClubConfig?.admin.creditCardRefundWithPayments || false
                }
                onChange={(e) => {
                  handleConfigChanged(
                    "admin.creditCardRefundWithPayments",
                    e.target.checked,
                    {
                      admin: {
                        ...remoteClubConfig.admin,
                        creditCardRefundWithPayments: e.target.checked,
                      },
                    }
                  );
                }}
              />
            }
            titleSx={styles.infoRowTitle}
          />

          <InfoRow
            title="הגדרות עונה"
            value={
              <BooleanSetting
                checked={remoteClubConfig?.admin.seasonsConfig || false}
                onChange={(e) => {
                  handleConfigChanged("admin.seasonsConfig", e.target.checked, {
                    admin: {
                      ...remoteClubConfig.admin,
                      seasonsConfig: e.target.checked,
                    },
                  });
                }}
              />
            }
            titleSx={styles.infoRowTitle}
          />

          <InfoRow
            title="רישום עם דרכון"
            value={
              <BooleanSetting
                checked={
                  remoteClubConfig?.admin.registrationWithPassport || false
                }
                onChange={(e) => {
                  handleConfigChanged(
                    "admin.registrationWithPassport",
                    e.target.checked,
                    {
                      admin: {
                        ...remoteClubConfig.admin,
                        registrationWithPassport: e.target.checked,
                      },
                    }
                  );
                }}
              />
            }
            titleSx={styles.infoRowTitle}
          />

          <InfoRow
            title="שם המועדון / ענף"
            value={remoteClubConfig?.general.name || ""}
            editType="text"
            editable
            editableComponentProps={{
              sx: { width: 300 },
              inputProps: { maxLength: 35 },
            }}
            titleSx={styles.infoRowTitle}
            contentSx={{ flex: null }}
            onChange={(value) => {
              handleConfigChanged("general.name", value, {
                general: {
                  ...remoteClubConfig.general,
                  name: value,
                },
              });
            }}
          />

          <InfoRow
            title="שם המועדון / ענף (עד 25 תווים)"
            value={remoteClubConfig?.general.shortName || ""}
            editType="text"
            editable
            editableComponentProps={{
              sx: { width: 300 },
              inputProps: { maxLength: 25 },
            }}
            titleSx={styles.infoRowTitle}
            contentSx={{ flex: null }}
            onChange={(value) => {
              handleConfigChanged("general.shortName", value, {
                general: {
                  ...remoteClubConfig.general,
                  shortName: value,
                },
              });
            }}
          />

          {props.user.email.match(/^.*@trainlitapp.com$/g) && (
            <ThemeSettings
              onChange={handleConfigChanged}
              clubConfig={remoteClubConfig}
              isLoading={isLoading}
            />
          )}
          {props.user.email.match(/^gilad.*@trainlitapp.com$/g) && (
            <LogoSettings
              oldClubConfig={clubConfig}
              clubConfig={remoteClubConfig}
              isLoading={isLoading}
              onChange={handleLogoChanged}
            />
          )}
        </>
      )}
      <SnackbarNg
        message={
          snackbar?.type === "success" ? "העדכון בוצע בהצלחה" : "ארעה שגיאה"
        }
        severity={snackbar?.type}
        open={snackbar !== null}
        onClose={() => setSnackbar(null)}
        snackKey={snackbar?.key}
      />
    </TlPaper>
  );
}

const styles = {
  infoRowTitle: {
    width: 300,
  },
};

function mapStateToProps(state) {
  return {
    user: state.auth.user,
    selectedClub: state.auth.selectedClub,
    clubConfig: state.auth.selectedClub.clubConfig,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateClubConfig: (clubInternalName, clubConfig) =>
      dispatch(Actions.auth.updateClubConfig(clubInternalName, clubConfig)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(GeneralSettings);
