import React from "react";

import { Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import moment from "moment";

import EnhancedTable from "../../components/table/EnhancedTable";
import axios from "../../utils/axios";
import TlPaper from "../../components/TlPaper";

const columns = [
  { id: "player", label: "ספורטאי" },
  { id: "team", label: "קבוצה" },
  { id: "createdAt", label: "תאריך הרשמה" },
];

export default class LatestSubscriptions extends React.Component {
  state = { subscriptions: [] };

  componentDidMount = async () => {
    this.setState({ isLoading: true });
    const response = await axios.get(`/store/subscriptions/lastX`);
    this.setState({ subscriptions: response.data, isLoading: false });
  };

  renderCell = (columnId, subscription) => {
    switch (columnId) {
      case "createdAt":
        return moment(subscription.createdAt).format("DD/MM/YY LT");

      case "type":
        return subscription.type === "direct" ? "טריינליט" : "חיצוני";

      case "player":
        return (
          <Link
            component={RouterLink}
            color="primary"
            to={`players/${subscription.clubPlayer._id}`}
            underline="hover"
          >
            {subscription.clubPlayer.name}
          </Link>
        );

      case "team":
        return (
          <Link
            component={RouterLink}
            color="primary"
            to={`teams/${subscription.team._id}`}
            underline="hover"
          >
            {subscription.team.name}
          </Link>
        );

      default:
        return subscription[columnId];
    }
  };

  render() {
    return (
      <TlPaper title="נרשמים אחרונים" titleBackground sx={{ height: "100%" }}>
        <EnhancedTable
          headers={columns}
          renderCell={this.renderCell}
          rows={this.state.subscriptions}
          sort={false}
        />
      </TlPaper>
    );
  }
}
