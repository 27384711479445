const initialState = {
  user: null,
  token: null,
  loading: true,
  gentleClubChange: false,
  selectedClub: null,
  clubs: null,
  clubsHash: {},
  superClub: null,
  errors: {},
};

const auth = (state = initialState, action) => {
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        user: action.user,
        token: action.token,
      };

    case "LOGOUT":
      return {
        ...state,
        user: null,
        token: null,
        loading: false,
        selectedClub: null,
        clubs: null,
        clubsHash: null,
        superClub: null,
        errors: {},
      };

    case "STATUS":
      return {
        ...state,
        loading: action.loading,
      };

    case "SET_GENTLE_CLUB_CHANGE":
      return {
        ...state,
        gentleClubChange: action.gentleClubChange,
      };

    case "CHANGE_CLUB":
      return {
        ...state,
        selectedClub: action.club,
      };

    case "SET_CLUBS":
      return {
        ...state,
        clubs: action.clubs,
        superClub: action.superClub,
        clubsHash: action.clubsHash,
      };

    case "SET_ERROR":
      return {
        ...state,
        errors: { ...state.errors, [action.errorType]: action.error },
      };

    default:
      return state;
  }
};

export default auth;
