import React, { useState, useEffect } from "react";
import { TextField, Stack } from "@mui/material";

import axios from "../../utils/axios";
import EditFab from "../../components/EditFab";
import ButtonSx from "../../components/base/ButtonSx";
import TlPaper from "../../components/TlPaper";

export default function PlayerComments({
  clubPlayer,
  onUpdate,
  hasHrPlayerParentAdminPermission,
}) {
  const [currentComment, setCurrentComment] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    setCurrentComment(clubPlayer.managerComments || "");
  }, []);

  const handleCancelEditing = () => {
    setCurrentComment(clubPlayer.managerComments || "");
    setIsEditing(false);
  };

  const handleSaveChanges = async () => {
    setIsSaving(true);
    await axios.patch(`/clubPlayers/ng/${clubPlayer._id}`, {
      managerComments: currentComment,
    });
    setIsSaving(false);
    setIsEditing(false);
    onUpdate();
  };

  const renderNoEdit = () => {
    return (
      <div style={{ marginBottom: 50 }}>
        <TextField
          variant="standard"
          multiline
          value={currentComment || "לא קיימות הערות"}
          sx={styles.editTextField}
          fullWidth
          InputProps={{ disableUnderline: true, readOnly: true }}
        />

        {hasHrPlayerParentAdminPermission && (
          <EditFab title="עריכה" onClick={() => setIsEditing(true)} />
        )}
      </div>
    );
  };

  const renderEdit = () => {
    return (
      <div>
        <TextField
          placeholder="רישמו כאן את הערות הספורטאי"
          multiline
          minRows={6}
          value={currentComment}
          onChange={(e) => setCurrentComment(e.target.value)}
          sx={{ ...styles.editTextField, borderColor: "#1876d3" }}
          fullWidth
          disabled={isSaving}
        />
        <Stack
          direction="row"
          spacing={1}
          justifyContent="flex-end"
          sx={{ mt: 1 }}
        >
          <ButtonSx
            sx={{ width: 90 }}
            onClick={handleCancelEditing}
            disabled={isSaving}
          >
            ביטול
          </ButtonSx>
          <ButtonSx
            sx={{ width: 90 }}
            onClick={handleSaveChanges}
            isLoading={isSaving}
            debounce
          >
            שמירה
          </ButtonSx>
        </Stack>
      </div>
    );
  };

  return (
    <TlPaper title="הערות ספורטאי" titleBackground collapsible noMinHeight>
      {isEditing ? renderEdit() : renderNoEdit()}
    </TlPaper>
  );
}

const styles = {
  editTextField: {
    border: "0px solid #efefef",
    borderRadius: "5px",
    px: "3px",
    "& .Mui-disabled": {
      color: "black",
    },
  },
};
