import React, { useState } from "react";
import { CircularProgress, Stack } from "@mui/material";

import InfoRow from "../InfoRows/InfoRow";
import PaperHeaderButton from "../PaperHeaderButton";
import EnhancedTable from "./EnhancedTable";

export default function Table(props) {
  const [isExporting, setIsExporting] = useState(false);
  const handleExport = async () => {
    setIsExporting(true);
    await props.onExport();
    setIsExporting(false);
  };

  return (
    <div>
      <Stack direction="row" justifyContent="space-between">
        {!props.noHeader && (
          <>
            {props.title ? (
              <InfoRow
                title={`מספר ${props.title}:`}
                value={props.rows.length}
                titleSx={{ width: 120 }}
              />
            ) : (
              <span />
            )}
            <div>
              {props.customHeaderItems}
              {props.onAdd && (
                <PaperHeaderButton
                  onClick={props.onAdd}
                  type="add"
                  title="הוספה"
                  disabled={props.isLoading}
                />
              )}
              {props.onExport &&
                (isExporting ? (
                  <CircularProgress size={24} />
                ) : (
                  <PaperHeaderButton
                    onClick={handleExport}
                    type="export"
                    disabled={props.isLoading}
                  />
                ))}
              {props.onSettings && (
                <PaperHeaderButton
                  onClick={props.onSettings}
                  type="settings"
                  disabled={props.isLoading}
                />
              )}
            </div>
          </>
        )}
      </Stack>
      <EnhancedTable
        size="small"
        headers={props.columns}
        renderCell={props.renderCell}
        sortValueCell={props.sortValueCell}
        rows={props.rows}
        pagination={props.pagination}
        CollapsableComponent={props.CollapsableComponent}
        collapsableComponentProps={props.collapsableComponentProps}
        defaultSortBy={props.defaultSortBy}
        defaultSortDirection={props.defaultSortDirection}
        renderKey={props.renderKey}
        sort={props.sort}
        selected={props.selected}
        onRowClick={props.onRowClick}
        stickyHeader={props.stickyHeader}
      />
    </div>
  );
}
