import React from "react";
import moment from "moment";
import { Grid } from "@mui/material";

import MenuItem from "../../../../components/base/MenuItem";

export const maxPeriod = 12;

export const renderDateOptions = (startMonth, total, type) => {
  const items = [];
  for (let index = 0; index < total; index++) {
    const month = moment(startMonth);
    month.add(index, "month");
    if (type === "start") {
      month.startOf("month");
    } else {
      month.endOf("month");
    }
    items.push(
      <MenuItem key={index} value={month.format("YYYY-MM-DD")}>
        <Grid container spacing={1} dir="rtl">
          <Grid item>{month.format("MM/YY")}</Grid>
          {type === "end" && <Grid item>({index + 1} חודשים)</Grid>}
        </Grid>
      </MenuItem>
    );
  }
  return items;
};

export const validate = (
  subscriptionProduct,
  requireAccountingExportNumber
) => {
  let isValid = true;
  const errors = {};

  if (!subscriptionProduct.subscriptionType) {
    errors.subscriptionType = "שדה חובה";
    isValid = false;
  }

  if (!subscriptionProduct.season) {
    errors.season = "שדה חובה";
    isValid = false;
  }

  if (requireAccountingExportNumber) {
    if (!subscriptionProduct.accountingExportNumber) {
      errors.accountingExportNumber = "שדה חובה";
      isValid = false;
    } else if (subscriptionProduct.accountingExportNumber.length < 3) {
      errors.accountingExportNumber = "שדה לא תקין";
      isValid = false;
    }
  }

  if (subscriptionProduct.title.length < 2) {
    errors.title = "שדה חובה";
    isValid = false;
  }

  if (!subscriptionProduct.startDate) {
    errors.startDate = "שדה חובה";
    isValid = false;
  }

  if (!subscriptionProduct.endDate) {
    errors.endDate = "שדה חובה";
    isValid = false;
  }

  if (
    moment(subscriptionProduct.startDate).isAfter(
      moment(subscriptionProduct.endDate)
    )
  ) {
    errors.endDate = "חודש סיום לא תקין";
    isValid = false;
  }

  if (subscriptionProduct.singlePricing === "") {
    errors.singlePricing = "שדה חובה";
    isValid = false;
  }

  if (subscriptionProduct.singlePricing) {
    if (!subscriptionProduct.pricings[0]?.unitPriceStr) {
      errors.unitPrice = "שדה חובה";
      isValid = false;
    } else if (subscriptionProduct.pricings[0].unitPrice <= 0) {
      errors.unitPrice = "שדה לא תקין";
      isValid = false;
    }
  }

  if (
    subscriptionProduct.subscriptionType !== "season" &&
    !subscriptionProduct.numberOfPayments
  ) {
    errors.numberOfPayments = "שדה חובה";
    isValid = false;
  }

  errors.fees = [];

  for (let index = 0; index < subscriptionProduct.fees.length; index++) {
    const fee = subscriptionProduct.fees[index];
    const validity = {};

    if (fee.priceStr === "" || fee.price < 0) {
      validity.price = "שדה לא תקין";
      isValid = false;
    }

    if (fee.description.trim().length < 2) {
      validity.description = "שדה לא תקין";
      isValid = false;
    }

    if (requireAccountingExportNumber) {
      if (fee.aen.trim().length < 3) {
        validity.aen = "שדה לא תקין";
        isValid = false;
      }
    }

    errors.fees[index] = validity;
  }

  if (!subscriptionProduct.singlePricing) {
    if (subscriptionProduct.pricings.length === 0) {
      errors.pricings = "יש להוסיף תמחורים";
      isValid = false;
    } else {
      const res = validatePricings(subscriptionProduct);
      errors.pricings = res.errors;
      if (!res.isValid) {
        isValid = false;
      }
    }
  }

  return { isValid, errors };
};

const validatePricings = (subscriptionProduct) => {
  const { pricings } = subscriptionProduct;

  const errors = [];
  let isValid = true;

  const maxQuantity =
    subscriptionProduct.subscriptionType === "daily"
      ? subscriptionProduct.units || 0
      : 7;

  for (let index = 0; index < pricings.length; index++) {
    const item = pricings[index];
    const validity = {};

    if (item.title.length < 2) {
      validity.title = "שדה לא תקין";
      isValid = false;
    }

    if (!item.unitPriceStr || item.unitPrice < 0) {
      validity.unitPrice = "שדה לא תקין";
      isValid = false;
    }

    if (
      ["season", "daily"].indexOf(subscriptionProduct.subscriptionType) > -1
    ) {
      if (
        !item.quantity ||
        item.quantity <= 0 ||
        item.quantity > maxQuantity ||
        item.quantity.toString() !== item.quantityStr
      ) {
        validity.quantity = "שדה לא תקין";
        isValid = false;
      }
    }

    errors.push(validity);
  }

  return { isValid, errors };
};
