import React, { useState } from "react";
import moment from "moment";

import Report from "./Report";
import { renderCell, sortValueCell } from "../../lib/tableHelpers";

const columns = [
  {
    id: "clubPlayer",
    label: "ספורטאי",
    preset: "link",
  },
  {
    id: "team",
    label: "קבוצה",
    preset: "link",
  },
  {
    id: "discountReason",
    label: "סיבת ההנחה",
  },
  {
    id: "monthlyPrice",
    label: "מחיר חודשי",
    preset: "price",
    alignment: "right",
  },
  {
    id: "originalMonthlyPrice",
    label: "מחיר חודשי מקורי",
    preset: "price",
    alignment: "right",
  },
  { id: "months", label: "חודשי פעילות" },
  {
    id: "discount",
    label: "הנחה לחודש",
    baseRenderer: true,
    preset: "price",
    alignment: "right",
  },
  {
    id: "discountPercent",
    label: "אחוז הנחה",
    baseRenderer: true,
    preset: "percent",
    alignment: "right",
  },
  { id: "feesPrice", label: "דמי רישום", preset: "price", alignment: "right" },
  {
    id: "originalFeesPrice",
    label: "דמי רישום מקוריים",
    preset: "price",
    alignment: "right",
  },
  {
    id: "feesDiscount",
    label: "הנחה - דמי רישום",
    baseRenderer: true,
    alignment: "right",
    preset: "price",
  },
  {
    id: "feesDiscountPercent",
    label: "אחוז הנחה - דמי רישום",
    baseRenderer: true,
    preset: "percent",
    alignment: "right",
  },
  {
    id: "totalDiscount",
    label: "סה״כ הנחה",
    preset: "price",
    alignment: "right",
  },
];

export default function Discounts(props) {
  const filtersStructure = [{ type: "date", label: "תאריך רישום" }];
  const [initialFiltersState] = useState({
    date: { from: moment().startOf("month"), to: moment().endOf("month") },
  });

  const baseRenderer = (columnId, subscription) => {
    switch (columnId) {
      case "discount":
        return subscription.originalMonthlyPrice - subscription.monthlyPrice;

      case "discountPercent":
        return subscription.originalMonthlyPrice === 0
          ? 0
          : Math.round(
              ((subscription.originalMonthlyPrice - subscription.monthlyPrice) /
                subscription.originalMonthlyPrice) *
                1000
            ) / 10;

      case "feesDiscount":
        return subscription.originalFeesPrice - subscription.feesPrice;

      case "feesDiscountPercent":
        return subscription.originalFeesPrice === 0
          ? 0
          : Math.round(
              ((subscription.originalFeesPrice - subscription.feesPrice) /
                subscription.originalFeesPrice) *
                1000
            ) / 10;

      case "clubPlayer":
        return {
          to: `/players/${subscription.clubPlayer._id}`,
          label: subscription.clubPlayer.name,
        };

      case "team":
        return {
          to: `/teams/${subscription.team._id}`,
          label: subscription.team.name,
        };

      default:
        return subscription[columnId];
    }
  };

  return (
    <Report
      title="דו״ח הנחות"
      tableTitle="הנחות"
      url="/store/subscriptions?hasDiscount=true&granularity=clubPlayer&granularity=team&"
      initialFiltersState={initialFiltersState}
      filtersStructure={filtersStructure}
      location={props.location}
      history={props.history}
      renderCell={renderCell(columns, false, baseRenderer)}
      sortValueCell={sortValueCell(columns, baseRenderer)}
      columns={columns}
      exportFileName="הנחות"
      renderCellExport={renderCell(columns, true, baseRenderer)}
    />
  );
}
