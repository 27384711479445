import React from "react";
import moment from "moment";
import { Link as RouterLink } from "react-router-dom";
import { Link } from "@mui/material";

import {
  transactionStatuses,
  transactionSources,
  transactionKinds,
  creditCardTypes,
} from "../../../utils/dictionaries";

import { displayPrice } from "../../../utils/helpers";
import Table from "../../../components/table/Table";
import { exportTable } from "../../../lib/tableHelpers";

const columns = [
  { id: "amount", label: "סכום" },
  { id: "status", label: "סטטוס", minWidth: 120 },
  { id: "paidAt", label: "תאריך", minWidth: 140 },
  { id: "receiptNumber", label: "קבלה", width: "60px" },
  { id: "transactionType", label: "סוג עסקה" },
  { id: "transactionMethod", label: "אמצעי תשלום" },
  { id: "source", label: "מקור", minWidth: 120 },
  { id: "payerName", label: "לקוח" },
];

function TransactionsTable(props) {
  const renderCell = (columnId, transaction) => {
    switch (columnId) {
      case "amount":
        return displayPrice(transaction.amount);
      case "paidAt":
        return moment(transaction.createdAt).format("DD/MM/YY LT");

      case "source":
        return transactionSources[transaction.source];

      case "receiptNumber":
        return (
          <Link
            component={RouterLink}
            color="primary"
            to={`${transaction._id}`}
            underline="hover"
          >
            {transaction.receiptNumber}
          </Link>
        );

      case "status":
        return transactionStatuses[transaction.status];

      case "transactionType":
        return transactionKinds[transaction.kind];

      case "payerName":
        return (transaction.payer || {}).name;

      case "transactionMethod":
        switch (transaction.kind) {
          case "CreditCardTransaction":
            return `${creditCardTypes[transaction.cardType]} - ${
              transaction.last4
            }`;

          case "CheckTransaction":
            return "-";

          default:
            break;
        }
        break;

      default:
        return transaction[columnId];
    }
  };

  const sortValueCell = (columnId, transaction) => {
    switch (columnId) {
      case "paidAt":
        return moment(transaction.createdAt).unix();

      default:
        return transaction[columnId];
    }
  };

  const renderCellExport = (columnId, transaction) => {
    switch (columnId) {
      case "transactionId":
        return transaction.transactionId;

      case "amount":
        return transaction.amount;

      case "receiptNumber":
        return transaction.receiptNumber;

      default:
        return renderCell(columnId, transaction);
    }
  };

  const handleExportTable = () => {
    exportTable(props.transactions, columns, renderCellExport, "csv", "עסקאות");
  };

  return (
    <Table
      columns={columns}
      renderCell={renderCell}
      sortValueCell={sortValueCell}
      rows={props.transactions}
      title="עסקאות"
      defaultSortBy={2}
      defaultSortDirection="desc"
      onExport={handleExportTable}
      isLoading={props.isLoading}
    />
  );
}

export default TransactionsTable;
