import React from "react";
import { DialogContent, DialogActions, Typography } from "@mui/material";

import InfoRow from "../../../components/InfoRows/InfoRow";
import ButtonSx from "../../../components/base/ButtonSx";
import Dialog from "../../../components/Dialog";

const identifiers = {
  phone: "מספר הטלפון",
  id: "תעודת הזהות",
  passportNumber: "מספר הדרכון",
};

export default function ParentFoundDialog(props) {
  const parent = props.parent || {};

  let title = props.invalidParentConnection ? (
    <span>
      לא ניתן לשייך את הספורטאי להורה עם {identifiers[props.foundBy]} שהוזן
    </span>
  ) : (
    <span>הורה עם {identifiers[props.foundBy]} שהזנתם קיים במועדון </span>
  );

  const content = props.invalidParentConnection ? (
    <>
      <DialogContent />
      <DialogActions>
        <ButtonSx onClick={() => props.onClose(false)}>סגירה</ButtonSx>
      </DialogActions>
    </>
  ) : (
    <>
      <DialogContent dividers>
        <Typography gutterBottom>האם זה ההורה שחיפשתם?</Typography>

        <InfoRow title="שם" value={`${parent.firstName} ${parent.lastName}`} />
        <InfoRow title="טלפון" value={parent.phone} />
        {parent.notIsraeli ? (
          <InfoRow title="מספר דרכון" value={parent.passportNumber} />
        ) : (
          <InfoRow title="תעודת זהות" value={parent.id || "-"} />
        )}
        <InfoRow
          title="כתובת"
          value={`${parent.street} ${parent.houseNumber} ${parent.city}`}
        />
        <InfoRow title="אימייל" value={`${parent.email}`} />
        <InfoRow
          title="הורה ב"
          value={`${parent.secondParentName} ${parent.secondParentPhone}`}
        />
      </DialogContent>
      <DialogActions>
        <ButtonSx onClick={() => props.onClose(false)}>ביטול</ButtonSx>
        <ButtonSx debounce onClick={() => props.onClose(true)}>
          המשך
        </ButtonSx>
      </DialogActions>
    </>
  );

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      maxWidth="sm"
      fullWidth
      title={title}
    >
      {content}
    </Dialog>
  );
}
