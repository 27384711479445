import React, { useEffect, useState } from "react";
import {
  List,
  ListItem,
  ListItemText,
  DialogContent,
  DialogActions,
} from "@mui/material";

import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import _ from "lodash";

import Dialog from "../../../components/Dialog";
import ButtonSx from "../../../components/base/ButtonSx";

export default function ProductSortDialog(props) {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    setProducts(_.sortBy(props.products, "priority"));
  }, [props.products]);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const items = reorder(products, source.index, destination.index);
      setProducts(items);
    }
  };

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "lightblue" : "#ebecf0",
    padding: 8,
    width: "100%",
    height: "100%",
    overflow: "auto",
    borderRadius: 5,
  });

  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: 10,
    margin: `0 0 ${8}px 0`,
    // change background colour if dragging
    background: isDragging ? "#EFEFEF" : "white",
    // styles we need to apply on draggables
    ...draggableStyle,
  });

  const handleSave = async () => {
    await props.onSave(products);
    props.onClose();
  };

  const renderProducts = () => (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="options">
        {(provided, snapshot) => (
          <List
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
          >
            {products.map((product, index) => (
              <Draggable
                key={product._id}
                draggableId={product._id}
                index={index}
              >
                {(provided, snapshot) => (
                  <ListItem
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style
                    )}
                  >
                    <ListItemText primary={product.title} />
                  </ListItem>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </List>
        )}
      </Droppable>
    </DragDropContext>
  );

  return (
    <Dialog
      title="מיקום הפריטים בחנות"
      open={props.isOpen}
      onClose={props.onClose}
    >
      <DialogContent dividers>
        <div style={{ width: 400 }}>
          {products.length === 0 ? "אין פריטים פעילים" : renderProducts()}
        </div>
      </DialogContent>
      <DialogActions>
        <ButtonSx onClick={props.onClose}>ביטול</ButtonSx>
        <ButtonSx
          debounce
          onClick={handleSave}
          isLoading={props.isSaving}
          disabled={products.length === 0}
        >
          שמירה
        </ButtonSx>
      </DialogActions>
    </Dialog>
  );
}
