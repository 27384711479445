import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";

import ButtonSx from "../../components/base/ButtonSx";

const options = {
  delete: { single: "אירוע זה בלבד", fromEvent: "אירוע זה והאירועים הבאים" },
  update: {
    single: "אירוע זה בלבד",
    startFromEvent: "אירוע זה והאירועים הבאים",
    futureEvents: "כל האירועים שלא התרחשו",
  },
};

export default function RecurringActionEventDialog(props) {
  const [actionType, setActionType] = useState(0);
  const currentOptions = props.options || Object.keys(options[props.action]);
  return (
    <Dialog open={props.isOpen} onClose={props.onClose} dir="rtl">
      <DialogTitle>
        {props.action === "delete" ? "מחיקת אירוע" : "עריכת אירוע"}
      </DialogTitle>
      <DialogContent dividers>
        <RadioGroup
          value={actionType}
          onChange={(event) => setActionType(event.target.value)}
        >
          {currentOptions.map((option) => (
            <FormControlLabel
              key={option}
              value={option}
              control={<Radio color="primary" />}
              label={options[props.action][option]}
            />
          ))}
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <ButtonSx onClick={props.onClose}>ביטול</ButtonSx>
        <ButtonSx
          disabled={!actionType}
          onClick={() => props.onAction(actionType)}
        >
          אישור
        </ButtonSx>
      </DialogActions>
    </Dialog>
  );
}
