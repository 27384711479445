import React from "react";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  IconButton,
  Slide,
  DialogContent,
  Box,
  Typography,
} from "@mui/material";
import moment from "moment";
import CloseIcon from "@mui/icons-material/Close";

import AbsoluteCenter from "../../../components/AbsoluteCenter";
import axios from "../../../utils/axios";
import ButtonSx from "../../../components/base/ButtonSx";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});

export default function UnseenUpdatesDialog(props) {
  const [events, setEvents] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);

  const loadEvents = async () => {
    setIsLoading(true);
    const res = await axios.get(`/events?unseenUpdates=true`);
    setEvents(res.data);
    setIsLoading(false);
    // if toolbar badge number is not updated
    if (res.data.length !== props.count) {
      props.onUpdate();
    }
  };

  React.useEffect(() => {
    if (props.open) {
      if (!isLoading) {
        loadEvents();
      }
    } else {
      setTimeout(() => {
        setEvents(null);
      }, 100);
    }
  }, [props.open]);

  const formatSecondary = (event) => {
    return `${moment(event.start).format("D/M/YY HH:mm")} - ${moment(
      event.end
    ).format("HH:mm")}`;
  };

  const renderContent = () => {
    if (events.length === 0) {
      return <Box sx={styles.noData}>כל העדכונים נקראו!</Box>;
    }

    return (
      <>
        <List disablePadding>
          {events.map((event) => (
            <ListItem dense key={event._id}>
              <ListItemText
                primary={event.title}
                secondary={formatSecondary(event)}
              />
              {event.deleted && (
                <ListItemSecondaryAction>
                  <Typography sx={styles.collisionDescription}>
                    אירוע מבוטל
                  </Typography>
                </ListItemSecondaryAction>
              )}
            </ListItem>
          ))}
        </List>
        {process.env.NODE_ENV === "development" &&
          events.map((e) => e._id).join(" ")}
      </>
    );
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      fullScreen
      sx={styles.dialog}
      dir="rtl"
      TransitionComponent={Transition}
    >
      <DialogTitle>
        <Box sx={styles.titleContainer}>
          עדכונים שלא נקראו
          <IconButton size="small" onClick={props.onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        {isLoading || !events ? (
          <AbsoluteCenter>
            <CircularProgress />
          </AbsoluteCenter>
        ) : (
          renderContent()
        )}
      </DialogContent>
      <DialogActions>
        <ButtonSx onClick={props.onClose} color="primary">
          סגירה
        </ButtonSx>
      </DialogActions>
    </Dialog>
  );
}

const styles = {
  noData: {
    marginTop: "5px",
    marginLeft: { xs: 0, sm: 2 },
    display: "flex",
    justifyContent: { xs: "center", sm: "flex-start" },
    fontSize: 20,
  },
  dialog: {
    zIndex: { sm: 11 },
    width: { sm: 400 },
    marginTop: { xs: 0, sm: "72px" },
  },
  divider: {
    width: 3,
    borderRadius: "2px",
    marginLeft: "10px",
    marginRight: "20px",
    height: 40,
  },
  collisionDescription: (theme) => ({
    backgroundColor: theme.calendar.collisions,
    padding: "5px 10px",
    borderRadius: ".25rem",
    color: "#ffffff",
    fontWeight: "bold",
    marginLeft: "10px",
  }),
  button: {
    width: "120px",
  },
  titleContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
};
