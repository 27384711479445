import React from "react";
import ReactGA from "react-ga4";
import moment from "moment";
import _ from "lodash";
import { connect } from "react-redux";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

import axios from "../../../utils/axios";
import {
  prepareFilters,
  getFiltersFromUrl,
} from "../../../components/filters/prepareFilters";
import withRouter from "../../../components/routing/withRouter";
import RevenueSeason from "./RevenueSeason";
import { exportTable } from "../../../lib/tableHelpers";
import { displaySeason } from "../../../utils/helpers";
import RevenueMerchandise from "./RevenueMerchandise";
import { clubCategories } from "../../../utils/dictionaries";
import IconButtonTooltip from "../../../components/base/IconButtonTooltip";
import RevenuesReportSuperClub from "./RevenuesReportSuperClub";
import TlPaper from "../../../components/TlPaper";

class RevenuesReport extends React.Component {
  state = {
    subscriptions: [],
    isLoading: true,
    filters: {
      date: "",
      season: "",
    },
    seasons: [],
  };

  componentDidMount = async () => {
    ReactGA.send({
      hitType: "pageview",
      page: "/reports/revenues",
      title: "Revenues Report",
    });

    const minYear = 2023;
    let seasons = this.props.seasons.filter((s) => s.year > minYear);

    this.filtersStructure = [
      {
        name: "season",
        type: "select",
        options: seasons.reduce((prev, curr) => {
          prev[curr._id] = curr.name;
          return prev;
        }, {}),
        emptyLabel: "כל העונות",
        label: "עונה",
        includeAll: false,
      },
    ];

    const filters = getFiltersFromUrl(
      this.props.location,
      this.state.filters,
      this.filtersStructure
    );
    const mainSeason = this.props.seasons.find((s) => s.main);
    await this.setState(
      {
        filters: {
          ...filters,
          date: filters.date || {
            from: moment(`${mainSeason.year}-08-01`),
            to: moment(`${mainSeason.year + 1}-07-31`),
          },
          season: seasons.map((s) => s._id),
        },
        rawSeasons: seasons,
      },
      () => this.loadData(false, false)
    );
  };

  prepareSubscriptions = (revenues) => {
    const seasons = this.state.rawSeasons.reduce((prev, season) => {
      prev[season._id] = {
        products: [],
        totalCost: 0,
        totalPaid: 0,
        paidByCategory: { league: 0, school: 0, camp: 0 },
        season,
      };

      return prev;
    }, {});

    revenues.subscriptions.forEach((productIncome) => {
      const seasonId = productIncome.product.season._id;

      seasons[seasonId].products.push(productIncome);
      productIncome.teams = _.sortBy(productIncome.teams, "name");
      productIncome.totalPaid = productIncome.income.paid;
      productIncome.totalCost = productIncome.income.cost;
      seasons[seasonId].totalPaid += productIncome.totalPaid;
      seasons[seasonId].totalCost += productIncome.totalCost;
      productIncome.teams.forEach((team) => {
        seasons[seasonId].paidByCategory[team.teamType] += team.income.paid;
      });
    });

    return Object.values(seasons);
  };

  prepareMerchandise = (products) => {
    const merchandise = { totalPaid: 0, products };
    merchandise.products.forEach((product) => {
      product.totalPaid = product.income.paid;
      merchandise.totalPaid += product.income.paid;
    });

    return merchandise;
  };

  loadData = async (silence, updateUrl = true) => {
    this.setState({ isLoading: !silence });
    let filters = prepareFilters(this.state.filters, this.filtersStructure);

    const res = await axios.get(`/reports/revenues?${filters.join("&")}`);
    const seasons = this.prepareSubscriptions(res.data);
    const merchandise = this.prepareMerchandise(res.data.merchandise);

    this.setState({ merchandise, isLoading: false, seasons });

    if (updateUrl) {
      filters = prepareFilters(this.state.filters, this.filtersStructure);
      this.props.navigate(`?${filters.join("&")}`, { replace: true });
    }
  };

  loadMerchandiseOnly = async () => {
    this.setState({
      isLoadingMerchandise: true,
      merchandise: { products: [] },
    });
    let filters = prepareFilters(this.state.filters, this.filtersStructure);

    const res = await axios.get(
      `/reports/revenues/merchandise?${filters.join("&")}`
    );

    const merchandise = this.prepareMerchandise(res.data);

    this.setState({ merchandise, isLoadingMerchandise: false });

    filters = prepareFilters(this.state.filters, this.filtersStructure);
    this.props.navigate(`?${filters.join("&")}`, { replace: true });
  };

  prepareSeasonExportData = (seasonRecord) => {
    const season = displaySeason(seasonRecord.season.year);
    const category =
      clubCategories[this.props.clubsHash[seasonRecord.season.club].category];
    return _.flatten(
      seasonRecord.products.map((product) =>
        product.teams.map((team) => ({
          product: product.product.title,
          team: team.name,
          paid: team.income.paid,
          season,
          category,
        }))
      )
    );
  };

  handleDownloadSeason = (seasonRecord) => (e) => {
    e.stopPropagation();
    e.preventDefault();

    let filename = "הכנסות";
    if (seasonRecord) {
      filename = `הכנסות עונה ${displaySeason(seasonRecord.season.year)}`;
      if (this.props.selectedClub.superClub) {
        filename = `${filename} - ${
          clubCategories[
            this.props.clubsHash[seasonRecord.season.club].category
          ]
        }`;
      }
    }

    const columns = [
      { id: "product", label: "מסגרת" },
      { id: "team", label: "קבוצה" },
      { id: "paid", label: "הכנסות" },
      { id: "season", label: "עונה" },
    ];

    let seasonRecords = [seasonRecord];
    if (!seasonRecord) {
      seasonRecords = this.state.seasons;
      columns.push({ id: "category", label: "ענף" });
    }

    const records = _.flatten(
      seasonRecords.map((seasonRecord) =>
        this.prepareSeasonExportData(seasonRecord, true)
      )
    );

    exportTable(
      records,
      columns,
      (columnId, rec) => rec[columnId],
      "csv",
      filename
    );
  };

  handleDownloadMerchandise = (e) => {
    e.stopPropagation();
    e.preventDefault();

    const records = _.flatten(
      this.state.merchandise.products.map((product) => ({
        product: product.product.title,
        paid: product.totalPaid,
      }))
    );

    const columns = [
      { id: "product", label: "מוצר" },
      { id: "paid", label: "הכנסות" },
    ];

    exportTable(
      records,
      columns,
      (columnId, rec) => rec[columnId],
      "csv",
      `הכנסות חנות ${this.state.filters.date.from.format(
        "MM/YY"
      )} - ${this.state.filters.date.to.format("MM/YY")}`
    );
  };

  handleMerchandiseDateChange = (dates) => {
    this.setState({ filters: { ...this.state.filters, date: dates } }, () => {
      this.loadMerchandiseOnly();
    });
  };

  render() {
    if (this.props.selectedClub.superClub) {
      return (
        <RevenuesReportSuperClub
          seasons={this.state.seasons}
          merchandise={this.state.merchandise}
          filters={this.state.filters}
          isLoadingMerchandise={this.state.isLoadingMerchandise}
          onDownloadMerchandise={this.handleDownloadMerchandise}
          onMerchandiseDateChange={this.handleMerchandiseDateChange}
          onDownloadSeason={this.handleDownloadSeason}
        />
      );
    }

    const downloadAllButton = (
      <IconButtonTooltip
        title="הורדת כל ההכנסות"
        onClick={this.handleDownloadSeason()}
      >
        <ArrowDownwardIcon />
      </IconButtonTooltip>
    );

    return (
      <TlPaper
        title="הכנסות"
        fullPage
        titleBackground
        isLoading={this.state.isLoading}
        leftItem={downloadAllButton}
      >
        {(this.state.seasons || []).map((season) => (
          <RevenueSeason
            season={season}
            key={season.season._id}
            onDownloadSeason={this.handleDownloadSeason}
          />
        ))}
        <RevenueMerchandise
          dates={this.state.filters.date}
          merchandise={this.state.merchandise}
          onDownload={this.handleDownloadMerchandise}
          onDateChanged={this.handleMerchandiseDateChange}
          isLoading={this.state.isLoadingMerchandise}
          superClub={this.props.selectedClub.superClub}
        />
      </TlPaper>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedClub: state.auth.selectedClub,
    seasons: state.general.seasons,
    clubsHash: state.auth.clubsHash,
  };
}

export default connect(mapStateToProps)(withRouter(RevenuesReport));
