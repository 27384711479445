import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  TextField,
} from "@mui/material";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";

import PriceInputNg from "../../../../components/PriceInputNG";

const cellStyle = (cell, selectedCell, errors) => {
  const style = {
    padding: "1px",
    pl: 1,
  };

  if (errors && errors[cell]) {
    style.border = "1px solid red";
  } else if (selectedCell === cell) {
    style.border = "1px solid #0088ff";
  }
  return style;
};

export default function SubscriptionProductPricingItem(props) {
  const {
    pricing,
    errors,
    onChange,
    header,
    onCellSelected,
    selectedCell,
    subscriptionProduct,
  } = props;

  const textFieldProps = {
    variant: "standard",
    fullWidth: true,
    margin: "dense",
    InputProps: { disableUnderline: true, readOnly: props.completed },
  };

  const handleCellFocus = (cell) => () => {
    !props.completed && onCellSelected(cell);
  };

  const type = subscriptionProduct?.subscriptionType;

  const renderQuantity = () => {
    let label;
    switch (type) {
      case "fixedPrice":
        return;

      case "season":
        label = "מספר ימים בשבוע";
        break;

      case "daily":
        label = "ימי פעילות";
        break;

      default:
        break;
    }

    return (
      <Grid md={3} sx={cellStyle("quantity", selectedCell, errors)}>
        {header ? (
          <Box sx={styles.headerTitle}>{label}</Box>
        ) : (
          <TextField
            value={pricing.quantityStr}
            onChange={(e) =>
              onChange({
                quantityStr: e.target.value,
                quantity: parseInt(e.target.value),
              })
            }
            {...textFieldProps}
            onFocus={handleCellFocus("quantity")}
            onBlur={handleCellFocus(null)}
            type="number"
          />
        )}
      </Grid>
    );
  };

  const handleActiveChanged = (e) => {
    const { checked } = e.target;
    const params = { active: checked };
    if (!checked) {
      params.activeForParents = false;
    }
    onChange(params);
  };

  const renderPrice = () => {
    let label;
    switch (type) {
      case "fixedPrice":
        label = "מחיר";
        break;

      case "season":
        label = "מחיר חודשי";
        break;

      case "daily":
        label = "מחיר כולל";
        break;

      default:
        break;
    }

    return (
      <Grid md={2} sx={cellStyle("unitPrice", selectedCell, errors)}>
        {header ? (
          <Box sx={styles.headerTitle}>{label}</Box>
        ) : (
          <PriceInputNg
            value={pricing.unitPriceStr}
            onChange={(unitPriceStr, unitPrice) =>
              onChange({ unitPriceStr, unitPrice })
            }
            {...textFieldProps}
            onFocus={handleCellFocus("unitPrice")}
            onBlur={handleCellFocus(null)}
          />
        )}
      </Grid>
    );
  };

  return (
    <Box sx={{ ...styles.container, maxHeight: header ? "30px" : "48px" }}>
      <Grid container alignItems="center" sx={{ flex: 1 }}>
        <Grid xs="auto">
          <Box sx={{ width: "30px" }}>
            {!props.completed && !header && (
              <DragIndicatorIcon sx={{ mt: 1 }} />
            )}
          </Box>
        </Grid>

        {renderPrice()}

        <Grid xs sx={cellStyle("title", selectedCell, errors)}>
          {header ? (
            <Box sx={styles.headerTitle}>תיאור</Box>
          ) : (
            <TextField
              value={pricing.title}
              onChange={(e) => onChange({ title: e.target.value })}
              {...textFieldProps}
              onFocus={handleCellFocus("title")}
              onBlur={handleCellFocus(null)}
              inputProps={{ maxLength: 35 }}
            />
          )}
        </Grid>

        {renderQuantity()}

        <Grid xs={1} sx={cellStyle("active", selectedCell, errors)}>
          {header ? (
            <Box sx={styles.headerTitle}>פעיל</Box>
          ) : (
            <FormControlLabel
              disabled={props.completed}
              control={
                <Checkbox
                  checked={pricing.active}
                  onChange={handleActiveChanged}
                />
              }
            />
          )}
        </Grid>
        <Grid xs={2} sx={cellStyle("activeForParents", selectedCell, errors)}>
          {header ? (
            <Box sx={styles.headerTitle}>פעיל - הורים</Box>
          ) : (
            <FormControlLabel
              disabled={props.completed || !pricing.active}
              control={
                <Checkbox
                  checked={pricing.activeForParents}
                  onChange={(e) =>
                    onChange({ activeForParents: e.target.checked })
                  }
                />
              }
            />
          )}
        </Grid>
      </Grid>

      <Box sx={{ width: 50, display: "flex", justifyContent: "flex-end" }}>
        {!header && (
          <IconButton
            onClick={props.onDelete}
            disabled={!props.editable && !pricing.isNew}
          >
            <DeleteIcon />
          </IconButton>
        )}
      </Box>
    </Box>
  );
}

const styles = {
  container: {
    border: "1px solid #EEE",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flex: 1,
  },
  headerTitle: {
    pl: "1px",
  },
};
