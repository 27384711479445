import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
} from "@mui/material";

import axios from "../../utils/axios";
import { resources } from "../../utils/dictionaries";
import ButtonSx from "../base/ButtonSx";

export default function VoidDialog(props) {
  const [processing, setProcessing] = React.useState(false);
  const [success, setSuccess] = React.useState(null);
  // const [commentError, setCommentError] = React.useState(false);
  // const [comment, setComment] = React.useState("");

  const handleSubmit = () => {
    if (success === null) {
      voidTransaction();
    } else {
      props.onComplete();
    }
  };

  const voidTransaction = async () => {
    setProcessing(true);
    await axios.post(
      `/payments/${resources[props.transaction.kind]}/${
        props.transaction._id
      }/void`
    );

    setProcessing(false);
    setSuccess(true);
  };

  let content;
  if (processing) {
    content = (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <CircularProgress />
      </div>
    );
  } else if (success) {
    content = <div>העסקה בוטלה בהצלחה</div>;
  } else {
    content = <div>האם ברצונך לבטל עסקה זו?</div>;
  }

  return (
    <Dialog
      dir="rtl"
      fullWidth={true}
      maxWidth="sm"
      onClose={props.onClose}
      open={props.open}
    >
      <DialogTitle id="form-dialog-title">ביטול עסקה</DialogTitle>
      <DialogContent dividers>{content}</DialogContent>
      <DialogActions>
        {success === null && (
          <ButtonSx onClick={props.onClose} disabled={processing}>
            ביטול
          </ButtonSx>
        )}
        <div>
          <ButtonSx debounce disabled={processing} onClick={handleSubmit}>
            אישור
          </ButtonSx>
        </div>
      </DialogActions>
    </Dialog>
  );
}
