import React from "react";
import withBreakpoints from "./routing/withBreakpoints";

function ResponsiveContainer({
  MobileComponent,
  DesktopComponent,
  mobileContent,
  desktopContent,
  isMatching,
  ...props
}) {
  if (isMatching) {
    return DesktopComponent ? (
      <DesktopComponent {...props} />
    ) : (
      desktopContent || <span />
    );
  }

  return MobileComponent ? (
    <MobileComponent {...props} />
  ) : (
    mobileContent || <span />
  );
}

export default withBreakpoints("smUp", ResponsiveContainer);
