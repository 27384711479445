export const coachCertifications = {
  coach: "מאמנ/ת",
  instructor: "מדריכ/ה",
};

export const leagueTypes = {
  katsalA: "קטסל א",
  katsalB: "קטסל ב",
  yeladimB: "ילדים ב",
  yeladimA: "ילדים א",
  nearimA: "נערים א",
  nearimB: "נערים ב",
  noar: "נוער",
  bogrim: "בוגרים",
};

export const teamTypes = {
  default: {
    league: "ליגה",
    school: "בית ספר",
    camp: "מחנה",
  },
  gymnastics: {
    league: "נבחרת",
    school: "חוג",
    camp: "מחנה",
  },
};

export const genders = {
  male: "בנים",
  female: "בנות",
  mixed: "מעורב",
};

export const transactionKinds = {
  CheckTransaction: "צ׳ק",
  CreditCardTransaction: "כרטיס אשראי",
  CashTransaction: "מזומן",
  WireTransferTransaction: "העברה בנקאית",
};

export const paymentMethods = {
  check: "צ׳ק",
  creditCard: "כרטיס אשראי",
  cash: "מזומן",
  wireTransfer: "העברה בנקאית",
};

export const creditCardTypes = {
  visa: "ויזה",
  isracard: "ישראכרט",
  amex: "אמריקן אקספרס",
  diners: "דיינרס",
  max: "מקס",
  cal: "כאל",
  unknown: "לא ידוע",
};

export const transactionStatuses = {
  approved: "עסקה תקינה",
  cancelled: "עסקה מבוטלת",
  settled: "עסקה תקינה",
  declined: "עסקה נכשלה",
  error: "העסקה נכשלה",
};

export const classes = {
  0: "גן",
  1: "כיתה א",
  2: "כיתה ב",
  3: "כיתה ג",
  4: "כיתה ד",
  5: "כיתה ה",
  6: "כיתה ו",
  7: "כיתה ז",
  8: "כיתה ח",
  9: "כיתה ט",
  10: "כיתה י",
  11: "כיתה יא",
  12: "כיתה יב",
  13: "חייל",
  14: "בוגר",
};

export const transactionSources = {
  web: "אתר הורים",
  backOffice: "בק אופיס",
  mobile: "מובייל",
};

export const purchaseTypes = {
  all: "כל הסוגים",
  enrollment: "הרשמה",
  merchandise: "מוצרים",
  mixed: "הרשמה ומוצרים",
};

export const checkStatuses = {
  pending: "לא הופקד",
  bounced: "צ׳ק חוזר",
  bouncedCopy: "צ׳ק חוזר",
  deposited: "הופקד",
  cancelled: "מבוטל",
  cancelledCopy: "מבוטל",
  refund: "החזר",
};

export const resources = {
  CreditCardTransaction: "creditCardTransactions",
  CheckTransaction: "checkTransactions",
  CashTransaction: "cashTransactions",
  WireTransferTransaction: "wireTransferTransactions",
};

export const attendanceMissingReasons = {
  vacation: "חופשה",
  sick: "מחלה",
  schoolActivity: "פעילות בית ספר",
  unknown: "לא ידוע",
  other: "אחר",
  quarantine: "בידוד",
  notInSegel: "לא בסגל",
  injury: "פציעה",
  limitedSubscription: "פעילות לא כלולה ברישום",
};

export const attendanceMissingReasonsShort = {
  ...attendanceMissingReasons,
  schoolActivity: "בי״ס",
};

export const purchaseStatuses = {
  open: "פתוחה",
  closed: "סגורה",
  cancelled: "מבוטלת",
};

export const purchaseProductStatuses = {
  pending: "לא התקבל",
  received: "התקבל",
  returned: "הוחזר",
};

export const eventTypes = {
  practice: "אימון",
  game: "משחק",
  other: "אחר",
};

export const collisions = {
  coach: "מאמן",
  resource: "מגרש",
};

export const gender = {
  male: "זכר",
  female: "נקבה",
};

export const accountingDocumentsExportTypes = {
  hash_dos_short: "חשבשבת DOS בשיטה מקוצרת",
  hash_dos_long: "חשבשבת DOS בשיטה מפורטת",
  hash_win_flexible: "חשבשבת חלונות בשיטה הגמישה",
  sap_flexible: "SAP בשיטה הגמישה",
  priority: "פריוריטי",
  rivhit: "ריווחית",
};

export const reportStatuses = {
  pending: "תהליך היצור החל",
  error: "שגיאה",
  ready: "מוכן",
};

export const weekStatusStatuses = {
  ready: "פתוח",
  unready: "לא פתוח",
};

export const banks = {
  13: "בנק אגוד לישראל",
  14: "בנק אוצר החייל",
  11: "בנק דיסקונט לישראל",
  68: "בנק דקסיה ישראל",
  8: "בנק הפועלים",
  4: "בנק יהב לעובדי המדינה",
  54: "בנק ירושלים",
  10: "בנק לאומי לישראל",
  20: "בנק מזרחי טפחות",
  46: "בנק מסד",
  17: "בנק מרכנתיל דיסקונט",
  34: "בנק ערבי ישראלי",
  52: "בנק פועלי אגודת ישראל",
  31: "הבנק הבינלאומי הראשון לישראל",
  26: "יובנק",
  9: "בנק הדואר",
  99: "בנק ישראל",
  18: "וואן זירו",
};

export const userStatuses = {
  fresh: "משתמש חדש",
  active: "פעיל",
  invitationPending: "הזמנה נשלחה",
};

export const userLevels = {
  admin: "אדמין",
  user: "משתמש",
};

export const dicToArray = (dic) => {
  return Object.entries(dic).map(([key, value]) => ({ key, value }));
};

export const teamStatuses = {
  active: "פעילה",
  completed: "פעילות הסתיימה",
  archived: "ארכיון",
};

export const subscriptionsStatuses = {
  active: "פעיל",
  completed: "הסתיים",
  cancelled: "הופסק",
  pendingDiscount: "הוגשה בקשה מיוחדת",
  pendingOtherPaymentMethod: "הוגשה בקשה לתשלום במזומן / צ׳ק",
};

export const subscriptionProductsStatuses = {
  active: "פעילה",
  expired: "הסתיימה",
  inactive: "לא פעילה",
};

export const discountRequestStatuses = {
  open: "חדשה",
  accepted: "אושרה",
  rejected: "נדחתה",
};

export const specialRequestStatuses = {
  open: "חדשה",
  close: "טופלה",
};

export const productStatuses = {
  active: "פעיל",
  inactive: "לא פעיל",
};

export const subscriptionProductsTypes = {
  camp: "מחנה",
  season: "מחיר חודשי",
  fixedPrice: "מחיר קבוע",
  daily: "מחיר יומי",
};

export const subscriptionLogTypes = {
  new: "רישום",
  newTeamChange: "רישום - לאחר מעבר קבוצה",
  payment: "תשלום",
  void: "ביטול תשלום",
  virtualPayment: "העברת תשלום בין רישומים",
  cancelled: "הפסקת פעילות",
  cancelledTeamChange: "הפסקת פעילות - מעבר קבוצה",
  discountRequestSubmitted: "הוגשה בקשה מיוחדת",
  otherPaymentMethodRequestSubmitted: "הוגשה בקשה לתשלום באמצעי תשלום אחר",
  discountRequestAccepted: "אישור בקשה מיוחדת",
  discountRequestRejected: "דחיית בקשה מיוחדת",
  termsChange: "עדכון פרטי רישום",
  debtCleared: "ניכוי חוב",
};

export const clubCategories = {
  basketball: "כדורסל",
  soccer: "כדורגל",
  handball: "כדוריד",
  volleyball: "כדורעף ",
  football: "פוטבול",
  swimming: "שחיה",
  taekwondo: "טאקוונדו",
  badminton: "בדמינטון",
  waterpolo: "כדורמים ",
  fencing: "סייף",
  triathlon: "טריאתלון",
  judo: "ג׳ודו",
  chess: "שחמט",
  bouldering: "בולדר",
  athletics: "אתלטיקה",
  rugby: "רוגבי",
  rhythmic_gymnastics: "התעמלות אומנותית",
  artistic_gymnastics: "התעמלות מכשירים",
  catchball: "כדורשת",
  wrestling: "היאבקות",
  surfing: "גלישה",
  acrobatics: "אקרובטיקה",
  table_tennis: "טניס שולחן",
  archery: "קשתות",
  weightlifting: "הרמת משקולות",
  aerobic_gymnastics: "התעמלות אירובית",
  general: "כללי",
};
