// const dev = {
//   apiUrl: "http://10.0.0.3:3000",
//  baseUrl: "http://10.0.0.3:3001",
// };

const dev = {
  apiUrl: "http://localhost:3000",
  baseUrl: "http://localhost:3001",
};

const prod = {
  baseUrl: "https://admin.trainlitapp.com",
  apiUrl: "https://api4.trainlitapp.com",
};

let config;

switch (process.env.NODE_ENV) {
  case "development":
    config = dev;
    break;

  case "production":
    config = prod;
    break;

  default:
    break;
}

export default config;
