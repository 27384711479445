import React, { useEffect, useState } from "react";
import { CssBaseline, Box, Container, useMediaQuery } from "@mui/material";
import { Outlet, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { styled } from "@mui/material/styles";

import Rtl from "../../layout/Rtl";
import TrainlitLoader from "../../components/TrainlitLoader";
import AppBar from "./AppBar";
import TrainlitSpinner from "../../components/TrainlitSpinner";
import Drawer from "./Drawer";

const Main = styled("main", {
  shouldForwardProp: (prop) => prop !== "open" && prop !== "isMdUp",
})(({ theme, open, isMdUp }) => {
  const base = {
    flexGrow: 1,
    overflow: "auto",
    minHeight: "100vh",
    backgroundColor: "#FAFAFA",
  };
  base.marginLeft = 0;

  if (isMdUp && open !== null) {
    base.transition = open
      ? theme.transitions.create("margin", {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        })
      : theme.transitions.create("margin", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        });
    base.marginLeft = open ? 0 : "-220px";
  }
  return base;
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
  height: 72,
}));

function Layout(props) {
  const isMdUp = useMediaQuery((theme) => theme.breakpoints.up("md"), {
    noSsr: true,
  });
  const [isDrawerOpen, setIsDrawerOpen] = useState(null);
  const params = useParams();

  useEffect(() => {
    setIsDrawerOpen(isMdUp);
  }, [isMdUp]);

  const giladProd =
    props.user &&
    props.user.email.match(/gilad.*@trainlitapp.com/g) &&
    process.env.NODE_ENV === "production";

  const backgroundColor = props.selectedClub?.clubConfig.mainBackgroundColor;
  const textColor = props.selectedClub?.clubConfig.mainTextColor;
  const secondaryColor = props.selectedClub?.clubConfig.secondaryColor;

  const handleDrawerToggle = () => {
    setIsDrawerOpen((prev) => !prev);
  };

  let noOutlet;
  if (
    params.clubId !== props.selectedClub?.internalName ||
    props.gentleClubChange
  ) {
    if (props.selectedClub) {
      noOutlet = true;
    } else {
      return <div />;
    }
  }

  return (
    <Rtl>
      <Box dir="rtl" sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          giladProd={giladProd}
          backgroundColor={backgroundColor}
          textColor={textColor}
          secondaryColor={secondaryColor}
          selectedClub={props.selectedClub}
          user={props.user}
          onDrawerToggle={handleDrawerToggle}
          isDrawerOpen={isDrawerOpen}
        />
        <Drawer
          isOpen={isDrawerOpen}
          selectedClub={props.selectedClub}
          user={props.user}
          onDrawerToggle={handleDrawerToggle}
          header={DrawerHeader}
        />

        <Main open={isDrawerOpen} isMdUp={isMdUp}>
          <DrawerHeader />
          {props.isLoading || props.gentleClubChange ? (
            props.isLoading ? (
              <TrainlitLoader />
            ) : (
              <TrainlitSpinner />
            )
          ) : noOutlet ? (
            <div />
          ) : (
            <Container
              maxWidth={props.maxWidth}
              sx={{ p: { xs: 1, sm: 2 }, mt: { xs: 0, sm: 2 } }}
              disableGutters
            >
              <Outlet />
            </Container>
          )}
        </Main>
      </Box>
    </Rtl>
  );
}

function mapStateToProps(state) {
  return {
    user: state.auth.user,
    selectedClub: state.auth.selectedClub,
    isLoading: state.auth.loading,
    gentleClubChange: state.auth.gentleClubChange,
  };
}

export default connect(mapStateToProps)(Layout);
