import axios from "../../utils/axios";

export const login = async (onLogin, password, email) => {
  try {
    let response = await axios.post("/users/login", {
      password,
      email,
      loginType: "email",
      userType: "manager",
    });

    const user = response.data;
    const token = response.headers.authorization;

    onLogin(user, token);
    return true;
  } catch (error) {
    if (error.response.status === 401) {
      return false;
    } else {
      throw error;
    }
  }
};
