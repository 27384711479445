import React, { Suspense, lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { connect } from "react-redux";
import * as Actions from "./store/actions";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import ReactGA from "react-ga4";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

import config from "./config";
import theme from "./theme/muiTheme";

import Login from "./containers/Auth/Login";
import Logout from "./containers/Auth/Logout";
import ForgotPassword from "./containers/Auth/ForgotPassword";
import ChangePassword from "./containers/Auth/ChangePassword";
import Dashboard from "./containers/Dashboard/Dashboard";
import Coach from "./containers/Coaches/Coach";
import Coaches from "./containers/Coaches/Coaches";
import Team from "./containers/Teams/Team";
import Teams from "./containers/Teams/Teams";
import Player from "./containers/Players/Player";
import Players from "./containers/Players";
import Parents from "./containers/Accounts/Parents";
import Account from "./containers/Accounts/Account";
import Calendar from "./containers/Calendar/Calendar";

const Privacy = lazy(() => import("./containers/Static/Privacy"));

import Merchandise from "./containers/Store/Purchases/Merchandise";
import Debt from "./containers/Store/Subscriptions/Debt";
import SubscriptionDiscountRequests from "./containers/Subscriptions/DiscountRequests";
import SubscriptionOtherPaymentMethodRequests from "./containers/Subscriptions/OtherPaymentMethodRequests";

import Products from "./containers/Store/Admin/Products";
import NewPurchase from "./containers/Purchase/NewPurchase";
import IframeResponder from "./containers/Payments/IframeResponder";
import Transactions from "./containers/Store/Transactions/Transactions";
import Receipts from "./containers/Store/Receipts/Receipts";
import DealReceipts from "./containers/DealReceipts/DealReceipts";

import Transaction from "./containers/Payments/Transaction";
import Checks from "./containers/Payments/Checks";
import AccountingDocuments from "./containers/Payments/AccountingDocuments/AccountingDocuments";
import Deposit from "./containers/Payments/Deposits/Deposits";

import GeneralSettings from "./containers/General/Settings/GeneralSettings";
import SubscriptionProducts from "./containers/General/Settings/SubscriptionProducts/SubscriptionProducts";
const Seasons = lazy(() =>
  import("./containers/General/Settings/Seasons/Seasons")
);
import PlayerAlerts from "./containers/General/Settings/PlayerAlerts/PlayerAlerts";
const ParentsSite = lazy(() =>
  import("./containers/General/Settings/ParentsSite/ParentsSite")
);

import AttendanceAnalytics from "./containers/Attendance/AttendanceAnalytics";
import Users from "./containers/Users/Users";
import ActivateUser from "./containers/Users/ActivateUser";

//REPORTS

import IncomeReport from "./containers/Reports/IncomeReport";
import MonthlyIncomePerProduct from "./containers/Reports/MonthlyIncomePerProduct";
import DiscountsReport from "./containers/Reports/Discounts";
import PaymentsDistributionOverMonthsReport from "./containers/Reports/PaymentsDistributionOverMonthsReport";
import SubscriptionLogs from "./containers/Reports/SubscriptionLogs";
import Quitters from "./containers/Reports/Quitters";
import RevenuesReport from "./containers/Reports/Revenues/RevenuesReport";

import InternalTools from "./containers/InternalTools/InternalTools";

import "moment/locale/he";
import Layout from "./containers/Layout/Layout";
import NavigateToDefaultClub from "./components/routing/NavigateToDefaultClub";
import Clubs from "./containers/Clubs";
import TrainlitSpinner from "./components/TrainlitSpinner";

// TESTS
import ProdTests from "./containers/ProdTests/ProdTests";

class App extends React.Component {
  handleLoginLogout = (event) => {
    if (event.storageArea !== localStorage) return;
    if (event.key === "userId") {
      location.reload(true);
    }
  };

  componentDidMount = () => {
    ReactGA.initialize("G-PRE71KW63M", {
      testMode: process.env.NODE_ENV === "development",
    });
    window.addEventListener("storage", this.handleLoginLogout);

    // no need for autoLogin in case of cc iframe response
    if (
      window.location.href.startsWith(`${config.baseUrl}/payments/responder`)
    ) {
      this.props.setIsLoading(false);
    } else {
      this.props.autoLogin();
    }
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.user !== this.props.user) {
      ReactGA.set({ userId: this.props.user?._id });
    }
  };

  componentWillUnmount = async () => {
    window.removeEventListener("storage", this.handleLoginLogout);
  };

  render() {
    const { user } = this.props;
    const content = this.props.isAuthenticated ? (
      <Routes>
        <Route path="/logout" element={<Logout />} />
        <Route path="/clubs/:clubId/" element={<Clubs />}>
          <Route element={<Layout maxWidth="lg" />}>
            <Route index element={<Dashboard />} />
            {(user.permissions.hr || user.permissions.paymentsReports) && (
              <Route
                path="payments/transactions/:transactionId"
                element={<Transaction />}
              />
            )}

            {user.permissions.hr && (
              <>
                <Route path="teams/:teamId" element={<Team />} />
                <Route path="coaches" element={<Coaches />} />
                <Route path="coaches/:coachId" element={<Coach />} />
                <Route path="players/:playerId" element={<Player />} />
              </>
            )}

            {user.level === "admin" && (
              <Route path="settings/parentsSite/*" element={<ParentsSite />} />
            )}
          </Route>

          <Route element={<Layout maxWidth="xl" />}>
            {user.permissions.hr && (
              <>
                <Route path="players" element={<Players />} />
                <Route path="teams" element={<Teams />} />
              </>
            )}

            {user.permissions.registrationAdmin && (
              <>
                <Route
                  path="subscriptions/discountRequests"
                  element={<SubscriptionDiscountRequests />}
                />
                <Route
                  path="subscriptions/otherPaymentMethodRequests"
                  element={<SubscriptionOtherPaymentMethodRequests />}
                />
              </>
            )}

            {user.permissions.registrationReports && (
              <>
                <Route path="reports/quitters" element={<Quitters />} />
                <Route
                  path="reports/subscriptionLogs"
                  element={<SubscriptionLogs />}
                />
                <Route
                  path="store/purchases/merchandise"
                  element={<Merchandise />}
                />
                <Route path="reports/debt" element={<Debt />} />
              </>
            )}

            {user.permissions.hr && (
              <>
                <Route path="players/:playerId" element={<Player />} />
                <Route path="attendance" element={<AttendanceAnalytics />} />
                <Route path="accounts/:parentId" element={<Account />} />
                <Route path="parents" element={<Parents />} />
              </>
            )}

            {user.level === "admin" && (
              <>
                <Route path="users" exact element={<Users />} />
                <Route
                  path="settings/generalSettings"
                  element={<GeneralSettings />}
                />
                <Route
                  path="settings/subscriptionProducts"
                  element={<SubscriptionProducts />}
                />
                <Route path="settings/seasons" element={<Seasons />} />
                <Route
                  path="settings/playerAlerts"
                  element={<PlayerAlerts />}
                />
              </>
            )}

            {user.permissions.paymentsReports && (
              <>
                <Route path="payments/receipts" element={<Receipts />} />
                <Route path="reports/income" exact element={<IncomeReport />} />
                <Route
                  path="reports/revenues"
                  exact
                  element={<RevenuesReport />}
                />
                <Route
                  path="reports/monthlyIncomePerProduct"
                  exact
                  element={<MonthlyIncomePerProduct />}
                />

                <Route
                  path="reports/discounts"
                  exact
                  element={<DiscountsReport />}
                />
                <Route
                  path="reports/paymentsDistribution"
                  exact
                  element={<PaymentsDistributionOverMonthsReport />}
                />
                <Route
                  path="payments/transactions"
                  element={<Transactions />}
                />
                <Route path="payments/checks" element={<Checks />} />
                <Route
                  path="payments/accountingDocuments"
                  element={<AccountingDocuments />}
                />
                <Route path="payments/deposits" element={<Deposit />} />
                <Route path="dealReceipts" element={<DealReceipts />} />
              </>
            )}

            <Route path="privacy" element={<Privacy />} />

            {user.permissions.storeAdmin && (
              <Route path="store/admin/products" element={<Products />} />
            )}

            <Route path="internalTools" exact element={<InternalTools />} />
            <Route path="prodTests" exact element={<ProdTests />} />

            <Route path="*" element={<Navigate to="" replace />} />
          </Route>

          <Route element={<Layout maxWidth={false} />}>
            {user.permissions.calendar && (
              <Route path="calendar" element={<Calendar />} />
            )}
            {user.permissions.registrationAdmin && (
              <>
                <Route path="purchases/new" element={<NewPurchase />} />
              </>
            )}
          </Route>

          <Route path="*" element={<NavigateToDefaultClub />} />
        </Route>
        <Route path="*" element={<NavigateToDefaultClub />} />
      </Routes>
    ) : (
      <>
        <Routes>
          <Route path="/payments/responder" element={<IframeResponder />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/forgotPassword" element={<ForgotPassword />} />
          <Route path="/changePassword" element={<ChangePassword />} />
          <Route path="/users/activate" element={<ActivateUser />} />
          <Route path="/login" element={<Login />} />
          <Route path="*" element={<Navigate to="/login" replace />} />
        </Routes>
      </>
    );

    return (
      <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="he">
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <Suspense>
              {this.props.loading ? <TrainlitSpinner /> : content}
            </Suspense>
          </ThemeProvider>
        </StyledEngineProvider>
      </LocalizationProvider>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.user !== null,
    user: state.auth.user,
    loading: state.auth.loading,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    autoLogin: () => dispatch(Actions.auth.autoLogin()),
    setIsLoading: (isLoading) => dispatch(Actions.auth.setIsLoading(isLoading)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
