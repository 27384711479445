import React from "react";
import { Box } from "@mui/material";

export default function RevenueIcon(props) {
  return (
    <Box sx={{ ...styles.container, backgroundColor: props.color }}>
      {props.children}
    </Box>
  );
}

const styles = {
  container: {
    borderRadius: "200px",
    height: 70,
    width: 70,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "30px",
  },
};
