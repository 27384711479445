import React, { Component } from "react";
import Avatar from "@mui/material/Avatar";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import { Link as RouterLink } from "react-router-dom";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { connect } from "react-redux";
import validator from "validator";
import { Box } from "@mui/material";

import * as Actions from "./../../store/actions";
import config from "./../../config";
import RTL from "../../layout/Rtl";
import { login } from "./loginHelper";
import ButtonSx from "../../components/base/ButtonSx";

const styles = {
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: `url(${config.apiUrl}/images/dashboard/sign_in3.png)`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    my: 8,
    mx: 4,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  submit: {
    mt: 3,
    mb: 2,
    height: 40,
    "&:disabled": {
      backgroundColor: "primary.main",
    },
  },
  buttonProgress: {
    color: "#FFFFFF",
    position: "absolute",
  },
};

class Login extends Component {
  state = {
    form: {
      email: "",
      password: "",
    },
    loginError: false,
    isLoading: false,
    isEmailValid: true,
  };

  onSignIn = async function (e) {
    e.stopPropagation();
    e.preventDefault();
    if (!this.validateEmail()) {
      return;
    }
    this.setState({ isLoading: true });

    const success = await login(
      this.props.login,
      this.state.form.password,
      this.state.form.email
    );
    if (!success) {
      this.setState({ loginError: true, isLoading: false });
    }
  };

  validateEmail = () => {
    const isEmailValid = validator.isEmail(this.state.form.email);
    this.setState({ isEmailValid });
    return isEmailValid;
  };

  handleFormChange = (field, event) => {
    this.setState({
      form: {
        ...this.state.form,
        [field]: event.target.value,
      },
    });
  };

  render() {
    return (
      <RTL>
        <Grid dir="rtl" container component="main" sx={styles.root}>
          <CssBaseline />
          <Grid
            item
            xs={12}
            sm={8}
            md={5}
            component={Paper}
            elevation={6}
            square
          >
            <Box sx={styles.paper}>
              <Avatar sx={{ backgroundColor: "primary.main" }}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                כניסה
              </Typography>
              <form
                style={{
                  width: "100%", // Fix IE 11 issue.
                  marginTop: 1,
                }}
                noValidate
              >
                <TextField
                  disabled={this.state.isLoading}
                  onChange={(event) => this.handleFormChange("email", event)}
                  value={this.state.form.email}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="אימייל"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  error={!this.state.isEmailValid}
                  helperText={
                    this.state.isEmailValid ? "" : "יש להזין כתובת אימייל תקינה"
                  }
                />
                <TextField
                  disabled={this.state.isLoading}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="סיסמה"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  onChange={(event) => this.handleFormChange("password", event)}
                />
                {this.state.loginError && (
                  <div style={{ textAlign: "center", fontSize: 18 }}>
                    שם משתמש או סיסמה לא נכונים. אנא נסה שוב
                  </div>
                )}
                <div style={{ position2: "relative" }}>
                  <ButtonSx
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={(e) => this.onSignIn(e)}
                    buttonProps={{ type: "submit" }}
                    isLoading={this.state.isLoading}
                  >
                    כניסה
                  </ButtonSx>
                </div>
                <Grid container>
                  <Grid item xs>
                    <RouterLink
                      color="secondary"
                      to={`/forgotPassword`}
                      sx={styles.link}
                    >
                      שכחת סיסמה?
                    </RouterLink>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Grid>
          <Grid item xs={false} sm={4} md={7} sx={styles.image} />
        </Grid>
      </RTL>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

function mapDispatchToProps(dispatch) {
  return {
    login: (user, token) => dispatch(Actions.auth.login(user, token)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
