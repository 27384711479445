import React from "react";
import { Box, DialogActions, DialogContent } from "@mui/material";

import Dialog from "../../components/Dialog";
import ButtonSx from "../../components/base/ButtonSx";

export default function ArchiveTeamDialog(props) {
  return (
    <Dialog
      onClose={props.onClose}
      open={props.open}
      fullWidth
      title="העברת קבוצה לארכיון"
    >
      <DialogContent dividers>
        <Box sx={{ py: 2 }}>
          האם אתם רוצים להעביר את &quot;{props.team.name}&quot; לארכיון?
          <Box sx={{ fontWeight: "bold", mt: 1 }}>
            שימו לב, לאחר ביצוע פעולה זו לא יהיה ניתן לבצע תשלומים עבור פעילות
            הקבוצה.
            <div>אנא וודאו כי אין חובות לפני העברת הקבוצה לארכיון</div>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <ButtonSx onClick={props.onClose}>ביטול</ButtonSx>
        <ButtonSx debounce onClick={props.onArchive}>
          העברה לארכיון
        </ButtonSx>
      </DialogActions>
    </Dialog>
  );
}
