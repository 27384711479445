import React, { useMemo } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import {
  Select,
  FormControl,
  InputLabel,
  Grid,
  FormHelperText,
} from "@mui/material";

import MenuItem from "../../components/base/MenuItem";

function FormDateField(props) {
  const currentYear = useMemo(() => moment().year(), []);

  return (
    <>
      <Grid container spacing={0} sx={{ margin: 0 }}>
        <Grid item xs={4}>
          <FormControl variant="standard" sx={{ m: 1, ml: 0, pr: 1 }} fullWidth>
            <InputLabel id="dob-year">תאריך לידה</InputLabel>
            <Select
              labelId="dob-year"
              variant="standard"
              value={props.year}
              onChange={(e) => props.onChange({ dobYear: e.target.value })}
              error={props.error !== undefined}
              placeholder="שנה"
              fullWidth
            >
              <MenuItem value="">
                <em>שנה</em>
              </MenuItem>
              {[...Array(100).keys()].map((index) => (
                <MenuItem key={index} value={currentYear - index}>
                  {currentYear - index}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={4}>
          <FormControl variant="standard" sx={{ m: 1, ml: 0, pr: 1 }} fullWidth>
            <InputLabel />
            <Select
              variant="standard"
              value={props.month}
              onChange={(e) => props.onChange({ dobMonth: e.target.value })}
              error={props.error !== undefined}
              placeholder="חודש"
              fullWidth
            >
              <MenuItem value="">
                <em>חודש</em>
              </MenuItem>
              {[...Array(12).keys()].map((index) => (
                <MenuItem key={index} value={index + 1}>
                  {index + 1}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl variant="standard" sx={{ m: 1, ml: 0 }} fullWidth>
            <InputLabel />
            <Select
              variant="standard"
              value={props.day}
              onChange={(e) => props.onChange({ dobDay: e.target.value })}
              error={props.error !== undefined}
              placeholder="יום"
              fullWidth
            >
              <MenuItem value="">
                <em>יום</em>
              </MenuItem>
              {[...Array(31).keys()].map((day) => (
                <MenuItem key={day} value={day + 1}>
                  {day + 1}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        {props.error !== undefined && (
          <FormHelperText error>{props.error}</FormHelperText>
        )}
      </Grid>
    </>
  );
}

FormDateField.propTypes = {
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  year: PropTypes.number,
  month: PropTypes.number,
  dat: PropTypes.number,
};

export default FormDateField;
