import React from "react";
import { TextField } from "@mui/material";
import { generateErrorMessage } from "../../lib/formHelpers";

export default function FormTextField(props) {
  const handleChange = (e) => {
    let val = e.target.value;
    if (props.fieldType === "price") {
      val = Math.floor(val * 100) / 100;
      if (isNaN(val)) {
        val = 0;
      }
    }
    props.onChange({ [props.field]: val });
  };
  const defaultHelperText = props.maxLength
    ? `${props.form[props.field].length}/${props.maxLength}`
    : props.defaultHelperText;

  let value = props.form[props.field];
  if (props.fieldType === "price") {
    value = value.toString();
  }

  return (
    <TextField
      variant="standard"
      fullWidth
      label={props.label}
      value={value}
      onChange={handleChange}
      error={props.errors[props.field] !== undefined}
      helperText={
        generateErrorMessage(
          props.field,
          props.errors,
          ((props.formSettings || {})[props.field] || {}).errorMessages
        ) || defaultHelperText
      }
      margin="dense"
      {...props.textFieldProps}
      inputProps={{
        ...(props.textFieldProps || {}).inputProps,
        ...(props.maxLength ? { maxLength: props.maxLength } : {}),
      }}
    >
      {props.children}
    </TextField>
  );
}
