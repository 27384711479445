import React, { useState, useEffect } from "react";
import {
  DialogActions,
  DialogContent,
  TextField,
  Typography,
} from "@mui/material";
import validator from "validator";

import Dialog from "../../components/Dialog";
import ButtonSx from "../../components/base/ButtonSx";

export default function ResendEmailDialog(props) {
  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);

  useEffect(() => {
    setEmail("");
    setError(false);
  }, [props.open]);

  const handleSend = () => {
    if (validator.isEmail(email)) {
      setError(false);
      props.onSend(email);
    } else {
      setError(true);
    }
  };

  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      onClose={props.onClose}
      open={props.open}
      title={"שליחת קבלה ללקוח"}
    >
      <DialogContent dividers>
        <Typography gutterBottom>הזינו כתובת אימייל לשליחת הקבלה:</Typography>
        <TextField
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          error={error}
          helperText={error ? "יש להזין כתובת אימייל תקינה" : " "}
          placeholder="כתובת אימייל"
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <ButtonSx onClick={props.onClose}>ביטול</ButtonSx>
        <ButtonSx onClick={handleSend} isLoading={props.isResending}>
          שליחה
        </ButtonSx>
      </DialogActions>
    </Dialog>
  );
}
