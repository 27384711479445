import React, { useState } from "react";
import moment from "moment";
import { Link as RouterLink } from "react-router-dom";
import { Link } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import Report from "../Reports/Report";
import { renderCell, sortValueCell } from "../../lib/tableHelpers";
import CreateDealReceiptDialog from "./CreateDealReceiptDialog";
import SnackbarNg from "../../components/base/SnackbarNg";
import ButtonSx from "../../components/base/ButtonSx";

const columns = [
  { id: "createdAt", label: "תאריך ושעה", preset: "datetime", minWidth: 140 },
  {
    id: "docNumber",
    label: "מספר מסמך",
    preset: "link",
    linkProps: { target: "_" },
  },
  { id: "clientName", label: "לקוח" },
  { id: "clientId", label: "ת.ז/ח.פ/ע.מ" },
  { id: "price", label: "מחיר" },
  { id: "dueDate", label: "לתשלום עד", preset: "date", minWidth: 140 },
];

const filtersStructure = [{ type: "date" }];

export default function DealReceipts() {
  const [initialFiltersState] = useState({
    date: { from: moment().startOf("day"), to: moment().endOf("day") },
  });
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const [snackbarDetails, setSnackbarDetails] = useState({ isOpen: false });

  const baseRenderer = (columnId, dealReceipt) => {
    switch (columnId) {
      case "docNumber":
        return {
          to: dealReceipt.url,
          label: dealReceipt.docNumber,
        };

      default:
        return dealReceipt[columnId];
    }
  };

  const addNewDealReceiptButton = (
    <ButtonSx
      onClick={() => setIsCreateDialogOpen(true)}
      startIcon={<AddIcon />}
      sx={{ width: null }}
    >
      דרישת תשלום חדשה
    </ButtonSx>
  );

  return (
    <>
      <CreateDealReceiptDialog
        open={isCreateDialogOpen}
        onClose={() => setIsCreateDialogOpen(false)}
        onComplete={(url, docNumber) => {
          setIsCreateDialogOpen(false);
          setSnackbarDetails({ isOpen: true, key: docNumber, docNumber, url });
        }}
      />
      <SnackbarNg
        snackKey={snackbarDetails.key}
        open={snackbarDetails.isOpen}
        onClose={() => setSnackbarDetails({ isOpen: false, key: null })}
        message={
          <div>
            דרישת תשלום{" "}
            <Link
              component={RouterLink}
              to={snackbarDetails.url}
              target="_"
              underline="hover"
              sx={{ color: "#FFF" }}
            >
              {snackbarDetails.docNumber}
            </Link>{" "}
            נוצרה בהצלחה
          </div>
        }
      />
      <Report
        title="דרישות תשלום"
        url="other/dealReceipts?"
        initialFiltersState={initialFiltersState}
        filtersStructure={filtersStructure}
        renderCell={renderCell(columns, false, baseRenderer)}
        sortValueCell={sortValueCell(columns, baseRenderer)}
        columns={columns}
        exportFileName="דרישות תשלום"
        renderCellExport={renderCell(columns, true, baseRenderer)}
        defaultSortBy={0}
        defaultSortDirection={"desc"}
        leftItem={addNewDealReceiptButton}
      />
    </>
  );
}
