import React from "react";
import { Checkbox, List } from "@mui/material";

import ListItem from "../../../components/ListItem";

export default function ResourceGroup(props) {
  const { group } = props;

  return (
    <List disablePadding sx={{ border: "1px solid #DDD", borderRadius: 1 }}>
      {group.resources.map((resource, i) => (
        <ListItem
          divider={i < group.resources.length - 1}
          key={resource._id}
          primaryText={resource.name}
          icon={
            <Checkbox
              edge="start"
              checked={resource.selected}
              tabIndex={-1}
              disableRipple
            />
          }
          listItemTextProps={{ sx: { ml: -1 } }}
          button
          onClick={() => props.onChange(resource)}
        />
      ))}
    </List>
  );
}
