import React, { useState } from "react";
import {
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  ListItemButton,
} from "@mui/material";

import { displayPrice } from "../../../utils/helpers";
import Paper2Sx from "../../../components/Paper2Sx";
import EditNewDirectSubscriptionPrices from "./EditNewDirectSubscriptionPrices";
import ButtonSx from "../../../components/base/ButtonSx";

export default function SubscriptionCostForm(props) {
  const [showDialog, setShowDialog] = useState(false);

  const cartItem = props.cart.subscription || {};
  const external = cartItem.product?._id === "external";

  const handlePricesUpdated = (newSubscription) => {
    props.onCartSubscriptionChange({
      monthlyPrices: newSubscription.monthlyPrices,
      fees: newSubscription.fees,
    });
    props.onChange({
      monthlyPrices: newSubscription.monthlyPrices,
      fees: newSubscription.fees,
      discountReason: newSubscription.discountReason,
    });
    setShowDialog(false);
  };

  const renderCosts = () => {
    const numberOfMonths = props.state.monthlyPrices?.filter(
      (m) => !m.oneCharge
    ).length;

    let basePrice = "";
    if (props.state.pricing) {
      basePrice = `מחיר בסיס: ${displayPrice(props.state.pricing.unitPrice)}`;
      if (
        ["season", "camp"].indexOf(
          props.state.product.subscriptionDetails.subscriptionType
        ) > -1
      ) {
        basePrice += " לחודש";
      }
    }

    return (
      <Grid item xs={12} sx={{}}>
        <List disablePadding>
          {props.state.fees?.map((fee) => (
            <ListItem key={fee.feeProductId} divider disablePadding>
              <ListItemButton disableRipple sx={{ cursor: "auto", height: 60 }}>
                <ListItemText primary={fee.title} />
                <ListItemSecondaryAction>
                  {displayPrice(fee.price)}
                </ListItemSecondaryAction>
              </ListItemButton>
            </ListItem>
          ))}
          {!props.state.fees?.length && (
            <ListItem divider sx={{ height: 60 }}>
              <ListItemText primary="דמי רישום" />
              <ListItemSecondaryAction>-</ListItemSecondaryAction>
            </ListItem>
          )}
          <ListItem divider disablePadding>
            <ListItemButton disableRipple sx={{ cursor: "auto", height: 60 }}>
              <ListItemText
                primary={`פעילות${
                  numberOfMonths ? ` - ${numberOfMonths} חודשים` : ""
                }`}
                secondary={basePrice}
              />
              <ListItemSecondaryAction>
                {cartItem.price ? displayPrice(cartItem.price) : "-"}
              </ListItemSecondaryAction>
            </ListItemButton>
          </ListItem>

          {props.state.discountReason && (
            <ListItem divider>
              <ListItemText
                primary="הערות / הנחה"
                secondary={props.state.discountReason}
              ></ListItemText>
              <ListItemSecondaryAction />
            </ListItem>
          )}

          <ListItem divider sx={{ height: 60 }}>
            <ListItemText primary="סה״כ" />
            <ListItemSecondaryAction>
              <b>
                {displayPrice(props.cart.totals.costs.subscriptionPlusFees)}
              </b>
            </ListItemSecondaryAction>
          </ListItem>

          <ListItem sx={{ justifyContent: "center" }}>
            <ButtonSx
              fullWidth
              sx={{ width: 200 }}
              disabled={!props.state.pricingId || !props.state.startDate}
              onClick={() => setShowDialog(true)}
            >
              עדכון עלויות
            </ButtonSx>
          </ListItem>
        </List>
      </Grid>
    );
  };

  return (
    <Paper2Sx
      sx={styles.paperContainer}
      paperSx={{ flex: 1, padding: 0 }}
      externalTitle="עלות"
    >
      <Grid container>
        {props.hasPayments && !external ? (
          renderCosts()
        ) : (
          <Grid item xs={12}>
            <Typography sx={styles.indirect}>
              התשלום לקבוצה זו לא מתבצע דרך טריינליט
            </Typography>
          </Grid>
        )}
      </Grid>

      <EditNewDirectSubscriptionPrices
        open={showDialog}
        onClose={() => setShowDialog(false)}
        subscription={props.state}
        onComplete={handlePricesUpdated}
        allowZero
      />
    </Paper2Sx>
  );
}

const styles = {
  paperContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  indirect: {
    textAlign: "center",
    mt: 2,
  },
};
