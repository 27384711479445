import React from "react";
import { Tooltip as MuiTooltip, Typography } from "@mui/material";
import { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

const StyledTooltip = styled(({ className, ...props }) => (
  <MuiTooltip {...props} classes={{ popper: className }} />
))(({ sx }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    fontSize: "14px",
    backgroundColor: "#F5F5F5",
    color: "#000",
    boxShadow: 1,
    ...sx,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#F5F5F5",
  },
}));

export default function ToolTip({ title, children, ...props }) {
  return (
    <StyledTooltip
      {...props}
      title={
        <Typography component="span" dir="rtl" {...props.titleProps}>
          {title}
        </Typography>
      }
    >
      {children}
    </StyledTooltip>
  );
}
