import React, { useState } from "react";
import {
  ClickAwayListener,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Grow,
  Paper,
  Popper,
  useMediaQuery,
} from "@mui/material";
import { FaPercent } from "react-icons/fa6";

import PriceInputNg from "../../../PriceInputNG";
import DiscountPercent from "../../../../containers/Subscriptions/DiscountPercent";
import { roundPrice } from "../../../../utils/helpers";
import IconButtonTooltip from "../../../base/IconButtonTooltip";
import ButtonSx from "../../../base/ButtonSx";

export default function DiscountPopper(props) {
  const anchorRef = React.useRef(null);
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = useState({});
  const [pricing, setPricing] = useState({});
  const isSmUp = useMediaQuery((theme) => theme.breakpoints.up("sm"), {
    noSsr: true,
  });
  const { subscription } = props;
  const monthly =
    props.subscriptionType === "season" || props.subscriptionType === "camp";

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
    const pricing = subscription?.product?.subscriptionDetails?.pricings.find(
      (p) => p._id === subscription.pricingId
    );
    setPricing(pricing);
    setValue(pricing?.unitPrice || "");
  };

  const handleClose = () => {
    setOpen(false);
  };

  const content = (
    <div>
      <DialogTitle>עדכון מחיר</DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <PriceInputNg
              fullWidth
              value={value}
              onChange={setValue}
              label={`מחיר${monthly ? " חודשי" : ""}`}
            />
          </Grid>
          <Grid item xs={12}>
            <DiscountPercent
              value={value}
              onPercentChanged={(percent) => {
                const price = roundPrice(
                  pricing.unitPrice * ((100 - percent) / 100)
                );
                setValue(price);
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <ButtonSx onClick={handleClose}>ביטול</ButtonSx>
        <ButtonSx
          onClick={() => {
            props.onChange(value, parseFloat(value));
            handleClose();
          }}
        >
          אישור
        </ButtonSx>
      </DialogActions>
    </div>
  );

  return (
    <>
      <IconButtonTooltip
        aria-label="close"
        onClick={handleToggle}
        disabled={props.disabled}
        title={`עדכון מחיר${monthly ? " חודשי" : ""}`}
        iconButtonProps={{ ref: anchorRef }}
      >
        <FaPercent />
      </IconButtonTooltip>

      {isSmUp ? (
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
          placement={isSmUp ? "left" : undefined}
          sx={{ zIndex: 10000 }}
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: "left center" }}
            >
              <Paper sx={styles.paper} elevation={5}>
                <ClickAwayListener onClickAway={handleClose}>
                  {content}
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      ) : (
        <Dialog dir="rtl" open={open} onClose={() => setOpen(false)}>
          {content}
        </Dialog>
      )}
    </>
  );
}

const styles = {
  paper: {
    border: "1px solid #CCC",
    maxWidth: 350,
  },
};
