import React, { useEffect, useState } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  TextField,
  useMediaQuery,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import BackArrowIcon from "@mui/icons-material/ArrowBackIos";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";

import MenuItem from "../base/MenuItem";
import { displayPrice } from "../../utils/helpers";
import PriceInputNg from "../PriceInputNG";
import ButtonSx from "../base/ButtonSx";
import { AdvancedImage } from "@cloudinary/react";
import { cloudinaryImage } from "../../utils/cloudinary";

const ProductImage = styled(AdvancedImage)({
  alignSelf: "center",
  borderRadius: 7,
  height: 150,
  width: 150,
  border: "1px solid #F0F0F0",
});

export default function PurchaseProductDialog(props) {
  const [currentPlayer, setCurrentPlayer] = useState("");
  const [currentOption, setCurrentOption] = useState("");
  const [currentQuantity, setCurrentQuantity] = useState("");
  const [currentPrice, setCurrentPrice] = useState("");
  const [priceEditMode, setPriceEditMode] = useState(false);
  const [isPriceValid, setIsPriceValid] = useState(true);
  const isSmUp = useMediaQuery((t) => t.breakpoints.up("sm"), { noSsr: true });

  const product = props.product || {};
  const hasOptions = product.options?.length > 0;

  useEffect(() => {
    if (props.product) {
      setCurrentPrice(props.product.price);
    }
  }, [props.product]);

  useEffect(() => {
    if (!props.isOpen) {
      setCurrentOption("");
      setCurrentPlayer("");
      setCurrentQuantity("");
      setCurrentPrice("");
      setPriceEditMode(false);
    }
  }, [props.isOpen, props.players]);

  // prevent dialog view getting broken right before closing
  if (!props.product) {
    return <span />;
  }

  const handleAddItem = () => {
    props.onAddItem(
      currentQuantity,
      currentPrice,
      currentPlayer,
      currentOption
    );
  };

  const image = cloudinaryImage(product.imagePublicId, {
    version: product.imageVersion,
    size: "thumbnail",
  });

  return (
    <Dialog
      open={props.isOpen}
      maxWidth="xs"
      dir="rtl"
      onClose={props.onClose}
      fullScreen={!isSmUp}
    >
      <DialogContent dividers>
        <Box sx={styles.header}>
          <IconButton onClick={props.onClose} size="large">
            {isSmUp ? <CloseIcon /> : <BackArrowIcon />}
          </IconButton>
        </Box>
        <Grid container spacing={1}>
          <Grid item container xs={12} justifyContent="center">
            {props.product.otp ? (
              <Box sx={{ ...styles.productImage, ...styles.optProductImage }}>
                פריט חד פעמי
              </Box>
            ) : (
              <ProductImage cldImg={image} alt="Logo" />
            )}
          </Grid>
          <Grid item xs={12}>
            <Box sx={styles.title}>{product.title}</Box>
          </Grid>
          {product.description && (
            <Grid item xs={12}>
              {product.description}
            </Grid>
          )}
          <Grid item xs={12} container alignItems="center">
            {priceEditMode ? (
              <PriceInputNg
                label="מחיר"
                fullWidth
                value={currentPrice}
                onChange={(priceStr, price) => {
                  // allowing 0 price doesn't work (no purchase is created)
                  setIsPriceValid(
                    priceStr !== "" && price > 0 && price <= props.product.price
                  );
                  setCurrentPrice(priceStr);
                }}
                error={!isPriceValid}
                helperText={!isPriceValid ? "יש להזין מחיר תקין" : null}
              />
            ) : (
              <>
                {displayPrice(currentPrice)}
                <IconButton onClick={() => setPriceEditMode(true)} size="large">
                  <EditIcon />
                </IconButton>
              </>
            )}
          </Grid>

          <Grid item xs={12}>
            <TextField
              variant="standard"
              select
              fullWidth
              value={currentPlayer}
              onChange={(e) => setCurrentPlayer(e.target.value)}
              label="ספורטאי"
            >
              {props.players.map((player) => (
                <MenuItem value={player._id} key={player._id}>
                  {player.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          {product.options?.length > 0 && (
            <Grid item xs={12}>
              <TextField
                variant="standard"
                select
                label={product.optionsType}
                fullWidth
                value={currentOption}
                onChange={(e) => setCurrentOption(e.target.value)}
              >
                {product.options.map((option) => (
                  <MenuItem value={option._id} key={option._id}>
                    {option.title}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          )}

          <Grid item xs={12}>
            <TextField
              variant="standard"
              select
              label="כמות"
              fullWidth
              value={currentQuantity}
              onChange={(e) => setCurrentQuantity(e.target.value)}
            >
              {[...Array(10).keys()].map((i) => (
                <MenuItem value={i + 1} key={i}>
                  {i + 1}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} container justifyContent="center">
            <ButtonSx
              sx={styles.addButton}
              onClick={handleAddItem}
              disabled={
                !currentPlayer ||
                (hasOptions && !currentOption) ||
                !currentQuantity ||
                !isPriceValid
              }
            >
              הוספה לסל הקניות
            </ButtonSx>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

const styles = {
  header: {
    display: "flex",
    justifyContent: "flex-end",
  },
  addButton: {
    marginTop: "30px",
    width: 200,
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  optProductImage: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid #EEE",
    borderRadius: "7px",
    width: 200,
    height: 200,
    fontSize: 20,
  },
};
