import React from "react";
import { Dialog, DialogTitle, DialogActions, Button } from "@mui/material";

function SendNotificationDialog(props) {
  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      maxWidth="xs"
      fullWidth
      dir="rtl"
    >
      <DialogTitle>האם ברצונך לעדכן את המשתתפים באירוע?</DialogTitle>
      <DialogActions>
        <Button onClick={props.onClose} color="primary">
          {props.origin === "editing" ? "חזרה לעריכה" : "ביטול"}
        </Button>
        <Button onClick={() => props.onComplete(false)} color="primary">
          אל תשלח
        </Button>
        <Button onClick={() => props.onComplete(true)} color="primary">
          שלח
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default SendNotificationDialog;
