import React, { useMemo } from "react";
import { Box, Grid, Typography } from "@mui/material";
import _ from "lodash";

import SumUnderlinePaper from "../UnderlinePaper";
import { displayPrice, roundPrice } from "../../utils/helpers";

export default function PlayerSubscriptionSum({ players }) {
  const cost = useMemo(
    () =>
      roundPrice(_.sumBy(players, (p) => p.subscriptions[0]?.totalPrice || 0)),
    [players]
  );
  const paid = useMemo(
    () => roundPrice(_.sumBy(players, (p) => p.subscriptions[0]?.paid || 0)),
    [players]
  );

  const debt = useMemo(() => roundPrice(cost - paid), [cost, paid]);

  return (
    <Grid container spacing={3}>
      <Grid item xs>
        <SumUnderlinePaper type="info">
          <Box sx={styles.sumContainer}>
            <Typography variant="h6" color="textSecondary" component="span">
              צפי הכנסות
            </Typography>
            <Typography component="span" variant="h5">
              {displayPrice(cost)}
            </Typography>
          </Box>
        </SumUnderlinePaper>
      </Grid>
      <Grid item xs>
        <SumUnderlinePaper type="success">
          <Box sx={styles.sumContainer}>
            <Typography variant="h6" color="textSecondary" component="span">
              תקבולים
            </Typography>
            <Typography component="span" variant="h5">
              {displayPrice(paid)}
            </Typography>
          </Box>
        </SumUnderlinePaper>
      </Grid>
      <Grid item xs>
        <SumUnderlinePaper type="error">
          <Box sx={styles.sumContainer}>
            <Typography variant="h6" color="textSecondary" component="span">
              חובות
            </Typography>
            <Typography component="span" variant="h5">
              {displayPrice(debt)}
            </Typography>
          </Box>
        </SumUnderlinePaper>
      </Grid>
    </Grid>
  );
}

const styles = {
  sumContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
};
