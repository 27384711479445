import React from "react";
import { Box } from "@mui/material";

const styles = {
  fullHeightContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  paper: {
    border: "1px solid #EEE",
    backgroundColor: "#FFF",
    borderRadius: "6px",
    padding: 1,
  },
  fullHeightPaper: {
    height: "100%",
  },
  title: {
    fontSize: 16,
    fontWeight: "bold",
    paddingLeft: 1,
    paddingBottom: 1,
  },
};

export default function Paper2Sx(props) {
  const renderTitle = () => {
    if (props.externalTitle) {
      return (
        <Box sx={{ ...styles.title, ...props.titleSx }}>
          {props.externalTitle}
        </Box>
      );
    }

    return null;
  };

  return (
    <Box
      sx={{
        ...(props.fullHeight ? styles.fullHeightContainer : {}),
        ...props.sx,
      }}
    >
      {renderTitle()}
      <Box
        sx={{
          ...styles.paper,
          ...(props.fullHeight ? styles.fullHeightPaper : {}),
          ...props.paperSx,
        }}
      >
        {props.children}
      </Box>
    </Box>
  );
}
