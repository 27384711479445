import React from "react";
import { IconButton, Fab, Tooltip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import MailIcon from "@mui/icons-material/MailOutline";
import { connect } from "react-redux";
import ReactGA from "react-ga4";

import axios from "../../utils/axios";
import EditUserDialog from "./EditUserDialog";
import DeleteUserDialog from "./DeleteUserDialog";
import Table from "../../components/table/Table";
import IconButtonTooltip from "../../components/base/IconButtonTooltip";
import { userLevels, userStatuses } from "../../utils/dictionaries";
import SnackbarNg from "../../components/base/SnackbarNg";
import TlPaper from "../../components/TlPaper";

class Users extends React.Component {
  state = {
    users: [],
    editItem: null,
    deleteItem: null,
    addItem: false,
    isSnackbarOpen: false, // if open value = weekstatus._id otherwise false
    snackbarAction: null,
  };

  componentDidMount = async () => {
    ReactGA.send({
      hitType: "pageview",
      page: "/users",
      title: "Users",
    });

    this.loadData();
  };

  loadData = async () => {
    this.setState({ isLoading: true });
    const res = await axios.get("/managers");
    this.setState({ users: res.data, isLoading: false });
  };

  resendInvitation = (user) => async () => {
    this.setState({ isLoading: true });
    await axios.post(`/managers/${user._id}/sendInvitation`);
    this.setState({
      // isLoading: false, don't set it until loadData is finished so the buttons are disabled
      isSnackbarOpen: user._id,
      snackbarAction: "resend",
    });
    this.loadData();
  };

  columns = [
    { id: "name", label: "שם" },
    { id: "email", label: "אי-מייל" },
    { id: "level", label: "סוג משתמש" },
    { id: "status", label: "סטטוס" },
    { id: "actions", label: "" },
  ];

  sortValueCell = (columnId, enrollment) => {
    switch (columnId) {
      default:
        return enrollment[columnId];
    }
  };

  renderCell = (columnId, user) => {
    switch (columnId) {
      case "status":
        return user.invitationExpired ? (
          <span>
            פג תוקף הזמנה
            <IconButtonTooltip
              title="שליחת הזמנה חדשה"
              onClick={this.resendInvitation(user)}
              disabled={this.state.isLoading}
            >
              <MailIcon size="small" />
            </IconButtonTooltip>
          </span>
        ) : (
          userStatuses[user.status]
        );

      case "level":
        return userLevels[user.level];
      case "actions":
        return (
          <div>
            <IconButton
              onClick={() => this.setState({ editItem: user })}
              disabled={this.state.isLoading}
              size="large"
            >
              <EditIcon size="small" />
            </IconButton>
            <IconButton
              onClick={() => this.setState({ deleteItem: user })}
              disabled={
                user._id === this.props.user._id || this.state.isLoading
              }
              size="large"
            >
              <DeleteIcon size="small" />
            </IconButton>
          </div>
        );

      default:
        return user[columnId];
    }
  };

  handleClose = () => {
    this.setState({ deleteItem: null, editItem: null, addItem: false });
  };

  handleCompleted = (userId, action) => {
    this.setState({
      deleteItem: null,
      editItem: null,
      addItem: false,
      isSnackbarOpen: userId,
      snackbarAction: action,
    });
    this.loadData();
  };

  renderSnackbarMessage = () => {
    switch (this.state.snackbarAction) {
      case "resend":
        return "ההזמנה נשלחה בהצלחה";

      case "create":
        return "המשתמש נוצר בהצלחה";

      case "edit":
        return "המשתמש עודכן בהצלחה";

      case "delete":
        return "המשתמש נמחק בהצלחה";

      default:
        break;
    }
  };

  render() {
    const addItem = (
      <Tooltip title="הוספת משתמש">
        <Fab
          color="primary"
          aria-label="Add"
          size="small"
          onClick={() => this.setState({ addItem: true })}
        >
          <AddIcon />
        </Fab>
      </Tooltip>
    );

    return (
      <TlPaper title="משתמשים" leftItem={addItem} titleBackground>
        <Table
          title="משתמשים"
          columns={this.columns}
          renderCell={this.renderCell}
          rows={this.state.users}
          sortValueCell={this.sortValueCell}
        />
        <EditUserDialog
          open={this.state.editItem !== null || this.state.addItem}
          onClose={this.handleClose}
          user={this.state.editItem}
          onComplete={this.handleCompleted}
        />

        <DeleteUserDialog
          open={this.state.deleteItem !== null}
          onClose={this.handleClose}
          user={this.state.deleteItem}
          onComplete={this.handleCompleted}
        />

        <SnackbarNg
          snackKey={this.state.isSnackbarOpen}
          open={this.state.isSnackbarOpen !== false}
          onClose={() => {
            this.setState({ isSnackbarOpen: false });
          }}
          severity="success"
          message={this.renderSnackbarMessage()}
        />
      </TlPaper>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.auth.user,
  };
}

export default connect(mapStateToProps)(Users);
