import React from "react";
import {
  TextField,
  Switch,
  Typography,
  Grid,
  DialogContent,
  DialogActions,
  Fab,
  FormHelperText,
  Box,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import ImageIcon from "@mui/icons-material/AddAPhotoOutlined";
import validator from "validator";
import _ from "lodash";

import axios from "../../../utils/axios";
import SelectImage from "./SelectImage";
import Dialog from "../../../components/Dialog";
import ProductOptionsDND from "./ProductOptionsDND";
import MenuItem from "../../../components/base/MenuItem";
import ButtonSx from "../../../components/base/ButtonSx";
import { AdvancedImage } from "@cloudinary/react";
import { cloudinaryImage } from "../../../utils/cloudinary";

export default class EditProductDialog extends React.Component {
  initialProduct = () => ({
    title: "",
    description: "",
    price: "",
    active: true,
    accountingExportNumber: "",
    hasOptions: false,
    optionsType: "none",
    options: [],
  });

  initState = () => ({
    image: null,
    isLoading: true,
    product: this.initialProduct(),
    selectImageDialogOpen: false,
    errors: {},
  });

  state = this.initState();

  componentDidUpdate = async (prevProps) => {
    if (!prevProps.open && this.props.open) {
      this.loadData();
    }
  };

  showSnackbar = (variant, message, key) => {
    this.setState({
      isSnackbarOpen: true,
      snackbarParams: { variant, message },
      snackKey: key,
    });
  };

  loadData = async () => {
    this.setState(this.initState());

    let product;
    if (this.props.product._id) {
      const res = await axios.get(`/store/products/${this.props.product._id}`);

      product = _.pick(res.data, [
        "_id",
        "title",
        "price",
        "active",
        "imagePublicId",
        "imageVersion",
        "accountingExportNumber",
        "options",
        "optionsType",
      ]);

      product.optionsType = product.optionsType || "none";
      product.description = res.data.description || "";
    } else {
      product = this.initialProduct();
    }

    this.setState({
      isLoading: false,
      product,
    });
  };

  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  handleSave = async () => {
    const valid = this.validate();
    if (!valid) return;
    this.setState({ isSaving: true });

    const formData = new FormData();
    const { product } = this.state;
    formData.append("title", product.title);
    formData.append("description", product.description);
    formData.append("price", product.price);
    formData.append("active", product.active);
    if (this.props.requireAccountingExportNumber) {
      formData.append("accountingExportNumber", product.accountingExportNumber);
    }

    formData.append(
      "options",
      JSON.stringify(
        product.options.map((option) => ({
          title: option.title,
          _id: option._id.startsWith("localOption_") ? undefined : option._id,
        }))
      )
    );
    if (product.optionsType !== "none") {
      formData.append("optionsType", product.optionsType);
    }

    if (this.state.image) {
      formData.append("file", this.state.blob);
    }

    if (product._id) {
      await axios.patch(`/store/products/${product._id}`, formData);
    } else {
      await axios.post("/store/products", formData);
    }

    this.setState({ isSaving: false });
    this.props.onComplete();
  };

  handleFieldChange = (field, value) => {
    this.setState((prevState) => {
      return { product: { ...prevState.product, [field]: value } };
    });
  };

  validate = () => {
    const errors = {};
    let isValid = true;
    const { product } = this.state;
    const title = product.title.trim();
    if (!title) {
      errors.title = "שדה חובה";
    } else if (!validator.isLength(title, { min: 2 })) {
      errors.title = "שדה לא תקין";
    }

    const { price } = product;
    if (!price) {
      errors.price = "שדה חובה";
      isValid = false;
    } else if (price < 1) {
      errors.price = "שדה לא תקין";
      isValid = false;
    }

    if (!this.state.image && !product.imagePublicId) {
      errors.image = "יש להוסיף תמונה";
      isValid = false;
    }

    if (this.props.requireAccountingExportNumber) {
      const accountingExportNumber = product.accountingExportNumber.trim();
      if (!accountingExportNumber) {
        errors.accountingExportNumber = "שדה חובה";
        isValid = false;
      } else if (accountingExportNumber.length < 3) {
        errors.accountingExportNumber = "שדה לא תקין";
        isValid = false;
      }
    }

    if (product.optionsType !== "none") {
      if (product.options.length === 0) {
        errors.options = "יש להוסיף אפשרויות בחירה";
        isValid = false;
      } else {
        for (let index = 0; index < product.options.length; index++) {
          const option = product.options[index];
          const title = option.title.trim();
          if (title === "") {
            option.error = "יש להזין אפשרות בחירה";
            isValid = false;
          } else {
            // the first option is ok
            const sameOptions = product.options.filter(
              (opt, i) => opt.title.trim() === title && i < index
            );
            if (sameOptions.length > 0) {
              option.error = "אפשרות בחירה זו כבר קיימת";
              isValid = false;
            } else {
              option.error = null;
            }
          }
        }
      }
    }

    this.setState({ errors });
    return isValid;
  };

  renderTitle = () => (
    <Box sx={styles.titleContainer}>
      <span style={{ flexGrow: 1 }}>
        {this.props.product && this.props.product._id ? "עריכת" : "הוספת"} מוצר
      </span>

      <Typography variant="subtitle1">
        {this.props.product ? (
          <Grid container alignItems="center">
            <Grid item>לא פעיל</Grid>
            <Grid item>
              <Switch
                checked={this.state.product.active}
                name="active"
                onChange={() =>
                  this.handleFieldChange("active", !this.state.product.active)
                }
              />
            </Grid>
            <Grid item>פעיל</Grid>
          </Grid>
        ) : (
          <span />
        )}
      </Typography>
    </Box>
  );

  defaultTextFieldParams = (field) => ({
    margin: "dense",
    value: this.state.product[field],
    onChange: (e) => this.handleFieldChange(field, e.target.value),
    fullWidth: true,
    error: Object.prototype.hasOwnProperty.call(this.state.errors, field),
    helperText: this.state.errors[field] || " ",
    // InputLabelProps: { shrink: true },
  });

  renderImage = () => {
    if (this.state.image) {
      return (
        <img
          style={{ width: "100%", borderRadius: 5 }}
          src={this.state.image}
        />
      );
    } else if (this.state.product.imagePublicId) {
      const img = cloudinaryImage(this.state.product.imagePublicId, {
        version: this.state.product.imageVersion,
        size: "custom",
        width: 400,
        height: 400,
      });
      return (
        <AdvancedImage
          cldImg={img}
          style={{ width: "100%", borderRadius: 5 }}
        />
      );
    }

    return <ImageIcon style={{ fontSize: 60 }} />;
  };

  render() {
    return (
      <Dialog
        title={this.renderTitle()}
        open={this.props.open}
        onClose={this.props.onClose}
        maxWidth="md"
      >
        <DialogContent dividers>
          <div style={{ width: 800 }}>
            <Grid container spacing={2}>
              <Grid item>
                <Box
                  sx={{
                    ...styles.imageContainer,
                    ...(this.state.errors.image
                      ? { borderColor: "error.main" }
                      : {}),
                  }}
                  onClick={() => this.setState({ selectImageDialogOpen: true })}
                >
                  {this.renderImage()}
                  {this.state.image || this.state.product.imagePublicId ? (
                    <Fab
                      style={{ position: "absolute", left: 5, bottom: 10 }}
                      size="small"
                    >
                      <EditIcon />
                    </Fab>
                  ) : (
                    <FormHelperText error>
                      {this.state.errors.image}
                    </FormHelperText>
                  )}
                </Box>
                <SelectImage
                  open={this.state.selectImageDialogOpen}
                  onClose={() =>
                    this.setState({ selectImageDialogOpen: false })
                  }
                  onImageAdded={(image) => {
                    this.setState({ image: image.url, blob: image.blob });
                  }}
                />
              </Grid>
              <Grid item container xs spacing={1}>
                <Grid item xs={12}>
                  <TextField
                    variant="standard"
                    {...this.defaultTextFieldParams("title")}
                    label="שם המוצר"
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    variant="standard"
                    {...this.defaultTextFieldParams("description")}
                    label="תיאור המוצר (אופציונלי)"
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    variant="standard"
                    {...this.defaultTextFieldParams("price")}
                    label="מחיר"
                    type="number"
                  />
                </Grid>

                {this.props.requireAccountingExportNumber && (
                  <Grid item xs={12}>
                    <TextField
                      variant="standard"
                      {...this.defaultTextFieldParams("accountingExportNumber")}
                      label="מפתח חשבון"
                      field="accountingExportNumber"
                    />
                  </Grid>
                )}

                <Grid item xs={12}>
                  <TextField
                    variant="standard"
                    select
                    label="סוג אפשרות בחירה"
                    value={this.state.product.optionsType}
                    onChange={(e) => {
                      this.handleFieldChange("optionsType", e.target.value);
                      if (e.target.value === "none") {
                        this.handleFieldChange("options", []);
                      }
                    }}
                    fullWidth
                  >
                    <MenuItem value="none">ללא אפשרות בחירה</MenuItem>
                    <MenuItem value="צבע">צבע</MenuItem>
                    <MenuItem value="מידה">מידה</MenuItem>
                  </TextField>
                </Grid>

                {this.state.product.optionsType !== "none" && (
                  <Grid item xs={12} style={{ marginTop: 10 }}>
                    <ProductOptionsDND
                      onChange={this.handleFieldChange}
                      product={this.state.product}
                      errors={this.state.errors}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <ButtonSx onClick={this.props.onClose} disabled={this.state.isSaving}>
            ביטול
          </ButtonSx>
          <ButtonSx
            onClick={this.handleSave}
            isLoading={this.state.isSaving}
            debounce
          >
            שמירה
          </ButtonSx>
        </DialogActions>
      </Dialog>
    );
  }
}

const styles = {
  titleContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  imageContainer: {
    position: "relative",
    width: 250,
    height: 250,
    border: "1px solid #EEE",
    borderRadius: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#EEE",
    flexDirection: "column",
  },
};
