import React from "react";
import { DialogActions, DialogContent } from "@mui/material";

import Dialog from "../../Dialog";
import ButtonSx from "../../base/ButtonSx";

export default function ReturnedCheckConfirmationDialog({ open, onClose }) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      title="צ׳ק חוזר"
      fullWidth
      maxWidth="xs"
    >
      <DialogContent dividers>
        צ׳ק זה כבר הופקד ולאחר ביצוע ההחזר יסומן כצ׳ק חוזר
      </DialogContent>
      <DialogActions>
        <ButtonSx onClick={onClose}>אישור</ButtonSx>
      </DialogActions>
    </Dialog>
  );
}
