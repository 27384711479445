import React from "react";
import { Tooltip } from "@mui/material";

const styles = {
  tooltip: {
    fontSize: "14px",
    boxShadow: 1,
    // "& .MuiTooltip-arrow": {
    //   // color: "#F0F0F0",
    // },
  },
};

export default function TooltipSx({ title, children, sx, ...props }) {
  return (
    <Tooltip
      componentsProps={{
        tooltip: {
          sx: { ...styles.tooltip, ...sx },
        },
      }}
      // if title is falsy we don't want to show it
      title={
        title ? (
          <div dir="rtl" {...props.titleProps}>
            {title}
          </div>
        ) : (
          title
        )
      }
      {...props}
    >
      {children}
    </Tooltip>
  );
}
