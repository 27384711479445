import React from "react";
import {
  Grid,
  TextField,
  FormHelperText,
  List,
  ListItem,
  Checkbox,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Alert,
} from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Check";
import CancelIcon from "@mui/icons-material/Close";
import { displayPrice } from "../../../utils/helpers";
import InfoRow from "../../InfoRows/InfoRow";
import FlexCenter from "../../FlexCenter";
import Paper2Sx from "../../Paper2Sx";
import TlPaper from "../../TlPaper";

export default function RefundProductForReturnChecks(props) {
  const { refundedItems, errors } = props.form;

  const renderItems = () => {
    return (
      <>
        {/* maxRefundAmount - can only happen for refunds created before the "complex refunds fix" */}
        {errors.refundAmount ||
          (errors.maxRefundAmount && (
            <FormHelperText error style={{ fontSize: 20 }}>
              {errors.refundAmount
                ? "יש לבחור מוצרים להחזר"
                : `הסכום המקסימלי
              להחזר עבור עסקה זו הוא ${displayPrice(
                props.transaction.payment.refundableAmount
              )}`}
            </FormHelperText>
          ))}
        <List dense>
          {refundedItems.map((refundItem, i) => {
            const { purchasedItem } = refundItem;

            // if 1. item has been full refunded
            //    2. no checks have been chosen
            //    3. current item is not selected and the selected checks amount has been fully covered by other items
            const disabled =
              purchasedItem.refundedAmount === -purchasedItem.price ||
              props.refundAmount === 0 ||
              (props.form.selectedItemsAmount === props.refundAmount &&
                !refundItem.checked);
            let secondaryText;

            secondaryText =
              purchasedItem.type === "subscription" ||
              props.allowMerchandisePartialRefund
                ? `סכום מירבי להחזר: ${displayPrice(
                    refundItem.maxRefundAmount
                  )}`
                : "";

            if (
              purchasedItem &&
              purchasedItem.details &&
              purchasedItem.details.player
            ) {
              secondaryText = (
                <span>
                  {secondaryText}
                  <div>ספורטאי: {purchasedItem.details.player.name}</div>
                </span>
              );
            }

            return (
              <ListItem
                key={purchasedItem._id}
                role={undefined}
                disabled={disabled}
              >
                <Checkbox
                  edge="start"
                  checked={refundItem.checked}
                  tabIndex={-1}
                  disableRipple
                  onClick={() =>
                    props.onItemChange(i, {
                      checked: !refundItem.checked,
                      editing: false,
                    })
                  }
                  disabled={disabled}
                  sx={styles.checkBox}
                />
                <ListItemText
                  primary={purchasedItem.product.title}
                  secondary={props.refundAmount > 0 && secondaryText}
                  secondaryTypographyProps={{ component: "span" }}
                />
                {props.refundAmount > 0 && (
                  <ListItemSecondaryAction sx={styles.leftSide}>
                    {purchasedItem.type === "subscription" ||
                    props.allowMerchandisePartialRefund
                      ? renderSubscriptionItem(refundItem, i)
                      : displayPrice(refundItem.maxRefundAmount)}
                  </ListItemSecondaryAction>
                )}
              </ListItem>
            );
          })}
        </List>
      </>
    );
  };

  const renderSubscriptionItem = (refundItem, itemIndex) => {
    if (refundItem.editing) {
      return (
        <Paper2Sx>
          <FlexCenter>
            <IconButton
              edge="end"
              onClick={() => props.onItemAmountChange(itemIndex)}
              size="large"
            >
              <SaveIcon />
            </IconButton>
            <IconButton
              edge="end"
              onClick={() => props.onItemChange(itemIndex, { editing: false })}
              size="large"
            >
              <CancelIcon />
            </IconButton>
            <TextField
              variant="standard"
              style={{ width: 100 }}
              type="number"
              value={refundItem.tempAmount}
              onChange={(e) => {
                props.onItemChange(itemIndex, {
                  tempAmount: Math.floor(parseFloat(e.target.value) * 10) / 10, // removes non-digits btw and leaves 1 digit after decimal point
                });
              }}
              error={refundItem.error}
              helperText={refundItem.error}
            />
          </FlexCenter>
        </Paper2Sx>
      );
    } else {
      return (
        <>
          <IconButton
            edge="end"
            onClick={() => props.onItemChange(itemIndex, { editing: true })}
            disabled={!refundItem.checked}
            size="large"
          >
            <EditIcon />
          </IconButton>
          {displayPrice(refundItem.refundAmount)}
        </>
      );
    }
  };

  return (
    <TlPaper paperProps={{ variant: "outlined" }} title="בחירת פריטים להחזר">
      <Grid container>
        <Grid item xs={12}>
          {props.refundAmount > 0 && (
            <Alert severity="info">
              עליכם לבחור פריטים בסכום כולל של{" "}
              {displayPrice(props.refundAmount)}
            </Alert>
          )}
          {props.form.errors?.amountMismatch && (
            <Alert severity="error" style={{ marginTop: 10 }}>
              {props.form.errors.amountMismatch}
            </Alert>
          )}
        </Grid>

        <Grid item xs={12}>
          {renderItems()}
        </Grid>
        {!props.returnChecks && (
          <>
            <Grid item xs={12}>
              <TextField
                variant="standard"
                required
                fullWidth
                label="סיבת החזר"
                value={props.form.comment}
                onChange={(e) => {
                  props.onChange({ comment: e.target.value });
                }}
                error={errors.comment}
                helperText={errors.comment ? "שדה חובה" : ""}
              />
            </Grid>
            <Grid item xs={12}>
              <InfoRow
                title="סכום כולל להחזר"
                value={displayPrice(props.form.refundAmount)}
              />
            </Grid>
          </>
        )}
      </Grid>
    </TlPaper>
  );
}

const styles = {
  listItem: {
    padding: "10px",
    border: "1px solid #EEE",
    borderRadius: "5px",
    marginTop: "7px",
  },
  checkBox: {
    marginLeft: { xs: -3, sm: 0 },
  },
  leftSide: {
    marginRight: { xs: -2, sm: 0 },
  },
};
