import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  CircularProgress,
} from "@mui/material";
import _ from "lodash";

import axios from "../../../../utils/axios";
import FormTextField from "../../../../components/base/FormTextField";
import ButtonSx from "../../../../components/base/ButtonSx";

export default class EditPlayerAlertDialog extends React.Component {
  state = {
    isLoading: false,
    instantValidate: false,
    errors: {},
  };

  componentDidUpdate = async (prevProps) => {
    if (!prevProps.open && this.props.open) {
      let playerAlert;
      if (this.props.playerAlert) {
        playerAlert = {
          ..._.pick(this.props.playerAlert, ["text"]),
        };
      } else {
        playerAlert = {
          text: "",
        };
      }
      this.setState({
        playerAlert,
        isLoading: false,
      });
    }
  };

  handleFieldChange = async (updates) => {
    const playerAlert = {
      ...this.state.playerAlert,
      ...updates,
    };

    await this.setState({ playerAlert });
    if (this.state.instantValidate) {
      this.validate();
    }
  };

  validate = () => {
    let isValid = true;
    const errors = {};

    if (this.state.playerAlert.text.length < 2) {
      errors.text = "שדה חובה";
      isValid = false;
    }

    this.setState({ errors, instantValidate: true });
    return isValid;
  };

  handleSave = async () => {
    const valid = this.validate();
    if (valid && !this.state.isLoading) {
      const data = { ...this.state.playerAlert };
      this.setState({ isLoading: true });
      if (this.props.playerAlert) {
        await axios.patch(
          `/settings/playerAlerts/${this.props.playerAlert._id}`,
          data
        );
      } else {
        await axios.post(`/settings/playerAlerts`, data);
      }

      this.props.onComplete();
    }
  };

  renderContent = () => {
    const defaultTextFieldParams = {
      form: this.state.playerAlert,
      onChange: this.handleFieldChange,
      errors: this.state.errors,
    };

    return (
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <FormTextField
            label="שם ההתראה"
            field="text"
            {...defaultTextFieldParams}
          />
        </Grid>
      </Grid>
    );
  };

  render() {
    return (
      <Dialog
        dir="rtl"
        fullWidth
        maxWidth="sm"
        onClose={this.props.onClose}
        open={this.props.open}
      >
        <DialogTitle id="form-dialog-title">
          {this.props.playerAlert ? "עריכת התראה" : "הוספת התראה"}
        </DialogTitle>
        <DialogContent dividers>
          {this.state.playerAlert ? this.renderContent() : <CircularProgress />}
        </DialogContent>
        <DialogActions>
          <ButtonSx
            onClick={this.props.onClose}
            disabled={this.state.isLoading}
          >
            ביטול
          </ButtonSx>
          <ButtonSx
            onClick={_.debounce(this.handleSave, 200)}
            disabled={this.state.isLoading}
          >
            אישור
          </ButtonSx>
        </DialogActions>
      </Dialog>
    );
  }
}
