import React from "react";
import {
  Box,
  Divider,
  Grid,
  IconButton,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
  useMediaQuery,
} from "@mui/material";

import InfoIcon from "@mui/icons-material/InfoOutlined";
import EditIcon from "@mui/icons-material/Edit";

import { classes as playerClasses } from "../../utils/dictionaries";
import { displayPrice, roundPrice } from "../../utils/helpers";
import ToolTip from "../../components/base/ToolTip";
import EllipsisTooltip from "../../components/EllipsisTooltip";
import ReviewItemLeftSide from "./ReviewItemLeftSide";

export default function SubscriptionReviewItem({
  cart,
  cartItem,
  subscriptionForm,
  player,
  onOpenPartialPayment,
}) {
  const product = cartItem.product;
  const isSmUp = useMediaQuery((theme) => theme.breakpoints.up("sm"), {
    noSsr: true,
  });
  const subscriptionType = product?.subscriptionDetails?.subscriptionType;
  const isExternal = product._id === "external";

  const priceBreakDown = (
    <div>
      <Grid container sx={{ fontSize: 14 }}>
        <Grid item xs={12}>
          <Typography>פרוט עלויות ותשלומים</Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider style={{ backgroundColor: "#AAA", marginBottom: 10 }} />
        </Grid>
        <Grid item xs={6}>
          <b>פריט</b>
        </Grid>
        <Grid item xs={3} container justifyContent="flex-end">
          <b>עלות</b>
        </Grid>
        <Grid item xs={3} container justifyContent="flex-end">
          <b>תשלום</b>
        </Grid>
        {cartItem.fees.map((fee) => (
          <React.Fragment key={fee.product._id}>
            <Grid item xs={6}>
              <EllipsisTooltip>{fee.product.title}</EllipsisTooltip>
            </Grid>
            <Grid item xs={3} container justifyContent="flex-end">
              {displayPrice(fee.price)}
            </Grid>
            <Grid item xs={3} container justifyContent="flex-end">
              {displayPrice(fee.paymentAmount)}
            </Grid>
          </React.Fragment>
        ))}
        <Grid item xs={6}>
          {subscriptionType === "season" || subscriptionType === "camp" ? (
            <EllipsisTooltip>פעילות</EllipsisTooltip>
          ) : (
            "פעילות"
          )}
        </Grid>
        <Grid item xs={3} container justifyContent="flex-end">
          {displayPrice(cartItem.price)}
        </Grid>
        <Grid item xs={3} container justifyContent="flex-end">
          {displayPrice(cartItem.paymentAmount)}
        </Grid>
        <Grid item xs={12}>
          <Divider style={{ backgroundColor: "#AAA", marginTop: 10 }} />
        </Grid>
        <Grid item xs={6}>
          סה״כ
        </Grid>
        <Grid item xs={3} container justifyContent="flex-end">
          {displayPrice(cart.totals.costs.subscriptionPlusFees)}
        </Grid>
        <Grid item xs={3} container justifyContent="flex-end">
          {displayPrice(cart.totals.paymentAmounts.subscriptionPlusFees)}
        </Grid>
      </Grid>
    </div>
  );

  const description = (
    <Box sx={styles.secondaryTextContainer}>
      <div style={{ flex: 1 }}>
        {player.firstName} {player.lastName}, {playerClasses[player.class]}
        {player.phone && `, ${player.phone}`}
        <Box sx={styles.priceContainer}>
          <ToolTip
            title={priceBreakDown}
            arrow
            placement="bottom"
            sx={styles.tooltip}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {isExternal ? (
                "התשלום לקבוצה זו לא מתבצע דרך טריינליט"
              ) : (
                <>
                  {displayPrice(cart.totals.costs.subscriptionPlusFees)}
                  <InfoIcon style={{ marginRight: 5, fontSize: 20 }} />
                </>
              )}
            </Box>
          </ToolTip>
        </Box>
      </div>
      {!isSmUp && (
        <Box sx={styles.mobilePriceContainer}>
          <ReviewItemLeftSide
            topRight="לתשלום: "
            topCenter={displayPrice(
              cart.totals.paymentAmounts.subscriptionPlusFees
            )}
            topLeft={
              isExternal ? (
                <span style={{ width: 50 }} />
              ) : (
                <IconButton onClick={onOpenPartialPayment} size="large">
                  <EditIcon />
                </IconButton>
              )
            }
            bottom={
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  textDecoration: "underline",
                }}
              >
                {cart.totals.costs.subscriptionPlusFees !==
                  cart.totals.paymentAmounts.subscriptionPlusFees && (
                  <span>
                    *יתרת חוב:{" "}
                    {displayPrice(
                      roundPrice(
                        cart.totals.costs.subscriptionPlusFees -
                          cart.totals.paymentAmounts.subscriptionPlusFees
                      )
                    )}
                  </span>
                )}
              </Box>
            }
          />
        </Box>
      )}
    </Box>
  );

  return (
    <ListItem sx={styles.listItem}>
      <ListItemText
        primary={`${subscriptionForm.team.name}${
          isExternal ? "" : ` / ${product.title}`
        }`}
        primaryTypographyProps={{ sx: styles.primaryTextContainer }}
        secondaryTypographyProps={{ component: "span" }}
        secondary={description}
      />

      {isSmUp && (
        <ListItemSecondaryAction>
          <ReviewItemLeftSide
            topRight="לתשלום: "
            topCenter={displayPrice(
              cart.totals.paymentAmounts.subscriptionPlusFees
            )}
            topLeft={
              isExternal ? (
                <span style={{ width: 50 }} />
              ) : (
                <IconButton onClick={onOpenPartialPayment} size="large">
                  <EditIcon />
                </IconButton>
              )
            }
            bottom={
              <span
                style={{
                  display: "flex",
                  justifyContent: "center",
                  textDecoration: "underline",
                }}
              >
                {cart.totals.costs.subscriptionPlusFees !==
                  cart.totals.paymentAmounts.subscriptionPlusFees && (
                  <span>
                    *יתרת חוב:{" "}
                    {displayPrice(
                      roundPrice(
                        cart.totals.costs.subscriptionPlusFees -
                          cart.totals.paymentAmounts.subscriptionPlusFees
                      )
                    )}
                  </span>
                )}
              </span>
            }
          />
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
}

const styles = {
  listItem: {
    border: "1px solid #EEE",
    backgroundColor: "#FFF",
    borderRadius: "10px",
  },
  priceContainer: {
    marginTop: "10px",
    color: "#000",
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
  },
  primaryTextContainer: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "calc(100vw - 70px)",
  },
  secondaryTextContainer: {
    display: "flex",
    flexDirection: "column",
  },
  tooltip: {
    maxWidth: 400,
  },
  mobilePriceContainer: {
    marginTop: "10px",
    backgroundColor: "#e7f1fa",
    border: "1px solid #EEE",
    borderRadius: "7px",
  },
};
