import React from "react";
import { Grid } from "@mui/material";

import { subscriptionsStatuses } from "../../../../utils/dictionaries";
import { displayDate, displayPrice } from "../../../../utils/helpers";
import ResponsiveContainer from "../../../ResponsiveContainer";
import SubscriptionHeaderItem from "./SubscriptionHeaderItem";

export default function SubscriptionHeader(props) {
  const { subscription } = props;

  let status = subscriptionsStatuses[subscription.status];
  if (
    subscription.status === "cancelled" &&
    subscription.subStatus === "teamChange"
  ) {
    status = `${status} / מעבר קבוצה`;
  }

  const items = {
    status: (
      <Grid item xs={6} md={4} key="status">
        <SubscriptionHeaderItem title="סטטוס" value={status} bigValueFont />
      </Grid>
    ),
    start: (
      <Grid item xs={6} md={4} key="start">
        <SubscriptionHeaderItem
          title="תחילת פעילות"
          value={displayDate(subscription.startDate)}
          bigValueFont
        />
      </Grid>
    ),
    end: (
      <Grid item xs={6} md={4} key="end">
        <SubscriptionHeaderItem
          title="סיום פעילות"
          value={displayDate(
            subscription.status === "cancelled"
              ? subscription.cancelledAt
              : subscription.endDate
          )}
          bigValueFont
        />
      </Grid>
    ),
    cost: (
      <Grid item xs={6} md={4} key="cost">
        <SubscriptionHeaderItem
          title="עלות"
          value={displayPrice(subscription.totalPrice)}
          bigValueFont
        />
      </Grid>
    ),
    paid: (
      <Grid item xs={6} md={4} key="paid">
        <SubscriptionHeaderItem
          title="שולם"
          value={displayPrice(subscription.paid)}
          bigValueFont
        />
      </Grid>
    ),
    debt: (
      <Grid item xs={6} md={4} key="debt">
        <SubscriptionHeaderItem
          title={subscription.debt < 0 ? "יתרת זכות" : "יתרת חוב"}
          value={displayPrice(Math.abs(subscription.debt))}
          bigValueFont
        />
      </Grid>
    ),
  };

  return (
    <Grid container spacing={3}>
      <ResponsiveContainer
        desktopContent={Object.values(items)}
        mobileContent={[
          items.status,
          items.cost,
          items.paid,
          items.debt,
          items.start,
          items.end,
        ]}
      />
    </Grid>
  );
}
