import React, { useMemo } from "react";
import Chart from "react-apexcharts";
import moment from "moment";
import _ from "lodash";

import {
  eventTypes,
  attendanceMissingReasonsShort,
  attendanceMissingReasons,
} from "../../utils/dictionaries";

const legend = {
  noAttendance: { value: 0.5, label: "דוח נוכחות חסר" },
  notInTeam: { value: 1.5, label: "ספורטאי לא שייך לקבוצה" },
  attended: { value: 2.5, label: "נוכח/ת" },
  notAttended: { value: 3.5, label: "חסר/ה" },
  limitedSubscription: { value: 4.5, label: "פעילות לא כלולה ברישום" },
};

export default function TeamDetailedAttendanceChart(props) {
  if (!props.players) {
    return <div />;
  }

  const players = useMemo(
    () =>
      props.players.reduce((dic, player) => {
        dic[player._id] = player.name;
        return dic;
      }, {}),
    [props.players]
  );

  // add players that are not in the team right now (quitters) and trial players
  props.events.forEach((event) => {
    if (event.attendance) {
      event.attendance.players.forEach((record) => {
        players[record.player._id] = record.player.name;
      });
    }
  });

  const playerIds = useMemo(
    () => _.orderBy(Object.keys(players), (pId) => players[pId], "desc"),
    [players]
  );

  const series = useMemo(() => {
    if (props.events.length === 0) {
      return [];
    }

    return playerIds.map((playerId) => {
      const name = players[playerId];
      let data;

      data = props.events.map((event) => {
        let status, missingReason, missingOtherReason;
        if (!event.attendance) {
          status = "noAttendance";
        } else {
          const playerAttendance = event.attendance.players.find(
            (rec) => rec.player._id === playerId
          );

          if (!playerAttendance) {
            status = "notInTeam";
          } else {
            if (playerAttendance.attended) {
              status = "attended";
            } else {
              status =
                playerAttendance.reason === "limitedSubscription"
                  ? "limitedSubscription"
                  : "notAttended";
            }
            missingReason = playerAttendance.reason;
            missingOtherReason = playerAttendance.otherReason;
          }
        }

        return {
          x: event._id,
          y: legend[status].value,
          player: players[playerId],
          status,
          missingReason,
          missingOtherReason,
        };
      });

      return {
        name,
        data,
      };
    });
  }, [props.events, playerIds, players]);

  const options = {
    chart: {
      height: 500,
      type: "heatmap",
      toolbar: { show: false },
    },
    yaxis: {
      opposite: true,
      labels: {
        formatter: function (value) {
          return value.toString();
        },
        offsetX: -60,
      },
    },
    xaxis: {
      labels: {
        show: true,
        formatter: function (value) {
          const event = props.events.find((e) => e._id === value);
          return event
            ? [
                eventTypes[event.type].padStart(5, " "), //fix some weird alignment shit at xaxis label
                moment(event.start).format("DD/MM"),
              ]
            : null;
        },
      },
      axisTicks: { show: true },
      tooltip: { enabled: false },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val, opts) {
        const event = props.events[opts.dataPointIndex];
        if (!event || !event.attendance) {
          return "";
        }
        const playerId = playerIds[opts.seriesIndex];
        const playerAttendance = event.attendance.players.find(
          (rec) => rec.player._id === playerId
        );
        if (playerAttendance && !playerAttendance.attended) {
          return attendanceMissingReasonsShort[playerAttendance.reason];
        }
      },
    },
    tooltip: {
      custom: function ({ seriesIndex, dataPointIndex, w }) {
        const { x, status, player, missingReason, missingOtherReason } =
          w.config.series[seriesIndex].data[dataPointIndex];

        const event = props.events.find((e) => e._id === x);
        if (!event) {
          return;
        }

        let missingText = "";
        if (missingReason && missingReason !== "limitedSubscription") {
          missingText = ` - ${
            missingReason === "other"
              ? missingOtherReason
              : attendanceMissingReasons[missingReason]
          }`;
        }

        const statusDesc = legend[status].label + missingText;

        return (
          '<div class="arrow_box" style="padding:10px;"><table>' +
          '<tr><td style="width:50px;"><b>ספורטאי:</b></td><td>' +
          player +
          "</td></tr>" +
          '<tr><td style="width:50px;"><b>אירוע:</b></td><td>' +
          event.title +
          "</td></tr>" +
          '<tr><td style="width:50px;"><b>תאריך:</b></td><td>' +
          moment(event.start).format("DD/MM/YY") +
          "</td></tr>" +
          '<tr><td style="width:50px;"><b>סטטוס:</b></td><td>' +
          statusDesc +
          "</td></tr></table></div>"
        );
      },
    },
    colors: ["#E0E0E0"],
    stroke: {
      width: 1,
    },
    plotOptions: {
      tooltip: false,
      heatmap: {
        enableShades: false,
        shadeIntensity: 1,
        colorScale: {
          inverse: true,
          ranges: [
            {
              from: 0,
              to: 1,
              color: "#ff9800",
              name: "דוח נוכחות חסר",
            },
            {
              from: 1,
              to: 2,
              color: "#EFEFEF",
              name: "ספורטאי לא שייך לקבוצה",
            },
            {
              from: 2,
              to: 3,
              color: "#388e3c",
              name: "נוכח",
            },
            {
              from: 3,
              to: 4,
              color: "#d32f2f",
              name: "חיסור",
            },
            {
              from: 4,
              to: 5,
              color: "#EFEFEF",
              name: "לא כלול",
            },
          ],
        },
      },
    },
  };

  return (
    <div style={{ letterSpacing: "normal" }}>
      <Chart
        options={options}
        series={series}
        type="heatmap"
        height={playerIds.length * 20 + 100}
      />
    </div>
  );
}
