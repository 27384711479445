import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";

import axios from "../../utils/axios";

function RemoveTrialPlayerFromTeamDialog(props) {
  const handleChange = async () => {
    await axios.delete(`/clubPlayers/trial/${props.player._id}`);
    props.onComplete();
  };

  return (
    <Dialog
      dir="rtl"
      fullWidth
      maxWidth="sm"
      onClose={props.onClose}
      open={props.open}
    >
      <DialogTitle id="form-dialog-title">הסרת ספורטאי</DialogTitle>
      <DialogContent dividers style={{ height: "100px" }}>
        האם ברצונך להסיר את {props.player.name} מקבוצת {props.team.name}?
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose}>ביטול</Button>
        <Button onClick={handleChange}>אישור</Button>
      </DialogActions>
    </Dialog>
  );
}

export default RemoveTrialPlayerFromTeamDialog;
