import React from "react";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import GroupIcon from "@mui/icons-material/Group";
import FlexCenter from "../../components/FlexCenter";

export default function TeamsList(props) {
  const renderTeam = (team) => {
    const level = team.teamLevel
      ? `${team.teamLevel.name}${
          team.teamSubLevel ? ` / ${team.teamSubLevel.name}` : ""
        }`
      : "-";

    return (
      <ListItem
        component={RouterLink}
        to={`../teams/${team._id}`}
        key={team._id}
        sx={styles.listItem}
      >
        <ListItemButton>
          <ListItemText primary={team.name} secondary={level} />
          <ListItemSecondaryAction>
            <FlexCenter>
              {team.playersStats.registered}
              <GroupIcon fontSize="small" sx={{ ml: "5px" }} />
            </FlexCenter>
          </ListItemSecondaryAction>
        </ListItemButton>
      </ListItem>
    );
  };

  return <List>{props.teams.map((team) => renderTeam(team))}</List>;
}

const styles = {
  listItem: {
    border: "1px solid #EEE",
    borderRadius: "5px",
    marginBottom: "5px",
    color: "black",
    p: 0,
    backgroundColor: "background.paper",
  },
};
