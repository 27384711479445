import React from "react";
import { Box, CircularProgress, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { green } from "@mui/material/colors";

export default function CountItem(props) {
  const navigate = useNavigate();

  const handleClick = () => {
    !props.superClub && props.linkTo && navigate(props.linkTo);
  };

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      direction="column"
      sx={styles.container}
      onClick={handleClick}
    >
      {props.children ? (
        props.isLoading ? (
          <CircularProgress />
        ) : (
          props.children
        )
      ) : (
        <>
          <Box sx={styles.title}>{props.title}</Box>
          <Box
            sx={{
              ...styles.count,
              ...styles[props.color],
              ...(props.primary ? styles.primary : {}),
            }}
          >
            {props.isLoading ? (
              <CircularProgress />
            ) : (
              props.customValue || props.count
            )}
          </Box>
          <Box sx={{ position: "absolute", left: -100 }}>{props.chart}</Box>
        </>
      )}
    </Stack>
  );
}

const styles = {
  title: {
    color: "#495057",
    fontSize: "22px",
    fontWeight: "500",
  },
  count: {
    fontWeight: "bold",
    fontSize: "26px",
    color: green[600],
  },
  container: {
    height: 150,
    "&:hover": {
      backgroundColor: "white",
      transform: "scale(1.05)",
      borderRadius: "4px",
      boxShadow: "0px 2px 15px -1px rgba(0,0,0,0.12)",
    },
    transitionDuration: 300,
    position: "relative",
    border: "1px",
  },
  color1: {
    color: green[600],
  },
  color2: {
    color: "primary.main",
  },
  primary: {
    fontSize: "30px",
  },
};
