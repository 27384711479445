import React from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  IconButton,
  TextField,
  MenuItem,
  Link,
  Divider,
  Box,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import CopyIcon from "@mui/icons-material/FileCopyOutlined";
import DatePicker from "../DatePicker";

const styles = {
  container: {
    display: "flex",
    alignItems: "center",
    minHeight: "30px",
    fontSize: "14px",
  },
  rowTitle: {
    fontWeight: "600",
    fontSize: "14px",
    width: { xs: 80, sm: 120 },
  },
};

export default function InfoRow(props) {
  const [isEditing, setIsEditing] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState(props.value);
  // const linkRef = React.useRef(null);

  React.useEffect(() => {
    setSelectedValue(props.value);
  }, [props.value]);

  // const copyToClipboard = (e) => {
  //   linkRef.current.select();
  //   document.execCommand("copy");
  //   e.target.focus();
  // };

  const renderEditableField = () => {
    switch (props.editType) {
      case "select":
        return (
          <TextField
            variant="standard"
            select
            value={selectedValue}
            onChange={(event) => setSelectedValue(event.target.value)}
          >
            {Object.entries(props.options).map(([value, title]) => (
              <MenuItem value={value} key={value}>
                {title}
              </MenuItem>
            ))}
          </TextField>
        );

      case "number":
        return (
          <TextField
            variant="standard"
            type="number"
            value={selectedValue}
            onChange={(event) => setSelectedValue(event.target.value)}
          />
        );

      case "text":
        return (
          <TextField
            variant="standard"
            value={selectedValue}
            onChange={(event) => setSelectedValue(event.target.value)}
            {...props.editableComponentProps}
          />
        );

      case "date":
        return (
          <DatePicker
            disableFuture
            value={selectedValue}
            onChange={setSelectedValue}
          />
        );

      default:
        break;
    }
  };

  const renderEditingButtons = () => {
    return isEditing ? (
      <>
        <IconButton
          size="small"
          color="primary"
          component="span"
          onClick={() => {
            props.onChange(selectedValue);
            setIsEditing(false);
          }}
        >
          <CheckIcon />
        </IconButton>
        <IconButton
          size="small"
          color="primary"
          component="span"
          onClick={() => setIsEditing(false)}
        >
          <CloseIcon />
        </IconButton>
      </>
    ) : (
      <IconButton
        size="small"
        color="primary"
        component="span"
        onClick={() =>
          props.editType === "dialog" ? props.onEdit() : setIsEditing(true)
        }
      >
        <EditIcon />
      </IconButton>
    );
  };

  const renderContent = () => {
    let content;
    if (props.options) {
      content = <div>{props.options[props.value]}</div>;
    } else {
      const value = props.displayValue
        ? props.displayValue(props.value)
        : props.value;

      if (props.linkTo) {
        content = props.externalLink ? (
          <Link
            color="primary"
            href={props.linkTo}
            target={props.target || "_self"}
            // ref={linkRef}
            underline="hover"
          >
            {value}
          </Link>
        ) : (
          <Link
            component={RouterLink}
            color="primary"
            to={props.linkTo}
            underline="hover"
          >
            {value}
          </Link>
        );
      } else {
        content = value;
      }
    }

    // if (props.copy && document.queryCommandSupported("copy")) {
    if (props.copy) {
      return (
        <>
          {content}
          <IconButton size="large">
            {/* <IconButton onClick={copyToClipboard}> */}
            <CopyIcon />
          </IconButton>
        </>
      );
    } else {
      return content;
    }
  };

  return (
    <>
      <Box sx={{ ...styles.container, ...props.sx }}>
        <Typography sx={{ ...styles.rowTitle, ...props.titleSx }}>
          {props.title}
        </Typography>
        <Box sx={{ flex: 1, ...props.contentSx }}>
          {isEditing ? renderEditableField() : renderContent()}
        </Box>
        {props.editable && (
          <Box sx={{ flexGrow: 1 }}>
            <div>{renderEditingButtons()}</div>
          </Box>
        )}
      </Box>
      {props.divider && <Divider />}
    </>
  );
}
