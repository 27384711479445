import React, { useState } from "react";
import { Box, List, ListItem, Stack, Typography } from "@mui/material";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import _ from "lodash";
import AddIcon from "@mui/icons-material/Add";

import { DnDreorder } from "../../../../utils/helpers";
import Paper2Sx from "../../../../components/Paper2Sx";
import ButtonSx from "../../../../components/base/ButtonSx";
import SubscriptionProductPricingItem from "./SubscriptionProductPricingItem";
import AbsoluteCenter from "../../../../components/AbsoluteCenter";

export default function Test(props) {
  const [selectedCell, setSelectedCell] = useState({
    pricingId: null,
    cell: null,
  });

  const { subscriptionProduct } = props;
  const { pricings, subscriptionType } = subscriptionProduct;

  const handleCellSelected = (pricingId) => (cell) => {
    setSelectedCell({ pricingId, cell });
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const items = DnDreorder(pricings, source.index, destination.index);
      props.onChange({ pricings: items });
    }
  };

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "lightblue" : "transparent",
    padding: 8,
    width: "100%",
    height: "100%",
    overflow: "auto",
    borderRadius: 5,
  });

  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    // styles we need to apply on draggables
    ...draggableStyle,
    borderWidth: 0,
  });

  const handleChange = (pricingId) => (updates) => {
    const newPricings = pricings.map((p) =>
      p.id === pricingId ? { ...p, ...updates } : { ...p }
    );
    props.onChange({ pricings: newPricings });
  };

  const handleAddNewItem = () => {
    const newPricing = {
      id: _.uniqueId(),
      title: "",
      quantity: "",
      quantityStr: "",
      active: true,
      activeForParents: true,
      unitPrice: "",
      unitPriceStr: "",
      isNew: true,
    };
    props.onChange({ pricings: [...pricings, newPricing] });
  };

  const handleDeleteItem = (pricing) => () => {
    props.onChange({
      pricings: pricings.filter((row) => row.id !== pricing.id),
    });
  };

  const title = (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="flex-end"
      sx={{ position: "relative" }}
    >
      תמחורים
      <ButtonSx
        onClick={handleAddNewItem}
        disabled={pricings.length === 7 || props.completed}
        variant="none"
        startIcon={<AddIcon />}
        color="primary"
        sx={{ position: "absolute", right: 0, top: 0, height: 30 }}
        buttonProps={{ size: "small" }}
      >
        הוספת פריט
      </ButtonSx>
    </Stack>
  );

  return (
    <Paper2Sx
      externalTitle={title}
      sx={styles.paper}
      paperSx={styles.paperContent}
    >
      <Box sx={{ flex: 1 }}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="pricingsDraggable">
            {(provided, snapshot) => (
              <List
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                {pricings.length > 0 && (
                  <ListItem disableGutters disablePadding>
                    <SubscriptionProductPricingItem
                      header
                      subscriptionProduct={subscriptionProduct}
                    />
                  </ListItem>
                )}
                {pricings.map((pricing, index) => (
                  <Draggable
                    key={pricing.id}
                    draggableId={pricing.id}
                    index={index}
                    isDragDisabled={!props.editable}
                  >
                    {(provided, snapshot) => (
                      <ListItem
                        disableGutters
                        disablePadding
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                        <SubscriptionProductPricingItem
                          pricing={pricing}
                          key={pricing.id}
                          onDelete={handleDeleteItem(pricing)}
                          errors={(props.errors?.pricings || [])[index] || {}}
                          onChange={handleChange(pricing.id)}
                          selectedCell={
                            pricing.id === selectedCell.pricingId
                              ? selectedCell.cell
                              : null
                          }
                          onCellSelected={handleCellSelected(pricing.id)}
                          subscriptionProduct={subscriptionProduct}
                          completed={props.completed}
                          editable={props.editable}
                        />
                      </ListItem>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </List>
            )}
          </Droppable>
        </DragDropContext>
      </Box>
      {subscriptionType === "daily" && subscriptionProduct.units && (
        <Typography sx={{ textAlign: "center" }}>
          מספר ימים מקסימלי - {subscriptionProduct.units} ימים
        </Typography>
      )}
      {_.isString(props.errors.pricings) && (
        <AbsoluteCenter>
          <Box sx={styles.noPricingError}>{props.errors.pricings}</Box>
        </AbsoluteCenter>
      )}
    </Paper2Sx>
  );
}

const styles = {
  paper: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    mt: 3,
  },
  paperContent: {
    height: "100%",
    position: "relative",
    minHeight: 200,
    display: "flex",
    flexDirection: "column",
  },
  noPricingError: {
    fontSize: 20,
    color: "red",
  },
};
