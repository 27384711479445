import React from "react";
import { Paper, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";

import ScheduleIcon from "@mui/icons-material/Schedule";
import LocationIcon from "@mui/icons-material/LocationOn";
import StarIcon from "@mui/icons-material/Star";
import BusIcon from "@mui/icons-material/DirectionsBus";
import RefreshIcon from "@mui/icons-material/Refresh";
import PeopleIcon from "@mui/icons-material/People";

const styles = {
  tooltip: {
    color: "black",
    rippleBackgroundColor: "transparent",
    backgroundColor: "transparent",
    maxWidth: "none",
    border: "0px solid black",
    minWidth: "200px",
    "& .MuiTooltip-arrow": {
      color: "white",
    },
  },

  paper: {
    fontSize: "14px",
    padding: "10px",
    lineHeight: "140%",
  },
};

const Line = styled("div")({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

const LineStart = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

export default function EventToolTip(props) {
  const { event } = props;

  const tooltipContent = (
    <Paper elevation={6} dir="rtl" sx={styles.paper}>
      <div>
        <b> {event.title}</b>
      </div>
      <Line>
        <LineStart>
          <LocationIcon style={{ fontSize: 16, marginLeft: 3 }} />
        </LineStart>
        {event.resourceDescription ||
          (event.gameDetails ? event.gameDetails.location : "")}
      </Line>
      {event.gameDetails && event.gameDetails.opponent && (
        <Line>
          <LineStart>
            <PeopleIcon style={{ fontSize: 16, marginLeft: 3 }} />
          </LineStart>
          {event.gameDetails.opponent}
        </Line>
      )}
      {event.gameDetails && event.gameDetails.gameType === "away" && (
        <Line>
          <LineStart>
            <BusIcon style={{ fontSize: 16, marginLeft: 3 }} />
          </LineStart>
          {event.gameDetails.departureTime
            ? `${props.formatTime(event.gameDetails.departureTime)}, ${
                event.gameDetails.busType
              } מקומות`
            : "ללא הסעה"}
        </Line>
      )}
      <Line>
        <LineStart>
          <ScheduleIcon style={{ fontSize: 16, marginLeft: 3 }} />
        </LineStart>
        <div style={{ fontSize: "90%" }}>
          {props.formatTime(event.start)} - {props.formatTime(event.end)}
        </div>
      </Line>
      {event.shouldSendCoachNotification && !event.started && (
        <Line>
          <LineStart>
            <StarIcon style={{ fontSize: 16, marginLeft: 3 }} />
          </LineStart>
          לא נשלח עדכון
        </Line>
      )}
      {event.isRecurringEvent && (
        <Line>
          <LineStart>
            <RefreshIcon style={{ fontSize: 16, marginLeft: 3 }} />
          </LineStart>
          אירוע מחזורי
        </Line>
      )}
    </Paper>
  );

  if (props.holiday) {
    return props.children;
  }

  return (
    <Tooltip
      placement="top"
      arrow
      enterDelay={500}
      enterNextDelay={500}
      title={tooltipContent}
      componentsProps={{
        tooltip: {
          sx: styles.tooltip,
        },
      }}
    >
      {props.children}
    </Tooltip>
  );
}
