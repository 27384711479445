import React from "react";
import {
  TextField,
  CircularProgress,
  Button,
  Box,
  Typography,
  Stack,
} from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";

import ListItem from "../../../components/ListItem";
import IconButtonTooltip from "../../../components/base/IconButtonTooltip";

const styles = {
  button: {
    width: 70,
  },
};

export default function TeamLevel(props) {
  const renderPrimary = () => {
    if (!props.isEditing) {
      return (
        <Typography
          variant="body2"
          sx={props.parent ? {} : { fontWeight: "bold" }}
        >
          {props.teamLevel.name}
        </Typography>
      );
    }

    return (
      <TextField
        variant="standard"
        value={props.teamLevel.name}
        onChange={props.onNameChange}
        placeholder="שם הקטגוריה"
        error={props.error !== null}
        helperText={props.error}
        style={{ width: 180 }}
        autoFocus
        fullWidth
      />
    );
  };

  const renderLevel = () => {
    return (
      <ListItem
        listItemProps={{ dense: true, divider: true }}
        primaryText={renderPrimary()}
      >
        {props.isSaving && <CircularProgress />}
        {!props.isSaving &&
          (props.isEditing ? (
            <Stack
              direction="row"
              justifyContent="space-between"
              spacing={1}
              sx={{ flex: 1 }}
            >
              <Button
                variant="outlined"
                onClick={props.onCancelEditing}
                disabled={props.disabled}
                sx={styles.button}
              >
                ביטול
              </Button>
              <Button
                variant="outlined"
                onClick={props.onCompleteEditing}
                disabled={props.disabled}
                sx={styles.button}
              >
                שמירה
              </Button>
            </Stack>
          ) : (
            <span>
              {!props.parent && (
                <IconButtonTooltip
                  title="הוספת תת קטגוריה"
                  iconButtonProps={{ size: "small" }}
                  onClick={props.onAddNewSubLevel}
                  disabled={props.disabled}
                >
                  <AddIcon />
                </IconButtonTooltip>
              )}
              <IconButtonTooltip
                title="עריכה"
                iconButtonProps={{ size: "small" }}
                onClick={props.onStartEditing}
                disabled={props.disabled}
              >
                <EditIcon />
              </IconButtonTooltip>
              <IconButtonTooltip
                title="מחיקה"
                iconButtonProps={{ size: "small" }}
                onClick={props.onDelete}
                disabled={props.disabled}
              >
                <DeleteIcon />
              </IconButtonTooltip>
            </span>
          ))}
      </ListItem>
    );
  };

  return <Box sx={props.parent ? { ml: "20px" } : {}}>{renderLevel()}</Box>;
}
