import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import queryString from "query-string";

import withRouter from "../../components/routing/withRouter";

class IframeResponder extends React.Component {
  state = {};

  componentDidMount = async () => {
    let query = this.props.location.search.slice(
      1,
      this.props.location.search.length - 3
    );
    query = query.replace(/%26/g, "&").replace(/%3D/g, "=");

    const params = queryString.parse(query);
    const success = params.success === "true";

    if (success) {
      window.parent.postMessage(
        { success: true, messageType: "newCard", ...params },
        "*"
      );
    } else {
      window.parent.postMessage(
        { success: false, messageType: "newCard" },
        "*"
      );
    }
  };

  render() {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </div>
    );
  }
}

export default withRouter(IframeResponder);
