import React, { useEffect, useMemo, useState } from "react";
import moment from "moment";
import { Box, Checkbox, TableContainer } from "@mui/material";
import { Buffer } from "buffer";

import AddIcon from "@mui/icons-material/Add";
import InfoIcon from "@mui/icons-material/Info";
import ReactGA from "react-ga4";
import { connect } from "react-redux";

import FiltersBar from "../../../components/filters/FiltersBar";
import axios from "../../../utils/axios";
import {
  exportTable,
  renderCell,
  sortValueCell,
} from "../../../lib/tableHelpers";
import CreateDepositDialog from "./CreateDepositDialog";
import SnackbarNg from "../../../components/base/SnackbarNg";
import { displayDate } from "../../../utils/helpers";
import Table from "../../../components/table/Table";
import {
  getFiltersFromUrl,
  prepareFilters,
} from "../../../components/filters/prepareFilters";
import withRouter from "../../../components/routing/withRouter";
import { Browser } from "../../../utils";
import InfoRow from "../../../components/InfoRows/InfoRow";
import PaperHeaderButton from "../../../components/PaperHeaderButton";
import IconButtonTooltip from "../../../components/base/IconButtonTooltip";
import DepositDetailsDialog from "./DepositDetailsDialog";
import TlPaper from "../../../components/TlPaper";
import ButtonSx from "../../../components/base/ButtonSx";

const filtersStructure = [{ type: "date", label: "תאריך הפקדה" }];

function Deposits(props) {
  const [filters, setFilters] = useState({
    date: { from: moment().startOf("day"), to: moment().endOf("day") },
  });
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const [snackbarDetails, setSnackbarDetails] = useState({ isOpen: false });
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [currentDeposit, setCurrentDeposit] = useState(null);

  const checkedCount = useMemo(
    () => Object.values(data).filter((i) => i.checked).length,
    [data]
  );

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/payments/deposits",
      title: "Deposits",
    });

    const newFilters = getFiltersFromUrl(
      props.location,
      filters,
      filtersStructure
    );

    setFilters({ ...filters, ...newFilters });
    loadData(true, { ...filters, ...newFilters });
  }, []);

  const loadData = async (updateUrl = true, newFilters) => {
    const currentFilters = newFilters || filters;
    let filtersQueryString = prepareFilters(currentFilters, filtersStructure);
    setIsLoading(false);
    const res = await axios.get(
      `payments/deposits?${filtersQueryString.join("&")}`
    );
    setIsLoading(false);
    setData(res.data);
    if (updateUrl) {
      props.navigate(`?${filtersQueryString.join("&")}`, { replace: true });
    }
  };

  const handleExportToAccounting = async () => {
    const deposits = data.filter((d) => d.checked);
    setIsLoading(true);

    const res = await axios.get(
      `/payments/accountingDocuments/deposits?${deposits
        .map((d) => `ids=${d._id}`)
        .join("&")}`,
      { responseType: "arraybuffer" }
    );
    const result = Buffer.from(res.data, "binary").toString("base64");
    Browser.download("data:application/zip;base64," + result, "deposits.zip");
    setIsLoading(false);
  };

  const handleFilterChange = (filterName, value) => {
    setFilters((prevFilters) => ({ ...prevFilters, [filterName]: value }));
  };

  const handleExportTable = () => {
    exportTable(data, columns(), exportRenderer, "csv", "הפקדות");
  };

  const renderHeaderCheckBox = () => {
    return (
      <span>
        <Checkbox
          indeterminate={checkedCount > 0 && checkedCount < data.length}
          checked={data.length > 0 && checkedCount === data.length}
          onClick={handleSelectAll}
          inputProps={{ "aria-label": "select all" }}
        />
        תאריך הפקדה
      </span>
    );
  };

  const columns = () => [
    {
      id: "date",
      label: renderHeaderCheckBox(),
      exportLabel: "תאריך הפקדה",
      minWidth: 160,
      baseRenderer: true,
    },
    { id: "iid", label: "מספר הפקדה" },
    { id: "cashAmount", label: "סכום מזומן", preset: "price" },
    { id: "cashCount", label: "מספר עסקאות מזומן" },
    { id: "checkAmount", label: "סכום צ׳קים", preset: "price" },
    { id: "checkCount", label: "מספר צ׳קים" },
    { id: "info", label: "פרטים", baseRenderer: true },
  ];

  const handleSelectAll = (e) => {
    e.stopPropagation();

    const checked = checkedCount !== data.length;
    setData((prevData) => prevData.map((datum) => ({ ...datum, checked })));
  };

  const handleItemSelectToggle = (record) => () => {
    setData((prevData) =>
      prevData.map((datum) => {
        return datum._id === record._id
          ? { ...datum, checked: !datum.checked }
          : { ...datum };
      })
    );
  };

  const baseRenderer = (columnId, deposit) => {
    switch (columnId) {
      case "date":
        return (
          <span>
            <Checkbox
              checked={deposit.checked || false}
              onClick={handleItemSelectToggle(deposit)}
            />
            {displayDate(deposit.date)}
          </span>
        );

      case "docNumber":
        return {
          to: deposit.url,
          label: deposit.docNumber,
        };

      case "info":
        return (
          <IconButtonTooltip
            title="פרטי ההפקדה"
            iconButtonProps={{ size: "small" }}
            onClick={() => setCurrentDeposit(deposit)}
          >
            <InfoIcon />
          </IconButtonTooltip>
        );

      default:
        return deposit[columnId];
    }
  };

  const sortRenderer = (columnId, deposit) => {
    switch (columnId) {
      default:
        return deposit[columnId];
    }
  };

  const exportRenderer = (columnId, deposit) => {
    switch (columnId) {
      case "date":
        return displayDate(deposit.date);

      case "docNumber":
        return deposit.docNumber;

      default:
        return deposit[columnId];
    }
  };

  const addNewDepositButton = (
    <ButtonSx
      onClick={() => setIsCreateDialogOpen(true)}
      startIcon={<AddIcon />}
    >
      הפקדה חדשה
    </ButtonSx>
  );

  const currentColumns = columns();

  return (
    <>
      <CreateDepositDialog
        open={isCreateDialogOpen}
        onClose={() => setIsCreateDialogOpen(false)}
        onComplete={(url, docNumber) => {
          setIsCreateDialogOpen(false);
          setSnackbarDetails({ isOpen: true, key: docNumber, docNumber, url });
        }}
      />
      <SnackbarNg
        snackKey={snackbarDetails.key}
        open={snackbarDetails.isOpen}
        onClose={() => setSnackbarDetails({ isOpen: false, key: null })}
        message={<div>ההפקדה נוצרה בהצלחה</div>}
      />

      <TlPaper
        title="הפקדות"
        fullPage
        titleBackground
        leftItem={addNewDepositButton}
      >
        <FiltersBar
          filters={filtersStructure}
          onFilterChanged={handleFilterChange}
          values={filters}
          onSearch={loadData}
          isSearching={isLoading}
        />

        <Box sx={styles.tableHeader}>
          <InfoRow title="מספר הפקדות:" value={data.length} />
          <span>
            <PaperHeaderButton
              onClick={handleExportTable}
              type="export"
              disabled={props.isLoading}
            />
            {props.selectedClub.clubConfig.general.exportDepositsToHRP && (
              <ButtonSx
                onClick={handleExportToAccounting}
                disabled={checkedCount === 0}
                sx={styles.exportButton}
                isSearching={isLoading}
              >
                ייצוא ההפקדות שנבחרו לחשבשבת
              </ButtonSx>
            )}
          </span>
        </Box>
        <TableContainer sx={styles.tableContainer}>
          <Table
            columns={currentColumns}
            renderCell={renderCell(currentColumns, false, baseRenderer)}
            sortValueCell={sortValueCell(
              currentColumns,
              baseRenderer,
              sortRenderer
            )}
            rows={data}
            noHeader
            isLoading={isLoading}
            stickyHeader
            defaultSortDirection="desc"
          />
        </TableContainer>
        <DepositDetailsDialog
          open={currentDeposit !== null}
          onClose={() => setCurrentDeposit(null)}
          deposit={currentDeposit}
        />
      </TlPaper>
    </>
  );
}

const styles = {
  tableContainer: {
    maxHeight: "calc(100vh - 300px)",
  },
  exportButton: { margin: 5 },
  tableHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
};

function mapStateToProps(state) {
  return {
    selectedClub: state.auth.selectedClub,
  };
}

export default connect(mapStateToProps)(withRouter(Deposits));
