import React from "react";
import { useEffect } from "react";
import Chart from "react-apexcharts";
import { useSelector } from "react-redux";
import { useState } from "react";
import {
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
} from "@mui/material";

import axios from "../../../../utils/axios";
import AbsoluteCenter from "../../../../components/AbsoluteCenter";
import { useMemo } from "react";
import { displaySeason } from "../../../../utils/helpers";

const baseChartOptions = {
  colors: [
    "#40a0fc",
    "#febc4b",
    "#50e7a6",
    "#ff6478",
    "#8c75d7",
    "#f44336",
    "#e91e63",
    "#9c27b0",
    "#00bcd4",
    "#009688",
    "#4caf50",
    "#cddc39",
    "#ff9800",
    "#795548",
  ],
  chart: {
    height: 350,
    type: "treemap",
    toolbar: { show: false },
  },
  noData: { text: "לא נמצאו ספורטאים רשומים", style: { fontSize: "20px" } },
};

export default function SeasonRegistrationTreeMap(props) {
  const [data, setData] = useState(null);
  const [options, setOptions] = useState({});
  const [series, setSeries] = useState([]);
  const [detailed, setDetailed] = useState(false);
  const [hasSubCategories, setHasSubCategories] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const seasons = useSelector((state) => state.general.seasons);

  const season = useMemo(() => {
    return props.defaultSeason ? seasons?.find((s) => s.main) : props.season;
  }, [props.defaultSeason, seasons, props.season]);

  const chartOptions = (detailed) => {
    const title = {
      title: {
        text: `ספורטאים רשומים ${
          season ? ` - עונת ${displaySeason(season.year)}` : ""
        }`,
        align: "center",
      },
    };
    return !detailed
      ? {
          ...title,
          ...baseChartOptions,
          legend: { show: true },
          plotOptions: {
            treemap: {
              distributed: true,
              enableShades: true,
            },
          },
          dataLabels: {
            enabled: true,
            style: { fontSize: "16px", colors: ["#444"] },
            formatter: function (text, op) {
              return [text, op.value];
            },
          },
          tooltip: {
            y: {
              formatter: function (value) {
                return value + " ספורטאים רשומים";
              },
            },
          },
        }
      : {
          ...title,
          ...baseChartOptions,
          legend: {
            show: true,
          },
          dataLabels: {
            enabled: true,
            style: { fontSize: "16px", colors: ["#444"] },
            formatter: function (text, op) {
              return [text, op.value];
            },
          },
          tooltip: {
            y: {
              formatter: function (value) {
                return value + " ספורטאים רשומים";
              },
              title: {
                formatter: function (value, { seriesIndex }) {
                  return data[seriesIndex].subCategories.length > 0
                    ? `${data[seriesIndex]?.categoryName} / ${value}: `
                    : `${value}: `;
                },
              },
            },
          },
        };
  };

  useEffect(() => {
    setOptions(chartOptions(detailed));
    const loadData = async () => {
      setIsLoading(true);
      try {
        const res = await axios.get(
          `/teams/registrationStatus?groupBy=category&season=${season._id}`
        );

        setData(res.data.filter((ca) => ca.total > 0));
        setHasSubCategories(
          res.data.some((category) => category.subCategories.length > 0)
        );
      } catch (error) {
        setData([]);
        setHasSubCategories(false);
      }
      setIsLoading(false);
    };
    if (season?._id) {
      loadData();
    }
  }, [season]);

  useEffect(() => {
    if (!data) {
      return;
    }
    if (detailed) {
      setSeries(
        data.map((category) => {
          const data = category.subCategories
            .filter((sc) => sc.total > 0)
            .map((sc) => ({
              x: sc.subCategoryName,
              y: sc.total,
            }));

          if (data.length === 0) {
            data.push({ x: category.categoryName, y: category.total });
          }

          return {
            name: category.categoryName,
            data,
          };
        })
      );
    } else {
      setSeries([
        {
          data: data.map((category) => ({
            x: category.categoryName,
            y: category.total,
          })),
        },
      ]);
    }
  }, [data, detailed]);

  const handleChange = (e) => {
    setDetailed(e.target.checked);
    setOptions(chartOptions(e.target.checked));
  };

  if (isLoading) {
    return (
      <Box sx={{ height: 100, position: "relative" }}>
        <AbsoluteCenter>
          <CircularProgress />
        </AbsoluteCenter>
      </Box>
    );
  }

  if (data.length === 0) {
    return <></>;
  }

  return (
    <Box>
      {hasSubCategories && (
        <FormControlLabel
          sx={{ position: "absolute", top: 10, left: 50 }}
          label="הצג תתי-קטגוריות"
          control={<Checkbox checked={detailed} onChange={handleChange} />}
        />
      )}
      <Chart
        options={options}
        series={series}
        type="treemap"
        height={350}
        key={detailed ? "detailed" : "notDetailed"}
      />
    </Box>
  );
}
