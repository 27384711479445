import React, { Component } from "react";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import { Badge, Button, IconButton, Tooltip } from "@mui/material";

import UnseenUpdatesDialog from "./UnseenUpdatesDialog";
import axios from "../../../utils/axios";

export default class UnseenUpdates extends Component {
  state = {
    isLoadingUnseenUpdates: false,
    unseenUpdates: null,
    isDialogOpen: false,
  };

  componentDidMount = () => {
    this.loadData();
  };

  componentDidUpdate = async (prevProps) => {
    if (!prevProps.update && this.props.update) {
      this.loadData();
    }
  };

  loadData = async () => {
    this.setState({ isLoadingUnseenUpdates: true });
    const res = await axios.get(`/events/unseenUpdatesCount`);

    this.setState({
      isLoadingUnseenUpdates: false,
      unseenUpdates: res.data.count,
    });

    this.props.onComplete();
  };

  handleClose = () => () => {
    this.setState({ isDialogOpen: false });
    this.props.onClose && this.props.onClose();
  };

  render() {
    const count = this.state.unseenUpdates || 0;

    return (
      <>
        {this.props.mobile ? (
          <Button
            onClick={() => this.setState({ isDialogOpen: true })}
            startIcon={
              <Badge
                overlap="rectangular"
                color="primary"
                badgeContent={this.state.unseenUpdates}
                invisible={count === 0}
              >
                <DoneAllIcon />
              </Badge>
            }
          >
            עדכונים שלא נקראו
          </Button>
        ) : (
          <Tooltip title="עדכונים שלא נקראו" arrow>
            <IconButton
              size="small"
              onClick={() => this.setState({ isDialogOpen: true })}
            >
              <Badge
                overlap="rectangular"
                color="primary"
                badgeContent={this.state.unseenUpdates}
                invisible={count === 0}
              >
                <DoneAllIcon />
              </Badge>
            </IconButton>
          </Tooltip>
        )}
        <UnseenUpdatesDialog
          open={this.state.isDialogOpen}
          onClose={this.handleClose(false)}
          onUpdate={this.loadData}
          count={count}
        />
      </>
    );
  }
}
