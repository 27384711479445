import React from "react";
import {
  List,
  Divider,
  IconButton,
  Drawer as MuiDrawer,
  Box,
} from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import {
  MainListItems,
  MobileBottomItems,
  SecondaryListItems,
} from "../listItems";

export default function DrawerMobile(props) {
  return (
    <MuiDrawer
      variant="temporary"
      anchor="left"
      open={props.isOpen}
      onClose={props.onDrawerToggle}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
        dir: "rtl",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <IconButton onClick={props.onDrawerToggle} size="large">
          <ChevronRightIcon />
        </IconButton>
      </Box>
      <Divider />
      <List dense disablePadding>
        <MainListItems
          selectedClub={props.selectedClub}
          selectedItem={props.selectedItem}
          user={props.user}
          onClick={props.onDrawerToggle}
        />
      </List>
      <Divider />
      <List dense disablePadding>
        <SecondaryListItems
          selectedClub={props.selectedClub}
          selectedItem={props.selectedItem}
          user={props.user}
          onClick={props.onDrawerToggle}
        />
      </List>
      <Divider />
      <List dense>
        <MobileBottomItems />
      </List>
    </MuiDrawer>
  );
}
