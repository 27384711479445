import React from "react";
import { Dialog as MuiDialog, DialogTitle, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import AbstractBackground from "../resources/images/paperBackground.jpg";

const styles = {
  closeButton: {
    position: "absolute",
    right: 5,
    top: 9,
    color: "palette.grey[500]",
  },
  title: {
    backgroundImage: `url(${AbstractBackground})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
};

export default function Dialog(props) {
  return (
    <MuiDialog
      dir="rtl"
      fullWidth={props.fullWidth}
      fullScreen={props.fullScreen}
      maxWidth={props.maxWidth || "sm"}
      onClose={props.onClose}
      open={props.open}
      sx={props.sx}
    >
      {props.title && (
        <DialogTitle sx={styles.title}>{props.title}</DialogTitle>
      )}
      {props.closeButton && (
        <IconButton
          aria-label="close"
          sx={styles.closeButton}
          onClick={props.onClose}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      )}
      {props.children}
    </MuiDialog>
  );
}
