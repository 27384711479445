import React, { useEffect, useState } from "react";
import {
  Box,
  DialogActions,
  DialogContent,
  Stack,
  Step,
  StepLabel,
  Stepper,
  useMediaQuery,
} from "@mui/material";
import _ from "lodash";
import moment from "moment";

import ButtonSx from "../../../../base/ButtonSx";
import Dialog from "../../../../Dialog";
import CancelSubscriptionForm from "../../CancelSubscription/CancelSubscriptionForm";
import EditDirectSubscriptionForm from "../EditDirectSubscriptionForm";
import axios from "../../../../../utils/axios";
import { handleSubscriptionFieldsChanged } from "../../../../../utils/subscriptionHelpers";
import ChooseTeamForm from "./ChooseTeamForm";
import ChangeTeamSummary from "./ChangeTeamSummary";
import { displayPrice } from "../../../../../utils/helpers";
import {
  handleFeePriceChanged,
  handlePriceChanged,
  handleSettingsNewPrice,
} from "../editDirectSubscriptionHelper";

const getSteps = () => {
  return [
    "סיום פעילות בקבוצה הנוכחית",
    "בחירת קבוצה חדשה",
    "פרטי הקבוצה",
    "סיום",
  ];
};

const initNewSub = () => ({
  reason: "",
  errors: { fees: [] },
  fees: [],
  monthlyPrices: [],
  price: "",
  teamId: "",
  pricingId: "",
  startDate: "",
  productId: "",
  discountReason: "",
  hasFees: false,
  level: "",
  subLevel: "",
  instaValidate: false,
  // reason: "sdf",
  // errors: {},
  // monthlyPrices: [],
  // price: 100,
  // teamId: "62e9184ae8ab10001332511c",
  // startDate: moment(),
  // discountReason: "sdf",
  // hasFees: false,
  // level: "",
  // subLevel: "",
  // instaValidate: false,
  // team: {
  //   name: "test",
  // },
});

export default function ChangeTeamDialog({ subscription, ...props }) {
  const [activeStep, setActiveStep] = useState(0);
  const [oldSubData, setOldSubData] = useState({
    reason: "",
    cancelledAt: null,
    reasonError: false,
    dateError: false,
    fees: [],
    nonFeesPrice: null,
    nonFeesPriceStr: "",
    months: null,
    startDate: subscription.startDate,
    feesError: [],
    // reason: "sdf",
    // cancelledAt: moment(),
    // reasonError: false,
    // dateError: false,
    // nonFeesPrice: 200,
    // months: 3,
  });
  const [newSubData, setNewSubData] = useState(initNewSub());
  // show spinner while loading
  // const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [teams, setTeams] = useState({});
  const steps = getSteps();
  const isSmUp = useMediaQuery((theme) => theme.breakpoints.up("sm"), {
    noSsr: true,
  });

  useEffect(() => {
    oldSubActions.handleFieldsChange({
      fees: subscription.feeProducts.map((fp) => ({
        ...fp,
        priceStr: fp.price,
      })),
      nonFeesPrice: subscription.price,
      nonFeesPriceStr: subscription.price.toString(),
      months: subscription.months,
      team: subscription.team,
      feesError: [],
    });
    setNewSubData(initNewSub());
  }, [subscription]);

  useEffect(() => {
    if (props.isOpen) {
      loadTeams();
    }
  }, [props.isOpen]);

  const loadTeams = async () => {
    // setIsLoading(true);

    let res = await axios.get(
      "/teams?hasProduct=true&status=active&isSeasonOpen=true"
    );
    let teams = res.data;

    res = await axios.get(
      "/store/products?category=subscription&status=active&granularity=subProducts&expired=false"
    );

    const subscriptionProducts = res.data;

    teams.forEach((t) => {
      t.product = subscriptionProducts.find((sp) => sp._id === t.productId);
    });

    //remove teams with inactive or expired product
    teams = teams.filter((t) => t.product);

    let levels = _.groupBy(teams, (t) => t.teamLevel._id);
    levels = Object.entries(levels).map(([_id, teams]) => {
      const bySubLevel = _.groupBy(teams, (t) => (t.teamSubLevel || {}).name);
      const subLevels = Object.entries(bySubLevel).map(([slId, slTeams]) => ({
        _id: slId === "undefined" ? "" : slId,
        name: (slTeams[0].teamSubLevel || {}).name,
        teams: _.sortBy(slTeams, "name"),
      }));

      return {
        _id,
        name: teams[0].teamLevel.name,
        subLevels: _.sortBy(subLevels, "name"),
      };
    });

    levels = _.sortBy(levels, "name");

    setTeams({ teams, levels, subscriptionProducts });
    // setIsLoading(false);
  };

  //#region cancel old subscription
  const oldSubActions = {
    handleDateChange: (cancelledAt, months) => {
      const params = { cancelledAt };
      if (["season", "camp"].indexOf(subscription.newType) > -1) {
        params.nonFeesPrice = subscription.monthlyPrice * months;
        params.nonFeesPriceStr = subscription.monthlyPrice * months;
        params.months = months;
      } else if (months === 0) {
        params.nonFeesPrice = 0;
        params.nonFeesPriceStr = "0";
      }
      oldSubActions.handleFieldsChange(params);

      setNewSubData({
        ...initNewSub(),
        startDate: moment(cancelledAt),
      });
    },
    handleFieldChange: (field) => (value) => {
      setOldSubData({ ...oldSubData, ...{ [field]: value } });
    },
    handleFieldsChange: (updates) => {
      setOldSubData({ ...oldSubData, ...updates });
    },
    handlePriceChange: (priceStr, price) => {
      oldSubActions.handleFieldsChange({
        nonFeesPriceError:
          priceStr === "" || !(price >= 0 && price <= subscription.price),
        nonFeesPrice: price,
        nonFeesPriceStr: priceStr,
      });
    },
    handleFeePriceChange: (index) => (priceStr, price) => {
      const fees = [...oldSubData.fees];
      fees[index].priceStr = priceStr;
      fees[index].price = price;

      const feesError = [...oldSubData.feesError];
      feesError[index] = priceStr === "";

      oldSubActions.handleFieldsChange({ feesError, fees });
    },
    validate: () => {
      let valid = !oldSubData.feesError.some((e) => e);

      let reasonError = false;
      let dateError = false;
      let nonFeesPriceError = false;

      if (oldSubData.reason.length === 0) {
        reasonError = true;
        valid = false;
      }
      if (oldSubData.cancelledAt === null) {
        dateError = true;
        valid = false;
      }

      if (
        oldSubData.nonFeesPriceStr === "" ||
        !(
          oldSubData.nonFeesPrice >= 0 &&
          oldSubData.nonFeesPrice <= subscription.price
        )
      ) {
        nonFeesPriceError = true;
        valid = false;
      }

      oldSubActions.handleFieldsChange({
        dateError,
        reasonError,
        nonFeesPriceError,
        valid,
      });

      return valid;
    },
  };

  //#endregion

  const newSubActions = {
    handleFieldsChange: (updates) => {
      const updateSub = handleSubscriptionFieldsChanged(
        updates,
        newSubData,
        teams.subscriptionProducts,
        teams.teams
      );
      setNewSubData((prev) => ({ ...updateSub, errors: prev.errors }));
      if (newSubData.instaValidate) {
        newSubActions.validate(updateSub);
      }
    },
    handlePriceChange: (month, newPriceStr, newPrice) => {
      let updatedSub;
      setNewSubData((prev) => {
        updatedSub = handlePriceChanged(prev, month, newPriceStr, newPrice);
        return updatedSub;
      });

      if (newSubData.instaValidate) {
        newSubActions.validate(updatedSub);
      }
    },
    handleSettingNewPrice: (priceStr, price) => {
      let updatedSub;
      setNewSubData((prev) => {
        updatedSub = handleSettingsNewPrice(prev, priceStr, price);
        return updatedSub;
      });

      if (newSubData.instaValidate) {
        newSubActions.validate(updatedSub);
      }
    },
    handleFeePriceChange: (index) => (priceStr, price) => {
      let updatedSub;
      setNewSubData((prev) => {
        updatedSub = handleFeePriceChanged(index, prev, priceStr, price);
        return updatedSub;
      });

      if (newSubData.instaValidate) {
        newSubActions.validate(updatedSub);
      }
    },
    validateTeam: () => {
      const errors = {};
      if (!newSubData.level) {
        errors.level = true;
      } else if (
        newSubData.level.subLevels.length > 1 &&
        !newSubData.subLevel
      ) {
        errors.subLevel = true;
      }

      if (!newSubData.teamId) {
        errors.teamId = true;
      }

      setNewSubData((prev) => ({ ...prev, errors: { ...errors, fees: [] } }));
      return Object.keys(errors).length === 0;
    },
    validate: (updateSubRaw = null) => {
      const updatedSub = updateSubRaw || newSubData;
      const errors = {};
      let isValid = true;

      if (!updatedSub.startDate) {
        errors.startDate = "שדה חובה";
        isValid = false;
      }

      if (!updatedSub.pricingId) {
        errors.pricingId = "שדה חובה";
        isValid = false;
      }

      const originalMonthlyRate =
        updatedSub.product.subscriptionDetails.monthlyRate;

      errors.monthlyPrices = [];
      errors.monthlyPricesError = null;

      updatedSub.monthlyPrices.forEach((monthlyPrice) => {
        if (
          monthlyPrice.price < 0 ||
          monthlyPrice.price > originalMonthlyRate
        ) {
          errors.monthlyPrices.push(true);
          isValid = false;
          errors.monthlyPricesError = ` על המחיר להיות בין  ${displayPrice(
            originalMonthlyRate
          )} - ${displayPrice(0)}`;
        } else {
          errors.monthlyPrices.push(false);
        }
      });

      errors.fees = [];

      updatedSub.fees.forEach((fee) => {
        if (
          fee.priceStr === "" ||
          !(fee.price >= 0 && fee.price <= fee.originalPrice)
        ) {
          isValid = false;
          errors.fees.push(
            ` על המחיר להיות בין  ${displayPrice(
              fee.originalPrice
            )} - ${displayPrice(0)}`
          );
        } else {
          errors.fees.push("");
        }
      });

      setNewSubData((prev) => ({ ...prev, errors, instaValidate: true }));
      return isValid;
    },
  };

  const handleSave = async () => {
    const oldSub = {
      ..._.pick(oldSubData, ["reason", "nonFeesPrice", "months"]),
      cancelledAt: oldSubData.cancelledAt.format("YYYY-MM-DD"),
      feePrices: oldSubData.fees.map((fee) => ({
        _id: fee.feeProductId,
        price: fee.price,
      })),
    };

    const newSub = {
      ..._.pick(newSubData, [
        "price",
        "months",
        "discountReason",
        "teamId",
        "pricingId",
      ]),
      startDate: newSubData.startDate.format("YYYY-MM-DD"),
    };

    const type = newSubData.product.subscriptionDetails.subscriptionType;
    if (type === "season" || type === "camp") {
      newSub.monthlyPrices = newSubData.monthlyPrices.map((p) => ({
        month: moment(p.month).format("YYYY-MM-DD"),
        price: p.price,
        oneCharge: p.oneCharge,
      }));
    } else {
      newSub.unitPrice = newSubData.monthlyPrices[0].price;
    }

    newSub.subProducts = newSubData.fees.map((fee) => ({
      productId: fee.feeProductId,
      price: fee.price,
    }));

    const data = { type: "direct", oldSub, newSub };

    setIsSaving(true);
    await axios.patch(
      `/store/subscriptions/${subscription._id}/changeTeam`,
      data
    );
    setIsSaving(false);
  };

  const handleNext = async () => {
    switch (activeStep) {
      case 0:
        if (oldSubActions.validate()) {
          setActiveStep(1);
        }
        break;

      case 1:
        if (newSubActions.validateTeam()) {
          setActiveStep(2);
        }
        break;

      case 2:
        if (newSubActions.validate()) {
          setActiveStep(3);
        }
        break;

      case 3:
        await handleSave();
        props.onComplete();
        break;

      default:
        break;
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <CancelSubscriptionForm
            changeTeam
            subscription={subscription}
            onDateChange={oldSubActions.handleDateChange}
            months={oldSubData.months}
            cancelledAt={oldSubData.cancelledAt}
            reason={oldSubData.reason}
            setReason={oldSubActions.handleFieldChange("reason")}
            fees={oldSubData.fees}
            nonFeesPrice={oldSubData.nonFeesPrice}
            nonFeesPriceStr={oldSubData.nonFeesPriceStr}
            onFeePriceChange={oldSubActions.handleFeePriceChange}
            onPriceChange={oldSubActions.handlePriceChange}
            errors={{
              date: oldSubData.dateError,
              reason: oldSubData.reasonError,
              fees: oldSubData.feesError,
              nonFeesPrice: oldSubData.nonFeesPriceError,
            }}
          />
        );
      case 1:
        return (
          <ChooseTeamForm
            state={newSubData}
            subscriptionProducts={teams.subscriptionProducts}
            onFieldsChanged={newSubActions.handleFieldsChange}
            clubPlayer={props.clubPlayer}
            levels={teams.levels}
          />
        );

      case 2:
        return (
          <EditDirectSubscriptionForm
            subscriptionProducts={teams.subscriptionProducts}
            subscription={newSubData}
            onFieldsChanged={newSubActions.handleFieldsChange}
            onPriceChanged={newSubActions.handlePriceChange}
            onSettingNewPrice={newSubActions.handleSettingNewPrice}
            onFeePriceChange={newSubActions.handleFeePriceChange}
            summary
          />
        );

      case 3:
        return (
          <ChangeTeamSummary
            oldSubscription={oldSubData}
            newSubscription={newSubData}
            originalSubscription={subscription}
          />
        );

      default:
        return "Unknown step";
    }
  };

  return (
    <>
      <Dialog
        onClose={props.onClose}
        open={props.isOpen}
        title="מעבר קבוצה"
        closeButton
        fullScreen={!isSmUp}
        fullWidth
      >
        <DialogContent dividers sx={{ m: 0, p: { xs: "5px", sm: 2 } }}>
          <Box sx={styles.root}>
            <Stepper activeStep={activeStep} alternativeLabel sx={{ py: 2 }}>
              {steps.map((label, index) => (
                <Step key={label}>
                  <StepLabel>
                    <Box sx={index === activeStep ? styles.currentStep : {}}>
                      {label}
                    </Box>
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
            <Box sx={styles.stepContent}>{getStepContent(activeStep)}</Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Stack direction="row" spacing={1}>
            <ButtonSx disabled={activeStep === 0} onClick={handleBack}>
              חזרה
            </ButtonSx>
            <ButtonSx
              variant="contained"
              color="primary"
              onClick={handleNext}
              debounce
              isLoading={isSaving}
            >
              {activeStep === steps.length - 1 ? "סיום" : "המשך"}
            </ButtonSx>
          </Stack>
        </DialogActions>
      </Dialog>
    </>
  );
}

const styles = {
  root: {
    width: "100%",
  },
  currentStep: {
    fontWeight: "bold",
  },
  stepContent: {
    border: "1px solid #EFEFEF",
    borderRadius: "5px",
    padding: "15px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};
