import React from "react";
import moment from "moment";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  Grid,
  Typography,
  Box,
} from "@mui/material";

import { collisions as collisionTypes } from "../../utils/dictionaries";
import ButtonSx from "../../components/base/ButtonSx";

function CollisionsDialog(props) {
  const { collisions } = props;

  return (
    <>
      <Dialog
        open={props.open}
        onClose={props.onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        fullWidth
        dir="rtl"
      >
        <DialogTitle id="alert-dialog-title">
          האירוע מתנגש עם האירועים הבאים
        </DialogTitle>
        <DialogContent dividers>
          {collisions &&
            collisions.map((collision, index) => {
              return (
                <React.Fragment key={collision.event._id}>
                  <Grid container>
                    <Grid item>
                      <Divider
                        orientation="vertical"
                        sx={styles.startDivider}
                      />
                    </Grid>
                    <Grid item xs>
                      <Box sx={styles.eventTitle}>
                        {collision.event.title}{" "}
                        {collision.reasons.map((res) => (
                          <Box
                            component="span"
                            sx={styles.collisionDescription}
                            key={res}
                          >
                            {collisionTypes[res]}
                          </Box>
                        ))}
                      </Box>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                      >
                        {moment(collision.event.end).format("HH:mm")} -{" "}
                        {moment(collision.event.start).format("HH:mm")},{" "}
                        {collision.event.resources
                          .map((r) => r.name)
                          .join(", ")}
                      </Typography>
                    </Grid>
                  </Grid>
                  {index < collisions.length - 1 && (
                    <div>
                      <Divider sx={styles.divider} />
                    </div>
                  )}
                </React.Fragment>
              );
            })}
        </DialogContent>
        <DialogActions>
          <ButtonSx onClick={props.onClose} autoFocus>
            {props.source === "dialog" ? "חזרה לעריכה" : "ביטול"}
          </ButtonSx>
          <ButtonSx onClick={props.onComplete} debounce>
            שמור בכל זאת
          </ButtonSx>
        </DialogActions>
      </Dialog>
    </>
  );
}

const styles = {
  eventTitle: {
    fontWeight: "500",
    mb: 1,
  },
  collisionDescription: {
    backgroundColor: (theme) => theme.calendar.collisions,
    padding: "5px 10px",
    borderRadius: ".25rem",
    color: "#ffffff",
    fontWeight: "bold",
    marginLeft: "10px",
  },
  divider: {
    my: "10px",
  },
  startDivider: {
    borderWidth: "2px",
    borderRadius: "2px",
    mx: "10px",
  },
};

export default CollisionsDialog;
