import React, { useMemo } from "react";
import moment from "moment";
import { Link as RouterLink } from "react-router-dom";
import { Link, IconButton } from "@mui/material";
import OpenIcon from "@mui/icons-material/InfoOutlined";

import {
  displayDate,
  displayPrice,
  displaySeason,
} from "../../../utils/helpers";
import Table from "../../table/Table";
import { subscriptionsStatuses } from "../../../utils/dictionaries";

const baseColumns = [
  { id: "team", label: "קבוצה" },
  { id: "season", label: "עונה" },
  { id: "status", label: "סטטוס" },
  { id: "start", label: "תחילת פעילות" },
];

const additionalColumns = [
  { id: "cost", label: "עלות" },
  { id: "debt", label: "חוב" },
];
const infoColumn = { id: "info", label: "", width: 40 };

function SubscriptionsTable(props) {
  const columns = useMemo(
    () => [
      ...baseColumns,
      ...(props.showMoney ? additionalColumns : []),
      infoColumn,
    ],
    [props.showMoney]
  );

  const renderCell = (columnId, subscription) => {
    switch (columnId) {
      case "team":
        return subscription.team ? (
          <Link
            component={RouterLink}
            color="primary"
            to={`../teams/${subscription.team._id}`}
            underline="hover">
            {subscription.team.name}
          </Link>
        ) : (
          `${subscription.teamName}`
        );

      case "season":
        return displaySeason(subscription.team.seasonYear);

      case "status":
        if (subscription.status === "cancelled") {
          return `${subscriptionsStatuses[subscription.status]}${
            subscription.subStatus === "teamChange" ? " / מעבר קבוצה" : ""
          }`;
        }
        return subscription.trial
          ? "ניסיון"
          : subscriptionsStatuses[subscription.status];

      case "start":
        return subscription.startDate
          ? displayDate(subscription.startDate)
          : displayDate(subscription.createdAt);

      case "cost":
        return subscription.type === "direct"
          ? displayPrice(subscription.totalPrice)
          : "-";

      case "paid":
        return subscription.type === "direct"
          ? displayPrice(subscription.paid)
          : "-";

      case "debt":
        return subscription.type === "direct"
          ? displayPrice(subscription.debt)
          : "-";

      case "info":
        return (
          <IconButton size="small">
            <OpenIcon />
          </IconButton>
        );

      default:
        return subscription[columnId];
    }
  };

  const sortValueCell = (columnId, subscription) => {
    switch (columnId) {
      case "start":
        return moment(subscription.startDate).unix();

      default:
        return subscription[columnId];
    }
  };

  return (
    <Table
      columns={columns}
      renderCell={renderCell}
      sortValueCell={sortValueCell}
      rows={props.subscriptions}
      onRowClick={props.onItemClicked}
      isLoading={props.isLoading}
      noHeader
      defaultSortBy={3}
      defaultSortDirection="desc"
    />
  );
}

export default SubscriptionsTable;
