import React, { useMemo } from "react";
import { useMediaQuery } from "@mui/material";

import DrawerDesktop from "./DrawerDesktop";
import DrawerMobile from "./DrawerMobile";
import { useLocation } from "react-router-dom";

const routes = [
  { name: "dashboard", absoluteRegex: /^\/clubs\/[a-z_]+$/ },
  { name: "coaches", regex: /coaches(\/.*)*/ },
  { name: "teams", regex: /teams(\/.*)*/ },
  { name: "players", regex: /players(\/.*)*/ },
  { name: "parents", regex: /parents(\/.*)*/ },
  { name: "parents", regex: /accounts(\/.*)+/ },
  { name: "calendar", regex: /calendar(\/.*)*/ },
  { name: "attendance", regex: /attendance(\/.*)*/ },
  {
    name: "newPurchaseSubscription",
    regex: /purchases\/new$/,
    queryRegex: /type=subscription(&.*)*/,
  },
  {
    name: "newPurchaseMerchandise",
    regex: /purchases\/new$/,
    queryRegex: /type=merchandise(&.*)*/,
  },
  { name: "products", regex: /store\/admin\/products(\/.*)*/ },
  {
    name: "purchasesMerchandise",
    regex: /store\/purchases\/merchandise(\/.*)*/,
  },
  { name: "debt", regex: /reports\/debt(\/.*)*/ },
  { name: "subscriptions", regex: /reports\/subscriptionLogs(\/.*)*/ },
  { name: "quitters", regex: /reports\/quitters(\/.*)*/ },
  {
    name: "subscriptionDiscountRequests",
    regex: /subscriptions\/discountRequests(\/.*)*/,
  },
  { name: "transactions", regex: /payments\/transactions(\/.*)*/ },
  { name: "receipts", regex: /payments\/receipts(\/.*)*/ },
  { name: "checks", regex: /payments\/checks(\/.*)*/ },
  { name: "deposits", regex: /payments\/deposits(\/.*)*/ },
  {
    name: "accountingDocuments",
    regex: /payments\/accountingDocuments(\/.*)*/,
  },
  { name: "generalSettings", regex: /settings\/generalSettings(\/.*)*/ },
  {
    name: "subscriptionProducts",
    regex: /settings\/subscriptionProducts(\/.*)*/,
  },
  { name: "playerAlerts", regex: /settings\/playerAlerts(\/.*)*/ },
  { name: "seasons", regex: /settings\/seasons(\/.*)*/ },
  { name: "users", regex: /users$/ },
  { name: "parentsSite", regex: /settings\/parentsSite(\/.*)*$/ },
  { name: "paymentsDistribution", regex: /reports\/paymentsDistribution$/ },
  {
    name: "monthlyIncomePerProduct",
    regex: /reports\/monthlyIncomePerProduct$/,
  },
  { name: "incomeReport", regex: /reports\/income$/ },
  { name: "discountsReport", regex: /reports\/discounts$/ },
  { name: "revenuesReport", regex: /reports\/revenues$/ },
  { name: "dealReceipts", regex: /dealReceipts$/ },
].map((item) => {
  item.regex =
    item.absoluteRegex || new RegExp("^/clubs/[a-z_]+/" + item.regex.source);
  return item;
});

export default function Drawer(props) {
  const isMdUp = useMediaQuery((theme) => theme.breakpoints.up("md"), {
    noSsr: true,
  });

  const location = useLocation();

  const selectedMenuItem = () => {
    for (let index = 0; index < routes.length; index++) {
      const route = routes[index];

      if (location.pathname.match(route.regex)) {
        if (route.queryRegex) {
          if (location.search.match(route.queryRegex)) {
            return route.name;
          }
        } else {
          return route.name;
        }
      }
    }

    console.warn("route not found");
  };

  const selectedItem = useMemo(
    () => selectedMenuItem(),
    [location.pathname, location.search]
  );

  if (props.isOpen === null) {
    return <span />;
  }

  return isMdUp ? (
    <DrawerDesktop
      selectedItem={selectedItem}
      selectedClub={props.selectedClub}
      isOpen={props.isOpen}
      onDrawerToggle={props.onDrawerToggle}
      user={props.user}
      Header={props.header}
    />
  ) : (
    <DrawerMobile
      selectedItem={selectedItem}
      selectedClub={props.selectedClub}
      isOpen={props.isOpen}
      onDrawerToggle={props.onDrawerToggle}
      user={props.user}
    />
  );
}
