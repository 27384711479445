import React from "react";
import { DialogActions, DialogContent } from "@mui/material";

import Dialog from "../../../components/Dialog";
import ButtonSx from "../../../components/base/ButtonSx";
import InfoRow from "../../../components/InfoRows/InfoRow";
import { displayPrice, roundPrice } from "../../../utils/helpers";

export default function FirstMonthAutoPriceUpdateDialog(props) {
  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      maxWidth="xs"
      fullWidth
      title="עדכון עלות החודש הראשון"
    >
      <DialogContent dividers>
        תאריך תחילת הפעילות הינו אחרי ה 15 לחודש. האם ברצונכם לעדכן את מחיר
        החודש הראשון?
        <InfoRow
          title="מחיר מלא"
          value={displayPrice(props.subscription?.pricing?.unitPrice)}
        />
        <InfoRow
          title="מחיר לאחר העדכון"
          value={
            <u>
              {displayPrice(
                roundPrice(props.subscription?.pricing?.unitPrice / 2)
              )}{" "}
              (50%)
            </u>
          }
        />
      </DialogContent>
      <DialogActions>
        <ButtonSx variant="outlined" onClick={props.onClose}>
          ביטול
        </ButtonSx>
        <ButtonSx variant="outlined" onClick={props.onComplete} debounce>
          עדכון המחיר
        </ButtonSx>
      </DialogActions>
    </Dialog>
  );
}
