import React from "react";
import { TextField, FormControlLabel, Checkbox } from "@mui/material";
import _ from "lodash";

import MenuItem from "../../../components/base/MenuItem";

const ResourceSelect = (props) => {
  const { event, resources } = props;

  const renderValue = (selectedValues) => {
    return selectedValues.map((value) => resources[value].name).join(", ");
  };

  const onResourcesChanged = (uiEvent) => {
    const newResourceId = _.difference(
      uiEvent.target.value,
      event.resources
    )[0];
    let selectedResources = uiEvent.target.value;

    // only add siblings
    if (newResourceId) {
      selectedResources = [];
      const newResource = resources[newResourceId];
      if (newResource.parent) {
        // newResource is subResource
        const parent = resources[newResource.parent];
        uiEvent.target.value.forEach((selectedResourceId) => {
          if (parent.subResources.indexOf(selectedResourceId) > -1) {
            selectedResources.push(selectedResourceId);
          }
        });

        if (selectedResources.length === parent.subResources.length) {
          selectedResources = [parent._id];
        }
      } else {
        selectedResources.push(newResourceId);
      }
    }
    // unselect
    props.onFieldUpdated("resources", selectedResources);
  };

  return (
    <TextField
      variant="standard"
      error={props.errors.resources !== undefined}
      select={!props.readOnly}
      disabled={event.type === "game" && event.gameDetails.gameType === "away"}
      label="מגרש"
      fullWidth
      value={props.readOnly ? renderValue(event.resources) : event.resources}
      onChange={onResourcesChanged}
      margin="dense"
      SelectProps={{
        multiple: true,
        renderValue,
        dir: "rtl",
      }}
      inputProps={{
        name: "resources",
        id: "resources",
      }}
    >
      {props.resources
        ? _.sortBy(Object.values(resources), (r) => r.name).map((resource) => {
            return (
              <MenuItem key={resource._id} value={resource._id}>
                <div>
                  <FormControlLabel
                    labelPlacement="start" // patch for rtl
                    control={
                      <Checkbox
                        checked={
                          event.resources &&
                          event.resources.indexOf(resource._id) > -1
                        }
                      />
                    }
                    label={resource.name}
                  />
                </div>
              </MenuItem>
            );
          })
        : null}
    </TextField>
  );
};

export default ResourceSelect;
