import React, { useMemo } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Checkbox, Link, useMediaQuery } from "@mui/material";
import moment from "moment";

import { displayDate, displayPrice } from "../../../utils/helpers";
import Table from "../../../components/table/Table";
import { exportTable } from "../../../lib/tableHelpers";
import { purchaseProductStatuses } from "../../../utils/dictionaries";
import ButtonSx from "../../../components/base/ButtonSx";

const generateColumns = (playerPage) => [
  { id: "product", label: "מוצר" },
  { id: "option", label: "פרטים" },
  { id: "purchasedAt", label: "תאריך" },
  { id: "price", label: "מחיר" },
  { id: "status", label: "סטטוס" },
  ...(playerPage
    ? [{ id: "quantity", label: "כמות" }]
    : [
        { id: "customer", label: "לקוח" },
        { id: "player", label: "ספורטאי" },
        { id: "team", label: "קבוצה" },
      ]),
  { id: "transaction", label: "עסקה" },
];

export default function MerchandiseTable(props) {
  const checkedProductsCount = useMemo(
    () => props.products.filter((p) => p.checked).length,
    [props.products]
  );
  const isSmUp = useMediaQuery((t) => t.breakpoints.up("sm"), { noSsr: true });
  const columns = useMemo(
    () => generateColumns(props.playerPage),
    [props.playerPage]
  );

  const sortValueCell = (columnId, productPurchase) => {
    switch (columnId) {
      case "price":
        return productPurchase.price;

      case "transaction":
        return productPurchase.transaction.publicId;

      case "purchasedAt":
        return moment(productPurchase.purchasedAt).toDate().getTime();

      case "team":
        return productPurchase.team?.name || "";

      default:
        return renderCell(columnId, productPurchase);
    }
  };

  const renderCell = (columnId, productPurchase) => {
    switch (columnId) {
      case "product":
        return (
          <span style={{ display: "flex", alignItems: "center" }}>
            <Checkbox
              checked={productPurchase.checked || false}
              onClick={props.onItemCheckToggle(productPurchase)}
              disabled={productPurchase.status !== "pending"}
            />
            <div>{productPurchase.product.title}</div>
          </span>
        );

      case "price":
        return displayPrice(productPurchase.price);

      case "purchasedAt":
        return displayDate(productPurchase.purchasedAt);

      case "customer":
        return productPurchase.customer.name;

      case "player":
        if (productPurchase.clubPlayer) {
          return (
            <Link
              component={RouterLink}
              color="primary"
              to={`../players/${productPurchase.clubPlayer._id}`}
              underline="hover"
            >
              {productPurchase.clubPlayer?.name}
            </Link>
          );
        } else if (productPurchase.guestPlayer) {
          return `${productPurchase.guestPlayer?.name} (אורח)`;
        } else {
          // old purchases
          return "-";
        }

      case "transaction":
        return (
          <Link
            component={RouterLink}
            color="primary"
            to={`../payments/transactions/${productPurchase.transaction._id}`}
            underline="hover"
          >
            {productPurchase.transaction.receiptNumber}
          </Link>
        );

      case "status":
        return purchaseProductStatuses[productPurchase.status];

      case "option":
        return productPurchase.product.option || "-";

      case "team":
        return productPurchase.team ? (
          <Link
            component={RouterLink}
            color="primary"
            to={`../teams/${productPurchase.team._id}`}
            underline="hover"
          >
            {productPurchase.team.name}
          </Link>
        ) : (
          ""
        );

      default:
        return productPurchase[columnId];
    }
  };

  const renderCellExport = (columnId, productPurchase) => {
    switch (columnId) {
      case "product":
        return productPurchase.product.title;

      case "price":
        return productPurchase.price;

      case "player":
        if (productPurchase.clubPlayer) {
          return productPurchase.clubPlayer?.name;
        } else if (productPurchase.guestPlayer) {
          return `${productPurchase.guestPlayer?.name} (אורח)`;
        } else {
          return "-";
        }

      case "transaction":
        return productPurchase.transaction.publicId;

      case "team":
        return productPurchase.team?.name;

      default:
        return renderCell(columnId, productPurchase);
    }
  };

  const handleExportTable = () => {
    exportTable(
      props.products,
      columns,
      renderCellExport,
      "csv",
      "רכישת מוצרים"
    );
  };

  return (
    <Table
      title={props.title}
      columns={columns}
      renderCell={renderCell}
      sortValueCell={sortValueCell}
      defaultSortBy={2}
      defaultSortDirection="desc"
      rows={props.products}
      onExport={handleExportTable}
      isLoading={props.isLoading}
      customHeaderItems={
        <ButtonSx
          disabled={checkedProductsCount === 0}
          onClick={props.onShowUpdateDialog}
          sx={{ width: undefined }}
        >
          עדכון סטטוס {isSmUp ? "הפריטים שנבחרו" : ""} ({checkedProductsCount})
        </ButtonSx>
      }
    />
  );
}
