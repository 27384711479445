import React from "react";
import { Box, Stack } from "@mui/material";

import Paper2Sx from "../../../../components/Paper2Sx";
import InfoRow from "../../../../components/InfoRows/InfoRow";
import {
  displayPrice,
  displayPriceRound,
  roundPrice,
} from "../../../../utils/helpers";

export default function SubscriptionProductCostsSummary({
  subscriptionProduct,
}) {
  const isSeasonCamp =
    ["season", "camp"].indexOf(subscriptionProduct.subscriptionType) > -1;
  const months = isSeasonCamp ? subscriptionProduct.months : 1;
  const price = subscriptionProduct.singlePricing
    ? roundPrice(subscriptionProduct.pricings[0].unitPrice * months) || 0
    : "עלות משתנה";

  const monthlyCostBreakdown = isSeasonCamp ? (
    <span>
      {" "}
      ({displayPrice(subscriptionProduct.pricings[0]?.unitPrice || 0)}{" "}
      {"\u00D7"} {subscriptionProduct.months || 0} חודשי פעילות)
    </span>
  ) : (
    ""
  );

  const cost = subscriptionProduct.singlePricing ? (
    <Stack direction="row">
      <Box sx={styles.totalPrice}>
        {displayPrice(price)}
        {monthlyCostBreakdown}
      </Box>
    </Stack>
  ) : (
    "עלות משתנה"
  );

  return (
    <Paper2Sx
      externalTitle="סיכום עלויות"
      paperSx={{ backgroundColor: "#e4f6ff" }}
    >
      <InfoRow title="עלות" value={cost} />
      <InfoRow
        title="דמי רישום"
        value={
          <span>
            <Box>{displayPriceRound(subscriptionProduct.feesTotal || 0)}</Box>
          </span>
        }
      />
      <InfoRow
        title="סה״כ"
        value={
          <span>
            <Box sx={styles.totalPrice}>
              {subscriptionProduct.singlePricing
                ? displayPriceRound(
                    price + (subscriptionProduct.feesTotal || 0)
                  )
                : "עלות משתנה"}
            </Box>
          </span>
        }
      />
    </Paper2Sx>
  );
}

const styles = {
  totalPrice: {
    fontWeight: "bold",
    marginRight: "10px",
  },
};
