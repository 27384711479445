import React from "react";
import { Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import queryString from "query-string";

import axios from "../../../utils/axios";
import SubscriptionsTable from "./SubscriptionsTable";
import SnackbarNg from "../../base/SnackbarNg";
import ResponsiveContainer from "../../ResponsiveContainer";
import SubscriptionsList from "./SubscriptionsList";
import Subscription from "./Subscription";
import TlPaper from "../../TlPaper";
import withRouter from "../../routing/withRouter";

class Subscriptions extends React.Component {
  state = {
    snackbar: {
      open: false,
      message: null,
      status: null,
      subscription: null,
    },
    currentSubscription: null,
    currentSubscriptionOpenEdit: null,
  };

  componentDidMount = async () => {
    const qs = queryString.parse(location.search);
    await this.loadData(() => {
      if (qs.subscription) {
        const sub = this.state.subscriptions.filter(
          (s) => s._id === qs.subscription
        );

        // remove subscription's auto open on edit mode so if page is refreashed it won't open again
        this.props.navigate("", { replace: true });

        if (sub) {
          this.setState({
            currentSubscription: sub[0],
            currentSubscriptionOpenEdit: qs.subMode === "edit",
          });
        }
      }
    });
  };

  componentDidUpdate = async (prevProps) => {
    if (
      prevProps.subscriptionId !== this.props.subscriptionId ||
      (prevProps.player?.trial && !this.props.player.trial) // after removing trial player from the trial team
    ) {
      this.setState({ currentSubscription: null });
      this.loadData();
    }
  };

  loadData = async (callback) => {
    const subs = [
      ...this.props.activeSubscriptions,
      ...this.props.inactiveSubscriptions,
    ];

    if (subs.length > 0) {
      const res = await axios.get(
        `/store/subscriptions?playerId=${this.props.player._id}&granularity=team`
      );

      this.setState(
        { subscriptions: res.data, subscriptionIds: subs },
        callback
      );
    } else if (this.props.player.trial) {
      this.setState({
        // _id: 'trial' is used as the key of the row by <Table>
        subscriptions: [
          { _id: "trial", trial: true, team: this.props.player.trialTeam },
        ],
        subscriptionIds: [],
      });
    } else {
      this.setState({ subscriptions: [], subscriptionIds: [] }, callback);
    }
  };

  //snack = {status, message, subscription}
  onComplete = (snack, reloadUser) => {
    this.loadData();
    if (snack) {
      this.setState({
        snackbar: {
          open: true,
          subscription: snack.subscription,
          status: snack.status,
          message: snack.message,
        },
      });
    }
    if (reloadUser) {
      this.props.onComplete(); // reload user when updating trial subscription
    }
  };

  render() {
    const subscribeLink = this.props.player.status !== "trial" &&
      this.props.hasRegistrationAdminPermission && (
        <Link
          component={RouterLink}
          color="primary"
          to={`../purchases/new?type=subscription&clubPlayerId=${this.props.player._id}&clubAccountId=${this.props.player.clubAccountId}`}
          underline="hover"
        >
          רישום למסגרת חדשה
        </Link>
      );

    return (
      <TlPaper
        title="פעילויות"
        leftItem={subscribeLink}
        titleBackground
        isLoading={!this.state.subscriptions}
      >
        <ResponsiveContainer
          DesktopComponent={SubscriptionsTable}
          MobileComponent={SubscriptionsList}
          subscriptions={this.state.subscriptions}
          onItemClicked={(sub) => this.setState({ currentSubscription: sub })}
          showMoney
        />

        <Subscription
          open={this.state.currentSubscription !== null}
          onClose={() => {
            this.setState({ currentSubscription: null });
          }}
          subscription={this.state.currentSubscription}
          player={this.props.player}
          onComplete={this.onComplete}
          currentSubscriptionOpenEdit={this.state.currentSubscriptionOpenEdit}
        />

        <SnackbarNg
          snackKey={this.state.snackbar.subscription?._id}
          open={this.state.snackbar.open}
          onClose={() => this.setState({ snackbar: { open: false } })}
          severity={this.state.snackbar.status}
          message={this.state.snackbar.message}
        />
      </TlPaper>
    );
  }
}

export default withRouter(Subscriptions);
