import React, { useState } from "react";
import { Grid, FormControlLabel, Checkbox, Alert } from "@mui/material";

import { displayDate, displayPrice } from "../../../utils/helpers";
import { checkStatuses } from "../../../utils/dictionaries";
import ReturnedCheckConfirmationDialog from "./ReturnedCheckConfirmationDialog";

export default function CancelChecks(props) {
  const [isReturnedCheckDialogOpen, setIsReturnedCheckDialogOpen] =
    useState(false);
  const { transaction } = props;

  const handleChange = (e) => {
    const check = props.transaction.checks.find((c) => c._id === e.target.name);

    if (
      !props.returnChecks &&
      check.status === "deposited" &&
      e.target.checked
    ) {
      setIsReturnedCheckDialogOpen(true);
    }

    props.onChange(check, e.target.checked);
  };

  return (
    <>
      <ReturnedCheckConfirmationDialog
        open={isReturnedCheckDialogOpen}
        onClose={() => setIsReturnedCheckDialogOpen(false)}
      />

      <Grid container spacing={1} direction="column">
        {(transaction.checks || []).map((check) => {
          const disabled = props.returnChecks
            ? check.status !== "deposited"
            : ["cancelled", "refund", "bounced"].indexOf(check.status) > -1 ||
              (props.cancelledChecks.indexOf(check) === -1 &&
                check.amount >
                  Math.round(
                    (props.refundAmount - props.totalCancelledChecksAmount) *
                      100
                  ) /
                    100);
          return (
            <Grid item xs={12} key={check._id}>
              <FormControlLabel
                disabled={disabled}
                control={
                  <Checkbox
                    sx={{ py: 0 }}
                    checked={props.cancelledChecks.indexOf(check) > -1}
                    onChange={handleChange}
                    name={check._id}
                  />
                }
                label={`${displayDate(check.date)} ${displayPrice(
                  check.amount
                )} ${check.checkNumber} ${checkStatuses[check.status]}`}
              />
            </Grid>
          );
        })}
        {props.errors?.cancelledChecks && (
          <Alert severity="error" sx={{ mr: "-10px" }}>
            {props.errors?.cancelledChecks}
          </Alert>
        )}
      </Grid>
    </>
  );
}
