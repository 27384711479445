import React from "react";
import {
  List,
  TextField,
  CircularProgress,
  Tooltip,
  Stack,
} from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import DeleteIcon from "@mui/icons-material/Delete";
import _ from "lodash";
import moment from "moment";

import ResourceGroup from "./ResourceGroup";
import AbsoluteCenter from "../../../components/AbsoluteCenter";
import IconButtonTooltip from "../../../components/base/IconButtonTooltip";
import axios from "../../../utils/axios";
import { getAxiosErrorData } from "../../../utils/helpers";
import SnackbarNg from "../../../components/base/SnackbarNg";
import ButtonSx from "../../../components/base/ButtonSx";
import ListItem from "../../../components/ListItem";

export default class ResourceGroups extends React.Component {
  state = {
    newGroupDialogOpen: false,
    groups: null,
    currentGroupName: "",
    isLoading: false,
    isSaving: false,
    error: null,
    snackbar: {},
    isEditing: false,
  };

  componentDidMount = async () => {
    this.loadGroups(false);
  };

  componentDidUpdate = async (prevProps) => {
    if (prevProps.selectedGroup !== null && this.props.selectedGroup === null) {
      this.setState({ selectedGroup: null });
    }
  };

  loadGroups = async (silence = true) => {
    this.setState({ isLoading: !silence });
    let res = await axios.get("/calendar/resources");
    const resources = _.sortBy(
      res.data.filter((resource) => !resource.parent),
      "sortId"
    ); //only parents
    res = await axios.get("/calendar/resourceGroups");
    let groups = res.data.filter((g) => !g.default);

    groups.forEach((group) => {
      group.resources = resources.map((r) => ({
        ...r,
        selected: group.resources.find((gr) => gr === r._id) !== undefined,
      }));
    });

    groups = _.orderBy(groups, ["name"], ["desc", "asc"]);
    this.setState({ groups, resources, isLoading: false });
  };

  addNewGroup = () => {
    this.setState((prevState) => {
      return {
        isEditing: true,
        groups: [
          ...prevState.groups,
          {
            name: "",
            new: true,
            availableResources: [...prevState.resources],
            selectedResources: [],
          },
        ],
        currentGroupName: "",
      };
    });
  };

  cancelNewGroup = () => {
    this.setState((prevState) => {
      return {
        isEditing: false,
        groups: prevState.groups.filter((g) => !g.new),
        currentGroupName: "",
      };
    });
  };

  saveNewGroup = async () => {
    try {
      this.setState({ isSaving: true });
      this.props.onChange();
      await axios.post("/calendar/resourceGroups", {
        name: this.state.currentGroupName,
        resources: [],
      });
      this.setState({
        isSaving: false,
        isEditing: false,
        error: null,
        snackbar: { key: moment().format() },
      });
      await this.loadGroups();
    } catch (error) {
      const errorData = getAxiosErrorData(error);
      if ((errorData || {}).error === "notUnique") {
        this.setState({
          error: "קיימת קבוצה עם שם זהה",
          isSaving: false,
        });
      }
    }
  };

  saveSelectedGroup = _.debounce(async () => {
    const resources = this.state.selectedGroup.resources
      .filter((r) => r.selected)
      .map((r) => r._id);

    this.props.onChange();
    await axios.patch(
      `/calendar/resourceGroups/${this.state.selectedGroup._id}`,
      {
        name: this.state.selectedGroup.name,
        resources,
      }
    );

    this.setState({ snackbar: { key: moment().format() } });
  }, 200);

  handleGroupUpdate = async (resource) => {
    this.setState(
      (prev) => ({
        selectedGroup: {
          ...prev.selectedGroup,
          resources: prev.selectedGroup.resources.map((r) => ({
            ...r,
            selected: r._id === resource._id ? !r.selected : r.selected,
          })),
        },
      }),
      this.saveSelectedGroup
    );

    this.loadGroups();
  };

  handleDeleteGroup = (group) => async (e) => {
    e.stopPropagation();
    this.props.onChange();
    await axios.delete(`/calendar/resourceGroups/${group._id}`);
    this.setState({
      groups: this.state.groups.filter((g) => g._id !== group._id),
    });
    this.loadGroups();
    this.setState({ snackbar: { key: moment().format() } });
  };

  handleSelectGroup = (group) => () => {
    if (!group.new) {
      this.setState({ selectedGroup: group });
      this.props.onSelectSubSetting(group);
    }
  };

  renderNewGroup = () => {
    return (
      <Stack direction="row" spacing={1} sx={{ flex: 1, ml: 1 }}>
        <TextField
          placeholder="שם קבוצת המגרשים"
          variant="standard"
          autoFocus
          fullWidth
          value={this.state.currentGroupName}
          onChange={(e) => this.setState({ currentGroupName: e.target.value })}
          error={this.state.error !== null}
          helperText={this.state.error}
          sx={{ flexGrow: 1 }}
        />
        <ButtonSx
          variant="outlined"
          onClick={this.cancelNewGroup}
          sx={{ width: 100, height: 35 }}
          disabled={this.state.isSaving}
        >
          ביטול
        </ButtonSx>
        <ButtonSx
          variant="outlined"
          onClick={this.saveNewGroup}
          sx={{ width: 100, height: 35 }}
          debounce
          isLoading={this.state.isSaving}
        >
          שמירה
        </ButtonSx>
      </Stack>
    );
  };

  renderGroups = () => {
    return (
      <div>
        <List sx={styles.list} disablePadding>
          {this.state.groups.map((group, i) => {
            if (group.new) {
              return (
                <ListItem
                  listItemProps={{ disablePadding: false }}
                  key="new"
                  content={this.renderNewGroup()}
                />
              );
            }
            return (
              <ListItem
                divider={i < this.state.groups.length - 1}
                key={group._id || "new"}
                button
                onClick={this.handleSelectGroup(group)}
                primaryText={group.name}
              >
                {group.default ? (
                  <Tooltip title="לא ניתן למחוק קבוצה זו">
                    <LockIcon fontSize="small" sx={{ mr: "11px" }} />
                  </Tooltip>
                ) : (
                  <IconButtonTooltip
                    title="מחיקת קבוצה המגרשים"
                    onClick={this.handleDeleteGroup(group)}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButtonTooltip>
                )}
              </ListItem>
            );
          })}
        </List>

        <ButtonSx
          fullWidth
          onClick={this.addNewGroup}
          disabled={this.state.isEditing}
        >
          יצירת קבוצת מגרשים חדשה
        </ButtonSx>
      </div>
    );
  };

  renderContent = () => {
    return (
      <>
        {this.state.selectedGroup ? (
          <ResourceGroup
            group={this.state.selectedGroup}
            onChange={this.handleGroupUpdate}
          />
        ) : (
          this.renderGroups()
        )}
        <SnackbarNg
          snackKey={this.state.snackbar.key}
          open={this.state.snackbar.key !== undefined}
          onClose={() => this.setState({ snackbar: {} })}
          message="הפעולה בוצעה בהצלחה"
        />
      </>
    );
  };

  render() {
    return this.state.isLoading || !this.state.groups ? (
      <AbsoluteCenter>
        <CircularProgress />
      </AbsoluteCenter>
    ) : (
      this.renderContent()
    );
  }
}

const styles = {
  list: {
    border: "1px solid #EEE",
    borderRadius: 1,
    mt: 1,
    mb: 2,
  },
};
