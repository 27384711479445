import React from "react";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";

export default function ErrorDialog(props) {
  return (
    <Dialog
      dir="rtl"
      open={props.open}
      onClose={props.onClose}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>{props.title || "שגיאה"}</DialogTitle>
      <DialogContent dividers>
        {props.content || (
          <>
            <div>לא ניתן לבצע פעולה זו כרגע</div>
            <div>אנא נסו מאוחר יותר</div>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose}>אישור</Button>
      </DialogActions>
    </Dialog>
  );
}
