import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  Button,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Typography,
  Popover,
  ListItemButton,
  Box,
  useMediaQuery,
  DialogActions,
  DialogContent,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { useNavigate } from "react-router-dom";
import _ from "lodash";

import * as Actions from "./../../store/actions";
import ResponsiveContainer from "../../components/ResponsiveContainer";
import { AdvancedImage, lazyload } from "@cloudinary/react";
import { cloudinaryImage } from "../../utils/cloudinary";
import Dialog from "../../components/Dialog";
import ButtonSx from "../../components/base/ButtonSx";

const MainLogo = styled(AdvancedImage)({
  height: "50px",
  width: "50px",
  margin: 5,
});

const PopupLogo = styled(AdvancedImage)({
  height: 45,
  width: 45,
  border: "1px solid #ECECEC",
  borderRadius: 25,
  backgroundColor: "white",
});

const Container = styled("div")({
  alignItems: "center",
  display: "flex",
  flexDirection: "row",
});

function ClubSelectContent({ currentClub, clubs, onClick, open, textColor }) {
  const mainLogo = cloudinaryImage(currentClub.clubConfig.general.logo.name, {
    version: currentClub.clubConfig.general.logo.version,
    size: "thumbnail",
  });

  return (
    <Button onClick={onClick} disableRipple>
      <Container>
        <MainLogo cldImg={mainLogo} />
        <Typography variant="h5" noWrap sx={{ color: textColor || "#FFFFFF" }}>
          {currentClub.clubConfig.general.name}
        </Typography>
        {clubs.length > 1 &&
          (open ? (
            <ArrowDropUpIcon sx={{ color: textColor || "#FFFFFF" }} />
          ) : (
            <ArrowDropDownIcon sx={{ color: textColor || "#FFFFFF" }} />
          ))}
      </Container>
    </Button>
  );
}

function ClubSelectXsContent({ currentClub, clubs, onClick, open, textColor }) {
  const mainLogo = cloudinaryImage(currentClub.clubConfig.general.logo.name, {
    version: currentClub.clubConfig.general.logo.version,
    size: "thumbnail",
  });
  return (
    <Box sx={{ display: "flex", alignItems: "center", flex: 1 }}>
      <Button onClick={onClick} disableRipple sx={{ p: 0 }}>
        <Typography
          variant="h6"
          color="inherit"
          noWrap
          sx={{ color: textColor || "#FFFFFF" }}
        >
          {currentClub.clubConfig.general.shortName}
        </Typography>
      </Button>
      {clubs.length > 1 &&
        (open ? (
          <ArrowDropUpIcon sx={{ color: textColor || "#FFFFFF" }} />
        ) : (
          <ArrowDropDownIcon sx={{ color: textColor || "#FFFFFF" }} />
        ))}
      <div style={{ flexGrow: 1 }} />
      <MainLogo cldImg={mainLogo} />
    </Box>
  );
}

function ClubSelect(props) {
  const [clubs, setClubs] = React.useState([]);
  const navigate = useNavigate();
  const isSmUp = useMediaQuery((t) => t.breakpoints.up("sm"), { noSsr: true });
  const [anchorEl, setAnchorEl] = React.useState(null);

  useEffect(() => {
    const regularClubs = props.clubs.filter((c) => !c.superClub);
    setClubs(_.sortBy(regularClubs, (c) => c.clubConfig.general.name));
  }, [props.clubs]);

  let currentClub = props.clubs.find((c) => c._id === props.selectedClub._id);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClubSelect = (e, club) => {
    if (club._id === props.selectedClub._id) {
      e.preventDefault();
    }
    // changeClub is required
    // without it auth.changeClub is called after the dashboard has already requested the data (and got the previous club data)
    props.changeClub(club, true);
    navigate(`/clubs/${club.internalName}`, { replace: false });
    handleClose();
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderPopUpContent = () => {
    let superClubLogo;
    if (
      props.superClub &&
      props.superClub.clubConfig &&
      props.superClub.clubConfig.superClubAdminSection
    ) {
      superClubLogo = cloudinaryImage(
        `/logos/${props.superClub.internalName}`,
        { size: "thumbnail" }
      );
    }

    return (
      <List>
        {props.superClub &&
          props.superClub.clubConfig &&
          props.superClub.clubConfig.superClubAdminSection && (
            <>
              <ListItem disablePadding>
                <ListItemButton
                  onClick={(e) => handleClubSelect(e, props.superClub)}
                >
                  <ListItemAvatar>
                    <PopupLogo cldImg={superClubLogo} alt="Logo" />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Box sx={{ fontWeight: "bold" }}>
                        {props.superClub.clubConfig.general.name}
                      </Box>
                    }
                  />
                </ListItemButton>
              </ListItem>
              <Divider />
            </>
          )}
        {clubs.map((club) => {
          const logo = cloudinaryImage(club.clubConfig.general.logo.name, {
            version: club.clubConfig.general.logo.version,
            size: "thumbnail",
          });

          return (
            <ListItem disablePadding key={club._id}>
              <ListItemButton onClick={(e) => handleClubSelect(e, club)}>
                <ListItemAvatar>
                  <PopupLogo cldImg={logo} alt="Logo" plugins={[lazyload()]} />
                </ListItemAvatar>
                <ListItemText primary={club.clubConfig.general.name} />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    );
  };

  const renderPopUp = () => {
    if (isSmUp) {
      return (
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          sx={{ maxHeight: "calc(100vh - 60px)" }}
          dir="rtl"
        >
          <Paper>{renderPopUpContent()}</Paper>
        </Popover>
      );
    }

    return (
      <Dialog
        open={open}
        onClose={handleClose}
        fullScreen
        title="בחירת ענף"
        closeButton
      >
        <DialogContent sx={{ p: 0 }} dividers>
          {renderPopUpContent()}
        </DialogContent>
        <DialogActions>
          <ButtonSx fullWidth onClick={handleClose}>
            סגירה
          </ButtonSx>
        </DialogActions>
      </Dialog>
    );
  };

  return clubs.length > 1 ? (
    <>
      <ResponsiveContainer
        DesktopComponent={ClubSelectContent}
        MobileComponent={ClubSelectXsContent}
        clubs={clubs}
        currentClub={currentClub}
        onClick={handleClick}
        open={open}
        textColor={props.textColor}
      />
      {renderPopUp()}
    </>
  ) : currentClub && clubs.length === 1 ? (
    <ResponsiveContainer
      DesktopComponent={ClubSelectContent}
      MobileComponent={ClubSelectXsContent}
      clubs={clubs}
      currentClub={currentClub}
      onClick={handleClick}
      open={open}
    />
  ) : (
    ""
  );
}

function mapStateToProps(state) {
  return {
    selectedClub: state.auth.selectedClub,
    clubs: state.auth.clubs,
    superClub: state.auth.superClub,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changeClub: (club, gentle) =>
      dispatch(Actions.auth.changeClub(club, gentle)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ClubSelect);
