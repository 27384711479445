import React from "react";
import {
  IconButton,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  useMediaQuery,
  Box,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import { displayPrice, roundPrice } from "../../utils/helpers";
import ReviewItemLeftSide from "./ReviewItemLeftSide";
import PlusMinusButton from "../../components/buttons/PlusMinusButtons";

export default function MerchandiseReviewItem({ item, onQuantityChange }) {
  const isSmUp = useMediaQuery((theme) => theme.breakpoints.up("sm"), {
    noSsr: true,
  });

  const option = item.optionId
    ? `, ${item.product.optionsType} ${item.optionTitle}`
    : "";

  const handleQuantityChange = (quantityDiff) => {
    onQuantityChange(
      item.product,
      item.price,
      item.clubPlayer,
      item.optionId,
      quantityDiff
    );
  };

  return (
    <ListItem sx={styles.listItem} divider>
      <ListItemText
        primary={item.product.title}
        secondaryTypographyProps={{ component: "span" }}
        secondary={
          <Box sx={styles.secondaryTextContainer}>
            <div>
              {item.clubPlayer.name}
              {option}
            </div>
            <Box sx={styles.priceContainer}>
              <strong>{displayPrice(item.price)}</strong>
            </Box>
            {!isSmUp && (
              <>
                <PlusMinusButton
                  value={item.quantity}
                  onChange={handleQuantityChange}
                />

                <Box sx={styles.mobileItemTotalPriceContainer}>
                  <ReviewItemLeftSide
                    topRight="לתשלום: "
                    topCenter={displayPrice(
                      roundPrice(item.price * item.quantity)
                    )}
                    topLeft={
                      <IconButton
                        onClick={() => handleQuantityChange(-item.quantity)}
                        size="large"
                      >
                        <DeleteIcon />
                      </IconButton>
                    }
                  />
                </Box>
              </>
            )}
          </Box>
        }
      />

      {isSmUp && (
        <ListItemSecondaryAction>
          <ReviewItemLeftSide
            topRight="לתשלום: "
            topCenter={displayPrice(roundPrice(item.price * item.quantity))}
            topLeft={
              <IconButton
                onClick={() => handleQuantityChange(-item.quantity)}
                size="large"
              >
                <DeleteIcon />
              </IconButton>
            }
            bottom={
              <PlusMinusButton
                fullWidth
                value={item.quantity}
                onChange={handleQuantityChange}
              />
            }
          />
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
}

const styles = {
  listItem: {
    my: 1,
    mx: 0,
    borderRadius: "10px",
    backgroundColor: "#FFF",
    border: "1px solid #EEE",
  },
  priceContainer: {
    marginTop: "10px",
    color: "#000",
  },
  secondaryTextContainer: {
    display: "flex",
    flexDirection: "column",
  },
  mobileItemTotalPriceContainer: {
    marginTop: "10px",
    backgroundColor: "#e7f1fa",
    border: "1px solid #EEE",
    borderRadius: "7px",
  },
};
