import React, { useMemo } from "react";
import { TextField } from "@mui/material";
import moment from "moment";

import { renderDateOptions } from "./subscriptionProductsHelper";
import DateRangePicker from "../../../../components/DateRangePicker";
import MenuItem from "../../../../components/base/MenuItem";

export default function SubscriptionProductStartDate(props) {
  const { subscriptionProduct, errors, onChange } = props;
  const { subscriptionType } = subscriptionProduct;

  const options = useMemo(() => {
    if (["season", "camp"].indexOf(subscriptionType) > -1) {
      let { startDate } = subscriptionProduct;
      if (!startDate || moment().isBefore(startDate)) {
        startDate = moment().startOf("month");
      }

      const pastMonths = moment().startOf("month").diff(startDate, "month");
      return renderDateOptions(startDate, pastMonths + 6, "start");
    } else {
      return <MenuItem value=""></MenuItem>;
    }
  }, [subscriptionType, subscriptionProduct]);

  const monthStartDate = useMemo(
    () =>
      subscriptionProduct?.startDate
        ? subscriptionProduct.startDate.format("YYYY-MM-DD")
        : "",
    [subscriptionProduct?.startDate]
  );

  const handleMonthDateChange = (e) => {
    onChange({ startDate: moment(e.target.value) });
  };

  switch (subscriptionType) {
    case "":
    case "season":
    case "camp":
      return (
        <TextField
          select
          fullWidth
          margin="dense"
          variant="standard"
          label="תאריך התחלה"
          value={monthStartDate}
          disabled={subscriptionType === ""}
          error={errors?.startDate !== undefined}
          helperText={errors?.startDate || " "}
          onChange={handleMonthDateChange}
          inputProps={{ readOnly: !props.editable }}
        >
          {options}
        </TextField>
      );

    case "daily":
    case "fixedPrice":
      return (
        <DateRangePicker
          textFieldProps={{
            label: "תאריכים",
            error:
              errors?.startDate !== undefined || errors?.endDate !== undefined,
            helperText: errors?.startDate || errors?.endDate || " ",
          }}
          mode="startEnd"
          startDate={subscriptionProduct.startDate}
          endDate={subscriptionProduct.endDate}
          autoClose
          readOnly={!props.editable}
          onDatesChanged={(startDate, endDate) =>
            onChange({ startDate, endDate })
          }
        />
      );

    default:
      break;
  }
}
