import React, { useMemo } from "react";
import { TextField, Popover, useMediaQuery, Dialog } from "@mui/material";
import moment from "moment";

import DatePickerAdvancedPopupContent from "./DatePickerAdvancedPopupContent";

export default function DatePickerAdvanced(props) {
  const [popupOpen, setPopupOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  // the content width is 700px
  const is700Up = useMediaQuery((t) => t.breakpoints.up("700"), {
    noSsr: true,
  });

  const isSingleMode = props.mode === "single";

  const value = useMemo(() => {
    const format = props.type === "month" ? "MMMM YY" : "DD/MM/YY";
    if (
      !isSingleMode &&
      !moment(props.dates.to).startOf(props.type).isSame(props.dates.from)
    ) {
      return `${props.dates.from.format(format)} - ${props.dates.to.format(
        format
      )}`;
    }
    return `${props.dates.from.format(format)}`;
  }, [props.dates.to, props.dates.from]);

  const handlePresetSelected = async (dates) => {
    await props.onFilterChanged(dates);
    setPopupOpen(false);
  };

  const handleDateChange = (type) => (date) => {
    props.onFilterChanged({ ...props.dates, [type]: date });
    if (isSingleMode) {
      setPopupOpen(false);
    }
  };

  const handleClose = () => {
    setPopupOpen(false);
  };

  const renderPopupContent = () => (
    <DatePickerAdvancedPopupContent
      onClose={handleClose}
      noPresets={props.noPresets}
      type={props.type}
      isSingleMode={props.isSingleMode}
      onPresetSelected={handlePresetSelected}
      onDateChanged={handleDateChange}
      dates={props.dates}
      calendarProps={props.calendarProps}
    />
  );

  return (
    <>
      <TextField
        ref={anchorRef}
        label={props.label || "תאריך"}
        size="small"
        onClick={() => setPopupOpen(true)}
        InputProps={{ readOnly: true }}
        value={value}
        fullWidth={props.fullWidth}
        inputProps={{ sx: { pl: { xs: "5px", sm: "14px" } } }}
      />
      {is700Up ? (
        <Popover
          dir="rtl"
          open={popupOpen}
          anchorEl={anchorRef.current}
          onClose={handleClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          transformOrigin={{ vertical: "top", horizontal: "center" }}
        >
          {renderPopupContent()}
        </Popover>
      ) : (
        <Dialog
          onClose={handleClose}
          open={popupOpen}
          title="הפסקת פעילות"
          fullScreen
        >
          {renderPopupContent()}
        </Dialog>
      )}
    </>
  );
}
