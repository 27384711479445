import moment from "moment";
import _ from "lodash";

export const getSeasonDetails = (subscriptionProduct) => {
  const startDate = subscriptionProduct
    ? moment(subscriptionProduct.subscriptionDetails.startDate).startOf("day")
    : null;

  const endDate = subscriptionProduct
    ? moment(subscriptionProduct.subscriptionDetails.endDate).startOf("day")
    : null;

  const months = subscriptionProduct
    ? subscriptionProduct.subscriptionDetails.numberOfMonths
    : null;

  return { startDate, endDate, months };
};

export const handleSubscriptionFieldsChanged = (
  updates,
  subscription,
  subscriptionProducts,
  teams
) => {
  const updatedSub = {
    ...subscription,
    ...updates,
  };

  // change to a new team ( and not just cleared the value)
  if (Object.prototype.hasOwnProperty.call(updates, "teamId")) {
    if (!updates.teamId) {
      updatedSub.pricingId = "";
      updatedSub.pricing = null;
      updatedSub.team = "";
      updatedSub.product = "";
      updatedSub.monthlyPrices = [];
      updatedSub.endDate = "";
      updatedSub.hasFees = "";
      updatedSub.fees = [];
      updatedSub.price = 0;
    } else {
      updatedSub.team = teams.find((t) => t._id === updates.teamId);

      if (updatedSub.team.product._id === "external") {
        updatedSub.product = updatedSub.team.product;
        updatedSub.pricingId = "";
        updatedSub.pricing = null;
        updatedSub.monthlyPrices = [];
        updatedSub.endDate = "";
        updatedSub.hasFees = "";
        updatedSub.fees = [];
        updatedSub.price = 0;
        return updatedSub;
      }
      updatedSub.product = subscriptionProducts.find(
        (p) => p._id === updatedSub.team.productId
      );

      if (updatedSub.product.subscriptionDetails.singlePricing) {
        updatedSub.pricingId =
          updatedSub.product.subscriptionDetails.pricings[0]._id;
      } else {
        updatedSub.pricingId = "";
        updatedSub.pricing = null;
      }

      updatedSub.monthlyPrices = [];

      const { startDate: seasonStart, endDate: seasonEnd } = getSeasonDetails(
        updatedSub.product
      );
      updatedSub.endDate = seasonEnd;

      if (
        updatedSub.startDate &&
        !moment(updatedSub.startDate).isBetween(seasonStart, seasonEnd)
      ) {
        updatedSub.startDate = seasonStart;
      }

      updatedSub.hasFees = updatedSub.product.subProducts.length > 0;
      updatedSub.fees = updatedSub.product.subProducts.map((sp) => ({
        feeProductId: sp._id,
        price: sp.price,
        priceStr: sp.price,
        originalPrice: sp.price,
        title: sp.title,
      }));
    }
  }

  if (updatedSub.product && updatedSub.product._id !== "external") {
    updateMonthlyPrices(updatedSub, updates);
  }

  return updatedSub;
};

const updateMonthlyPrices = (updatedSub, updates) => {
  const pricing = updatedSub.product.subscriptionDetails.pricings.find(
    (p) => p._id === updatedSub.pricingId
  );

  if (pricing) {
    updatedSub.pricing = pricing;
    if (
      ["season", "camp"].indexOf(
        updatedSub.product.subscriptionDetails.subscriptionType
      ) > -1
    ) {
      if (updatedSub.startDate) {
        let startMonth = moment(updatedSub.startDate).startOf("month");
        let month = moment(startMonth);
        // add new months
        while (month.isBefore(updatedSub.endDate)) {
          const price = updatedSub.monthlyPrices.find((monthlyPrice) =>
            monthlyPrice.month.isSame(month)
          );
          if (!price) {
            updatedSub.monthlyPrices.push({
              month,
              price: pricing.unitPrice,
              priceStr: pricing.unitPrice.toString(),
            });
          }
          month = moment(month).add(1, "month");
        }

        // remove months
        updatedSub.monthlyPrices = updatedSub.monthlyPrices.filter(
          (monthlyPrice) =>
            moment(monthlyPrice.month).isBetween(
              startMonth,
              updatedSub.endDate,
              null,
              "[)"
            ) // including the startMonth, exluding endDate (endDate exluclusion doesn't really matter because it is the end of month and monthlyPrice.month is the beginning )
        );
      }
    } else if (updatedSub.monthlyPrices.length === 0) {
      updatedSub.monthlyPrices = [
        {
          // MonthlyCostForm expects a month for oneCharge as well
          month: moment(),
          oneCharge: true,
          price: pricing.unitPrice,
          priceStr: pricing.unitPrice.toString(),
        },
      ];
    }
    updatedSub.monthlyPrices.sort(
      (a, b) => a.month.toDate().getTime() - b.month.toDate().getTime()
    );

    if (Object.prototype.hasOwnProperty.call(updates, "pricingId")) {
      updatedSub.monthlyPrices.forEach((item) => {
        item.price = pricing.unitPrice;
        item.priceStr = pricing.unitPrice.toString();
      });
    }

    updatedSub.price = _.sumBy(updatedSub.monthlyPrices, "price");
  }
};
