import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Checkbox, Divider, Link, Typography, Box } from "@mui/material";
import moment from "moment";
import InfoIcon from "@mui/icons-material/InfoOutlined";

import {
  displayDate,
  displayPrice,
  displaySeason,
} from "../../../utils/helpers";
import Table from "../../../components/table/Table";
import { exportTable } from "../../../lib/tableHelpers";
import {
  discountRequestStatuses,
  subscriptionsStatuses,
} from "../../../utils/dictionaries";
import InfoRow from "../../../components/InfoRows/InfoRow";
import Tooltip from "../../../components/base/ToolTip";

function SubscriptionsTable(props) {
  const sortValueCell = (columnId, subscription) => {
    switch (columnId) {
      case "team":
        return subscription.team.name;

      case "unbalanceType":
        return subscription.debt > 0 ? "יתרת חוב" : "יתרת זכות";

      case "player":
        return subscription.clubPlayer.name;

      case "debtExcessPayments":
        return -subscription.debt;

      case "season":
        return props.seasonsDic[subscription.team.season];

      default:
        return subscription[columnId];
    }
  };

  const renderDiscountRequestPopup = (subscription) => {
    return (
      <div>
        <Typography variant="h6">הוגשה בקשה מיוחדת</Typography>
        <Divider sx={styles.discountRequestDivider} />
        <InfoRow
          title="תאריך"
          value={displayDate(subscription.createdAt)}
          titleSx={styles.infoRowTitle}
        />
        <InfoRow
          title="סטטוס"
          value={
            discountRequestStatuses[
              subscription.specialRequest.discountRequest.status
            ]
          }
          titleSx={styles.infoRowTitle}
        />
      </div>
    );
  };

  const renderOtherPaymentMethodPopup = (subscription) => {
    return (
      <div>
        <Typography variant="h6">בקשה לתשלום באמצעות מזומן/צ׳ק</Typography>
        <Divider sx={styles.discountRequestDivider} />
        <InfoRow
          title="תאריך"
          value={displayDate(subscription.createdAt)}
          titleSx={styles.infoRowTitle}
        />
      </div>
    );
  };

  const renderCell = (columnId, subscription) => {
    switch (columnId) {
      case "paid":
        if (subscription.specialRequest && subscription.specialRequest.type) {
          const title =
            subscription.specialRequest.type === "discount"
              ? renderDiscountRequestPopup(subscription)
              : renderOtherPaymentMethodPopup(subscription);
          return (
            <Box sx={styles.paidContainer}>
              <Tooltip arrow title={title}>
                <InfoIcon style={{ fontSize: 16, marginLeft: 5 }} />
              </Tooltip>
              <span>{displayPrice(subscription[columnId] || 0)}</span>
            </Box>
          );
        }
        return displayPrice(subscription[columnId] || 0);

      case "totalPrice":
        return displayPrice(subscription[columnId] || 0);

      case "debt":
        return subscription.debt > 0 ? displayPrice(subscription.debt) : 0;

      case "debtExcessPayments":
        return subscription.debt < 0
          ? displayPrice(Math.abs(subscription.debt))
          : 0;

      case "createdAt":
        return moment(subscription.createdAt).format("DD/MM/YY LT");

      case "type":
        return subscription.type === "direct" ? "ישיר" : "עקיף";

      case "player":
        return (
          <Link
            component={RouterLink}
            color="primary"
            to={`../players/${subscription.clubPlayer._id}`}
            underline="hover"
          >
            {subscription.clubPlayer.name}
          </Link>
        );

      case "status":
        return subscriptionsStatuses[subscription.status];

      case "team":
        return (
          <Link
            component={RouterLink}
            color="primary"
            to={`../teams/${subscription.team._id}`}
            underline="hover"
          >
            {subscription.team.name}
          </Link>
        );

      case "season":
        return displaySeason(props.seasonsDic[subscription.team.season]);

      case "autoDebtAlert":
        return (
          <Checkbox
            checked={subscription.autoDebtAlert}
            onChange={props.onAutoDebtAlertChanged(subscription)}
            disabled={
              subscription.status === "cancelled" ||
              subscription.status === "completed"
            }
          />
        );

      default:
        return subscription[columnId];
    }
  };

  const renderCellExport = (columnId, subscription) => {
    switch (columnId) {
      case "player":
        return subscription.clubPlayer.name;

      case "team":
        return subscription.team.name;

      case "totalPrice":
      case "paid":
        return subscription[columnId] || 0;

      case "debt":
        return subscription.debt > 0 ? subscription.debt : 0;

      case "debtExcessPayments":
        return subscription.debt < 0 ? Math.abs(subscription.debt) : 0;

      case "autoDebtAlert":
        return subscription.status !== "cancelled" &&
          subscription.status !== "completed" &&
          subscription.autoDebtAlert
          ? "פעילה"
          : "לא פעילה";

      default:
        return renderCell(columnId, subscription);
    }
  };

  const handleExportTable = () => {
    exportTable(
      props.subscriptions,
      props.columns,
      renderCellExport,
      "csv",
      props.exportFileName || "רישום לפעילות"
    );
  };

  return (
    <Table
      title={props.title}
      columns={props.columns}
      renderCell={renderCell}
      sortValueCell={sortValueCell}
      rows={props.subscriptions}
      onExport={handleExportTable}
      isLoading={props.isLoading}
    />
  );
}

const styles = {
  discountRequestDivider: {
    borderColor: "white",
  },
  paidContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  infoRowTitle: {
    width: 70,
  },
};

export default SubscriptionsTable;
