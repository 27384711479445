import React, { useMemo } from "react";
import { Alert, Grid, TextField } from "@mui/material";
import moment from "moment-timezone";
import _ from "lodash";

import {
  displayDate,
  displayPrice,
  roundPrice,
} from "../../../../utils/helpers";
import PriceInputNg from "../../../PriceInputNG";
import DatePicker from "../../../DatePicker";
import SubscriptionHeaderItem from "../Direct/SubscriptionHeaderItem";

export default function CancelSubscriptionForm({
  subscription,
  onDateChange,
  cancelledAt,
  reason,
  setReason,
  fees,
  nonFeesPrice,
  nonFeesPriceStr,
  errors,
  onPriceChange,
  onFeePriceChange,
  changeTeam,
}) {
  const handleDateChange = (date) => {
    const newDate = moment(date).startOf("day");
    const cancelDateSameAsStart = newDate.isSame(
      moment.tz(subscription.startDate, "Asia/Tel_Aviv").startOf("day")
    );

    if (["season", "camp"].indexOf(subscription.newType) > -1) {
      const endDate = moment(subscription.endDate).endOf("day");
      let diff;

      if (cancelDateSameAsStart) {
        diff = subscription.months;
      } else {
        diff = moment(endDate).diff(newDate, "month");
        const dayOfMonth = newDate.date();
        if (dayOfMonth <= 15) {
          diff += 0.5;
        }
      }
      onDateChange(date, subscription.months - diff);
    } else {
      onDateChange(date, cancelDateSameAsStart ? 0 : 1);
    }
  };

  const calendarProps = useMemo(
    () =>
      subscription.type === "direct"
        ? {
            minDate: moment(subscription.startDate),
            maxDate: moment().isBefore(subscription.startDate)
              ? moment(subscription.startDate)
              : moment(),
          }
        : {},
    [subscription.type]
  );

  const totalCost = useMemo(
    () => roundPrice(_.sumBy(fees, "price")) + nonFeesPrice,
    [fees, nonFeesPrice]
  );

  const debt = totalCost - subscription.paid;

  return (
    <Grid container sx={styles.container}>
      <Grid item container xs={12} md={8} spacing={1}>
        <Grid item xs={12}>
          <DatePicker
            label={changeTeam ? "תאריך מעבר הקבוצה" : "תאריך הפסקת פעילות"}
            textFieldProps={{
              error: errors.date,
              helperText: errors.date ? "שדה חובה" : "",
            }}
            {...calendarProps}
            onChange={handleDateChange}
            value={cancelledAt}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="standard"
            required
            fullWidth
            label={changeTeam ? "סיבת מעבר הקבוצה" : "סיבת הפסקת הפעילות"}
            value={reason}
            onChange={(e) => {
              setReason(e.target.value);
            }}
            error={errors.reason}
            helperText={errors.reason ? "שדה חובה" : ""}
          />
        </Grid>
        {fees.map((fee, index) => (
          <Grid item xs={12} key={fee.feeProductId}>
            <PriceInputNg
              required
              fullWidth
              label={fee.title}
              value={fee.priceStr}
              onChange={onFeePriceChange(index)}
              error={errors.fees[index]}
              helperText={errors.fees[index] ? "יש להזין מחיר תקין" : ""}
            />
          </Grid>
        ))}
        {subscription.type === "direct" && (
          <>
            <Grid item xs={12}>
              <PriceInputNg
                required
                fullWidth
                label="מחיר הפעילות (ללא דמי רישום)"
                value={nonFeesPriceStr}
                onChange={onPriceChange}
                error={errors.nonFeesPrice}
                helperText={
                  errors.nonFeesPrice
                    ? `על המחיר להיות בין ${displayPrice(0)} ו ${displayPrice(
                        subscription.price
                      )}`
                    : ""
                }
              />
            </Grid>
          </>
        )}
      </Grid>
      {subscription.type === "direct" && (
        <>
          <Grid item xs={12} md={4}>
            <SubscriptionHeaderItem
              sx={styles.startDateItem}
              title="תחילת פעילות"
              value={displayDate(subscription.startDate)}
            />
          </Grid>
          <Grid item container spacing={3} sx={{ my: 1 }}>
            <Grid item xs={6} md={4}>
              <SubscriptionHeaderItem
                title="עלות כוללת"
                value={displayPrice(totalCost)}
              />
            </Grid>{" "}
            <Grid item xs={6} md={4}>
              <SubscriptionHeaderItem
                title="שולם"
                value={displayPrice(subscription.paid)}
              />
            </Grid>{" "}
            <Grid item xs={6} md={4}>
              <SubscriptionHeaderItem
                title={debt < 0 ? "יתרת זכות" : "יתרת חוב"}
                value={displayPrice(Math.abs(debt))}
              />
            </Grid>
          </Grid>

          {changeTeam && debt < 0 && (
            <Grid item xs={12}>
              <Alert severity="info" sx={{ mt: 1 }}>
                יתרת הזכות תועבר אוטומטית לקבוצה החדשה
              </Alert>
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
}

const styles = {
  container: {
    width: "100%",
    margin: 0,
  },
  startDateItem: {
    mb: 2,
    ml: 2,
    borderBottomColor: "success.light",
    height: "100%",
  },
};
