import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Link } from "@mui/material";

import * as Browser from "../utils/browser";
import {
  generateCsv,
  displayDate,
  displayDateTime,
  displayPrice,
} from "../utils/helpers";

export const exportTable = async (
  rows,
  columns,
  renderCell,
  exportType,
  filename
) => {
  let data, csv;
  switch (exportType) {
    case "csv":
      data = rows.map((row) => renderRow(row, columns, renderCell));
      csv = await generateCsv(data, {
        headers: columns.map((c) => c.exportLabel || c.label),
      });
      downloadCsv(csv, filename);
      break;

    default:
      throw new Error("unimplemented");
  }
};

const renderRow = (row, columns, renderCell) => {
  const cells = [];
  for (let index = 0; index < columns.length; index++) {
    const column = columns[index];

    cells.push(renderCell(column.id, row));
  }

  return cells;
};

export const downloadCsv = async (csv, filename) => {
  const content = `data:text/csv;charset=utf-8,%EF%BB%BF${encodeURIComponent(
    csv
  )}`;
  Browser.download(content, `${filename}.csv`, "text/csv");
};

export const renderCell = (
  columns,
  exportMode,
  baseRenderer,
  displayRenderer
) => {
  const columnsMap = new Map();
  columns.forEach((col) => {
    columnsMap.set(col.id, col);
  });

  return function cellRenderer(columnId, record) {
    const column = columnsMap.get(columnId);
    let value;
    if (column.baseRenderer || column.preset === "link") {
      value = baseRenderer(columnId, record, column, exportMode);
    } else {
      if ("defaultValue" in column && !(columnId in record)) {
        value = column.defaultValue;
      } else {
        value = record[columnId];
      }
    }

    switch (column.preset) {
      case "price":
        return exportMode ? value : isNaN(value) ? value : displayPrice(value);

      case "percent":
        return `${value}%`;

      case "link":
        return exportMode || column.noLink ? (
          value.label
        ) : value.label ? (
          <Link
            component={RouterLink}
            color="primary"
            to={value.to}
            {...column.linkProps}
            underline="hover"
          >
            {value.label}
          </Link>
        ) : null;

      case "mapping":
        return column.mapping[value];

      case "datetime":
        return displayDateTime(value);

      case "date":
        return displayDate(value);

      default:
        return displayRenderer
          ? displayRenderer(columnId, record, value)
          : value;
    }
  };
};

export const sortValueCell = (columns, baseRenderer, sortRenderer) => {
  const columnsMap = new Map();
  columns.forEach((col) => {
    columnsMap.set(col.id, col);
  });

  return (columnId, record) => {
    const column = columnsMap.get(columnId);
    const value =
      column.baseRenderer || column.preset === "link"
        ? baseRenderer(columnId, record, column)
        : record[columnId];

    switch (column.preset) {
      case "price":
      case "percent":
        return value;

      case "link":
        return value.label;

      case "mapping":
        return column.mapping[value];

      default:
        return sortRenderer ? sortRenderer(columnId, record, value) : value;
    }
  };
};
