import React from "react";
import { DialogActions, DialogContent } from "@mui/material";

import Dialog from "../../../components/Dialog";
import ButtonSx from "../../../components/base/ButtonSx";

export default function MerchandiseUpdateStatusDialog(props) {
  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      onClose={props.onClose}
      open={props.open}
      title="עדכון סטטוס"
    >
      <DialogContent dividers>
        האם ברצונך לעדכן את הפריטים שנבחרו לסטטוס ״התקבל״?
      </DialogContent>
      <DialogActions>
        <ButtonSx onClick={props.onClose} disabled={props.isLoading}>
          ביטול
        </ButtonSx>
        <ButtonSx debounce onClick={props.onUpdate} isLoading={props.isLoading}>
          אישור
        </ButtonSx>
      </DialogActions>
    </Dialog>
  );
}
