import React, { useMemo } from "react";
import _ from "lodash";

import { displayPrice, roundPrice } from "../../utils/helpers";
import InfoRow from "../../components/InfoRows/InfoRow";
import Table from "../../components/table/Table";

export default function TeamPricing({ product }) {
  const { subscriptionType } = product.subscriptionDetails;
  const columns = useMemo(() => {
    const base = [{ id: "title", label: "תיאור" }];
    switch (subscriptionType) {
      case "season":
        base.push(
          { id: "unitPrice", label: "מחיר חודשי" },
          { id: "totalPrice", label: "מחיר כולל" },
          { id: "quantity", label: "מספר ימים בשבוע" }
        );
        break;

      case "fixedPrice":
        base.push({ id: "unitPrice", label: "מחיר" });
        break;

      case "daily":
        base.push(
          { id: "unitPrice", label: "מחיר" },
          { id: "quantity", label: "מספר ימים" }
        );
        break;

      default:
        break;
    }
    return base;
  }, [subscriptionType]);

  const renderPricingCell = (columnId, pricing) => {
    switch (columnId) {
      case "unitPrice":
        return displayPrice(pricing.unitPrice);

      case "totalPrice":
        return displayPrice(
          roundPrice(
            pricing.unitPrice * product.subscriptionDetails.numberOfMonths
          )
        );

      case "deliverable":
        return pricing.deliverable ? "כן" : "לא";

      default:
        return pricing[columnId];
    }
  };

  const renderPricing = () => {
    if (product.subscriptionDetails.singlePricing) {
      let monthlyDetails;
      if (["season", "camp"].indexOf(subscriptionType) > -1) {
        monthlyDetails = (
          <span>
            {"  "}(
            {displayPrice(product.subscriptionDetails.pricings[0].unitPrice)}{" "}
            &#215; {product.subscriptionDetails.numberOfMonths} חודשי פעילות)
          </span>
        );
      }
      return (
        <>
          <InfoRow
            title="מחיר"
            value={
              <span>
                {displayPrice(
                  roundPrice(
                    product.subscriptionDetails.pricings[0].unitPrice *
                      (product.subscriptionDetails.numberOfMonths || 1)
                  )
                )}
                {monthlyDetails}
              </span>
            }
            titleSx={styles.infoRowTitle}
          />

          <InfoRow
            title="מחיר כולל"
            value={
              <span>
                {displayPrice(
                  roundPrice(
                    product.subscriptionDetails.pricings[0].unitPrice *
                      (product.subscriptionDetails.numberOfMonths || 1) +
                      feesPrice
                  )
                )}
              </span>
            }
            titleSx={styles.infoRowTitle}
          />
        </>
      );
    }

    return (
      <>
        <InfoRow
          title="תמחורים"
          value=""
          titleSx={{
            ...styles.infoRowTitle,
            alignSelf: "flex-start",
          }}
        />
        <Table
          columns={columns}
          renderCell={renderPricingCell}
          rows={product.subscriptionDetails.pricings}
          noHeader
          sort={false}
        />
      </>
    );
  };

  const feesPrice = useMemo(
    () => roundPrice(_.sumBy(product.subProducts, "price")),
    [product]
  );

  return (
    <>
      <InfoRow
        title="דמי רישום"
        value={
          product.subProducts.length === 0
            ? "ללא דמי רישום"
            : displayPrice(feesPrice)
        }
      />
      {renderPricing()}
    </>
  );
}

const styles = {
  infoRowTitle: { width: 120 },
};
