import React from "react";
import { Alert, Box, List, ListItemText } from "@mui/material";

import FlexCenter from "../../components/FlexCenter";
import { classes as classesDic } from "../../utils/dictionaries";
import { displayPrice } from "../../utils/helpers";
import { useNavigate } from "react-router-dom";

export default function PlayersList(props) {
  const navigate = useNavigate();

  const renderPlayer = (player) => {
    const debt = player.subscriptions[0]?.debt || 0;
    const details = (
      <div>
        <div>{player.phone}</div>
        {classesDic[player.class]}
      </div>
    );
    return (
      <Box
        sx={styles.item}
        onClick={() => navigate(`../players/${player._id}`)}
        to={`../players/${player._id}`}
        key={player._id}
      >
        <Box sx={styles.itemPrimaryRow}>
          <ListItemText
            primary={player.name}
            secondary={details}
            secondaryTypographyProps={{ component: "span" }}
          />
          <div>
            <FlexCenter direction="column">
              {displayPrice(player.subscriptions[0]?.totalPrice || 0)}
            </FlexCenter>
          </div>
        </Box>
        {debt !== 0 && (
          <Alert severity="info" style={{ width: "100%" }}>
            יתרת חוב: {displayPrice(debt)}
          </Alert>
        )}
      </Box>
    );
  };
  return <List>{props.players.map((player) => renderPlayer(player))}</List>;
}

const styles = {
  item: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    flex: 1,
    padding: "7px",
    border: "1px solid #EEE",
    borderRadius: "5px",
    marginBottom: "5px",
    minHeight: 92,
  },
  itemPrimaryRow: {
    display: "flex",
    alignItems: "center",
    alignSelf: "stretch",
  },
  debt: {
    backgroundColor: "#64b5f6",
    color: "#FFF",
    borderRadius: "5px",
    padding: "5px",
    fontWeight: "bold",
    width: 150,
  },
};
