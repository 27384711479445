import React from "react";
import { Dialog, DialogActions, DialogContent, Grid } from "@mui/material";

import ToolbarFilters from "./ToolbarFilters";
import ButtonSx from "../base/ButtonSx";

export default function ToolBarFilterDialog(props) {
  return (
    <Dialog open={props.open} onClose={props.onClose} fullScreen dir="rtl">
      <DialogContent dividers>
        <Grid container spacing={2}>
          <ToolbarFilters {...props.filterProps} />
        </Grid>
      </DialogContent>
      <DialogActions>
        <ButtonSx
          fullWidth
          color="primary"
          variant="contained"
          onClick={props.onClose}
        >
          סגירה
        </ButtonSx>
      </DialogActions>
    </Dialog>
  );
}
