import React from "react";

import TlPaper from "./TlPaper";

export default function UnderlinePaper({ type, children }) {
  return (
    <TlPaper
      sx={{ ...styles.defaultPaperStyle, ...styles[type] }}
      bodySx={styles.paperBody}
      noMinHeight
    >
      {children}
    </TlPaper>
  );
}

const styles = {
  defaultPaperStyle: {
    borderBottomWidth: "3px",
    borderBottomStyle: "solid",
    height: "100%",
  },
  error: {
    borderBottomColor: "error.main",
  },
  success: {
    borderBottomColor: "success.main",
  },
  warning: {
    borderBottomColor: "warning.main",
  },
  info: {
    borderBottomColor: "info.main",
  },
  paperBody: {
    padding: 1,
    paddingLeft: 2,
    paddingRight: 2,
  },
};
