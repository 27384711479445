import { CircularProgress, Typography } from "@mui/material";
import React, { Component } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Link } from "@mui/material";

import EnhancedTable from "../../components/table/EnhancedTable";
import AbsoluteCenter from "../../components/AbsoluteCenter";
import axios from "../../utils/axios";
import CoachAttendanceChart from "./CoachAttendanceChart";

const columns = [
  { id: "name", label: "מאמן", width: 200 },
  { id: "rate", label: "אחוז מילוי", width: 150 },
  { id: "chart", label: "אירועים" },
];

export default class Coaches extends Component {
  state = { isLoading: true, coaches: null };

  componentDidMount = () => {
    if (this.props.visible) {
      this.loadData();
    }
  };

  //lazy load coaches
  componentDidUpdate = async (prevProps) => {
    if (
      !prevProps.visible &&
      this.props.visible &&
      this.state.coaches === null
    ) {
      this.loadData();
    }
  };

  loadData = async () => {
    this.setState({ isLoading: true });
    const res = await axios.get(
      "/attendances/detailedCoachesStats?level=coach"
    );
    this.setState({ isLoading: false, coaches: res.data });
  };

  sortValueCell = (columnId, record) => {
    switch (columnId) {
      case "rate":
        return (
          (record.hasAttendance /
            (record.hasAttendance + record.noAttendance)) *
          100
        );
      default:
        return record[columnId];
    }
  };

  rowKey = (record) => record.coach._id;

  renderCell = (columnId, record) => {
    const coach = record.coach;
    switch (columnId) {
      case "name":
        return (
          <Link
            component={RouterLink}
            color="primary"
            to={`../coaches/${coach._id}`}
            underline="hover"
          >
            {coach.name}
          </Link>
        );

      case "rate":
        return `${Math.round(
          (record.hasAttendance /
            (record.hasAttendance + record.noAttendance)) *
            100
        )}%`;

      case "chart":
        return <CoachAttendanceChart events={record.events} />;

      default:
        return record[columnId];
    }
  };

  render() {
    return this.state.isLoading ? (
      <AbsoluteCenter>
        <CircularProgress />
      </AbsoluteCenter>
    ) : this.state.coaches.length === 0 ? (
      <AbsoluteCenter>
        <Typography variant="h6">לא נמצאו נתונים</Typography>
      </AbsoluteCenter>
    ) : (
      <EnhancedTable
        size="small"
        headers={columns}
        renderCell={this.renderCell}
        sortValueCell={this.sortValueCell}
        defaultSortBy={1}
        defaultSortDirection="asc"
        rows={this.state.coaches}
        renderKey={this.rowKey}
      />
    );
  }
}
