import React from "react";

import InfoRow from "../../../components/InfoRows/InfoRow";

export default function ExistingParent(props) {
  const { parent } = props;
  return (
    <div>
      <InfoRow title="שם" value={`${parent.firstName} ${parent.lastName}`} />
      <InfoRow title="טלפון" value={parent.phone} />
      {parent.notIsraeli ? (
        <InfoRow title="מספר דרכון" value={parent.passportNumber || "-"} />
      ) : (
        <InfoRow title="תעודת זהות" value={parent.id || "-"} />
      )}
      <InfoRow
        title="כתובת"
        value={`${parent.street} ${parent.houseNumber} ${parent.city}`}
      />
      <InfoRow title="אימייל" value={`${parent.email}`} />
      {(parent.secondParentName || parent.secondParentPhone) && (
        <InfoRow
          title="הורה ב"
          value={`${parent.secondParentName}, ${parent.secondParentPhone}`}
        />
      )}
    </div>
  );
}
