import React from "react";
import ReactGA from "react-ga4";
import moment from "moment";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import { connect } from "react-redux";
import { Box } from "@mui/material";

import axios from "../../../utils/axios";
import SubscriptionsTable from "./SubscriptionsTable";
import {
  prepareFilters,
  getFiltersFromUrl,
} from "../../../components/filters/prepareFilters";
import FiltersBar from "../../../components/filters/FiltersBar";
import withRouter from "../../../components/routing/withRouter";
import SnackbarNg from "../../../components/base/SnackbarNg";
import TlPaper from "../../../components/TlPaper";
import ToolTip from "../../../components/base/ToolTip";
import { displaySeason } from "../../../utils/helpers";

class Debt extends React.Component {
  state = {
    subscriptions: [],
    isLoading: true,
    filters: {
      unbalanced: "all",
      season: "all",
    },
    snackbarDetails: { isOpen: false },
    seasonsDic: {},
  };

  componentDidMount = async () => {
    ReactGA.send({
      hitType: "pageview",
      page: "/reports/debt",
      title: "Debt Report",
    });

    this.filtersStructure = [
      {
        name: "unbalanced",
        type: "select",
        options: {
          debt: "חובה",
          surplus: "זכות",
        },
        emptyLabel: "זכות וחובה",
        label: "סוג יתרה",
        // required for the server (calling /subscription?debt=...)
        includeAll: true,
      },
      {
        preset: "season",
        type: "select",
        label: "עונה",
        name: "season",
        options: Object.fromEntries(
          this.props.seasons.map((s) => [s._id, displaySeason(s.year)])
        ),
        emptyLabel: "כל העונות",
        includeAll: false,
      },
    ];

    const filters = getFiltersFromUrl(
      this.props.location,
      this.state.filters,
      this.filtersStructure
    );

    const seasonsDic = Object.fromEntries(
      this.props.seasons.map((s) => [s._id, s.year])
    );

    await this.setState({ filters, seasonsDic });
    this.loadData(false, false);
  };

  loadData = async (silence, updateUrl = true) => {
    this.setState({ isLoading: !silence });
    let filters = prepareFilters(this.state.filters, this.filtersStructure);

    const response = await axios.get(
      `/store/subscriptions?granularity=clubPlayer&granularity=team&${filters.join(
        "&"
      )}`
    );

    this.setState({ subscriptions: response.data, isLoading: false });

    if (updateUrl) {
      filters = prepareFilters(this.state.filters, this.filtersStructure);
      this.props.navigate(`?${filters.join("&")}`, { replace: true });
    }
  };

  handleFilterChange = (filterName, value) => {
    this.setState({ filters: { ...this.state.filters, [filterName]: value } });
  };

  generateColumns = () => {
    return [
      { id: "player", label: "ספורטאי" },
      { id: "status", label: "סטטוס רישום" },
      { id: "totalPrice", label: "מחיר", alignment: "right" },
      { id: "paid", label: "שולם", alignment: "right" },
      { id: "debt", label: "יתרת חובה", alignment: "right" },
      { id: "debtExcessPayments", label: "יתרת זכות", alignment: "right" },
      { id: "team", label: "קבוצה" },
      { id: "season", label: "עונה" },
      ...(this.props.selectedClub.clubConfig.general.autoDebtAlert
        ? [
            {
              id: "autoDebtAlert",
              exportLabel: "התראת חוב למאמנים",
              label: (
                <span>
                  <ToolTip
                    title="במידה וישנה יתרת חוב (מעל 1 ש״ח) תופיע התראת חוב באפליקציית המאמנים"
                    arrow
                  >
                    <Box sx={styles.debtAlert}>
                      התראת חוב
                      <InfoIcon style={{ marginRight: 5 }} />
                    </Box>
                  </ToolTip>
                </span>
              ),
            },
          ]
        : []),
    ];
  };

  updateAutoDebtAlert = async (subscription) => {
    let success;
    try {
      await axios.patch(
        `/store/subscriptions/${subscription._id}/autoDebtAlert`,
        { autoDebtAlert: subscription.autoDebtAlert }
      );
      success = true;
    } catch (error) {
      success = false;
    }
    this.setState({
      snackbarDetails: {
        isOpen: true,
        key: moment().format(),
        success,
      },
    });
  };

  toggleAutoDebtAlert = (subscription) => () => {
    this.setState((prev) => {
      const subscriptions = [...prev.subscriptions];
      subscriptions.forEach((sub) => {
        if (sub._id === subscription._id) {
          sub.autoDebtAlert = sub.autoDebtAlert !== true;
          this.updateAutoDebtAlert(sub);
        }
      });
      return { subscriptions };
    });
  };

  render() {
    return (
      <TlPaper title="יתרות זכות וחובה" fullPage titleBackground>
        <FiltersBar
          filters={this.filtersStructure || []}
          onFilterChanged={this.handleFilterChange}
          values={this.state.filters}
          onSearch={this.loadData}
          isSearching={this.state.isLoading}
        />
        <SubscriptionsTable
          title="תוצאות"
          subscriptions={this.state.subscriptions}
          columns={this.generateColumns()}
          exportFileName="יתרות"
          onAutoDebtAlertChanged={this.toggleAutoDebtAlert}
          seasonsDic={this.state.seasonsDic}
        />
        <SnackbarNg
          snackKey={this.state.snackbarDetails.key}
          open={this.state.snackbarDetails.isOpen}
          onClose={() =>
            this.setState({ snackbarDetails: { isOpen: false, key: null } })
          }
          severity={this.state.snackbarDetails.success ? "success" : "error"}
          message={
            <div>
              {this.state.snackbarDetails.success
                ? "העדכון בוצע בהצלחה"
                : "אירעה שגיאה"}
            </div>
          }
        />
      </TlPaper>
    );
  }
}

const styles = {
  debtAlert: {
    display: "flex",
    alignItems: "center",
  },
};

function mapStateToProps(state) {
  return {
    selectedClub: state.auth.selectedClub,
    seasons: state.general.seasons,
  };
}

export default connect(mapStateToProps)(withRouter(Debt));
