import React, { useState } from "react";
import { Box, Fab, Tooltip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";

import InfoRow from "../../components/InfoRows/InfoRow";
import CoachDialog from "./CoachDialog";
import DeleteCoachDialog from "./DeleteCoachDialog";
import TlPaper from "../../components/TlPaper";

export default function CoachPersonalDetails(props) {
  const { coach } = props;
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const navigate = useNavigate();

  const handleEditCompleted = () => {
    setIsDialogOpen(false);
    props.onChange();
  };

  return (
    <TlPaper title="פרטי המאמן" isLoading={props.isLoading} titleBackground>
      {props.editable && props.hasHrCoachTeamAdminPermission && (
        <Box sx={{ position: "absolute", right: 10, bottom: 10 }}>
          <Tooltip title="עדכון פרטים אישיים">
            <Fab
              color="primary"
              aria-label="Add"
              size="small"
              onClick={() => setIsDialogOpen(true)}
              style={{ marginLeft: 10 }}
            >
              <EditIcon />
            </Fab>
          </Tooltip>
          <Tooltip
            title={
              coach.nonArchivedTeamsCount > 0
                ? "ניתן למחוק מאמן רק כשכל הקבוצות במצב ארכיון"
                : "מחיקה"
            }
          >
            <span>
              <Fab
                color="primary"
                size="small"
                onClick={() => setIsDeleteDialogOpen(true)}
                disabled={coach.nonArchivedTeamsCount > 0}
              >
                <DeleteIcon />
              </Fab>
            </span>
          </Tooltip>
          <CoachDialog
            key={`${coach._id}`}
            coach={coach}
            open={isDialogOpen}
            onClose={() => setIsDialogOpen(false)}
            onComplete={handleEditCompleted}
            clubCategory={props.clubCategory}
          />
          <DeleteCoachDialog
            coach={coach}
            open={isDeleteDialogOpen}
            onClose={() => setIsDeleteDialogOpen(false)}
            onDelete={props.onDelete}
            onComplete={() => navigate("../coaches", { replace: true })}
            status={props.deleteStatus}
          />
        </Box>
      )}
      <InfoRow title="שם" value={coach.name} />
      <InfoRow title="טלפון" value={coach.phone} />
      <InfoRow title="אימייל" value={coach.email || "-"} />
      <InfoRow title="הסמכה" value={coach.certification} />
      <InfoRow title="ת.ז" value={coach.id || "-"} />
      {/* <InfoRow title="אפליקציה" value={coach.active ? "מופעלת" : "לא מופעלת"} /> */}
    </TlPaper>
  );
}
