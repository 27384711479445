import React, { useState, useEffect } from "react";
import { DialogContent, DialogActions, Grid } from "@mui/material";

import Dialog from "../Dialog";
import PriceTextInput from "../PriceTextInput";
import { displayPrice } from "../../utils/helpers";
import ButtonSx from "../base/ButtonSx";

export default function CreditCardPaymentsAmountDialog(props) {
  const [price, setPrice] = useState("");
  const [priceError, setPriceError] = useState(false);
  const [instaValidate, setInstaValidate] = useState(false);

  useEffect(() => {
    if (props.open) {
      setPrice(props.price);
      setPriceError(false);
    }
  }, [props.open]);

  const validatePrice = (val) => {
    const curPrice = val === undefined ? price : val;
    const isValid = curPrice >= 100 && curPrice <= props.maxPrice * 100;

    setPriceError(!isValid);
    setInstaValidate(true);

    return isValid;
  };

  const handleCompleteClick = () => {
    if (validatePrice()) {
      props.onChange({ firstPaymentAmount: price });
      props.onClose();
    }
  };

  return (
    <Dialog
      onClose={props.onClose}
      open={props.open}
      title="עריכת סכום התשלום הראשון"
    >
      <DialogContent dividers>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <PriceTextInput
              label="מחיר"
              fullWidth
              InputProps={{ inputProps: { min: 0 } }}
              value={price / 100}
              onChange={(val) => {
                const value = val * 100;
                if (instaValidate) {
                  validatePrice(value);
                }
                setPrice(value);
              }}
              error={priceError}
              helperText={
                priceError
                  ? `יש להזין מחיר תקין ${displayPrice(1)} - ${displayPrice(
                      props.maxPrice
                    )}`
                  : null
              }
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <ButtonSx onClick={props.onClose}>ביטול</ButtonSx>
        <div>
          <ButtonSx
            disabled={priceError}
            onClick={handleCompleteClick}
            debounce
          >
            אישור
          </ButtonSx>
        </div>
      </DialogActions>
    </Dialog>
  );
}
