import React from "react";
import { Typography, CircularProgress, IconButton, Box } from "@mui/material";
import moment from "moment";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

import axios from "../../utils/axios";
import TeamDetailedAttendanceChart from "./TeamDetailedAttendanceChart";
import AbsoluteCenter from "../../components/AbsoluteCenter";

export default class TeamAttendance extends React.Component {
  state = {
    selectedDate: moment().startOf("day"),
    currentAttendace: {},
    isLoading: true,
    isDialogOpen: false,
    snackbar: {
      open: false,
      team: null,
    },
    month: moment().startOf("month"),
  };

  componentDidMount = async () => {
    await this.loadData();
  };

  componentDidUpdate = async (prevProps) => {
    if (prevProps.teamId !== this.props.teamId) {
      await this.loadData();
    }
  };

  loadData = async () => {
    this.setState({ isLoading: true });
    const from = this.state.month.format("YYYY-MM-DD");
    const endOfMonth = moment(this.state.month).endOf("month");
    const to = (moment().isBefore(endOfMonth) ? moment() : endOfMonth).format(
      "YYYY-MM-DD"
    );

    const res = await axios.get(
      `/teams/${this.props.teamId}/events?from=${from}&to=${to}&includeResourceDeleted=true`
    );

    this.setState({ isLoading: false, events: res.data });
  };

  prevMonth = async () => {
    await this.setState({ month: this.state.month.add(-1, "month") });
    this.loadData();
  };

  nextMonth = async () => {
    await this.setState({ month: this.state.month.add(1, "month") });
    this.loadData();
  };

  renderContent = () => {
    const nextMonthDisabled = moment(this.state.month)
      .add(1, "month")
      .isAfter(moment());

    return (
      <div>
        {this.state.isLoading && (
          <AbsoluteCenter>
            <CircularProgress />
          </AbsoluteCenter>
        )}
        <Box sx={styles.navigation}>
          <IconButton
            onClick={this.prevMonth}
            disabled={this.state.isLoading}
            size="large"
          >
            <NavigateNextIcon
              sx={{
                ...styles.navigationIcon,
                ...(this.state.isLoading ? {} : styles.black),
              }}
            />
          </IconButton>
          <Typography variant="h5">
            {this.state.month.format("MMM YYYY")}
          </Typography>
          <IconButton
            onClick={this.nextMonth}
            disabled={nextMonthDisabled || this.state.isLoading}
            size="large"
          >
            <NavigateBeforeIcon
              sx={{
                ...styles.navigationIcon,
                ...(!nextMonthDisabled && !this.state.isLoading
                  ? styles.black
                  : {}),
              }}
            />
          </IconButton>
        </Box>
        {this.state.events.length === 0 || this.props.players.length === 0 ? (
          <Box sx={styles.noData}>
            <AbsoluteCenter>
              <Typography variant="h6">לא קיימים אירועים לחודש זה</Typography>
            </AbsoluteCenter>
          </Box>
        ) : (
          <TeamDetailedAttendanceChart
            events={this.state.events}
            players={this.props.players}
          />
        )}
      </div>
    );
  };

  render() {
    return (
      <div>
        {!this.state.events || !this.props.players ? (
          <AbsoluteCenter>
            <CircularProgress />
          </AbsoluteCenter>
        ) : (
          this.renderContent()
        )}
      </div>
    );
  }
}

const styles = {
  navigationIcon: {
    fontSize: 30,
  },
  navigation: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  noData: {
    height: 200,
    alignSelf: "center",
    position: "relative",
  },
  black: {
    color: "#000000",
  },
};
