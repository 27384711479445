import React from "react";
import { connect } from "react-redux";
import { IconButton, Fab, Tooltip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import ReactGA from "react-ga4";
import _ from "lodash";
import moment from "moment";

import clubConfig from "../../../../config/clubs";
import axios from "../../../../utils/axios";
import {
  displayDate,
  displayPrice,
  displaySeason,
  roundPrice,
} from "../../../../utils/helpers";
import EditSubscriptionProductDialog from "./EditSubscriptionProductDialog";
import DeleteSubscriptionProductDialog from "./DeleteSubscriptionProductDialog";
import {
  getFiltersFromUrl,
  prepareFilters,
} from "../../../../components/filters/prepareFilters";
import {
  subscriptionProductsStatuses,
  subscriptionProductsTypes,
} from "../../../../utils/dictionaries";
import FiltersBar from "../../../../components/filters/FiltersBar";
import withRouter from "../../../../components/routing/withRouter";
import TlPaper from "../../../../components/TlPaper";
import Table from "../../../../components/table/Table";
import { exportTable } from "../../../../lib/tableHelpers";
import SnackbarNg from "../../../../components/base/SnackbarNg";

const baseColumns = [
  { id: "title", label: "שם" },
  { id: "subscriptionType", label: "סוג" },
  { id: "season", label: "עונה" },
  { id: "status", label: "סטטוס" },
  { id: "price", label: "מחיר" },
  { id: "fees", label: "דמי רישום" },
  { id: "startDate", label: "התחלה" },
  { id: "endDate", label: "סיום" },
  { id: "parentsPriceAutoUpdate", label: "ֿעדכון מחיר אוטומטי להורים" },
  { id: "actions", label: "פעולות", minWidth: 130 },
];

class Settings extends React.Component {
  accountingNumberColumn = {
    id: "accountingExportNumber",
    label: "מפתח חשבון",
  };

  state = {
    enrollments: [],
    editItem: null,
    deleteItem: null,
    addItem: false,
    columns: [...baseColumns],
    requireAccountingExportNumber: null,
    filters: {
      status: "active",
      season: "",
    },
    isLoading: false,
    snackbarKey: null,
  };

  componentDidMount = async () => {
    ReactGA.send({
      hitType: "pageview",
      page: "/settings/subscriptionProducts",
      title: "Subscription Products",
    });

    this.filtersStructure = [
      {
        name: "season",
        type: "select",
        options: _.orderBy(this.props.seasons, "year", "desc").reduce(
          (prev, curr) => {
            prev[curr._id] = curr.name;
            return prev;
          },
          {}
        ),
        emptyLabel: "כל העונות",
        label: "עונה",
        includeAll: true,
      },
      {
        name: "status",
        type: "select",
        options: subscriptionProductsStatuses,
        emptyLabel: "כל הסטטוסים",
        label: "סטטוס",
        includeAll: true,
      },
    ];

    const requireAccountingExportNumber = (
      clubConfig[this.props.selectedClub.internalName].features || {}
    ).requireAccountingExportNumber;

    const columns = [...baseColumns];
    if (requireAccountingExportNumber) {
      columns.splice(
        baseColumns.findIndex((c) => c.id === "status") + 1,
        0,
        this.accountingNumberColumn
      );
    }

    this.setState({
      requireAccountingExportNumber,
      columns,
    });

    const filters = getFiltersFromUrl(
      this.props.location,
      this.state.filters,
      this.filtersStructure
    );
    this.setState(
      {
        filters: {
          ...filters,
          season: filters.season || this.props.seasons.find((s) => s.main)?._id,
        },
      },
      () => this.loadData(null, false)
    );
  };

  loadData = async (newFilters, updateUrl = true) => {
    const baseFilters = newFilters || this.state.filters;
    this.setState({ isLoading: true });
    const res = await axios.get(
      `/store/products?category=subscription&granularity=subProducts&granularity=season&granularity=editable&${prepareFilters(
        baseFilters,
        this.filtersStructure,
        { includeAll: false }
      ).join("&")}`
    );
    this.setState({ enrollments: res.data, isLoading: false });

    if (updateUrl) {
      const filters = prepareFilters(baseFilters, this.filtersStructure);
      this.props.navigate(`?${filters.join("&")}`, { replace: true });
    }
  };

  handleFilterChange = (filterName, value) => {
    this.setState({
      filters: { ...this.state.filters, [filterName]: value },
    });
  };

  sortValueCell = (columnId, subscriptionProduct) => {
    switch (columnId) {
      case "actions":
        return 1;

      case "startDate":
        return subscriptionProduct.subscriptionDetails.startDate;

      case "endDate":
        return subscriptionProduct.subscriptionDetails.endDate;

      case "season":
        return subscriptionProduct.season.year;

      case "price":
        return subscriptionProduct.subscriptionDetails.singlePricing
          ? subscriptionProduct.subscriptionDetails.pricings[0].unitPrice
          : 0;

      case "fees":
        return _.sumBy(subscriptionProduct.subProducts, "price");

      default:
        return subscriptionProduct[columnId];
    }
  };

  renderCell = (columnId, subscriptionProduct) => {
    switch (columnId) {
      case "actions":
        return (
          <div>
            <IconButton
              onClick={() => this.setState({ editItem: subscriptionProduct })}
              size="large"
            >
              <EditIcon size="small" />
            </IconButton>
            <IconButton
              onClick={() => this.setState({ deleteItem: subscriptionProduct })}
              size="large"
            >
              <DeleteIcon size="small" />
            </IconButton>
          </div>
        );

      case "subscriptionType":
        return subscriptionProductsTypes[
          subscriptionProduct.subscriptionDetails.subscriptionType
        ];

      case "price":
        if (subscriptionProduct.subscriptionDetails.singlePricing) {
          return displayPrice(
            subscriptionProduct.subscriptionDetails.pricings[0].unitPrice
          );
        }
        return "משתנה";

      case "fees":
        return displayPrice(_.sumBy(subscriptionProduct.subProducts, "price"));

      case "startDate":
        return displayDate(subscriptionProduct.subscriptionDetails.startDate);

      case "endDate":
        return displayDate(subscriptionProduct.subscriptionDetails.endDate);

      case "parentsPriceAutoUpdate":
        return subscriptionProduct.parentsPriceAutoUpdate ? "כן" : "לא";

      case "status":
        if (subscriptionProduct.expired) {
          return subscriptionProductsStatuses.expired;
        } else if (subscriptionProduct.active) {
          return subscriptionProductsStatuses.active;
        } else {
          return subscriptionProductsStatuses.inactive;
        }

      case "season":
        return displaySeason(subscriptionProduct.season.year);

      default:
        return subscriptionProduct[columnId];
    }
  };

  handleClose = () => {
    this.setState({ deleteItem: null, editItem: null, addItem: false });
  };

  handleCompleted = () => {
    this.setState({
      deleteItem: null,
      editItem: null,
      addItem: false,
      snackbarKey: moment().format(),
    });
    this.loadData();
  };

  renderCellExport = (columnId, subscriptionProduct) => {
    const { subscriptionDetails } = subscriptionProduct;
    switch (columnId) {
      case "pricing":
        return subscriptionDetails.singlePricing
          ? "מחיר אחיד"
          : subscriptionProduct.pricing.title;

      case "price":
        return (
          subscriptionProduct.pricing.unitPrice *
          (subscriptionDetails.numberOfMonths || 1)
        );

      case "monthlyPrice":
        return ["season", "camp"].indexOf(
          subscriptionDetails.subscriptionType
        ) > -1
          ? subscriptionProduct.pricing.unitPrice
          : "";

      case "totalPrice":
        return roundPrice(
          subscriptionProduct.pricing.unitPrice *
            (subscriptionDetails.numberOfMonths || 1) +
            _.sumBy(subscriptionProduct.subProducts, (s) => s.price)
        );

      case "feesPrice":
        return _.sumBy(subscriptionProduct.subProducts, (s) => s.price);

      case "pricingQuantity":
        if (subscriptionDetails.singlePricing) {
          return "";
        }
        if (subscriptionDetails.subscriptionType === "season") {
          return `${subscriptionProduct.pricing.quantity} פעמים בשבוע`;
        }

        if (subscriptionDetails.subscriptionType === "daily") {
          return `${subscriptionProduct.pricing.quantity} ימים`;
        }
        return "";

      case "fee1Description":
        return subscriptionProduct.subProducts[0]?.title;

      case "fee1Price":
        return subscriptionProduct.subProducts[0]?.price;

      case "fee1AccountingExportNumber":
        return subscriptionProduct.subProducts[0]?.accountingExportNumber;

      case "fee2Description":
        return subscriptionProduct.subProducts[1]?.title;

      case "fee2Price":
        return subscriptionProduct.subProducts[1]?.price;

      case "fee2AccountingExportNumber":
        return subscriptionProduct.subProducts[1]?.accountingExportNumber;

      default:
        return this.renderCell(columnId, subscriptionProduct);
    }
  };

  handleExportTable = () => {
    const columns = [
      { id: "title", label: "שם" },
      { id: "season", label: "עונה" },
      { id: "pricing", label: "תמחור" },
      { id: "pricingQuantity", label: "פרטי תמחור" },
      { id: "status", label: "סטטוס" },
      ...(this.state.requireAccountingExportNumber
        ? [{ id: "accountingExportNumber", label: "מפתח חשבון" }]
        : []),
      { id: "startDate", label: "התחלה" },
      { id: "endDate", label: "סיום" },
      { id: "parentsPriceAutoUpdate", label: "ֿעדכון מחיר אוטומטי להורים" },
      { id: "monthlyPrice", label: "מחיר חודשי" },
      { id: "price", label: "מחיר כלל הפעילות ללא דמי רישום" },
      { id: "feesPrice", label: "מחיר דמי רישום" },
      { id: "totalPrice", label: "מחיר כולל" },
      { id: "fee1Description", label: "דמי רישום 1" },
      { id: "fee1Price", label: "דמי רישום 1 - מחיר " },
      ...(this.state.requireAccountingExportNumber
        ? [
            {
              id: "fee1AccountingExportNumber",
              label: "דמי רישום 1 - מפתח חשבון",
            },
          ]
        : []),
      { id: "fee2Description", label: "דמי רישום 2" },
      { id: "fee2Price", label: "דמי רישום 2 - מחיר" },
      ...(this.state.requireAccountingExportNumber
        ? [
            {
              id: "fee2AccountingExportNumber",
              label: "דמי רישום 2 - מפתח חשבון",
            },
          ]
        : []),
    ];

    const subscriptionProducts = [];
    this.state.enrollments.forEach((e) => {
      e.subscriptionDetails.pricings.forEach((pricing) => {
        subscriptionProducts.push({
          ...e,
          pricing,
        });
      });
    });

    exportTable(
      subscriptionProducts,
      columns,
      this.renderCellExport,
      "csv",
      "מסגרות"
    );
  };

  render() {
    const addItem = (
      <Tooltip title="הוספת מסגרת">
        <Fab
          color="primary"
          aria-label="Add"
          size="small"
          onClick={() => this.setState({ addItem: true })}
        >
          <AddIcon />
        </Fab>
      </Tooltip>
    );

    return (
      <TlPaper title="מסגרות" leftItem={addItem} titleBackground>
        <FiltersBar
          filters={this.filtersStructure || []}
          onFilterChanged={this.handleFilterChange}
          values={this.state.filters}
          onSearch={this.loadData}
          isSearching={this.state.isLoading}
        />
        <Table
          columns={this.state.columns}
          renderCell={this.renderCell}
          rows={this.state.enrollments}
          sortValueCell={this.sortValueCell}
          title="מסגרות"
          onExport={this.handleExportTable}
        />
        <EditSubscriptionProductDialog
          open={this.state.editItem !== null || this.state.addItem}
          onClose={this.handleClose}
          subscriptionProduct={this.state.editItem}
          onComplete={this.handleCompleted}
          requireAccountingExportNumber={
            this.state.requireAccountingExportNumber
          }
        />
        <DeleteSubscriptionProductDialog
          open={this.state.deleteItem !== null}
          onClose={this.handleClose}
          subscriptionProduct={this.state.deleteItem}
          onComplete={this.handleCompleted}
        />
        <SnackbarNg
          snackKey={this.state.snackbarKey}
          open={this.state.snackbarKey !== null}
          onClose={() => this.setState({ snackbarKey: null })}
          severity="success"
          message="הפעולה בוצעה בהצלחה"
        />
      </TlPaper>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedClub: state.auth.selectedClub,
    seasons: state.general.seasons,
  };
}

export default connect(mapStateToProps)(withRouter(Settings));
