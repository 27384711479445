import React from "react";
import { Grid, Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import ReactGA from "react-ga4";

import axios from "../../utils/axios";
import FiltersBar from "../../components/filters/FiltersBar";
import {
  prepareFilters,
  getFiltersFromUrl,
} from "../../components/filters/prepareFilters";
import Table from "../../components/table/Table";
import { exportTable } from "../../lib/tableHelpers";
import withRouter from "../../components/routing/withRouter";
import TlPaper from "../../components/TlPaper";

const filtersStructure = [
  {
    name: "name",
    type: "text",
    label: "שם",
  },
  {
    name: "phone",
    type: "text",
    label: "טלפון",
  },
  {
    name: "email",
    type: "text",
    label: "אימייל",
  },
];

const columns = [
  { id: "name", label: "שם ההורה" },
  { id: "phone", label: "טלפון", width: "80" },
  { id: "email", label: "אימייל" },
];

class Parents extends React.Component {
  state = {
    parents: null,
    isLoading: false,
    error: false,
    filters: {
      name: "",
      phone: "",
      email: "",
    },
  };

  componentDidMount = async () => {
    ReactGA.send({
      hitType: "pageview",
      page: "/parents",
      title: "Parents",
    });

    const filters = getFiltersFromUrl(
      this.props.location,
      this.state.filters,
      filtersStructure
    );
    await this.setState({ filters });
    this.loadData(false, false);
  };

  loadData = async (updateUrl, instaValidate = true) => {
    const filters = prepareFilters(this.state.filters, filtersStructure);
    if (filters.length === 0) {
      if (instaValidate) {
        this.setState({ error: true, instaValidate: true, parents: null });
      }
    } else {
      this.setState({ isLoading: true });

      let response = await axios.get(`/clubParents?${filters.join("&")}`);
      this.setState({
        isLoading: false,
        parents: response.data,
      });
    }
    if (updateUrl) {
      this.props.navigate(`?${filters.join("&")}`, { replace: true });
    }
  };

  renderCell = (columnId, parent) => {
    switch (columnId) {
      case "name":
        return (
          <Link
            component={RouterLink}
            color="primary"
            to={`../accounts/${parent._id}`}
            underline="hover"
          >
            {parent.name}
          </Link>
        );

      default:
        return parent[columnId];
    }
  };

  renderCellExport = (columnId, parent) => {
    switch (columnId) {
      case "name":
        return parent.name;

      default:
        return this.renderCell(columnId, parent);
    }
  };

  handleExportTable = () => {
    exportTable(
      this.state.parents,
      columns,
      this.renderCellExport,
      "csv",
      "הורים"
    );
  };

  handleFilterChange = (filterName, value) => {
    this.setState((prevState) => {
      const filters = { ...prevState.filters, [filterName]: value };

      return {
        filters,
        error:
          prevState.instaValidate &&
          filters.name === "" &&
          filters.email === "" &&
          filters.phone === "",
      };
    });
  };

  render() {
    return (
      <TlPaper title="הורים" titleBackground>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FiltersBar
              filters={filtersStructure}
              onFilterChanged={this.handleFilterChange}
              values={this.state.filters}
              onSearch={() => this.loadData(true, true)}
              isSearching={this.state.isLoading}
            />
            {this.state.error && "*יש להזין שדה חיפוש אחד לפחות"}
          </Grid>
          <Grid item xs={12}>
            {this.state.parents &&
              (this.state.parents.length > 0 ? (
                <Table
                  title="הורים"
                  columns={columns}
                  renderCell={this.renderCell}
                  sortValueCell={this.renderCellExport}
                  rows={this.state.parents}
                  onExport={this.handleExportTable}
                  pagination
                />
              ) : (
                "לא נמצאו תוצאות"
              ))}
          </Grid>
        </Grid>
      </TlPaper>
    );
  }
}

export default withRouter(Parents);
