import React, { useState } from "react";
import { Box, Grid, useMediaQuery } from "@mui/material";

import ProductsPurchase from "../../components/store/ProductsPurchase";
import OneTimeProductDialog from "../../components/store/OneTimeProductDialog";
import StepTitle from "./StepTitle";
import ButtonSx from "../../components/base/ButtonSx";

export default function MerchandiseForm(props) {
  const [OTPDialogOpen, setOTPDialogOpen] = useState(false);
  const [currentOPT, setCurrentOPT] = useState(null);
  const isSmUp = useMediaQuery((theme) => theme.breakpoints.up("sm"), {
    noSsr: true,
  });

  const handleUpdateOTP = (otp) => {
    setOTPDialogOpen(false);
    setCurrentOPT(null);
    props.onUpdateOTP(otp);
  };

  const handleStartEditingOTP = (otp) => {
    setCurrentOPT(otp);
    setOTPDialogOpen(true);
  };

  const handleCloseOTP = () => {
    setCurrentOPT(null);
    setOTPDialogOpen(false);
  };

  return (
    <>
      <StepTitle title="מוצרים נלווים" purchaseType={props.purchaseType} />
      {isSmUp ? (
        <Box sx={styles.header}>
          {props.OTP && (
            <ButtonSx sx={styles.addOTP} onClick={() => setOTPDialogOpen(true)}>
              הוספת פריט חד פעמי
            </ButtonSx>
          )}
        </Box>
      ) : (
        props.OTP && (
          <Box sx={styles.mobileAddOtpContainer}>
            <ButtonSx
              onClick={() => setOTPDialogOpen(true)}
              sx={styles.addOTPMobile}
              fullWidth
            >
              הוספת פריט חד פעמי
            </ButtonSx>
          </Box>
        )
      )}
      {props.OTP && (
        <OneTimeProductDialog
          open={OTPDialogOpen}
          onClose={handleCloseOTP}
          onUpdateProduct={handleUpdateOTP}
          product={currentOPT}
        />
      )}
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {props.state.errors.merchandise && (
            <div style={{ color: "red" }}>*יש להוסיף פריטים</div>
          )}
          <ProductsPurchase
            cart={props.cart}
            merchandise={props.merchandise}
            onProductChange={props.onProductChange}
            onDeleteOTP={props.onDeleteOTP}
            onStartEditingOTP={handleStartEditingOTP}
            players={props.players}
          />
        </Grid>
      </Grid>
    </>
  );
}

const styles = {
  header: {
    position: "relative",
  },
  addOTP: {
    position: "absolute",
    right: 0,
    bottom: 5,
    width: null,
  },
  addOTPMobile: {
    marginBottom: "10px",
    width: null,
    mx: 3,
  },
  mobileAddOtpContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};
