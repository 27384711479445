import React, { useMemo } from "react";
import { List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import _ from "lodash";

import { subscriptionsStatuses } from "../../../utils/dictionaries";
import { displayDate, displayPrice } from "../../../utils/helpers";
import InfoRow from "../../InfoRows/InfoRow";

const styles = {
  infoRowTitle: {
    width: 120,
  },
};

export default function SubscriptionsList(props) {
  const subscriptions = useMemo(
    () =>
      _.sortBy(props.subscriptions || [], [
        (s) => (s.status === "active" ? 0 : 1),
        (s) => -new Date(s.startDate).getTime(),
      ]),
    [props.subscriptions]
  );

  const renderSubscription = (subscription) => {
    let status;
    if (subscription.status === "cancelled") {
      status = `${subscriptionsStatuses[subscription.status]}${
        subscription.subStatus === "teamChange" ? " / מעבר קבוצה" : ""
      }`;
    } else {
      status = subscription.trial
        ? "ניסיון"
        : subscriptionsStatuses[subscription.status];
    }

    const description = (
      <div>
        <div>
          <InfoRow title="סטטוס" value={status} titleSx={styles.infoRowTitle} />
          <InfoRow
            title="תחילת פעילות"
            value={displayDate(subscription.startDate)}
            titleSx={styles.infoRowTitle}
          />
          <InfoRow
            title="עלות"
            value={
              subscription.type === "direct"
                ? displayPrice(subscription.totalPrice)
                : "-"
            }
            titleSx={styles.infoRowTitle}
          />

          <InfoRow
            title="שולם"
            value={
              subscription.type === "direct"
                ? displayPrice(subscription.paid)
                : "-"
            }
            titleSx={styles.infoRowTitle}
          />
        </div>
      </div>
    );

    return (
      <ListItem
        key={subscription._id}
        sx={{ border: "1px solid #EEE", borderRadius: "5px", mb: "5px" }}
      >
        <ListItemButton onClick={() => props.onItemClicked(subscription)}>
          <ListItemText
            primary={subscription.team?.name || subscription.teamName}
            secondary={description}
            secondaryTypographyProps={{ component: "span" }}
          />
        </ListItemButton>
      </ListItem>
    );
  };

  return <List>{subscriptions.map((sub) => renderSubscription(sub))}</List>;
}
