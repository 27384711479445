import React from "react";
import { TextField } from "@mui/material";

import ResponsiveContainer from "../../../components/ResponsiveContainer";
import EventTypeSelectDesktop from "./EventTypeSelectDesktop";
import MenuItem from "../../../components/base/MenuItem";

export default function EventTypeSelect({ event, readOnly, onFieldUpdated }) {
  let value = event.type;

  if (event.type === "game") {
    if (event.gameDetails?.gameType === "home") {
      value = "homeGame";
    } else {
      value = "awayGame";
    }
  }

  return (
    <ResponsiveContainer
      desktopContent={
        <EventTypeSelectDesktop
          disabled={event.recurringEventId || readOnly}
          eventType={event.type}
          gameType={event.gameDetails?.gameType}
          onEventTypeChange={(newValue) => onFieldUpdated("type", newValue)}
          onGameTypeChange={(newValue) => {
            onFieldUpdated("gameDetails", {
              ...event.gameDetails,
              gameType: newValue,
            });
            onFieldUpdated("isRecurringEvent", false);
          }}
        />
      }
      mobileContent={
        <TextField
          select
          variant="standard"
          label="סוג אירוע"
          value={value}
          onChange={(uiEvent) => {
            const { value } = uiEvent.target;
            if (value === "homeGame" || value === "awayGame") {
              onFieldUpdated("type", "game");
              onFieldUpdated("gameDetails", {
                ...event.gameDetails,
                gameType: value === "homeGame" ? "home" : "away",
              });
              onFieldUpdated("isRecurringEvent", false);
            } else {
              onFieldUpdated("type", value);
            }
          }}
          fullWidth
          inputProps={{ readOnly }}
          margin="dense"
        >
          <MenuItem value="practice">אימון</MenuItem>
          <MenuItem value="homeGame">משחק בית</MenuItem>
          <MenuItem value="awayGame">משחק חוץ</MenuItem>
          <MenuItem value="other">אחר</MenuItem>
        </TextField>
      }
    />
  );
}
