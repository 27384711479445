import React, { Component } from "react";
import { CircularProgress, List } from "@mui/material";
import _ from "lodash";
import moment from "moment";

import axios from "../../../utils/axios";
import AbsoluteCenter from "../../../components/AbsoluteCenter";
import SnackbarNg from "../../../components/base/SnackbarNg";
import { getAxiosErrorData } from "../../../utils/helpers";
import TeamLevel from "./TeamLevel";
import ButtonSx from "../../../components/base/ButtonSx";

export default class TeamLevels extends Component {
  state = {
    isLoading: false,
    isSaving: false,
    teamLevels: null,
    editingItemIndex: -1,
    editingSubItemIndex: -1,
    editingItem: null,
    snack: { open: false },
    error: null,
  };

  componentDidMount = async () => {
    this.loadTeamLevels(false);
  };

  loadTeamLevels = async (silence = true) => {
    this.setState({ isLoading: !silence });
    const res = await axios.get(`/settings/teamLevels`);
    const teamLevels = _.sortBy(res.data, "name");
    this.setState({ isLoading: false, teamLevels });
  };

  handleAddTeamLevel = (index, parent) => () => {
    const newLevel = { name: "", subLevels: [] };
    this.setState((prevState) => {
      let teamLevels = [...prevState.teamLevels];
      if (parent) {
        newLevel.parent = parent;
        teamLevels
          .find((tl) => tl._id === parent._id)
          .subLevels.splice(0, 0, newLevel);
      } else {
        teamLevels.splice(0, 0, newLevel);
      }
      return {
        teamLevels,
        editingItemIndex: index,
        editingSubItemIndex: parent ? 0 : -1,
        editingItem: newLevel,
      };
    });
  };

  renderContent = () => (
    <div>
      <ButtonSx
        fullWidth
        onClick={this.handleAddTeamLevel(0)}
        disabled={this.state.editingItemIndex > -1}
      >
        הוספת קטגוריה חדשה
      </ButtonSx>
      {this.renderTeamLevelList()}
    </div>
  );

  handleSetEditing =
    (teamLevel, index, subLevel, subLevelIndex = -1) =>
    () => {
      this.handleCancelEditing();
      this.setState({
        editingItemIndex: index,
        editingSubItemIndex: subLevelIndex,
        editingItem: subLevel
          ? { ...subLevel, parent: teamLevel }
          : { ...teamLevel },
      });
    };

  handleCancelEditing = () => {
    this.setState((prevState) => {
      let teamLevels = [...prevState.teamLevels];
      //editing new item => remove it
      if (this.state.editingItem && !this.state.editingItem._id) {
        if (this.state.editingItem.parent) {
          teamLevels[this.state.editingItemIndex].subLevels.splice(
            this.state.editingSubItemIndex,
            1
          );
        } else {
          teamLevels.splice(this.state.editingItemIndex, 1);
        }
      }
      return {
        editingItemIndex: -1,
        editingSubItemIndex: -1,
        editingItem: null,
        teamLevels,
        error: null,
      };
    });
  };

  handleEditingCompleted = async () => {
    if (this.state.editingItem.name.length < 2) {
      this.setState({ error: "שדה לא תקין" });
      return;
    }

    this.setState({ error: null });
    const success = await this.saveTeamLevel(this.state.editingItem);
    if (success) {
      this.handleCancelEditing();
      this.loadTeamLevels();
    }
  };

  handleTeamLevelNameChanged = (e) => {
    const name = e.target.value;
    this.setState((prevState) => ({
      editingItem: { ...prevState.editingItem, name },
    }));
  };

  handleDelete = (teamLevel, subTeamLevel) => async () => {
    try {
      if (subTeamLevel) {
        await axios.delete(
          `/settings/teamLevels/${teamLevel._id}/subLevels/${subTeamLevel._id}`
        );
      } else {
        await axios.delete(`/settings/teamLevels/${teamLevel._id}`);
      }
      this.showSnack("success", `${teamLevel._id}${(subTeamLevel || {})._id}`);
      this.loadTeamLevels();
    } catch (error) {
      // already deleted (from another page)
      if (error.response && error.response.status === 404) {
        this.loadTeamLevels();
        return;
      }
      const errorData = getAxiosErrorData(error);
      let message;
      if (((errorData || {}).errors || {}).hasTeams) {
        message = `לא ניתן למחוק ${
          subTeamLevel ? " תת" : ""
        } קטגוריה אליה משוייכות קבוצות`;
      } else {
        message = "אירעה שגיאה";
      }

      this.setState({ isSaving: false });
      this.showSnack(
        "error",
        `${teamLevel._id}${(subTeamLevel || {})._id}`,
        message
      );
      return false;
    }
  };

  saveTeamLevel = async (teamLevel) => {
    try {
      this.setState({ isSaving: true });
      if (teamLevel.parent) {
        if (teamLevel._id) {
          await axios.patch(
            `/settings/teamLevels/${teamLevel.parent._id}/subLevels/${teamLevel._id}`,
            { name: teamLevel.name }
          );
        } else {
          await axios.post(
            `/settings/teamLevels/${teamLevel.parent._id}/subLevels`,
            {
              name: teamLevel.name,
            }
          );
        }
      } else {
        if (teamLevel._id) {
          await axios.patch(`/settings/teamLevels/${teamLevel._id}`, {
            name: teamLevel.name,
          });
        } else {
          await axios.post(`/settings/teamLevels/`, {
            name: teamLevel.name,
          });
        }
      }
      this.showSnack("success", teamLevel._id || moment().format());
      this.setState({ isSaving: false });
      return true;
    } catch (error) {
      const errorData = getAxiosErrorData(error);
      if (((errorData || {}).errors || {}).name === "unique") {
        this.setState({ error: "קיימת קטגוריה עם שם זהה", isSaving: false });
      } else if (((errorData || {}).errors || {}).subLevels === "unique") {
        this.setState({ error: "קיימת תת קטגוריה עם שם זהה", isSaving: false });
      } else if (((errorData || {}).errors || {}).teamsWithoutSubLevel) {
        this.setState({
          error: "לא ניתן להוסיף תת קטגוריה לקטגוריה אליה משוייכות קבוצות",
          isSaving: false,
        });
      } else {
        this.setState({ error: "אירעה שגיאה", isSaving: false });
      }
      return false;
    }
  };

  showSnack = (status, key, message = "הפעולה בוצעה בהצלחה") => {
    this.setState({ snack: { open: true, status, message, key } });
  };

  renderTeamLevelList = () => (
    <List>
      {this.state.teamLevels.map((teamLevel, index) => {
        const editingParent =
          index === this.state.editingItemIndex &&
          this.state.editingSubItemIndex === -1;

        const parent = (
          <TeamLevel
            key={teamLevel._id || "new"}
            teamLevel={editingParent ? this.state.editingItem : teamLevel}
            onStartEditing={this.handleSetEditing(teamLevel, index)}
            onCancelEditing={this.handleCancelEditing}
            onCompleteEditing={this.handleEditingCompleted}
            isEditing={editingParent}
            isSaving={this.state.isSaving && editingParent}
            onNameChange={this.handleTeamLevelNameChanged}
            onAddNewSubLevel={this.handleAddTeamLevel(index, teamLevel)}
            disabled={this.state.editingItemIndex > -1 && !editingParent}
            error={editingParent ? this.state.error : null}
            onDelete={this.handleDelete(teamLevel)}
          />
        );

        const children = teamLevel.subLevels.map((sl, slIndex) => {
          const editingChild =
            index === this.state.editingItemIndex &&
            this.state.editingSubItemIndex === slIndex;
          return (
            <TeamLevel
              key={sl._id || "new"}
              parent={teamLevel}
              teamLevel={editingChild ? this.state.editingItem : sl}
              onStartEditing={this.handleSetEditing(
                teamLevel,
                index,
                sl,
                slIndex
              )}
              onCancelEditing={this.handleCancelEditing}
              onCompleteEditing={this.handleEditingCompleted}
              isEditing={editingChild}
              isSaving={this.state.isSaving && editingChild}
              onNameChange={this.handleTeamLevelNameChanged}
              disabled={this.state.editingItemIndex > -1 && !editingChild}
              error={editingChild ? this.state.error : null}
              onDelete={this.handleDelete(teamLevel, sl)}
            />
          );
        });

        return [parent, ...children];
      })}
    </List>
  );

  render() {
    return (
      <div>
        {this.state.isLoading || !this.state.teamLevels ? (
          <AbsoluteCenter>
            <CircularProgress />
          </AbsoluteCenter>
        ) : (
          this.renderContent()
        )}
        <SnackbarNg
          snackKey={this.state.snack.key}
          open={this.state.snack.open}
          onClose={() => {
            this.setState({ snack: { open: false } });
          }}
          severity={this.state.snack.status}
          message={this.state.snack.message}
        />
      </div>
    );
  }
}
