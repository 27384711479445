import React from "react";
import { DialogContent, DialogActions, Typography } from "@mui/material";

import InfoRow from "../../../components/InfoRows/InfoRow";
import { classes, gender } from "../../../utils/dictionaries";
import ButtonSx from "../../../components/base/ButtonSx";
import Dialog from "../../../components/Dialog";

export default function ExistingPlayerFoundDialog(props) {
  const player = props.player || {};
  const title = player.notIsraeli
    ? `ספורטאי עם מספר דרכון ${player.passportNumber} קיים במועדון`
    : `ספורטאי עם תעודת הזהות ${props.id} קיים במועדון`;
  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      maxWidth="sm"
      fullWidth
      title={title}
    >
      <DialogContent dividers>
        <Typography gutterBottom>
          <b>האם זה הספורטאי שחיפשתם?</b>
        </Typography>
        <InfoRow title="שם" value={`${player.firstName} ${player.lastName}`} />
        {player.notIsraeli ? (
          <InfoRow title="מספר דרכון" value={player.passportNumber} />
        ) : (
          <InfoRow title="ת.ז" value={player.id} />
        )}
        <InfoRow title="טלפון" value={player.phone || "-"} />
        <InfoRow title="כיתה" value={classes[player.class]} />
        <InfoRow title="מין" value={gender[player.gender]} />
      </DialogContent>
      <DialogActions>
        <ButtonSx onClick={() => props.onClose(false)}>ביטול</ButtonSx>
        <ButtonSx onClick={() => props.onClose(true)}>המשך</ButtonSx>
      </DialogActions>
    </Dialog>
  );
}
