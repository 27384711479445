import React from "react";
import {
  DialogActions,
  DialogContent,
  CircularProgress,
  Grid,
} from "@mui/material";
import _ from "lodash";

import axios from "../../../utils/axios";
import RefundPaymentMethod from "../Refund/RefundPaymentMethod";
import Dialog from "../../Dialog";
import { roundPrice } from "../../../utils/helpers";
import RefundProductForReturnChecks from "./RefundProductForReturnChecks";
import withIsXs from "../../routing/withIsXs";
import ButtonSx from "../../base/ButtonSx";

const initState = () => ({
  errors: {},
  processing: true,
  success: null,
  productsForm: {
    refundedItems: [],
    errors: {},
    selectedItemsAmount: 0,
  },
  refundAmount: 0,
  paymentMethodForm: {
    errors: {},
    cancelledChecks: [],
    totalCancelledChecksAmount: 0,
    type: "check",
  },
  transaction: null,
});

class ReturnChecksDialog extends React.Component {
  state = initState();

  componentDidUpdate = async (prevProps) => {
    if (this.props.open) {
      if (
        this.props.transactionId &&
        prevProps.transactionId !== this.props.transactionId
      ) {
        this.loadData();
      }
    } else if (prevProps.open) {
      // was open before. now closed
      this.setState(initState());
    }
  };

  loadData = async () => {
    await this.setState({ processing: true });
    let res;
    let transaction = this.props.transaction;
    if (!transaction && this.props.transactionId) {
      res = await axios.get(
        `/payments/transactions/${this.props.transactionId}`
      );
      transaction = res.data;
    }
    res = await axios.get(`/store/purchases/${transaction.purchaseId}`);
    await this.setState(
      { purchase: res.data, processing: false, transaction },
      () => {
        this.resetProductsForm();
      }
    );
  };

  resetProductsForm = () => {
    const refundedItems = this.state.purchase.products
      .filter((p) => !p.reallocatedAt)
      .map((p) => ({
        checked: false,
        itemMaxRefundAmount: roundPrice(p.price + p.refundedAmount),
        maxRefundAmount: 0,
        refundAmount: 0,
        tempAmount: 0,
        editing: false,
        purchasedItem: p,
      }));

    return this.setState((prevState) => {
      return {
        productsForm: { ...prevState.productsForm, refundedItems },
        processing: false,
      };
    });
  };

  refund = async () => {
    const amount = -this.state.refundAmount;
    this.setState({ processing: true });
    try {
      const data = {
        items: this.state.productsForm.refundedItems
          .filter((item) => item.checked)
          .map((item) => ({
            itemId: item.purchasedItem._id,
            amount: item.refundAmount,
          })),
        payment: {
          comment:
            this.state.paymentMethodForm.cancelledChecks.length === 1
              ? "צ׳ק חוזר"
              : "צ׳קים חוזרים",
          amount,
          paymentMethod: {
            amount: 0,
            type: "check",
            cancelledChecks: this.state.paymentMethodForm.cancelledChecks.map(
              (c) => c._id
            ),
          },
        },
        allowMerchandisePartialRefund: this.props.allowMerchandisePartialRefund,
      };

      await axios.post(
        `/store/purchases/${this.state.purchase._id}/refund`,
        data
      );

      this.setState({ processing: false, success: true });
    } catch (error) {
      this.setState({ processing: false, success: false });
    }
  };

  validateProducts = async () => {
    let valid = true;
    const errors = {};
    const products = this.state.productsForm;

    if (this.state.refundAmount !== products.selectedItemsAmount) {
      errors.amountMismatch =
        "סכום הפריטים שנבחרו אינו תואם את סכום הצ׳קים החוזרים";
      valid = false;
    }

    products.refundedItems.forEach((item) => {
      if (item.editing) {
        item.error = "יש לסיים עריכת שדה זה";
        errors.editingItems = true; //useless - only for consistency
        valid = false;
      }
    });

    await this.handleProductsFormChange({ errors });
    return valid;
  };

  validatePaymentMethod = async () => {
    const isValid =
      this.state.paymentMethodForm.totalCancelledChecksAmount !== 0;
    this.setState((prevState) => ({
      paymentMethodForm: {
        ...prevState.paymentMethodForm,
        errors: isValid ? {} : { cancelledChecks: "יש לבחור צ׳קים להחזר" },
      },
    }));

    return isValid;
  };

  validate = async () => {
    let validPaymentMethod = await this.validatePaymentMethod();
    let validProdutcs = await this.validateProducts();

    return validProdutcs && validPaymentMethod;
  };

  handleSubmit = async () => {
    const valid = await this.validate();
    if (valid) {
      if (this.state.success === null) {
        this.refund();
      } else {
        this.props.onComplete();
      }
    }
  };

  renderContent = () => {
    if (this.state.processing) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </div>
      );
    } else if (this.state.success !== null) {
      return this.state.success ? (
        <div>הפעולה בוצעה בהצלחה</div>
      ) : (
        "הפעולה נכשלה"
      );
    } else {
      return this.renderRefundForm();
    }
  };

  handleProductsFormChange = (updates) => {
    this.setState((prevState) => {
      return {
        productsForm: {
          ...prevState.productsForm,
          ...updates,
        },
      };
    });
  };

  handleCancelledCheckChanged = async (check, checked) => {
    await this.setState((prevState) => {
      const cancelledChecks = checked
        ? [...prevState.paymentMethodForm.cancelledChecks, check]
        : prevState.paymentMethodForm.cancelledChecks.filter(
            (c) => c._id !== check._id
          );

      const totalAmount = roundPrice(
        _.sumBy(
          prevState.transaction.checks.filter(
            (c) => cancelledChecks.indexOf(c) > -1
          ),
          "amount"
        )
      );

      return {
        paymentMethodForm: {
          ...prevState.paymentMethodForm,
          cancelledChecks,
          totalCancelledChecksAmount: totalAmount,
        },
        refundAmount: totalAmount,
        productsForm: {
          ...prevState.productsForm,
          selectedItemsAmount: 0,
          refundedItems: prevState.productsForm.refundedItems.map((i) => {
            const amount = Math.min(i.itemMaxRefundAmount, totalAmount);
            return {
              ...i,
              refundAmount: amount,
              maxRefundAmount: amount,
              tempAmount: amount,
              checked: false,
            };
          }),
        },
      };
    });
  };

  handleRefundItemChange = async (itemIndex, updates) => {
    await this.setState((prevState) => {
      const refundedItems = [...prevState.productsForm.refundedItems];

      refundedItems[itemIndex] = { ...refundedItems[itemIndex], ...updates };
      const selectedItemsAmount = roundPrice(
        _.sumBy(refundedItems, (item) => (item.checked ? item.refundAmount : 0))
      );

      refundedItems.forEach((item) => {
        const maxRefundAmount = Math.min(
          item.itemMaxRefundAmount,
          this.state.refundAmount -
            (selectedItemsAmount - (item.checked ? item.refundAmount : 0))
        );
        item.maxRefundAmount = maxRefundAmount;
        if (!item.checked) {
          item.refundAmount = maxRefundAmount;
          item.tempAmount = maxRefundAmount;
        }
      });

      const updatedState = {
        productsForm: {
          ...prevState.productsForm,
          refundedItems,
          selectedItemsAmount,
        },
      };

      return updatedState;
    });
  };

  //when finish editing button clicked
  handleRefundItemAmountChange = (itemIndex) => {
    const refundItem = this.state.productsForm.refundedItems[itemIndex];
    if (
      refundItem.tempAmount > refundItem.maxRefundAmount ||
      refundItem.tempAmount <= 0
    ) {
      this.handleRefundItemChange(itemIndex, { error: "סכום לא תקין" });
    } else {
      this.handleRefundItemChange(itemIndex, {
        editing: false,
        refundAmount: refundItem.tempAmount,
      });
    }
  };

  renderRefundForm = () => {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <RefundPaymentMethod
            form={this.state.paymentMethodForm}
            transaction={this.state.transaction}
            onCancelledCheckChange={this.handleCancelledCheckChanged}
            refundAmount={this.state.refundAmount}
            returnChecks
          />
        </Grid>
        <Grid item xs={12}>
          <RefundProductForReturnChecks
            refundAmount={this.state.refundAmount}
            comment={this.state.comment}
            purchase={this.state.purchase}
            form={this.state.productsForm}
            onItemChange={this.handleRefundItemChange}
            onItemAmountChange={this.handleRefundItemAmountChange}
            onChange={this.handleProductsFormChange}
            transaction={this.state.transaction}
            allowMerchandisePartialRefund={
              this.props.allowMerchandisePartialRefund
            }
            returnChecks
          />
        </Grid>
      </Grid>
    );
  };

  render() {
    return (
      <Dialog
        maxWidth="md"
        fullWidth
        onClose={this.props.onClose}
        open={this.props.open}
        title="צ׳קים חוזרים"
        fullScreen={this.props.isXs}
      >
        <DialogContent dividers style={{ padding: 10 }}>
          {this.renderContent()}
        </DialogContent>
        <DialogActions>
          {this.state.success === null && (
            <ButtonSx
              onClick={this.props.onClose}
              disabled={this.state.processing}
            >
              ביטול
            </ButtonSx>
          )}
          <div>
            <ButtonSx
              disabled={this.state.processing}
              onClick={this.handleSubmit}
              debounce
            >
              אישור
            </ButtonSx>
          </div>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withIsXs(ReturnChecksDialog);
