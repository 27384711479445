import React from "react";
import {
  DialogContent,
  DialogActions,
  CircularProgress,
  useMediaQuery,
  Stack,
} from "@mui/material";

import axios from "../../../../utils/axios";
import Dialog from "../../../Dialog";
import EditDirectSubscriptionForm from "./EditDirectSubscriptionForm";
import { useState } from "react";
import { useEffect } from "react";
import { handleSubscriptionFieldsChanged } from "../../../../utils/subscriptionHelpers";
import {
  handleFeePriceChanged,
  handlePriceChanged,
  handleSettingsNewPrice,
  prepareDataForSubmit,
  prepareLocalSubscription,
  validate,
} from "./editDirectSubscriptionHelper";
import ButtonSx from "../../../base/ButtonSx";

export default function EditDirectSubscriptionDialog(props) {
  // show spinner while loading
  // const [isLoading, setIsLoading] = useState(false);
  const [subscription, setSubscription] = useState(false);
  const isSmUp = useMediaQuery((theme) => theme.breakpoints.up("sm"), {
    noSsr: true,
  });

  useEffect(() => {
    if (props.isOpen) {
      loadData();
    } else {
      setSubscription(null);
    }
  }, [props.isOpen]);

  const loadData = async () => {
    setSubscription(
      prepareLocalSubscription(props.subscription, props.subscription.product)
    );
  };

  const handleFieldsChanged = (updates) => {
    setSubscription(handleSubscriptionFieldsChanged(updates, subscription));
  };

  const handlePriceChangedLocal = (month, newPriceStr, newPrice) => {
    setSubscription((prev) =>
      handlePriceChanged(prev, month, newPriceStr, newPrice)
    );
  };

  const handleSettingNewPriceLocal = (priceStr, price) => {
    setSubscription((prev) => handleSettingsNewPrice(prev, priceStr, price));
  };

  const handleFeePriceChangedLocal = (index) => (priceStr, price) => {
    setSubscription((prev) =>
      handleFeePriceChanged(index, prev, priceStr, price)
    );
  };

  const handleSave = async () => {
    const { isValid, errors } = validate(subscription);
    setSubscription({ ...subscription, errors });

    if (isValid) {
      const data = prepareDataForSubmit(subscription);
      await axios.patch(`/store/subscriptions/${props.subscription._id}`, data);
      props.onComplete();
    }
  };

  const handleClose = (changeTeam) => () => {
    props.onClose(changeTeam);
  };

  const renderTitle = () => (
    <Stack direction="row" justifyContent="space-between">
      עדכון פרטי רישום
      <ButtonSx onClick={handleClose(true)}>מעבר קבוצה</ButtonSx>
    </Stack>
  );

  return (
    <Dialog
      onClose={handleClose(false)}
      open={props.isOpen}
      title={renderTitle()}
      fullScreen={!isSmUp}
    >
      <DialogContent dividers>
        {subscription ? (
          <EditDirectSubscriptionForm
            subscription={subscription}
            onFieldsChanged={handleFieldsChanged}
            onPriceChanged={handlePriceChangedLocal}
            onSettingNewPrice={handleSettingNewPriceLocal}
            onFeePriceChange={handleFeePriceChangedLocal}
            summary
            originalSubscription={props.subscription}
          />
        ) : (
          <CircularProgress />
        )}
      </DialogContent>
      <DialogActions>
        <ButtonSx variant="outlined" onClick={handleClose(false)}>
          ביטול
        </ButtonSx>
        <ButtonSx variant="outlined" onClick={() => handleSave()} debounce>
          אישור
        </ButtonSx>
      </DialogActions>
    </Dialog>
  );
}
