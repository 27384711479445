import React from "react";
import moment from "moment";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import ButtonSx from "../../../components/base/ButtonSx";

function SendNotificationsFailedDialog(props) {
  const failures = props.failures || [];

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      maxWidth="xs"
      fullWidth
      dir="rtl"
    >
      <DialogTitle>לא ניתן לשלוח עדכונים לאירועים הבאים</DialogTitle>
      <DialogContent dividers>
        <List disablePadding>
          {failures.map((event) => (
            <ListItem dense key={event._id}>
              <Divider orientation="vertical" sx={styles.divider} />
              <ListItemText
                primary={event.title}
                secondary={`${moment(event.start).format(
                  "D/M/YY HH:mm"
                )} - ${moment(event.end).format("HH:mm")}`}
              />
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <ButtonSx onClick={props.onClose} color="primary">
          סגור
        </ButtonSx>
      </DialogActions>
    </Dialog>
  );
}

const styles = {
  divider: {
    width: 3,
    borderRadius: "2px",
    marginRight: "10px",
    height: 40,
  },
};

export default SendNotificationsFailedDialog;
