import React from "react";
import Chart from "react-apexcharts";

export default function AttendancePieNG(props) {
  const series = [
    props.data.find((i) => i.name === "Attended").value,
    props.data.find((i) => i.name === "Missed").value,
  ];

  return (
    <Chart
      options={{
        chart: { type: "donut" },
        labels: ["נוכח", "חיסור"],
        plotOptions: {
          pie: {
            donut: { labels: { show: true } },
          },
        },
        tooltip: {
          enabled: false,
        },
        colors: ["#4caf50", "#d32f2f"],
      }}
      series={series}
      height={350}
      type="donut"
    />
  );
}
