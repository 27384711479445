import React from "react";
import { Grid, Box } from "@mui/material";
import moment from "moment";

import DatePicker from "../../../components/DatePicker";

export default function ResourceSelect(props) {
  const { event } = props;

  const shouldDisableDate = (date) => {
    return (
      date.isBefore(moment().startOf("day")) &&
      !date.isSame(props.originalStartDate) // allow original date
    );
  };

  return (
    <Grid item xs={12} container spacing={1}>
      <Grid item>
        <DatePicker
          label="התחלה"
          value={event.recurringEvent.startDate}
          readOnly={props.readOnly}
          textFieldProps={{ sx: { width: 138 } }}
          shouldDisableDate={shouldDisableDate}
          onChange={(date) => {
            const updatedRecurringEvent = {
              ...event.recurringEvent,
              startDate: date,
            };

            if (
              moment(date)
                .startOf("day")
                .add(1, "week")
                .isAfter(event.recurringEvent.endDate)
            ) {
              updatedRecurringEvent.endDate = null;
            }
            props.onFieldUpdated("recurringEvent", updatedRecurringEvent);
          }}
        />
      </Grid>
      <Box sx={styles.spacer} />
      <Grid item>
        <DatePicker
          error={props.errors.recurringEventEndDate}
          label="סיום"
          textFieldProps={{ sx: { width: 138 } }}
          minDate={moment(event.recurringEvent.startDate).add(1, "week")}
          value={event.recurringEvent.endDate}
          readOnly={props.readOnly}
          onChange={(date) =>
            props.onFieldUpdated("recurringEvent", {
              ...event.recurringEvent,
              endDate: date,
            })
          }
        />
      </Grid>
    </Grid>
  );
}

const styles = {
  spacer: {
    width: { sm: 20 },
  },
};
