import React from "react";
import { Grid, FormHelperText } from "@mui/material";

import PriceInputNg from "../../../PriceInputNG";

export default function MonthlyCostForm({
  subscription,
  onPriceChange,
  disabled,
}) {
  const handlePriceChange = (month) => (newPriceStr, newPrice) => {
    onPriceChange(month, newPriceStr, newPrice);
  };

  return (
    <>
      {subscription.monthlyPrices.map((monthlyPrice, index) => (
        <Grid item xs={4} key={monthlyPrice.month.format()}>
          <PriceInputNg
            label={
              monthlyPrice.oneCharge
                ? "פעילות"
                : `מחיר ${monthlyPrice.month.format("MMMM YY")}`
            }
            fullWidth
            margin="dense"
            value={monthlyPrice.priceStr}
            onChange={handlePriceChange(monthlyPrice.month)}
            error={(subscription.errors.monthlyPrices || [])[index]}
            disabled={disabled}
          />
        </Grid>
      ))}
      {(subscription.errors.monthlyPricesError ||
        subscription.errors.totalPrice) && (
        <Grid item xs={12}>
          <FormHelperText error>
            {subscription.errors.monthlyPricesError ||
              subscription.errors.totalPrice}
          </FormHelperText>
        </Grid>
      )}
    </>
  );
}
