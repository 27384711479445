import axios from "../../utils/axios";
import { loadSeasons } from "./general";

export const auth = (user) => {
  return {
    type: "LOGIN",
    user,
  };
};

export const login = (user, token) => {
  return async (dispatch, getState) => {
    dispatch(setIsLoading(true));
    axios.defaults.headers.common["Authorization"] = token;
    axios.defaults.headers.common["Tl-club"] = user.clubId;

    await dispatch(loadClubs(user.clubId));
    const clubs = getState().auth.clubs;
    const lastClubId = clubs[0]._id;
    // don't save lastClubId. it can be the super club and not all clubs has superclub access in admin (only haifa and ashdod)
    localStorage.setItem("userId", user._id);
    localStorage.setItem("token", token);
    localStorage.setItem("userClubId", user.clubId);

    axios.defaults.headers.common["Authorization"] = token;
    axios.defaults.headers.common["Tl-club"] = lastClubId;
    dispatch(auth(prepareUser(user, token)));
    dispatch(setIsLoading(false));
  };
};

const prepareUser = (user, token) => ({
  _id: user._id,
  email: user.email,
  level: user.level,
  permissions: user.permissions,
  name: user.name,
  token,
});

export const autoLogin = () => {
  return async (dispatch) => {
    dispatch(setIsLoading(true));

    const userId = localStorage.getItem("userId");
    const token = localStorage.getItem("token");
    const userClubId = localStorage.getItem("userClubId");

    if (userId !== null && token !== null) {
      axios.defaults.headers.common["Authorization"] = token;
      axios.defaults.headers.common["Tl-club"] = userClubId;
      try {
        const res = await axios.get(`/managers/${userId}`);
        const user = res.data;

        dispatch(auth(prepareUser(user, token)));
        await dispatch(loadClubs(userClubId));
      } catch (error) {
        if (
          error &&
          error.response &&
          error.response.status &&
          (error.response.status === 404 || error.response.status === 401)
        ) {
          dispatch(logout());
        } else {
          // for now we want to be stuck at "loading" (white screen) and not be transfered to login screen
          throw error;
        }
      }
    } else {
      dispatch(logout());
    }

    dispatch(setIsLoading(false));
  };
};

export const logout = () => {
  localStorage.removeItem("userId");
  localStorage.removeItem("token");
  localStorage.removeItem("lastClubId");
  localStorage.removeItem("userClubId");
  delete axios.defaults.headers.common["Authorization"];
  delete axios.defaults.headers.common["Tl-club"];

  return {
    type: "LOGOUT",
  };
};

export const setIsLoading = (isLoading) => {
  return {
    type: "STATUS",
    loading: isLoading,
  };
};

export const setGentleClubChange = (status) => {
  return {
    type: "SET_GENTLE_CLUB_CHANGE",
    gentleClubChange: status,
  };
};

export const changeToLastClub = () => {
  return (dispatch, getState) => {
    const { clubs } = getState().auth;
    let clubId = localStorage.getItem("lastClubId");
    let club;
    if (clubId) {
      club = clubs.find((c) => c._id === clubId);
    }

    if (!club) {
      club = getState().auth.clubs[0];
    }

    dispatch(changeClub(club));
  };
};

// gentle. true -> spinner within the layout. false -> spinner without layout
export const changeClub = (club, gentle) => {
  return async (dispatch) => {
    localStorage.setItem("lastClubId", club._id);
    axios.defaults.headers.common["Tl-club"] = club._id;

    gentle ? dispatch(setGentleClubChange(true)) : dispatch(setIsLoading(true));
    await dispatch(set_club(club));
    await dispatch(loadSeasons(club));
    gentle
      ? dispatch(setGentleClubChange(false))
      : dispatch(setIsLoading(false));
  };
};

export const set_club = (club) => {
  return {
    type: "CHANGE_CLUB",
    club,
  };
};

// userClubId - original user's clubId (not subClubs)
export const loadClubs = (userClubId) => {
  return async (dispatch) => {
    try {
      const res = await axios.get(
        `/clubs/${userClubId}?` +
          "granularity=adminConfig" +
          "&granularity=subClubs.adminConfig" +
          "&granularity=additionalConfigs.parents" +
          "&granularity=additionalConfigs.server" +
          "&granularity=subClubs.additionalConfigs.parents" +
          "&granularity=subClubs.additionalConfigs.server",
        {
          // the following lines can retrieve the clubs if current club no longer be connected to the super club
          // but fuck it. the user will get 401 for all requests and will have to choose different club
          // transformRequest: (data, headers) => {
          //   delete headers.common["Tl-club"]; // don't send club since it may no longer be connected to the super club
          //   return data;
          // },
        }
      );

      const club = res.data;
      let clubs = [club];
      if (club.superClub) {
        if (club.clubConfig.superClubAdminSection) {
          clubs = [club, ...club.subClubs];
        } else {
          clubs = [...club.subClubs];
        }
      }

      clubs.forEach((club) => {
        club.clubConfig.parentsConfig = club.parentsConfig;
      });

      await dispatch(setClubs(clubs, club.superClub ? club : null));
    } catch (error) {
      setError("loadClubs", "failed");
    }
  };
};

export const setError = (errorType, error) => {
  return {
    type: "SET_ERROR",
    errorType,
    error,
  };
};

export const setClubs = (clubs, superClub) => {
  const clubsHash = Object.fromEntries(clubs.map((c) => [c._id, c]));
  return {
    type: "SET_CLUBS",
    clubs,
    superClub,
    clubsHash,
  };
};

export const updateClubConfig = (clubInternalName, clubConfig) => {
  return (dispatch, getState) => {
    const { clubs, superClub } = getState().auth;
    let newClub;

    const newClubs = clubs.map((club) => {
      if (club.internalName === clubInternalName) {
        newClub = { ...club, clubConfig };
        return newClub;
      }
      return club;
    });
    dispatch(setClubs(newClubs, superClub));
    dispatch(set_club(newClub));
  };
};
