import React from "react";
import { TextField } from "@mui/material";
import moment from "moment";

import MenuItem from "../../../components/base/MenuItem";

export default function DepartureTimeSelect(props) {
  const { event } = props;

  const optionalDepartureTime = (startTime) => {
    const startTimeString = moment(startTime).format("HH:mm");
    const startTimeIndex = props.times.indexOf(startTimeString);
    return props.times.slice(Math.max(startTimeIndex - 32, 0), startTimeIndex);
  };

  return (
    <TextField
      variant="standard"
      error={props.errors.departureTime !== undefined}
      select={!props.readOnly}
      disabled={props.disabled}
      label="שעת יציאה"
      margin="dense"
      fullWidth
      value={
        event.gameDetails.departureTime
          ? moment(event.gameDetails.departureTime).format("HH:mm")
          : ""
      }
      onChange={(uiEvent) => {
        const newDepartureTime = props
          .prepareTime(event.start.date, uiEvent.target.value)
          .toDate();

        props.onFieldUpdated("gameDetails", {
          ...event.gameDetails,
          departureTime: newDepartureTime,
        });
      }}
      inputProps={{
        readOnly: props.readOnly,
      }}
    >
      {optionalDepartureTime(event.start).map((time) => (
        <MenuItem key={time} value={time}>
          {time}
        </MenuItem>
      ))}
    </TextField>
  );
}
