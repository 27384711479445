import React from "react";
import ReactGA from "react-ga4";

import PlayersTable from "../components/players/PlayersTable";
import axios from "../utils/axios";
import FiltersBar from "../components/filters/FiltersBar";
import {
  prepareFilters,
  getFiltersFromUrl,
} from "../components/filters/prepareFilters";
import withRouter from "../components/routing/withRouter";
import TlPaper from "../components/TlPaper";

const columns = [
  { id: "name", label: "שם" },
  { id: "teams", label: "קבוצות", minWidth: 100 },
  { id: "phone", label: "טלפון", width: "120px" },
  { id: "dateOfBirth", label: "תאריך לידה", width: "120px" },
  { id: "status", label: "סטטוס", width: "60px" },
  { id: "medicalIssues", label: "מגבלות רפואיות", width: 100 },
];

const filtersStructure = [
  {
    name: "name",
    type: "text",
    label: "שם הספורטאי",
  },
  {
    name: "phone",
    type: "text",
    label: "טלפון",
  },
  {
    name: "id",
    type: "text",
    label: "ת.ז",
  },
  {
    name: "status",
    type: "select",
    options: {
      active: "רשום",
      trial: "ניסיון",
      inactive: "לא פעיל",
    },
    emptyLabel: "כל הסטטוסים",
    label: "סטטוס רישום",
    includeAll: true,
  },
  {
    name: "hasMedicalIssues",
    type: "select",
    options: {
      false: "אין מגבלות",
      true: "קיימת מגבלה רפואית",
    },
    emptyLabel: "כל השחקנים",
    label: "מגבלות רפואיות",
  },
  // {
  //   name: "gender",
  //   type: "select",
  //   options: {
  //     male: "זכר",
  //     female: "נקבה",
  //   },
  //   emptyLabel: "כל המינים",
  //   label: "מין",
  // },
];

class Players extends React.Component {
  state = {
    isLoading: true,
    filters: {
      name: "",
      phone: "",
      status: "active",
      id: "",
      hasMedicalIssues: "all",
      gender: "all",
    },
    players: [],
  };

  componentDidMount = async () => {
    ReactGA.send({
      hitType: "pageview",
      page: "/players",
      title: "Players",
    });

    const filters = getFiltersFromUrl(
      this.props.location,
      this.state.filters,
      filtersStructure
    );
    await this.setState({ filters });
    this.loadData(false);
  };

  loadData = async (updateUrl = true) => {
    this.setState({ isLoading: true });
    // if (!this.state.clubAlerts) {
    //   await this.loadGeneralData();
    // }
    let filters = prepareFilters(this.state.filters, filtersStructure, {
      includeAll: false,
    });
    let response = await axios.get(
      `/clubPlayers?granularity=alerts&granularity=activeTeamsSuperlight&${filters.join(
        "&"
      )}`
    );

    this.setState({
      isLoading: false,
      players: response.data,
      filteredPlayers: response.data,
    });

    if (updateUrl) {
      filters = prepareFilters(this.state.filters, filtersStructure);
      this.props.navigate(`?${filters.join("&")}`, { replace: true });
    }
  };

  handleFilterChange = (filterName, value) => {
    this.setState({ filters: { ...this.state.filters, [filterName]: value } });
  };

  render() {
    return (
      <TlPaper title="ספורטאים" titleBackground>
        <FiltersBar
          filters={filtersStructure}
          onFilterChanged={this.handleFilterChange}
          values={this.state.filters}
          onSearch={this.loadData}
          isSearching={this.state.isLoading}
        />
        <PlayersTable
          filters
          players={this.state.players}
          pagination
          tableHeaders={columns}
          exportFileName="ספורטאים"
          isLoading={this.state.isLoading}
          noSubscriptionSum
        />
      </TlPaper>
    );
  }
}

export default withRouter(Players);
