import { roundPrice } from "./helpers";

export const calcCreditCardPaymentsAmount = (
  rawTotalAmount,
  rawFirstPaymentAmount,
  numberOfPayments,
  rawNonSubscriptionAmount
) => {
  if (!numberOfPayments || !rawTotalAmount) {
    return { firstPaymentAmount: undefined, basePaymentAmount: undefined };
  } else if (numberOfPayments === 1) {
    return {
      firstPaymentAmount: roundPrice(rawTotalAmount * 100),
      basePaymentAmount: 0,
    };
  }

  const totalAmount = roundPrice(rawTotalAmount * 100);
  let basePaymentAmount, firstPaymentAmount;

  // user chose first payment amount
  if (rawFirstPaymentAmount) {
    basePaymentAmount = Math.floor(
      (totalAmount - rawFirstPaymentAmount) / (numberOfPayments - 1)
    );
    firstPaymentAmount =
      totalAmount - basePaymentAmount * (numberOfPayments - 1);
  } else {
    // default calc
    let nonSubscriptionAmount = rawNonSubscriptionAmount * 100;
    let subscriptionAmount = totalAmount - nonSubscriptionAmount;

    // only fees or merchandise -> split the amount equally between payments
    if (nonSubscriptionAmount === totalAmount) {
      subscriptionAmount = nonSubscriptionAmount;
      nonSubscriptionAmount = 0;
    }

    // make default base payment round number
    basePaymentAmount =
      Math.floor(subscriptionAmount / numberOfPayments / 100) * 100;
    firstPaymentAmount =
      totalAmount - basePaymentAmount * (numberOfPayments - 1);
  }

  return {
    firstPaymentAmount: roundPrice(firstPaymentAmount),
    basePaymentAmount: roundPrice(basePaymentAmount),
  };
};
