import React from "react";
import _ from "lodash";
import { Grid, Typography } from "@mui/material";

import {
  displayDate,
  displayPrice,
  roundPrice,
} from "../../../../utils/helpers";
import SubscriptionHeaderItem from "./SubscriptionHeaderItem";

export default function SubscriptionCostSummary({
  subscription,
  originalSubscription,
}) {
  const feesPrice = roundPrice(_.sumBy(subscription.fees, "price"));
  let totalPrice = roundPrice(subscription.price + feesPrice);
  if (isNaN(totalPrice)) {
    totalPrice = 0;
  }

  // product for editing, productId for new sub
  if (!subscription.product && !subscription.productId) {
    return (
      <Typography sx={styles.summaryContainer}>
        בחרו את מסגרת התשלום הרצויה
      </Typography>
    );
  }

  const currentDebt = roundPrice(totalPrice - originalSubscription?.paid);

  return (
    <Grid container spacing={3}>
      <Grid item xs={6} md={4}>
        <SubscriptionHeaderItem
          title="פעילות"
          value={displayPrice(subscription.price)}
        />
      </Grid>
      <Grid item xs={6} md={4}>
        <SubscriptionHeaderItem
          title="דמי רישום"
          value={displayPrice(feesPrice)}
        />
      </Grid>
      {originalSubscription && (
        <Grid item xs={6} md={4}>
          <SubscriptionHeaderItem
            title="תחילת פעילות"
            value={displayDate(subscription.startDate)}
          />
        </Grid>
      )}
      <Grid item xs={6} md={4}>
        <SubscriptionHeaderItem
          title="עלות כוללת"
          value={displayPrice(totalPrice)}
        />
      </Grid>
      {originalSubscription && (
        <>
          <Grid item xs={6} md={4}>
            <SubscriptionHeaderItem
              title="שולם"
              value={displayPrice(originalSubscription.paid)}
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <SubscriptionHeaderItem
              title={currentDebt < 0 ? "יתרת זכות" : "יתרת חוב"}
              value={displayPrice(Math.abs(currentDebt))}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
}

const styles = {
  summaryContainer: {
    flex: 1,
    display: "flex",
    margin: "5px 0px",
    padding: "5px",
    justifyContent: "space-around",
  },
};
