import React from "react";
import _ from "lodash";
import { connect } from "react-redux";

import axios from "../../../utils/axios";
import DirectSubscription from "./DirectSubscription";
import IndirectSubscription from "./IndirectSubscription";
import * as Actions from "../../../store/actions";

class Subscription extends React.Component {
  state = {
    subscription: null,
    payments: null,
    transactions: null,
    isLoading: true,
  };

  componentDidUpdate = async (prevProps) => {
    if (
      prevProps.subscription?._id !== this.props.subscription?._id &&
      this.props.subscription?._id
    ) {
      this.loadData();
    }

    if (prevProps.open && !this.props.open) {
      this.setState({
        subscription: null,
        payments: null,
        transactions: null,
        isLoading: true,
      });
    }
  };

  loadData = async () => {
    let subscription;
    let res;

    this.setState({ isLoading: true });

    if (
      this.props.subscription._id &&
      this.props.subscription._id !== "trial"
    ) {
      res = await axios.get(
        `/store/subscriptions/${this.props.subscription._id}?granularity=payments&granularity=team&granularity=feeProducts&granularity=product`
      );
      subscription = res.data;
    }

    let payments = [];
    let transactions = [];
    if (
      subscription &&
      subscription.type === "direct" &&
      subscription.payments.length > 0
    ) {
      const paymentIds = subscription.payments.map(
        (p) => `ids=${p.payment._id}`
      );
      res = await axios.get(`/payments/payments?${paymentIds.join("&")}`);
      payments = res.data;

      payments.forEach((p) => {
        p.transactions.forEach((t) => (t.payment = p));
      });

      transactions = _.reverse(
        _.sortBy(_.flatten(payments.map((p) => p.transactions)), "createdAt")
      );
    }

    // when reloading after a payment which changes the subscription's status
    // -> update the notification bar counters as well
    if (
      this.state.subscription &&
      this.state.subscription.status === "pendingOtherPaymentMethod" &&
      subscription.status !== "pendingOtherPaymentMethod"
    ) {
      this.props.loadNotificationBarCounters();
    }

    this.setState({ subscription, payments, transactions, isLoading: false });
  };

  onComplete = (...args) => {
    this.loadData();
    this.props.onComplete(...args);
  };

  toggleAutoDebtAlert = async () => {
    let newState;
    this.setState(
      (prev) => {
        newState = !prev.subscription.autoDebtAlert;
        return {
          subscription: { ...prev.subscription, autoDebtAlert: newState },
        };
      },
      async () => {
        let success;
        try {
          await axios.patch(
            `/store/subscriptions/${this.state.subscription._id}/autoDebtAlert`,
            { autoDebtAlert: newState }
          );
          success = true;
        } catch (error) {
          success = false;
        }

        this.props.onComplete({
          status: success ? "success" : "error",
          message: success ? "השינוי בוצע בהצלחה" : "אירעה שגיאה",
          subscription: this.props.subscription,
        });
      }
    );
  };

  render() {
    let subType;
    if (!this.props.subscription) {
      return <div />;
    }

    if (this.props.subscription.trial) {
      subType = this.props.subscription.team.productId ? "direct" : "indirect";
    } else if (this.props.subscription?._id) {
      subType = this.props.subscription.type;
    }

    if (subType === "direct") {
      return (
        <DirectSubscription
          player={this.props.player}
          subscription={this.state.subscription || this.props.subscription} // this.state.subscription - real sub || subscription - trial
          isLoading={this.state.isLoading}
          onComplete={this.onComplete}
          payments={this.state.payments}
          transactions={this.state.transactions}
          open={this.props.open}
          onClose={this.props.onClose}
          user={this.props.user}
          onToggleAutoDebtAlert={this.toggleAutoDebtAlert}
          clubConfig={this.props.selectedClub.clubConfig}
          currentSubscriptionOpenEdit={this.props.currentSubscriptionOpenEdit}
        />
      );
    } else if (subType === "indirect") {
      return (
        <IndirectSubscription
          player={this.props.player}
          subscription={this.state.subscription || this.props.subscription}
          isLoading={this.state.isLoading}
          onComplete={this.onComplete}
          open={this.props.open}
          onClose={this.props.onClose}
          user={this.props.user}
        />
      );
    } else {
      return <span />;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    selectedClub: state.auth.selectedClub,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    loadNotificationBarCounters: () =>
      dispatch(Actions.general.loadNotificationBarCounters()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Subscription);
