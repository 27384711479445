import React from "react";
import queryString from "query-string";
import Avatar from "@mui/material/Avatar";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import { Box } from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Link as RouterLink } from "react-router-dom";

import axios from "../../utils/axios";
import withRouter from "../../components/routing/withRouter";
import ButtonSx from "../../components/base/ButtonSx";

const styles = {
  paper: {
    marginTop: "8px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: "1px",
    backgroundColor: "secondary.main",
  },
  submit: {
    mt: 3,
    mb: 2,
  },
  dialogTitle: {
    textAlign: "center",
  },
  dialogText: {
    textAlign: "center",
    paddingBottom: "20px",
    borderWidth: 1,
  },
  errorText: {
    color: "secondary.main",
  },
};

class ChangePassword extends React.Component {
  state = {
    password: "",
    passwordConfirmation: "",
    formErrors: {},
    error: null,
    completed: false,
    loginError: false,
    isLoading: false,
  };

  validateForm = function () {
    const formErrors = {};
    let valid = true;
    if (
      this.state.password.match(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/
      ) === null
    ) {
      valid = false;
      formErrors.password =
        "סיסמה לא תקינה. על הסיסמה להיות בעלת לפחות 8 תוים הכוללים אות גדולה ומספר";
    }

    if (this.state.password !== this.state.passwordConfirmation) {
      valid = false;
      formErrors.passwordConfirmation = "הסיסמאות לא זהות";
    }

    this.setState({ formErrors });
    return valid;
  };

  onPress = async function (e) {
    e.stopPropagation();
    e.preventDefault();

    const valid = this.validateForm();
    if (valid) {
      try {
        this.setState({ isLoading: true });
        const token = queryString.parse(this.props.location.search).token;

        await axios.post("/users/reset-password-admin", {
          password: this.state.password,
          token,
        });
        this.setState({ isLoading: false, completed: true });
      } catch (error) {
        this.setState({ loginError: true, isLoading: false });
        if (error.response.status === 401) {
          if (error.response.data && error.response.data.error) {
            switch (error.response.data.error) {
              case "token_expired":
                this.setState({ error: "לינק איפוס הסיסמה פג תוקף" });
                break;

              case "invalid_token":
                this.setState({ error: "לינק איפוס הסיסמה אינו תקין" });
                break;

              default:
                break;
            }
          }
        }
      }
    }
  };

  render() {
    return (
      <Container dir="rtl" component="main" maxWidth="xs">
        <CssBaseline />
        <Box sx={styles.paper}>
          <Avatar sx={styles.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            איפוס סיסמה
          </Typography>
          <form
            style={{
              width: "100%", // Fix IE 11 issue.
              marginTop: "1px",
            }}
            noValidate
          >
            <TextField
              disabled={this.state.isLoading}
              error={this.state.formErrors.password !== undefined}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              placeholder="סיסמה"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={(event) =>
                this.setState({ password: event.target.value })
              }
            />
            <Box sx={styles.errorText}>{this.state.formErrors.password}</Box>
            <TextField
              disabled={this.state.isLoading}
              error={this.state.formErrors.passwordConfirmation !== undefined}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="passwordConfirmation"
              placeholder="אישור"
              type="password"
              id="passwordConfirmation"
              autoComplete="current-password"
              onChange={(event) =>
                this.setState({ passwordConfirmation: event.target.value })
              }
            />
            <Box sx={styles.errorText}>
              {this.state.formErrors.passwordConfirmation}
            </Box>
            {this.state.error && (
              <div style={{ textAlign: "center", fontSize: 18 }}>
                {this.state.error}
              </div>
            )}
            <div style={{ position2: "relative" }}>
              <ButtonSx
                disabled={this.state.error !== null}
                fullWidth
                variant="contained"
                color="primary"
                sx={styles.submit}
                onClick={(e) => this.onPress(e)}
                isLoading={this.state.isLoading}
                buttonProps={{ type: "submit" }}
              >
                אפס סיסמה
              </ButtonSx>
            </div>
          </form>
        </Box>
        <Dialog
          aria-labelledby="simple-dialog-title"
          open={this.state.completed}
          fullWidth={true}
          maxWidth="sm"
        >
          <DialogTitle id="simple-dialog-title" sx={styles.dialogTitle}>
            איפוס הסיסמה הושלם בהצלחה
          </DialogTitle>
          <Box sx={styles.dialogText}>
            <RouterLink color="secondary" to={`/login`} sx={styles.link}>
              לחץ כאן להמשך
            </RouterLink>
          </Box>
        </Dialog>
      </Container>
    );
  }
}

export default withRouter(ChangePassword);
