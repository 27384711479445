import React, { useState } from "react";
import validator from "validator";
import { TextField, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";

import InfoRow from "./InfoRow";
import ButtonSx from "../base/ButtonSx";

export default function ColorInfoRow({
  title,
  value: rawValue,
  onChange,
  ...rest
}) {
  const [isEditing, setIsEditing] = useState(false);
  const [editedValue, setEditedValue] = useState("");

  const isValidHexColor = (color) => {
    return validator.isHexColor(color);
  };

  const isEditedColorValid = validator.isHexColor(editedValue);
  let isValidColor = !isEditing || isValidHexColor(editedValue);

  const handleStartEditing = () => {
    setEditedValue(rawValue || "");
    setIsEditing(true);
  };

  const handleCancelEditing = () => {
    setEditedValue(rawValue || "");
    setIsEditing(false);
  };

  const hashFixedEditedColor = () =>
    editedValue.startsWith("#") ? editedValue : "#" + editedValue;

  const handleCompleteEditing = async () => {
    await onChange(hashFixedEditedColor());
    setEditedValue("");
    setIsEditing(false);
  };

  const renderSampleColor = () => {
    const color = isEditing ? hashFixedEditedColor() : rawValue;
    return (
      <Box
        sx={{
          ...styles.sampleColor,
          backgroundColor: isValidColor ? color : "",
        }}
      />
    );
  };

  const renderNotEditingValue = () => (
    <Box dir="ltr" onClick={handleStartEditing} sx={styles.valueContainer}>
      {rawValue || "Default"}
    </Box>
  );

  const renderEditingValue = () => (
    <Box sx={styles.valueContainerEditing}>
      <TextField
        variant="standard"
        dir="ltr"
        sx={styles.textField}
        value={editedValue}
        onChange={(e) => {
          setEditedValue(e.target.value);
        }}
      />
      <Box sx={styles.buttonsContainer}>
        <ButtonSx onClick={handleCancelEditing} sx={styles.button}>
          <CloseIcon style={{ fontSize: 16 }} />
        </ButtonSx>
        <ButtonSx
          onClick={handleCompleteEditing}
          disabled={!isEditedColorValid}
          sx={styles.button}
        >
          <DoneIcon style={{ fontSize: 16 }} />
        </ButtonSx>
      </Box>
    </Box>
  );

  const value = (
    // first div takes 100% width
    <div>
      <Box sx={styles.container}>
        {isEditing ? renderEditingValue() : renderNotEditingValue()}
        {renderSampleColor()}
        <Box sx={styles.invalidMessageContainer}>
          {!isValidColor && "צבע לא תקין"}
        </Box>
      </Box>
    </div>
  );

  return <InfoRow title={title} value={value} {...rest} />;
}

const styles = {
  container: {
    display: "flex",
    flexDirection: "row",
    margin: "3px",
  },
  valueContainer: {
    width: 110,
    display: "flex",
    alignItems: "center",
    padding: "5px 5px 10px",
    "&:hover": {
      backgroundColor: "#dfe1e6",
      borderRadius: "6px",
    },
  },
  valueContainerEditing: {
    display: "flex",
    flexDirection: "column",
    marginRight: "10px",
  },
  buttonsContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    width: 30,
    height: 30,
    minWidth: 30,
    padding: 0,
    marginLeft: "5px",
    marginTop: "5px",
  },
  textField: {
    width: 100,
  },
  invalidMessageContainer: {
    height: 30,
    marginLeft: "10px",
    display: "flex",
    alignItems: "center",
  },
  sampleColor: {
    width: 30,
    height: 30,
    border: "0.5px solid #2F3532",
    borderRadius: "4px",
    marginRight: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "2px",
  },
};
