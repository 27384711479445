import React from "react";
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  IconButton,
  Slide,
  ListItemButton,
  Box,
  Stack,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";

import AbsoluteCenter from "../../../components/AbsoluteCenter";
import axios from "../../../utils/axios";
import SendNotificationsFailureDialog from "./SendNotificationsFailureDialog";
import SnackbarNg from "../../../components/base/SnackbarNg";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});

export default function UnsentNotificationsDialog(props) {
  const [events, setEvents] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isSaving, setIsSaving] = React.useState(false);
  const [checked, setChecked] = React.useState([]);
  const [isFailureDialogOpen, setIsFailureDialogOpen] = React.useState(false);
  const [failedEvents, setFailedEvents] = React.useState(null);
  const [successCount, setSuccessCount] = React.useState(null);
  const [isSnackBarOpen, setIsSnackBarOpen] = React.useState(false);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleToggleAll = () => {
    const checkAll = checked.length === events.length;
    setChecked(checkAll ? [] : events.map((e) => e._id));
  };

  const loadEvents = async () => {
    setIsLoading(true);
    const res = await axios.get(`/events?unsentNotifications=true`);
    setEvents(res.data);
    setIsLoading(false);
    // update parent if needed
    if (res.data.length !== props.count) {
      props.onUpdate();
    }
  };

  React.useEffect(() => {
    if (props.open) {
      if (!isLoading) {
        loadEvents();
      }
    } else {
      setTimeout(() => {
        setEvents(null);
        setChecked([]);
      }, 100);
    }
  }, [props.open]);

  const formatSecondary = (event) => {
    return `${moment(event.start).format("D/M/YY HH:mm")} - ${moment(
      event.end
    ).format("HH:mm")}`;
  };

  const handleSave = async () => {
    setIsSaving(true);
    const res = await axios.post("/events/sendNotifications", {
      eventIds: checked,
    });

    const failures = res.data.failures;
    setSuccessCount(res.data.successCount || 0);
    if (failures && failures.length > 0) {
      const failed = failures.map((failure) => {
        const event = events.find((e) => e._id === failure._id);
        return { ...event, failureReason: failure.reason };
      });
      setFailedEvents(failed);
      setIsFailureDialogOpen(true);
    } else {
      props.onComplete();
      setIsSnackBarOpen(moment().format());
    }

    setIsSaving(false);
  };

  const renderContent = () => {
    if (events.length === 0) {
      return <Box sx={styles.noData}>כל העדכונים נשלחו!</Box>;
    }

    return (
      <>
        <List>
          <ListItem disableRipple disablePadding>
            <ListItemButton onClick={handleToggleAll}>
              <ListItemIcon>
                <Box sx={styles.divider} />
                <Checkbox
                  edge="start"
                  checked={checked.length === events.length}
                  tabIndex={-1}
                  disableRipple
                />
              </ListItemIcon>
              <ListItemText primary={`${checked.length} אירועים נבחרו`} />
            </ListItemButton>
          </ListItem>
        </List>
        <Divider sx={{ borderColor: "gray", marginLeft: "15px" }} />
        <List>
          {events.map((event) => (
            <ListItem dense key={event._id} disablePadding>
              <ListItemButton onClick={handleToggle(event._id)}>
                <ListItemIcon>
                  <Box
                    sx={{ ...styles.divider, backgroundColor: "lightGray" }}
                  />
                  <Checkbox
                    edge="start"
                    checked={checked.indexOf(event._id) !== -1}
                    tabIndex={-1}
                    disableRipple
                  />
                </ListItemIcon>
                <ListItemText
                  primary={event.title}
                  secondary={formatSecondary(event)}
                />
                {event.deleted && (
                  <ListItemSecondaryAction>
                    <Box sx={styles.collisionDescription}>אירוע מבוטל</Box>
                  </ListItemSecondaryAction>
                )}
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        {isSaving && (
          <AbsoluteCenter>
            <CircularProgress />
          </AbsoluteCenter>
        )}
        {process.env.NODE_ENV === "development" &&
          events.map((e) => e._id).join(" ")}
      </>
    );
  };

  const handleFailuresDialogClose = () => {
    loadEvents();
    setChecked([]);
    setIsFailureDialogOpen(false);
    setFailedEvents([]);
    props.onUpdate();
  };

  return (
    <>
      <Dialog
        open={props.open}
        onClose={props.onClose}
        fullScreen
        sx={styles.dialog}
        dir="rtl"
        TransitionComponent={Transition}
      >
        <DialogTitle>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            עדכונים שלא נשלחו
            <IconButton size="small" onClick={props.onClose}>
              <CloseIcon />
            </IconButton>
          </Stack>
        </DialogTitle>

        <DialogContent dividers>
          {isLoading || !events ? (
            <AbsoluteCenter>
              <CircularProgress />
            </AbsoluteCenter>
          ) : (
            renderContent()
          )}
        </DialogContent>

        <DialogActions>
          <Button
            disabled={isLoading || isSaving}
            onClick={props.onClose}
            color="primary"
            variant="outlined"
            sx={styles.button}
          >
            ביטול
          </Button>
          <Button
            disabled={isLoading || isSaving || checked.length === 0}
            onClick={handleSave}
            color="primary"
            variant="outlined"
            sx={styles.button}
          >
            שליחה
          </Button>
        </DialogActions>
      </Dialog>
      <SendNotificationsFailureDialog
        open={isFailureDialogOpen}
        onClose={handleFailuresDialogClose}
        failures={failedEvents}
        events={events}
      />
      <SnackbarNg
        snackKey={isSnackBarOpen}
        open={isSnackBarOpen !== false}
        onClose={() => setIsSnackBarOpen(false)}
        severity="success"
        message={
          successCount === 0
            ? "לא ניתן לשלוח עדכונים למאמנים שנבחרו"
            : `${successCount} עדכונים נשלחו בהצלחה`
        }
      />
    </>
  );
}

const styles = {
  noData: {
    marginTop: 5,
    marginLeft: { xs: 0, sm: 2 },
    display: "flex",
    justifyContent: { xs: "center", sm: "flex-start" },
    fontSize: 20,
  },
  divider: {
    width: 3,
    borderRadius: 2,
    marginRight: "20px",
    height: 40,
    backgroundColor: "gray",
  },
  collisionDescription: {
    backgroundColor: (theme) => theme.calendar.collisions,
    padding: "5px 10px",
    borderRadius: ".25rem",
    color: "#ffffff",
    fontWeight: "bold",
    marginLeft: 10,
  },
  button: {
    width: 120,
  },
  dialog: {
    width: { sm: 470 },
    zIndex: { sm: 11 },
    marginTop: { xs: 0, sm: "72px" },
  },
};
