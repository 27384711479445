import React from "react";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

export default function PasswordTextField(props) {
  const [visible, setVisible] = React.useState(false);

  return (
    <TextField
      variant="standard"
      InputProps={{
        type: visible ? "text" : "password",
        endAdornment: (
          <InputAdornment position="start">
            <IconButton size="small" onClick={() => setVisible(!visible)}>
              {visible ? <VisibilityOffIcon /> : <VisibilityIcon />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...props} />
  );
}
