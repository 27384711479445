import React from "react";
import { Grid, TextField } from "@mui/material";

import { banks, dicToArray } from "../../../utils/dictionaries";
import AutoComplete from "../../base/AutoComplete";
import DatePicker from "../../DatePicker";

const banksArray = dicToArray(banks);

export default function CheckRefund(props) {
  const { date, checkNumber, accountNumber, branch, bank } = props;

  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <DatePicker
          label="תאריך"
          textFieldProps={{
            error: props.errors.date,
            helperText: props.errors.date && "שדה לא תקין",
          }}
          value={date}
          onChange={(date) => props.onChange({ date })}
          disabled={props.disabled}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          variant="standard"
          label="מספר צ׳ק"
          fullWidth
          margin="dense"
          value={checkNumber}
          type="number"
          onChange={(e) => {
            props.onChange({ checkNumber: e.target.value });
          }}
          disabled={props.disabled}
          error={props.errors.checkNumber}
          helperText={props.errors.checkNumber && "שדה לא תקין"}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          variant="standard"
          label="מספר חשבון"
          fullWidth
          margin="dense"
          value={accountNumber}
          type="number"
          onChange={(e) => {
            props.onChange({ accountNumber: e.target.value });
          }}
          disabled={props.disabled}
          error={props.errors.accountNumber}
          helperText={props.errors.accountNumber && "שדה לא תקין"}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          variant="standard"
          label="סניף"
          fullWidth
          margin="dense"
          value={branch}
          type="number"
          onChange={(e) => {
            props.onChange({ branch: e.target.value });
          }}
          disabled={props.disabled}
          error={props.errors.branch}
          helperText={props.errors.branch && "שדה לא תקין"}
        />
      </Grid>
      <Grid item xs={12}>
        <AutoComplete
          options={banksArray}
          mode="objects"
          value={bank}
          label="בנק"
          fullWidth
          onChange={(bank) => {
            props.onChange({ bank });
          }}
          textFieldParams={{
            error: props.errors.bank,
            helperText: props.errors.bank && "שדה לא תקין",
          }}
        />
      </Grid>
    </Grid>
  );
}
