import React from "react";
import { Container, Typography, Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

import TlPaper from "./TlPaper";

export default function PageNotFound(props) {
  return (
    <Container maxWidth="sm">
      <TlPaper bodySx={styles.paper}>
        <Typography variant="h6">
          {props.children || "העמוד שחיפשתם לא נמצא"}
        </Typography>
        <Link component={RouterLink} color="primary" to={`/`} underline="hover">
          חזרה לדף הבית
        </Link>
      </TlPaper>
    </Container>
  );
}

const styles = {
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
};
