import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Link } from "@mui/material";
import moment from "moment";

import { classes as classDictionary, gender } from "../../utils/dictionaries";
import { displayDate, displayPrice } from "../../utils/helpers";
import { exportTable } from "../../lib/tableHelpers";
import Table from "../table/Table";
import EllipsisTooltip from "../EllipsisTooltip";
import PlayerSubscriptionSum from "./PlayerSubscriptionSum";

function PlayersTable(props) {
  const sortValueCell = (columnId, player) => {
    const subscriptions = player.subscriptions || [];

    switch (columnId) {
      case "team":
        return (player.activeTeams[0] || {}).name;

      case "alerts":
        return player.alerts.length;

      case "status":
        return renderCell(columnId, player);

      case "cost":
        return parseFloat(subscriptions[0]?.price || 0);

      case "totalCost":
        return parseFloat(subscriptions[0]?.totalPrice || 0);

      case "totalPaid":
        return parseFloat(subscriptions[0]?.paid || 0);

      case "medicalIssues":
        return player.medicalIssues ? player.medicalIssues : "";

      case "createdAt":
        return moment(subscriptions[0]?.createdAt).unix();

      case "startDate":
        return moment(subscriptions[0]?.startDate).unix();

      case "cancelledAt":
        return moment(subscriptions[0]?.cancelledAt).unix();

      case "cancellationReason":
        return subscriptions[0]?.cancellationReason;

      default:
        return player[columnId];
    }
  };

  const renderCellExport = (columnId, player) => {
    const subscriptions = player.subscriptions || [];

    switch (columnId) {
      case "name":
        return player.name;

      case "teams":
        return player.activeTeams.map((t) => t.name).join("; ");

      case "medicalIssues":
        return player.medicalIssues;

      case "cost":
        return subscriptions[0]?.price || 0;

      case "totalCost":
        return subscriptions[0]?.totalPrice || 0;

      case "totalPaid":
        return subscriptions[0]?.paid || 0;

      default:
        return renderCell(columnId, player);
    }
  };

  const renderCell = (columnId, player) => {
    const subscriptions = player.subscriptions || [];
    switch (columnId) {
      case "name":
        return (
          <Link
            component={RouterLink}
            color="primary"
            to={`../players/${player._id}`}
            underline="hover"
          >
            {player.name}
          </Link>
        );

      case "teams":
        return (
          <>
            {player.activeTeams.map((t, index) => (
              <Link
                component={RouterLink}
                color="primary"
                to={`../teams/${t._id}`}
                key={t._id}
                underline="hover"
              >
                {t.name + (index + 1 < player.activeTeams.length ? ", " : "")}
              </Link>
            ))}
          </>
        );

      case "dateOfBirth":
        return player.dateOfBirth
          ? moment(player.dateOfBirth).format("DD/MM/YYYY")
          : "";

      case "status":
        if (player.trial) {
          return "ניסיון";
        }
        return player.activeSubscriptions &&
          player.activeSubscriptions.length > 0
          ? "רשום"
          : "לא פעיל";

      case "class":
        return classDictionary[player.class];

      case "gender":
        return gender[player.gender];

      case "alerts":
        return player.alerts.map((alert) => alert.text).join(", ");

      // only work on team level - only team's subscriptions in player.subscriptions
      case "cost":
        return displayPrice(subscriptions[0]?.price || 0);

      case "totalCost":
        return displayPrice(subscriptions[0]?.totalPrice || 0);

      case "totalPaid":
        return displayPrice(subscriptions[0]?.paid || 0);

      case "medicalIssues":
        return player.medicalIssues ? (
          <div style={{ maxWidth: 250, whiteSpace: "nowrap" }}>
            <EllipsisTooltip>{player.medicalIssues}</EllipsisTooltip>
          </div>
        ) : (
          ""
        );

      case "createdAt":
        return displayDate(subscriptions[0]?.createdAt).toString();

      case "startDate":
        return displayDate(subscriptions[0]?.startDate);

      case "cancelledAt":
        return displayDate(subscriptions[0]?.cancelledAt);

      case "cancellationReason":
        return subscriptions[0]?.cancellationReason;

      case "pricingType":
        return subscriptions[0]?.pricing.title;

      default:
        return player[columnId];
    }
  };

  const handleExportTable = () => {
    exportTable(
      props.players,
      props.tableHeaders,
      renderCellExport,
      "csv",
      props.exportFileName
    );
  };

  // when rendered by Players page, there are no subscriptions
  const internalPayment =
    props.players[0] &&
    props.players[0]?.subscriptions &&
    props.players[0].subscriptions[0].product;

  return (
    <>
      {!props.noSubscriptionSum && internalPayment && (
        <PlayerSubscriptionSum players={props.players} />
      )}
      <Table
        title="ספורטאים"
        columns={props.tableHeaders}
        renderCell={renderCell}
        sortValueCell={sortValueCell}
        rows={props.players}
        pagination={props.pagination}
        onExport={handleExportTable}
        isLoading={props.isLoading}
        renderKey={props.renderKey}
      />
    </>
  );
}

export default PlayersTable;
