import React, { useMemo } from "react";
import { Grid, Select } from "@mui/material";

import MenuItem from "../base/MenuItem";
import MultipleSelect from "../base/MultipleSelect";
import AdvancedResourceSelect from "../../containers/Calendar/AdvancedResourceSelect";

const EVENT_TYPE_OPTIONS = [
  { key: "practice", value: "אימון" },
  { key: "awayGame", value: "משחק חוץ" },
  { key: "homeGame", value: "משחק בית" },
  { key: "other", value: "אחר" },
];

export default function ToolbarFilters(props) {
  const resources = useMemo(
    () =>
      props.resourcesProps.resources
        .filter((r) => !r.parent)
        .map((r) => ({
          key: r._id,
          value: r.name,
        })),
    [props.resourcesProps.resources]
  );

  return (
    <>
      {!props.isMobile && (
        <Grid item xs={12} sm={3} lg={2}>
          <Select
            margin="dense"
            value={props.view}
            onChange={(event) => props.onViewChanged(event.target.value)}
            sx={styles.viewSelect}
            fullWidth
          >
            <MenuItem value="day">יום</MenuItem>
            <MenuItem value="week">שבוע</MenuItem>
          </Select>
        </Grid>
      )}
      {!props.isMobile && props.view === "day" && (
        <>
          <Grid item xs={12} sm={3} lg={2}>
            <Select
              margin="dense"
              value={props.resourceView ? "resource" : "regular"}
              onChange={(event) => {
                props.onResourceViewChanged(event.target.value === "resource");
              }}
              sx={styles.viewSelect}
              fullWidth
            >
              <MenuItem value="resource">תצוגת מגרשים</MenuItem>
              <MenuItem value="regular">תצוגה רגילה</MenuItem>
            </Select>
          </Grid>
        </>
      )}
      {!props.isMobile && props.view === "week" && (
        <Grid item xs={12} sm={3} lg={2}>
          <Select
            margin="dense"
            value={props.weekViewType}
            onChange={(event) =>
              props.onWeekViewTypeChanged(event.target.value)
            }
            sx={styles.viewSelect}
            fullWidth
          >
            <MenuItem value="table">טבלה</MenuItem>
            <MenuItem value="regular">תצוגה רגילה</MenuItem>
          </Select>
        </Grid>
      )}
      <Grid item xs={12} sm={3} lg={2}>
        <AdvancedResourceSelect
          options={resources}
          allResources={props.resourcesProps.resources}
          resourceGroups={props.resourcesProps.resourceGroups}
          onChange={(val) => props.resourcesProps.onResourceChanged(val)}
          allTitle="כל המגרשים"
          selectedItems={props.resourcesProps.selectedResources}
          itemTitle="מגרשים"
          id="resourcesSelect"
          label="מגרשים"
          fullWidth
          selectSx={styles.viewSelect}
          isMobile={props.isMobile}
        />
      </Grid>
      <Grid item xs={12} sm={3} lg={2}>
        <MultipleSelect
          options={EVENT_TYPE_OPTIONS}
          onChange={(newValue) =>
            props.eventTypesProps.onEventTypesChanged(newValue)
          }
          allOption
          allTitle="כל סוגי האירועים"
          selectedItems={props.eventTypesProps.selectedEventTypes}
          itemTitle="סוגי אירועים"
          id="eventTypeSelect"
          label="סוגי אירועים"
          fullWidth
          selectSx={styles.viewSelect}
        />
      </Grid>
      <Grid item xs={12} sm={3} lg={2}>
        <MultipleSelect
          options={props.clubCoachesProps.clubCoaches.map((cc) => ({
            key: cc._id,
            value: cc.name,
          }))}
          onChange={(newValue) =>
            props.clubCoachesProps.onClubCoachesChanged(newValue)
          }
          allOption
          allTitle="כל המאמנים"
          selectedItems={props.clubCoachesProps.selectedClubCoaches}
          itemTitle="מאמנים"
          id="coachSelect"
          label="מאמנים"
          fullWidth
          selectSx={styles.viewSelect}
        />
      </Grid>
      <Grid item xs={12} sm={3} lg={2}>
        <MultipleSelect
          options={props.teamsProps.teams.map((cc) => ({
            key: cc._id,
            value: cc.name,
          }))}
          onChange={(newValue) =>
            props.teamsProps.onSelectedTeamChanged(newValue)
          }
          allOption
          allTitle="כל הקבוצות"
          selectedItems={props.teamsProps.selectedTeams}
          itemTitle="קבוצות"
          id="teamSelect"
          label="קבוצות"
          fullWidth
          selectSx={styles.viewSelect}
        />
      </Grid>
    </>
  );
}

const styles = {
  viewSelect: {
    height: 35,
    fontSize: "0.875rem",
  },
};
