import React from "react";
import { Grid, Stack, Typography } from "@mui/material";
import { amber, green } from "@mui/material/colors";
import _ from "lodash";

import axios from "../../utils/axios";
import TlPaper from "../../components/TlPaper";

export default class Alerts extends React.Component {
  state = { alerts: [] };
  componentDidMount = async () => {
    this.setState({ isLoading: true });
    const response = await axios.get(`/clubPlayers/alertsStatus`);

    const alerts = _.orderBy(response.data, "count", "desc");
    this.setState({ alerts, isLoading: false });
  };

  render() {
    return (
      <TlPaper
        title="התראות"
        isLoading={this.state.isLoading}
        titleBackground
        sx={{ height: "100%" }}
      >
        <Grid container spacing={1}>
          {this.state.alerts.map((alert) => {
            const count = alert.count || 0;
            const color = count < 10 ? "good" : count < 50 ? "bad" : "worse";
            return (
              <Grid
                container
                item
                xs={12}
                direction="row"
                alignItems="center"
                key={alert._id}
              >
                <Grid item xs>
                  <Typography sx={styles.title}>{alert.text}</Typography>
                </Grid>
                <Stack alignItems="center">
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "26px",
                      ...styles[color],
                    }}
                  >
                    {count}
                  </Typography>
                  <Typography sx={styles[color]}>ספורטאים</Typography>
                </Stack>
              </Grid>
            );
          })}
        </Grid>
      </TlPaper>
    );
  }
}

const styles = {
  title: {
    color: "#495057",
    fontSize: "22px",
    fontWeight: "500",
  },
  good: {
    color: green[600],
  },
  bad: {
    color: amber[700],
  },
  worse: {
    color: "error.dark",
  },
};
