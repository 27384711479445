import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Link } from "@mui/material";

import { displayDateTime, displayPrice } from "../../utils/helpers";
import { transactionStatuses } from "../../utils/dictionaries";
import ResponsiveContainer from "../../components/ResponsiveContainer";
import Table from "../../components/table/Table";

const columns = [
  { id: "createdAt", label: "זמן העסקה" },
  { id: "transactionId", label: "קבלה" },
  { id: "status", label: "סטטוס" },
  { id: "amount", label: "סכום" },
  { id: "comment", label: "סיבת החזר" },
];

const mobileColumns = [
  { id: "createdAt", label: "זמן העסקה" },
  { id: "transactionId", label: "קבלה" },
  { id: "amount", label: "סכום" },
];

export default function PaymentsTable(props) {
  const renderCell = (columnId, refund) => {
    let amount;
    switch (columnId) {
      case "amount":
        amount = displayPrice(refund.amount);
        return ["cancelled", "declined"].indexOf(refund.status) > -1 ? (
          <s>{amount}</s>
        ) : (
          amount
        );

      case "createdAt":
        return displayDateTime(refund.createdAt);

      case "comment":
        return refund.payment.comment;

      case "status":
        return <strong>{transactionStatuses[refund.status]}</strong>;

      case "transactionId":
        return (
          <Link
            component={RouterLink}
            color="primary"
            to={`../payments/transactions/${refund._id}`}
            underline="hover"
          >
            {refund.receiptNumber}
          </Link>
        );

      default:
        return refund[columnId];
    }
  };

  if (props.refunds.length > 0) {
    const params = {
      noHeader: true,
      columns: columns,
      renderCell: renderCell,
      rows: props.refunds,
      sort: false,
    };
    return (
      <ResponsiveContainer
        desktopContent={<Table {...params} />}
        mobileContent={<Table {...params} columns={mobileColumns} />}
      />
    );
  }

  return <div style={{ marginBottom: 10 }}>לא קיימים החזרים לתשלום זה</div>;
}
