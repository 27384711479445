import React from "react";
import { Paper, TextField, Autocomplete } from "@mui/material";

function RtlPaper(props) {
  return (
    <Paper {...props} dir="rtl">
      {props.children}
    </Paper>
  );
}

export default function AutoComplete(props) {
  return (
    <Autocomplete
      options={props.options}
      getOptionLabel={(option) => {
        return props.mode === "objects" ? option.value || "" : option;
      }}
      noOptionsText="לא נמצאו תוצאות"
      PaperComponent={RtlPaper}
      renderInput={(params) => (
        <TextField
          variant="standard"
          margin="dense"
          {...params}
          label={props.label}
          error={props.error !== undefined}
          helperText={props.error || ""}
          {...props.textFieldParams}
        />
      )}
      onChange={(event, newValue) => props.onChange(newValue)}
      value={props.value}
      freeSolo={props.freeSolo}
      fullWidth={props.fullWidth}
      disabled={props.disabled}
      onInputChange={(e, value) =>
        props.onInputChange && props.onInputChange(value)
      }
    />
  );
}
