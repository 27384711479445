import React from "react";
import { IconButton, Tooltip } from "@mui/material";

export default function IconButtonTooltip(props) {
  return (
    <Tooltip title={props.title} {...props.tooltipProps}>
      <span>
        <IconButton
          onClick={props.onClick}
          disabled={props.disabled}
          size="large"
          {...props.iconButtonProps}
        >
          {props.children}
        </IconButton>
      </span>
    </Tooltip>
  );
}
