import React, { useState } from "react";
import { Divider } from "@mui/material";

import InfoRow from "../../components/InfoRows/InfoRow";
import EditParentsDialog from "./EditParentsDialog";
import EditFab from "../../components/EditFab";
import TlPaper from "../../components/TlPaper";

export default function Parents(props) {
  const { player, parent } = props;
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const parent1 = parent
    ? parent
    : {
        name: player.trialInfo.parentName,
        phone: player.trialInfo.parentPhone,
      };

  return (
    <TlPaper title="הורים" isLoading={props.isLoading} titleBackground>
      {props.hasHrPlayerParentAdminPermission &&
        props.editable &&
        player.status !== "trial" && (
          <>
            <EditFab
              tooltip="עדכון פרטי הורים"
              onClick={() => setIsDialogOpen(true)}
            />
            <EditParentsDialog
              player={props.player}
              onClose={() => setIsDialogOpen(false)}
              onComplete={() => {
                setIsDialogOpen(false);
                props.onPlayerChanged();
              }}
              open={isDialogOpen}
            />
          </>
        )}
      <InfoRow title="הורה א" value={parent1.name || "-"} />
      <InfoRow title="טלפון" value={parent1.phone || "-"} />
      <InfoRow title="כתובת" value={parent1 ? parent1.address : "-"} />
      <InfoRow title="אימייל" value={parent1.email || "-"} />
      {parent1.notIsraeli ? (
        <InfoRow title="מספר דרכון" value={parent1.passportNumber || "-"} />
      ) : (
        <InfoRow title="ת.ז" value={parent1.id || "-"} />
      )}

      {parent1.secondParentName && (
        <>
          <Divider style={{ marginTop: 10, marginBottom: 10 }} />
          <InfoRow title="הורה ב" value={parent1.secondParentName} />
          <InfoRow title="טלפון" value={parent1.secondParentPhone} />
        </>
      )}
    </TlPaper>
  );
}
