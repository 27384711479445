import React from "react";
import { IconButton, Tooltip } from "@mui/material";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import AddIcon from "@mui/icons-material/Add";
import SettingsIcon from "@mui/icons-material/Settings";

const types = {
  export: { title: "הורדה", icon: ArrowDownwardIcon },
  add: { title: "הוספה", icon: AddIcon },
  settings: { title: "הגדרות", icon: SettingsIcon },
};

export default function PaperHeaderButton(props) {
  const type = types[props.type];
  return (
    <Tooltip title={props.title || type.title}>
      <span>
        <IconButton onClick={props.onClick} disabled={props.disabled} size="large">
          <type.icon />
        </IconButton>
      </span>
    </Tooltip>
  );
}
