import React from "react";
import { Grid, TextField } from "@mui/material";

import AutoComplete from "../base/AutoComplete";
import { banks, dicToArray } from "../../utils/dictionaries";

const banksArray = dicToArray(banks);

export default function WireTransferField(props) {
  const handleChange = (updates) => {
    props.onChange({
      wireTransfer: { ...props.state.wireTransfer, ...updates },
    });
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <AutoComplete
          options={banksArray}
          mode="objects"
          value={props.state.wireTransfer.bank}
          label="בנק"
          onChange={(bank) => {
            handleChange({ bank });
          }}
          error={props.state.errors.wireTransferBank}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          variant="standard"
          fullWidth
          label="סניף"
          value={props.state.wireTransfer["bankBranch"]}
          onChange={(e) => handleChange({ bankBranch: e.target.value })}
          error={props.state.errors.wireTransferBankBranch !== undefined}
          helperText={props.state.errors.wireTransferBankBranch}
          margin="dense"
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          variant="standard"
          fullWidth
          label="חשבון"
          value={props.state.wireTransfer["bankAccount"]}
          onChange={(e) => handleChange({ bankAccount: e.target.value })}
          error={props.state.errors.wireTransferBankAccount !== undefined}
          helperText={props.state.errors.wireTransferBankAccount}
          margin="dense"
        />
      </Grid>
    </Grid>
  );
}
