import React from "react";

export default function FlexCenter({ children, ...props }) {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: props.direction || "row",
      }}
    >
      {children}
    </div>
  );
}
