import React from "react";
import { Typography, List, ListItem, ListItemText } from "@mui/material";

import PartialPaymentDialog from "../../components/store/PartialPaymentDialog";
import AbsoluteCenter from "../../components/AbsoluteCenter";
import { displayPrice } from "../../utils/helpers";
import MerchandiseReviewItem from "./MerchandiseReviewItem";
import SubscriptionReviewItem from "./SubscriptionReviewItem";
import StepTitle from "./StepTitle";
import Paper2Sx from "../../components/Paper2Sx";

export default function Review(props) {
  const [isPartialPaymentDialogOpen, setIsPartialPaymentDialogOpen] =
    React.useState(false);

  const renderNoItems = () => (
    <AbsoluteCenter>
      <Typography sx={styles.noItemsContainer}>סל הקניות שלך ריק</Typography>
    </AbsoluteCenter>
  );
  const merchandiseEntries = Object.entries(props.cart.merchandise);

  const renderContent = () => (
    <>
      <List sx={{ p: 0 }}>
        {props.cart.subscription && (
          <SubscriptionReviewItem
            cart={props.cart}
            cartItem={props.cart.subscription}
            subscriptionForm={props.subscription}
            player={props.states.player}
            onOpenPartialPayment={() => setIsPartialPaymentDialogOpen(true)}
          />
        )}

        {merchandiseEntries.map(([key, item]) => (
          <MerchandiseReviewItem
            key={key}
            item={item}
            cart={props.cart}
            onQuantityChange={props.onProductChange}
          />
        ))}

        {props.hasPayments && (
          <ListItem sx={styles.listItem}>
            <ListItemText primary="" />
            <Typography variant="subtitle1" sx={styles.total}>
              <span style={{ marginLeft: "50px" }}>סה״כ לתשלום:</span>
              {displayPrice(props.cart.totals.totalPaymentAmounts)}
            </Typography>
          </ListItem>
        )}
      </List>
      <Paper2Sx externalTitle="פרטי ההורה" sx={styles.parentsPaper}>
        {props.guest ? (
          <>
            <Typography gutterBottom>
              {props.states.parent.guestName}
            </Typography>
            <Typography gutterBottom>
              {props.states.parent.guestEmail}
            </Typography>
            <Typography gutterBottom>
              {props.states.parent.guestPhone}
            </Typography>
          </>
        ) : (
          <>
            <Typography gutterBottom>
              {props.states.parent.firstName} {props.states.parent.lastName}
            </Typography>
            {props.states.parent.address && (
              <Typography gutterBottom>
                {props.states.parent.address} {props.states.parent.houseNumber}{" "}
                {props.states.parent.city}
              </Typography>
            )}
            <Typography gutterBottom noWrap>
              {props.states.parent.email}
            </Typography>
            <Typography gutterBottom noWrap>
              {props.states.parent.phone}
            </Typography>
          </>
        )}
      </Paper2Sx>
    </>
  );

  return (
    <>
      <StepTitle title="סל קניות" purchaseType={props.purchaseType} />

      {props.cart.subscription || merchandiseEntries.length > 0
        ? renderContent()
        : renderNoItems()}

      <PartialPaymentDialog
        open={isPartialPaymentDialogOpen}
        onClose={() => setIsPartialPaymentDialogOpen(false)}
        onComplete={(amount) => {
          props.onPartialPaymentAmountChanged(amount);
          setIsPartialPaymentDialogOpen(false);
        }}
        amount={props.cart.totals.paymentAmounts.subscriptionPlusFees}
        minAmount={0}
        maxAmount={props.cart.totals.costs.subscriptionPlusFees}
      />
    </>
  );
}

const styles = {
  listItem: {
    marginTop: "20px",
    backgroundColor: "#247b9f",
    color: "#FFF",
    borderRadius: "5px",
  },
  total: {
    fontWeight: "700",
    marginRight: "50px",
  },
  title: {
    marginTop: "2px",
  },
  noItemsContainer: {
    height: 60,
    width: 400,
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    fontSize: 18,
  },
  parentsPaper: {
    marginTop: "70px",
  },
};
