import React, { useState } from "react";
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  IconButton,
  ListItemButton,
  Box,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import moment from "moment";
import { styled } from "@mui/material/styles";

import IconButtonTooltip from "../base/IconButtonTooltip";
import { displayPrice } from "../../utils/helpers";
import PurchaseProductDialog from "./PurchaseProductDialog";
import SnackbarNg from "../base/SnackbarNg";
import { AdvancedImage } from "@cloudinary/react";
import { cloudinaryImage } from "../../utils/cloudinary";

const ProductImage = styled(AdvancedImage)(({ theme }) => ({
  width: 70,
  height: 70,
  marginRight: 5,
  [theme.breakpoints.up("sm")]: {
    width: 90,
    height: 90,
    marginRight: 20,
  },
  border: "1px solid #F0F0F0",
  borderRadius: "5px",
}));

export default function ProductsPurchase(props) {
  const [currentProduct, setCurrentProduct] = useState(null);
  const [snackbarDetails, setSnackbarDetails] = useState({
    isOpen: false,
    key: undefined,
    status: null,
  });

  const openProductDialog = (product) => () => {
    setCurrentProduct(product);
  };

  const handleChange = (quantity, price, playerId, optionId) => {
    const player = props.players.find((p) => p._id === playerId);

    props.onProductChange(currentProduct, price, player, optionId, quantity);

    setSnackbarDetails({ isOpen: true, key: moment().format() });
    setCurrentProduct(null);
  };

  return (
    <>
      <PurchaseProductDialog
        isOpen={currentProduct !== null}
        product={currentProduct}
        onClose={() => setCurrentProduct(null)}
        players={props.players}
        onAddItem={handleChange}
      />
      <SnackbarNg
        snackKey={snackbarDetails.key}
        open={snackbarDetails.isOpen}
        onClose={() => setSnackbarDetails({ isOpen: false, key: null })}
        message={"הפריטים הוספו בהצלחה"}
        anchor={{ vertical: "bottom", horizontal: "center" }}
      />
      <List style={{ padding: 0 }}>
        {props.merchandise.map((product) => {
          const img = cloudinaryImage(product.imagePublicId, {
            version: product.imageVersion,
            size: "thumbnail",
          });
          return (
            <ListItem
              disablePadding
              key={product._id}
              sx={styles.listItem}
              onClick={openProductDialog(product)}
            >
              <ListItemButton sx={{ p: { xs: "3px", sm: 1 } }}>
                <ListItemIcon>
                  {product.otp ? (
                    <Box
                      sx={{ ...styles.productImage, ...styles.optProductImage }}
                    >
                      פריט חד פעמי
                    </Box>
                  ) : (
                    <ProductImage cldImg={img} />
                  )}
                </ListItemIcon>

                <ListItemText
                  primary={product.title}
                  secondary={
                    // div can't be under p so we need the warpper span work around
                    <span style={{ display: "flex", flexDirection: "column" }}>
                      <Typography component="span" variant="body2">
                        {product.description || ""}
                      </Typography>
                      <span>{displayPrice(product.price)}</span>
                    </span>
                  }
                />
                {product.otp ? (
                  <div>
                    <IconButtonTooltip
                      title="עריכת פרטי הפריט"
                      iconButtonProps={{ size: "small" }}
                      onClick={() => props.onStartEditingOTP(product)}
                    >
                      <EditIcon />
                    </IconButtonTooltip>
                    <IconButtonTooltip
                      title="מחיקת הפריט"
                      iconButtonProps={{ size: "small" }}
                      onClick={() => {
                        props.onDeleteOTP(product);
                      }}
                    >
                      <DeleteIcon />
                    </IconButtonTooltip>
                    <IconButton
                      onClick={openProductDialog(product)}
                      size="large"
                    >
                      <AddIcon />
                    </IconButton>
                  </div>
                ) : (
                  <IconButton onClick={openProductDialog(product)} size="large">
                    <AddIcon />
                  </IconButton>
                )}
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    </>
  );
}

const styles = {
  productImage: {
    width: { xs: 70, sm: 90 },
    height: { xs: 70, sm: 90 },
    mr: { xs: 1, sm: "20px" },
    border: "1px solid #F0F0F0",
    borderRadius: "5px",
  },
  optProductImage: {
    textAlign: "center",
    display: "flex",
    alignItems: "center",
  },
  listItem: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    border: "1px solid #EEE",
    borderRadius: "5px",
    marginBottom: "10px",
  },
};
