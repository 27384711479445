import React, { useEffect, useState } from "react";
import { DialogActions, DialogTitle, DialogContent } from "@mui/material";
import moment from "moment";

import Dialog from "../../../components/Dialog";
import DatePickerAdvancedPopupContent from "../../../components/filters/DatePickerAdvancedPopupContent";
import ButtonSx from "../../../components/base/ButtonSx";

export default function StandaloneDatesPickers(props) {
  const [fromDate, setFromDate] = useState(moment());
  const [toDate, setToDate] = useState(moment());

  useEffect(() => {
    if (props.isOpen) {
      setFromDate(moment(props.dates.from));
      setToDate(moment(props.dates.to));
    }
  }, [props.isOpen]);

  const handleClose = (e) => {
    e.stopPropagation();
    props.onClose();
  };

  const handleDateChange = (type) => (date) => {
    if (type === "from") {
      setFromDate(date);
    } else {
      setToDate(date);
    }
  };

  const handleComplete = (dates) => {
    props.onComplete({ from: dates.from || fromDate, to: dates.to || toDate });
  };

  return (
    <Dialog open={props.isOpen} closeButton onClose={handleClose} maxWidth="lg">
      <DialogTitle>בחירת תאריכים לחנות</DialogTitle>
      <DialogContent dividers style={{ padding: 0 }}>
        <DatePickerAdvancedPopupContent
          closeButton={false}
          dates={{ from: fromDate, to: toDate }}
          type="month"
          isSingleMode={false}
          onPresetSelected={handleComplete}
          onDateChanged={handleDateChange}
        />
      </DialogContent>
      <DialogActions>
        <ButtonSx onClick={props.onClose}>ביטול</ButtonSx>
        <ButtonSx onClick={handleComplete}>אישור</ButtonSx>
      </DialogActions>
    </Dialog>
  );
}
