import React from "react";
import moment from "moment";
import ReactGA from "react-ga4";
import _ from "lodash";

import axios from "../../../utils/axios";
import FiltersBar from "../../../components/filters/FiltersBar";
import {
  prepareFilters,
  getFiltersFromUrl,
} from "../../../components/filters/prepareFilters";
import MerchandiseTable from "./MerchandiseTable";
import { purchaseProductStatuses } from "../../../utils/dictionaries";
import withRouter from "../../../components/routing/withRouter";
import MerchandiseUpdateStatusDialog from "./MerchandiseUpdateStatusDialog";
import SnackbarNg from "../../../components/base/SnackbarNg";
import TlPaper from "../../../components/TlPaper";

const baseFiltersStructure = (merchandise) => [
  { type: "date" },
  {
    name: "status",
    type: "multiSelect",
    options: Object.entries(purchaseProductStatuses).map(([key, value]) => ({
      key,
      value,
    })),
    emptyLabel: "כל הסטטוסים",
    label: "סטטוס",
    mustSelectAtLeastOne: true,
  },
  {
    name: "productId",
    type: "select",
    options: merchandise || {},
    emptyLabel: "כל המוצרים",
    label: "מוצר",
  },
];

class Merchandise extends React.Component {
  state = {
    products: [],
    isLoading: true,
    filters: {
      status: { pending: true, received: true, returned: true },
      productId: "all",
      date: { from: moment().startOf("day"), to: moment().endOf("day") },
    },
    filtersStructure: baseFiltersStructure(),
    isUpdateDialogOpen: false,
    isUpdating: false,
    snackbarDetails: { isOpen: false },
  };

  componentDidMount = async () => {
    ReactGA.send({
      hitType: "pageview",
      page: "/purchases/merchandise",
      title: "Merchandise Report (new)",
    });

    const filters = getFiltersFromUrl(
      this.props.location,
      this.state.filters,
      this.state.filtersStructure
    );
    this.setState({ filters }, () => {
      this.loadData(false);
    });
  };

  handleFilterChange = (filterName, value) => {
    this.setState({
      filters: {
        ...this.state.filters,
        [filterName]: value,
      },
    });
  };

  loadData = async (updateUrl = true) => {
    const filters = prepareFilters(
      this.state.filters,
      this.state.filtersStructure
    );
    const queryFilters = [...filters];
    this.setState({ isLoading: true });
    let response = await axios.get(
      `/store/purchases/products?${queryFilters.join("&")}`
    );
    const products = response.data;

    response = await axios.get(
      "/store/products?merchandiseAndDeliverableFees=true"
    );
    const merchandise = _.sortBy(response.data, "title").reduce(
      (dic, current) => {
        dic[current._id] = current.title;
        return dic;
      },
      {}
    );
    merchandise.otp = "מוצר חד פעמי";

    this.setState({
      products,
      merchandise,
      filtersStructure: baseFiltersStructure(merchandise),
      isLoading: false,
    });

    if (updateUrl) {
      this.props.navigate(`?${filters.join("&")}`, { replace: true });
    }
  };

  handleItemSelectToggle = (item) => () => {
    this.setState((prev) => {
      return {
        products: prev.products.map((datum) => {
          return datum._id === item._id || item === "all"
            ? { ...datum, checked: !datum.checked }
            : { ...datum };
        }),
      };
    });
  };

  handleUpdateStatus = async () => {
    const checkedProducts = this.state.products.filter((p) => p.checked);
    const groupedByPurchase = _.groupBy(checkedProducts, "purchaseId");
    const items = Object.entries(groupedByPurchase).map(
      ([purchaseId, products]) => ({
        purchaseId: purchaseId,
        productIds: products.map((p) => p._id),
      })
    );

    this.setState({ isUpdating: true });

    try {
      await axios.patch(`/store/purchases/products/setAsReceived`, { items });
      this.setState((prev) => {
        const products = [...prev.products];
        products.forEach((p) => {
          if (p.checked) {
            p.status = "received";
            p.checked = false;
          }
        });
        return {
          snackbarDetails: {
            isOpen: true,
            key: moment().format(),
            success: true,
          },
          products,
        };
      });
    } catch (error) {
      this.setState({
        snackbarDetails: {
          isOpen: true,
          key: moment().format(),
          success: false,
        },
      });
    }
    this.setState({ isUpdating: false, isUpdateDialogOpen: false });
  };

  toggleUpdateDialog = async () => {
    this.setState((prev) => ({ isUpdateDialogOpen: !prev.isUpdateDialogOpen }));
  };

  render() {
    return (
      <TlPaper title="רכישת מוצרים" fullPage titleBackground>
        <FiltersBar
          filters={this.state.filtersStructure}
          onFilterChanged={this.handleFilterChange}
          values={this.state.filters}
          onSearch={this.loadData}
          isSearching={this.state.isLoading}
        />
        <MerchandiseTable
          title="מוצרים"
          products={this.state.products}
          defaultSortBy={4}
          isLoading={this.state.isLoading}
          onItemCheckToggle={this.handleItemSelectToggle}
          onShowUpdateDialog={this.toggleUpdateDialog}
        />
        <MerchandiseUpdateStatusDialog
          open={this.state.isUpdateDialogOpen}
          onClose={this.toggleUpdateDialog}
          onUpdate={this.handleUpdateStatus}
          isLoading={this.state.isUpdating}
        />
        <SnackbarNg
          snackKey={this.state.snackbarDetails.key}
          open={this.state.snackbarDetails.isOpen}
          onClose={() =>
            this.setState({ snackbarDetails: { isOpen: false, key: null } })
          }
          severity={this.state.snackbarDetails.success ? "success" : "error"}
          message={
            <div>
              {this.state.snackbarDetails.success
                ? "העדכון בוצע בהצלחה"
                : "אירעה שגיאה"}
            </div>
          }
        />
      </TlPaper>
    );
  }
}

export default withRouter(Merchandise);
