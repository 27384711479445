import React, { useEffect, useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { connect } from "react-redux";
import _ from "lodash";

import * as Actions from "../store/actions";
import SnackbarNg from "../components/base/SnackbarNg";

function Clubs(props) {
  const params = useParams();
  const navigate = useNavigate();
  const [snackbar, setSnackbar] = useState({});

  useEffect(() => {
    const club = props.clubs.find(
      (club) => club.internalName === params.clubId
    );
    if (club) {
      // when changing club through the club select menu, changeClub() is called from that page and there is no need for the snack (the user actively changed the club)
      if (club._id !== props.selectedClub?._id) {
        // changing club by clicking on a link (superclub => subClub)
        if (props.selectedClub) {
          setSnackbar({
            key: _.uniqueId(),
            message: (
              <span>
                הינכם מועברים <b>ל{club.clubConfig.general.name}</b>
              </span>
            ),
          });
        }
        props.changeClub(club, props.selectedClub !== null);
      }
    } else {
      // club's internal name not found (/clubs/internal_name_not_found)
      navigate("/");
    }
  }, [params.clubId]);

  return (
    <>
      <Outlet />
      {/* this component renders outside the layout and doesn't have rtl */}
      <SnackbarNg
        snackKey={snackbar.key}
        open={snackbar.key !== undefined}
        message={snackbar.message}
        severity="info"
        onClose={() => setSnackbar({})}
        anchor={{
          vertical: "bottom",
          horizontal: "left",
        }}
      />
    </>
  );
}

function mapStateToProps(state) {
  return {
    selectedClub: state.auth.selectedClub,
    clubs: state.auth.clubs,
    gentleClubChange: state.auth.gentleClubChange,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changeClub: (club, gentle) =>
      dispatch(Actions.auth.changeClub(club, gentle)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Clubs);
