import React from "react";
import _ from "lodash";
import { Box, Grid, Typography } from "@mui/material";

import TlPaper from "../../../components/TlPaper";
import { displayPrice } from "../../../utils/helpers";

const styles = {
  paper: {
    borderBottomWidth: "3px",
    borderBottomStyle: "solid",
    height: "100%",
  },
  failure: {
    borderBottomColor: "error.main",
  },
  success: {
    borderBottomColor: "success.main",
  },
  cancelled: {
    borderBottomColor: "warning.main",
  },
  all: {
    borderBottomColor: "info.main",
  },
  paperBody: {
    padding: 1,
    pl: 2,
    pr: 2,
  },
};

function Sum({ title, type, transactions }) {
  const total = _.sumBy(transactions, "amount");
  return (
    <TlPaper
      sx={{ ...styles[type], ...styles.paper }}
      bodySx={styles.paperBody}
      noMinHeight
    >
      <Typography variant="h6" color="textSecondary" gutterBottom>
        {title}
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h5">{displayPrice(total)}</Typography>
        <Box sx={{ textAlign: "center" }}>{transactions.length} עסקאות</Box>
      </Box>
    </TlPaper>
  );
}

export default function TransactionsSum(props) {
  const approvedTransactions = props.transactions.filter(
    (t) => t.status === "approved" || t.status === "settled"
  );

  const declinedTransactions = props.transactions.filter(
    (t) => t.status === "declined"
  );

  const voidedTransactions = props.transactions.filter(
    (t) => t.status === "cancelled"
  );
  return (
    <Grid container spacing={1}>
      <Grid xs item>
        <Sum type="all" title="כל העסקאות" transactions={props.transactions} />
      </Grid>
      <Grid xs item>
        <Sum
          type="success"
          title="עסקאות מאושרות"
          transactions={approvedTransactions}
        />
      </Grid>
      <Grid xs item>
        <Sum
          type="cancelled"
          title="עסקאות מבוטלות"
          transactions={voidedTransactions}
        />
      </Grid>
      <Grid xs item>
        <Sum
          type="failure"
          title="עסקאות שנכשלו"
          transactions={declinedTransactions}
        />
      </Grid>
    </Grid>
  );
}
