import React from "react";

import moment from "moment";
import * as dates from "date-arithmetic";
import { Navigate } from "react-big-calendar";
import Resources from "react-big-calendar/lib/utils/Resources";
import memoize from "memoize-one";
import { styled } from "@mui/material/styles";
import * as dates2 from "react-big-calendar/lib/utils/dates";
import { inRange } from "react-big-calendar/lib/utils/eventLevels";
import { isSelected } from "react-big-calendar/lib/utils/selection";

const Td = styled("td")({
  border: "1px solid #DDD",
  padding: 5,
  height: 35,
});

class CustomAgenda extends React.Component {
  memoizedResources = memoize((resources, accessors) =>
    Resources(resources, accessors)
  );

  renderDay = (day, events, dayKey) => {
    let { selected, getters, accessors, localizer } = this.props;

    events = events.filter((e) =>
      inRange(
        e,
        dates2.startOf(day, "day"),
        dates2.endOf(day, "day"),
        accessors,
        localizer
      )
    );

    events.sort(
      (e1, e2) =>
        moment(e1.start).toDate().getTime() -
        moment(e2.start).toDate().getTime()
    );

    events.sort();

    const rows = [];

    events.forEach((event, idx) => {
      let end = accessors.end(event);
      let start = accessors.start(event);

      const userProps = getters.eventProp(
        event,
        start,
        end,
        isSelected(event, selected)
      );

      rows.push(
        <tr key={dayKey + "_" + idx} style={userProps.style}>
          <Td>{localizer.format(event.start, "HH:mm")}</Td>
          <Td>{event.title}</Td>
          <Td>{event.resourceDescription}</Td>
        </tr>
      );
    }, []);
    const ph = 20 - rows.length;

    for (let index = 0; index < ph; index++) {
      rows.push(
        <tr key={`placeholder${index}`}>
          <Td />
          <Td />
          <Td />
        </tr>
      );
    }

    return rows;
  };

  render() {
    let { date, events, localizer } = this.props;
    const currentRange = CustomAgenda.range(date);

    return (
      <>
        <table
          style={{ display: "block", whiteSpace: "nowrap", overflowX: "auto" }}
        >
          <tbody style={{ position: "sticky" }}>
            <tr>
              {currentRange.map((day, idx) => (
                <td key={idx} style={{ minWidth: 400, verticalAlign: "top" }}>
                  <table style={{ borderCollapse: "collapse" }}>
                    <thead>
                      <tr>
                        <th
                          colSpan={3}
                          style={{ width: 700, border: "1px solid #DDD" }}
                        >
                          {localizer.format(day, "ddd L")}
                        </th>
                      </tr>
                    </thead>
                    <tbody ref={this.tbodyRef} style={{}}>
                      {this.renderDay(day, events, idx)}
                    </tbody>
                  </table>
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      </>
    );
  }
}

CustomAgenda.range = (date) => {
  const start = moment(date).startOf("week").toDate();
  const end = moment(date).endOf("week").toDate();
  let current = start;
  let range = [];

  while (dates.lte(current, end, "day")) {
    range.push(current);
    current = dates.add(current, 1, "day");
  }

  return range;
};

CustomAgenda.navigate = (date, action) => {
  switch (action) {
    case Navigate.PREVIOUS:
      return dates.add(date, -7, "day");

    case Navigate.NEXT:
      return dates.add(date, 7, "day");

    default:
      return date;
  }
};

CustomAgenda.title = (date) => {
  const range = CustomAgenda.range(date);
  return `${moment(range[0]).format("L")} - ${moment(
    range[range.length - 1]
  ).format("L")}`;
};

export default CustomAgenda;
