import React from "react";
import moment from "moment";
import ReactGA from "react-ga4";

import axios from "../../utils/axios";
import FiltersBar from "../../components/filters/FiltersBar";
import {
  prepareFilters,
  getFiltersFromUrl,
} from "../../components/filters/prepareFilters";
import IncomeTable from "./IncomeTable";
import withRouter from "../../components/routing/withRouter";
import { transactionKinds } from "../../utils/dictionaries";
import TlPaper from "../../components/TlPaper";

const filtersStructure = [
  { type: "date" },
  {
    name: "dateFilterType",
    type: "select",
    options: {
      transaction: "לפי תאריך ביצוע העסקה",
      payment: "לפי תאריך הפירעון",
    },
    label: "סוג החיפוש",
  },
  {
    name: "transactionType",
    type: "select",
    options: transactionKinds,
    label: "סוג עסקה",
    emptyLabel: "כל הסוגים",
    includeAll: true,
  },
];

class IncomeReport extends React.Component {
  state = {
    transactions: [],
    filters: {
      date: { from: moment().startOf("month"), to: moment().endOf("month") },
      dateFilterType: "transaction",
      transactionType: "all",
    },
    isLoading: false,
  };

  componentDidMount = async () => {
    ReactGA.send({
      hitType: "pageview",
      page: "/reports/income",
      title: "Income Report",
    });

    const filters = getFiltersFromUrl(
      this.props.location,
      this.state.filters,
      filtersStructure
    );
    await this.setState({ filters });
    this.loadData(false);
  };

  loadData = async (updateUrl = true) => {
    let filters = prepareFilters(this.state.filters, filtersStructure);
    this.setState({ isLoading: true });
    const res = await axios.get(
      `/reports/payments/income?${filters.join("&")}`
    );
    this.setState({ transactions: res.data, isLoading: false });

    if (updateUrl) {
      filters = prepareFilters(this.state.filters, filtersStructure);
      this.props.navigate(`?${filters.join("&")}`, { replace: true });
    }
  };

  handleExport = async () => {
    let filters = prepareFilters(this.state.filters, filtersStructure);
    const res = await axios.get(
      `/reports/payments/income?${filters.join("&")}`,
      {
        headers: {
          Accept: "text/csv",
        },
      }
    );

    return res.data;
  };

  handleFilterChange = (filterName, value) => {
    this.setState({ filters: { ...this.state.filters, [filterName]: value } });
  };

  render() {
    return (
      <TlPaper title="פירעון תשלומים" fullPage titleBackground>
        <FiltersBar
          filters={filtersStructure}
          onFilterChanged={this.handleFilterChange}
          values={this.state.filters}
          onSearch={this.loadData}
          isSearching={this.state.isLoading}
        />
        <div style={{ margin: 10 }}>
          <IncomeTable
            transactions={this.state.transactions}
            isLoading={this.state.isLoading}
            onExport={this.handleExport}
          />
        </div>
      </TlPaper>
    );
  }
}

export default withRouter(IncomeReport);
