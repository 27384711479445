import React from "react";
import { Box } from "@mui/material";

export default function AbsoluteCenter({ children }) {
  return (
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: 10,
      }}
    >
      {children}
    </Box>
  );
}
