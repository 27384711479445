import React, { useState, useEffect } from "react";
import {
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  FormControlLabel,
  Radio,
  FormControl,
  FormHelperText,
  RadioGroup,
  FormLabel,
  useMediaQuery,
} from "@mui/material";
import validator from "validator";
import moment from "moment";

import MenuItem from "../../components/base/MenuItem";
import axios from "../../utils/axios";
import RTL from "../../layout/Rtl";
import { classes as playerClasses } from "../../utils/dictionaries";
import FormTextField from "../../components/base/FormTextField";
import {
  generateErrorMessage,
  normalizeServerValidationErrors,
  validateFormNg,
} from "../../lib/formHelpers";
import FormDateField from "../../components/base/FormDateField";
import ButtonSx from "../../components/base/ButtonSx";
import { useMemo } from "react";

const defaultForm = () => ({
  firstName: "",
  lastName: "",
  phone: "",
  class: "",
  dobYear: null,
  dobMonth: null,
  dobDay: null,
  id: "",
  gender: "",
  hasMedicalIssues: null,
  medicalIssues: "",
  school: "",
});

export default function EditPlayerDialog(props) {
  const [instantValidate, setInstantValidate] = useState(false);
  const [form, setForm] = useState(defaultForm());
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const isSmUp = useMediaQuery((theme) => theme.breakpoints.up("sm"), {
    noSsr: true,
  });

  useEffect(() => {
    const player = props.player;
    const dob = moment(player.dateOfBirth);
    setForm({
      firstName: player.firstName,
      lastName: player.lastName,
      phone: player.phone,
      class: player.class,
      dobYear: dob.year(),
      dobMonth: dob.month() + 1,
      dobDay: dob.date(),
      id: player.id || "",
      passportNumber: player.passportNumber || "",
      gender: player.gender,
      hasMedicalIssues: player.hasMedicalIssues,
      medicalIssues: player.medicalIssues || "",
      school: player.school || "",
    });
    setErrors({});
    setInstantValidate(false);
  }, [props.open, props.player]);

  const validateDob = () => {
    const dob = moment({
      year: form.dobYear,
      month: form.dobMonth - 1,
      day: form.dobDay,
    });

    return dob.isValid();
  };

  const formSettings = useMemo(
    () => ({
      firstName: {
        validator: (firstName) => firstName.length > 1,
        errorMessages: { invalid: "שדה לא תקין" },
      },
      lastName: {
        validator: (lastName) => lastName.length > 1,
        errorMessages: { invalid: "שדה לא תקין" },
      },
      phone: {
        validator: (phone) =>
          phone === undefined ||
          phone === "" ||
          validator.isMobilePhone(phone, "he-IL"),
      },
      class: {
        validator: (val) => val !== "",
      },
      dateOfBirth: {
        validator: validateDob,
      },
      id: {
        validator: (val) => validator.isIdentityCard(val, "he-IL"),
        required: !props.player?.notIsraeli,
      },
      hasMedicalIssues: {
        validator: (val) => val === true || val === false,
      },
      medicalIssues: {
        required: form.hasMedicalIssues === true,
        validator: (val) => {
          return val.length > 0;
        },
      },
    }),
    [form, props.player]
  );

  const handleFieldChange = async (updates) => {
    if (updates.hasMedicalIssues === false) {
      updates.medicalIssues = "";
    }

    setForm({ ...form, ...updates });

    if (instantValidate) {
      let { formErrors } = validateFormNg(form, formSettings);
      setErrors(formErrors);
    }
  };

  const handleSubmit = async () => {
    let { valid, formErrors } = validateFormNg(form, formSettings);
    setErrors(formErrors);

    if (valid) {
      setIsLoading(true);

      const data = {
        dateOfBirth: `${form.dobYear}-${form.dobMonth
          .toString()
          .padStart(2, "0")}-${form.dobDay.toString().padStart(2, "0")}`,
        firstName: form.firstName,
        lastName: form.lastName,
        class: form.class,
        phone: form.phone,
        gender: form.gender,
        hasMedicalIssues: form.hasMedicalIssues,
        medicalIssues: form.medicalIssues,
        ...(!props.player.id && form.id ? { id: form.id } : {}),
        school: form.school,
      };

      try {
        await axios.patch(`/clubPlayers/ng/${props.player._id}`, data);
        setIsLoading(false);
        props.onComplete();
      } catch (error) {
        console.log(error);
        setIsLoading(false);
        setErrors(normalizeServerValidationErrors(error.response.data.errors));
      }
    }
  };

  const defaultTextFieldParams = {
    form,
    onChange: handleFieldChange,
    errors,
    formSettings,
  };

  return (
    <Dialog
      dir="rtl"
      fullWidth={true}
      maxWidth="sm"
      onClose={props.onClose}
      open={props.open}
      fullScreen={!isSmUp}
    >
      <DialogTitle id="form-dialog-title">עדכון פרטי ספורטאי</DialogTitle>
      <RTL>
        <DialogContent dividers>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              <FormTextField
                label="שם פרטי"
                field="firstName"
                {...defaultTextFieldParams}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormTextField
                label="שם משפחה"
                field="lastName"
                {...defaultTextFieldParams}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormTextField
                label="טלפון"
                field="phone"
                {...defaultTextFieldParams}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormTextField
                label="בית ספר"
                field="school"
                {...defaultTextFieldParams}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="standard"
                value={form.class}
                onChange={(e) => handleFieldChange({ class: e.target.value })}
                error={errors.class !== undefined}
                helperText={generateErrorMessage("class", errors)}
                select
                required
                id="class"
                name="class"
                label="כיתה"
                fullWidth
                margin="dense"
              >
                {Object.entries(playerClasses).map(([id, title]) => (
                  <MenuItem key={id} value={id}>
                    {title}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormDateField
                onChange={handleFieldChange}
                year={form.dobYear}
                month={form.dobMonth}
                day={form.dobDay}
                error={
                  errors.dateOfBirth &&
                  generateErrorMessage("dateOfBirth", errors)
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              {props.player.notIsraeli ? (
                <FormTextField
                  label="מספר דרכון"
                  field="passportNumber"
                  {...defaultTextFieldParams}
                  textFieldProps={{ InputProps: { readOnly: true } }}
                />
              ) : (
                <FormTextField
                  label="תעודת זהות"
                  field="id"
                  {...defaultTextFieldParams}
                  textFieldProps={
                    props.player.id
                      ? {
                          InputProps: { readOnly: true },
                          helperText: "לא ניתן לשנות שדה זה",
                        }
                      : {}
                  }
                />
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="standard"
                value={form.gender}
                onChange={(e) => handleFieldChange({ gender: e.target.value })}
                error={errors.gender !== undefined}
                helperText={generateErrorMessage("gender", errors)}
                select
                required
                id="gender"
                name="gender"
                label="מין"
                fullWidth
                margin="dense"
              >
                <MenuItem value="male">זכר</MenuItem>
                <MenuItem value="female">נקבה</MenuItem>
              </TextField>
            </Grid>

            <Grid item xs={12} style={{ marginTop: 30 }}>
              <FormControl
                variant="standard"
                component="fieldset"
                error={errors.hasMedicalIssues !== undefined}
                required
              >
                <FormLabel color="primary">
                  האם לספורטאי יש בעיות רפואיות ו/או רגישויות ו/או מגבלות
                  גופניות אחרות?
                </FormLabel>
                <RadioGroup
                  name="medical"
                  value={
                    form.hasMedicalIssues !== true &&
                    form.hasMedicalIssues !== false // undefined - for trial and indirect players. for now
                      ? ""
                      : form.hasMedicalIssues.toString()
                  }
                  onChange={(e) =>
                    handleFieldChange({
                      hasMedicalIssues: e.target.value === "true",
                    })
                  }
                  row
                >
                  <FormControlLabel
                    value="true"
                    control={<Radio color="primary" />}
                    label="כן"
                  />
                  <FormControlLabel
                    value="false"
                    control={<Radio color="primary" />}
                    label="לא"
                  />
                </RadioGroup>
                <FormHelperText>
                  {generateErrorMessage("hasMedicalIssues", errors)}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="standard"
                label="אם כן, אנא פרט אותן:"
                value={form.medicalIssues}
                onChange={(e) =>
                  handleFieldChange({ medicalIssues: e.target.value })
                }
                fullWidth
                multiline
                maxRows={3}
                disabled={form.hasMedicalIssues === false}
                error={errors.medicalIssues !== undefined}
                helperText={generateErrorMessage("medicalIssues", errors)}
                style={{ marginBottom: 20 }}
              />
            </Grid>
          </Grid>
        </DialogContent>
      </RTL>

      <DialogActions>
        <ButtonSx
          onClick={props.onClose}
          disabled={isLoading}
          sx={{ width: 120 }}
        >
          ביטול
        </ButtonSx>
        <ButtonSx
          onClick={handleSubmit}
          isLoading={isLoading}
          debounce
          sx={{ width: 120 }}
        >
          שמירה
        </ButtonSx>
      </DialogActions>
    </Dialog>
  );
}
