export const download = (data, filename, type = "text/csv") => {
  // var file = new Blob([data], { type: type });
  if (window.navigator.msSaveOrOpenBlob) {
    // IE10+
    var blob = new Blob([data], { type });
    window.navigator.msSaveOrOpenBlob(blob, filename);
  } else {
    // Others

    var a = document.createElement("a");
    a.href = data;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    setTimeout(function () {
      document.body.removeChild(a);
    }, 0);
  }
};
