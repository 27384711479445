import React from "react";
import ReactApexChart from "react-apexcharts";
import _ from "lodash";

import { displayPrice } from "../../../utils/helpers";

export default function RevenueChart({ labels, series, isLoading }) {
  return (
    <ReactApexChart
      options={{
        chart: { type: "pie" },
        labels: labels,
        tooltip: {
          enabled: true,
          y: {
            formatter: function (value) {
              return displayPrice(value);
            },
          },
        },
        legend: {
          formatter: function (value) {
            return _.truncate(value, { length: 25 });
          },
          width: 180,
        },
        noData: {
          text: isLoading ? "טוען נתונים..." : "לא נמכרו מוצרים בתקופה שנבחרה",
          align: "center",
          verticalAlign: "middle",
          offsetX: 0,
          offsetY: 0,
        },
      }}
      height={200}
      series={series}
      type="pie"
    />
  );
}
