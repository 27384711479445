import React from "react";
import { Button, CircularProgress } from "@mui/material";
import _ from "lodash";

const styles = {
  progress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: "-12px",
    marginLeft: "-12px",
  },
  whiteProgress: {
    color: "white",
  },
};

export default function ButtonSx(props) {
  return (
    <Button
      variant={props.variant || "outlined"}
      {...(props.color ? { color: props.color } : {})}
      onClick={props.debounce ? _.debounce(props.onClick, 200) : props.onClick}
      disabled={props.disabled || props.isLoading}
      fullWidth={props.fullWidth}
      startIcon={props.startIcon}
      endIcon={props.endIcon}
      sx={{
        ...(props.fullWidth ? {} : { minWidth: 120 }),
        ...props.sx,
      }}
      {...props.buttonProps}
    >
      {props.isLoading && (
        <CircularProgress
          size={24}
          sx={{
            ...styles.progress,
            ...(props.color !== undefined ? styles.whiteProgress : {}),
          }}
        />
      )}
      {props.children}
    </Button>
  );
}
