import React, { useMemo } from "react";
import moment from "moment";

import { displayPrice } from "../../../utils/helpers";
import Table from "../../table/Table";
import TlPaper from "../../TlPaper";

const columns = [
  { id: "item", label: "פריט", width: 140 },
  { id: "cost", label: "מחיר" },
  { id: "paid", label: "שולם" },
];

export default function SubscriptionCosts({ subscription }) {
  const originalPrice = useMemo(
    () =>
      ["season", "camp"].indexOf(subscription.newType) > -1
        ? subscription.originalMonthlyPrice
        : subscription.originalPrice,
    [subscription]
  );

  const showOriginalPrice = useMemo(() => {
    return (
      subscription.balance.find(
        (b) => !b.isFee && b.amount !== originalPrice
      ) !== undefined
    );
  }, [subscription.balance, originalPrice]);

  const renderCell = (columnId, balance) => {
    switch (columnId) {
      case "item":
        if (balance.isFee) {
          return "דמי רישום";
        } else if (balance.oneCharge) {
          return "פעילות";
        }
        return `${moment(balance.dueDate).format("MM/YY")}`;

      case "cost":
        return `${
          !balance.isFee && balance.amount !== originalPrice ? "*" : ""
        }${displayPrice(balance.amount)}`;

      case "paid":
        return displayPrice(balance.paid);

      default:
        return balance[columnId];
    }
  };

  const renderOriginalPrice = () => (
    <div style={{ marginRight: 5, marginBottom: 5 }}>
      *מחיר מקורי: {displayPrice(originalPrice)} לחודש
    </div>
  );

  const rowId = (row) => {
    if (row.isFee) {
      return "fee";
    }

    if (row.oneCharge) {
      return "oneCharge";
    }

    return row.dueDate;
  };

  return (
    <TlPaper
      noMinHeight
      title="עלויות"
      titleBackground
      bodySx={{ pl: 0, pr: 0, p: 0, m: 0 }}
      collapsible
    >
      <Table
        noHeader
        columns={columns}
        renderCell={renderCell}
        rows={subscription.balance}
        renderKey={rowId}
        selected={"none"}
        sort={false}
      />

      {subscription.discountReason && (
        <div style={{ marginRight: 5, marginBottom: 5 }}>
          סיבת הנחה / הערות: {subscription.discountReason}
        </div>
      )}

      {showOriginalPrice && renderOriginalPrice()}
    </TlPaper>
  );
}
