import React from "react";
import { Grid, TextField } from "@mui/material";

import FormTextField from "../../../base/FormTextField";
import { getSeasonDetails } from "../../../../utils/subscriptionHelpers";
import SubscriptionCostSummary from "./SubscriptionCostSummary";
import MonthlyCostForm from "./MonthlyCostForm";
import PriceInputNg from "../../../PriceInputNG";
import DiscountPopper from "./DiscountPopper";
import DatePicker from "../../../DatePicker";
import MenuItem from "../../../base/MenuItem";

export default function EditDirectSubscriptionForm({
  subscription,
  onFieldsChanged,
  onPriceChanged,
  onFeePriceChange,
  summary,
  disabled,
  onSettingNewPrice,
  pricesOnly,
  ...props
}) {
  let season = getSeasonDetails(subscription.product);
  const errors = subscription.errors || {};

  const defaultTextFieldParams = {
    form: subscription,
    errors,
    onChange: onFieldsChanged,
  };

  return (
    <div>
      <Grid
        container
        spacing={2}
        alignItems="flex-start"
        justifyContent="flex-start"
      >
        {!pricesOnly && (
          <Grid item xs={4}>
            <DatePicker
              label="תחילת פעילות"
              textFieldProps={{
                error: Object.prototype.hasOwnProperty.call(
                  errors,
                  "startDate"
                ),
                helperText: errors.startDate,
              }}
              disabled={disabled}
              minDate={season.startDate}
              maxDate={season.endDate}
              value={subscription.startDate}
              onChange={(startDate) => onFieldsChanged({ startDate })}
            />
          </Grid>
        )}

        {!subscription.product.subscriptionDetails.singlePricing &&
          !pricesOnly && (
            <Grid item xs={4}>
              <TextField
                label="סוג רישום"
                error={Object.prototype.hasOwnProperty.call(
                  errors,
                  "pricingId"
                )}
                helperText={errors.pricingId}
                disabled={disabled}
                onChange={(e) => onFieldsChanged({ pricingId: e.target.value })}
                select
                fullWidth
                variant="standard"
                margin="dense"
                value={subscription.pricingId}
              >
                {subscription.product.subscriptionDetails.pricings.map(
                  (pricing) => (
                    <MenuItem
                      key={pricing._id}
                      value={pricing._id}
                      disabled={!pricing.active}
                    >
                      {pricing.title}
                    </MenuItem>
                  )
                )}
              </TextField>
            </Grid>
          )}

        {subscription.fees.map((fee, index) => (
          <Grid item xs={4} key={fee.feeProductId}>
            <PriceInputNg
              label={`${fee.title.slice(0, 45)}${
                fee.title.length > 45 ? "..." : ""
              }`}
              fullWidth
              value={fee.priceStr}
              onChange={onFeePriceChange(index)}
              error={errors.fees[index] ? true : false}
              helperText={errors.fees[index]}
              margin="dense"
              disabled={disabled}
            />
          </Grid>
        ))}
        <MonthlyCostForm
          subscription={subscription}
          onPriceChange={onPriceChanged}
          disabled={disabled}
        />
        <Grid item container xs={12}>
          <Grid item xs>
            <FormTextField
              label="סיבת ההנחה / הערות"
              field="discountReason"
              margin="dense"
              textFieldProps={{ disabled }}
              {...defaultTextFieldParams}
            />
          </Grid>
          <Grid item>
            <DiscountPopper
              subscription={subscription}
              onChange={onSettingNewPrice}
              disabled={disabled || !subscription?.pricingId}
              subscriptionType={
                subscription.product.subscriptionDetails.subscriptionType
              }
            />
          </Grid>
        </Grid>

        {summary && (
          <Grid item xs={12}>
            <SubscriptionCostSummary
              subscription={subscription}
              showPaidAmount
              originalSubscription={props.originalSubscription}
              title="סיכום עלויות"
            />
          </Grid>
        )}
      </Grid>
    </div>
  );
}
