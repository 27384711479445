import React, { useEffect, useMemo, useState } from "react";
import {
  Typography,
  Divider,
  Paper,
  CircularProgress,
  Collapse,
  IconButton,
  Box,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMoreRounded";
import ExpandLessIcon from "@mui/icons-material/ExpandLessRounded";

import AbsoluteCenter from "./AbsoluteCenter";
import AbstractBackground from "../resources/images/paperBackground.jpg";

const styles = {
  paper: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    m: "1px",
  },
  body: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    p: 1,
    pl: { xs: "10px", md: 3 },
    pr: { xs: "10px", md: 3 },
    position: "relative",
    overflowX: "auto",
  },
  bodyWhenTitleExists: {
    marginTop: 1,
  },
  title: {
    p: 1,
    pl: 3,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: 56,
  },
  bigTitle: {
    padding: 2,
  },
  titleBackground: {
    backgroundImage: `url(${AbstractBackground})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  collapsibleBody: {
    p: 1,
    pl: { xs: "10px", md: 3 },
    pr: { xs: "10px", md: 3 },
    position: "relative",
  },
};

export default function TlPaper(props) {
  const [isCollapsed, setIsCollapsed] = useState(false);

  useEffect(() => {
    setIsCollapsed(props.initiallyCollapsed);
  }, [props.initiallyCollapsed]);

  const fullPage = props.fullPage
    ? props.title
      ? "calc(100vh - 196px)"
      : "calc(100vh - 120px)"
    : null;

  const minHeight = {};
  if (!props.noMinHeight) {
    minHeight.minHeight = props.minHeight || fullPage || 200;
  }

  const renderCollapseIcon = () => (
    <IconButton
      color="primary"
      onClick={() => setIsCollapsed(!isCollapsed)}
      size="small"
    >
      {isCollapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />}
    </IconButton>
  );

  const titleExists = props.title || props.titleComponent;

  const Container = useMemo(
    () => (props.collapsible ? Collapse : Box),
    [props.collapsible]
  );

  return (
    <Paper
      {...props.paperProps}
      sx={{ ...styles.paper, ...minHeight, ...props.sx }}
    >
      {titleExists && (
        <>
          <Box
            sx={{
              ...styles.title,
              ...props.titleSx,
              ...(props.bigTitle ? styles.bigTitle : {}),
              ...(props.titleBackground ? styles.titleBackground : {}),
            }}
          >
            {props.titleComponent || (
              <Typography component="h2" variant="h6" color="primary">
                {props.title} {props.collapsible && renderCollapseIcon()}
              </Typography>
            )}
            {props.leftItem}
          </Box>
          <Divider sx={props.dividerSx} />
        </>
      )}
      <Container
        {...(props.collapsible
          ? { in: !isCollapsed }
          : {
              // this causes lag for when collapsing (when using collapse)
              sx: {
                ...styles.body,
                ...minHeight,
                ...(titleExists ? styles.bodyWhenTitleExists : {}),
                ...props.bodySx,
              },
            })}
      >
        {props.isLoading ? (
          <AbsoluteCenter>
            <CircularProgress />
          </AbsoluteCenter>
        ) : props.collapsible ? (
          <Box
            sx={{
              ...styles.collapsibleBody,
              ...(titleExists ? styles.bodyWhenTitleExists : {}),
              ...props.bodySx,
            }}
          >
            {props.children}
          </Box>
        ) : (
          props.children
        )}
      </Container>
    </Paper>
  );
}
