import React from "react";
import { Alert, Snackbar } from "@mui/material";

export default function SnackbarNg(props) {
  const { message, open, snackKey, severity } = props;

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    props.onClose();
  };

  return (
    <Snackbar
      key={snackKey}
      anchorOrigin={
        props.anchor || {
          vertical: "bottom",
          horizontal: "right",
        }
      }
      open={open}
      autoHideDuration={props.autoHideDuration || 6000}
      onClose={handleClose}
    >
      <Alert
        elevation={6}
        variant="filled"
        onClose={handleClose}
        severity={severity || "success"}
      >
        {message}
      </Alert>
    </Snackbar>
  );
}
