import React, { useRef, useState } from "react";
import { Box, FormHelperText, Typography } from "@mui/material";

import AddAPhotoIcon from "@mui/icons-material/AddAPhotoOutlined";
import ButtonSx from "./base/ButtonSx";

//maxFileSize in MB
export default function PictureSelector({
  onPictureSelected,
  maxFileSize = 1,
}) {
  const [error, setError] = useState(false);
  const fileReaderRef = useRef(null);

  const handleNewFile = (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileSize = file.size;
      if (fileSize > maxFileSize * 1024 * 1024) {
        return setError(true);
      }
      setError(false);

      fileReaderRef.current = new FileReader();
      fileReaderRef.current.onloadend = handleFileLoaded;
      fileReaderRef.current.readAsDataURL(file);
    }
  };

  const handleFileLoaded = () => {
    onPictureSelected(fileReaderRef.current.result);
    fileReaderRef.current = null;
  };

  return (
    <Box sx={styles.container}>
      <AddAPhotoIcon sx={{ fontSize: 40, color: "AAA" }} />
      <input
        accept="image/*"
        style={{ display: "none" }}
        id="raised-button-file"
        type="file"
        onChange={handleNewFile}
      />
      <Typography sx={styles.info}>
        גודל מקסימלי: {maxFileSize}MB, תמונות בפורמט: jpg, gif, png
      </Typography>
      <label htmlFor="raised-button-file">
        <ButtonSx buttonProps={{ component: "span" }}>בחירת תמונה</ButtonSx>
      </label>

      {error && (
        <FormHelperText error sx={styles.error}>
          *התמונה שנבחרה עברה את הגודל המקסימלי
        </FormHelperText>
      )}
    </Box>
  );
}

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px",
  },
  info: {
    marginTop: "100px",
    marginBottom: "15px",
  },
  error: {
    marginTop: "10px",
    fontSize: 18,
  },
};
