import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Link } from "@mui/material";
import { connect } from "react-redux";
import ReactGA from "react-ga4";
import moment from "moment";

import CoachDialog from "./CoachDialog";
import axios from "../../utils/axios";
import clubConfig from "../../config/clubs";
import { exportTable } from "../../lib/tableHelpers";
import Table from "../../components/table/Table";
import TlPaper from "../../components/TlPaper";
import SnackbarNg from "../../components/base/SnackbarNg";

class Coaches extends React.Component {
  state = {
    coaches: [],
    isDialogOpen: false,
    isLoading: true,
    isSnackbarOpen: null,
  };

  componentDidMount = async () => {
    ReactGA.send({
      hitType: "pageview",
      page: "/coaches",
      title: "Coaches",
    });

    this.loadCoaches();
  };

  loadCoaches = async () => {
    this.setState({ isLoading: true });
    const response = await axios.get(
      "/clubCoaches?granularity=activeTeamsCount"
    );
    this.setState({ coaches: response.data, isLoading: false });
  };

  tableHeaders = [
    { id: "name", label: "שם" },
    { id: "phone", label: "טלפון" },
    { id: "email", label: "אימייל", alignment: "left" },
    { id: "certification", label: "הסמכה" },
    { id: "activeTeamsCount", label: "קבוצות פעילות" },
  ];

  renderCell = (columnId, coach) => {
    switch (columnId) {
      case "name":
        return (
          <Link
            component={RouterLink}
            color="primary"
            to={coach._id}
            underline="hover"
          >
            {coach.name}
          </Link>
        );

      case "certification":
        return coach.certification;

      default:
        return coach[columnId];
    }
  };

  handleAddItem = () => {
    this.setState({
      isDialogOpen: true,
    });
  };

  handleCoachAdded = async (newCoach) => {
    this.setState({ isDialogOpen: false });
    this.showSuccessMessage(newCoach);
    this.loadCoaches();
  };

  showSuccessMessage = async () => {
    this.setState({ isSnackbarOpen: moment() });
  };

  handleExportTable = () => {
    exportTable(
      this.state.coaches,
      this.tableHeaders,
      this.renderCellExport,
      "csv",
      "coaches"
    );
  };

  renderCellExport = (columnId, coach) => {
    switch (columnId) {
      case "name":
        return coach.name;

      default:
        return this.renderCell(columnId, coach);
    }
  };

  render() {
    const coachAdmin = this.props.user.permissions.hrCoachTeamAdmin;
    return (
      <TlPaper
        title="מאמנים"
        isLoading={this.state.isLoading}
        fullPage
        titleBackground
      >
        <Table
          columns={this.tableHeaders}
          renderCell={this.renderCell}
          rows={this.state.coaches}
          title="מאמנים"
          onAdd={coachAdmin ? this.handleAddItem : null}
          onExport={this.handleExportTable}
          isLoading={this.state.isLoading}
        />

        <CoachDialog
          open={this.state.isDialogOpen}
          onClose={() => this.setState({ isDialogOpen: false })}
          onComplete={this.handleCoachAdded}
          clubCategory={
            clubConfig[this.props.selectedClub.internalName].category
          }
        />
        <SnackbarNg
          snackKey={this.state.isSnackbarOpen}
          open={this.state.isSnackbarOpen !== null}
          onClose={() => this.setState({ isSnackbarOpen: null })}
          message="הפעולה בוצעה בהצלחה"
        />
      </TlPaper>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedClub: state.auth.selectedClub,
    user: state.auth.user,
  };
}

export default connect(mapStateToProps)(Coaches);
