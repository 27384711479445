import React from "react";
import Chart from "react-apexcharts";

export default function ChartItem({ labels, series, isLoading, title }) {
  return (
    !isLoading && (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            color: "#495057",
            fontSize: "22px",
            fontWeight: "500",
            marginBottom: 10,
          }}
        >
          {title}
        </div>
        <Chart
          options={{
            chart: {
              type: "pie",
            },
            labels,
            plotOptions: {},
            tooltip: {
              enabled: true,
              y: {
                formatter: function (value) {
                  return value + " עסקאות";
                },
              },
            },
          }}
          height={250}
          series={series}
          type="pie"
        />
      </>
    )
  );
}
