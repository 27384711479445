import React from "react";
import { useMediaQuery } from "@mui/material";
import theme from "../../theme/muiTheme";

export default function withIsXs(Component) {
  return function Wrapper(props) {
    const matches = useMediaQuery(theme.breakpoints.down('sm'), {
      noSsr: true,
    });

    return <Component {...props} isXs={matches} />;
  };
}
