import React, { useState } from "react";
import { Alert, DialogActions, DialogContent } from "@mui/material";
import moment from "moment";

import Dialog from "../../../components/Dialog";
import InfoRow from "../../../components/InfoRows/InfoRow";
import { displayPrice, roundPrice } from "../../../utils/helpers";
import DatePicker from "../../../components/DatePicker";
import ButtonSx from "../../../components/base/ButtonSx";
import Paper2Sx from "../../../components/Paper2Sx";

export default function DepositConfirmationDialog(props) {
  const [date, setDate] = useState(moment());
  const { sums } = props;

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      onClose={props.onClose}
      open={props.open}
      title={"יצירת הפקדה חדשה"}
    >
      <DialogContent dividers>
        <Alert severity="info">
          <div>שימו לב, פעולה זו אינה הפיכה.</div>
          אנא וודאו שסכומי ההפקדה נכונים.
        </Alert>
        <DatePicker
          label="תאריך הפקדה"
          textFieldProps={{ sx: { mb: 2, width: 150 } }}
          value={date}
          onChange={setDate}
          disableFuture
        />
        <Paper2Sx>
          <InfoRow
            title="מזומן"
            value={`${displayPrice(sums.cash.amount)} (${sums.cash.count})`}
          />
          <InfoRow
            title="צ׳קים"
            value={`${displayPrice(sums.check.amount)} (${sums.check.count})`}
          />
          <InfoRow
            title='סה"כ'
            value={`${displayPrice(
              roundPrice(sums.check.amount + sums.cash.amount)
            )} (${sums.check.count + sums.cash.count})`}
          />
        </Paper2Sx>
      </DialogContent>
      <DialogActions>
        <ButtonSx onClick={props.onClose} disabled={props.isLoading}>
          ביטול
        </ButtonSx>
        <ButtonSx
          debounce
          onClick={() => props.onComplete(date)}
          isLoading={props.isLoading}
        >
          אישור
        </ButtonSx>
      </DialogActions>
    </Dialog>
  );
}
