import React, { useState } from "react";
import { Avatar, Box, Fab, Grid, Tooltip } from "@mui/material";

import moment from "moment";
import EditIcon from "@mui/icons-material/Edit";

import EditPlayerDialog from "./EditPlayerDialog";
import InfoRow from "../../components/InfoRows/InfoRow";
import { classes as playerClasses, gender } from "../../utils/dictionaries";
import TlPaper from "../../components/TlPaper";

export default function PlayerPersonalDetails(props) {
  const { user } = props;
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const renderMedicalIssues = () => {
    switch (user.hasMedicalIssues) {
      case true:
        return user.medicalIssues;

      case false:
        return "אין";

      default:
        return "לא ידוע";
    }
  };

  return (
    <TlPaper title="פרטים אישיים" titleBackground>
      <Box sx={styles.fabContainer}>
        {props.hasHrPlayerParentAdminPermission && user.status !== "trial" && (
          <Tooltip title="עדכון פרטים אישיים">
            <Fab
              color="primary"
              aria-label="Add"
              size="small"
              onClick={() => setIsDialogOpen(true)}
            >
              <EditIcon />
            </Fab>
          </Tooltip>
        )}
      </Box>
      <EditPlayerDialog
        open={isDialogOpen}
        player={user}
        onClose={() => setIsDialogOpen(false)}
        onCancel={() => setIsDialogOpen(false)}
        onComplete={() => {
          setIsDialogOpen(false);
          props.onUserChanged();
        }}
      />
      <Grid container>
        <Grid item xs={12} sm={6}>
          <InfoRow title="שם" value={user.name} titleSx={styles.infoRowTitle} />
          <InfoRow
            title="טלפון"
            value={
              (user.status === "trial" ? user.trialInfo.phone : user.phone) ||
              "-"
            }
            titleSx={styles.infoRowTitle}
          />
          <InfoRow
            title="בית ספר"
            value={user.school || "-"}
            titleSx={styles.infoRowTitle}
          />
          <InfoRow
            title="כיתה"
            value={
              user.class !== undefined ? `${playerClasses[user.class]}` : "-"
            }
            titleSx={styles.infoRowTitle}
          />
          <InfoRow
            title="תאריך לידה"
            value={
              user.dateOfBirth
                ? moment(user.dateOfBirth).format("DD/MM/YYYY")
                : "-"
            }
            titleSx={styles.infoRowTitle}
          />
          {user.notIsraeli ? (
            <InfoRow
              title="מספר דרכון"
              value={user.passportNumber || "-"}
              titleSx={styles.infoRowTitle}
            />
          ) : (
            <InfoRow
              title="תעודת זהות"
              value={user.id || "-"}
              titleSx={styles.infoRowTitle}
            />
          )}

          <InfoRow
            title="מין"
            value={user.gender}
            options={gender}
            titleSx={styles.infoRowTitle}
          />
          <InfoRow
            title="מגבלות רפואיות"
            value={renderMedicalIssues()}
            titleSx={styles.infoRowTitle}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          {user.avatarUrl && (
            <div
              style={{
                border: "0px solid black",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
                paddingBottom: 30,
              }}
            >
              <Avatar sx={styles.avatar} src={user.avatarUrl} />
            </div>
          )}
        </Grid>
      </Grid>
    </TlPaper>
  );
}

const styles = {
  fabContainer: {
    position: "absolute",
    right: 10,
    bottom: 10,
  },
  avatar: {
    width: 120,
    height: 120,
  },
  infoRowTitle: {
    width: 120,
  },
};
