import React from "react";
import Chart from "react-apexcharts";
import moment from "moment";
import { attendanceMissingReasons } from "../../utils/dictionaries";

export default function PlayerAttendanceChart(props) {
  const series = [
    {
      name: "",
      data: [
        ...props.attendances.map((attendance, index) => {
          return {
            attendance: attendance,
            x: index,
            y: attendance.attended ? 2 : 0,
          };
        }),
      ],
    },
  ];

  const options = {
    chart: {
      height: 60,
      type: "heatmap",
      toolbar: { show: false },
    },
    yaxis: {
      labels: { show: false },
    },
    xaxis: {
      labels: { show: false },
      axisTicks: { show: false },
      tooltip: { enabled: false },
    },
    dataLabels: { enabled: false },
    legend: { show: false },
    tooltip: {
      custom: function ({ seriesIndex, dataPointIndex, w }) {
        const { attendance } = w.config.series[seriesIndex].data[
          dataPointIndex
        ];
        const missingReason = attendance.attended
          ? ""
          : `<div><b>סיבת חיסור:</b> ${
              attendanceMissingReasons[attendance.missingReason]
            }</div>`;
        return (
          '<div class="arrow_box" style="padding:10px;">' +
          "<span>" +
          "<div><b>תאריך:</b> " +
          moment(attendance.date).format("DD/MM/YY") +
          "</div>" +
          missingReason +
          "</div>"
        );
      },
    },
    colors: ["#E0E0E0"],
    stroke: {
      width: 1,
    },
    plotOptions: {
      heatmap: {
        enableShades: false,
        shadeIntensity: 1,
        colorScale: {
          inverse: true,
          ranges: [
            {
              from: 0,
              to: 1,
              color: "#d32f2f",
            },
            {
              from: 1,
              to: 2,
              color: "#388e3c",
            },
          ],
        },
      },
    },
  };

  return (
    <Chart
      options={options}
      series={series}
      type="heatmap"
      height={60}
      width={props.attendances.length * 18}
      style={{ marginLeft: 10 }}
    />
  );
}
