import React, { Component } from "react";
import { Link, IconButton, Alert, Skeleton } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import CancelIcon from "@mui/icons-material/CancelOutlined";
import ReactGA from "react-ga4";

import axios from "../../utils/axios";
import Table from "../../components/table/Table";
import { displayDate, displayPrice } from "../../utils/helpers";
import * as Actions from "../../store/actions";
import FiltersBar from "../../components/filters/FiltersBar";
import {
  prepareFilters,
  getFiltersFromUrl,
} from "../../components/filters/prepareFilters";
import { specialRequestStatuses } from "../../utils/dictionaries";
import CancelOtherPaymentMethodRequestDialog from "./CancelOtherPaymentMethodRequestDialog";
import withRouter from "../../components/routing/withRouter";
import TlPaper from "../../components/TlPaper";

const filtersStructure = [
  { type: "date" },
  {
    name: "specialRequestStatus",
    type: "select",
    options: {
      open: "בקשות חדשות",
      close: "בקשות שטופלו",
    },
    emptyLabel: "כל הסטטוסים",
    label: "סטטוס",
    includeAll: true,
  },
];

class OtherPaymentMethodRequests extends Component {
  state = {
    requests: null,
    isDialogOpen: false,
    isSaving: false,
    isLoading: true,
    filters: {
      specialRequestStatus: "open",
      date: {
        from: moment("2022-01-01").startOf("day"),
        to: moment().endOf("day"),
      },
    },
    cancelledRequst: null,
    columns: [
      { id: "clubPlayer", label: "שם הספורטאי" },
      { id: "status", label: "סטטוס הבקשה" },
      { id: "date", label: "תאריך" },
      { id: "parentName", label: "שם ההורה" },
      { id: "parentPhone", label: "טלפון ההורה" },
      { id: "team", label: "קבוצה" },
      { id: "price", label: "מחיר" },
      { id: "action", label: "ביטול הבקשה" },
    ],
  };

  componentDidMount = async () => {
    ReactGA.send({
      hitType: "pageview",
      page: "/subscriptions/otherPaymentMethodRequests",
      title: "Other Payment Method Requests",
    });

    let { columns } = this.state;
    if (this.props.selectedClub.superClub) {
      columns.splice(1, 0, { id: "club", label: "ענף" });
    }

    const filters = getFiltersFromUrl(
      this.props.location,
      this.state.filters,
      filtersStructure
    );
    await this.setState({ filters, columns });
    this.loadData(false, false);
    // in case the notification bar counter is out of date
    this.props.loadNotificationBarCounters();
  };

  loadData = async (silence, updateUrl = true) => {
    this.setState({ isLoading: !silence });
    let filters = prepareFilters(this.state.filters, filtersStructure, {
      includeAll: false,
    });
    const queryFilters = [...filters];

    const granularity = "granularity=clubPlayer.clubParent&granularity=team";
    const res = await axios.get(
      `/store/subscriptions?specialRequestType=otherPaymentMethod&${queryFilters.join(
        "&"
      )}&${granularity}`
    );
    this.setState({ isLoading: false, requests: res.data });

    if (updateUrl) {
      // includeAll is neccesary since the default status is open (without it, on refresh the status will from all to open)
      filters = prepareFilters(this.state.filters, filtersStructure, {});
      this.props.navigate(`?${filters.join("&")}`, { replace: true });
    }
  };

  handleFilterChange = (filterName, value) => {
    this.setState({
      filters: {
        ...this.state.filters,
        [filterName]: value,
      },
    });
  };

  handleSave = async (accepted) => {
    this.setState((prevState) => {
      const requests = [...prevState.requests];

      const sub = requests.find(
        (req) => req._id === prevState.selectedDiscountRequest._id
      );

      sub.specialRequest.status = "close";
      sub.specialRequest.discountRequest.status = accepted
        ? "accepted"
        : "rejected";

      return {
        isSaving: false,
        isDialogOpen: false,
        selectedDiscountRequest: null,
        requests,
      };
    });
    this.loadData(true, false);

    this.props.loadNotificationBarCounters();
  };

  handleCancelRequest = async () => {
    try {
      await axios.post(
        `/store/subscriptions/${this.state.cancelledRequst._id}/closeSpecialRequest`,
        { specialRequestType: "otherPaymentMethod" }
      );
      this.setState({ cancelledRequst: null });
      this.loadData(true, false);
      this.props.loadNotificationBarCounters();
    } catch (error) {
      // if request has already been closed
      if (
        error.response &&
        error.response.status === 422 &&
        error.response.data.error === "wrongStatus"
      ) {
        this.setState({ cancelledRequst: null });
        this.loadData(true, false);
        this.props.loadNotificationBarCounters();
      }
      console.log(error);
    }
  };

  renderPlaceholder = () => (
    <div>
      {[...Array(10).keys()].map((index) => (
        <Skeleton key={index} animation="wave" height={30} />
      ))}
    </div>
  );

  renderCell = (columnId, subscription) => {
    switch (columnId) {
      case "clubPlayer":
        return (
          <Link
            component={RouterLink}
            color="primary"
            to={`/clubs/${
              this.props.clubsHash[subscription.club].internalName
            }/players/${subscription.clubPlayer._id}`}
            underline="hover"
          >
            {subscription.clubPlayer.name}
          </Link>
        );

      case "club":
        return this.props.clubsHash[subscription.club]?.clubConfig.general.name;

      case "status":
        return specialRequestStatuses[subscription.specialRequest.status];

      case "date":
        return displayDate(subscription.createdAt);

      case "parentName":
        return subscription.clubPlayer.parent.firstName;

      case "parentPhone":
        return subscription.clubPlayer.parent.phone;

      case "team":
        return subscription.team.name;

      case "price":
        return displayPrice(subscription.totalPrice);

      case "action":
        return (
          subscription.status === "pendingOtherPaymentMethod" && (
            <IconButton
              onClick={() => this.setState({ cancelledRequst: subscription })}
              size="large"
            >
              <CancelIcon />
            </IconButton>
          )
        );

      default:
        return subscription[columnId];
    }
  };

  sortValueCell = (columnId, subscription) => {
    switch (columnId) {
      case "date":
        return moment(subscription.createdAt).unix();

      default:
        return subscription[columnId];
    }
  };

  handleRowClicked = (row) => {
    if (row.specialRequest.discountRequest.status === "open") {
      this.setState({ selectedDiscountRequest: row, isDialogOpen: true });
    }
  };

  renderContent = () => (
    <Table
      title="בקשות"
      columns={this.state.columns}
      renderCell={this.renderCell}
      sortValueCell={this.sortValueCell}
      rows={this.state.requests}
      isLoading={this.state.isLoading}
      onRowClick={this.handleRowClicked}
      defaultSortBy={2}
      defaultSortDirection="desc"
    />
  );

  render() {
    return (
      <TlPaper title="בקשות לתשלום במזומן או צ׳קים">
        <Alert severity="info" style={{ marginBottom: 10 }}>
          בקשות לתשלום במזומן או צ׳קים יסגרו באופן אוטומטי לאחר ביצוע התשלום
        </Alert>
        <FiltersBar
          filters={filtersStructure}
          onFilterChanged={this.handleFilterChange}
          values={this.state.filters}
          onSearch={() => this.loadData(true, true)}
          isSearching={this.state.isLoading}
        />
        <CancelOtherPaymentMethodRequestDialog
          open={this.state.cancelledRequst !== null}
          onClose={() => this.setState({ cancelledRequst: null })}
          onCancelRequest={this.handleCancelRequest}
        />
        {this.state.isLoading ? this.renderPlaceholder() : this.renderContent()}
      </TlPaper>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedClub: state.auth.selectedClub,
    clubsHash: state.auth.clubsHash,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loadNotificationBarCounters: () =>
      dispatch(Actions.general.loadNotificationBarCounters()),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(OtherPaymentMethodRequests));
