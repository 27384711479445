import React from "react";
import { DialogContent, DialogActions } from "@mui/material";

import axios from "../../utils/axios";
import Dialog from "../../components/Dialog";
import ButtonSx from "../../components/base/ButtonSx";

export default function DeleteUserDialog(props) {
  const [error, setError] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setError(null);
    setLoading(false);
  }, [props.user]);

  const handleDelete = async () => {
    try {
      setLoading(true);
      await axios.delete(`/managers/${props.user._id}`);
      props.onComplete(props.user._id, "delete");
    } catch (error) {
      setError({ type: "unknown" });
    } finally {
      setLoading(false);
    }
  };

  const renderContent = () => {
    if (error) {
      return `לא ניתן לבצע פעולה זו כרגע `;
    } else {
      return <div>האם ברצונך למחוק את ״{(props.user || {}).name}״?</div>;
    }
  };

  const renderActions = () => {
    if (error) {
      return <ButtonSx onClick={props.onClose}>חזרה</ButtonSx>;
    } else {
      return (
        <>
          <ButtonSx onClick={props.onClose} disabled={loading}>
            ביטול
          </ButtonSx>
          <ButtonSx onClick={handleDelete} disabled={loading} debounce>
            מחיקה
          </ButtonSx>
        </>
      );
    }
  };

  return (
    <Dialog
      fullWidth
      onClose={props.onClose}
      open={props.open}
      title="מחיקת משתמש"
    >
      <DialogContent dividers>{renderContent()}</DialogContent>
      <DialogActions>{renderActions()}</DialogActions>
    </Dialog>
  );
}
