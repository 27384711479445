import React, { useState, useMemo } from "react";
import {
  Checkbox,
  List,
  Popover,
  Select,
  InputLabel,
  FormControl,
  Grid,
  Typography,
  MenuItem,
  Divider,
  Box,
} from "@mui/material";

import ListItem from "../../components/ListItem";
import TlPaper from "../../components/TlPaper";
import Dialog from "../../components/Dialog";

export default function AdvancedResourceSelect(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const resourceGroups = useMemo(
    () => props.resourceGroups?.filter((rg) => !rg.default) || [],
    [props.resourceGroups]
  );

  const handleClose = () => {
    if (Object.keys(selectedItems).length > 0) {
      setIsOpen(false);
    }
  };

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setIsOpen(!isOpen);
  };

  const handleItemToggle = (item) => () => {
    let selectedItems = { ...props.selectedItems };
    if (item === "all") {
      if (Object.keys(selectedItems).length === props.options.length) {
        selectedItems = {};
      } else {
        selectedItems = props.options.reduce((hash, opt) => {
          hash[opt.key] = true;
          return hash;
        }, {});
      }
    } else if (selectedItems[item.key]) {
      delete selectedItems[item.key];
    } else {
      selectedItems[item.key] = true;
    }

    props.onChange(selectedItems);
  };

  const handleResourceGroupSelected = (resourceGroup) => () => {
    const newItems = resourceGroup.resources.reduce((prev, resourceId) => {
      const resource = props.allResources.find((r) => r._id === resourceId);
      if (!resource.parent) {
        prev[resourceId] = true;
      }
      return prev;
    }, {});

    props.onChange(newItems);
    handleClose();
  };

  const options = props.options || [];
  const selectedItems = useMemo(
    () => props.selectedItems || {},
    [props.selectedItems]
  );
  const keys = useMemo(() => Object.keys(selectedItems), [selectedItems]);
  const allSelected = keys.length === options.length;

  const renderValue = () => {
    if (props.isLoading) {
      return "טוען...";
    }
    if (props.selectedItems.all) {
      return "";
    }
    if (allSelected) {
      return props.allTitle;
    }

    if (keys.length === 1) {
      const opt = props.options.find((opt) => opt.key === keys[0]);
      return opt.value;
    }
    return `${keys.length} ${props.itemTitle} נבחרו`;
  };
  const labelId = `${props.id}-label`;

  const renderResourceGroups = () => {
    if (!resourceGroups.length > 0) {
      return <Box>לא נמצאו קבוצות מגרשים</Box>;
    }

    return (
      <div>
        {resourceGroups.map((rg) => (
          <ListItem
            key={rg._id}
            button
            onClick={handleResourceGroupSelected(rg)}
            primaryText={rg.name}
            divider
          ></ListItem>
        ))}
      </div>
    );
  };

  const renderContent = () => (
    <TlPaper sx={{ minWidth: 200 }} paperProps={{ dir: "rtl" }}>
      <Grid container spacing={1}>
        <Grid item sx={props.isMobile ? { width: "100%" } : { width: 250 }}>
          <Typography sx={styles.listHeader} gutterBottom>
            מגרשים
          </Typography>
          <List dense disablePadding sx={styles.resourcesList}>
            <ListItem
              button
              onClick={handleItemToggle("all")}
              listItemProps={{ sx: { backgroundColor: "#FAFAFA" } }}
              primaryText={
                <>
                  <Checkbox
                    checked={allSelected}
                    onChange={handleItemToggle("all")}
                    sx={{ p: 0 }}
                  />
                  <span style={{ fontWeight: "bold" }}>{props.allTitle}</span>
                </>
              }
            />

            <Divider component="li" sx={{ backgroundColor: "#AAA" }} />
            {options.map((opt) => (
              <ListItem
                key={opt.key}
                button
                onClick={handleItemToggle(opt)}
                divider
                primaryText={
                  <>
                    <Checkbox
                      checked={selectedItems[opt.key] === true}
                      onChange={handleItemToggle(opt)}
                      sx={{ p: 0 }}
                    />
                    {opt.value}
                  </>
                }
              />
            ))}
          </List>
        </Grid>
        <Grid item sx={{ width: 250 }}>
          <Typography sx={styles.listHeader} gutterBottom>
            קבוצות מגרשים
          </Typography>
          {resourceGroups.length > 0 ? (
            <List dense disablePadding sx={styles.resourcesList}>
              {renderResourceGroups()}
            </List>
          ) : (
            <Box sx={styles.noResourceGroups}>לא נוצרו קבוצות מגרשים</Box>
          )}
        </Grid>
      </Grid>
    </TlPaper>
  );

  return (
    <>
      <FormControl fullWidth={props.fullWidth}>
        <InputLabel id={labelId}>{props.label}</InputLabel>
        <Select
          id={props.id}
          labelId={labelId}
          sx={props.selectSx}
          onOpen={handleOpen}
          open={false}
          value={"ph"} // must provide something to triger renderValue
          renderValue={renderValue}
          label={props.label}
          size="small"
        >
          <MenuItem value="ph"></MenuItem>
        </Select>
      </FormControl>
      {props.isMobile ? (
        <Dialog
          onClose={handleClose}
          open={isOpen}
          title="בחירת מגרשים"
          fullWidth={false}
          closeButton
          fullScreen
        >
          {renderContent()}
        </Dialog>
      ) : (
        <Popover
          open={!props.isLoading && isOpen}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          transformOrigin={{ vertical: "top", horizontal: "center" }}
        >
          {renderContent()}
        </Popover>
      )}
    </>
  );
}

const styles = {
  noResourceGroups: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 50,
    border: "1px solid #EEE",
    p: 1,
    borderRadius: 1,
    fontSize: "0.875rem",
  },
  resourcesList: {
    borderRadius: 1,
    border: "1px solid #EEE",
    overflow: "auto",
    maxHeight: "calc(100vh - 300px)",
  },
  listHeader: {
    fontWeight: "bold",
    borderBottom: "1px solid $EEE",
    mt: 1,
  },
};
