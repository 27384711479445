import React from "react";
import { CircularProgress } from "@mui/material";

import InfoRow from "../../components/InfoRows/InfoRow";
import TlPaper from "../../components/TlPaper";

export default function AccountDetails(props) {
  const clubParent = props.clubParent || {};

  return (
    <TlPaper title="פרטי לקוח" sx={{ height: "100%" }} titleBackground>
      {clubParent._id ? (
        <>
          <InfoRow
            title="שם הלקוח"
            value={clubParent.name}
            linkTo={
              props.hasHrPermission ? `../accounts/${clubParent._id}` : null
            }
          />
          <InfoRow title="טלפון" value={clubParent.phone} />
          <InfoRow title="אימייל" value={clubParent.email} />
          <InfoRow title="כתובת" value={clubParent.address} />
        </>
      ) : (
        <CircularProgress />
      )}
    </TlPaper>
  );
}
