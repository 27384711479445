import React from "react";
import { TextField } from "@mui/material";

// this component is always a string
// value has to be parsed before further handling, but this component should always get string (e.g for cases like, 20. -> before typing the decimals -> 20.12)
// the returned string is always a number but in one case of empty value ""
// in this case parseFloat("") will be NaN
// the PriceTextInput component has 2 problems
// 1. when value is 1.12 type 9 and the value will change to 1.13
// 2. cant enter values like xx.0x

export default function PriceInputNg({ onChange, value, ...props }) {
  const handleChange = (e) => {
    let val = e.target.value;
    if (val === "") {
      return onChange("", 0);
    }

    if (!val.match(/^[0-9]+(\.[0-9]{0,2})?$/)) {
      return;
    }

    onChange(val, parseFloat(val));
  };

  return (
    <TextField
      variant="standard"
      fullWidth
      {...props}
      value={value?.toString()}
      onChange={handleChange}
    />
  );
}
