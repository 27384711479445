import React from "react";
import _ from "lodash";
import moment from "moment";

import DeleteIcon from "@mui/icons-material/Delete";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  InputBase,
  Grid,
  Checkbox,
  TextField,
  FormControlLabel,
  Paper,
  CircularProgress,
  Box,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Draggable from "react-draggable";

import RTL from "../../../layout/Rtl";
import RecurringActionEventDialog from "../RecurringActionEventDialog";
import RegularEventTimes from "./RegularEventTimes";
import ResourceSelect from "./ResourceSelect";
import DepartureTimeSelect from "./DepartureTimeSelect";
import TeamSelect from "./TeamSelect";
import RecurringEventDatesSelect from "./RecurringEventDatesSelect";
import RecurringEventTimesSelect from "./RecurringEventTimesSelect";
import AutoComplete from "../../../components/base/AutoComplete";
import AbsoluteCenter from "../../../components/AbsoluteCenter";
import SendNotificationDialog from "../Notifications/SendNotificationDialog";
import EventTypeSelect from "./EventTypeSelect";
import ButtonSx from "../../../components/base/ButtonSx";

function PaperComponent(props) {
  const isSmUp = useMediaQuery((t) => t.breakpoints.up("sm"), { noSsr: true });

  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
      disabled={!isSmUp}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default class EventDialog extends React.Component {
  constructor(props) {
    super(props);
    const event = this.prepareEvent(props.event);

    this.state = {
      errors: {},
      originalEvent: { ...props.event },
      event,
      originalRecurringStartDate: event.recurringEvent
        ? event.recurringEvent.startDate
        : null,
      isStartTimeSelectOpen: false,
      selectedButtonIndex: 0,
      isDeleteDialogOpen: false,
      isUpdateDialogOpen: false,
      isSendNotificationDialogOpen: false,
      updateType: event.isRecurringEvent ? "recurring" : "single",
      hasEventChanged: false,
    };
  }

  prepareEvent = (event) => {
    const eventClone = { ...event };
    if (eventClone.gameDetails && eventClone.gameDetails.gameType === "away") {
      if (eventClone.gameDetails.departureTime) {
        eventClone.gameDetails.transportation = true;
      } else {
        eventClone.gameDetails.transportation = false;
        eventClone.gameDetails.departureTime = "";
        eventClone.gameDetails.busType = "";
      }
    }

    return eventClone;
  };

  prepareTime = (datetime, newTime) => {
    return moment(
      `${moment(datetime).format("DD/MM/YYYY")} ${newTime}`,
      "DD/MM/YYYY HH:mm"
    );
  };

  optionalEndTimes = (startTimeString) => {
    const startTimeIndex = this.props.times.indexOf(startTimeString);
    return this.props.times.slice(startTimeIndex + 1, this.props.times.length);
  };

  getEventTitle = (event) => {
    let team = this.props.teams.find((t) => t._id === event.team);
    if (!team) {
      team = this.props.completedTeams.find((t) => t._id === event.team);
    }

    const translations = {
      type: {
        game: "משחק",
        practice: "אימון",
      },
    };
    const type = translations.type[event.type];
    let gameType = "";
    if (event.type === "game") {
      gameType = ` ${event.gameDetails.gameType === "away" ? "חוץ" : "בית"}`;
    }

    if (team) {
      return `${type}${gameType} - ${team.name}`;
    } else {
      return `הוספת ${type}${gameType}`;
    }
  };

  hasEventChanged = (newEvent) => {
    const ne = newEvent;
    const { originalEvent: oe } = this.state;
    if (
      !oe._id || // new event
      ne.type !== oe.type ||
      (ne.type === "other" && ne.title !== oe.title) ||
      !moment(ne.start).isSame(oe.start) ||
      !moment(ne.end).isSame(oe.end) ||
      ne.team !== oe.team ||
      !_.isEqual([...ne.resources].sort(), [...oe.resources].sort())
    ) {
      return true;
    }

    let same = false;
    if (ne.type === "game") {
      same =
        ne.gameDetails.gameType === oe.gameDetails.gameType &&
        ne.gameDetails.opponent === oe.gameDetails.opponent;

      if (ne.gameDetails.gameType === "away") {
        same =
          same &&
          ne.gameDetails.location === oe.gameDetails.location &&
          ne.gameDetails.departureTime === oe.gameDetails.departureTime &&
          ne.gameDetails.busType === oe.gameDetails.busType;
      }

      if (!same) {
        return true;
      }
    }

    if (oe.isRecurringEvent && this.state.updateType === "recurring") {
      const ore = oe.recurringEvent;
      const nre = ne.recurringEvent;
      if (
        ore.dayOfWeek !== nre.dayOfWeek ||
        !moment(ore.startDate).isSame(nre.startDate) ||
        !moment(ore.endDate).isSame(nre.endDate) ||
        ore.startTime !== nre.startTime ||
        ore.endTime !== nre.endTime
        // the rest of the data is managed on the event itself
      ) {
        return true;
      }
    }

    return false;
  };

  onFieldUpdated = (field, value, callback) => {
    this.setState((prevState) => {
      const updates = { [field]: value };
      if (field === "type") {
        if (value === "game") {
          updates.gameDetails = {
            gameType: "home",
            opponent: "",
            location: "",
          };
        } else {
          updates.gameDetails = null;
        }

        if (value === "other") {
          updates.title = "";
        }
      }

      if (
        field === "start" &&
        prevState.event.type === "game" &&
        prevState.event.gameDetails.gameType === "away"
      ) {
        updates.gameDetails = {
          ...prevState.event.gameDetails,
        };

        delete updates.gameDetails.departureTime;
      }

      if (field === "gameDetails") {
        if (!updates.gameDetails.opponent) {
          updates.gameDetails.opponent = "";
        }

        if (value.gameType === "away") {
          // set transportation to true if first time
          if (
            value.transportation === null ||
            value.transportation === undefined
          ) {
            updates.gameDetails.transportation = true;
          }

          if (!updates.gameDetails.transportation) {
            updates.gameDetails.departureTime = "";
            updates.gameDetails.busType = "";
          }

          if (!updates.gameDetails.location) {
            updates.gameDetails.location = "";
          }

          // set departure time for the first time (only)
          if (
            value.departureTime === null ||
            value.departureTime === undefined
          ) {
            updates.gameDetails.departureTime = moment(
              prevState.event.start
            ).add(-2, "hours");
            updates.gameDetails.busType = "";
          }
        } else if (value.gameType === "home") {
          delete updates.gameDetails.departureTime;
          delete updates.gameDetails.busType;
        }
      }

      const newEventState = {
        ...prevState.event,
        ...updates,
      };
      if (newEventState.type !== "other") {
        newEventState.title = this.getEventTitle(newEventState);
      }

      return {
        ...prevState,
        hasEventChanged: this.hasEventChanged(newEventState),
        event: newEventState,
      };
    }, callback);
  };

  validateEvent = () => {
    const errors = {};
    if (!this.state.event.team && this.state.event.type !== "other") {
      errors.team = "required";
    }

    const resourceRequired =
      this.state.event.type === "practice" ||
      (this.state.event.type === "game" &&
        this.state.event.gameDetails.gameType === "home");

    if (resourceRequired && this.state.event.resources.length === 0) {
      errors.resources = "required";
    }

    if (this.state.event.isRecurringEvent) {
      const recurringEvent = this.state.event.recurringEvent;
      if (!recurringEvent.endTime) {
        errors.recurringEventEndTime = "required";
      }

      if (!recurringEvent.endDate) {
        errors.recurringEventEndDate = "required";
      }
    } else {
      if (!this.state.event.end) {
        errors.end = "required";
      }
    }

    if (this.state.event.title === "") {
      errors.title = "required";
    }

    if (this.state.event.type === "game") {
      if (this.state.event.gameDetails.opponent === "") {
        errors.opponent = "required";
      }

      if (this.state.event.gameDetails.gameType === "away") {
        if (this.state.event.gameDetails.location === "") {
          errors.location = "required";
        }

        if (this.state.event.gameDetails.transportation) {
          if (!this.state.event.gameDetails.busType) {
            errors.busType = "required";
          }

          if (
            !this.state.event.gameDetails.departureTime ||
            this.state.event.gameDetails.departureTime === ""
          ) {
            errors.departureTime = "required";
          }
        }
      }
    }

    this.setState({ errors });
    return Object.keys(errors).length === 0;
  };

  getChanges = () => {
    const changes = [];
    if (
      this.props.event.recurringEvent.startDate !==
      this.state.event.recurringEvent.startDate
    ) {
      changes.push("startDate");
    }

    if (
      this.props.event.recurringEvent.endDate !==
      this.state.event.recurringEvent.endDate
    ) {
      changes.push("endDate");
    }

    if (
      this.props.event.recurringEvent.startTime !==
      this.state.event.recurringEvent.startTime
    ) {
      changes.push("startTime");
    }

    if (
      this.props.event.recurringEvent.endTime !==
      this.state.event.recurringEvent.endTime
    ) {
      changes.push("endTime");
    }

    if (
      this.props.event.recurringEvent.dayOfWeek !==
      this.state.event.recurringEvent.dayOfWeek
    ) {
      changes.push("dayOfWeek");
    }

    if (this.props.event.title !== this.state.event.title) {
      changes.push("title");
    }

    if (
      !_.isEqual(
        _.sortBy(this.props.event.resources),
        _.sortBy(this.state.event.resources)
      )
    ) {
      changes.push("resources");
    }

    return changes;
  };

  onComplete = () => {
    if (this.state.originalEvent._id && !this.state.hasEventChanged) {
      // no need to save if exisiting event and nothing changed
      this.props.onClose();
    } else if (this.validateEvent()) {
      if (this.state.event._id && this.state.updateType === "recurring") {
        const changes = this.getChanges();
        if (changes.indexOf("startDate") > -1) {
          this.props.onUpdate(this.state.event, "recurring", "newStartDate");
        } else if (_.isEqual(changes, ["endDate"])) {
          this.props.onUpdate(this.state.event, "recurring", "newEndDateOnly");
        } else {
          this.setState({ isUpdateDialogOpen: true });
        }
        return;
      }

      // new recurring event or edit recurring event in non-single update type
      const isRecurring = this.state.event._id
        ? this.state.updateType === "recurring"
        : this.state.event.isRecurringEvent;

      if (
        !isRecurring &&
        (this.props.sendNotificationAvailable(this.state.event) || //new event
          (this.state.event._id &&
            this.props.sendNotificationAvailable(this.props.event))) && // or existing old event are in an open week
        this.state.hasEventChanged
      ) {
        this.setState({ isSendNotificationDialogOpen: true });
      } else {
        this.save();
      }
    }
  };

  save = () => {
    this.state.event._id
      ? this.props.onUpdate(this.state.event, "single", null, false, "dialog")
      : this.props.onCreate(this.state.event);
  };

  handleSendNotificationDialogCompleted = async (send) => {
    await this.setState({
      isSendNotificationDialogOpen: false,
      event: { ...this.state.event, sendNotification: send },
    });
    this.save();
  };

  handleBusTypeChanged = (value) => {
    this.onFieldUpdated("gameDetails", {
      ...this.state.event.gameDetails,
      busType: value,
    });
  };

  handleSendNotification = () => {
    this.props.onSendNotification(this.state.event);
  };

  render() {
    const { admin } = this.props;
    const isEditing = this.state.event._id;
    const completed =
      this.state.event._id &&
      (moment(this.state.event.start)
        .startOf("day")
        .isBefore(moment().startOf("day")) ||
        this.state.event.teamCompleted);
    const readOnly = !admin || completed;

    const isAwayGame =
      this.state.event.gameDetails &&
      this.state.event.gameDetails.gameType === "away";

    // const isHomeGame =
    //   this.state.event.gameDetails &&
    //   this.state.event.gameDetails.gameType === "home";

    const defaultProps = {
      event: this.state.event,
      onFieldUpdated: this.onFieldUpdated,
      errors: this.state.errors,
      admin: this.props.admin,
      readOnly,
    };

    return (
      <div>
        <Dialog
          open={this.props.isVisible}
          onClose={this.props.onClose}
          dir="rtl"
          PaperComponent={PaperComponent}
          fullScreen={this.props.isXs}
        >
          <RTL>
            <DialogTitle style={{ padding: 0 }}>
              <Box sx={styles.titleContainer}>
                <Box
                  sx={styles.titleRightSideContainer}
                  id="draggable-dialog-title"
                >
                  {this.state.event.type === "other" ? (
                    <TextField
                      variant="standard"
                      error={this.state.errors.title !== undefined}
                      value={this.state.event.title}
                      onChange={(event) => {
                        this.onFieldUpdated("title", event.target.value);
                      }}
                      autoFocus
                      id="title"
                      placeholder="הוסף שם אירוע"
                      fullWidth
                      inputProps={{ style: styles.title, readOnly }}
                    />
                  ) : (
                    <InputBase
                      fullWidth
                      value={this.getEventTitle(this.state.event)}
                      inputProps={{ readOnly: true, style: styles.title }}
                    />
                  )}
                  {completed && (
                    <Typography sx={styles.readOnlyAlert}>
                      *לא ניתן לערוך אירוע זה מאחר והוא כבר הסתיים
                    </Typography>
                  )}
                </Box>
                <Box sx={styles.titleLeftSideContainer}>
                  {this.props.admin && this.state.event._id && (
                    <Fab
                      size="small"
                      color="primary"
                      aria-label="add"
                      onClick={() => {
                        this.state.event.isRecurringEvent
                          ? this.setState({ isDeleteDialogOpen: true })
                          : this.props.onDelete(this.state.event);
                      }}
                    >
                      <DeleteIcon />
                    </Fab>
                  )}
                </Box>
              </Box>
            </DialogTitle>
            <DialogContent dividers>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <EventTypeSelect
                    readOnly={readOnly}
                    event={this.state.event}
                    onFieldUpdated={this.onFieldUpdated}
                  />
                </Grid>

                {isEditing &&
                  this.state.event.recurringEventId &&
                  !readOnly && ( //update recurring event
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={this.state.updateType === "single"}
                            onChange={(event) =>
                              this.setState({
                                updateType: event.target.checked
                                  ? "single"
                                  : "recurring",
                              })
                            }
                          />
                        }
                        label="ערוך אירוע זה בלבד"
                      />
                    </Grid>
                  )}
                {!isEditing && this.state.event.type !== "game" && (
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.event.isRecurringEvent}
                          onChange={(event) =>
                            this.onFieldUpdated(
                              "isRecurringEvent",
                              event.target.checked
                            )
                          }
                        />
                      }
                      label="אירוע מחזורי"
                    />
                  </Grid>
                )}
                {(isEditing
                  ? this.state.updateType === "recurring"
                  : this.state.event.isRecurringEvent) && (
                  <>
                    <RecurringEventDatesSelect
                      {...defaultProps}
                      originalStartDate={
                        this.props.event.recurringEvent.startDate
                      }
                    />
                    <RecurringEventTimesSelect
                      {...defaultProps}
                      times={this.props.times}
                      optionalEndTimes={this.optionalEndTimes}
                    />
                  </>
                )}
                {(isEditing
                  ? this.state.updateType === "single"
                  : !this.state.event.isRecurringEvent) && (
                  <>
                    <RegularEventTimes
                      {...defaultProps}
                      prepareTime={this.prepareTime}
                      times={this.props.times}
                      optionalEndTimes={this.optionalEndTimes}
                    />
                  </>
                )}

                <Grid item xs={12}>
                  <TeamSelect
                    {...defaultProps}
                    teams={this.props.teams}
                    completedTeams={this.props.completedTeams}
                    teamsByType={this.props.teamsByType}
                    readOnly={readOnly || this.state.event.recurringEventId}
                    clubCategory={this.props.clubCategory}
                  />
                </Grid>
                {!isAwayGame && (
                  <Grid item xs={12}>
                    <ResourceSelect
                      {...defaultProps}
                      resources={this.props.resources}
                    />
                  </Grid>
                )}

                {this.state.event.type === "game" && (
                  <Grid item xs={12}>
                    <TextField
                      variant="standard"
                      error={this.state.errors.opponent !== undefined}
                      value={this.state.event.gameDetails.opponent}
                      onChange={(event) => {
                        this.onFieldUpdated("gameDetails", {
                          ...this.state.event.gameDetails,
                          opponent: event.target.value,
                        });
                      }}
                      inputProps={{
                        readOnly: readOnly,
                      }}
                      margin="dense"
                      id="opponent"
                      label="קבוצה יריבה"
                      fullWidth
                    />
                  </Grid>
                )}
                {isAwayGame && (
                  <>
                    <Grid item xs={12}>
                      <TextField
                        variant="standard"
                        error={this.state.errors.location !== undefined}
                        value={this.state.event.gameDetails.location}
                        onChange={(event) => {
                          this.onFieldUpdated("gameDetails", {
                            ...this.state.event.gameDetails,
                            location: event.target.value,
                          });
                        }}
                        inputProps={{
                          readOnly: readOnly,
                        }}
                        margin="dense"
                        id="location"
                        label="מיקום"
                        fullWidth
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              this.state.event.gameDetails.transportation
                            }
                            style={{
                              padding: 0,

                              marginRight: 9,
                              marginLeft: 9,
                            }}
                            onChange={(e) =>
                              this.onFieldUpdated("gameDetails", {
                                ...this.state.event.gameDetails,
                                transportation: e.target.checked,
                              })
                            }
                          />
                        }
                        label="הסעה"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <DepartureTimeSelect
                        {...defaultProps}
                        times={this.props.times}
                        prepareTime={this.prepareTime}
                        disabled={!this.state.event.gameDetails.transportation}
                      />
                    </Grid>
                    <Grid item xs={6} sx={styles.center}>
                      <AutoComplete
                        options={["15", "16", "20"]}
                        value={this.state.event.gameDetails.busType}
                        label="מספר מקומות"
                        onChange={this.handleBusTypeChanged}
                        onInputChange={this.handleBusTypeChanged}
                        freeSolo
                        fullWidth
                        disabled={!this.state.event.gameDetails.transportation}
                        textFieldParams={{
                          type: "number",
                          error: this.state.errors.busType !== undefined,
                        }}
                      />
                    </Grid>
                  </>
                )}
              </Grid>

              <div style={{ height: 10 }} />
              {process.env.NODE_ENV === "development" && (
                <div>
                  <div>{this.props.event._id}</div>
                  <div>
                    changed: {this.state.hasEventChanged ? "true" : "false"}
                  </div>
                </div>
              )}
              {this.props.isSaving && (
                <AbsoluteCenter>
                  <CircularProgress />
                </AbsoluteCenter>
              )}
            </DialogContent>
            <DialogActions sx={styles.actions}>
              <span>
                {this.props.event.shouldSendCoachNotification && (
                  <ButtonSx
                    onClick={this.handleSendNotification}
                    sx={{ mr: 0, minWidth: undefined, width: 100 }}
                    disabled={
                      this.props.isSaving ||
                      readOnly ||
                      this.state.hasEventChanged
                    }
                  >
                    שלח עדכון
                  </ButtonSx>
                )}
              </span>
              {readOnly ? (
                <ButtonSx
                  onClick={this.props.onClose}
                  sx={{ mr: 0, minWidth: undefined, width: 100 }}
                >
                  סגירה
                </ButtonSx>
              ) : (
                <span>
                  <ButtonSx
                    onClick={this.props.onClose}
                    sx={{ mr: 1, minWidth: undefined, width: 100 }}
                    disabled={this.props.isSaving || readOnly}
                  >
                    ביטול
                  </ButtonSx>
                  <ButtonSx
                    onClick={this.onComplete}
                    disabled={this.props.isSaving || readOnly}
                    sx={{ mr: 0, minWidth: undefined, width: 100 }}
                  >
                    שמירה
                  </ButtonSx>
                </span>
              )}
            </DialogActions>
          </RTL>
        </Dialog>
        <RecurringActionEventDialog
          action="delete"
          isOpen={this.state.isDeleteDialogOpen}
          onClose={() => this.setState({ isDeleteDialogOpen: false })}
          onAction={(actionType) => {
            this.props.onDelete(this.state.event, actionType);
            this.setState({ isDeleteDialogOpen: false });
          }}
        />
        <RecurringActionEventDialog
          action="update"
          options={["startFromEvent", "futureEvents"]}
          isOpen={this.state.isUpdateDialogOpen}
          onClose={() => this.setState({ isUpdateDialogOpen: false })}
          onAction={(actionType) => {
            this.props.onUpdate(this.state.event, "recurring", actionType);
            this.setState({ isUpdateDialogOpen: false });
          }}
        />

        <SendNotificationDialog
          open={this.state.isSendNotificationDialogOpen}
          onClose={() => this.setState({ isSendNotificationDialogOpen: false })}
          onComplete={this.handleSendNotificationDialogCompleted}
          origin="editing"
        />
      </div>
    );
  }
}

const styles = {
  titleContainer: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
  },
  titleRightSideContainer: {
    flex: 1,
    padding: "10px",
    paddingRight: "22px",
    cursor: "grab",
  },
  titleLeftSideContainer: {
    padding: "10px",
  },
  title: {
    fontSize: 20,
    cursor: "grab",
  },
  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  actions: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  ltr: { flip: false, borderWidth: 10 },
  formControl: {
    width: "100%",
  },
  select: {
    minWidth: 200,
    height: 30,
    marginTop: "2px",
  },
  menuItemChild: {
    paddingLeft: "20px",
  },
  readOnlyAlert: {
    fontSize: 14,
  },
};
