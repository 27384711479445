import React from "react";
import { Collapse, Grid, TextField } from "@mui/material";

import MenuItem from "../../../../base/MenuItem";

export default function ChooseTeamForm({
  clubPlayer,
  onFieldsChanged,
  levels,
  state,
}) {
  const { level, subLevel, errors, teamId } = state;
  const showSubLabel = level !== "" && level.subLevels.length > 1;

  const teamStatus = (team) => {
    const alreadyRegistered =
      clubPlayer &&
      clubPlayer.activeTeams &&
      clubPlayer.activeTeams.indexOf(team._id) > -1;

    let text = team.name;
    if (alreadyRegistered) {
      text += " - הספורטאי רשום לקבוצה זו";
    } else if (team.isFull) {
      text += " - הקבוצה מלאה";
    } else if (!team.isRegistrationOpen) {
      text += " - ההרשמה נסגרה";
    }

    return { text, alreadyRegistered };
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <TextField
          variant="standard"
          fullWidth
          select
          required
          error={Object.prototype.hasOwnProperty.call(errors, "level")}
          label="קטגוריה"
          value={level ? level._id : ""}
          onChange={(event) => {
            const level = levels.find((l) => l._id === event.target.value);
            onFieldsChanged({
              level,
              subLevel: level.subLevels.length === 1 ? level.subLevels[0] : "",
              teamId: "",
            });
          }}
          helperText={errors.level ? "שדה חובה" : ""}
        >
          {levels.map((level) => (
            <MenuItem key={level._id} value={level._id}>
              {level.name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Collapse in={showSubLabel} sx={styles.subCategoryCollapse}>
        <Grid item xs={12}>
          <TextField
            variant="standard"
            fullWidth
            select
            required
            error={Object.prototype.hasOwnProperty.call(errors, "subLevel")}
            label="תת קטגוריה"
            value={subLevel ? subLevel._id : ""}
            onChange={(event) => {
              onFieldsChanged({
                subLevel: level.subLevels.find(
                  (sl) => sl._id === event.target.value
                ),
                teamId: "",
              });
            }}
            helperText={errors.subLevel ? "שדה חובה" : ""}
          >
            {level ? (
              level.subLevels.map((subLevel) => (
                <MenuItem key={subLevel._id} value={subLevel._id}>
                  {subLevel.name}
                </MenuItem>
              ))
            ) : (
              <MenuItem />
            )}
          </TextField>
        </Grid>
      </Collapse>
      <Grid item xs={12}>
        <TextField
          variant="standard"
          fullWidth
          disabled={!subLevel}
          required
          error={Object.prototype.hasOwnProperty.call(errors, "teamId")}
          label="קבוצה"
          select
          value={teamId}
          onChange={(event) => onFieldsChanged({ teamId: event.target.value })}
          helperText={errors.teamId ? "שדה חובה" : ""}
        >
          {subLevel ? (
            subLevel.teams.map((team) => {
              const { text, alreadyRegistered } = teamStatus(team);
              return (
                <MenuItem
                  key={team._id}
                  value={team._id}
                  disabled={alreadyRegistered}
                >
                  {text}
                </MenuItem>
              );
            })
          ) : (
            <MenuItem />
          )}
        </TextField>
      </Grid>
    </Grid>
  );
}

const styles = {
  subCategoryCollapse: {
    width: "100%",
    px: "4px",
  },
};
