import React from "react";

import InfoRow from "../../components/InfoRows/InfoRow";
import {
  subscriptionProductsStatuses,
  subscriptionProductsTypes,
} from "../../utils/dictionaries";
import { displayDate } from "../../utils/helpers";
import TlPaper from "../../components/TlPaper";
import TeamPricing from "./TeamPricing";

const styles = {
  infoRowTitle: { width: 120 },
};

export default function SubscriptionProductDetails(props) {
  const { team } = props;

  let product = team ? team.product : null;
  let subDetails = product ? product.subscriptionDetails : null;

  const getStatus = () => {
    if (product.expired) {
      return subscriptionProductsStatuses.expired;
    } else if (product.active) {
      return subscriptionProductsStatuses.active;
    } else {
      return subscriptionProductsStatuses.inactive;
    }
  };

  const renderSubscriptionProduct = () => {
    return (
      <>
        <InfoRow
          title="שם המסגרת"
          value={product.title}
          titleSx={styles.infoRowTitle}
        />
        <InfoRow
          title="סטטוס"
          value={getStatus()}
          titleSx={styles.infoRowTitle}
        />
        <InfoRow
          title="סוג"
          value={subscriptionProductsTypes[subDetails.subscriptionType]}
          titleSx={styles.infoRowTitle}
        />
        <InfoRow
          title="תאריכים"
          value={`${displayDate(subDetails.startDate)} - ${displayDate(
            subDetails.endDate
          )}`}
          titleSx={styles.infoRowTitle}
        />
        <TeamPricing product={product} />
      </>
    );
  };

  const renderDeletedSubProduct = () => (
    <>
      <InfoRow title="שם המסגרת" value={team.productName} />
      <InfoRow title="סטטוס" value="המסגרת נמחקה" />
    </>
  );

  const renderExternal = () => (
    <>
      <InfoRow title="שם המסגרת" value="מסגרת חיצונית" />
      <div style={{ marginTop: 15 }}>
        *התשלום למסגרת זו אינו מתבצע דרך המועדון
      </div>
    </>
  );

  const renderContent = () => {
    if (team._id) {
      if (!team.productId) {
        return renderExternal();
      } else if (team.product) {
        return renderSubscriptionProduct();
      } else {
        return renderDeletedSubProduct();
      }
    }
  };

  return (
    <TlPaper
      title="פרטי המסגרת"
      isLoading={props.isLoading}
      titleBackground
      sx={{ height: "100%" }}
    >
      {renderContent()}
    </TlPaper>
  );
}
