import React from "react";
import ReactGA from "react-ga4";
import { IconButton, Fab, Tooltip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";

import axios from "../../../../utils/axios";
import EditPlayerAlertDialog from "./EditPlayerAlertDialog";
import DeletePlayerAlertDialog from "./DeletePlayerAlertDialog";
import Table from "../../../../components/table/Table";
import TlPaper from "../../../../components/TlPaper";

export default class PlayerAlerts extends React.Component {
  state = {
    alerts: [],
    editItem: null,
    deleteItem: null,
    addItem: false,
  };

  componentDidMount = async () => {
    ReactGA.send({
      hitType: "pageview",
      page: "/settings/playerAlerts",
      title: "Player Alerts",
    });

    this.loadData();
  };

  loadData = async () => {
    const res = await axios.get("/settings/playerAlerts");
    this.setState({ alerts: res.data });
  };

  columns = [
    { id: "text", label: "שם" },
    { id: "actions", label: "", width: 140 },
  ];

  sortValueCell = (columnId, enrollment) => {
    switch (columnId) {
      default:
        return enrollment[columnId];
    }
  };

  renderCell = (columnId, playerAlert) => {
    switch (columnId) {
      case "actions":
        return (
          <div>
            <IconButton
              onClick={() => this.setState({ editItem: playerAlert })}
              size="large"
            >
              <EditIcon size="small" />
            </IconButton>
            <IconButton
              onClick={() => this.setState({ deleteItem: playerAlert })}
              size="large"
            >
              <DeleteIcon size="small" />
            </IconButton>
          </div>
        );

      default:
        return playerAlert[columnId];
    }
  };

  handleClose = () => {
    this.setState({ deleteItem: null, editItem: null, addItem: false });
  };

  handleCompleted = () => {
    this.setState({ deleteItem: null, editItem: null, addItem: false });
    this.loadData();
  };

  render() {
    const addItem = (
      <Tooltip title="הוספת התראה">
        <Fab
          color="primary"
          aria-label="Add"
          size="small"
          onClick={() => this.setState({ addItem: true })}
        >
          <AddIcon />
        </Fab>
      </Tooltip>
    );

    return (
      <TlPaper title="התראות ספורטאים" leftItem={addItem} titleBackground>
        <Table
          title="התראות"
          columns={this.columns}
          renderCell={this.renderCell}
          rows={this.state.alerts}
          sortValueCell={this.sortValueCell}
        />
        <EditPlayerAlertDialog
          open={this.state.editItem !== null || this.state.addItem}
          onClose={this.handleClose}
          playerAlert={this.state.editItem}
          onComplete={this.handleCompleted}
        />

        <DeletePlayerAlertDialog
          open={this.state.deleteItem !== null}
          onClose={this.handleClose}
          playerAlert={this.state.deleteItem}
          onComplete={this.handleCompleted}
        />
      </TlPaper>
    );
  }
}
