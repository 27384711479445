import React from "react";

import PlayersTable from "../../components/players/PlayersTable";
import ResponsiveContainer from "../../components/ResponsiveContainer";
import PlayersList from "../../components/players/PlayersList";
import TlPaper from "../../components/TlPaper";

export default function Quitters(props) {
  const { players } = props;

  const columns = [
    { id: "name", label: "שם" },
    { id: "createdAt", label: "תאריך רישום" },
    { id: "startDate", label: "תאריך התחלה" },
    { id: "cancelledAt", label: "תאריך פרישה" },
    { id: "cancellationReason", label: "סיבת פרישה" },
  ];

  if (props.directPayments) {
    columns.push({ id: "totalCost", label: "מחיר שנתי" });
    columns.push({ id: "totalPaid", label: "שולם" });
  }

  const renderContent = () => {
    return players.length > 0 ? (
      <ResponsiveContainer
        DesktopComponent={PlayersTable}
        MobileComponent={PlayersList}
        players={players}
        tableHeaders={columns}
        export
        exportFileName={`${props.team.name} - פורשים ומעברי קבוצה`}
        isLoading={props.isLoading}
        renderKey={(p) => p.subscriptions[0]._id}
      />
    ) : (
      <div>לא נמצאו ספורטאים</div>
    );
  };

  const title = (
    <span>
      פורשים ומעברי קבוצה
      {players ? ` (${players?.length})` : ""}
    </span>
  );

  return (
    <TlPaper
      title={title}
      isLoading={props.isLoading}
      titleBackground
      collapsible
      noMinHeight={!props.isLoading}
      initiallyCollapsed={players?.length === 0}
    >
      {!props.isLoading && renderContent()}
    </TlPaper>
  );
}
