import { CircularProgress } from "@mui/material";
import React, { Component } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Link } from "@mui/material";

import EnhancedTable from "../../components/table/EnhancedTable";
import axios from "../../utils/axios";
import PlayerAttendanceChart from "./PlayerAttendanceChart";
import AbsoluteCenter from "../../components/AbsoluteCenter";

const columns = [
  { id: "name", label: "שם הספורטאי", width: 200 },
  { id: "team", label: "קבוצה", width: 200 },
  { id: "attendanceRate", label: "נוכחות", width: 140 },
  { id: "chart", label: "" },
];

const minimumAttendanceRate = 80;

export default class Players extends Component {
  state = { isLoading: true, players: null };

  componentDidMount = () => {
    if (this.props.visible) {
      this.loadData();
    }
  };

  //lazy load players
  componentDidUpdate = async (prevProps) => {
    if (
      !prevProps.visible &&
      this.props.visible &&
      this.state.players === null
    ) {
      this.loadData();
    }
  };

  loadData = async () => {
    this.setState({ isLoading: true });
    const res = await axios.get(
      `/attendances/detailedPlayersStats?minimumAttendanceRate=${minimumAttendanceRate}`
    );

    this.setState({
      isLoading: false,
      players: res.data,
    });
  };

  rowKey = (record) => record.player._id;

  sortValueCell = (columnId, record) => {
    switch (columnId) {
      case "team":
        return record.teams[0].name;
      default:
        return record[columnId];
    }
  };

  renderCell = (columnId, record) => {
    let player;
    switch (columnId) {
      case "name":
        player = record.player;
        return (
          <Link
            component={RouterLink}
            color="primary"
            to={`../players/${player._id}`}
            underline="hover"
          >
            {player.name}
          </Link>
        );

      case "team":
        return (
          <>
            {record.teams.map((t, index) => (
              <Link
                component={RouterLink}
                color="primary"
                to={`../teams/${t.id}`}
                key={t.id}
                underline="hover"
              >
                {t.name + (index + 1 < record.teams.length ? ", " : "")}
              </Link>
            ))}
          </>
        );

      case "attendanceRate":
        return `${Math.round(record.attendanceRate * 100)}%`;

      case "chart":
        return (
          <div>
            <PlayerAttendanceChart attendances={record.attendances} />
          </div>
        );

      default:
        return record[columnId];
    }
  };

  render() {
    return (
      <div>
        {this.state.isLoading ? (
          <AbsoluteCenter>
            <CircularProgress />
          </AbsoluteCenter>
        ) : (
          <>
            <div style={{ marginBottom: 5 }}>
              <b>{this.state.players.length}</b> ספורטאים מתחת ל{" "}
              <b>{minimumAttendanceRate}% נוכחות</b>
            </div>
            <EnhancedTable
              size="small"
              headers={columns}
              renderCell={this.renderCell}
              sortValueCell={this.sortValueCell}
              defaultSortBy={2}
              defaultSortDirection="asc"
              rows={this.state.players}
              renderKey={this.rowKey}
            />
          </>
        )}
      </div>
    );
  }
}
