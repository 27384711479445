import React from "react";
import { Button, ButtonGroup } from "@mui/material";

export default function PlusMinusButton({ onChange, value, fullWidth }) {
  return (
    <ButtonGroup color="primary" size="small" fullWidth={fullWidth}>
      <Button onClick={() => onChange(1)}>+</Button>
      <Button
        disableRipple
        disableFocusRipple
        disableElevation
        disableTouchRipple
      >
        {value}
      </Button>
      <Button
        disableRipple={value === 0}
        disableFocusRipple={value === 0}
        disableElevation={value === 0}
        disableTouchRipple={value === 0}
        onClick={() => value > 0 && onChange(-1)}
      >
        -
      </Button>
    </ButtonGroup>
  );
}
