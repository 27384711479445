import React, { Component } from "react";
import { Link, IconButton, Skeleton, Box } from "@mui/material";

import { Link as RouterLink } from "react-router-dom";
import OpenWithIcon from "@mui/icons-material/OpenWith";
import { connect } from "react-redux";
import moment from "moment";
import ReactGA from "react-ga4";
import InfoIcon from "@mui/icons-material/InfoOutlined";

import axios from "../../utils/axios";
import Table from "../../components/table/Table";
import { displayDate, displayPrice } from "../../utils/helpers";
import DiscountRequestDialog from "./DiscountRequestDialog";
import { discountRequestStatuses } from "../../utils/dictionaries";
import * as Actions from "../../store/actions";
import FiltersBar from "../../components/filters/FiltersBar";
import {
  prepareFilters,
  getFiltersFromUrl,
} from "../../components/filters/prepareFilters";
import withRouter from "../../components/routing/withRouter";
import ToolTip from "../../components/base/ToolTip";
import InfoRow from "../../components/InfoRows/InfoRow";
import TlPaper from "../../components/TlPaper";
import { exportTable } from "../../lib/tableHelpers";

const filtersStructure = [
  { type: "date" },
  {
    name: "discountRequestStatus",
    type: "select",
    options: {
      open: "בקשות חדשות",
      accepted: "בקשות שאושרו",
      rejected: "בקשות שנדחו",
    },
    emptyLabel: "כל הסטטוסים",
    label: "סטטוס",
    // includeAll is neccesary since the default status is open (without it, on refresh the status will from all to open)
    includeAll: true,
  },
];

class DiscountRequests extends Component {
  state = {
    discountRequests: null,
    isDialogOpen: false,
    selectedDiscountRequest: null,
    isSaving: false,
    isLoading: true,
    filters: {
      discountRequestStatus: "open",
      date: {
        from: moment("2022-01-01").startOf("day"),
        to: moment().endOf("day"),
      },
    },
    columns: [
      { id: "clubPlayer", label: "שם הספורטאי" },
      { id: "status", label: "סטטוס" },
      { id: "date", label: "תאריך" },
      { id: "parentName", label: "שם ההורה" },
      { id: "parentPhone", label: "טלפון ההורה" },
      { id: "team", label: "קבוצה" },
      { id: "price", label: "מחיר" },
      { id: "reason", label: "סיבה" },
      { id: "action", label: "" },
    ],
  };

  componentDidMount = async () => {
    ReactGA.send({
      hitType: "pageview",
      page: "/subscriptions/discountRequests",
      title: "Discount Requests",
    });

    let { columns } = this.state;
    if (this.props.selectedClub.superClub) {
      columns.splice(1, 0, { id: "club", label: "ענף" });
    }
    const filters = getFiltersFromUrl(
      this.props.location,
      this.state.filters,
      filtersStructure
    );
    await this.setState({ filters, columns });
    this.loadData(false, false);
    // in case the notification bar counter is out of date
    this.props.loadNotificationBarCounters();
  };

  loadData = async (silence, updateUrl = true) => {
    this.setState({ isLoading: !silence });

    let filters = prepareFilters(this.state.filters, filtersStructure, {
      includeAll: false,
    });
    const queryFilters = [...filters];

    const granularity =
      "granularity=clubPlayer.clubParent&granularity=team&granularity=feeProducts&granularity=product";
    const res = await axios.get(
      `/store/subscriptions?specialRequestType=discount&${queryFilters.join(
        "&"
      )}&${granularity}`
    );
    this.setState({ isLoading: false, discountRequests: res.data });

    if (updateUrl) {
      filters = prepareFilters(this.state.filters, filtersStructure);
      this.props.navigate(`?${filters.join("&")}`, { replace: true });
    }
  };

  handleFilterChange = (filterName, value) => {
    this.setState({
      filters: {
        ...this.state.filters,
        [filterName]: value,
      },
    });
  };

  handleSave = async (accepted) => {
    this.setState((prevState) => {
      const discountRequests = [...prevState.discountRequests];

      const sub = discountRequests.find(
        (req) => req._id === prevState.selectedDiscountRequest._id
      );

      sub.specialRequest.status = "close";
      sub.specialRequest.discountRequest.status = accepted
        ? "accepted"
        : "rejected";

      return {
        isSaving: false,
        isDialogOpen: false,
        selectedDiscountRequest: null,
        discountRequests,
      };
    });

    this.loadData(true, false);
    this.props.loadNotificationBarCounters();
  };

  renderPlaceholder = () => (
    <div>
      {[...Array(10).keys()].map((index) => (
        <Skeleton key={index} animation="wave" height={30} />
      ))}
    </div>
  );

  renderCell = (columnId, subscription) => {
    let data, tooltip;
    switch (columnId) {
      case "clubPlayer":
        return (
          <Link
            component={RouterLink}
            color="primary"
            to={`/clubs/${
              this.props.clubsHash[subscription.club].internalName
            }/players/${subscription.clubPlayer._id}`}
            underline="hover"
          >
            {subscription.clubPlayer.name}
          </Link>
        );

      case "club":
        return this.props.clubsHash[subscription.club]?.clubConfig.general.name;

      case "status":
        data =
          discountRequestStatuses[
            subscription.specialRequest.discountRequest.status
          ];

        if (
          subscription.specialRequest.discountRequest.status === "open" ||
          (!subscription.specialRequest.autoClosed &&
            !subscription.specialRequest.handledBy?._id)
        ) {
          return data;
        }

        if (subscription.specialRequest.autoClosed) {
          tooltip = "הבקשה נסגרה אוטומטית";
        } else {
          tooltip = (
            <div>
              <InfoRow
                title="טופל ע״י"
                value={subscription.specialRequest.handledBy.name}
              />
              {subscription.specialRequest.comment && (
                <InfoRow
                  title="הערות"
                  value={subscription.specialRequest.comment}
                />
              )}
            </div>
          );
        }

        return (
          <ToolTip arrow placement="left" title={tooltip}>
            <Box sx={styles.statusContainer}>
              {data}
              <InfoIcon fontSize="small" style={{ marginRight: 5 }} />
            </Box>
          </ToolTip>
        );

      case "date":
        return displayDate(subscription.createdAt);

      case "parentName":
        return subscription.clubPlayer.parent.firstName;

      case "parentPhone":
        return subscription.clubPlayer.parent.phone;

      case "team":
        return subscription.team.name;

      case "price":
        return displayPrice(subscription.totalPrice);

      case "reason":
        return subscription.specialRequest.discountRequest.reason;

      case "action":
        return (
          subscription.specialRequest.discountRequest.status === "open" && (
            <IconButton size="small">
              <OpenWithIcon />
            </IconButton>
          )
        );

      default:
        return subscription[columnId];
    }
  };

  sortValueCell = (columnId, subscription) => {
    switch (columnId) {
      case "date":
        return moment(subscription.createdAt).unix();

      default:
        return subscription[columnId];
    }
  };

  handleRowClicked = (row) => {
    if (row.specialRequest.discountRequest.status === "open") {
      this.setState({ selectedDiscountRequest: row, isDialogOpen: true });
    }
  };

  handleExportTable = () => {
    return exportTable(
      this.state.discountRequests,
      this.state.columns,
      this.renderCell,
      "csv",
      "בקשות מיוחדות"
    );
  };

  renderContent = () => {
    return (
      <>
        <Table
          title="בקשות"
          columns={this.state.columns}
          renderCell={this.renderCell}
          sortValueCell={this.sortValueCell}
          onExport={this.handleExportTable}
          rows={this.state.discountRequests}
          isLoading={this.state.isLoading}
          onRowClick={this.handleRowClicked}
          defaultSortBy={2}
          defaultSortDirection="desc"
        />
        <DiscountRequestDialog
          subscription={this.state.selectedDiscountRequest}
          clubPlayer={(this.state.selectedDiscountRequest || {}).clubPlayer}
          open={this.state.isDialogOpen}
          onClose={() => this.setState({ isDialogOpen: false })}
          onComplete={this.handleSave}
          isSaving={this.state.isSaving}
        />
      </>
    );
  };

  render() {
    return (
      <TlPaper title="בקשות מיוחדות" titleBackground>
        <FiltersBar
          filters={filtersStructure}
          onFilterChanged={this.handleFilterChange}
          values={this.state.filters}
          onSearch={() => this.loadData(false, true)}
          isSearching={this.state.isLoading}
        />
        {this.state.isLoading ? this.renderPlaceholder() : this.renderContent()}
      </TlPaper>
    );
  }
}

const styles = {
  statusContainer: { display: "flex", alignItems: "center" },
};

function mapStateToProps(state) {
  return {
    selectedClub: state.auth.selectedClub,
    clubsHash: state.auth.clubsHash,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loadNotificationBarCounters: () =>
      dispatch(Actions.general.loadNotificationBarCounters()),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DiscountRequests));
