import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import ReactGA from "react-ga4";

import Alerts from "./Alerts";
import Counts from "./Counts";
import LatestSubscriptions from "./LatestSubscriptions";
import SubscriptionsChart from "./SubscriptionsChart";
import Attendance from "./Attendance/Attendance";
import SuperClubDashboard from "./SuperClub/SuperClubDashboard";
import SeasonRegistrationTreeMap from "../General/Settings/Seasons/SeasonRegistrationTreeMap";
import TlPaper from "../../components/TlPaper";

export default function Dashboard() {
  const params = useParams();
  const navigate = useNavigate();
  const selectedClub = useSelector((state) => state.auth.selectedClub);
  const user = useSelector((state) => state.auth.user);

  const hasPaymentsReportsPermission = user.permissions.paymentsReports;
  const hasHrPermission = user.permissions.hr;

  useEffect(() => {
    if (!selectedClub.clubConfig.general.superclub) {
      ReactGA.send({
        hitType: "pageview",
        page: "/dashboard",
        title: "Dashboard",
      });
    }
  }, []);

  useEffect(() => {
    if (
      !hasPaymentsReportsPermission &&
      !hasHrPermission &&
      user.permissions.calendar
    ) {
      navigate("calendar");
    }
  }, [user]);

  if (selectedClub.clubConfig.general.superclub) {
    return <SuperClubDashboard />;
  }

  // the key params causes sub components to reload when it changes
  return (
    <Grid
      container
      spacing={3}
      justifyContent="center"
      alignItems="stretch"
      direction="row"
    >
      {(hasHrPermission || hasPaymentsReportsPermission) && (
        <Grid item xs={12}>
          <Counts
            selectedClub={selectedClub}
            user={user}
            hasPaymentsReoprtsPermission={hasPaymentsReportsPermission}
            hasHrPermission={hasHrPermission}
            key={params.clubId}
          />
        </Grid>
      )}

      {user.permissions.registrationAdmin && (
        <Grid item xs={12}>
          <TlPaper title="רישום לפעילות" titleBackground>
            <SeasonRegistrationTreeMap defaultSeason />
            <SubscriptionsChart
              key={params.clubId}
              selectedClub={selectedClub}
            />
          </TlPaper>
        </Grid>
      )}
      {hasHrPermission && (
        <>
          <Grid item xs={12}>
            <Attendance key={params.clubId} />
          </Grid>

          <Grid item lg={6} xs={12}>
            <LatestSubscriptions key={params.clubId} />
          </Grid>

          <Grid item lg={6} xs={12}>
            <Alerts selectedClub={selectedClub} key={params.clubId} />
          </Grid>
        </>
      )}
    </Grid>
  );
}
