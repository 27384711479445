import React from "react";
import moment from "moment";
import { Link as RouterLink } from "react-router-dom";
import { Link } from "@mui/material";

import { displayPrice, displayDate } from "../../utils/helpers";
import { subscriptionLogTypes } from "../../utils/dictionaries";
import _ from "lodash";

const formatter = new Intl.NumberFormat("he-IL", {
  style: "currency",
  currency: "ILS",
  minimumFractionDigits: 0,
  signDisplay: "exceptZero",
});

const renderTermsChange = (log) => {
  const data = [];
  log.changes.forEach((change) => {
    switch (change) {
      case "feesPrice":
        log.balanceDiff.forEach((diff) => {
          if (diff.amountDiff !== 0 && diff.isFee) {
            data.push({
              key: "דמי רישום:",
              value: formatter.format(diff.amountDiff),
            });
          }
        });
        break;

      case "prices":
        log.balanceDiff.forEach((diff) => {
          if (diff.amountDiff !== 0 && !diff.isFee) {
            data.push({
              key: `מחיר ${moment(diff.month).format("MM/YY")}`,
              value: formatter.format(diff.amountDiff),
            });
          }
        });
        break;

      case "dates":
        if (log.type === "termsChange") {
          data.push({
            key: "תאריך התחלה",
            value: displayDate(log.snapshot.startDate),
          });
        }
        // snapshot doesn't have endDate of cancallation date
        // : data.push(
        //     <span>
        //       <b>תאריך פרישה: </b>
        //       {displayDate(log.snapshot.cancelled)}
        //     </span>
        //   );
        break;

      case "pricing":
        data.push({ key: "סוג רישום", value: log.snapshot.pricingTitle });
        break;

      default:
        break;
    }
  });
  return data;
};

export const renderDetails = (log, exportMode) => {
  let data = [];
  let amount;
  switch (log.type) {
    case "new":
      if (log.subscriptionType === "direct") {
        data.push({
          key: "עלות",
          value: displayPrice(log.snapshot.price + log.snapshot.feesPrice),
        });
      } else {
        data.push({ value: "מסגרת חיצונית" });
      }

      data.push({
        key: "תאריך התחלה",
        value: displayDate(log.snapshot.startDate),
      });

      break;

    case "newTeamChange":
      return exportMode ? (
        `מעבר מקבוצת ${log.previousTeam.name} לקבוצת ${log.team.name}`
      ) : (
        <span>
          <b>מעבר מקבוצת </b>
          {log.previousTeam.name} <b>לקבוצת </b>
          {log.team.name}
        </span>
      );

    case "cancelledTeamChange":
      return exportMode ? (
        `מעבר מקבוצת ${log.team.name} לקבוצת ${log.nextTeam.name}`
      ) : (
        <span>
          <b>מעבר מקבוצת </b>
          {log.team.name} <b>לקבוצת </b>
          {log.nextTeam.name}
        </span>
      );

    case "cancelled":
      data = [
        { key: "סיבת הפסקת הפעילות", value: log.cancellationReason },
        ...renderTermsChange(log),
      ];
      break;

    case "termsChange":
      data = renderTermsChange(log);
      break;

    case "void":
    case "payment":
      amount =
        log.type === "payment"
          ? log.payment.applicableAmount
          : -log.payment.applicableAmount;
      return exportMode ? (
        `קבלה: ${log.payment.receiptNumber}, סכום: ${displayPrice(amount)}`
      ) : (
        <span>
          <b>קבלה: </b>
          <Link
            component={RouterLink}
            color="primary"
            to={`../payments/transactions/${log.payment.transactionId}`}
            underline="hover"
          >
            {log.payment.receiptNumber}
          </Link>
          , <b>סכום: </b>
          {displayPrice(amount)}
        </span>
      );

    default:
      break;
  }

  if (exportMode) {
    return data
      .map((datum) =>
        datum.key ? `${datum.key}: ${datum.value}` : datum.value
      )
      .join(", ");
  }

  return data.map((datum, i) => (
    <>
      {datum.key ? (
        <span key={datum.key}>
          <b>{datum.key}:</b> {datum.value}
        </span>
      ) : (
        <span>{datum.value}</span>
      )}

      {i < data.length - 1 ? ", " : ""}
    </>
  ));
};

export const baseRenderer = (columnId, log, columnDefenition, exportMode) => {
  switch (columnId) {
    case "clubPlayer":
      return {
        to: `../players/${log.clubPlayer._id}`,
        label: log.clubPlayer.name,
      };

    case "team":
      return {
        to: `../teams/${log.team._id}`,
        label: log.team.name,
      };

    case "details":
      return renderDetails(log, exportMode);

    default:
      return log[columnId];
  }
};

const excludedLogTypes = [
  "virtualPayment",
  "discountRequestSubmitted",
  "otherPaymentMethodRequestSubmitted",
  "discountRequestAccepted",
  "discountRequestRejected",
];

const logTypes = Object.fromEntries(
  Object.entries(subscriptionLogTypes).filter(
    ([type]) => excludedLogTypes.indexOf(type) === -1
  )
);

export const intialLogTypeFilterState = _.mapValues(
  { ...logTypes },
  () => true
);

export const columns = (source) => {
  return [
    { id: "createdAt", label: "תאריך ושעה", preset: "datetime", minWidth: 140 },
    {
      id: "type",
      label: "סוג לוג",
      preset: "mapping",
      mapping: logTypes,
    },
    { id: "clubPlayer", label: "ספורטאי", preset: "link" },
    ...(source === "teamPage"
      ? []
      : [
          { id: "team", label: "קבוצה", preset: "link" },
          { id: "season", label: "עונה", baseRenderer: true },
        ]),
    { id: "details", label: "פרטים", baseRenderer: true },
  ];
};

export const filtersStructure = (source, players) => {
  return [
    { type: "date" },
    ...(source === "teamPage"
      ? []
      : [{ preset: "teams" }, { preset: "season" }]),
    ...(players
      ? [
          {
            type: "multiSelect",
            label: "ספורטאים",
            name: "players",
            options: players.map((player) => ({
              key: player._id,
              value: player.name,
            })),
            emptyLabel: "כל הספורטאים",
            includeAll: true,
            mustSelectAtLeastOne: true,
          },
        ]
      : []),
    {
      type: "multiSelect",
      label: "סוגי לוגים",
      name: "logTypes",
      options: Object.entries(logTypes).map(([logType, logTypeName]) => ({
        key: logType,
        value: logTypeName,
      })),
      emptyLabel: "כל הסוגים",
      includeAll: true,
      mustSelectAtLeastOne: true,
    },
  ];
};
