import React from "react";
import { Box, DialogActions, DialogContent } from "@mui/material";

import Dialog from "../../components/Dialog";
import ButtonSx from "../../components/base/ButtonSx";

export default function DeleteCoachDialog(props) {
  let content;
  switch (props.status) {
    case "success":
      content = "מחיקת המאמן הושלמה!";
      break;

    case "failure":
      content = "אירעה שגיאה. אנא נסו מאוחר יותר";
      break;

    default:
      content = `האם ברצונכם למחוק את ${props.coach.name}?`;
      break;
  }
  return (
    <Dialog
      onClose={props.onClose}
      open={props.open}
      fullWidth
      title="מחיקת מאמן"
    >
      <DialogContent dividers>
        <Box sx={{ py: 2 }}>{content}</Box>
      </DialogContent>
      <DialogActions>
        {props.status !== "success" && (
          <ButtonSx onClick={props.onClose}>ביטול</ButtonSx>
        )}
        <ButtonSx
          isLoading={props.status === "loading"}
          debounce
          onClick={
            props.status === "success" ? props.onComplete : props.onDelete
          }
        >
          {props.status === "success" ? "המשך" : "מחיקה"}
        </ButtonSx>
      </DialogActions>
    </Dialog>
  );
}
