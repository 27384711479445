import React, { useState, useMemo } from "react";
import { CircularProgress, Grid, Box, Stack } from "@mui/material";
import GetAppIcon from "@mui/icons-material/GetApp";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import EmailIcon from "@mui/icons-material/EmailOutlined";
import moment from "moment";

import {
  transactionKinds,
  creditCardTypes,
  transactionStatuses,
  transactionSources,
  checkStatuses,
} from "../../utils/dictionaries";
import InfoRow from "../../components/InfoRows/InfoRow";
import { displayPrice, displayDateTime } from "../../utils/helpers";
import { displayDate } from "../../utils/helpers";
import TlPaper from "../../components/TlPaper";
import ButtonSx from "../../components/base/ButtonSx";
import ToolTip from "../../components/base/ToolTip";
import ResendEmailDialog from "./ResendEmailDialog";
import SnackbarNg from "../../components/base/SnackbarNg";

export default function TransactionDetails(props) {
  const { transaction } = props;
  const [isResendDialogOpen, setIsResendDialogOpen] = useState(false);
  const [isResending, setIsResending] = useState(false);
  const [snackbar, setSnackbar] = useState({ status: null });

  const anyCheckDeposited = useMemo(
    () => transaction.checks?.some((c) => c.status === "deposited"),
    [transaction.checks]
  );

  const handleResending = async (email) => {
    setIsResending(true);
    if (await props.onResendReceipt(email)) {
      setSnackbar({ key: moment().format(), status: "success" });
    } else {
      setSnackbar({ key: moment().format(), status: "error" });
    }
    setIsResending(false);
    setIsResendDialogOpen(false);
  };

  let paymentMethod;

  switch (transaction.kind) {
    case "CreditCardTransaction":
      paymentMethod = `${creditCardTypes[transaction.cardType]} - ${
        transaction.last4
      }`;
      break;

    case "CheckTransaction":
      paymentMethod = "צ׳ק";
      break;

    case "CashTransaction":
      paymentMethod = "מזומן";
      break;

    case "WireTransferTransaction":
      paymentMethod = "העברה בנקאית";
      break;

    default:
      break;
  }

  const renderPayer = () => {
    const payer = transaction.payer || {};
    const tooltip = (
      <Grid container>
        <Grid item xs={12}>
          <InfoRow
            title="שם"
            value={payer.name}
            titleSx={styles.infoRowPayerTitle}
          />
        </Grid>
        <Grid item xs={12}>
          <InfoRow
            title="אימייל"
            value={payer.email || "-"}
            titleSx={styles.infoRowPayerTitle}
          />
        </Grid>
        <Grid item xs={12}>
          <InfoRow
            title="טלפון"
            value={payer.phone}
            titleSx={styles.infoRowPayerTitle}
          />
        </Grid>
      </Grid>
    );

    return (
      <ToolTip arrow placement="left" title={tooltip}>
        <Stack direction="row" alignItems="center">
          {payer.name}
          <InfoIcon fontSize="small" sx={{ ml: "5px" }} />
        </Stack>
      </ToolTip>
    );
  };

  const renderPayments = () => {
    const tooltip = (
      <Grid container spacing={2} sx={{ fontSize: 14, p: 1 }}>
        <Grid item xs={12} container>
          <Grid item xs sx={styles.tooltipHeader}>
            תאריך
          </Grid>
          <Grid item xs sx={styles.tooltipHeader}>
            סכום
          </Grid>
          {transaction.checks && (
            <>
              <Grid item xs={3} sx={styles.tooltipHeader}>
                מספר צ׳ק
              </Grid>
              <Grid item xs={3} sx={styles.tooltipHeader}>
                חשבון
              </Grid>
              <Grid item xs={2} sx={styles.tooltipHeader}>
                סטטוס
              </Grid>
            </>
          )}
        </Grid>
        {(transaction.checks || transaction.payments).map((check) => (
          <Grid item xs={12} container key={check._id}>
            <Grid item xs>
              {displayDate(check.date)}
            </Grid>
            <Grid item xs>
              {displayPrice(check.amount)}
            </Grid>
            {transaction.checks && (
              <>
                <Grid item xs={3}>
                  {check.checkNumber}
                </Grid>
                <Grid item xs={3}>
                  {check.accountNumber
                    ? `${check.bank}-${check.branch}-${check.accountNumber}`
                    : "-"}
                </Grid>
                <Grid item xs={2}>
                  {checkStatuses[check.status] || check.status}
                </Grid>
              </>
            )}
          </Grid>
        ))}
      </Grid>
    );

    return (
      <ToolTip
        arrow
        title={tooltip}
        sx={{ maxWidth: transaction.checks ? 550 : 300 }}
      >
        <Box sx={styles.numberOfPaymentsContainer}>
          {(transaction.checks || transaction.payments).length}
          <InfoIcon fontSize="small" style={{ marginRight: 5 }} />
        </Box>
      </ToolTip>
    );
  };

  const content = (
    <>
      <InfoRow
        title="סטטוס"
        value={transactionStatuses[transaction.status]}
        titleSx={styles.infoRowTitle}
      />
      {transaction.parent && (
        <InfoRow
          title="עסקה מקורית"
          value={transaction.parent.transactionId}
          linkTo={`../payments/transactions/${transaction.parent._id}`}
          titleSx={styles.infoRowTitle}
        />
      )}
      <InfoRow
        title="מספר קבלה"
        value={transaction.receiptNumber}
        titleSx={styles.infoRowTitle}
      />
      {transaction.status === "cancelled" && (
        <InfoRow
          title="מספר קבלת ביטול"
          value={transaction.voidReceiptNumber}
          titleSx={styles.infoRowTitle}
        />
      )}
      <InfoRow
        title="סכום"
        value={displayPrice(transaction.amount)}
        titleSx={styles.infoRowTitle}
      />

      <InfoRow
        title="זמן עסקה"
        value={displayDateTime(transaction.createdAt)}
        titleSx={styles.infoRowTitle}
      />

      <InfoRow
        title="פרטי המשלם"
        value={renderPayer()}
        titleSx={styles.infoRowTitle}
        contentSx={{ flex: undefined }}
      />

      {transaction.cancelledAt && (
        <InfoRow
          title="זמן ביטול"
          value={displayDateTime(transaction.cancelledAt)}
          titleSx={styles.infoRowTitle}
        />
      )}

      <InfoRow
        title="מקור"
        value={transactionSources[transaction.source]}
        titleSx={styles.infoRowTitle}
      />
      {transaction.payment && transaction.payment.user && (
        <InfoRow
          title="מבצע העסקה"
          value={transaction.payment.user.name}
          titleSx={styles.infoRowTitle}
        />
      )}

      {transaction.status === "cancelled" &&
        transaction.payment &&
        transaction.payment.voidUser && (
          <InfoRow
            title="מבצע ביטול העסקה"
            value={transaction.payment.voidUser.name}
            titleSx={styles.infoRowTitle}
          />
        )}

      <InfoRow
        title="סוג עסקה"
        value={transaction.kind}
        options={transactionKinds}
        titleSx={styles.infoRowTitle}
      />

      {transaction.kind === "CreditCardTransaction" && (
        <>
          <InfoRow
            title="אמצעי תשלום"
            value={paymentMethod}
            titleSx={styles.infoRowTitle}
          />
          <InfoRow
            title="מספר תשלומים"
            value={renderPayments()}
            titleSx={styles.infoRowTitle}
          />
        </>
      )}

      {transaction.kind === "CashTransaction" && (
        <>
          <InfoRow
            title="סטטוס הפקדה"
            value={
              transaction.deposit ? (
                <div>
                  הופקד בתאריך {displayDate(transaction.deposit.date)} (
                  {transaction.deposit.iid})
                </div>
              ) : (
                "לא הופקד"
              )
            }
            titleSx={styles.infoRowTitle}
          />
        </>
      )}

      {transaction.kind === "CreditCardTransaction" &&
        transaction.localRefund && (
          <div style={{ marginTop: 10 }}>
            *החזר זה לא בוצע דרך מסוף התשלומים של טריינליט
          </div>
        )}

      {transaction.kind === "CheckTransaction" && (
        <InfoRow
          title="מספר צ׳קים"
          value={renderPayments()}
          titleSx={styles.infoRowTitle}
        />
      )}

      {transaction.kind === "WireTransferTransaction" && (
        <InfoRow
          title="מספר חשבון"
          value={`${transaction.bank}-${transaction.bankBranch}-${transaction.bankAccount}`}
          titleSx={styles.infoRowTitle}
        />
      )}

      <Box sx={styles.actionsRow}>
        {transaction.status !== "declined" && (
          <>
            <ButtonSx onClick={() => setIsResendDialogOpen(true)}>
              <EmailIcon sx={{ mr: 1 }} />
              שליחת הקבלה
            </ButtonSx>
            <Box sx={{ flexGrow: 1 }} />
          </>
        )}

        {props.hasRegistrationAdminPermission &&
          transaction.status === "approved" &&
          !transaction.deposit &&
          !anyCheckDeposited &&
          transaction.amount > 0 && (
            <ButtonSx onClick={props.onVoid}>ביטול עסקה</ButtonSx>
          )}

        {props.hasRegistrationAdminPermission &&
          transaction.status === "settled" &&
          transaction.amount > 0 &&
          transaction.payment.refundableAmount > 0 && (
            <ButtonSx onClick={props.onRefund}>החזר</ButtonSx>
          )}

        {transaction.status !== "declined" && (
          <ButtonSx
            buttonProps={{ href: transaction.receiptUrl, target: "_blank" }}
            sx={{ ml: 1 }}
          >
            <GetAppIcon />
            קבלה
          </ButtonSx>
        )}

        {transaction.status === "cancelled" && (
          <ButtonSx
            buttonProps={{ href: transaction.voidReceiptUrl, target: "_blank" }}
            sx={{ ml: 1 }}
          >
            <GetAppIcon />
            קבלת ביטול
          </ButtonSx>
        )}

        <ResendEmailDialog
          open={isResendDialogOpen}
          onClose={() => {
            setIsResendDialogOpen(false);
          }}
          onSend={handleResending}
          isResending={isResending}
        />
        <SnackbarNg
          snackKey={snackbar.key}
          open={snackbar.status !== null}
          onClose={() => setSnackbar({ status: null })}
          message={
            snackbar.status === "success"
              ? "הקבלה נשלחה בהצלחה"
              : "אירעה שגיאה. אנא נסו מאוחר יותר"
          }
          severity={snackbar.status}
        />
      </Box>
    </>
  );

  return (
    <TlPaper title="פרטי עסקה" titleBackground isLoading={props.isLoading}>
      {props.isLoading ? <CircularProgress /> : content}
    </TlPaper>
  );
}

const styles = {
  infoRowTitle: {
    width: 120,
  },
  actionsRow: {
    marginTop: "20px",
    display: "flex",
    justifyContent: "flex-end",
  },
  tooltipHeader: {
    fontWeight: "bold",
  },
  infoRowPayerTitle: {
    width: "70px",
  },
  numberOfPaymentsContainer: {
    display: "flex",
    alignItems: "center",
    width: 50,
  },
};
