import moment from "moment";
import validator from "validator";

import clubConfig from "../../config/clubs";
import { availablePaymentMethods } from "../../lib/clubHelper";

const PREFILLED = process.env.NODE_ENV === "production" ? false : false;

const randomId = () => {
  let id = Math.round(Math.random() * 10000000)
    .toString()
    .padStart(8, "0");
  for (let index = 0; index < 10; index++) {
    if (validator.isIdentityCard(`${id}${index}`, "he-IL")) {
      return `${id}${index}`;
    }
  }
};

export const initPlayer = (prefilled) => ({
  clubPlayerId: "",
  dobDay: prefilled ? moment().date() : "",
  dobMonth: prefilled ? moment().month() + 1 : "",
  dobYear: prefilled ? moment().add(-7, "years").year() : "",
  firstName: prefilled ? "נועם" : "",
  lastName: prefilled ? "משפחה" : "",
  class: prefilled ? "5" : "",
  phone: prefilled
    ? "" // "0547293" + (Math.floor(Math.random() * 899) + 100).toString()
    : "",
  id: prefilled ? randomId() : "",
  gender: prefilled ? "male" : "",
  hasMedicalIssues: prefilled ? false : null,
  medicalIssues: "",
  isIdUnique: prefilled ? true : null,
  // guest
  guestName: "",
  guestPhone: "",
  guestTeam: "",
  school: "",
  isPassportNumberUnique: null,
  notIsraeli: false,
  passportNumber: "",
  errors: {},
});

export const initExistingPlayerSearchForm = () => ({
  name: "",
  id: "",
  phone: "",
});

const initPlayerForm = (prefilled) => ({
  ...initPlayer(prefilled),
  searchClubPlayersResults: null,
  isSearchingClubPlayers: false,
  showPlayerAlreadyExistDialog: false,
  viewType: "existing",
  existingPlayerSearchForm: initExistingPlayerSearchForm(),
});

export const initParent = (prefilled) => ({
  isPhoneUnique: prefilled ? true : null,
  firstName: prefilled ? "גלעד" : "",
  lastName: prefilled ? "אסיף" : "",
  email: prefilled
    ? `gil${Math.floor(Math.random() * 1000000)}@trainlitapp.com`
    : "",
  phone: prefilled
    ? "0547" + (Math.floor(Math.random() * 100000) + 100000).toString()
    : "",
  street: prefilled ? "חובבי ציון 23" : "",
  houseNumber: prefilled ? "12" : "",
  city: prefilled ? "תל אביב" : "",
  secondParentName: prefilled ? "הורה 2" : "",
  secondParentPhone: prefilled ? "0547232332" : "",
  id: prefilled ? randomId() : "",
  clubParentId: "",
  clubParentSearchResult: null,
  showParentFoundDialog: false,
  //guest
  guestName: "",
  guestPhone: "",
  guestEmail: "",
  notIsraeli: false,
  passportNumber: "",
  isPassportNumberUnique: null,
  errors: {},
});

export const initSubscription = () => ({
  level: "",
  subLevel: "",
  teamType: "",
  teamId: "",
  pricingId: "",
  discountReason: "",
  startDate: null,
  monthlyPrices: [],
  errors: {},
});

const initPayment = (clubInternalName) => ({
  // if cc not available check is the default payment method
  paymentMethod: availablePaymentMethods(clubConfig[clubInternalName])
    .creditCard
    ? "creditCard"
    : "check",
  token: null,
  ccResponse: null,
  last4: null,
  numberOfPayments: "",
  check: initCheck(),
  wireTransfer: {
    bank: "",
    bankBranch: "",
    bankAccount: "",
  },
  donationReceipt: false,
  errors: {},
});

export const initCheck = () => {
  return {
    firstCheckDate: moment(),
    checks: [],
    numberOfChecks: "",
  };
};

export const initForms = (type, clubInternalName) => {
  const forms = {
    player: initPlayerForm(PREFILLED),
    parent: initParent(PREFILLED),
    merchandise: { errors: {} },
    payment: initPayment(clubInternalName, PREFILLED),
    guest: false,
  };

  if (type === "subscription") {
    forms.subscription = initSubscription(PREFILLED);
  }

  return forms;
};
