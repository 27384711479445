import React from "react";
import { Grid, TextField } from "@mui/material";

import TimeSelect from "../TimeSelect";
import MenuItem from "../../../components/base/MenuItem";

const ResourceSelect = (props) => {
  const { event } = props;

  return (
    <Grid item container spacing={1}>
      <Grid item>
        <TextField
          select={props.admin}
          variant="standard"
          margin="dense"
          value={
            props.admin
              ? event.recurringEvent.dayOfWeek
              : `יום ${
                  ["א", "ב", "ג", "ד", "ה", "ו", "ש"][
                    event.recurringEvent.dayOfWeek
                  ]
                }`
          }
          inputProps={{
            readOnly: props.readOnly,
          }}
          style={{ width: 90 }}
          label="יום"
          onChange={(uiEvent) =>
            props.onFieldUpdated("recurringEvent", {
              ...event.recurringEvent,
              dayOfWeek: uiEvent.target.value,
            })
          }
        >
          {["א", "ב", "ג", "ד", "ה", "ו", "ש"].map((day, index) => (
            <MenuItem key={index} value={index}>
              יום {day}׳
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item sx={{ ml: { sm: "20px" } }}>
        <TimeSelect
          label="שעת התחלה"
          value={event.recurringEvent.startTime}
          times={props.times}
          readOnly={props.readOnly}
          onChange={(time) => {
            let endTime = event.recurringEvent.endTime;
            if (
              props.times.indexOf(time) >=
              props.times.indexOf(event.recurringEvent.endTime)
            ) {
              endTime = null;
            }

            props.onFieldUpdated("recurringEvent", {
              ...event.recurringEvent,
              startTime: time,
              endTime,
            });
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <TimeSelect
          label="שעת סיום"
          error={props.errors.recurringEventEndTime}
          value={event.recurringEvent.endTime}
          times={props.optionalEndTimes(event.recurringEvent.startTime)}
          readOnly={props.readOnly}
          onChange={(time) =>
            props.onFieldUpdated("recurringEvent", {
              ...event.recurringEvent,
              endTime: time,
            })
          }
        />
      </Grid>
    </Grid>
  );
};

export default ResourceSelect;
