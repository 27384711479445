import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Slide,
  Stack,
  TableContainer,
  useMediaQuery,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";

import {
  baseRenderer,
  columns,
  filtersStructure,
  intialLogTypeFilterState,
} from "../Reports/subscriptionLogsHelper";
import Report from "../Reports/Report";
import { renderCell, sortValueCell } from "../../lib/tableHelpers";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});

const SubscriprtionLogsTableContainer = styled(TableContainer)({
  maxHeight: "calc(100vh - 170px)",
});

export default function TeamSubscriptionLogsDialog(props) {
  const [initialFiltersState, setInitialFiltersState] = useState({
    date: { from: moment().startOf("day"), to: moment().endOf("day") },
    teams: {},
    logTypes: { ...intialLogTypeFilterState },
    players: { all: true },
  });
  const isLgUp = useMediaQuery((t) => t.breakpoints.up("lg"), { noSsr: true });

  const [wasOpen, setWasOpen] = useState(false);

  useEffect(() => {
    setInitialFiltersState((prev) => ({
      ...prev,
      teams: props.team._id,
      date: {
        from: moment(props.team.createdAt).startOf("day"),
        to: moment().endOf("day"),
      },
    }));
  }, [props.team]);

  useEffect(() => {
    setInitialFiltersState((prev) => ({
      ...prev,
      players: Object.fromEntries(
        (props.players || []).map((p) => [p._id, true])
      ),
    }));
  }, [props.players]);

  useEffect(() => {
    setWasOpen(wasOpen || props.open);
  }, [props.open]);

  const cols = columns("teamPage");
  const structure = filtersStructure("teamPage", props.players || []);

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      fullScreen
      maxWidth="sm"
      sx={styles.dialog}
      dir="rtl"
      keepMounted={props.team?._id !== undefined && wasOpen}
      TransitionComponent={Transition}
    >
      <DialogTitle sx={{ p: 1, pl: 3 }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <div>פעילות בקבוצה</div>
          <IconButton
            sx={{ color: "grey" }}
            aria-label="close"
            onClick={props.onClose}
            size="large"
          >
            <CloseIcon sx={{ fontSize: 30 }} />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent dividers sx={styles.dialogContent}>
        <Report
          url="/store/subscriptionLogs?"
          initialFiltersState={initialFiltersState}
          filtersStructure={structure}
          renderCell={renderCell(cols, false, baseRenderer)}
          sortValueCell={sortValueCell(cols, baseRenderer)}
          columns={cols}
          exportFileName="פעילות"
          renderCellExport={renderCell(cols, true, baseRenderer)}
          defaultSortBy={0}
          defaultSortDirection={"desc"}
          noPaper
          navigation={false}
          updateUrl={false}
          TableContainer={SubscriprtionLogsTableContainer}
          // since the dialog has fixed width the filterbar's regular sizing doesn't work well on large screens and up
          {...(isLgUp ? { filtersBarAutoSizing: true } : {})}
        />
      </DialogContent>
      {props.actions && <DialogActions>{props.actions}</DialogActions>}
    </Dialog>
  );
}

const styles = {
  dialog: {
    maxWidth: 850,
    zIndex: 1100,
    padding: 0,
    backgroundColor: "#f2f4f6",
  },
  dialogContent: {
    px: { xs: 1, sm: 2 },
  },
};
