import moment from "moment";
import _ from "lodash";

import axios from "../../utils/axios";
import { displaySeason } from "../../utils/helpers";

export const setNotificationBarCounters = (counters, clubId) => {
  return {
    type: "SET_NOTIFICATIONS_COUNTER",
    notificationsCounterLastRequest: moment().toISOString(),
    notificationsCounterLastClubId: clubId,
    notificationBarCounters: counters,
  };
};

export const setSeasons = (seasons) => {
  return {
    type: "SET_SEASONS",
    seasons,
  };
};

export const loadNotificationBarCounters = (limitRequests = false) => {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const lastRequest = state.general.notificationsCounterLastRequest;
      const lastSelectedClubId = state.general.notificationsCounterLastClubId;
      const selectedClub = state.auth.selectedClub;
      if (
        limitRequests &&
        lastRequest &&
        selectedClub._id === lastSelectedClubId && // if club has been changed we must reload
        moment().add(-1, "minutes").isBefore(lastRequest)
      ) {
        return;
      }
      const res = await axios.get("/dashboard/nb");
      await dispatch(setNotificationBarCounters(res.data, selectedClub._id));
    } catch (error) {
      setError("loadNotificationBarCounters", "failed");
    }
  };
};

export const loadSeasons = () => {
  return async (dispatch) => {
    try {
      const res = await axios.get("/settings/seasons");
      const seasons = _.sortBy(res.data, (s) => -s.year);

      seasons.forEach((season) => {
        season.name = displaySeason(season.year);
      });

      await dispatch(setSeasons(seasons));
    } catch (error) {
      // do something about it
    }
  };
};

export const setIsLoading = (isLoading) => {
  return {
    type: "STATUS",
    isLoading,
  };
};

export const setError = (errorType, error) => {
  return {
    type: "SET_ERROR",
    errorType,
    error,
  };
};
