import React, { useEffect } from "react";
import { Tabs, Tab, Box, Typography, Divider } from "@mui/material";
import queryString from "query-string";
import { useLocation, useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";

import Coaches from "./Coaches";
import Players from "./Players";
import Teams from "./Teams";
import TlPaper from "../../components/TlPaper";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box pt={2}>
        <Typography variant="h6">נתוני החודש האחרון</Typography>
        <Divider style={{ marginBottom: 20 }} />
        {children}
      </Box>
    </div>
  );
}

const tabs = ["coaches", "teams", "players"];
export default function AttendanceAnalytics() {
  const [value, setValue] = React.useState(0);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const qs = queryString.parse(location.search);
    if (qs.tab) {
      const index = tabs.indexOf(qs.tab);
      setValue(index > -1 ? index : 0);
    }
  }, [location.search]);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/attendance",
      title: "Attendance Analytics",
    });
  }, []);

  const handleChange = (e, newValue) => {
    setValue(newValue);
    navigate(`?tab=${tabs[newValue]}`, { replace: true });
  };

  return (
    <TlPaper
      fullPage
      titleBackground
      titleComponent={
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          sx={{ flex: 1 }}
        >
          <Tab label="מילוי נוכחות" />
          <Tab label="נוכחות קבוצתית" />
          <Tab label="נוכחות ספורטאים" />
        </Tabs>
      }
    >
      <TabPanel value={value} index={0}>
        <Coaches visible={value === 0} />
      </TabPanel>

      <TabPanel value={value} index={1}>
        <Teams visible={value === 1} />
      </TabPanel>

      <TabPanel value={value} index={2}>
        <Players visible={value === 2} />
      </TabPanel>
    </TlPaper>
  );
}
