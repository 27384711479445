import React from "react";
import {
  DialogActions,
  DialogContent,
  CircularProgress,
  Grid,
  Box,
  Alert,
  Link,
  Typography,
} from "@mui/material";
import _ from "lodash";
import { Link as RouterLink } from "react-router-dom";

import axios from "../../../utils/axios";
import RefundPaymentMethod from "./RefundPaymentMethod";
import RefundProducts from "./RefundProducts";
import Dialog from "../../Dialog";
import { displayPrice, roundPrice } from "../../../utils/helpers";
import withIsXs from "../../routing/withIsXs";
import ButtonSx from "../../base/ButtonSx";

const paymentMethodDefaults = (type) => {
  switch (type) {
    case "check":
      return {
        type,
        checkNumber: "",
        accountNumber: "",
        branch: "",
        bank: "",
        date: null,
        refundCheck: true,
        amount: 0,
      };

    case "creditCard":
      return { type, localRefund: false, numberOfPayments: 1 };

    default:
      return { type };
  }
};

const initState = () => ({
  errors: {},
  processing: true,
  success: null,
  comment: "",
  postRefundData: null,
  productsForm: {
    refundedItems: [],
    comment: "",
    errors: {},
    refundAmount: 0,
  },
  paymentMethodForm: {
    errors: {},
    cancelledChecks: [],
    totalCancelledChecksAmount: 0,
    type: "",
    wireTransfer: {
      bank: "",
      bankBranch: "",
      bankAccount: "",
    },
  },
  purchase: null,
});

class RefundDialog extends React.Component {
  state = initState();

  componentDidMount = () => {
    this.loadData();
  };

  componentDidUpdate = async (prevProps) => {
    // onClose
    if (prevProps.open && !this.props.open) {
      this.setState(initState());
    }
    // onOpen
    if (!prevProps.open && this.props.open) {
      this.loadData();
    }
  };

  loadData = async () => {
    if (this.props.transaction) {
      await this.setState({ processing: true });
      const res = await axios.get(
        `/store/purchases/${this.props.transaction.purchaseId}`
      );
      await this.setState({ purchase: res.data, processing: false }, () => {
        this.resetForm();
      });
    }
  };

  resetForm = async () => {
    await this.resetProductsForm();
    await this.resetPaymentMethodForm();
  };

  resetProductsForm = () => {
    const refundedItems = this.state.purchase.products
      .filter((p) => !p.reallocatedAt)
      .map((p) => ({
        checked: false,
        maxRefundAmount: Math.round((p.price + p.refundedAmount) * 100) / 100,
        refundAmount: Math.round((p.price + p.refundedAmount) * 100) / 100,
        tempAmount: Math.round((p.price + p.refundedAmount) * 100) / 100,
        editing: false,
        purchasedItem: p,
      }));

    return this.setState((prevState) => {
      return {
        productsForm: { ...prevState.productsForm, refundedItems },
        processing: false,
      };
    });
  };

  resetPaymentMethodForm = () => {
    const paymentMethod = paymentMethodDefaults(
      this.props.transaction
        ? this.props.transaction.kind.charAt(0).toLowerCase() +
            this.props.transaction.kind.slice(1, -11)
        : ""
    );

    return this.setState((prevState) => {
      return {
        paymentMethodForm: { ...prevState.paymentMethodForm, ...paymentMethod },
      };
    });
  };

  handleCancelledCheckChanged = async (check, checked) => {
    const cancelledChecks = checked
      ? [...this.state.paymentMethodForm.cancelledChecks, check]
      : this.state.paymentMethodForm.cancelledChecks.filter(
          (c) => c._id !== check._id
        );

    await this.setState((prevState) => {
      let value = {
        ...prevState.paymentMethodForm,
        cancelledChecks,
      };

      // payment method must be check if cancelled checks have been selected
      if (
        cancelledChecks.length > 0 &&
        prevState.paymentMethodForm.type !== "check"
      ) {
        value = { ...value, ...paymentMethodDefaults("check") };
      }

      value.totalCancelledChecksAmount =
        this.props.transaction.kind === "CheckTransaction"
          ? Math.round(
              _.sumBy(
                this.props.transaction.checks.filter(
                  (c) => cancelledChecks.indexOf(c) > -1
                ),
                "amount"
              ) * 100
            ) / 100
          : 0;

      return { paymentMethodForm: value };
    });
  };

  handlePaymentMethodChange = (updates) => {
    this.setState((prevState) => {
      if (updates.type) {
        return {
          paymentMethodForm: {
            ...prevState.paymentMethodForm,
            ...paymentMethodDefaults(updates.type),
          },
        };
      }
      return {
        paymentMethodForm: { ...prevState.paymentMethodForm, ...updates },
      };
    });
  };

  refund = async () => {
    const amount = -this.state.productsForm.refundAmount;
    this.setState({ processing: true });
    const onlyCancelledChecks =
      this.state.paymentMethodForm.totalCancelledChecksAmount === -amount;
    try {
      const data = {
        items: this.state.productsForm.refundedItems
          .filter((item) => item.checked)
          .map((item) => ({
            itemId: item.purchasedItem._id,
            amount: item.refundAmount,
          })),
        payment: {
          amount,
          comment: this.state.productsForm.comment,
          paymentMethod: {
            ...(onlyCancelledChecks ? {} : this.state.paymentMethodForm),
            amount:
              //totalCancelledChecksAmount is positive, amount is negative
              Math.round(
                (amount +
                  this.state.paymentMethodForm.totalCancelledChecksAmount) *
                  100
              ) / 100, // only for refund check
          },
        },
        allowMerchandisePartialRefund: this.props.allowMerchandisePartialRefund,
      };

      if (data.payment.paymentMethod.bank) {
        data.payment.paymentMethod.bank = data.payment.paymentMethod.bank.key;
      }

      if (data.payment.paymentMethod.type === "wireTransfer") {
        data.payment.paymentMethod.wireTransfer.bank =
          data.payment.paymentMethod.wireTransfer.bank.key;
      }

      if (this.state.paymentMethodForm.cancelledChecks.length > 0) {
        data.payment.paymentMethod.cancelledChecks =
          this.state.paymentMethodForm.cancelledChecks.map((c) => c._id);
        data.payment.paymentMethod.type = "check";
      }

      if (data.payment.paymentMethod.type === "creditCard") {
        data.payment.paymentMethod.localRefund =
          this.state.paymentMethodForm.localRefund;
        data.payment.paymentMethod.numberOfPayments =
          this.state.paymentMethodForm.numberOfPayments;
      }

      await axios.post(
        `/store/purchases/${this.state.purchase._id}/refund`,
        data
      );

      this.postSuccessfulRefund();
    } catch (error) {
      this.setState({ processing: false, success: false });
    }
  };

  postSuccessfulRefund = async () => {
    const subscriptionRefunds = this.state.productsForm.refundedItems.filter(
      (item) =>
        item.checked &&
        ["subscription", "fee"].indexOf(item.purchasedItem.product.category) >
          -1
    );

    const params = {};
    // there might be 2 subscriptions (after reallocating payments).
    // most cases are product and fees which belong to the same subscription
    if (subscriptionRefunds.length > 0) {
      const res = await axios.get(
        `/store/subscriptions/${subscriptionRefunds[0].purchasedItem.details.subscription}?granularity=team`
      );
      if (res.data.debt !== 0) {
        params.postRefundData = { subscription: res.data };
      }
    }
    this.setState({ processing: false, success: true, ...params });
  };

  validateProducts = async () => {
    let valid = true;
    const errors = {};
    const products = this.state.productsForm;

    if (products.comment.length === 0) {
      errors.comment = true;
      valid = false;
    }

    if (products.refundAmount === 0) {
      errors.refundAmount = "invalid";
      valid = false;
    } else if (
      // can only happen for refunds created before the "complex refunds fix" (before single item's refundedAmount was kept)
      products.refundAmount > this.props.transaction.payment.refundableAmount
    ) {
      errors.maxRefundAmount = "invalid";
      valid = false;
    }

    products.refundedItems.forEach((item) => {
      if (item.editing) {
        item.error = "יש לסיים עריכת שדה זה";
        errors.editingItems = true; //useless - only for consistency
        valid = false;
      }
    });

    await this.handleProductsFormChange({ errors });
    return valid;
  };

  validatePaymentMethod = async () => {
    let valid = true;
    const errors = {};
    const paymentMethod = this.state.paymentMethodForm;

    // checkNumber and date not required if the cancelled checks fully cover the refund amount
    if (
      paymentMethod.totalCancelledChecksAmount !==
      this.state.productsForm.refundAmount
    ) {
      if (paymentMethod.type === "check") {
        if (paymentMethod.checkNumber === "") {
          errors.checkNumber = true;
          valid = false;
        }

        if (paymentMethod.accountNumber === "") {
          errors.accountNumber = true;
          valid = false;
        }

        if (paymentMethod.branch === "") {
          errors.branch = true;
          valid = false;
        }

        if (paymentMethod.bank === "") {
          errors.bank = true;
          valid = false;
        }

        if (paymentMethod.date === null) {
          errors.date = true;
          valid = false;
        }
      } else if (paymentMethod.type === "wireTransfer") {
        if (paymentMethod.wireTransfer.bank === "") {
          errors.wireTransferBank = "שדה חובה";
          valid = false;
        }

        if (paymentMethod.wireTransfer.bankBranch.trim() === "") {
          errors.wireTransferBankBranch = "שדה חובה";
          valid = false;
        }

        if (paymentMethod.wireTransfer.bankAccount.trim() === "") {
          errors.wireTransferBankAccount = "שדה חובה";
          valid = false;
        }
      } else if (paymentMethod.type === "creditCard") {
        if (!paymentMethod.numberOfPayments) {
          errors.numberOfPayments = "שדה חובה";
          valid = false;
        }
      }
    }

    await this.handlePaymentMethodChange({ errors });
    return valid;
  };

  validate = async () => {
    let validProdutcs = await this.validateProducts();
    let validPaymentMethod = await this.validatePaymentMethod();

    return validProdutcs && validPaymentMethod;
  };

  handleSubmit = async () => {
    const valid = await this.validate();
    if (valid) {
      if (this.state.success === null) {
        this.refund();
      } else {
        this.props.onComplete();
      }
    }
  };

  renderSuccessContent = () => {
    const subscription = this.state.postRefundData?.subscription;
    return (
      <Box sx={{ m: 1 }}>
        <Typography variant="h6" sx={{ mb: 2, mt: 1 }}>
          ההחזר בוצע בהצלחה!
        </Typography>
        <Box sx={{ mt: 4 }}>
          {this.state.postRefundData && (
            <Alert severity="info">
              שימו לב שלרישום לקבוצת &quot;{subscription.team.name}
              &quot; קיימת יתרת {subscription.debt > 0 ? "חוב" : "זכות"} של
              <b> {displayPrice(Math.abs(subscription.debt))}</b>.
              <div>
                לעדכון היתרה ומעבר למסך עדכון העלויות{" "}
                <Link
                  component={RouterLink}
                  color="primary"
                  to={`../players/${subscription.clubPlayer}?subscription=${subscription._id}&subMode=edit`}
                >
                  לחצו כאן
                </Link>
              </div>
            </Alert>
          )}
        </Box>
      </Box>
    );
  };

  renderContent = () => {
    if (this.state.processing) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </div>
      );
    } else if (this.state.success !== null) {
      return this.state.success ? this.renderSuccessContent() : "ההחזר נכשל";
    } else {
      return this.renderRefundForm();
    }
  };

  handleProductsFormChange = (updates) => {
    this.setState((prevState) => {
      return {
        productsForm: {
          ...prevState.productsForm,
          ...updates,
        },
      };
    });
  };

  handleRefundItemChange = async (itemIndex, updates) => {
    await this.setState((prevState) => {
      const refundedItems = [...prevState.productsForm.refundedItems];

      refundedItems[itemIndex] = { ...refundedItems[itemIndex], ...updates };
      const refundAmount = roundPrice(
        _.sumBy(refundedItems, (item) => {
          return item.checked ? item.refundAmount : 0;
        })
      );

      return {
        productsForm: {
          ...prevState.productsForm,
          refundedItems,
          refundAmount,
        },
        // reset cancelled checks if refund amount changes
        ...(refundAmount !== prevState.productsForm.refundAmount
          ? {
              paymentMethodForm: {
                ...prevState.paymentMethodForm,
                cancelledChecks: [],
                totalCancelledChecksAmount: 0,
              },
            }
          : {}),
      };
    });
  };

  //when finish editing button clicked
  handleRefundItemAmountChange = (itemIndex) => {
    const refundItem = this.state.productsForm.refundedItems[itemIndex];
    if (
      refundItem.tempAmount > refundItem.maxRefundAmount ||
      refundItem.tempAmount <= 0
    ) {
      this.handleRefundItemChange(itemIndex, { error: "סכום לא תקין" });
    } else {
      this.handleRefundItemChange(itemIndex, {
        editing: false,
        refundAmount: refundItem.tempAmount,
      });
    }
  };

  renderRefundForm = () => {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <RefundProducts
            refundAmount={this.state.productsForm.refundAmount}
            comment={this.state.comment}
            purchase={this.state.purchase}
            form={this.state.productsForm}
            onItemChange={this.handleRefundItemChange}
            onItemAmountChange={this.handleRefundItemAmountChange}
            onChange={this.handleProductsFormChange}
            transaction={this.props.transaction}
            allowMerchandisePartialRefund={
              this.props.allowMerchandisePartialRefund
            }
          />
        </Grid>
        <Grid item xs={12}>
          <RefundPaymentMethod
            form={this.state.paymentMethodForm}
            onChange={this.handlePaymentMethodChange}
            transaction={this.props.transaction}
            onCancelledCheckChange={this.handleCancelledCheckChanged}
            refundAmount={this.state.productsForm.refundAmount}
          />
        </Grid>
      </Grid>
    );
  };

  render() {
    return (
      <Dialog
        maxWidth="md"
        fullWidth
        fullScreen={this.props.isXs}
        onClose={this.props.onClose}
        open={this.props.open}
        title="החזר"
      >
        <DialogContent dividers style={{ padding: 10 }}>
          {this.renderContent()}
        </DialogContent>
        <DialogActions>
          {this.state.success === null && (
            <ButtonSx
              onClick={this.props.onClose}
              disabled={this.state.processing}
            >
              ביטול
            </ButtonSx>
          )}
          <div>
            <ButtonSx
              disabled={this.state.processing}
              onClick={this.handleSubmit}
              debounce
            >
              אישור
            </ButtonSx>
          </div>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withIsXs(RefundDialog);
