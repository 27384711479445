import React, { Component } from "react";
import SyncIcon from "@mui/icons-material/Sync";
import { Badge, Button, IconButton, Tooltip } from "@mui/material";

import UnsentNotificationsDialog from "./UnsentNotificationsDialog";
import axios from "../../../utils/axios";

export default class UnsentNotifications extends Component {
  state = {
    isLoadingUnsentNotifications: false,
    unsentNotifications: null,
    isDialogOpen: false,
  };

  componentDidMount = () => {
    this.loadData();
  };

  componentDidUpdate = async (prevProps) => {
    if (!prevProps.update && this.props.update) {
      this.loadData();
    }
  };

  loadData = async () => {
    this.setState({ isLoadingUnsentNotifications: true });
    const res = await axios.get(`/events/unsentNotificationsCount`);

    this.setState({
      newUnsentNotifications:
        res.data.count - (this.state.unsentNotifications || 0),
      isLoadingUnsentNotifications: false,
      unsentNotifications: res.data.count,
    });

    this.props.onComplete();
  };

  handleClose = (update) => async () => {
    this.setState({ isDialogOpen: false });
    if (update) {
      await this.handleUpdate();
    }
    this.props.onClose && this.props.onClose();
  };

  handleUpdate = async () => {
    await this.loadData();
    this.props.onSent();
  };

  render() {
    const count = this.state.unsentNotifications || 0;

    return (
      <>
        {this.props.mobile ? (
          <Button
            onClick={() => this.setState({ isDialogOpen: true })}
            startIcon={
              <Badge
                overlap="rectangular"
                color="primary"
                badgeContent={this.state.unsentNotifications}
                invisible={count === 0}
              >
                <SyncIcon />
              </Badge>
            }
          >
            עדכונים שלא נשלחו
          </Button>
        ) : (
          <div>
            <Tooltip title="עדכונים שלא נשלחו" arrow>
              <IconButton
                size="small"
                onClick={() => this.setState({ isDialogOpen: true })}
              >
                <Badge
                  overlap="rectangular"
                  color="primary"
                  badgeContent={this.state.unsentNotifications}
                  invisible={count === 0}
                >
                  <SyncIcon />
                </Badge>
              </IconButton>
            </Tooltip>
          </div>
        )}
        <UnsentNotificationsDialog
          open={this.state.isDialogOpen}
          onClose={this.handleClose(false)}
          onComplete={this.handleClose(true)}
          onUpdate={this.handleUpdate}
          count={this.state.unsentNotifications}
        />
      </>
    );
  }
}
