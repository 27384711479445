import React, { useMemo } from "react";
import { Box, Button, Link } from "@mui/material";
import OpenInBrowserIcon from "@mui/icons-material/OpenInBrowser";

import PlayersTable from "../../components/players/PlayersTable";
import ResponsiveContainer from "../../components/ResponsiveContainer";
import PlayersList from "../../components/players/PlayersList";
import TlPaper from "../../components/TlPaper";

export default function RegisteredPlayers(props) {
  const { players } = props;

  const singlePricing = useMemo(
    () =>
      players?.some((p) => p.subscriptions[0]?.pricing.singlePricing === true),
    [players]
  );

  const columns = [
    { id: "name", label: "שם" },
    { id: "phone", label: "טלפון" },
    { id: "dateOfBirth", label: "תאריך לידה" },
    ...(singlePricing ? [] : [{ id: "pricingType", label: "סוג רישום" }]),
    { id: "class", label: "כיתה" },
    { id: "id", label: "ת.ז" },
    { id: "gender", label: "מין" },
  ];

  if (props.directPayments) {
    columns.push({ id: "totalCost", label: "מחיר שנתי" });
    columns.push({ id: "totalPaid", label: "שולם" });
  }

  const renderContent = () => {
    return players.length > 0 ? (
      <ResponsiveContainer
        DesktopComponent={PlayersTable}
        MobileComponent={PlayersList}
        players={players}
        tableHeaders={columns}
        export
        exportFileName={`${props.team.name} - ספורטאים`}
        isLoading={props.isLoading}
      />
    ) : (
      <div>לא נמצאו ספורטאים</div>
    );
  };

  const title = (
    <span>
      ספורטאים רשומים
      {players ? ` (${players?.length})` : ""}
    </span>
  );

  const subscriptionLogsDialog = (
    <Link
      color="primary"
      href="#"
      onClick={props.onSubscriptionLogsClicked}
      component={Button}
      disabled={props.isLoading}
      sx={{ backgroundColor: "transparent" }}
      underline="hover"
    >
      <span style={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ display: { xs: "none", sm: "inline-block" } }}>
          דו״ח&nbsp;
        </Box>
        פעילות&nbsp;
        <OpenInBrowserIcon />
      </span>
    </Link>
  );

  return (
    <TlPaper
      title={title}
      leftItem={subscriptionLogsDialog}
      isLoading={props.isLoading}
      titleBackground
      collapsible
      noMinHeight={!props.isLoading}
      initiallyCollapsed={players?.length === 0}
      titleSx={{ p: { xs: 0, sm: 1 }, pl: { xs: 1, sm: 3 } }}
    >
      {!props.isLoading && renderContent()}
    </TlPaper>
  );
}
