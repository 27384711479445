import React from "react";
import { Box, Dialog, DialogActions, Typography } from "@mui/material";

import { FaExclamation } from "react-icons/fa6";
import { FaCheck } from "react-icons/fa6";
import ButtonSx from "./base/ButtonSx";

const styles = {
  iconContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 50,
  },
  childernContainer: {
    minWidth: 300,
    minHeight: 80,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: "20px",
    paddingTop: "10px",
  },
  icon: {
    border: "1px solid #EFEFEF",
    color: "#EFEFEF",
    boxShadow: 10,
    fontSize: 40,
    borderRadius: "30px",
    padding: "5px",
  },
};

const icons = {
  warning: FaExclamation,
  error: FaExclamation,
  success: FaCheck,
};

export default function MessageDialog(props) {
  const Icon = icons[props.type];

  return (
    <Dialog
      dir="rtl"
      onClose={props.onClose}
      open={props.open}
      sx={styles.dialog}
    >
      <Box
        sx={{ ...styles.iconContainer, backgroundColor: `${props.type}.light` }}
      >
        <Icon style={styles.icon} />
      </Box>
      <Box sx={styles.childernContainer}>
        {props.title && (
          <div>
            <Typography gutterBottom variant="h6">
              {props.title}
            </Typography>
          </div>
        )}
        {props.children && <div>{props.children}</div>}
      </Box>

      <DialogActions>
        {props.onCancel && (
          <ButtonSx
            onClick={props.onCancel}
            fullWidth
            {...props.cancelButtonProps}
          >
            ביטול
          </ButtonSx>
        )}
        {props.onOk && (
          <ButtonSx onClick={props.onOk} fullWidth>
            {props.okButtonText || "אישור"}
          </ButtonSx>
        )}
      </DialogActions>
    </Dialog>
  );
}
