import React, { useState } from "react";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

import EnterPassportNumberDialog from "./EnterPassportNumberDialog";

export default function PassportNumberTextField(props) {
  const [isPassportNumberDialogOpen, setIsPassportNumberDialogOpen] =
    useState(false);

  const handlePassportNumberEntered = async (passportNumber) => {
    const res = await props.onPassportNumberChanged(passportNumber);
    setIsPassportNumberDialogOpen(false);
    return res;
  };

  return (
    <>
      <TextField
        label="מספר דרכון"
        variant="standard"
        fullWidth
        required
        error={props.error !== undefined}
        helperText={props.error || ""}
        value={props.value}
        onClick={() => setIsPassportNumberDialogOpen(true)}
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputAdornment position="end">
              <IconButton sx={{ m: "3px" }} edge="end">
                <EditIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <EnterPassportNumberDialog
        open={isPassportNumberDialogOpen}
        onClose={() => setIsPassportNumberDialogOpen(false)}
        userType={props.userType}
        onComplete={handlePassportNumberEntered}
      />
    </>
  );
}
