import React, { useState, useEffect } from "react";
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Checkbox,
} from "@mui/material";
import moment from "moment";

import axios from "../../utils/axios";
import InfoRow from "../../components/InfoRows/InfoRow";
import { displayPrice } from "../../utils/helpers";
import { purchaseProductStatuses } from "../../utils/dictionaries";
import ResponsiveContainer from "../../components/ResponsiveContainer";
import SnackbarNg from "../../components/base/SnackbarNg";
import ButtonSx from "../../components/base/ButtonSx";

export default function PurchaseProducts(props) {
  const [snackbar, setSnackbar] = useState({});
  const [products, setProducts] = useState([]);
  const [hasRefundedItems, setHasRefundedItems] = useState(false);
  const [hasReallocatedItems, setHasReallocatedItems] = useState(false);

  useEffect(() => {
    const newProducts = props.purchase.products.filter((p) => !p.reallocatedAt);
    setHasRefundedItems(
      newProducts.some(
        (item) => item.refundedAmount !== 0 && props.purchase.type !== "refund"
      )
    );

    setHasReallocatedItems(
      newProducts.length !== props.purchase.products.length
    );

    setProducts(
      newProducts.map((product) => ({
        checked: false,
        product,
      }))
    );
  }, [props.purchase]);

  const updateLocalProductsStatus = () => {
    setProducts(
      products.map((item) => {
        if (item.checked) {
          item.checked = false;
          item.product.status = "received";
        }
        return item;
      })
    );
  };

  const handleSetProductsAsReceived = async () => {
    try {
      const purchaseProductIds = products
        .filter((item) => item.checked)
        .map((item) => item.product._id);
      await axios.patch(
        `/store/purchases/${props.purchase._id}/products/setAsReceived`,
        { purchaseProductIds }
      );

      updateLocalProductsStatus();

      showSnackbar("success", "המוצרים עודכנו בהצלחה");
    } catch (error) {
      showSnackbar("error", "עדכון המוצרים נכשל");
    }
  };

  const showSnackbar = (severity, message) => {
    setSnackbar({ severity, message, key: moment().format() });
  };

  const toggleItemChecked = (index) => () => {
    const newProducts = [...products];
    newProducts[index].checked = !newProducts[index].checked;
    setProducts(newProducts);
  };

  const renderItemPrice = (product) => {
    const orgRefund =
      product.refundedAmount !== 0 && props.purchase.type !== "refund"; // line through only on the original transaction not the refund

    return (
      <div style={{ textAlign: "left" }}>
        {orgRefund && (
          <div>*{displayPrice(product.price + product.refundedAmount)}</div>
        )}
        <Typography
          variant="body2"
          style={orgRefund ? { textDecoration: "line-through" } : {}}
        >
          {displayPrice(product.price * product.quantity)}
        </Typography>
      </div>
    );
  };

  const renderProduct = (item, index) => {
    // product is product purchase
    const { product } = item;

    const lineThrough =
      product.status === "returned" && props.purchase.type !== "refund"; // line through only on the original transaction not the refund

    return (
      <ListItem sx={styles.listItem} key={product._id}>
        {props.hasRegistrationAdminPermission &&
          props.purchase.type !== "refund" && (
            <ResponsiveContainer
              mobileContent={<span />}
              desktopContent={
                <ListItemIcon>
                  {(product.product.category === "merchandise" ||
                    (product.product.category === "fee" &&
                      product.product.deliverable)) && (
                    <Checkbox
                      edge="start"
                      checked={item.checked || product.status !== "pending"}
                      tabIndex={-1}
                      disableRipple
                      onChange={toggleItemChecked(index)}
                      disabled={product.status !== "pending"}
                    />
                  )}
                </ListItemIcon>
              }
            />
          )}
        <ListItemText
          disabled
          primary={
            <span style={lineThrough ? { textDecoration: "line-through" } : {}}>
              {product.product.title}
            </span>
          }
          secondaryTypographyProps={{ component: "span" }}
          secondary={renderDescription(product)}
        />
        {renderItemPrice(product)}
      </ListItem>
    );
  };

  const renderDescription = (product) => {
    let info;
    switch (product.type) {
      case "subscription":
        info = (
          <InfoRow
            titleSx={styles.infoRowTitle}
            title="קבוצה"
            value={product.details.team.name}
            sx={styles.infoRow}
          />
        );
        break;

      case "merchandise":
        if (props.purchase.type === "refund") {
          info = "החזרת מוצר";
        }

        info = (
          <>
            {product.details?.optionTitle && (
              <InfoRow
                titleSx={styles.infoRowTitle}
                title={product.details.optionsType}
                value={product.details.optionTitle}
              />
            )}
            <InfoRow
              titleSx={styles.infoRowTitle}
              title="סטטוס"
              value={purchaseProductStatuses[product.status]}
            />
          </>
        );
        break;

      default:
        break;
    }

    return (
      <>
        {info}
        {renderClubPlayer(product)}
      </>
    );
  };

  const renderClubPlayer = (product) => {
    if (product.clubPlayer?._id) {
      return (
        <InfoRow
          titleSx={styles.infoRowTitle}
          title="ספורטאי"
          value={product.clubPlayer.name}
          linkTo={
            props.hasHrPermission && product.clubPlayer._id
              ? `../players/${product.clubPlayer._id}`
              : null
          }
          sx={styles.infoRow}
        />
      );
    } else if (product.guestPlayer) {
      let player = product.guestPlayer.name;
      if (product.guestPlayer.phone) {
        player = `${player}, ${product.guestPlayer.phone}`;
      }

      let parent = product.guestPlayer.parentName;
      if (product.guestPlayer.parentPhone) {
        parent = `${parent}, ${product.guestPlayer.parentPhone}`;
      }
      return (
        <>
          <InfoRow
            titleSx={styles.infoRowTitle}
            title="ספורטאי"
            value={`${player} (אורח)`}
            sx={styles.infoRow}
          />
          <InfoRow
            titleSx={styles.infoRowTitle}
            title="הורה"
            value={parent}
            sx={styles.infoRow}
          />
        </>
      );
    }
  };

  const { purchase } = props;

  const openProducts =
    purchase.products.filter((p) => p.status === "pending").length > 0;

  return (
    <>
      <List disablePadding>
        {products.map((item, i) => renderProduct(item, i))}
        <ListItem>
          <ListItemText>
            {props.hasRegistrationAdminPermission && openProducts && (
              <ResponsiveContainer
                mobileContent={<span />}
                desktopContent={
                  <ButtonSx
                    onClick={handleSetProductsAsReceived}
                    disabled={!products.some((i) => i.checked)}
                  >
                    סימון הפריטים כהתקבלו
                  </ButtonSx>
                }
              />
            )}
          </ListItemText>

          <Typography variant="subtitle1" sx={styles.total}>
            סה״כ:{" "}
            {purchase.refundedAmount && purchase.refundedAmount !== 0 ? (
              <div style={{ textAlign: "left" }}>
                *{displayPrice(purchase.amount + purchase.refundedAmount)}
                <div>
                  <s>{displayPrice(purchase.amount)}</s>
                </div>
              </div>
            ) : (
              displayPrice(purchase.amount)
            )}
          </Typography>
        </ListItem>
        {(hasRefundedItems || hasReallocatedItems) && (
          <>
            {hasRefundedItems && <div> &nbsp;*סכום לאחר ניכוי החזרים</div>}
            {hasReallocatedItems && (
              <div>
                {hasRefundedItems ? "*" : ""}*חלק מהסכום של עסקה זו הוקצה לטובת
                פריטים השונים מהפריטים המקוריים שלשמם בוצעה העסקה. רשימת הפריטים
                המקוריים זמינה בקבלה
              </div>
            )}
          </>
        )}
      </List>
      <SnackbarNg
        snackKey={snackbar.key}
        open={snackbar.key !== undefined}
        onClose={() => setSnackbar({})}
        message={snackbar.message}
        severity={snackbar.severity}
      />
    </>
  );
}

const styles = {
  listItem: {
    marginTop: "10px",
    marginBottom: "10px",
    border: "1px solid #EEE",
    borderRadius: "5px",
  },
  total: {
    fontWeight: "bold",
  },
  infoRowTitle: {
    width: 60,
  },
  infoRow: {
    maxWidth: "calc(100vw - 130px)",
  },
};
