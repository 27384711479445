import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Link } from "@mui/material";

import Table from "../../components/table/Table";
import { genders, teamStatuses, teamTypes } from "../../utils/dictionaries";
import { exportTable } from "../../lib/tableHelpers";
import { displayPrice } from "../../utils/helpers";

export default function TeamsTable(props) {
  const tableHeaders = (exportMode = false) => {
    let headers = [
      { id: "name", label: "שם הקבוצה" },
      { id: "seasonYear", label: "עונה" },
      { id: "coach", label: "מאמן" },
      { id: "product", label: "מסגרת" },
      { id: "level", label: "קטגוריה" },
      { id: "status", label: "סטטוס" },
      { id: "teamType", label: "סוג" },
      { id: "gender", label: "מין" },
      { id: "registeredPlayers", label: "ספורטאים רשומים", width: 130 },
      { id: "unregisteredPlayers", label: "ספורטאים לא רשומים", width: 130 },
      // ...(props.showExtra
      //   ? [
      //       { id: "quitters", label: "פורשים", width: 130 },
      //       { id: "cost", label: "צפי הכנסות", width: 130 },
      //       { id: "paid", label: "תקבולים", width: 130 },
      //     ]
      //   : []),
      ...(exportMode ? [{ id: "assistants", label: "עוזרי מאמן" }] : []),
    ];

    if (props.context === "coach") {
      // don't render coach column on coach page
      headers = headers.filter((h) => h.id !== "coach");
    }

    return headers;
  };

  const sortValueCell = (columnId, team) => {
    switch (columnId) {
      case "coach":
        return (team.clubCoach || {}).name;

      case "gender":
      case "teamType":
      case "registeredPlayers":
      case "unregisteredPlayers":
      case "quitters":
      case "totalPlayers":
      case "product":
      case "level":
        return renderCell(columnId, team);

      case "cost":
      case "paid":
        return team.income[columnId];

      default:
        return team[columnId];
    }
  };

  const renderCell = (columnId, team) => {
    switch (columnId) {
      case "name":
        return (
          <Link
            component={RouterLink}
            color="primary"
            to={`../teams/${team._id}`}
            underline="hover"
          >
            {team.baseName}
          </Link>
        );
      case "coach":
        if (team.clubCoach) {
          return (
            <Link
              component={RouterLink}
              color="primary"
              to={`../coaches/${team.clubCoach._id}/`}
              underline="hover"
            >
              {team.clubCoach.name}
            </Link>
          );
        } else {
          return "-";
        }

      case "product":
        return team.productId ? team.productName : "חיצונית";

      case "teamType":
        return (teamTypes[props.clubCategory] || teamTypes.default)[
          team.teamType
        ];

      case "gender":
        return genders[team.gender];

      case "registeredPlayers":
        return team.playersStats.registered;

      case "unregisteredPlayers":
        return team.playersStats.unregistered;

      case "quitters":
        return team.playersStats.quitters;

      case "totalPlayers":
        return team.playersStats.unregistered + team.playersStats.registered;

      case "status":
        return teamStatuses[team.status];

      case "level":
        return team.teamLevel
          ? `${team.teamLevel.name}${
              team.teamSubLevel ? ` / ${team.teamSubLevel.name}` : ""
            }`
          : "-";

      case "cost":
        return displayPrice(team.income?.cost);
      case "paid":
        return displayPrice(team.income?.paid);
      case "seasonYear":
        return `${team.seasonYear - 2000}/${team.seasonYear - 2000 + 1}`;

      default:
        return team[columnId];
    }
  };

  const handleExportTable = () => {
    exportTable(
      props.teams,
      tableHeaders(true),
      renderCellExport,
      "csv",
      "קבוצות"
    );
  };

  const renderCellExport = (columnId, team) => {
    switch (columnId) {
      case "name":
        return team.baseName;

      case "coach":
        return team.clubCoach.name;

      case "assistants":
        return team.assistants.map((a) => a.name).join(", ");

      default:
        return renderCell(columnId, team);
    }
  };

  return (
    <Table
      columns={tableHeaders()}
      renderCell={renderCell}
      sortValueCell={sortValueCell}
      rows={props.teams}
      title="קבוצות"
      stickyHeader
      onExport={handleExportTable}
      onAdd={props.onAddItem}
      onSettings={props.onOpenSettings}
    />
  );
}
