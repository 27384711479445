import React from "react";
import {
  TextField,
  DialogActions,
  DialogContent,
  Typography,
  CircularProgress,
  Grid,
  Input,
  Alert,
} from "@mui/material";
import _ from "lodash";
import { connect } from "react-redux";

import clubConfig from "../config/clubs";
import MenuItem from "./base/MenuItem";
import axios from "../utils/axios";
import { generateErrorMessage } from "../lib/formHelpers";
import { teamTypes } from "../utils/dictionaries";
import Dialog from "./Dialog";
import AbsoluteCenter from "./AbsoluteCenter";
import { displaySeason } from "../utils/helpers";
import ButtonSx from "./base/ButtonSx";
import withIsXs from "./routing/withIsXs";

class TeamDialog extends React.Component {
  initForm = () => {
    const team = { ...this.props.team } || {};
    return {
      clubCoach: team.clubCoach ? team.clubCoach._id : "",
      assistants: team.assistants ? team.assistants.map((a) => a._id) : [],
      teamType: team.teamType || "",
      gender: team.gender || "",
      name: team.baseName || "",
      igudId: team.igud ? team.igud.id || "" : "",
      season: team._id ? team.season._id : "",
      productId: this.props.team ? team.productId || "external" : "",
      teamLevel: (team.teamLevel || {})._id || "",
      teamSubLevel: (team.teamSubLevel || {})._id || "",
      subscriptionsLimit: team.subscriptionsLimit || 0,
      subscriptionsLimitFocused: false,
      isRegistrationOpen: this.props.team
        ? team.isRegistrationOpen
          ? "true"
          : "false"
        : "true",
      canChangeProduct: team._id ? team.canChangeProduct : true,
    };
  };

  state = {
    clubCoaches: [],
    isLoading: true,
    isSaving: false,
    instantValidate: false, // validate only after first submit try
    form: this.initForm(),
    formErrors: {},
    productsBySeason: [],
    teamLevels: null,
    isWarningDialogOpen: false,
    hasPayments:
      clubConfig[this.props.selectedClub.internalName].payments.enabled,
  };

  componentDidUpdate = async (prevProps) => {
    if (!prevProps.open && this.props.open) {
      this.loadData();
    }
  };

  prepareProductsBySeason = (seasons, rawProducts, team) => {
    const products = rawProducts || [];
    const productsBySeason = _.groupBy(products, "season");

    if (team && !seasons.find((s) => s._id === team.season._id)) {
      seasons.push({ ...team.season });
      if (this.state.hasPayments) {
        productsBySeason[team.season._id] = products.filter(
          (p) => p._id === team.productId
        );
      }
    }

    // add team's product if it has expired
    if (
      this.state.hasPayments &&
      team &&
      team.productId &&
      !productsBySeason[team.season._id].find((p) => p._id === team.productId)
    ) {
      productsBySeason[team.season._id].push({
        _id: team.productId,
        title: `${team.productName} - מסגרת הסתיימה`,
        season: team.season._id,
      });
    }

    seasons.forEach((s) => {
      productsBySeason[s._id] = productsBySeason[s._id] || [];
      if (
        s.canCreateTeamsAndProducts ||
        (team && !team.season.canCreateTeamsAndProducts && !team.productId)
      ) {
        productsBySeason[s._id] = _.sortBy(
          [...productsBySeason[s._id], { _id: "external", title: "חיצונית" }],
          "title"
        );
      }
    });

    return productsBySeason;
  };

  loadData = async () => {
    this.setState({ isLoading: true });
    const promises = [];
    const data = {};

    promises.push(
      axios
        .get("/settings/seasons?canCreateTeamsAndProducts=true")
        .then((res) => {
          data.seasons = _.sortBy(res.data, "year");
        })
    );

    promises.push(
      axios
        .get("/clubCoaches")
        .then((res) => (data.clubCoaches = _.sortBy(res.data, "name")))
    );

    promises.push(
      axios
        .get("/settings/teamLevels")
        .then((res) => (data.teamLevels = res.data))
    );

    if (this.state.hasPayments) {
      promises.push(
        axios
          .get("/store/products?category=subscription&expired=false")
          .then((res) => {
            data.products = _.sortBy(res.data, "name");
          })
      );
    }

    await Promise.all(promises);
    const productsBySeason = this.prepareProductsBySeason(
      data.seasons,
      data.products,
      this.props.team
    );

    this.setState({
      clubCoaches: data.clubCoaches,
      productsBySeason,
      teamLevels: data.teamLevels,
      seasons: data.seasons,
      isLoading: false,
    });
  };

  validateField = (form, field, errors) => {
    let name;
    switch (field) {
      case "name":
        name = form.name.trim();
        if (name === "") {
          errors.name = "required";
        } else if (name.length < 2) {
          errors.name = "invalid";
        }
        break;

      case "teamType":
      case "season":
      case "productId":
      case "clubCoach":
      case "gender":
      case "teamLevel":
        if (form[field] === "") {
          errors[field] = "required";
        }
        break;

      case "teamSubLevel":
        if (form.teamLevel && form.teamSubLevel === "") {
          const teamLevel = this.state.teamLevels.find(
            (tl) => tl._id === form.teamLevel
          );
          if (teamLevel.subLevels.length > 0) {
            errors.teamSubLevel = "required";
          }
        }
        break;

      case "assistants":
        if (form.assistants.find((a) => a === form.clubCoach)) {
          errors.assistants = "מאמן הקבוצה לא יכול לשמש כעוזר מאמן";
        }

        break;

      default:
        break;
    }
  };

  validate = (form) => {
    const errors = {};

    this.validateField(form, "name", errors);
    this.validateField(form, "season", errors);
    if (this.state.hasPayments) {
      this.validateField(form, "productId", errors);
    }
    this.validateField(form, "teamType", errors);
    this.validateField(form, "clubCoach", errors);
    this.validateField(form, "gender", errors);
    this.validateField(form, "teamLevel", errors);
    this.validateField(form, "teamSubLevel", errors);
    this.validateField(form, "assistants", errors);

    return errors;
  };

  handleChange = (updates) => {
    if (updates.teamType === "school") {
      updates.igudId = "";
    }

    if (updates.season) {
      updates.productId = "";
    }

    this.setState((prevState) => {
      const form = { ...prevState.form, ...updates };

      let formErrors = prevState.formErrors;
      if (prevState.instantValidate) {
        formErrors = this.validate(form);
      }

      return {
        form,
        formErrors,
      };
    });
  };

  handleSubmit = async (force = false) => {
    const formErrors = this.validate(this.state.form);
    this.setState({ formErrors, instantValidate: true });

    if (Object.keys(formErrors).length === 0) {
      this.setState({ isSaving: true });
      const data = { ...this.state.form };
      if (
        this.state.form.productId === "" ||
        this.state.form.productId === "external"
      ) {
        delete data.productId;
      }

      if (!data.teamSubLevel) {
        delete data.teamSubLevel;
      }

      let res;

      delete data.subscriptionsLimitFocused;
      data.isRegistrationOpen = data.isRegistrationOpen === "true";

      if (this.props.team) {
        if (
          !force &&
          data.productId !== this.props.team.productId &&
          this.props.registeredPlayers &&
          this.props.registeredPlayers.length > 0
        ) {
          this.setState({ isWarningDialogOpen: true, isSaving: false });
          return;
        } else {
          res = await axios.patch(`/teams/${this.props.team._id}`, data);
        }
      } else {
        res = await axios.post("/teams/", data);
      }
      this.setState({
        isSaving: false,
        formErrors: {},
        form: this.initForm(),
        instantValidate: false,
      });
      this.props.onComplete(res.data);
    }
  };

  defaultParams = (field) => ({
    error: Object.prototype.hasOwnProperty.call(this.state.formErrors, field),
    helperText: generateErrorMessage(field, this.state.formErrors),
    disabled: this.state.isLoading || this.state.isSaving,
    value: this.state.form[field],
    onChange: (e) => this.handleChange({ [field]: e.target.value }),
    margin: "dense",
    fullWidth: true,
  });

  renderAssistantsNames = (assistantsIds) => {
    return assistantsIds
      .map((id) => this.state.clubCoaches.find((c) => c._id === id).name)
      .join(", ");
  };

  renderContent = () => {
    const disabled = this.state.isLoading || this.state.isSaving;
    const teamLevel = this.state.form.teamLevel
      ? this.state.teamLevels.find((tl) => tl._id === this.state.form.teamLevel)
      : null;

    return (
      <>
        {this.state.teamLevels.length === 0 && (
          <Alert severity="warning">
            לא נמצאו קטגוריות. יש להגדיר קטגוריות בחלון ההגדרות
          </Alert>
        )}
        {this.state.seasons.length === 0 && (
          <Alert severity="warning">
            לא נמצאו עונות שניתן להוסיף להן קבוצות. יש לאפשר יצירת קבוצות לעונה
            הרצויה
          </Alert>
        )}
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="standard"
              {...this.defaultParams("name")}
              required
              helperText={
                generateErrorMessage("name", this.state.formErrors) ||
                "אין צורך להוסיף שם מאמן ועונה"
              }
              autoFocus
              label="שם הקבוצה"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              variant="standard"
              {...this.defaultParams("season")}
              select
              required
              helperText={
                this.state.form.canChangeProduct
                  ? generateErrorMessage("season", this.state.formErrors)
                  : "לא ניתן לשנות שדה זה לאחר רישום ספורטאים לקבוצה"
              }
              label="עונה"
              disabled={!this.state.form.canChangeProduct}
            >
              {this.state.seasons.map((season) => (
                <MenuItem value={season._id} key={season._id}>
                  {displaySeason(season.year)}
                  {season.canCreateTeamsAndProducts
                    ? ""
                    : " - לא ניתן להוסיף קבוצות לעונה זו"}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          {this.state.hasPayments && (
            <Grid item xs={12} sm={6}>
              <TextField
                variant="standard"
                {...this.defaultParams("productId")}
                select
                required
                label="מסגרת"
                helperText={
                  this.state.form.canChangeProduct
                    ? this.state.form.season
                      ? generateErrorMessage("season", this.state.formErrors)
                      : "בחירת מסגרת תתאפשר לאחר בחירת עונה"
                    : "לא ניתן לשנות שדה זה לאחר רישום ספורטאים לקבוצה"
                }
                disabled={
                  !this.state.form.canChangeProduct || !this.state.form.season
                }
              >
                {(
                  this.state.productsBySeason[this.state.form.season] || []
                ).map((p) => (
                  <MenuItem value={p._id} key={p._id}>
                    {p.title}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          )}
          <Grid item xs={12} sm={6}>
            <TextField
              variant="standard"
              {...this.defaultParams("teamType")}
              select
              required
              label="סוג הקבוצה"
            >
              {Object.entries(
                teamTypes[this.props.clubCategory] || teamTypes.default
              ).map(([type, label]) => (
                <MenuItem key={type} value={type}>
                  {label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="standard"
              {...this.defaultParams("igudId")}
              label="מספר הקבוצה באיגוד"
              disabled={this.state.form.teamType !== "league"}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="standard"
              {...this.defaultParams("clubCoach")}
              select
              required
              label="מאמן"
            >
              {this.state.clubCoaches.map((clubCoach) => (
                <MenuItem key={clubCoach._id} value={clubCoach._id}>
                  {clubCoach.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="standard"
              {...this.defaultParams("assistants")}
              select
              label="עוזרי מאמן (לא חובה)"
              SelectProps={{
                multiple: true,
                renderValue: this.renderAssistantsNames,
              }}
              onChange={(e) => {
                // max 3 assistants
                if (e.target.value.length <= 3) {
                  this.handleChange({ assistants: e.target.value });
                }
              }}
              input={<Input />}
            >
              {this.state.clubCoaches.map((coach) => (
                <MenuItem
                  key={coach._id}
                  value={coach._id}
                  {...(this.state.form.assistants.indexOf(coach._id) > -1
                    ? { style: { fontWeight: "bold" } }
                    : {})}
                >
                  {coach.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="standard"
              {...this.defaultParams("gender")}
              select
              required
              label="מין"
            >
              <MenuItem value="male">בנים</MenuItem>
              <MenuItem value="female">בנות</MenuItem>
              <MenuItem value="mixed">מעורב</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="standard"
              {...this.defaultParams("teamLevel")}
              onChange={(e) =>
                this.handleChange({
                  teamLevel: e.target.value,
                  teamSubLevel: "",
                })
              }
              select
              required
              label="קטגוריה"
            >
              {this.state.teamLevels.map((tl) => (
                <MenuItem value={tl._id} key={tl._id}>
                  {tl.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="standard"
              {...this.defaultParams("teamSubLevel")}
              select
              label="תת קטגוריה"
              disabled={
                disabled ||
                !this.state.form.teamLevel ||
                teamLevel.subLevels.length === 0
              }
              {...(teamLevel && teamLevel.subLevels.length === 0
                ? { helperText: `לקטגוריה '${teamLevel.name}' אין תת קטגוריות` }
                : {})}
            >
              {teamLevel ? (
                teamLevel.subLevels.map((sl) => (
                  <MenuItem value={sl._id} key={sl._id}>
                    {sl.name}
                  </MenuItem>
                ))
              ) : (
                <div />
              )}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="standard"
              {...this.defaultParams("isRegistrationOpen")}
              select
              required
              label="סטטוס רישום"
              helperText="סגירת ההרשמה אינה מונעת רישום ספורטאים לקבוצה מהבק אופיס"
            >
              <MenuItem value="false">ההרשמה סגורה</MenuItem>
              <MenuItem value="true">
                ההרשמה פתוחה - בהתאם לסטטוס המסגרת ומספר הנרשמים
              </MenuItem>
            </TextField>
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              variant="standard"
              {...this.defaultParams("subscriptionsLimit")}
              onChange={(e) =>
                this.handleChange({
                  subscriptionsLimit: parseInt(e.target.value) || 0,
                })
              }
              value={
                this.state.form.subscriptionsLimitFocused
                  ? this.state.form.subscriptionsLimit || ""
                  : this.state.form.subscriptionsLimit || "ללא הגבלה"
              }
              onFocus={() =>
                this.handleChange({ subscriptionsLimitFocused: true })
              }
              onBlur={() =>
                this.handleChange({ subscriptionsLimitFocused: false })
              }
              label="מספר נרשמים מקסימלי"
            />
          </Grid>
        </Grid>
      </>
    );
  };

  render() {
    return (
      <>
        <Dialog
          fullWidth
          maxWidth="md"
          onClose={this.props.onClose}
          open={this.props.open}
          title={`${this.props.team ? "עדכון פרטי" : "הוספת"} קבוצה`}
          fullScreen={this.props.isXs}
        >
          <DialogContent dividers>
            {this.state.isLoading ? (
              <div style={{ height: 200 }}>
                <AbsoluteCenter>
                  <CircularProgress />
                </AbsoluteCenter>
              </div>
            ) : (
              this.renderContent()
            )}
          </DialogContent>
          <DialogActions>
            <ButtonSx
              sx={{ width: 120 }}
              onClick={() => {
                this.setState({
                  formErrors: {},
                  form: this.initForm(),
                  instantValidate: false,
                });
                this.props.onClose();
              }}
              disabled={this.state.isLoading || this.state.isSaving}
            >
              ביטול
            </ButtonSx>
            <div>
              <ButtonSx
                sx={{ width: 120 }}
                disabled={this.state.isLoading || this.state.isSaving}
                onClick={this.handleSubmit}
                isLoading={this.state.isLoading}
                debounce
              >
                {this.props.team ? "שמירה" : "הוספה"}
              </ButtonSx>
            </div>
          </DialogActions>
        </Dialog>
        <Dialog
          dir="rtl"
          fullWidth={true}
          maxWidth="xs"
          onClose={() => this.setState({ isWarningDialogOpen: false })}
          open={this.state.isWarningDialogOpen}
        >
          <DialogContent>
            <Typography>
              לקבוצה זו יש ספורטאים רשומים. שינוי מסגרת במצב זה אינו מומלץ
            </Typography>
          </DialogContent>
          <DialogActions>
            <ButtonSx
              onClick={() => this.setState({ isWarningDialogOpen: false })}
            >
              בטל
            </ButtonSx>
            <div>
              <ButtonSx
                onClick={() => {
                  this.setState({ isWarningDialogOpen: false });
                  this.handleSubmit(true);
                }}
              >
                שמור בכל זאת
              </ButtonSx>
            </div>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedClub: state.auth.selectedClub,
  };
}

export default withIsXs(connect(mapStateToProps)(TeamDialog));
