import React from "react";
import { TextField, Grid } from "@mui/material";

export default function GuestParentForm(props) {
  const handleChange = (field) => (event) => {
    props.onChange({ [field]: event.target.value });
  };

  const defaultParams = (field) => {
    const error = Object.prototype.hasOwnProperty.call(
      props.state.errors,
      field
    );

    return {
      id: field,
      name: field,
      fullWidth: true,
      value: props.state[field],
      onChange: handleChange(field),
      error,
      helperText: error ? props.state.errors[field] : "",
    };
  };

  return (
    <>
      <Grid item xs={12} sm={6}>
        <TextField
          variant="standard"
          {...defaultParams("guestName")}
          label="שם מלא"
          required
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          variant="standard"
          {...defaultParams("guestPhone")}
          label="טלפון"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          variant="standard"
          {...defaultParams("guestEmail")}
          label="כתובת אימייל"
        />
      </Grid>
    </>
  );
}
