import React from "react";
import _ from "lodash";
import {
  CircularProgress,
  Stepper,
  Step,
  StepLabel,
  Stack,
  stepLabelClasses,
  Box,
} from "@mui/material";
import moment from "moment";
import { connect } from "react-redux";
import queryString from "query-string";
import { Navigate } from "react-router-dom";
import ReactGA from "react-ga4";

import axios from "../../utils/axios";
import SubscriptionForm from "./Subscription/SubscriptionForm";
import MerchandiseForm from "./MerchandiseForm";
import ParentForm from "./Parent/ParentForm";
import PaymentForm from "../../components/store/PaymentForm";
import Review from "./Review";
import Confirmation from "./Confirmation";
import {
  validateSubscription,
  validateParent,
  validateParentField,
  validatePayment,
  validatePlayer,
  validatePlayerField,
} from "./validator";
import * as Cart from "../../components/store/helpers/cart";
import {
  preparePlayerForSubmit,
  prepareSubmitData,
  prepareSubmitDataGuest,
} from "../../components/store/helpers/prepareSubmitData";
import clubConfig from "../../config/clubs";
import ErrorDialog from "../../components/ErrorDialog";
import { availablePaymentMethods } from "../../lib/clubHelper";
import PlayerForm from "./Player/PlayerForm";
import {
  initExistingPlayerSearchForm,
  initForms,
  initParent,
  initPlayer,
  initSubscription,
} from "./NewPurchaseHelper";
import { calcCreditCardPaymentsAmount } from "../../utils/paymentHelpers";
import withRouter from "../../components/routing/withRouter";
import TlPaper from "../../components/TlPaper";
import FlexCenter from "../../components/FlexCenter";
import withBreakpoints from "../../components/routing/withBreakpoints";
import ButtonSx from "../../components/base/ButtonSx";

class NewPurchase extends React.Component {
  // should be changed to c'tor for availablePaymentMethods
  state = {
    error: {},
    loading: true,
    isErrorDialogOpen: false,
    isSending: false,
    availablePaymentMethods: availablePaymentMethods(
      clubConfig[this.props.selectedClub.internalName]
    ),
  };

  steps = (purchaseType, hasPayments, hasStore) => {
    return [
      { label: "פרטי הספורטאי", id: "player" },
      { label: "פרטי ההורה", id: "parent" },
      ...(purchaseType === "subscription"
        ? [{ label: "מסגרת", id: "subscription" }]
        : []),
      ...(hasStore ? [{ label: "מוצרים נלווים", id: "merchandise" }] : []),
      { label: "סל קניות", id: "review" },
      ...(hasPayments ? [{ label: "תשלום", id: "payment" }] : []),
    ];
  };

  componentDidMount = async () => {
    this.loadData();
  };

  componentDidUpdate = async (prevProps) => {
    if (prevProps.location.search !== this.props.location.search) {
      this.loadData();
    }
  };

  loadData = async () => {
    const qs = queryString.parse(this.props.location.search);
    const purchaseType = qs.type;

    ReactGA.send({
      hitType: "pageview",
      page: `/purchases/new?type=${purchaseType}`,
      title: `New ${
        purchaseType === "merchandise" ? "Merchandise Purchase" : "Registration"
      }`,
    });

    const currentClubConfig = {
      ...clubConfig[this.props.selectedClub.internalName],
    };
    const hasPayments = currentClubConfig.payments.enabled;
    const hasStore = currentClubConfig.payments.store;

    this.setState({ purchaseType, hasPayments, hasStore });

    if (["subscription", "merchandise"].indexOf(purchaseType) === -1) {
      // this.props.navigate doesn't work here
      this.setState({ redirectToHome: true });
      return;
    }

    const clubPlayerId = qs.clubPlayerId;
    const clubAccountId = qs.clubAccountId;

    let subscriptionProducts, teams, teamsByProduct, res, levels;
    if (purchaseType === "subscription") {
      res = await axios.get(
        "/store/products?category=subscription&granularity=subProducts&active=true&expired=false"
      );
      subscriptionProducts = res.data;

      teams = [];
      res = await axios.get("/teams?status=active&isSeasonOpen=true");
      teams = res.data;
      teams.forEach((t) => {
        t.product = t.productId
          ? subscriptionProducts.find((sp) => sp._id === t.productId)
          : { _id: "external" };
      });

      //remove teams with inactive or expired product
      teams = teams.filter((t) => t.product);
      teams = _.sortBy(teams);

      levels = _.groupBy(teams, (t) => t.teamLevel._id);
      levels = Object.entries(levels).map(([_id, teams]) => {
        const bySubLevel = _.groupBy(teams, (t) => (t.teamSubLevel || {}).name);
        const subLevels = Object.entries(bySubLevel).map(([slId, slTeams]) => ({
          _id: slId === "undefined" ? "" : slId,
          name: (slTeams[0].teamSubLevel || {}).name,
          teams: _.sortBy(slTeams, "name"),
        }));

        return {
          _id,
          name: teams[0].teamLevel.name,
          subLevels: _.sortBy(subLevels, "name"),
        };
      });

      levels = _.sortBy(levels, "name");
    }

    let merchandise;
    if (clubConfig[this.props.selectedClub.internalName].payments.store) {
      res = await axios.get("/store/products?category=merchandise&active=true");
      merchandise = _.sortBy(res.data, "priority");
    }

    let activeStep = 0;
    let clubPlayer = {};
    let clubParent = {};
    let clubPlayerPlusSiblings = [];
    if (clubPlayerId && clubAccountId) {
      activeStep = 2;
      res = await axios.get(
        `/clubPlayers?clubAccountId=${clubAccountId}&granularity=clubParent`
      );

      // for merchandise
      clubPlayerPlusSiblings = res.data;
      const rawSelectedClubPlayer = res.data.find(
        (cp) => cp._id === clubPlayerId
      );
      clubPlayer = this.adjustClubPlayer(rawSelectedClubPlayer);
      clubPlayer.searchClubPlayersResults = [rawSelectedClubPlayer];
      clubParent = this.adjustClubParent(rawSelectedClubPlayer.parent);
    }

    const forms = initForms(purchaseType, this.props.selectedClub.internalName);
    this.setState({
      teams,
      subscriptionProducts,
      merchandise,
      loading: false,
      steps: this.steps(purchaseType, hasPayments, hasStore),
      teamsByProduct,
      activeStep,
      forms: {
        ...forms,
        player: { ...forms.player, ...clubPlayer },
        parent: { ...forms.parent, ...clubParent },
      },
      cart: Cart.init(),
      levels,
      clubPlayerPlusSiblings,
    });
  };

  handlePaymentFieldChange = (updates) => {
    const updatedForm = { ...this.state.forms.payment, ...updates };
    if (updatedForm.paymentMethod === "creditCard") {
      if ("removeCCPaymentsAmount" in updates) {
        updates.firstPaymentAmount = undefined;
        updates.basePaymentAmount = undefined;
        updates.numberOfPayments = "";
        delete updates.recalcCCPaymentsAmount;
      } else {
        const defaultCalcMethod =
          "numberOfPayments" in updates || "amount" in updates;
        const { cart } = this.state;
        const res = calcCreditCardPaymentsAmount(
          this.state.cart.totals.totalPaymentAmounts,
          defaultCalcMethod ? undefined : updatedForm.firstPaymentAmount,
          updatedForm.numberOfPayments,
          cart.totals.paymentAmounts.merchandise +
            cart.totals.paymentAmounts.fees
        );
        updates.firstPaymentAmount = res.firstPaymentAmount;
        updates.basePaymentAmount = res.basePaymentAmount;
      }
    } else {
      updates.firstPaymentAmount = undefined;
      updates.basePaymentAmount = undefined;
    }

    this.handleFieldChange("payment", updates);
  };

  handleFieldChange = (form, updates, callback) => {
    this.setState(
      (prevState) => ({
        forms: {
          ...prevState.forms,
          [form]: { ...prevState.forms[form], ...updates },
        },
      }),
      callback
    );
  };

  handleFieldChangeNg = (form) => (updates, callback) => {
    this.handleFieldChange(form, updates, callback);
  };

  handleFormChange = (formName, handler, callback) => {
    this.setState(
      (prevState) => ({
        forms: {
          ...prevState.forms,
          [formName]: handler({ ...prevState.forms[formName] }),
        },
      }),
      callback
    );
  };

  handleFieldValidation = (entity, field, validator) => {
    const error = validator(entity, field);
    if (error) {
      entity.errors[field] = error;
    } else {
      entity[`${field}InstaValidate`] = true;
      delete entity.errors[field];
    }
  };

  handleSubscriptionChanged = (updates) => {
    this.handleFormChange("subscription", (subscription) => {
      return { ...subscription, ...updates };
    });
  };

  handleParentChange = (updates) => {
    const parent = { ...this.state.forms.parent, ...updates };

    // instaValidate once clicking on next or the specific field is valid
    [
      "firstName",
      "lastName",
      "phone",
      "street",
      "houseNumber",
      "city",
      "email",
      "secondParentName",
      "secondParentPhone",
      "id",
      "passportNumber",
    ].forEach((field) => {
      if (parent.instaValidate || parent[`${field}InstaValidate`]) {
        this.handleFieldValidation(parent, field, validateParentField);
      }
    });

    let validatePhone = false;
    let validateId = false;

    if (!parent.clubParentId) {
      // phone update and phone is valid

      if (Object.prototype.hasOwnProperty.call(updates, "phone")) {
        parent.isPhoneUnique = null;
        let phoneError = validateParentField(parent, "phone");
        if (!phoneError) {
          validatePhone = true;
        }
      }

      if (Object.prototype.hasOwnProperty.call(updates, "id")) {
        parent.isIdUnique = null;
        let idError = validateParentField(parent, "id");
        if (!idError) {
          validateId = true;
        }
      }
    }

    this.handleFieldChange("parent", parent, () => {
      if (validatePhone) {
        this.validateParentUniqueField("phone", updates.phone);
      }
      if (validateId) {
        this.validateParentUniqueField("id", updates.id);
      }
    });
  };

  handlePlayerChange = (updates, callback) => {
    let validateId = false;

    if (updates.viewType) {
      this.setState({ guest: updates.viewType === "guest" });
    }

    this.handleFormChange(
      "player",
      (player) => {
        let newPlayer = { ...player, ...updates };
        if (updates.viewType) {
          newPlayer = { ...newPlayer, ...initPlayer() };
        } else {
          ["id", "passportNumber"].forEach((field) => {
            if (newPlayer.instaValidate || newPlayer[`${field}InstaValidate`]) {
              const error = validatePlayerField(newPlayer, field);
              if (error) {
                newPlayer.errors[field] = error;
              } else {
                delete newPlayer.errors[field];
              }
            }
          });
          if (!newPlayer.clubPlayerId) {
            if (updates.id) {
              newPlayer.isIdUnique = null;
              if (!validatePlayerField(newPlayer, "id")) {
                newPlayer.isIdUnique = "loading";
                validateId = true;
              }
            }
          }
        }
        return newPlayer;
      },
      () => {
        if (validateId) {
          this.handlePlayerIdNumberChange(updates.id);
        }

        if (Object.prototype.hasOwnProperty.call(updates, "notIsraeli")) {
          const params = this.state.forms.parent.clubParentId
            ? initParent()
            : {};
          this.handleParentChange(
            updates.notIsraeli
              ? { ...params, notIsraeli: true, isIdUnique: null, id: "" }
              : {
                  ...params,
                  notIsraeli: false,
                  isPassportNumberUnique: null,
                  passportNumber: "",
                }
          );
        }

        if (updates.viewType) {
          this.handleFieldChange("parent", initParent()); // delete prev parent
          this.setState(
            (prevState) => ({
              clubPlayerPlusSiblings: [],
              cart: Cart.init(),
              forms: {
                ...prevState.forms,
                subscription: initSubscription(),
                merchandise: { errors: {} },
              },
            }),
            this.removeCreditCardPaymentsAmount
          );
        }

        if (callback) {
          callback();
        }
      }
    );
  };

  adjustClubPlayer = (serverClubPlayer) => {
    return {
      clubPlayerId: serverClubPlayer._id,
      id: serverClubPlayer.id || "", //V0 clubplayers
      firstName: serverClubPlayer.firstName,
      lastName: serverClubPlayer.lastName,
      phone: serverClubPlayer.phone,
      class: serverClubPlayer.class,
      gender: serverClubPlayer.gender,
      dobDay: moment(serverClubPlayer.dateOfBirth).date(),
      dobMonth: moment(serverClubPlayer.dateOfBirth).month() + 1,
      dobYear: moment(serverClubPlayer.dateOfBirth).year(),
      hasMedicalIssues: serverClubPlayer.hasMedicalIssues ? "true" : "false",
      medicalIssues: serverClubPlayer.medicalIssues,
      isIdUnique: true,
      isPassportNumberUnique: true,
      idInstaValidate: true,
      activeTeams: serverClubPlayer.activeTeams,
    };
  };

  adjustClubParent = (serverClubParent) => {
    return {
      clubParentId: serverClubParent._id,
      phone: serverClubParent.phone,
      firstName: serverClubParent.firstName,
      lastName: serverClubParent.lastName,
      city: serverClubParent.city,
      houseNumber: serverClubParent.houseNumber,
      street: serverClubParent.street,
      email: serverClubParent.email || "",
      id: serverClubParent.id || "",
      secondParentName: serverClubParent.secondParentName || "",
      secondParentPhone: serverClubParent.secondParentPhone || "",
      isPhoneUnique: true,
      isIdUnique: true,
      isPassportNumberUnique: true,
      passportNumber: serverClubParent.passportNumber || "",
      notIsraeli: serverClubParent.notIsraeli,
    };
  };

  searchClubPlayers = async (name, id, phone) => {
    this.handlePlayerChange({ isSearchingClubPlayers: true });
    const params = [];
    if (name) params.push(`name=${name}`);
    if (id) params.push(`id=${id}`);
    if (phone) params.push(`phone=${phone}`);

    const res = await axios.get(
      `/clubPlayers?${params.join(
        "&"
      )}&notTrial=true&granularity=clubParent&limit=10`
    );

    this.handlePlayerChange({
      isSearchingClubPlayers: false,
      searchClubPlayersResults: res.data,
    });
  };

  loadClubPlayerSiblings = async (clubAccountId) => {
    this.setState({ isLoadingSiblings: true });

    const res = await axios.get(
      `/clubPlayers?clubAccountId=${clubAccountId}&granularity=clubParent`
    );

    this.setState({
      clubPlayerPlusSiblings: res.data,
      isLoadingSiblings: false,
    });
  };

  handlePlayerPassportNumberChange = async (passportNumber) => {
    const isUnique = await this.handlePlayerIdNumberChange(
      passportNumber,
      "passportNumber"
    );
    if (isUnique) {
      this.handlePlayerChange({ passportNumber });
    }

    return isUnique;
  };

  handlePlayerIdNumberChange = async (newValue, type = "id") => {
    const isUniqueField =
      type === "id" ? "isIdUnique" : "isPassportNumberUnique";
    this.handlePlayerChange({ [isUniqueField]: "loading" });
    const res = await axios.get(
      `/clubPlayers?${type}=${newValue}&granularity=clubParent`
    );

    if (res.data[0]) {
      this.handlePlayerChange({
        isSearchingClubPlayers: false,
        searchClubPlayersResults: res.data,
        showPlayerAlreadyExistDialog: true,
        existingPlayerSearchForm: {
          ...initExistingPlayerSearchForm(),
          [type]: res.data[0][type],
        },
        [isUniqueField]: false,
      });
      return false;
    } else {
      this.handlePlayerChange({
        [isUniqueField]: true,
        [`${type}InstaValidate`]: true,
      });
      return true;
    }
  };

  handleExistingParent = (selected) => async () => {
    const clubAccountId =
      this.state.forms.parent.clubParentSearchResult?.clubAccountId;
    const updates = {
      clubParentSearchResult: null,
      showParentFoundDialog: false,
      ...(selected
        ? this.adjustClubParent(this.state.forms.parent.clubParentSearchResult)
        : {}),
    };

    this.handleFieldChange("parent", updates);
    if (selected) {
      await this.loadClubPlayerSiblings(clubAccountId);

      this.setState({ activeStep: 2 });
    }
  };

  clearSelectedParent = () => {
    this.handleFieldChange("parent", {
      ...initParent(),
      parentInstaValidate: false,
    });
    this.setState(
      (prevState) => ({
        clubPlayerPlusSiblings: [],
        cart: Cart.init(),
        forms: {
          ...prevState.forms,
          subscription: initSubscription(),
          merchandise: { errors: {} },
        },
      }),
      this.removeCreditCardPaymentsAmount
    );
  };

  handleUpdateOTP = (product) => {
    this.setState((prevState) => {
      let merchandise = [...prevState.merchandise];
      if (product.otp && product._id) {
        const p = merchandise.find((p) => p.otp && p._id === product._id);
        p.price = product.price;
        p.title = product.title;
        p.accountingExportNumber = product.accountingExportNumber;
      } else {
        merchandise = [
          { ...product, _id: _.uniqueId("opt"), options: [] },
          ...merchandise,
        ];
      }

      return { merchandise };
    });
  };

  handleDeleteOTP = (product) => {
    this.setState((prevState) => {
      return {
        merchandise: prevState.merchandise.filter((p) => p._id !== product._id),
        cart: Cart.removeOTP(this.state.cart, product._id),
      };
    });
  };

  removeCreditCardPaymentsAmount = () => {
    this.handlePaymentFieldChange({
      removeCCPaymentsAmount: true,
    });
  };

  getStepContent = (step) => {
    const stepId = this.state.steps[step].id;
    let players;
    switch (stepId) {
      case "player":
        return (
          <PlayerForm
            state={this.state.forms.player}
            onChange={this.handlePlayerChange}
            onPassportNumberChanged={this.handlePlayerPassportNumberChange}
            onExistingPlayerSelected={async (clubPlayer) => {
              this.handlePlayerChange({
                ...this.adjustClubPlayer(clubPlayer),
                errors: {},
              });
              this.handleParentChange(this.adjustClubParent(clubPlayer.parent));
              await this.loadClubPlayerSiblings(clubPlayer.clubAccountId);
              // clean team and merchandise and payment. if team and merch have already been selected, then the user goes back and changes the player we must clear the teams and merch
              this.setState(
                (prevState) => ({
                  activeStep: 1,
                  cart: Cart.init(),
                  forms: {
                    ...prevState.forms,
                    subscription: initSubscription(),
                    merchandise: { errors: {} },
                  },
                }),
                this.removeCreditCardPaymentsAmount
              );
            }}
            onSearch={this.searchClubPlayers}
            allowGuestMode={
              this.state.purchaseType === "merchandise" &&
              this.props.newClubConfig.general.guestMerchandise
            }
            isLoadingSiblings={this.state.isLoadingSiblings}
            purchaseType={this.state.purchaseType}
            remoteClubConfig={this.props.newClubConfig}
          />
        );

      case "parent":
        return (
          <ParentForm
            state={this.state.forms.parent}
            registerWithPassport={this.state.forms.player.notIsraeli}
            onChange={this.handleParentChange}
            existingPlayer={this.state.forms.player.clubPlayerId !== ""}
            onExistingParentNotSelected={this.handleExistingParent(false)}
            onExistingParentSelected={this.handleExistingParent(true)}
            onClearSelectedParent={this.clearSelectedParent}
            guest={this.state.guest}
            isLoadingSiblings={this.state.isLoadingSiblings}
            purchaseType={this.state.purchaseType}
            onPassportNumberChanged={this.validateParentPassportNumber}
          />
        );
      case "subscription":
        return (
          <SubscriptionForm
            state={this.state.forms.subscription}
            hasPayments={this.state.hasPayments}
            cart={this.state.cart}
            levels={this.state.levels}
            account={this.state.forms.parent.account}
            teams={this.state.teams}
            clubPlayer={this.state.forms.player}
            onChange={this.handleSubscriptionChanged}
            purchaseType={this.state.purchaseType}
            subscriptionProducts={this.state.subscriptionProducts}
            onNewSubscriptionProductSelected={(product) => {
              this.setState(
                { cart: Cart.setSubscription(this.state.cart, product) },
                this.removeCreditCardPaymentsAmount
              );
            }}
            onCartSubscriptionChange={(...ar) => {
              this.setState(
                { cart: Cart.updateSubscriptionFields(this.state.cart, ...ar) },
                this.removeCreditCardPaymentsAmount
              );
            }}
            onRemoveSubscription={() => {
              this.setState(
                { cart: Cart.removeSubscription(this.state.cart) },
                this.removeCreditCardPaymentsAmount
              );
            }}
          />
        );

      case "merchandise":
        players = this.state.clubPlayerPlusSiblings;
        if (this.state.guest) {
          players = [
            {
              _id: "guest",
              isGuest: true,
              name: this.state.forms.player.guestName,
            },
          ];
        } else if (!this.state.forms.player.clubPlayerId) {
          // if new player
          players = [
            {
              _id: "new",
              isNew: true,
              name: `${this.state.forms.player.firstName} ${this.state.forms.player.lastName}`,
            },
            ...players,
          ];
        }

        return (
          <MerchandiseForm
            state={this.state.forms.merchandise}
            subscription={this.state.forms.subscription}
            player={this.state.forms.player}
            players={players}
            teams={this.state.teams}
            merchandise={this.state.merchandise}
            cart={this.state.cart}
            onProductChange={(...args) =>
              this.setState(
                {
                  cart: Cart.handleMerchandiseChange(this.state.cart, ...args),
                },
                this.removeCreditCardPaymentsAmount
              )
            }
            OTP={this.state.purchaseType === "merchandise"}
            onUpdateOTP={this.handleUpdateOTP}
            onDeleteOTP={this.handleDeleteOTP}
            purchaseType={this.state.purchaseType}
          />
        );
      case "payment":
        return (
          <PaymentForm
            state={this.state.forms.payment}
            onChange={(updates) => this.handlePaymentFieldChange(updates)}
            cart={this.state.cart}
            paymentAmount={this.state.cart.paymentAmount}
            clubConfig={clubConfig[this.props.selectedClub.internalName]}
            isSending={this.state.isSending}
            availablePaymentMethods={this.state.availablePaymentMethods}
            purchaseType={this.state.purchaseType}
            remoteClubConfig={this.props.newClubConfig}
          />
        );
      case "review":
        return (
          <Review
            subscription={this.state.forms.subscription}
            hasPayments={this.state.hasPayments}
            cart={this.state.cart}
            teams={this.state.teams}
            states={this.state.forms}
            club={this.state.club}
            guest={this.state.guest}
            onChange={(updates) => this.handleFieldChange("review", updates)}
            purchaseType={this.state.purchaseType}
            onProductChange={(...args) =>
              this.setState(
                {
                  cart: Cart.handleMerchandiseChange(this.state.cart, ...args),
                },
                this.removeCreditCardPaymentsAmount
              )
            }
            onPartialPaymentAmountChanged={(type, paymentAmount) => {
              this.setState(
                {
                  cart: Cart.handleSubscriptionPartialPayment(
                    this.state.cart,
                    type,
                    paymentAmount
                  ),
                },
                this.removeCreditCardPaymentsAmount
              );
            }}
          />
        );
      default:
        throw new Error("Unknown step");
    }
  };

  createClubParent = async (check) => {
    try {
      const res = await axios.post(`/clubParents`, {
        ...this.state.forms.parent,
        mode: check,
      });
      const clubParentId = res.data.clubParentId;
      this.handleParentChange({ clubParentId });
      return clubParentId;
    } catch (error) {
      // this shouldn't happen, both data and uniqueness validations are happening before
      this.showErrorDialog("אירעה שגיאה", "אנא נסו מאוחר יותר");
    }
  };

  createClubPlayer = async (clubParentId, check) => {
    try {
      const res = await axios.post(`/clubPlayers`, {
        ...preparePlayerForSubmit(clubParentId, this.state.forms.player),
        mode: check,
      });
      const clubPlayerId = res.data.clubPlayerId;
      this.handlePlayerChange({ clubPlayerId });
      return clubPlayerId;
    } catch (error) {
      //shouldn't happen data is validate, and we verify that this is a new player in the club before (new id)
      this.showErrorDialog("אירעה שגיאה", "אנא נסו מאוחר יותר");
    }
  };

  handleSubmit = async () => {
    this.setState({ isSending: true });
    let clubParentId, clubPlayerId;
    if (!this.state.guest) {
      clubParentId = this.state.forms.parent.clubParentId;
      if (!clubParentId) {
        clubParentId = await this.createClubParent(false);
        if (!clubParentId) {
          this.setState({ isSending: false });
          return;
        }
      }

      clubPlayerId = this.state.forms.player.clubPlayerId;
      if (!clubPlayerId) {
        clubPlayerId = await this.createClubPlayer(clubParentId, false);
        if (!clubPlayerId) {
          this.setState({ isSending: false });
          return;
        }
      }
    }

    const data = this.state.guest
      ? prepareSubmitDataGuest(
          this.state.forms.player,
          this.state.forms.parent,
          this.state.forms.payment,
          this.state.cart
        )
      : prepareSubmitData(
          this.state.purchaseType,
          this.state.cart,
          this.state.forms.payment,
          this.state.forms.subscription,
          clubParentId,
          clubPlayerId
        );

    try {
      if (this.state.cart.totals.totalPaymentAmounts === 0) {
        await axios.post("/store/subscriptions/managers", data);
      } else {
        const response = await axios.post("/store/purchases", data);
        this.setState({
          transactionId: response.data.transactionId,
          transactionInternalId: response.data.transactionInternalId,
        });
      }
      return true;
    } catch (error) {
      if (error.response.status === 422) {
        const { errors } = error.response.data;
        if (errors.payment) {
          this.showErrorDialog("העסקה נכשלה", "אנא נסו כרטיס אשראי אחר", {
            backToStep: "payment",
          });
          return false;
        }
      }

      this.showErrorDialog("אירעה שגיאה", "אנא נסו מאוחר יותר");
      return false;
    } finally {
      this.setState({ isSending: false });
    }
  };

  validateParentPassportNumber = async (passportNumber) => {
    const isUnique = await this.validateParentUniqueField(
      "passportNumber",
      passportNumber
    );
    if (isUnique) {
      this.handleParentChange({ passportNumber });
    }

    return isUnique;
  };

  // id or phone or passportNumber
  validateParentUniqueField = async (field, value) => {
    const isUniqueField = {
      id: "isIdUnique",
      phone: "isPhoneUnique",
      passportNumber: "isPassportNumberUnique",
    }[field];
    this.handleFieldChange("parent", { [isUniqueField]: "loading" });

    const res = await axios.get(
      `/clubParents?${field}=${value}&granularity=clubPlayers`
    );
    if (res.data[0]) {
      this.handleParentChange({
        clubParentSearchResult: res.data[0],
        showParentFoundDialog: true,
        showParentFoundField: field,
        invalidParentConnection:
          this.state.forms.parent.notIsraeli !== res.data[0].notIsraeli,
        [isUniqueField]: false, // will be overriden when parent selected
        [`${field}InstaValidate`]: true,
      });
      return false;
    } else {
      this.handleParentChange({
        [isUniqueField]: true,
        [`${field}InstaValidate`]: true,
      });
      return true;
    }
  };

  validateForm = (formName, validator) => {
    const errors = validator(this.state.forms[formName]);
    this.handleFormChange(formName, (form) => {
      form.errors = errors;
      form.instaValidate = true;
      return form;
    });

    return errors;
  };

  validate = (step) => {
    const stepId = this.state.steps[step].id;
    let errors = {};

    switch (stepId) {
      case "player":
        errors = this.validateForm("player", validatePlayer);
        break;

      case "parent":
        errors = this.validateForm("parent", (parent) =>
          validateParent(parent, this.state.guest)
        );
        break;

      case "subscription":
        errors = this.validateForm("subscription", validateSubscription);
        break;

      case "payment":
        errors = validatePayment(this.state.forms.payment, this.state.cart);

        this.handleFormChange("payment", (paymentForm) => {
          paymentForm.errors = errors;
          paymentForm.instaValidate = true;
          return paymentForm;
        });
        break;

      case "merchandise":
        if (this.state.purchaseType === "merchandise") {
          if (Object.keys(this.state.cart.merchandise).length === 0) {
            errors.merchandise = "required";
          }
        }

        this.handleFormChange("merchandise", (merchandise) => {
          merchandise.errors = errors;
          return merchandise;
        });
        break;

      case "review":
        break;

      default:
        break;
    }
    return Object.keys(errors).length === 0;
  };

  handleNext = async () => {
    const isValid = this.validate(this.state.activeStep);
    if (isValid) {
      let success = true;
      if (this.state.activeStep === this.state.steps.length - 1) {
        success = await this.handleSubmit();
      }

      if (success) {
        this.setState((prevState) => {
          return { activeStep: prevState.activeStep + 1 };
        });
      }
    }
  };

  handleBack = () => {
    this.setState((prevState) => {
      return { activeStep: prevState.activeStep - 1 };
    });
  };

  showErrorDialog = (title, content, options = {}) => {
    this.setState({
      isErrorDialogOpen: true,
      error: { title, content, backToStep: options.backToStep || null },
    });
  };

  renderBackButton = () => {
    return this.state.activeStep !== 0 ? (
      <ButtonSx
        onClick={this.handleBack}
        sx={styles.button}
        disabled={this.state.isSending}
      >
        חזור
      </ButtonSx>
    ) : (
      <Box sx={styles.button} />
    );
  };

  renderNextButton = () => {
    const isCartEmptyOnReviewPage =
      this.state.steps[this.state.activeStep]?.id === "review" &&
      !this.state.cart.subscription &&
      Object.keys(this.state.cart.merchandise).length === 0;

    return (
      <ButtonSx
        variant="contained"
        color="primary"
        onClick={this.handleNext}
        sx={styles.button}
        isLoading={this.state.isLoadingSiblings}
        disabled={this.state.isSending || isCartEmptyOnReviewPage}
        debounce={this.state.activeStep === this.state.steps.length - 1}
      >
        {this.state.isSending && (
          <CircularProgress
            color="secondary"
            size={20}
            style={{ marginLeft: 5 }}
          />
        )}
        {this.state.activeStep === this.state.steps.length - 1
          ? this.state.cart.totals.totalPaymentAmounts === 0
            ? "סיום הרשמה"
            : "תשלום"
          : "הבא"}
      </ButtonSx>
    );
  };

  renderSteps = () => {
    return (
      <>
        <Box sx={styles.stepContainerBody}>
          {this.getStepContent(this.state.activeStep)}
        </Box>
        {this.props.isMatching && (
          <Stack
            spacing={1}
            justifyContent="flex-end"
            direction="row"
            sx={{ mt: 2, mb: 1 }}
          >
            {this.renderBackButton()}
            {this.renderNextButton()}
          </Stack>
        )}
      </>
    );
  };

  render() {
    if (this.state.redirectToHome) {
      return <Navigate to="../" />;
    }

    let paperTitle = null;
    if (this.props.isMatching) {
      paperTitle =
        this.state.purchaseType === "subscription"
          ? "הרשמה"
          : "רכישת מוצרים נלווים";
    }

    return (
      <FlexCenter>
        <Box sx={styles.layout}>
          <TlPaper
            sx={{
              margin: 0,
              padding: 0,
              backgroundColor: { xs: "#FAFAFA", sm: "#FFF" },
            }}
            bodySx={{
              padding: { xs: 0, sm: 1 },
              // "xs: 3" prevents the bottom of the step's content from disappearing underneath the mobile stepper
              pb: { xs: 3, sm: 1 },
            }}
            paperProps={{
              elevation: this.props.isMatching ? 1 : 0,
            }}
            titleBackground
            title={paperTitle}
          >
            <ErrorDialog
              open={this.state.isErrorDialogOpen}
              title={this.state.error.title}
              content={this.state.error.content}
              onClose={() => {
                const activeStep = this.state.error.backToStep
                  ? _.findIndex(
                      this.state.steps,
                      (s) => s.id === this.state.error.backToStep
                    )
                  : this.state.activeStep;

                this.setState({
                  isErrorDialogOpen: false,
                  activeStep,
                });
              }}
            />

            {this.state.loading ? (
              <Box sx={styles.progress}>
                <CircularProgress />
              </Box>
            ) : (
              <>
                {this.props.isMatching ? (
                  <Stepper
                    activeStep={this.state.activeStep}
                    sx={styles.stepper}
                    alternativeLabel
                  >
                    {this.state.steps.map((step) => (
                      <Step key={step.label}>
                        <StepLabel
                          sx={{
                            [`& .${stepLabelClasses.label}`]: {
                              [`&.${stepLabelClasses.active}`]: {
                                fontWeight: "bold",
                                fontSize: 18,
                              },
                            },
                          }}
                        >
                          {step.label}
                        </StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                ) : (
                  this.state.activeStep < this.state.steps.length && (
                    <Box sx={styles.mobileBottomStepper}>
                      {this.renderBackButton()}
                      <b>
                        {this.state.steps.length} / {this.state.activeStep + 1}
                      </b>
                      {this.renderNextButton()}
                    </Box>
                  )
                )}
                {this.state.activeStep === this.state.steps.length ? (
                  <Confirmation
                    noPayment={this.state.cart.totals.totalPaymentAmounts === 0}
                    transactionId={this.state.transactionId}
                    transactionInternalId={this.state.transactionInternalId}
                    email={this.state.forms.parent.email}
                    playerId={this.state.forms.player.clubPlayerId}
                  />
                ) : (
                  this.renderSteps()
                )}
              </>
            )}
          </TlPaper>
        </Box>
      </FlexCenter>
    );
  }
}

const styles = {
  layout: {
    width: "100%",
    maxWidth: 1000,
    paddingBottom: 3,
  },
  stepper: {
    pt: 1,
    pb: 2,
  },
  button: {
    width: 110,
  },
  progress: {
    height: "400px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  stepContainer: {
    padding: 0,
  },
  stepContainerBody: {
    padding: 0,
  },
  mobileBottomStepper: {
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 10,
    backgroundColor: "#FEFEFE",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 50,
    padding: "10px",
  },
};

function mapStateToProps(state) {
  return {
    selectedClub: state.auth.selectedClub,
    newClubConfig: state.auth.selectedClub.clubConfig,
  };
}

export default connect(mapStateToProps)(
  withBreakpoints("smUp", withRouter(NewPurchase))
);
