import React from "react";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Slide,
  Stack,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import AbsoluteCenter from "../../AbsoluteCenter";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});

export default function SubscriptionDialog(props) {
  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      fullScreen
      maxWidth="sm"
      sx={styles.dialog}
      dir="rtl"
      TransitionComponent={Transition}
    >
      <DialogTitle sx={{ pr: 1 }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          {props.title}
          <IconButton onClick={props.onClose}>
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent dividers sx={styles.content}>
        {props.isLoading ? (
          <AbsoluteCenter>
            <CircularProgress />
          </AbsoluteCenter>
        ) : (
          props.content
        )}
      </DialogContent>
      {props.actions && <DialogActions>{props.actions}</DialogActions>}
    </Dialog>
  );
}

const styles = {
  dialog: {
    maxWidth: 700,
    zIndex: 1100,
    padding: 0,
    backgroundColor: "#f2f4f6",
  },
  content: {
    p: { xs: 1, sm: 2 },
  },
};
