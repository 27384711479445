import React from "react";
import { Box, Fab, List, ListItem, TextField } from "@mui/material";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";

import PriceInputNg from "../../components/PriceInputNG";
import { DnDreorder, roundPrice } from "../../utils/helpers";
import Paper2Sx from "../../components/Paper2Sx";

const maxItems = 10;

export default function DealReceiptItemsForm({
  items,
  onItemsChanged,
  errors,
  isLoading,
}) {
  const itemsErrors = errors.items || [];

  const onDragEnd = (result) => {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const newItems = DnDreorder(items, source.index, destination.index);
      onItemsChanged(newItems);
    }
  };

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "lightblue" : "transparent",
    padding: 8,
    width: "100%",
    height: "100%",
    overflow: "auto",
    borderRadius: 5,
  });

  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: 10,
    margin: `0 0 ${8}px 0`,
    border: "1px solid #EFEFEF",
    borderRadius: 5,
    // change background colour if dragging
    background: isDragging ? "#EFEFEF" : "white",
    // styles we need to apply on draggables
    ...draggableStyle,
  });

  const addNewItem = () => {
    onItemsChanged([
      ...items,
      {
        description: "",
        price: "",
        priceStr: "",
        quantity: 1,
        _id: items.length.toString(),
      },
    ]);
  };

  const deleteItem = (index) => () => {
    onItemsChanged(items.filter((f, i) => i !== index));
  };

  const handleItemChange = (field, index) => (e) => {
    const newItems = [...items];
    const { value } = e.target;

    if (field === "quantity" && value !== "" && !value.match(/^\d+$/)) {
      return;
    }

    newItems[index] = { ...newItems[index], [field]: e.target.value };
    onItemsChanged(newItems);
  };

  const handlePriceChange = (index) => (priceStr, price) => {
    const newItems = [...items];
    newItems[index] = { ...newItems[index], priceStr, price };
    onItemsChanged(newItems);
  };

  const renderItem = (item, index) => {
    return (
      <Box sx={styles.itemLine}>
        {<DragIndicatorIcon sx={{ marginLeft: "10px" }} />}
        <TextField
          variant="standard"
          required
          sx={styles.flexDescription}
          label="תיאור"
          value={item.description}
          fullWidth
          onChange={handleItemChange("description", index)}
          inputProps={{ maxLength: 50 }}
          error={itemsErrors[index]?.description !== undefined}
          disabled={isLoading}
        />
        <PriceInputNg
          label="מחיר"
          fullWidth
          value={item.priceStr}
          sx={styles.flex1}
          onChange={handlePriceChange(index)}
          error={itemsErrors[index]?.price !== undefined}
          required
          disabled={isLoading}
        />
        <TextField
          variant="standard"
          required
          sx={styles.flex1}
          label="כמות"
          value={item.quantity}
          fullWidth
          onChange={handleItemChange("quantity", index)}
          error={itemsErrors[index]?.quantity !== undefined}
          disabled={isLoading}
        />

        <TextField
          variant="standard"
          sx={styles.flex1}
          label="סה״כ"
          value={roundPrice(item.quantity * item.price) || "-"}
          fullWidth
          onChange={handleItemChange("quantity", index)}
          error={itemsErrors[index]?.quantity !== undefined}
          inputProps={{
            readOnly: true,
          }}
          disabled={isLoading}
        />

        <Box sx={styles.optionButtonsContainer}>
          <Fab
            sx={styles.optionButton}
            size="small"
            onClick={deleteItem(index)}
            disabled={items.length === 1}
          >
            <DeleteIcon />
          </Fab>
        </Box>
      </Box>
    );
  };

  return (
    <Paper2Sx
      externalTitle={`פריטים (${items.length})`}
      noMinHeight
      paperSx={styles.paper}
    >
      <div style={{ position: "relative", height: "100%" }}>
        <Fab
          size="small"
          color="primary"
          onClick={addNewItem}
          sx={styles.addItemFab}
          disabled={items.length === maxItems}
        >
          <AddIcon />
        </Fab>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="options2">
            {(provided, snapshot) => (
              <List
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                {items.map((item, index) => (
                  <Draggable
                    key={item._id}
                    draggableId={item._id}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <ListItem
                        divider
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                        {renderItem(item, index)}
                      </ListItem>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </List>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </Paper2Sx>
  );
}

const styles = {
  paper: { height: 330 },
  itemLine: {
    display: "flex",
    flex: 1,
    alignItems: "center",
  },
  flex1: {
    flex: 1,
    marginRight: "10px",
  },
  flexDescription: {
    flex: 3,
    marginRight: "10px",
  },
  addItemFab: {
    position: "absolute",
    bottom: 5,
    right: 5,
    zIndex: 1000,
  },
};
