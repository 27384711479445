import React from "react";
import { Grid } from "@mui/material";

import axios from "../../../utils/axios";
import CoachesFillRate from "./CoachesFillRate";
import PlayersAttendance from "./PlayersAttendance";
import TlPaper from "../../../components/TlPaper";

export default class Attendance extends React.Component {
  state = { attendancesFillRate: null, playersStats: null, isLoading: true };

  componentDidMount = async () => {
    this.setState({ isLoading: true });
    let res = await axios.get(`/attendances/stats?level=coach&period=-30`);

    const attendancesFillRate = res.data;
    attendancesFillRate.rate = Math.round(
      (attendancesFillRate.hasAttendance /
        (attendancesFillRate.hasAttendance +
          attendancesFillRate.noAttendance)) *
        100 || 0
    );

    res = await axios.get(`/attendances/playersStats`);
    const playersStats = res.data;

    this.setState({ attendancesFillRate, playersStats, isLoading: false });
  };

  render() {
    return (
      <TlPaper title="נוכחות" isLoading={this.state.isLoading} titleBackground>
        {!this.state.isLoading && (
          <Grid container>
            <Grid item xs={12} sm={4}>
              <CoachesFillRate fillRate={this.state.attendancesFillRate.rate} />
            </Grid>
            <Grid item xs={12} sm={8}>
              <PlayersAttendance data={this.state.playersStats} />
            </Grid>
          </Grid>
        )}
      </TlPaper>
    );
  }
}
