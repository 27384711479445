import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export default function SideDialog(props) {
  return (
    <Dialog
      dir="rtl"
      fullScreen
      open={props.open}
      onClose={props.onClose}
      sx={{ width: 700, ...props.sx }}
      {...props.dialogProps}
    >
      <DialogTitle
        sx={
          props.coloredTitle
            ? (theme) => ({
                backgroundColor: theme.appBar.backgroundColor,
                color: theme.appBar.color,
              })
            : null
        }
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          {props.title}
          <IconButton onClick={props.onClose}>
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent dividers>{props.children}</DialogContent>
      {props.actions && <DialogActions>{props.actions}</DialogActions>}
    </Dialog>
  );
}
