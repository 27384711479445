import React from "react";
import Chart from "react-apexcharts";
import moment from "moment";

const height = 60;

const generateSeries = (data) => {
  return data.map((datum) => ({
    name: datum.name,
    data: [
      ...datum.events.map((e, index) => ({
        event: e,
        x: index,
        y: e.hasAttendance ? 1 : 3,
      })),
    ],
  }));
};

export default function CoachAttendanceChart(props) {
  const series = generateSeries([{ events: props.events, name: "" }]);
  const options = {
    chart: {
      height,
      type: "heatmap",
      toolbar: {
        show: false,
      },
    },
    grid: {
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      },
    },
    yaxis: {
      labels: {
        show: false,
      },
    },
    xaxis: {
      labels: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    colors: ["red"],
    tooltip: {
      custom: function ({ seriesIndex, dataPointIndex, w }) {
        const event = w.config.series[seriesIndex].data[dataPointIndex].event;
        return (
          '<div class="arrow_box" style="padding:10px;">' +
          "<span>" +
          event.title +
          "</span>" +
          "<div>" +
          moment(event.start).format("DD/MM") +
          "</div>" +
          "</div>"
        );
      },
    },
    plotOptions: {
      heatmap: {
        colorScale: {
          ranges: [
            {
              from: 0,
              to: 1,
              color: "#008000",
            },
            {
              from: 2,
              to: 3,
              color: "#FF0000",
            },
          ],
        },
      },
    },
  };

  return (
    <Chart
      options={options}
      series={series}
      type="heatmap"
      height={height}
      width={props.events.length * 18}
      style={{ padding: 0, marginLeft: 10 }}
    />
  );
}
