import React from "react";
import { Box, Fab, List, ListItem, TextField } from "@mui/material";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import _ from "lodash";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";

import TlPaper from "../../../components/TlPaper";

export default function ProductOptionsForm(props) {
  const { product } = props;

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const items = reorder(product.options, source.index, destination.index);
      props.onChange("options", items);
    }
  };

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "lightblue" : "transparent",
    padding: 8,
    width: "100%",
    height: "100%",
    overflow: "auto",
    borderRadius: 5,
  });

  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: 10,
    margin: `0 0 ${8}px 0`,
    border: "1px solid #EFEFEF",
    borderRadius: 5,
    // change background colour if dragging
    background: isDragging ? "#EFEFEF" : "white",
    // styles we need to apply on draggables
    ...draggableStyle,
  });

  const addNewOption = () => {
    props.onChange("options", [
      { title: "", _id: _.uniqueId("localOption_") },
      ...product.options,
    ]);
  };

  const handleOptionChange = (option, field) => (event) => {
    const options = [...product.options];
    options.find((opt) => opt._id === option._id)[field] = event.target.value;
    props.onChange("options", options);
  };

  const deleteOption = (index) => () => {
    props.onChange(
      "options",
      product.options.filter((o, i) => i !== index)
    );
  };

  const renderOption = (option, index) => {
    return (
      <Box sx={styles.option}>
        <DragIndicatorIcon />
        <TextField
          variant="standard"
          fullWidth
          value={option.title}
          onChange={handleOptionChange(option, "title")}
          inputProps={{ maxLength: 20 }}
          placeholder={`הזינו ${product.optionsType} לבחירה`}
          error={option.error}
          helperText={option.error}
        />
        <Box sx={styles.optionButtonsContainer}>
          <Fab
            sx={styles.optionButton}
            size="small"
            onClick={deleteOption(index)}
          >
            <DeleteIcon />
          </Fab>
        </Box>
      </Box>
    );
  };

  const addItemFab = (
    <Fab
      size="small"
      color="primary"
      onClick={addNewOption}
      sx={styles.addItemFab}
    >
      <AddIcon />
    </Fab>
  );

  const renderNoItems = () => (
    <ListItem sx={styles.headerItem}>
      <Box sx={styles.option}>
        <Box
          sx={{
            ...styles.addItemsText,
            ...(props.errors.options ? styles.error : {}),
          }}
        >
          יש להוסיף אפשרויות לבחירה
        </Box>
      </Box>
    </ListItem>
  );

  return (
    <TlPaper
      title="אפשרויות בחירה"
      noMinHeight
      bodySx={styles.container}
      leftItem={addItemFab}
    >
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="options">
          {(provided, snapshot) => (
            <List
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {product.options.length === 0 && renderNoItems()}

              {product.options.map((option, index) => (
                <Draggable
                  key={option._id}
                  draggableId={option._id}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <ListItem
                      divider
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      {renderOption(option, index)}
                    </ListItem>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </List>
          )}
        </Droppable>
      </DragDropContext>
    </TlPaper>
  );
}

const styles = {
  container: {
    padding: 0,
  },
  option: {
    display: "flex",
    flex: 1,
    alignItems: "center",
  },
  optionButtonsContainer: {
    display: "flex",
  },
  optionButton: {
    marginLeft: "10px",
  },
  addItemsText: {
    flexGrow: 1,
  },
  error: {
    color: "error.main",
    fontWeight: "bold",
  },
  headerItem: {
    padding: "10px",
    paddingBottom: "15px",
    paddingTop: "15px",
  },
  addItemFab: { marginRight: "10px" },
};
