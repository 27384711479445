import React from "react";
import Avatar from "@mui/material/Avatar";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import { Box } from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import validator from "validator";

import axios from "../../utils/axios";
import ButtonSx from "../../components/base/ButtonSx";

const styles = {
  paper: {
    marginTop: "8px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: 1,
    backgroundColor: "primary.main",
  },
  submit: {
    mt: 3,
    mb: 2,
  },
};

class ForgotPassword extends React.Component {
  state = {
    email: "",
    requestSent: false,
    loginError: false,
    isLoading: false,
    emailError: false,
  };

  onPress = async (e) => {
    e.stopPropagation();
    e.preventDefault();
    try {
      const isValid = validator.isEmail(this.state.email.trim());
      if (!isValid) {
        this.setState({ emailError: true });
        return;
      }
      this.setState({ isLoading: true, emailError: false });
      await axios.post("/users/forgot-password", {
        email: this.state.email.trim().toLowerCase(),
      });
      this.setState({ isLoading: false, requestSent: true });
    } catch (error) {
      this.setState({ loginError: true, isLoading: false });
    }
  };

  render() {
    return (
      <Container dir="rtl" component="main" maxWidth="xs">
        <CssBaseline />
        <Box sx={styles.paper}>
          <Avatar sx={styles.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            שכחת סיסמה?
          </Typography>
          <form
            style={{
              width: "100%", // Fix IE 11 issue.
              marginTop: 1,
            }}
            noValidate
          >
            <TextField
              disabled={this.state.isLoading}
              onChange={(event) =>
                this.setState({
                  email: event.target.value,
                  requestSent: false,
                })
              }
              value={this.state.email}
              placeholder="אימייל"
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              name="email"
              autoComplete="email"
              autoFocus
              error={this.state.emailError}
              helperText={this.state.emailError ? "כתובת אימייל לא תקינה" : ""}
            />
            {this.state.loginError && (
              <div style={{ textAlign: "center", fontSize: 18 }}>
                שם משתמש או סיסמא לא נכונים. אנא נסה שוב
              </div>
            )}
            <div style={{ position: "relative" }}>
              {this.state.requestSent && (
                <div>הוראות לאיפוס הסיסמה נשלחו לכתובת האימייל שהוזנה</div>
              )}
              <ButtonSx
                disabled={this.state.requestSent}
                isLoading={this.state.isLoading}
                fullWidth
                variant="contained"
                color="primary"
                sx={styles.submit}
                onClick={this.onPress}
                buttonProps={{ type: "submit" }}
              >
                שלח קישור לאיפוס הסיסמה
              </ButtonSx>
            </div>
          </form>
        </Box>
      </Container>
    );
  }
}

export default ForgotPassword;
