import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  useMediaQuery,
} from "@mui/material";
import validator from "validator";

import axios from "../../utils/axios";
import FormTextField from "../../components/base/FormTextField";
import {
  normalizeServerValidationErrors,
  validateFormNg,
} from "../../lib/formHelpers";
import ButtonSx from "../../components/base/ButtonSx";

const defaultForm = () => ({
  lastName: "",
  firstName: "",
  phone: "",
  id: "",
  email: "",
  city: "",
  street: "",
  houseNumber: "",
  secondParentName: "",
  secondParentPhone: "",
  passportNumber: "",
});

function EditParentsDialog(props) {
  const [instantValidate, setInstantValidate] = useState(false);
  const [form, setForm] = useState(defaultForm());
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const isSmUp = useMediaQuery((theme) => theme.breakpoints.up("sm"), {
    noSsr: true,
  });

  useEffect(() => {
    const parent = props.player.parent;
    setForm({
      firstName: parent.firstName,
      lastName: parent.lastName,
      phone: parent.phone,
      email: parent.email,
      city: parent.city,
      street: parent.street,
      houseNumber: parent.houseNumber,
      secondParentName: parent.secondParentName || "",
      secondParentPhone: parent.secondParentPhone || "",
      id: parent.id || "",
      passportNumber: parent.passportNumber || "",
    });
    setErrors({});
    setInstantValidate(false);
  }, [props.open, props.player]);

  const formSettings = useMemo(
    () => ({
      city: {
        validator: (val) => val.length >= 2,
      },
      street: {
        validator: (val) => val.length >= 2,
      },
      houseNumber: {
        validator: (val) => val.length >= 1,
      },
      firstName: {
        validator: (val) => val.length > 1,
      },
      lastName: {
        validator: (val) => val.length > 1,
      },
      id: {
        validator: (val) => val && validator.isIdentityCard(val, "he-IL"),
        required: !props.player?.parent?.notIsraeli,
      },
      // passportNumber: {
      //   validator: (val) => val && val.trim().length,
      //   required: props.player?.parent?.notIsraeli,
      // },
      secondParentName: {
        validator: (val) => !val || val.length >= 2,
        required: false,
      },
      secondParentPhone: {
        validator: (val) => !val || validator.isMobilePhone(val, "he-IL"),
        required: false,
      },
      email: {
        validator: (val) => !val || validator.isEmail(val),
        required: false,
      },
      phone: {
        validator: (val) => validator.isMobilePhone(val, "he-IL"),
        required: true,
      },
    }),
    [props.player.parent]
  );

  const handleFieldChange = async (updates) => {
    setForm({ ...form, ...updates });

    if (instantValidate) {
      let { formErrors } = validateFormNg(form, formSettings);

      setErrors(formErrors);
    }
  };

  const handleSubmit = async () => {
    let { valid, formErrors } = validateFormNg(form, formSettings);
    setErrors(formErrors);
    if (valid) {
      setIsLoading(true);
      const data = {
        ...form,
      };

      if (
        (form.id && props.player.parent.id !== form.id) ||
        props.player.parent.phone !== form.phone
      ) {
        try {
          await axios.patch(
            `/clubParents/${props.player.parent._id}/changeIdentifiers`,
            { id: form.id, phone: form.phone }
          );
        } catch (error) {
          if (
            error.response &&
            error.response.status === 422 &&
            error.response.data &&
            error.response.data.errors
          ) {
            setErrors(
              normalizeServerValidationErrors(error.response.data.errors)
            );
          }
          setIsLoading(false);
          return;
        }
      }

      delete data.phone;
      delete data.id;

      try {
        await axios.patch(`/clubParents/${props.player.parent._id}`, data);
        setErrors({});
        setForm(defaultForm());
        setIsLoading(false);
        props.onComplete();
      } catch (error) {
        setIsLoading(false);
        setErrors(normalizeServerValidationErrors(error.response.data.errors));
      }
    }
  };

  const defaultTextFieldParams = {
    form,
    onChange: handleFieldChange,
    errors,
    formSettings,
    margin: "dense",
  };

  return (
    <Dialog
      dir="rtl"
      fullWidth={true}
      maxWidth="sm"
      onClose={props.onClose}
      open={props.open}
      fullScreen={!isSmUp}
    >
      <DialogTitle id="form-dialog-title">עדכון פרטי הורים</DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormTextField
              label="שם פרטי - הורה א"
              field="firstName"
              {...defaultTextFieldParams}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTextField
              label="שם משפחה - הורה א"
              field="lastName"
              {...defaultTextFieldParams}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTextField
              label="טלפון הורה א"
              field="phone"
              {...defaultTextFieldParams}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            {props.player.parent.notIsraeli ? (
              <FormTextField
                label="מספר דרכון"
                field="passportNumber"
                {...defaultTextFieldParams}
                textFieldProps={{ InputProps: { readOnly: true } }}
              />
            ) : (
              <FormTextField
                label="תעודת זהות הורה א"
                field="id"
                {...defaultTextFieldParams}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTextField
              label="אימייל"
              field="email"
              {...defaultTextFieldParams}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTextField
              label="עיר"
              field="city"
              {...defaultTextFieldParams}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTextField
              label="רחוב"
              field="street"
              {...defaultTextFieldParams}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTextField
              label="מספר בית"
              field="houseNumber"
              {...defaultTextFieldParams}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTextField
              label="שם הורה ב"
              field="secondParentName"
              {...defaultTextFieldParams}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTextField
              label="טלפון הורה ב"
              field="secondParentPhone"
              {...defaultTextFieldParams}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <ButtonSx
          disabled={isLoading}
          onClick={props.onClose}
          sx={{ width: 120 }}
        >
          ביטול
        </ButtonSx>

        <ButtonSx
          isLoading={isLoading}
          onClick={handleSubmit}
          debounce
          sx={{ width: 120 }}
        >
          שמירה
        </ButtonSx>
      </DialogActions>
    </Dialog>
  );
}

EditParentsDialog.propTypes = {
  player: PropTypes.object.isRequired,
  onComplete: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default EditParentsDialog;
