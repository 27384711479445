import React from "react";
import { Box } from "@mui/material";

export default function ReviewItemLeftSide({
  topLeft,
  topCenter,
  topRight,
  bottom,
}) {
  return (
    <div>
      <Box sx={styles.topContainer}>
        {topRight}
        <Box sx={styles.topCenterContainer}>{topCenter}</Box>
        {topLeft}
      </Box>
      {bottom}
    </div>
  );
}

const styles = {
  topContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    // compensate for the iconButton padding of the left side
    paddingLeft: "12px",
  },
  topCenterContainer: {
    fontSize: 16,
    fontWeight: "bold",
    marginLeft: "5px",
    width: 75,
    textAlign: "right",
    paddingRight: "5px",
  },
};
