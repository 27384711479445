import React from "react";
import { TextField, Grid, CircularProgress } from "@mui/material";
import _ from "lodash";

import MenuItem from "../base/MenuItem";
import AmountFilter from "./Amount";
import DatePickerAdvanced from "./DatePickerAdvanced";
import MultipleSelect from "../base/MultipleSelect";
import ButtonSx from "../base/ButtonSx";

export default function FiltersBar(props) {
  const [tempAmounts, setTempAmounts] = React.useState({
    from: "",
    to: "",
  });

  const [errors, setErrors] = React.useState({});

  React.useEffect(() => {
    // update amounts popup - required for loading initial state from url
    setTempAmounts({
      from: props.values.amount ? props.values.amount.from : "",
      to: props.values.amount ? props.values.amount.to : "",
    });
  }, [props.values.amount]);

  const buildFilter = (filter) => {
    switch (filter.type) {
      case "date":
        return (
          <DatePickerAdvanced
            dates={props.values.date}
            label={filter.label}
            onFilterChanged={(dates) => {
              props.onFilterChanged("date", dates);
            }}
            calendarProps={filter.calendarProps}
            {...(filter.filterProps || {})}
            fullWidth
            type="day"
          />
        );

      case "dateMonth":
        return (
          <DatePickerAdvanced
            dates={props.values.date}
            label={filter.label}
            onFilterChanged={(dates) => {
              props.onFilterChanged("date", dates);
            }}
            calendarProps={filter.calendarProps}
            {...(filter.filterProps || {})}
            fullWidth
            monthPicker
            minDate={filter.minDate}
            type="month"
          />
        );

      // case "singleDate":
      //   return (
      //     <DateFilter
      //       dates={{ from: props.values.singleDate }}
      //       onFilterChanged={(dates) => {
      //         props.onFilterChanged("singleDate", dates.from);
      //       }}
      //       mode="single"
      //       fullWidth
      //       type="day"
      //     />
      //   );

      case "amount":
        return (
          <AmountFilter
            tempAmounts={tempAmounts}
            amounts={props.values.amount}
            onAmountChanged={(type, value) =>
              setTempAmounts({ ...tempAmounts, [type]: value })
            }
            onFilterChanged={() => {
              props.onFilterChanged("amount", tempAmounts);
            }}
            fullWidth
          />
        );

      case "select":
        return (
          <TextField
            select
            size="small"
            label={filter.label}
            sx={styles.select}
            value={props.values[filter.name]}
            onChange={(e) => props.onFilterChanged(filter.name, e.target.value)}
            error={errors[filter.name] !== undefined}
            helperText={errors[filter.name]}
            {...(filter.isLoading ? { value: "טוען...", select: false } : {})}
            fullWidth
          >
            {filter.emptyLabel && (
              <MenuItem value="all">{filter.emptyLabel}</MenuItem>
            )}
            {Object.entries(filter.options).map(([value, title]) => (
              <MenuItem value={value} key={value}>
                {title}
              </MenuItem>
            ))}
          </TextField>
        );

      case "multiSelect":
        return (
          <MultipleSelect
            options={filter.options}
            onChange={(newValue) =>
              props.onFilterChanged(filter.name, newValue)
            }
            allOption
            allTitle={filter.emptyLabel}
            selectedItems={props.values[filter.name]}
            itemTitle={filter.itemTitle || filter.label}
            label={filter.label}
            isLoading={filter.isLoading}
            mustSelectAtLeastOne={filter.mustSelectAtLeastOne}
            fullWidth
          />
        );

      case "text":
        return (
          <TextField
            label={filter.label}
            sx={styles.select}
            size="small"
            value={props.values[filter.name]}
            onChange={(e) => props.onFilterChanged(filter.name, e.target.value)}
            fullWidth
          />
        );

      default:
        throw new Error("unknown filter type");
    }
  };

  const handleSearch = () => {
    const errors = {};
    let valid = true;
    props.filters.forEach((filter) => {
      if (
        filter.must &&
        (!props.values[filter.name] || props.values[filter.name] === "")
      ) {
        errors[filter.name] = "שדה חובה";
        valid = false;
      }
    });

    setErrors(errors);
    if (valid) {
      props.onSearch();
    }
  };

  const debounceSearch = _.debounce(handleSearch, 200);

  const handleSearchDebounce = (e) => {
    e.preventDefault();
    debounceSearch(e);
  };

  return (
    <form noValidate>
      <Grid
        container
        spacing={1}
        sx={styles.containerStyle}
        alignItems="center"
      >
        {props.filters.map((filter) => (
          <Grid
            item
            xs={props.autoSizing ? props.autoSizing : 6}
            sm={props.autoSizing ? undefined : 4}
            md={props.autoSizing ? undefined : 4}
            lg={props.autoSizing ? undefined : 3}
            xl={props.autoSizing ? undefined : 2}
            key={filter.name || filter.type}
          >
            {buildFilter(filter)}
          </Grid>
        ))}

        <Grid
          item
          xs={props.autoSizing ? props.autoSizing : 6}
          sm={props.autoSizing ? undefined : 4}
          md={props.autoSizing ? undefined : 4}
          lg={props.autoSizing ? undefined : 3}
          xl={props.autoSizing ? undefined : 2}
        >
          <ButtonSx
            sx={{ height: 40 }}
            variant="contained"
            onClick={handleSearchDebounce}
            color="primary"
            disabled={props.isSearching}
            fullWidth
            isLoading={props.isSearching}
            buttonProps={{ type: "submit" }}
          >
            {props.isSearching && (
              <CircularProgress size={24} sx={styles.progress} />
            )}
            {props.searchButtonTitle || "חיפוש"}
          </ButtonSx>
        </Grid>
      </Grid>
    </form>
  );
}

const styles = {
  containerStyle: {
    paddingBottom: 2,
  },
  progress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: "-12px",
    marginLeft: "-12px",
    color: "white",
  },
};
