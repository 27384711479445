import React, { useEffect, useMemo, useState } from "react";
import moment from "moment";
import ReactGA from "react-ga4";
import { useSelector } from "react-redux";

import Report from "./Report";
import { renderCell, sortValueCell } from "../../lib/tableHelpers";
import {
  baseRenderer,
  columns,
  filtersStructure,
  intialLogTypeFilterState,
} from "./subscriptionLogsHelper";
import { displaySeason } from "../../utils/helpers";

export default function SubscriptionLogs() {
  const [initialFiltersState] = useState({
    date: { from: moment().startOf("day"), to: moment().endOf("day") },
    teams: { all: true },
    logTypes: { ...intialLogTypeFilterState },
  });

  const seasons = useSelector((state) => state.general.seasons);
  const seasonsDic = useMemo(
    () => Object.fromEntries(seasons.map((s) => [s._id, s.year])),
    [seasons]
  );

  const cols = columns("subscriptionLogsReport");
  const structure = filtersStructure("subscriptionLogsReport");

  const baseRendererExtender = (
    columnId,
    log,
    columnDefenition,
    exportMode
  ) => {
    if (columnId === "season") {
      return seasonsDic[log.season];
    }
    return baseRenderer(columnId, log, columnDefenition, exportMode);
  };

  const displayRenderer = (columnId, _, value) => {
    switch (columnId) {
      case "season":
        return displaySeason(value);

      default:
        return value;
    }
  };

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/reports/subscriptionLogs",
      title: "Subscriprtion Logs Report",
    });
  }, []);

  return (
    <Report
      title="דו״ח פעילות"
      url="/store/subscriptionLogs?"
      initialFiltersState={initialFiltersState}
      filtersStructure={structure}
      renderCell={renderCell(
        cols,
        false,
        baseRendererExtender,
        displayRenderer
      )}
      sortValueCell={sortValueCell(cols, baseRendererExtender)}
      columns={cols}
      exportFileName="פעילות"
      renderCellExport={renderCell(
        cols,
        true,
        baseRendererExtender,
        displayRenderer
      )}
      defaultSortBy={0}
      defaultSortDirection={"desc"}
    />
  );
}
