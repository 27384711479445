import React from "react";
import { Button } from "@mui/material";
import TlPaper from "../../components/TlPaper";

class Players extends React.Component {
  constructor(props) {
    super(props);
    this.fr = null;
    this.state = { output: [] };
  }

  recomendedPackage = (count) => {
    if (count > 900) {
      return 170;
    }

    if (count > 170) {
      return 120;
    }

    if (count > 50) {
      return 100;
    }

    return 45;
  };

  handleFileLoaded = async () => {
    // const content = this.fr.result;
    // const results = {};
    // const output = [];
    // parseString(content, { headers: true })
    //   .on("data", (row) => {
    //     const terminal = row["מסוף"];
    //     results[terminal] = (results[terminal] || 0) + 1;
    //   })
    //   .on("end", (rowCount) => {
    //     results["emekheferrtok"] = results["emekhefertok"];
    //     delete results["emekhefertok"];
    //     output.push(`Parsed ${rowCount} rows`);
    //     const terminals = Object.keys(results);
    //     let counter = 0;
    //     terminals.forEach((t) => {
    //       if (t === "tltest") {
    //         return;
    //       }
    //       if (t.endsWith("tok") || t.endsWith("yok")) {
    //         const otherTerminal = t.slice(0, -3);
    //         if (results[otherTerminal]) {
    //           output.push(
    //             [
    //               ++counter,
    //               t,
    //               otherTerminal,
    //               "מספר עסקאות: " +
    //                 // Math.max(results[otherTerminal], results[t]),
    //                 results[otherTerminal],
    //               "חבילה מומלצת: " +
    //                 this.recomendedPackage(results[otherTerminal]),
    //               // "--------------מספר עסקאות מסוף טוקנים: " +
    //               //   Math.max(results[t]),
    //             ].join(", ")
    //           );
    //         } else {
    //           output.push([t, "other terminal not found"].join(", "));
    //         }
    //       }
    //     });
    //     this.setState({ output });
    //   });
  };

  handleNewFile = (e) => {
    const file = e.target.files[0];

    this.fr = new FileReader();
    this.fr.onloadend = this.handleFileLoaded;
    this.fr.readAsText(file);
  };

  render() {
    return (
      <TlPaper title="Internal Tools" titleBackground>
        <input
          accept=".csv"
          style={{ display: "none" }}
          id="raised-button-file"
          type="file"
          onChange={this.handleNewFile}
        />
        <label htmlFor="raised-button-file">
          <Button variant="outlined" component="span">
            Upload
          </Button>
        </label>

        <div>
          mapping:
          <div>
            0-50 {"\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0"}-&gt; 45
          </div>
          <div>71-170 {"\u00A0\u00A0\u00A0"} -&gt; 100</div>
          <div>171-900 {"\u00A0"} -&gt; 120</div>
          <div>901-... {"\u00A0\u00A0\u00A0\u00A0"} -&gt; 170</div>
        </div>

        <h5>output:</h5>
        {this.state.output.map((line, i) => {
          return <div key={i}>{line}</div>;
        })}
      </TlPaper>
    );
  }
}

export default Players;
