import React from "react";
import {
  Grid,
  TextField,
  FormHelperText,
  List,
  ListItem,
  Checkbox,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Check";
import CancelIcon from "@mui/icons-material/Close";

import { displayPrice } from "../../../utils/helpers";
import InfoRow from "../../InfoRows/InfoRow";
import FlexCenter from "../../FlexCenter";
import Paper2Sx from "../../Paper2Sx";

export default function RefundProducts(props) {
  const { refundedItems, errors } = props.form;
  const isSmUp = useMediaQuery((theme) => theme.breakpoints.up("sm"), {
    noSsr: true,
  });
  isSmUp;

  const renderItems = () => {
    return (
      <>
        {/* maxRefundAmount - can only happen for refunds created before the "complex refunds fix" */}
        {errors.refundAmount ||
          (errors.maxRefundAmount && (
            <FormHelperText error style={{ fontSize: 20 }}>
              {errors.refundAmount
                ? "יש לבחור מוצרים להחזר"
                : `הסכום המקסימלי
              להחזר עבור עסקה זו הוא ${displayPrice(
                props.transaction.payment.refundableAmount
              )}`}
            </FormHelperText>
          ))}
        <List disablePadding>
          {refundedItems.map((refundItem, i) => {
            const { purchasedItem } = refundItem;

            const fullyRefunded =
              purchasedItem.refundedAmount === -purchasedItem.price;
            let secondaryText;

            if (fullyRefunded) {
              secondaryText = "לפריט זה בוצע זיכוי מלא";
            } else {
              secondaryText =
                purchasedItem.type === "subscription" ||
                props.allowMerchandisePartialRefund
                  ? `סכום מירבי להחזר: ${displayPrice(
                      refundItem.maxRefundAmount
                    )}`
                  : purchasedItem.status === "returned"
                  ? "מוצר זה הוחזר"
                  : "";
            }

            if (
              purchasedItem &&
              purchasedItem.details &&
              purchasedItem.details.player
            ) {
              secondaryText = (
                <span>
                  {secondaryText}
                  <div>ספורטאי: {purchasedItem.details.player.name}</div>
                </span>
              );
            }

            return (
              <ListItem
                key={purchasedItem._id}
                role={undefined}
                dense
                disabled={fullyRefunded}
                sx={styles.listItem}
              >
                <Checkbox
                  sx={styles.checkBox}
                  checked={refundItem.checked}
                  tabIndex={-1}
                  disableRipple
                  onClick={() =>
                    props.onItemChange(i, {
                      checked: !refundItem.checked,
                      editing: false,
                    })
                  }
                  disabled={fullyRefunded}
                />
                <ListItemText
                  primary={`${purchasedItem.product.title}`}
                  sx={{ maxWidth: "calc(100vw - 150px)" }}
                  primaryTypographyProps={{
                    sx: { fontWeight: "bold" },
                  }}
                  secondary={secondaryText}
                  secondaryTypographyProps={{ component: "span" }}
                />
                <ListItemSecondaryAction sx={styles.leftSide}>
                  {purchasedItem.type === "subscription" ||
                  props.allowMerchandisePartialRefund ? (
                    renderSubscriptionItem(refundItem, i)
                  ) : (
                    <b>{displayPrice(refundItem.maxRefundAmount)}</b>
                  )}
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </List>
      </>
    );
  };

  const renderSubscriptionItem = (refundItem, itemIndex) => {
    if (refundItem.editing) {
      return (
        <Paper2Sx>
          <FlexCenter>
            <IconButton
              edge="end"
              onClick={() => props.onItemAmountChange(itemIndex)}
              size="large"
            >
              <SaveIcon />
            </IconButton>
            <IconButton
              onClick={() => props.onItemChange(itemIndex, { editing: false })}
              size="large"
            >
              <CancelIcon />
            </IconButton>
            <TextField
              variant="standard"
              style={{ width: 100 }}
              type="number"
              value={refundItem.tempAmount}
              onChange={(e) => {
                props.onItemChange(itemIndex, {
                  tempAmount: Math.floor(parseFloat(e.target.value) * 10) / 10, // removes non-digits btw and leaves 1 digit after decimal point
                });
              }}
              error={refundItem.error}
            />
          </FlexCenter>
          <FlexCenter>
            <FormHelperText error={refundItem.error}>
              {refundItem.error}
            </FormHelperText>
          </FlexCenter>
        </Paper2Sx>
      );
    } else {
      return (
        <>
          <IconButton
            edge="end"
            onClick={() => props.onItemChange(itemIndex, { editing: true })}
            disabled={!refundItem.checked}
            size="large"
          >
            <EditIcon />
          </IconButton>
          <b>{displayPrice(refundItem.refundAmount)}</b>
        </>
      );
    }
  };

  return (
    <Paper2Sx externalTitle="בחירת פריטים להחזר">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {renderItems()}
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="standard"
            required
            fullWidth
            label="סיבת החזר"
            value={props.form.comment}
            onChange={(e) => {
              props.onChange({ comment: e.target.value });
            }}
            error={errors.comment}
            helperText={errors.comment ? "שדה חובה" : ""}
          />
        </Grid>
        <Grid item xs={12}>
          <InfoRow
            title="סכום כולל להחזר"
            value={displayPrice(props.form.refundAmount)}
            titleSx={{ width: 120 }}
          />
        </Grid>
      </Grid>
    </Paper2Sx>
  );
}

const styles = {
  listItem: {
    padding: "10px",
    border: "1px solid #EEE",
    borderRadius: "5px",
    marginTop: "7px",
    minHeight: 80,
  },
  checkBox: {
    marginLeft: { xs: "-15px", sm: "-10px" },
  },
  leftSide: {
    marginRight: { xs: "-10px", sm: 0 },
  },
};
