import React from "react";
import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";

import CreditCardForm from "./CreditCardForm";
import CheckFrom from "./CheckForm";
import WireTransferForm from "./WireTransferForm";

export default function PaymentMethod(props) {
  return (
    <>
      <FormControl
        variant="standard"
        component="fieldset"
        disabled={props.disabled}
      >
        <FormLabel component="legend">אמצעי תשלום</FormLabel>
        <RadioGroup
          row
          aria-label="gender"
          value={props.state.paymentMethod}
          onChange={(e) => props.onChange({ paymentMethod: e.target.value })}
        >
          {props.availablePaymentMethods["creditCard"] && (
            <FormControlLabel
              value="creditCard"
              control={<Radio />}
              label="כרטיס אשראי"
            />
          )}
          {props.availablePaymentMethods["check"] && (
            <FormControlLabel value="check" control={<Radio />} label="צ׳ק" />
          )}
          {props.availablePaymentMethods["cash"] && (
            <FormControlLabel value="cash" control={<Radio />} label="מזומן" />
          )}
          {props.availablePaymentMethods["wireTransfer"] && (
            <FormControlLabel
              value="wireTransfer"
              control={<Radio />}
              label="העברה בנקאית"
            />
          )}
        </RadioGroup>
      </FormControl>

      {!props.disabled && props.state.paymentMethod === "creditCard" && (
        <CreditCardForm
          state={props.state}
          onChange={props.onChange}
          clubConfig={props.clubConfig}
          store={props.store}
          cart={props.cart}
          paymentAmount={props.paymentAmount}
          purchaseType={props.purchaseType}
        />
      )}
      {!props.disabled && props.state.paymentMethod === "check" && (
        <CheckFrom
          state={props.state}
          onChange={props.onChange}
          total={props.paymentAmount || 0}
        />
      )}
      {!props.disabled && props.state.paymentMethod === "wireTransfer" && (
        <WireTransferForm state={props.state} onChange={props.onChange} />
      )}
    </>
  );
}
