import React from "react";
import Chart from "react-apexcharts";
import { Box, Typography } from "@mui/material";

function CoachesFillRate(props) {
  const series = [props.fillRate];
  const generateOptions = (title) => ({
    chart: {
      height: 200,
      type: "radialBar",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: "70%",
          background: "#fff",
          image: undefined,
          imageOffsetX: 0,
          imageOffsetY: 0,
          position: "front",
          dropShadow: {
            enabled: true,
            top: 3,
            left: 0,
            blur: 4,
            opacity: 0.24,
          },
        },
        track: {
          background: "#fff",
          strokeWidth: "67%",
          margin: 0, // margin is in pixels
          dropShadow: {
            enabled: true,
            top: -3,
            left: 0,
            blur: 4,
            opacity: 0.35,
          },
        },

        dataLabels: {
          show: true,
          name: {
            offsetY: -10,
            show: true,
            color: "#888",
            fontSize: "17px",
          },
          total: {
            show: false,
          },
          value: {
            formatter: function (val) {
              return parseInt(val) + "%";
            },
            color: "#111",
            fontSize: "24px",
            show: true,
          },
        },
      },
    },
    colors: ["#388E3C"],
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        type: "horizontal",
        shadeIntensity: 0.5,
        gradientToColors: ["#388E3C"],
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100],
      },
    },
    stroke: {
      lineCap: "round",
    },
    labels: [title],
  });

  return (
    <Box sx={styles.container}>
      <Chart
        options={generateOptions("שבוע אחרון")}
        series={series}
        type="radialBar"
        height={300}
      />
      <Typography variant="h6">מילוי נוכחות</Typography>
    </Box>
  );
}

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    alignItems: "center",
    letterSpacing: "normal",
  },
};

export default CoachesFillRate;
