import React from "react";
import moment from "moment";
import { Box, FormHelperText, TableContainer, TextField } from "@mui/material";

import EnhancedTable from "../table/EnhancedTable";
import { banks, dicToArray } from "../../utils/dictionaries";
import AutoComplete from "../base/AutoComplete";
import { displayDate, sxx } from "../../utils/helpers";
import DatePicker from "../DatePicker";

const banksArray = dicToArray(banks);

const columns = [
  { id: "index", label: "", width: 20, padding: "none" },
  { id: "amount", label: "סכום", width: 80, padding: "none" },
  { id: "date", label: "תאריך", width: 120, padding: "none" },
  { id: "checkNumber", label: "מספר צ׳ק", width: 130, padding: "none" },
  { id: "accountNumber", label: "מספר חשבון", width: 130, padding: "none" },
  { id: "branch", label: "סניף", width: 80, padding: "none" },
  { id: "bank", label: "בנק", width: 250, padding: "none" },
];

const handleIntField = (e) => parseFloat(e.target.value);
const fields = {
  amount: {
    updateAllChecks: false,
    valueExtractor: handleIntField,
  },
  checkNumber: {
    handler: (check, orgValue) => orgValue + check.index,
    updateAllChecks: true,
    valueExtractor: handleIntField,
  },
  date: {
    handler: (check, orgValue) => moment(orgValue).add(check.index, "months"),
    updateAllChecks: true,
    valueExtractor: (e) => e,
  },
  accountNumber: {
    updateAllChecks: true,
  },
  branch: {
    updateAllChecks: true,
  },
  bank: {
    updateAllChecks: true,
    valueExtractor: (e) => e,
  },
};

export default function ChecksTable(props) {
  const checkErrors = props.errors.checks || {};

  const onChange = (check, field) => (event) => {
    const fieldConfig = fields[field];
    const value = fieldConfig.valueExtractor
      ? fieldConfig.valueExtractor(event)
      : event.target.value;

    if (
      check.index === 0 &&
      fieldConfig.updateAllChecks &&
      !props.advancedMode
    ) {
      const checks = [...props.checks];
      for (let index = 0; index < checks.length; index++) {
        const check = checks[index];
        check[field] = fieldConfig.handler
          ? fieldConfig.handler(check, value)
          : value;
      }
      props.onChange({ checks });
    } else {
      props.updateCheck(check.index, { [field]: value });
    }
  };

  const errorField = (check, field) =>
    `${check.index}${field[0].toUpperCase()}${field.slice(1)}`;

  const renderItem = (check, editable, fieldName) => {
    const error = checkErrors[errorField(check, fieldName)];
    return (
      <Box sx={styles.cellContainer}>
        {editable ? (
          <TextField
            variant="standard"
            fullWidth
            margin="dense"
            value={check[fieldName]}
            type="number"
            onChange={onChange(check, fieldName)}
            error={error !== undefined}
          />
        ) : (
          renderReadOnlyItemContent(error, check[fieldName] || "-")
        )}
      </Box>
    );
  };

  const renderReadOnlyItemContent = (error, value) => (
    <Box sx={sxx({}, [[error, styles.error]])}>
      {value}
      {error && <FormHelperText error>*{error}</FormHelperText>}
    </Box>
  );

  const renderCell = (columnId, check) => {
    let date;
    const editable = check.index === 0 || props.advancedMode;
    switch (columnId) {
      case "index":
        return `${check.index + 1}.`;

      case "amount":
      case "accountNumber":
      case "checkNumber":
      case "branch":
        return renderItem(check, editable, columnId);

      case "bank":
        return (
          <Box sx={styles.cellContainer}>
            {editable ? (
              <AutoComplete
                options={banksArray}
                mode="objects"
                value={check.bank}
                onChange={onChange(check, "bank")}
                textFieldParams={{
                  error: checkErrors[`${check.index}Bank`],
                }}
              />
            ) : (
              renderReadOnlyItemContent(
                checkErrors[`${check.index}Bank`],
                check.bank ? check.bank.value : "-"
              )
            )}
          </Box>
        );

      case "date":
        date = check?.date;
        return (
          <Box sx={styles.cellContainer}>
            {editable ? (
              <DatePicker
                value={check.date}
                onChange={onChange(check, "date")}
              />
            ) : (
              renderReadOnlyItemContent(
                checkErrors[`${check.index}Date`],
                date ? displayDate(date) : "-"
              )
            )}
          </Box>
        );

      default:
        return check[columnId];
    }
  };

  return (
    <TableContainer sx={{ minWidth: 700 }}>
      <EnhancedTable
        size="small"
        headers={columns}
        renderCell={renderCell}
        rows={props.checks}
        sort={false}
        renderKey={(row) => row.index}
      />
    </TableContainer>
  );
}

const styles = {
  cellContainer: {
    padding: "3px",
  },
  error: {
    color: "error.main",
  },
};
