import React from "react";
import { Box, Typography } from "@mui/material";

export default function MobileContainer(props) {
  return (
    <div>
      <Box sx={styles.titleContainer}>
        <Typography variant="h5">{props.title}</Typography>
      </Box>
      <div>{props.children}</div>
    </div>
  );
}

const styles = {
  titleContainer: {
    textAlign: "center",
    marginBottom: 1,
  },
};
