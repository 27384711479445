import React from "react";
import { Grid } from "@mui/material";

import axios from "../../utils/axios";
import PlayersDetails from "./PlayersDetails";
import Purchases from "./Purchases";
import Parents from "../Players/Parents";
import withParams from "../../components/routing/withParams";

class Account extends React.Component {
  state = {
    parent: {},
    purchases: [],
    isLoading: true,
  };

  componentDidMount = async () => {
    this.loadData();
  };

  loadData = async function () {
    this.setState({ isLoading: true });

    let res = await axios.get(
      `/clubParents/${this.props.params.parentId}?granularity=clubPlayers`
    );
    const parent = res.data;

    res = await axios.get(
      `/payments/transactions?clubAccountId=${parent.clubAccountId}`
    );
    const transactions = res.data;
    const transactionsMap = new Map(transactions.map((t) => [t.purchaseId, t]));

    // notStatus is required for filtering transactions with status error (they do have clubAccountId)
    res = await axios.get(
      `/store/purchases?clubAccountId=${parent.clubAccountId}&notStatus=declined`
    );
    const purchases = res.data;
    const purchasesMap = new Map(purchases.map((t) => [t._id, t]));

    this.setState({
      parent,
      transactionsMap,
      purchases,
      purchasesMap,
      isLoading: false,
    });
  };

  render() {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Parents
            parent={this.state.parent}
            isLoading={this.state.isLoading}
          />
        </Grid>
        <Grid item xs={12}>
          <PlayersDetails players={this.state.parent.clubPlayers} />
        </Grid>
        <Grid item xs={12}>
          {this.state.purchases && this.state.purchases.length > 0 && (
            <Purchases
              transactionsMap={this.state.transactionsMap}
              purchases={this.state.purchases}
              purchasesMap={this.state.purchasesMap}
              isLoading={this.state.isLoading}
            />
          )}
        </Grid>
      </Grid>
    );
  }
}

export default withParams(Account);
