export const availablePaymentMethods = (clubConfig) => {
  const paymentMethods = ["creditCard", "check", "cash", "wireTransfer"];
  const availablePaymentMethods = paymentMethods.reduce((all, pm) => {
    const enabled = ((clubConfig.payments.paymentMethods || {})[pm] || {})
      .enabled;
    all[pm] = enabled !== false;
    return all;
  }, {});

  return availablePaymentMethods;
};
