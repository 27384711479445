import React from "react";

import ColorInfoRow from "../../../components/InfoRows/ColorInfoRow";

export default function ThemeSettings({ onChange, clubConfig }) {
  const handleColorChanged =
    (configName, configSection) => async (newColor) => {
      const fullConfigName = `${configSection}.${configName}`;
      return onChange(
        fullConfigName,
        newColor,
        {
          [configSection]: {
            ...clubConfig[configSection],
            [configName]: newColor,
          },
        },
        false
      );
    };

  const adminConfig = clubConfig ? clubConfig.admin : {};
  const mobileConfig = clubConfig ? clubConfig.mobile : {};

  return (
    <div>
      <ColorInfoRow
        value={adminConfig.mainBackgroundColor}
        title="אדמין צבע ראשי"
        titleSx={styles.infoRowTitle}
        onChange={handleColorChanged("mainBackgroundColor", "admin")}
      />
      <ColorInfoRow
        value={adminConfig.secondaryColor}
        title="אדמין צבע משני"
        titleSx={styles.infoRowTitle}
        onChange={handleColorChanged("secondaryColor", "admin")}
      />
      <ColorInfoRow
        value={mobileConfig.mainBackgroundColor}
        title="אפליקציה צבע ראשי"
        titleSx={styles.infoRowTitle}
        onChange={handleColorChanged("mainBackgroundColor", "mobile")}
      />
    </div>
  );
}

const styles = {
  infoRowTitle: {
    width: 260,
  },
};
