import React from "react";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";

export default function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {props.collapsableRow && <TableCell key={"collapsable"} />}
        {props.headers.map((row, index) => (
          <TableCell
            key={row.id}
            align={row.alignment || "left"}
            padding={row.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === row.id ? order : false}
            {...(row.width ? { width: row.width } : {})}
            style={{
              ...(row.minWidth ? { minWidth: row.minWidth } : {}),
              borderRight: index === 0 ? "0px" : "1px solid #EFEFEF",
              fontWeight: "bold",
            }}
          >
            {props.sort !== false ? (
              <TableSortLabel
                // dir="ltr"
                active={orderBy === row.id}
                direction={order}
                onClick={createSortHandler(row.id)}
              >
                {row.label}
              </TableSortLabel>
            ) : (
              row.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
