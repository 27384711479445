import React from "react";
import { TextField } from "@mui/material";

import MenuItem from "../../components/base/MenuItem";

export default function TimeSelect(props) {
  return (
    <TextField
      error={props.error !== undefined}
      select={!props.readOnly}
      variant="standard"
      label={props.label}
      value={props.value}
      onChange={(uiEvent) => props.onChange(uiEvent.target.value)}
      fullWidth
      style={{ width: 90 }}
      inputProps={{
        readOnly: props.readOnly,
      }}
      margin="dense"
    >
      {props.times.map((time) => (
        <MenuItem key={time} value={time}>
          {time}
        </MenuItem>
      ))}
    </TextField>
  );
}
