import React from "react";
import _ from "lodash";
import {
  Grid,
  TextField,
  Button,
  FormControlLabel,
  Switch,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import MenuItem from "../base/MenuItem";
import { displayPrice } from "../../utils/helpers";
import ChecksTable from "./ChecksTable";

export default function CheckForm(props) {
  const [advancedMode, setAdvancedMode] = React.useState(false);

  const calcChecksBreakdown = (numberOfChecks, price) => {
    let priceAgorot = price * 100;

    const paymentAmount = Math.round(priceAgorot / numberOfChecks);
    const firstPaymentAmount =
      priceAgorot - paymentAmount * (numberOfChecks - 1);

    return {
      paymentAmount: paymentAmount / 100,
      firstPaymentAmount: firstPaymentAmount / 100,
    };
  };

  const totalPrice = props.total;
  const totalChecksAmount =
    Math.round(_.sum(props.state.check.checks.map((c) => c.amount)) * 100) /
    100;
  const totalChecksDiff = totalPrice - totalChecksAmount;

  const generateChecks = (numberOfChecks) => {
    const { paymentAmount, firstPaymentAmount } = calcChecksBreakdown(
      numberOfChecks,
      totalPrice
    );
    const checks = [...Array(numberOfChecks).keys()].map((i) => {
      return {
        index: i,
        amount: i === 0 ? firstPaymentAmount : paymentAmount,
        date: null,
        checkNumber: "",
        accountNumber: "",
        branch: "",
        bank: null,
      };
    });

    return checks;
  };

  const updateCheck = (checkIndex, update) => {
    const checks = [...props.state.check.checks];
    checks[checkIndex] = { ...checks[checkIndex], ...update };
    handleChange({ checks });
  };

  const handleChange = (checkUpdates, updates = {}) => {
    props.onChange({
      check: { ...props.state.check, ...checkUpdates },
      ...updates,
    });
  };

  const spreadChecksAmounts = () => {
    const { paymentAmount, firstPaymentAmount } = calcChecksBreakdown(
      props.state.check.numberOfChecks,
      totalPrice
    );
    const checks = props.state.check.checks.map((c, i) => {
      c.amount = i === 0 ? firstPaymentAmount : paymentAmount;
      return { ...c };
    });

    handleChange({ checks });
  };

  return (
    <>
      <Grid container spacing={1} justifyContent="space-between">
        <Grid item sm xs={12}>
          <TextField
            variant="standard"
            label="מספר צ׳קים"
            fullWidth
            select
            sx={{ maxWidth: 410, mb: 1 }}
            value={props.state.check.numberOfChecks}
            onChange={(event) => {
              const errors = { ...props.state.errors };
              delete errors.checks;
              handleChange(
                {
                  numberOfChecks: event.target.value,
                  checks: generateChecks(
                    event.target.value,
                    props.state.check.firstCheckDate
                  ),
                },
                { errors }
              );
            }}
            error={props.state.errors.numberOfChecks !== undefined}
            helperText={props.state.errors.numberOfChecks}
          >
            {[...Array(12).keys()].map((i) => (
              <MenuItem value={i + 1} key={i}>
                {i + 1} צ׳קים
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid
          item
          xs={12}
          sm
          sx={{
            display: "flex",
            justifyContent: { xs: "flex-start", sm: "flex-end" },
          }}
        >
          <FormControlLabel
            control={
              <Switch
                checked={advancedMode}
                onChange={() => setAdvancedMode(!advancedMode)}
                name="advancedMode"
                color="primary"
              />
            }
            label="עריכה מתקדמת"
          />
        </Grid>

        {props.state.check.numberOfChecks && (
          <Grid item xs={12} sx={{ overflowX: "auto" }}>
            <ChecksTable
              checks={props.state.check.checks}
              advancedMode={advancedMode}
              updateCheck={updateCheck}
              onChange={handleChange}
              errors={props.state.errors}
            />
          </Grid>
        )}

        <Grid item xs={12}>
          {props.state.check.numberOfChecks && totalChecksDiff !== 0 && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                ...(totalChecksDiff !== 0 ? { color: "red" } : {}),
              }}
            >
              {Number.isNaN(totalChecksDiff) ? (
                "סכום לא תקין"
              ) : (
                <div>
                  <span style={{ display: "flex", alignItems: "center" }}>
                    {<CloseIcon />} סכום הצ׳קים הכולל (
                    {displayPrice(totalChecksAmount)}) אינו תואם את הסכום לתשלום
                    ({displayPrice(totalPrice)})
                  </span>
                  <div>
                    <Button variant="outlined" onClick={spreadChecksAmounts}>
                      עדכון סכומי הצ׳קים
                    </Button>
                  </div>
                </div>
              )}
            </div>
          )}
        </Grid>
      </Grid>
    </>
  );
}
