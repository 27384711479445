import React from "react";
import { Grid } from "@mui/material";
import moment from "moment";

import TimeSelect from "../TimeSelect";
import DatePicker from "../../../components/DatePicker";

export default function RegularEventTimes(props) {
  const { event, prepareTime, onFieldUpdated } = props;
  return (
    <>
      <Grid item container spacing={2}>
        <Grid item>
          <DatePicker
            readOnly={props.readOnly}
            label="תאריך"
            disablePast
            value={moment(event.start)}
            textFieldProps={{ sx: { width: 120 } }}
            onChange={(date) => {
              const newStartDate = prepareTime(
                date,
                moment(event.start).format("HH:mm")
              ).toDate();
              const newEndDate = prepareTime(
                date,
                moment(event.end).format("HH:mm")
              ).toDate();

              onFieldUpdated("start", newStartDate);
              onFieldUpdated("end", newEndDate);
            }}
          />
        </Grid>
        <Grid item sx={{ ml: { sm: "20px" } }}>
          <TimeSelect
            label="שעת התחלה"
            readOnly={props.readOnly}
            value={moment(event.start).format("HH:mm")}
            times={props.times}
            onChange={(time) => {
              onFieldUpdated("start", prepareTime(event.start, time).toDate());

              if (
                props.times.indexOf(time) >=
                props.times.indexOf(moment(event.end).format("HH:mm"))
              ) {
                onFieldUpdated("end", null);
              }
            }}
          />
        </Grid>
        <Grid item>
          <TimeSelect
            label="שעת סיום"
            readOnly={props.readOnly}
            error={props.errors.end}
            value={moment(event.end).format("HH:mm")}
            times={props.optionalEndTimes(moment(event.start).format("HH:mm"))}
            onChange={(time) => {
              const temp = prepareTime(
                event.start, // .end might be null
                time
              );
              onFieldUpdated("end", temp.toDate());
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}
