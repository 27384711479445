import React from "react";
import moment from "moment";
import { Link as RouterLink } from "react-router-dom";
import { IconButton, Link } from "@mui/material";
import LinkIcon from "@mui/icons-material/Link";

import {
  transactionSources,
  transactionKinds,
} from "../../../utils/dictionaries";

import { displayPrice } from "../../../utils/helpers";
import Table from "../../../components/table/Table";
import { exportTable } from "../../../lib/tableHelpers";

const baseColumns = [
  { id: "receiptNumber", label: "מספר קבלה" },
  { id: "amount", label: "סכום" },
  { id: "paidAt", label: "תאריך ושעה" },
  { id: "transactionType", label: "סוג עסקה" },
  { id: "payerName", label: "שם הלקוח" },
];

const columns = [...baseColumns, { id: "actions", label: "", width: "40px" }];

function ReceiptsTable(props) {
  const renderCell = (columnId, transaction) => {
    switch (columnId) {
      case "amount":
        return displayPrice(transaction.amount);
      case "paidAt":
        return moment(transaction.createdAt).format("DD/MM/YY LT");

      case "source":
        return transactionSources[transaction.source];

      case "receiptNumber":
        return (
          <Link
            component={RouterLink}
            color="primary"
            to={`../payments/transactions/${transaction.transactionId}`}
            underline="hover"
          >
            {transaction.receiptNumber}
          </Link>
        );

      case "transactionType":
        return transactionKinds[transaction.kind];

      case "payerName":
        return (transaction.payer || {}).name;

      case "actions":
        return (
          <IconButton
            href={transaction.receiptUrl}
            target="_blank"
            size="large"
          >
            <LinkIcon />
          </IconButton>
        );

      default:
        return transaction[columnId];
    }
  };

  const sortValueCell = (columnId, transaction) => {
    switch (columnId) {
      case "paidAt":
        return moment(transaction.createdAt).unix();

      default:
        return transaction[columnId];
    }
  };

  const renderCellExport = (columnId, transaction) => {
    switch (columnId) {
      case "amount":
        return transaction.amount;

      case "receiptNumber":
        return transaction.receiptNumber;

      default:
        return renderCell(columnId, transaction);
    }
  };

  const handleExportTable = () => {
    return exportTable(
      props.transactions,
      baseColumns,
      renderCellExport,
      "csv",
      "מסמכים חשבונאיים"
    );
  };

  return (
    <Table
      columns={columns}
      renderCell={renderCell}
      sortValueCell={sortValueCell}
      rows={props.transactions}
      title="מסמכים"
      defaultSortDirection="desc"
      onExport={handleExportTable}
      isLoading={props.isLoading}
    />
  );
}

export default ReceiptsTable;
