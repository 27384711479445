import React, { useEffect, useState } from "react";
import moment from "moment";
import ReactGA from "react-ga4";

import Report from "./Report";
import { renderCell, sortValueCell } from "../../lib/tableHelpers";

const baseColumns = () => [
  {
    id: "category",
    label: "סוג",
    mapping: { subscription: "מסגרת", merchandise: "מוצר", fee: "דמי רישום" },
    preset: "mapping",
  },
  {
    id: "title",
    label: "שם",
  },
  {
    id: "total",
    label: "סה״כ",
    preset: "price",
  },
];

const filtersStructure = [{ type: "dateMonth", minDate: moment("2022-01-01") }];

export default function MonthlyIncomePerProduct() {
  const [initialFiltersState] = useState({
    date: { from: moment().startOf("month"), to: moment().endOf("month") },
  });
  const [columns, setColumns] = useState([...baseColumns()]);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/reports/monthlyIncomePerProduct",
      title: "MonthlyIncomePerProduct Report",
    });
  }, []);

  const prepareData = ({
    products,
    firstMonth: firstMonthRaw,
    lastMonth: lastMonthRaw,
  }) => {
    const monthlyIncomeColumns = [];
    const lastMonth = moment(lastMonthRaw);
    const firstMonth = moment(firstMonthRaw);

    while (firstMonth.isSameOrBefore(lastMonth)) {
      monthlyIncomeColumns.push({
        id: firstMonth.format("MM/YY"),
        label: firstMonth.format("MM/YY"),
        monthlyIncome: true,
        preset: "price",
        baseRenderer: true,
      });
      firstMonth.add(1, "month");
    }
    setColumns([...baseColumns(), ...monthlyIncomeColumns]);

    return products;
  };

  const baseRenderer = (columnId, product, column) => {
    if (column.monthlyIncome) {
      return columnId in product.monthlyIncomes
        ? product.monthlyIncomes[columnId]
        : "-";
    }

    switch (columnId) {
      default:
        return product[columnId];
    }
  };

  return (
    <Report
      title="פירעון תשלומים חודשי"
      url="/reports/payments/monthlyIncomePerProduct?"
      prepareData={prepareData}
      initialFiltersState={initialFiltersState}
      filtersStructure={filtersStructure}
      renderCell={renderCell(columns, false, baseRenderer)}
      sortValueCell={sortValueCell(columns, baseRenderer)}
      columns={columns}
      exportFileName="פירעון תשלומים חודשי"
      renderCellExport={renderCell(columns, true, baseRenderer)}
    />
  );
}
