import React, { useState } from "react";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { Box, Collapse, Grid, Typography } from "@mui/material";
import { FaTag } from "react-icons/fa6";
import { FaDollarSign } from "react-icons/fa";
import { connect } from "react-redux";

import { displayPrice, displaySeason } from "../../../utils/helpers";
import RevenueCategory from "./RevenueCategory";
import IconButtonTooltip from "../../../components/base/IconButtonTooltip";
import RevenueChart from "./RevenueChart";
import RevenueIcon from "./RevenueIcon";
import { clubCategories } from "../../../utils/dictionaries";
import Paper2Sx from "../../../components/Paper2Sx";

function RevenueSeason(props) {
  const [isOpen, setIsOpen] = useState(false);
  const { products, season, totalPaid, paidByCategory } = props.season;

  return (
    <Paper2Sx
      paperSx={{ ...styles.container, ...(isOpen ? styles.openContainer : {}) }}
    >
      <Box sx={styles.downloadButton}>
        <IconButtonTooltip
          title="הורדה"
          onClick={props.onDownloadSeason(props.season)}
        >
          <ArrowDownwardIcon />
        </IconButtonTooltip>
      </Box>
      <Grid container justifyContent="space-between">
        <Grid
          item
          container
          xs={12}
          onClick={() => totalPaid > 0 && setIsOpen(!isOpen)}
          alignItems="center"
          sx={styles.header}
        >
          <Grid item xs>
            <Typography variant="h5" component="span" sx={styles.title}>
              <Box sx={styles.seasonTitleContainer}>
                <RevenueIcon color="#00e396">
                  <FaTag style={{ color: "FFF", fontSize: 40, padding: 2 }} />
                </RevenueIcon>
                <div>
                  {displaySeason(season.year)} -{" "}
                  {props.clubsHash[season.club].clubConfig.general.branchName ||
                    clubCategories[props.clubsHash[season.club].category]}
                </div>
                <div>
                  <Typography variant="subtitle1">עונה</Typography>
                </div>
              </Box>
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography variant="h5" component="span" sx={styles.title}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <RevenueIcon color="#008ffb">
                    <FaDollarSign
                      style={{ color: "FFF", fontSize: 40, padding: 2 }}
                    />
                  </RevenueIcon>

                  <div>{displayPrice(totalPaid)}</div>
                  <div>
                    <Typography variant="subtitle1">הכנסות</Typography>
                  </div>
                </span>
              </div>
            </Typography>
          </Grid>

          <Grid item xs={5}>
            {totalPaid === 0 ? (
              <Box sx={styles.chartTextNoData}>
                <Typography>לא קיימות הכנסות לעונה זו</Typography>
              </Box>
            ) : (
              <RevenueChart
                labels={["בית ספר", "ליגה", "מחנה"]}
                series={[
                  paidByCategory.school,
                  paidByCategory.league,
                  paidByCategory.camp,
                ]}
              />
            )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Collapse in={isOpen}>
            <RevenueCategory row={{ type: "subscription", products }} />
          </Collapse>
        </Grid>
      </Grid>
    </Paper2Sx>
  );
}

const styles = {
  container: {
    minHeight: 170,
    display: "flex",
    alignItems: "center",
    marginBottom: "30px",
    "&:hover": {
      backgroundColor: "#FAFAFA",
    },
    position: "relative",
  },
  openContainer: {
    backgroundColor: "#FAFAFA",
  },
  downloadButton: {
    zIndex: 100,
    position: "absolute",
    top: 5,
    right: 5,
  },
  header: {
    height: 200,
    cursor: "grab",
  },
  chartTextNoData: {
    display: "flex",
    justifyContent: "flex-end",
    paddingRight: "55px",
  },
  seasonTitleContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
};

function mapStateToProps(state) {
  return {
    clubsHash: state.auth.clubsHash,
  };
}

export default connect(mapStateToProps)(RevenueSeason);
