import React, { useEffect, useState } from "react";
import _ from "lodash";
import moment from "moment";
import { Skeleton, Typography } from "@mui/material";

import axios from "../../utils/axios";
import MerchandiseTable from "../Store/Purchases/MerchandiseTable";
import MerchandiseUpdateStatusDialog from "../Store/Purchases/MerchandiseUpdateStatusDialog";
import SnackbarNg from "../../components/base/SnackbarNg";
import AbsoluteCenter from "../../components/AbsoluteCenter";

export default function PlayerPurchasedMerchandise(props) {
  const [items, setItems] = useState(null);
  const [isUpdateDialogOpen, setIsUpdateDialogOpen] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [snackbarDetails, setSnackbarDetails] = useState({ key: null });

  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      /* reduce the amount of data */
      const fromDate = moment().add(-2, "year");
      let res = await axios.get(
        `/store/purchases/products?clubPlayer=${
          props.player._id
        }&dateFrom=${fromDate.format("YYYY-MM-DD")}&dateTo=2030-01-01`
      );

      let products = _.groupBy(
        res.data,
        (i) => `${i.purchaseId}_${i.product._id}_${i.price}${i.status}`
      );

      products = Object.values(products).map((p) => ({
        ...p[0],
        checked: false,
        quantity: p.length,
        allProductPurchaseIds: p.map((pp) => pp._id),
      }));

      setItems(products);
      setIsLoading(false);
    };

    // lazy load on the first
    if (props.visible && items === null && !isLoading) {
      loadData();
    }
  }, [props.visible]);

  const handleItemSelectToggle = (item) => () => {
    setItems((prev) => {
      return prev.map((datum) => {
        return datum._id === item._id || item === "all"
          ? { ...datum, checked: !datum.checked }
          : { ...datum };
      });
    });
  };

  const handleUpdateStatus = async () => {
    const checkedProducts = items.filter((p) => p.checked);
    const groupedByPurchase = _.groupBy(checkedProducts, "purchaseId");
    const updatedItems = Object.entries(groupedByPurchase).map(
      ([purchaseId, products]) => ({
        purchaseId: purchaseId,
        productIds: _.flatten(products.map((p) => p.allProductPurchaseIds)),
      })
    );

    setIsUpdating(true);

    try {
      await axios.patch(`/store/purchases/products/setAsReceived`, {
        items: updatedItems,
      });
      setItems((prev) => {
        const products = [...prev];
        products.forEach((p) => {
          if (p.checked) {
            p.status = "received";
            p.checked = false;
          }
        });

        return products;
      });

      setSnackbarDetails({ key: moment().format(), success: true });
    } catch (error) {
      setSnackbarDetails({ key: moment().format(), success: false });
    }
    setIsUpdateDialogOpen(false);
    setIsUpdating(false);
  };

  if (items === null) {
    return [1, 2, 3, 4, 5].map((i) => (
      <Skeleton
        key={i}
        variant="rounded"
        width={"100%"}
        height={30}
        sx={{ mb: 2 }}
      />
    ));
  }

  if (items.length === 0) {
    return (
      <AbsoluteCenter>
        <Typography variant="h6">לא נרכשו מוצרים</Typography>
      </AbsoluteCenter>
    );
  }

  return (
    <>
      <MerchandiseTable
        title="פריטים"
        products={items}
        defaultSortBy={4}
        isLoading={items === null}
        onItemCheckToggle={handleItemSelectToggle}
        onShowUpdateDialog={() => {
          setIsUpdateDialogOpen((prev) => !prev);
        }}
        playerPage
      />
      <MerchandiseUpdateStatusDialog
        open={isUpdateDialogOpen}
        onClose={() => setIsUpdateDialogOpen(false)}
        onUpdate={handleUpdateStatus}
        isLoading={isUpdating}
      />
      <SnackbarNg
        snackKey={snackbarDetails.key}
        open={snackbarDetails.key !== null}
        onClose={() => setSnackbarDetails({ key: null })}
        severity={snackbarDetails.success ? "success" : "error"}
        message={
          snackbarDetails.success
            ? "הפעולה בוצעה בהצלחה"
            : "ארעה שגיאה. אנא נסו מאוחר יותר"
        }
      />
    </>
  );
}
