import React from "react";
import {
  ListItem as MuiListItem,
  ListItemIcon,
  Divider,
  ListItemText,
  ListItemSecondaryAction,
  ListItemButton,
} from "@mui/material";

export default function ListItem(props) {
  const divider = (
    <Divider
      orientation="vertical"
      sx={{
        border: "2px solid #247BA0",
        borderRadius: 2,
        height: 36,
        my: "2px",
        opacity: 0.5,
      }}
    />
  );
  let itemContent;
  if (props.content) {
    itemContent = (
      <>
        {divider}
        {props.content}
      </>
    );
  } else {
    itemContent = (
      <>
        {divider}
        {props.icon ? (
          <ListItemIcon
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {props.icon}
          </ListItemIcon>
        ) : (
          <div style={{ width: 10 }} />
        )}
        <ListItemText
          primary={props.primaryText}
          secondary={props.secondaryText}
          {...props.listItemTextProps}
        />
        <ListItemSecondaryAction>{props.children}</ListItemSecondaryAction>
      </>
    );
  }

  return (
    <MuiListItem
      dense
      sx={props.sx}
      disablePadding
      divider={props.divider}
      {...props.listItemProps}
    >
      {props.button ? (
        <ListItemButton dense onClick={props.onClick}>
          {itemContent}
        </ListItemButton>
      ) : (
        itemContent
      )}
    </MuiListItem>
  );
}
