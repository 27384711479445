import React, { useMemo } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Link } from "@mui/material";
import { connect } from "react-redux";

import Table from "../../../components/table/Table";
import { displayPrice } from "../../../utils/helpers";
import RevenuesTeams from "./RevenuesTeams";

const generateColumns = (type, superClub) => [
  {
    id: "title",
    label: type === "subscription" ? "מסגרת" : "מוצר",
    width: 400,
  },
  ...(superClub && type === "merchandise"
    ? [{ id: "club", label: "ענף" }]
    : []),
  { id: "totalPaid", label: "סה״כ" },
];

function RevenueCategory({ row, dates, ...props }) {
  const columns = useMemo(
    () => generateColumns(row.type, props.selectedClub.superClub),
    [row.type, props.selectedClub.superClub]
  );
  const renderCell = (columnId, productIncome) => {
    switch (columnId) {
      case "title":
        if (row.type === "subscription") {
          return productIncome.product.title;
        } else {
          return productIncome.otp ? (
            <Link
              component={RouterLink}
              color="primary"
              to={`/clubs/${
                props.clubsHash[productIncome.club].internalName
              }/store/purchases/merchandise?status=received&status=pending&productId=otp&dateFrom=${dates.from.toISOString()}&dateTo=${dates.to.toISOString()}`}
              underline="hover"
            >
              מוצר חד פעמי
            </Link>
          ) : (
            <Link
              component={RouterLink}
              color="primary"
              to={`/clubs/${
                props.clubsHash[productIncome.club].internalName
              }/store/purchases/merchandise?status=received&status=pending&productId=${
                productIncome.product._id
              }&dateFrom=${dates.from.toISOString()}&dateTo=${dates.to.toISOString()}`}
              underline="hover"
            >
              {productIncome.product.title}
            </Link>
          );
        }

      case "club":
        return props.clubsHash[productIncome.club].name;

      case "totalPaid":
        return displayPrice(productIncome.totalPaid);

      default:
        return productIncome[columnId];
    }
  };

  const sortValueCell = (columnId, productIncome) => {
    switch (columnId) {
      case "title":
        return productIncome.otp ? "מוצר חד פעמי" : productIncome.product.title;

      default:
        return productIncome[columnId];
    }
  };

  return (
    <Table
      noHeader
      columns={columns}
      renderCell={renderCell}
      sortValueCell={sortValueCell}
      rows={row.products}
      CollapsableComponent={
        row.type === "subscription" ? RevenuesTeams : undefined
      }
      collapsableComponentProps={{
        clubInternalName: props.clubsHash[row.products[0]?.club]?.internalName,
      }}
      defaultSortBy={1}
      defaultSortDirection="desc"
    />
  );
}

function mapStateToProps(state) {
  return {
    selectedClub: state.auth.selectedClub,
    clubsHash: state.auth.clubsHash,
  };
}

export default connect(mapStateToProps)(RevenueCategory);
