import React from "react";
import { Grid } from "@mui/material";
import ReactGA from "react-ga4";
import moment from "moment";
import { connect } from "react-redux";

import axios from "../../utils/axios";
import PlayerPersonalDetails from "./PlayerPersonalDetails";
import Subscriptions from "../../components/store/Subscriptions/Subscriptions";
import Parents from "./Parents";
import SnackbarNg from "../../components/base/SnackbarNg";
import PlayerComments from "./PlayerComments";
import withMatch from "../../components/routing/withParams";
import PlayerTabs from "./PlayerTabs";

class Player extends React.Component {
  state = {
    player: {},
    isLoading: true,
    isEditing: false,
    isSaving: false,
    isDeleteDialogOpen: false,
    snackbar: { open: false, key: null },
  };

  componentDidMount = async () => {
    ReactGA.send({
      hitType: "pageview",
      page: "/players/:id",
      title: "Player",
    });
    this.loadData();
  };

  loadDataSlient = () => this.loadData(true);

  loadData = async (silent = false) => {
    try {
      this.setState({ isLoading: !silent });
      const response = await axios.get(
        `/clubPlayers/${this.props.params.playerId}?granularity=trialTeam&granularity=clubParent&granularity=avatarUrl&granularity=alerts&granularity=managerComments`
      );
      this.setState({ player: response.data, isLoading: false });
    } catch (error) {
      console.log(error);
    }
  };

  handleAlertsChanged = (newAlerts) => {
    this.setState((prevState) => {
      return {
        player: {
          ...prevState.player,
          alerts: newAlerts,
        },
      };
    });
  };

  showSnackbar = (severity, message) => {
    this.setState({
      snackbar: { severity, message, key: moment().format() },
    });
  };

  render() {
    const player = this.state.player;

    return (
      <>
        {!this.state.isLoading && (
          <div>
            <SnackbarNg
              key={this.state.snackbar.key}
              open={this.state.snackbar.key !== null}
              onClose={() =>
                this.setState({ snackbar: { open: false, key: null } })
              }
              message={this.state.snackbar.message}
              severity={this.state.snackbar.severity}
            />
            <Grid
              container
              spacing={3}
              alignItems="stretch"
              justifyContent="center"
            >
              <Grid item xs={12} sm={6}>
                <PlayerPersonalDetails
                  user={player}
                  userType="player"
                  onUserChanged={() => {
                    this.showSnackbar("success", "פרטי הספורטאי עודכנו בהצלחה");
                    this.loadData(true);
                  }}
                  hasHrPlayerParentAdminPermission={
                    this.props.user.permissions.hrPlayerParentAdmin
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} sx={{ display: "flex" }}>
                <Parents
                  editable
                  player={player}
                  parent={player.parent}
                  hasHrPlayerParentAdminPermission={
                    this.props.user.permissions.hrPlayerParentAdmin
                  }
                  onPlayerChanged={() => {
                    this.showSnackbar("success", "פרטי ההורים עודכנו בהצלחה");
                    this.loadData(true);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <PlayerComments
                  clubPlayer={player}
                  onUpdate={this.loadDataSlient}
                  hasHrPlayerParentAdminPermission={
                    this.props.user.permissions.hrPlayerParentAdmin
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Subscriptions
                  parent={player.parent}
                  player={this.state.player}
                  playerId={this.props.params.playerId}
                  accountId={this.state.player.accountId}
                  onComplete={this.loadDataSlient}
                  activeSubscriptions={this.state.player.activeSubscriptions}
                  inactiveSubscriptions={
                    this.state.player.inactiveSubscriptions
                  }
                  hasRegistrationAdminPermission={
                    this.props.user.permissions.registrationAdmin
                  }
                />
              </Grid>

              <Grid item xs={12}>
                <PlayerTabs
                  player={this.state.player}
                  user={this.props.user}
                  actions={{ handleAlertsChanged: this.handleAlertsChanged }}
                />
              </Grid>
            </Grid>
          </div>
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.auth.user,
  };
}

export default connect(mapStateToProps)(withMatch(Player));
