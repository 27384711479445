import React from "react";
import _ from "lodash";
import { connect } from "react-redux";

import axios from "../../utils/axios";
import FiltersBar from "../../components/filters/FiltersBar";
import TlPaper from "../../components/TlPaper";
import {
  prepareFilters,
  getFiltersFromUrl,
} from "../../components/filters/prepareFilters";
import Table from "../../components/table/Table";
import { exportTable } from "../../lib/tableHelpers";
import withRouter from "../../components/routing/withRouter";
import { displaySeason } from "../../utils/helpers";

class Report extends React.Component {
  state = {
    data: [],
    filters: null,
    isLoading: false,
    filtersStructure: [],
  };

  prepareFilterStructure = () => {
    const filtersStructure = this.props.filtersStructure.map((filter) => {
      switch (filter.preset) {
        case "teams":
          return {
            preset: "teams",
            type: "multiSelect",
            label: filter.label || "קבוצות",
            name: "teams",
            options: [],
            isLoading: true,
            emptyLabel: "כל הקבוצות",
            includeAll: false,
            mustSelectAtLeastOne: true,
          };

        case "season":
          return {
            preset: "season",
            type: "select",
            label: filter.label || "עונה",
            name: "season",
            options: Object.fromEntries(
              this.props.seasons.map((s) => [s._id, displaySeason(s.year)])
            ),
            emptyLabel: "כל העונות",
            includeAll: false,
          };

        default:
          return filter;
      }
    });

    this.setState({ filtersStructure });

    this.props.filtersStructure.forEach((filter) => {
      switch (filter.preset) {
        case "teams":
          return this.loadTeams();

        default:
          break;
      }
    });

    return filtersStructure;
  };

  loadTeams = async () => {
    const res = await axios.get("/teams?status=active");
    let teams = _.sortBy(res.data, "name").map((team) => ({
      key: team._id,
      value: team.name,
    }));

    this.setState((prevState) => {
      const filtersStructure = [...prevState.filtersStructure];
      let filters = { ...prevState.filters };
      filtersStructure.forEach((filter) => {
        if (filter.preset === "teams") {
          filter.options = teams;
          filter.isLoading = false;
          // remove invalid values
          filters = {
            ...filters,
            teams: Object.fromEntries(
              Object.entries(filters.teams).filter(([teamId]) =>
                teams.find((team) => team.key === teamId)
              )
            ),
          };
        }
      });

      if (Object.keys(filters.teams).length === 0) {
        // add defualt value if non is selected
        filters.teams.all = true;
      }

      return { filtersStructure, filters };
    });
  };

  componentDidMount = async () => {
    const filtersStructure = this.prepareFilterStructure();
    const filters = getFiltersFromUrl(
      this.props.location,
      this.props.initialFiltersState,
      filtersStructure
    );

    if (!this.props.initialFiltersState?.season) {
      if (this.props.filtersStructure.some((f) => f.preset === "season")) {
        filters.season = this.props.seasons.find((s) => s.main)?._id || "";
      }
    }
    await this.setState({ filters });

    this.loadData(false);
  };

  loadData = async (updateUrl = true) => {
    let filters = prepareFilters(
      this.state.filters,
      this.state.filtersStructure,
      this.props.prepareFiltersOptionsForServer
    );
    this.setState({ isLoading: true });
    const res = await axios.get(`${this.props.url}${filters.join("&")}`);
    let { data } = res;
    if (this.props.prepareData) {
      data = this.props.prepareData(data);
    }
    this.setState({ data, isLoading: false });
    if (updateUrl && this.props.updateUrl !== false) {
      filters = prepareFilters(this.state.filters, this.state.filtersStructure);
      this.props.navigate(`?${filters.join("&")}`, { replace: true });
    }
  };

  handleFilterChange = (filterName, value) => {
    this.setState({ filters: { ...this.state.filters, [filterName]: value } });
  };

  handleExportTable = () => {
    exportTable(
      this.state.data,
      this.props.columns,
      this.props.renderCellExport,
      "csv",
      this.props.exportFileName
    );
  };

  render() {
    const table = (
      <Table
        columns={this.props.columns}
        renderCell={this.props.renderCell}
        sortValueCell={this.props.sortValueCell}
        rows={this.state.data}
        title={this.props.tableTitle || "תוצאות"}
        isLoading={this.state.isLoading}
        renderKey={this.props.renderKey}
        pagination={this.props.navigation !== false}
        {...(this.props.export !== false
          ? { onExport: this.handleExportTable }
          : {})}
        defaultSortBy={this.props.defaultSortBy || 0}
        defaultSortDirection={this.props.defaultSortDirection || "asc"}
      />
    );

    const content = (
      <>
        <FiltersBar
          filters={this.state.filtersStructure}
          onFilterChanged={this.handleFilterChange}
          values={this.state.filters || this.props.initialFiltersState}
          onSearch={this.loadData}
          isSearching={this.state.isLoading}
          autoSizing={this.props.filtersBarAutoSizing}
        />
        <div style={{ marginTop: 10 }}>
          {this.props.TableContainer ? (
            <this.props.TableContainer>{table}</this.props.TableContainer>
          ) : (
            table
          )}
        </div>
      </>
    );

    return this.props.noPaper ? (
      content
    ) : (
      <TlPaper
        title={this.props.title}
        fullPage
        titleBackground
        leftItem={this.props.leftItem}
      >
        {content}
      </TlPaper>
    );
  }
}

function mapStateToProps(state) {
  return {
    seasons: state.general.seasons,
  };
}

export default connect(mapStateToProps)(withRouter(Report));
