import moment from "moment";
import _ from "lodash";

export const preparePaymentFieldsForSubmit = (cart, paymentForm) => {
  const params = {
    paymentMethod: paymentForm.paymentMethod,
    donationReceipt: paymentForm.donationReceipt,
    amount: cart.totals.totalCost,
    paymentAmount: cart.totals.totalPaymentAmounts,
  };

  switch (paymentForm.paymentMethod) {
    case "creditCard":
      params.last4 = paymentForm.last4;
      params.payments = paymentForm.numberOfPayments;
      params.token = paymentForm.token;
      params.expiration = paymentForm.expiration;
      params.ccResponse = paymentForm.ccResponse;
      params.customCCPaymentsAmount = {
        firstPaymentAmount: paymentForm.firstPaymentAmount,
        basePaymentAmount: paymentForm.basePaymentAmount,
      };
      break;

    case "check":
      params.checks = paymentForm.check.checks.map((check) => {
        return {
          amount: check.amount,
          date: check.date.format("YYYY-MM-DD"),
          checkNumber: check.checkNumber,
          accountNumber: check.accountNumber,
          branch: check.branch,
          bank: check.bank.key,
        };
      });
      break;

    case "wireTransfer":
      params.wireTransfer = _.pick(paymentForm.wireTransfer, [
        "bankBranch",
        "bankAccount",
      ]);

      params.wireTransfer.bank = paymentForm.wireTransfer.bank.key;
      break;

    default:
      break;
  }

  return params;
};

const prepareMerchandise = (
  isGuest,
  cart,
  mainClubPlayerId,
  playerFrom,
  parentForm
) => {
  let guestPlayer;
  if (isGuest) {
    guestPlayer = {
      name: playerFrom.guestName,
      phone: playerFrom.guestPhone,
      parentName: parentForm.guestName,
      parentPhone: parentForm.guestPhone,
    };
  }

  return Object.values(cart.merchandise).map((item) => {
    let clubPlayerId;

    if (item.clubPlayer.isNew) {
      clubPlayerId = mainClubPlayerId;
    } else if (!isGuest) {
      clubPlayerId = item.clubPlayer._id;
    }

    return {
      productId: item.product.otp ? null : item.product._id,
      otp: item.product.otp,
      otpTitle: item.product.otp ? item.product.title : null,
      otpAccountingExportNumber: item.product.otp
        ? item.product.accountingExportNumber
        : null,
      price: item.price,
      quantity: item.quantity,
      optionId: item.optionId,
      clubPlayerId,
      guestPlayer,
    };
  });
};

export const prepareSubscription = (cart, subscriptionForm, clubPlayerId) => {
  const sp = cart.subscription;
  let monthlyPrices;
  if (subscriptionForm.product._id !== "external") {
    monthlyPrices =
      ["fixedPrice", "daily"].indexOf(
        subscriptionForm.product.subscriptionDetails.subscriptionType
      ) > -1
        ? subscriptionForm.monthlyPrices.map((p) => ({
            price: p.price,
            oneCharge: true,
          }))
        : subscriptionForm.monthlyPrices.map((p) => ({
            month: moment(p.month).format("YYYY-MM-DD"),
            price: p.price,
          }));
  }

  return {
    type: sp.product._id === "external" ? "indirect" : "direct",
    subscription: {
      clubPlayerId,
      teamId: subscriptionForm.teamId,
      pricingId: subscriptionForm.pricingId,
      monthlyPrices,
      startDate: subscriptionForm.startDate.format("YYYY-MM-DD"),
      price: sp.paymentAmount,
      discountReason: subscriptionForm.discountReason,
      subProducts: sp.fees.map((feeCartItem) => {
        return {
          productId: feeCartItem.product._id,
          quantity: 1,
          price: feeCartItem.price,
          paymentAmount: feeCartItem.paymentAmount,
        };
      }),
    },
  };
};

export const prepareSubmitDataGuest = (
  playerForm,
  parentForm,
  paymentForm,
  cart
) => ({
  guest: true,
  player: _.pick(playerForm, ["guestName", "guestPhone", "guestTeam"]),
  parent: _.pick(parentForm, ["guestName", "guestPhone", "guestEmail"]),
  merchandise: prepareMerchandise(true, cart, null, playerForm, parentForm),
  payment: preparePaymentFieldsForSubmit(cart, paymentForm),
});

export const prepareSubmitData = (
  purchaseType,
  cart,
  paymentForm,
  subscriptionForm,
  clubParentId,
  clubPlayerId
) => {
  const subscription =
    purchaseType === "subscription"
      ? prepareSubscription(cart, subscriptionForm, clubPlayerId)
      : {};
  const data = {
    guest: false,
    clubParentId,
    ...subscription,
    merchandise: prepareMerchandise(false, cart, clubPlayerId),
    payment: preparePaymentFieldsForSubmit(cart, paymentForm),
  };

  return data;
};

export const preparePlayerForSubmit = (clubParentId, playerForm) => {
  const data = {
    clubParentId,
    ..._.pick(playerForm, [
      "id",
      "gender",
      "class",
      "hasMedicalIssues",
      "medicalIssues",
      "school",
      "firstName",
      "lastName",
      "notIsraeli",
      "passportNumber",
    ]),
    dateOfBirth: moment([
      playerForm.dobYear,
      playerForm.dobMonth - 1,
      playerForm.dobDay,
    ]).format("YYYY-MM-DD"),
  };

  if (playerForm.phone) {
    data.phone = playerForm.phone;
  }

  return data;
};
