import _ from "lodash";
import moment from "moment";

const formatter = new Intl.NumberFormat("he-IL", {
  style: "currency",
  currency: "ILS",
  minimumFractionDigits: 0,
});

export const displayPrice = (price) => {
  return formatter.format(price);
};

export const displayPriceTruncated = (price) => {
  if (Math.abs(price) < 1000) {
    return formatter.format(roundPrice(price));
  } else if (Math.abs(price) < 1000000) {
    return "K" + formatter.format(floorPrice(price / 1000));
  }

  return "M" + formatter.format(floorPrice(price / 1000000));
};

export const displayPriceRound = (price) => {
  return formatter.format(roundPrice(price));
};

export const displayDate = (date) => {
  return moment(date).format("DD/MM/YY");
};

export const displayDateTime = (date) => {
  return moment(date).format("DD/MM/YY HH:mm");
};

export const getAxiosErrorData = (error) => {
  if (error.response && error.response.status === 422) {
    return error.response.data;
  }
  return undefined;
};

export const roundPrice = (price) => {
  return Math.round(price * 100) / 100;
};

export const floorPrice = (price) => {
  if (price > 0) {
    return Math.floor(price * 10) / 10;
  }
  return -Math.floor(-price * 10) / 10;
};

export const DnDreorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const displaySeason = (year) => {
  return `${year - 2000}/${year - 2000 + 1}`;
};

export const sxx = (...args) => {
  let sx = {};

  for (let index = 0; index < args.length; index++) {
    const arg = args[index];

    if (_.isArray(arg)) {
      const optionals = arg;
      for (let index = 0; index < optionals.length; index++) {
        const optional = optionals[index];
        if (optional[0]) {
          sx = { ...sx, ...optional[1] };
        } else if (optional[2]) {
          sx = { ...sx, ...optional[2] };
        }
      }
    } else {
      sx = { ...sx, arg };
    }
  }
  return sx;
};

const prepareCsvField = (field) => {
  if (field?.toString().match(/"|,|\n/)) {
    return `"${field.replace(/"/g, '""')}"`;
  }

  return field;
};

export const generateCsv = (rows, options = {}) => {
  const lines = [];
  if (options.headers) {
    lines.push(options.headers.map((h) => prepareCsvField(h)).join(","));
  }
  for (let index = 0; index < rows.length; index++) {
    const row = rows[index];
    lines.push(row.map((field) => prepareCsvField(field)).join(","));
  }

  return lines.join("\n");
};
