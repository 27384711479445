import React from "react";
import moment from "moment";
import _ from "lodash";
import { connect } from "react-redux";
import ReactGA from "react-ga4";
import { Box } from "@mui/material";

import SnackbarNg from "../../components/base/SnackbarNg";
import holidays from "./holidays.json";
import axios from "../../utils/axios";
import RecurringActionEventDialog from "./RecurringActionEventDialog";
import Cal from "../../components/calendar/Calendar";
import clubConfig from "../../config/clubs";
import CollisionsDialog from "./CollisionsDialog";
import SendNotificationDialog from "./Notifications/SendNotificationDialog";
import withIsXs from "../../components/routing/withIsXs";
import EventDialogWrapper from "./EventDialog/EventDialogWrapper";
import TlPaper from "../../components/TlPaper";

class Calendar extends React.Component {
  currentDate = moment();
  state = {
    eventDialogVisible: false,
    events: [],
    viewEvents: [],
    currentEvent: { recurringEvent: {} },
    currentDate: this.currentDate,
    rangeStart: moment(this.currentDate).startOf("day"),
    rangeEnd: moment(this.currentDate).endOf("day"),
    snackbar: {
      open: false,
    },
    allResources: [],
    selectableResourcesMap: {}, // mapping of db resource
    resourceView: true,
    resourceGroups: null,
    currentView: "day",
    isUpdateDialogOpen: false,
    isSaving: false,
    weekStatus: null,
    isWeekStatusDialogOpen: false,
    isSendNotificationDialogOpen: false,
    weekViewType: "regular",
    teams: [],
    clubCoaches: [],
    selectedClubCoaches: { all: true },
    completedTeams: [],
    teamsByType: [],
    teamsObject: {},
    holidays: { resources: [], noResource: [] },
    isLoading: {},
    selectedEventTypes: { all: true },
    selectedTeams: { all: true },
    // for the resources select "drop down"
    selectedResources: { all: true },
    // selected resources and their sub resource array. for showing day view resources and filtering events.
    selectedResourcesForDisplay: [],
    isCollisionsDialogOpen: false,

    currentHandledEventData: null, // the event being dropped or deleted. used for the send notification flow
    currentHandledEventAction: null, // dropped or deleted event
    unsentNotificationShouldUpdate: false, //unsent notification update flag for the calendar toolbar
    unsentNotificationShowAlert: false, //show alert if new unsent notifications has been added (for recurring events)
    unseenUpdatesShouldUpdate: false, //unseen updates update flag for the calendar toolbar
    weekStatusDialogShouldUpdate: false,
  };

  awayGameResource = {
    _id: "test",
    name: "משחקי חוץ",
    sortId: -1,
  };

  componentDidMount = async () => {
    ReactGA.send({
      hitType: "pageview",
      page: "/calendar",
      title: "Calendar",
    });
    this.setState({ resourceView: !this.props.isXs });

    this.loadWeekStatus();
    await this.loadResources();
    this.loadTeams();
    this.loadClubCoaches();
    this.loadEvents(this.state.rangeStart, this.state.rangeEnd);
  };

  componentDidUpdate = (prevProps) => {
    if (!prevProps.isXs && this.props.isXs) {
      this.setState({ currentView: "day" }, () => {
        this.onResourceViewChanged(false);
      });
    }
  };

  loadHolidays = (start, end) => {
    const startTime = moment(start).startOf("day");
    const endTime = moment(end).endOf("day");

    const events = holidays
      .filter((h) =>
        moment(h.date).isBetween(startTime, endTime, undefined, "[]")
      )
      .reduce(
        (obj, holiday) => {
          const params = {
            title: holiday.name,
            start: moment(holiday.date).startOf("day"),
            end: moment(holiday.date).endOf("day"),
            allDay: true,
            isDraggable: false,
            holiday: true,
          };
          this.state.allResources.forEach((resource) => {
            obj.resources.push({
              ...params,
              viewResource: resource._id,
              cloneType: "holidayResource",
            });
          });

          obj.noResource.push(params);
          return obj;
        },
        { resources: [], noResource: [] }
      );

    this.setState({ holidays: events });

    return events;
  };

  // viewType - day/week
  generateViewEvents = (view, events, options = {}) => {
    let filteredEvents;
    switch (view) {
      case "day":
        filteredEvents = options.resourceView
          ? [...events]
          : events.filter((e) => !e.cloneType);
        break;

      case "week":
        filteredEvents = events.filter((e) => !e.cloneType);

        break;

      default:
        break;
    }

    const selectedEventTypes =
      options.selectedEventTypes || this.state.selectedEventTypes;

    const selectedClubCoaches =
      options.selectedClubCoaches || this.state.selectedClubCoaches;

    // this is needed for deleted coaches => coach is not in selectedCoaches list even though all coaches are selected
    // also needed to show all coaches before selectedClubCoaches.all is replaced with the actual coaches
    const allCoachesSelected =
      selectedClubCoaches.all ||
      Object.keys(selectedClubCoaches).length === this.state.clubCoaches.length;

    const selectedTeams = options.selectedTeams || this.state.selectedTeams;
    const selectedResources = (
      options.selectedResources || this.state.selectedResourcesForDisplay
    ).reduce((h, i) => {
      h[i._id] = true;
      return h;
    }, {});

    // same as allCoachesSelected - for non-active teams
    const allTeamsSelected =
      selectedTeams.all ||
      Object.keys(selectedTeams).length === this.state.teams.length;

    filteredEvents = filteredEvents.filter((e) => {
      const eventTypeFilter =
        selectedEventTypes.all ||
        selectedEventTypes[e.type] ||
        (e.type === "game" &&
          e.gameDetails.gameType === "away" &&
          selectedEventTypes.awayGame) ||
        (e.type === "game" &&
          e.gameDetails.gameType === "home" &&
          selectedEventTypes.homeGame);

      const team = this.state.teamsObject[e.team];
      const clubCoachId = team ? team.clubCoach : null;
      // even if coach has been deleted the event will be included if allCoachesSelected is true
      const clubCoachFilter =
        allCoachesSelected || selectedClubCoaches[clubCoachId];

      // event will be visible even if the team is not active (not in the teams list) and allTeamsSelected is true
      const teamFilter = allTeamsSelected || selectedTeams[e.team];
      const resourceFilter =
        selectedResources.all ||
        e.resources.some((r) => selectedResources[r]) ||
        (e.resources.length === 0 && selectedResources.test);

      return eventTypeFilter && clubCoachFilter && teamFilter && resourceFilter;
    });

    const holidayEvents = options.holidays || this.state.holidays;
    let holidays = [];
    if (view === "day") {
      holidays = options.resourceView
        ? holidayEvents.resources
        : holidayEvents.noResource;
    } else if (this.state.weekViewType !== "table") {
      holidays = holidayEvents.noResource;
    }

    return [...filteredEvents, ...holidays];
  };

  onResourceViewChanged = (isResourceView) => {
    this.setState((prevState) => {
      return {
        resourceView: isResourceView,
        viewEvents: this.generateViewEvents("day", prevState.events, {
          resourceView: isResourceView,
        }),
      };
    });
  };

  handleWeekViewTypeChanged = (view) => {
    //generateViewEvents needs the updated this.state.weekViewType
    this.setState({ weekViewType: view }, () => {
      this.setState((prevState) => ({
        viewEvents: this.generateViewEvents(
          prevState.currentView,
          prevState.events,
          { resourceView: prevState.resourceView }
        ),
      }));
    });
  };

  resourceDescription = (event) => {
    let resourceDesc;
    if (event.resources && event.resources[0]) {
      const resourceIds = event.resources;
      const resources = resourceIds.map(
        (resourceId) => this.state.selectableResourcesMap[resourceId]
      );
      if (!resources[0].parent) {
        // full court
        resourceDesc = `${resources[0].name}`;
      } else {
        const parent = this.state.selectableResourcesMap[resources[0].parent];
        if (parent.subResources.length === 2) {
          resourceDesc = `${parent.name} (\u00BD)`;
        } else if (resources.length === 1) {
          resourceDesc = `${parent.name} (\u2153)`;
        } else {
          resourceDesc = `${parent.name} (\u2154)`;
        }
      }
    } else if (event.location) {
      // resourceDesc = event.location;
      resourceDesc = "";
    }

    return resourceDesc;
  };

  prepareEventForDisplay = (event) => {
    event.id = event._id;
    event.start = moment(event.start).toDate();
    event.started = moment().isAfter(event.start);
    event.end = moment(event.end).toDate();
    event.isDraggable = this.isDraggable(event);

    event.resourceDescription = this.resourceDescription(event);

    event.isRecurringEvent = event.recurringEventId;
    if (event.isRecurringEvent) {
      event.recurringEvent.startDate = moment(
        event.recurringEvent.startDate,
        "YYYY-MM-DD"
      );
      event.recurringEvent.endDate = moment(
        event.recurringEvent.endDate,
        "YYYY-MM-DD"
      );
    }

    if (event.resources.length === 1) {
      const firstResource =
        this.state.selectableResourcesMap[event.resources[0]];

      // if parent with children
      if (firstResource.subResources.length > 0) {
        event.clones = [];
        for (
          let index = 0;
          index < firstResource.subResources.length;
          index++
        ) {
          const subResourceId = firstResource.subResources[index];
          const eventClone = { ...event };
          eventClone.viewResource = subResourceId;
          eventClone.cloneType = "placeholder";
          eventClone.id = eventClone.id + index.toString();
          eventClone.parent = event;
          event.clones.push(eventClone);
        }
      }
      event.viewResource = firstResource._id;
    } else if (event.resources.length > 1) {
      event.clones = [];
      for (let index = 0; index < event.resources.length; index++) {
        const resourceId = event.resources[index];
        const eventClone = { ...event };
        eventClone.viewResource = resourceId;
        eventClone.cloneType = "multiResources";
        eventClone.id = eventClone.id + index.toString();
        eventClone.parent = event;
        event.clones.push(eventClone);
      }
    } else {
      // no resource -> away game
      event.viewResource = this.awayGameResource._id;
    }

    return event;
  };

  onEventSelected = async (event) => {
    if (event.cloneType !== "placeholder" && !event.holiday) {
      const currentEvent =
        event.cloneType === "multiResources"
          ? { ...event.parent }
          : { ...event };

      currentEvent.id = `${currentEvent._id}_${new Date()}`;
      this.setState({
        currentEvent,
        eventDialogVisible: true,
      });
    }
  };

  handleUpdateEvent = async (
    event,
    updateType,
    recurringUpdateType,
    force = false,
    source // dialog / dnd
  ) => {
    delete event.clones;
    let response;
    this.setState({ isSaving: true });
    let data, updatedEvent;
    switch (updateType) {
      case "recurring":
        data = this.prepareRecurringEventForServer(event);
        data.updateType = recurringUpdateType;
        try {
          response = await axios.put(
            `/recurringEvents/${event.recurringEventId}`,
            data
          );
          this.setState({ unsentNotificationShowAlert: true });
        } catch (error) {
          this.handleServerError(error, event);
          this.setState({ isSaving: false });
          return;
        }

        break;

      case "single":
        try {
          response = await axios.put(`/events/${event._id}`, {
            ...event,
            force,
          });

          updatedEvent = this.prepareEventForDisplay(response.data);

          this.setState((prevState) => {
            let events = prevState.events;

            events = events.filter((e) => {
              // using event._id since updatedEvent can be a new event (change of game type or team)
              return e._id !== event._id; // cloned events have the same _id as the parent. only id is different
            });

            events.push(updatedEvent, ...(updatedEvent.clones || []));

            return {
              events,
              viewEvents: this.generateViewEvents(
                prevState.currentView,
                events,
                { resourceView: prevState.resourceView }
              ),
              eventDialogVisible: false,
            };
          });
        } catch (error) {
          this.setState({ isSaving: false });
          this.handleServerError(error, event, "update", source);
          return;
        }

        break;

      default:
        throw new Error("uknown update type");
    }

    await this.loadEvents(this.state.rangeStart, this.state.rangeEnd, true);
    this.updateToolbar();
    this.setState({ eventDialogVisible: false, isSaving: false });

    if (
      response.data.notificationsErrors &&
      response.data.notificationsErrors.length > 0
    ) {
      this.showSnackBar(
        event,
        true,
        "האירוע עודכן בהצלחה אך לא ניתן לשלוח עדכון למאמן"
      );
    } else {
      this.showSnackBar(event, false, "האירוע עודכן בהצלחה");
    }
  };

  handleServerError = (error, event, actionType, source) => {
    let collision = false;
    if (error.response && error.response.status === 422) {
      const errors = error.response.data.errors;
      if (errors.collisions) {
        this.setState({
          isCollisionsDialogOpen: true,
          collisions: errors.collisions,
          collisionSource: source,
          collisionCallback: {
            event,
            actionType,
          },
        });
        collision = true;
      }
    } else {
      if (!collision) {
        this.showSnackBar(event, true, "אירעה שגיאה");
      }
    }
  };

  prepareRecurringEventForServer = (event) => {
    // if last or first event were dnd and now out of the recurring event start and end dates we chould change the dates
    // the following (commented) start date doesnt work in case we are on the first event or the series and only move the start date 1 week later
    // const startDate = moment(event.recurringEvent.startDate).isBefore(
    //   event.start
    // )
    //   ? event.recurringEvent.startDate
    //   : event.start;

    const endDate = moment(event.recurringEvent.endDate).isAfter(event.end)
      ? event.recurringEvent.endDate
      : event.end;

    const data = {
      ...event,
      ...event.recurringEvent,
      title: event.title, //both event and recurringEvent has title. event is the updated one
      resources: event.resources, //both event and recurringEvent has resources. event is the updated one
      eventId: event._id,
      startDate: moment(event.recurringEvent.startDate).format("YYYY-MM-DD"),
      endDate: moment(endDate).format("YYYY-MM-DD"),
    };
    delete data.recurringEvent;

    return data;
  };

  updateToolbar = () => {
    this.setState({
      unsentNotificationShouldUpdate: true,
      unseenUpdatesShouldUpdate: true,
      weekStatusDialogShouldUpdate: true,
    });
  };

  handleCreateEvent = async (event, force = false) => {
    let response;

    try {
      this.setState({ isSaving: true });
      if (event.isRecurringEvent) {
        response = await axios.post(
          "/recurringEvents",
          this.prepareRecurringEventForServer(event)
        );
      } else {
        response = await axios.post("/events", { ...event, force });
        if (force) {
          this.loadEvents(this.state.rangeStart, this.state.rangeEnd, true);
        }
      }
      this.setState({ isSaving: false });
    } catch (error) {
      this.setState({ isSaving: false });
      this.handleServerError(error, event, "create", "dialog");
      return;
    }

    if (event.isRecurringEvent) {
      this.setState({
        eventDialogVisible: false,
        unsentNotificationShowAlert: true,
      });
      this.loadEvents(this.state.rangeStart, this.state.rangeEnd, true);
      this.updateToolbar();
      this.showSnackBar(event, false, "האירוע נוצר בהצלחה");

      return;
    }
    const viewEvent = this.prepareEventForDisplay(response.data);

    this.setState((prevState) => {
      const events = [...prevState.events];
      events.push(viewEvent, ...(viewEvent.clones || []));

      const viewEvents = this.generateViewEvents(
        prevState.currentView,
        events,
        { resourceView: prevState.resourceView }
      );

      return { events, viewEvents, eventDialogVisible: false };
    });

    this.updateToolbar();
    if (
      response.data.notificationsErrors &&
      response.data.notificationsErrors.length > 0
    ) {
      this.showSnackBar(
        event,
        true,
        "האירוע נוצר בהצלחה אך לא ניתן לשלוח עדכון למאמן"
      );
    } else {
      this.showSnackBar(event, false, "האירוע נוצר בהצלחה");
    }
  };

  deleteEvent = async (deletedEvent, deleteType, sendNotification) => {
    const data = deleteType ? { deleteType } : {};
    data.sendNotification = sendNotification;
    if (deletedEvent.isRecurringEvent) {
      data.eventId = deletedEvent._id;
    }
    this.setState({ isSaving: true });
    const url = deletedEvent.isRecurringEvent
      ? `/recurringEvents/${deletedEvent.recurringEventId}`
      : `/events/${deletedEvent._id}`;
    await axios.delete(url, { data });

    this.setState((prevState) => {
      let events = prevState.events;

      events = events.filter((event) => {
        return event._id !== deletedEvent._id; // cloned events have the same _id as the parent. only id is different
      });

      return {
        isSaving: false,
        events,
        viewEvents: this.generateViewEvents(prevState.currentView, events, {
          resourceView: prevState.resourceView,
        }),
        eventDialogVisible: false,
      };
    });

    if (deletedEvent.hasCollisions) {
      await this.loadEvents(this.state.rangeStart, this.state.rangeEnd, true);
    }
    this.updateToolbar();
    this.showSnackBar(deletedEvent, false, "האירוע נמחק בהצלחה");
  };

  loadWeekStatus = async () => {
    try {
      const response = await axios.get(
        `/weekStatuses/current?localDate=${moment().format("YYYY-MM-DD")}`
      );
      const weekStatus = response.data;

      weekStatus.currentWeek.startDate = moment(
        weekStatus.currentWeek.startDate
      ).startOf("day");
      weekStatus.nextWeek.startDate = moment(
        weekStatus.nextWeek.startDate
      ).startOf("day");

      this.setState({
        weekStatus,
      });
    } catch (error) {
      console.log(error);
    }
  };

  loadResources = async () => {
    await this.setState((prevState) => {
      return { isLoading: { ...prevState.isLoading, resources: true } };
    });
    const response = await axios.get(`/resources`);

    let resources = response.data;
    resources = _.sortBy(resources, (r) => r.sortId);
    resources = [this.awayGameResource, ...resources];

    const resourcesMap = {};
    response.data.forEach((r) => (resourcesMap[r._id] = r));

    let selectedResources = resources
      .filter((r) => !r.parent)
      .reduce((h, item) => {
        h[item._id] = true;
        return h;
      }, {});

    this.setState((prevState) => {
      return {
        allResources: resources,
        selectedResources,
        selectedResourcesForDisplay: [...resources],
        selectableResourcesMap: resourcesMap,
        isLoading: { ...prevState.isLoading, resources: false },
      };
    }, this.loadResourceGroups);

    return resources;
  };

  loadResourceGroups = async () => {
    const response = await axios.get(`/calendar/resourceGroups`);
    let resourceGroups = response.data;
    resourceGroups = _.orderBy(
      resourceGroups,
      ["default", "name"],
      ["desc", "asc"]
    );
    resourceGroups.forEach((group) => {
      group.viewableResources = group.resources.map(
        (r) => this.state.selectableResourcesMap[r]
      );
      group.viewableResources = [
        this.awayGameResource,
        ...group.viewableResources,
      ];
    });

    this.setState({ resourceGroups });
  };

  loadTeams = async () => {
    const res = await axios.get(`/teams?status=active&status=completed`);
    const completedTeams = res.data.filter((t) => t.status === "completed");
    const teams = _.sortBy(
      res.data.filter((t) => t.status === "active"),
      "name"
    );

    const teamsByType = _.groupBy(teams, "teamType");
    const teamsObject = _.keyBy(teams, "_id");
    this.setState({ teams, teamsObject, teamsByType, completedTeams });
  };

  loadClubCoaches = async () => {
    const res = await axios.get(`/clubCoaches`);
    const clubCoaches = _.sortBy(res.data, "name");
    this.setState({ clubCoaches });
  };

  handleSettingsUpdate = async () => {
    const resources = await this.loadResources();

    this.setState((prevState) => {
      return {
        viewEvents: this.generateViewEvents(
          prevState.currentView,
          prevState.events,
          {
            resourceView: prevState.resourceView,
            selectedResources: resources,
          }
        ),
      };
    });
  };

  loadEvents = async (start, end, silent = false) => {
    // remove holidays. without it sometimes
    this.setState((prevState) => ({
      viewEvents: prevState.viewEvents.filter((e) => !e.holiday),
    }));

    const startTime = moment(start).startOf("day").format("YYYY-MM-DD");
    const endTime = moment(end).endOf("day").format("YYYY-MM-DD");

    !silent &&
      this.setState((prevState) => {
        return { isLoading: { ...prevState.isLoading, events: true } };
      });
    const response = await axios.get(
      `/events?from=${startTime}&to=${endTime}&showCollisions=true&granularity=recurringEvent`
    );

    const holidays = await this.loadHolidays(startTime, endTime);

    this.setState((prevState) => {
      let hasCollisions = false;
      const events = [];
      response.data.forEach((event) => {
        const preparedEvent = this.prepareEventForDisplay(event);
        if (event.hasCollisions) {
          hasCollisions = true;
        }
        events.push(preparedEvent, ...(preparedEvent.clones || []));
      });

      const viewEvents = this.generateViewEvents(
        prevState.currentView,
        events,
        { resourceView: prevState.resourceView, holidays }
      );

      return {
        events,
        viewEvents,
        hasCollisions,
        isLoading: { ...prevState.isLoading, events: false },
      };
    });
  };

  createNewEvent = (start, end, resourceId) => {
    const awayGame = resourceId === this.awayGameResource._id;

    // set default event duration to 90 min when clicking on the calendar (or selecting 15 miuntes)
    if (moment(end).diff(start, "minutes") === 15) {
      end = moment(start).add(90, "minutes");
    }

    const endDate = moment([moment().year(), 5, 30]);
    if (endDate.isBefore(moment())) {
      endDate.add(1, "year");
    }

    this.setState({
      currentEvent: {
        id: new Date(),
        start,
        end,
        type: awayGame ? "game" : "practice",
        team: "",
        gameDetails: awayGame
          ? {
              gameType: "away",
              opponent: "",
              location: "",
              busType: "",
              transportation: true,
            }
          : {},
        resources: resourceId && !awayGame ? [resourceId] : [],
        isRecurringEvent: false,
        recurringEvent: {
          startDate: moment(start),
          endDate,
          startTime: moment(start).format("HH:mm"),
          endTime: moment(end).format("HH:mm"),
          dayOfWeek: moment(start).day(),
        },
      },
      eventDialogVisible: true,
    });
  };

  isLoading = () => {
    Object.values(this.state.isLoading).some((loading) => loading);
  };

  showSnackBar = (event, isError, message) => {
    this.setState({
      snackbar: {
        open: true,
        severity: isError ? "error" : "success",
        message,
        snackKey: event ? event.id : moment().format(),
      },
    });
  };

  sendNotificationAvailable = (event) => {
    const eventStartDate = moment(event.start);
    // no weekStatus (load issue?) or past event
    if (!this.state.weekStatus || eventStartDate.isBefore(moment())) {
      return false;
    }

    if (event.type === "other" && !event.team) {
      return false;
    }

    return [
      this.state.weekStatus.currentWeek,
      this.state.weekStatus.nextWeek,
    ].some(
      (weekStatus) =>
        weekStatus.status === "ready" &&
        moment(eventStartDate).isBetween(
          weekStatus.startDate,
          moment(weekStatus.startDate).endOf("week")
        )
    );
  };

  handleEventDrop = (info) => {
    const { event, start, end, resourceId } = info;
    // if not same day
    if (moment(start).endOf("day").isBefore(end)) {
      this.showSnackBar(event, true, "האירוע צריך להתחיל ולהסתיים באותו היום");
      return;
    }

    // trying to drag away game to 'real' resource
    if (
      event.type === "game" &&
      event.gameDetails.gameType === "away" &&
      resourceId &&
      resourceId !== this.awayGameResource._id
    ) {
      this.showSnackBar(event, true, "לא ניתן לגרור משחק חוץ למגרש אחר");
      return;
    }

    // trying to drag non away game to away resource
    if (
      !(event.gameDetails && event.gameDetails.gameType === "away") &&
      event.resources[0] !== this.awayGameResource._id &&
      resourceId &&
      resourceId === this.awayGameResource._id
    ) {
      this.showSnackBar(event, true, "לא ניתן לשנות אירוע זה למשחק חוץ");
      return;
    }

    const newEvent =
      event.cloneType === "multiResources" ? { ...event.parent } : { ...event };
    newEvent.start = start;
    newEvent.end = end;
    if (resourceId) {
      newEvent.resources = [resourceId];
    }

    if (event.recurringEventId) {
      newEvent.recurringEvent.startTime = moment(start).format("HH:mm");
      newEvent.recurringEvent.endTime = moment(end).format("HH:mm");
      newEvent.recurringEvent.dayOfWeek = moment(start).day();
      this.setState({ isUpdateDialogOpen: true, newDroppedEvent: newEvent });
      return;
    }

    // new event is good enough since event drop is always in the same
    if (this.sendNotificationAvailable(newEvent)) {
      this.setState({
        isSendNotificationDialogOpen: true,
        currentHandledEventData: { event: newEvent },
        currentHandledEventAction: "drop",
      });
    } else {
      this.handleEventChanges({ event: newEvent }, "drop");
    }
  };

  onCalendarChange = (view, date, rangeStart, rangeEnd) => {
    this.setState({
      currentDate: date,
      currentView: view,
      rangeStart,
      rangeEnd,
    });
    this.loadEvents(rangeStart, rangeEnd);
  };

  isDraggable = (event) => {
    return (
      this.props.user.permissions.calendarAdmin &&
      event.cloneType !== "placeholder" &&
      moment(event.start).startOf("day").isSameOrAfter(moment().startOf("day"))
    );
  };

  handleSelectSlot = (slot) => {
    if (this.state.eventDialogVisible) {
      // if you click on the bottom edge of a new event the event dialog appears, and than if you press any key somethimes this event gets fired. this condition prevents it.
      return;
    }
    if (moment(slot.end).diff(slot.start, "hours") > 20) {
      return; // full day
    }

    if (
      moment(slot.start).add(7, "days").isSameOrAfter(moment().startOf("day"))
    ) {
      this.createNewEvent(slot.start, slot.end, slot.resourceId);
    } else {
      this.showSnackBar(
        { _id: new Date().getTime() },
        true,
        "לא ניתן ליצור אירוע בעבר"
      );
    }
  };

  handleIgnoreCollisions = async () => {
    this.setState({ isCollisionsDialogOpen: false });
    switch (this.state.collisionCallback.actionType) {
      case "create":
        this.handleCreateEvent(this.state.collisionCallback.event, true);
        break;

      case "update":
        this.handleUpdateEvent(
          this.state.collisionCallback.event,
          "single",
          null,
          true
        );
        break;

      default:
        break;
    }
  };

  handleDeleteEvent = (deletedEvent, deleteType) => {
    if (
      (!deletedEvent.isRecurringEvent || deleteType === "single") &&
      this.sendNotificationAvailable(deletedEvent)
    ) {
      this.setState({
        isSendNotificationDialogOpen: true,
        currentHandledEventData: { event: deletedEvent, deleteType },
        currentHandledEventAction: "delete",
      });
    } else {
      if (deletedEvent.isRecurringEvent) {
        this.setState({ unsentNotificationShowAlert: true });
      }
      this.handleEventChanges({ event: deletedEvent, deleteType }, "delete");
    }
  };

  handleSendNotificationDialogCompleted = (sendNotification) => {
    this.setState({
      isSendNotificationDialogOpen: false,
      eventDialogVisible: false,
    });

    this.handleEventChanges(
      this.state.currentHandledEventData,
      this.state.currentHandledEventAction,
      sendNotification
    );
  };

  // created for handling actions after send notification dialog is showed
  handleEventChanges = (data, action, sendNotification = false) => {
    switch (action) {
      case "drop":
        this.handleUpdateEvent(
          { ...data.event, sendNotification },
          "single",
          null,
          false,
          "dnd"
        );
        break;

      case "delete":
        this.deleteEvent(data.event, data.deleteType, sendNotification);
        break;

      case "recurring":
        this.handleUpdateEvent(
          { ...data.event, sendNotification },
          data.updateType,
          data.recurringUpdateType,
          data.force,
          data.source
        );

        break;

      default:
        console.error(
          `unknown action: ${this.state.currentHandledEventAction}`
        );
        break;
    }
  };

  // through event dialog
  handleSendNotification = async (event) => {
    this.setState({ isSaving: true });

    try {
      await axios.post(`/events/${event._id}/sendNotification`);
      this.setState({
        isSaving: false,
        eventDialogVisible: false,
      });
      this.showSnackBar(event, false, "העדכון נשלח בהצלחה");
      await this.loadEvents(this.state.rangeStart, this.state.rangeEnd, true);
      this.updateToolbar();
    } catch (error) {
      if (
        error.response &&
        error.response.status === 422 &&
        error.response.data.reason === "sendFailed"
      ) {
        this.showSnackBar(event, true, "לא ניתן לשלוח עדכון למאמן");
      } else {
        this.showSnackBar(event, true, "ארעה שגיאה, אנא נסו מאוחר יותר");
      }
    } finally {
      this.setState({
        isSaving: false,
        eventDialogVisible: false,
      });
    }
  };

  // through unsentNotificationsDialog
  handleNotificationsSent = () => {
    this.loadEvents(this.state.rangeStart, this.state.rangeEnd);
    this.setState({ unseenUpdatesShouldUpdate: true });
  };

  // recurring events update type dialog completed
  handleRecurringEventChanged = (actionType) => {
    let eventData;
    if (actionType === "single") {
      eventData = {
        event: this.state.newDroppedEvent,
        updateType: "single",
        recurringUpdateType: null,
        force: false,
        source: "dialog",
      };
    } else {
      eventData = {
        event: this.state.newDroppedEvent,
        updateType: "recurring",
        recurringUpdateType: actionType,
      };
    }

    //send notification only if updating single event, otherwise changed events in open weeks will show up in the toolbar
    if (
      actionType === "single" &&
      this.sendNotificationAvailable(this.state.newDroppedEvent)
    ) {
      this.setState({
        isUpdateDialogOpen: false,
        isSendNotificationDialogOpen: true,
        currentHandledEventData: eventData,
        currentHandledEventAction: "recurring",
      });
    } else {
      this.setState({
        isUpdateDialogOpen: false,
        unsentNotificationShowAlert: true,
      });
      this.handleEventChanges(eventData, "recurring");
    }
  };

  handleEventTypesChanged = (newType) => {
    this.setState((prevState) => {
      return {
        events: prevState.events,
        viewEvents: this.generateViewEvents(
          prevState.currentView,
          prevState.events,
          {
            resourceView: prevState.resourceView,
            selectedEventTypes: newType,
          }
        ),
        selectedEventTypes: newType,
      };
    });
  };

  handleClubCoachesChanged = (newClubCoaches) => {
    this.setState((prevState) => {
      return {
        events: prevState.events,
        viewEvents: this.generateViewEvents(
          prevState.currentView,
          prevState.events,
          {
            resourceView: prevState.resourceView,
            selectedClubCoaches: newClubCoaches,
          }
        ),
        selectedClubCoaches: newClubCoaches,
      };
    });
  };

  handleSelectedTeamChanged = (newTeams) => {
    this.setState((prevState) => {
      return {
        events: prevState.events,
        viewEvents: this.generateViewEvents(
          prevState.currentView,
          prevState.events,
          {
            resourceView: prevState.resourceView,
            selectedTeams: newTeams,
          }
        ),
        selectedTeams: newTeams,
      };
    });
  };

  handleSelectedResourceChanged = (newResources) => {
    this.setState((prevState) => {
      let selectedResourcesForDisplay = prevState.allResources.filter(
        (r) => newResources[r._id] || newResources[r.parent]
      );

      return {
        viewEvents: this.generateViewEvents(
          prevState.currentView,
          prevState.events,
          {
            resourceView: prevState.resourceView,
            // include sub-resources for filtring purposes
            selectedResources: selectedResourcesForDisplay,
          }
        ),
        // only parents | hash {resourceId: true}
        selectedResources: newResources,
        // parents + sub | entire resource
        selectedResourcesForDisplay,
      };
    });
  };

  render() {
    const admin = this.props.user.permissions.calendarAdmin;

    let currentResources = null;
    if (this.state.currentView === "day" && this.state.resourceView) {
      currentResources = this.state.selectedResourcesForDisplay;
    }

    return (
      <TlPaper
        bodySx={{
          p: { xs: 0, sm: 1 },
          pl: { xs: 0, sm: 1 },
          pr: { xs: 0, sm: 1 },
          m: 0,
        }}
        paperProps={{ elevation: this.props.isXs ? 0 : 1 }}
      >
        <Box width="100%">
          <Cal
            onEventSelected={this.onEventSelected}
            currentView={this.state.currentView}
            currentDate={this.state.currentDate}
            isLoading={this.state.isLoading && this.state.isLoading.events}
            dateRange={{
              start: this.state.rangeStart,
              end: this.state.rangeEnd,
            }}
            isDraggable={this.isDraggable}
            events={this.state.viewEvents}
            onCalendarChange={this.onCalendarChange}
            resourceView={this.state.resourceView}
            resources={currentResources}
            onSelectSlot={this.handleSelectSlot}
            onEventDrop={this.handleEventDrop}
            onEventResize={this.handleEventDrop}
            onResourceViewChanged={this.onResourceViewChanged}
            onWeekViewTypeChanged={this.handleWeekViewTypeChanged}
            weekViewType={this.state.weekViewType}
            teams={this.state.teamsObject}
            weekStatusProps={{
              resourceDescription: this.resourceDescription,
              weeks: this.state.weekStatus,
              onCalendarChange: this.onCalendarChange,
              shouldUpdate: this.state.weekStatusDialogShouldUpdate,
              onWeekApprove: this.loadWeekStatus,
              onUpdateCompleted: () =>
                this.setState({ weekStatusDialogShouldUpdate: false }),
            }}
            unsentNotificationsProps={{
              update: this.state.unsentNotificationShouldUpdate,
              onComplete: () => {
                this.setState({
                  unsentNotificationShouldUpdate: false,
                  unsentNotificationShowAlert: false,
                });
              },
              showAlert: this.state.unsentNotificationShowAlert,
              onSent: this.handleNotificationsSent,
            }}
            unreadUpdatesProps={{
              update: this.state.unseenUpdatesShouldUpdate,
              onComplete: () =>
                this.setState({ unseenUpdatesShouldUpdate: false }),
            }}
            calendarSettingsProps={{
              resources: this.state.allResources,
              onUpdate: this.handleSettingsUpdate,
            }}
            admin={admin}
            eventTypesProps={{
              onEventTypesChanged: this.handleEventTypesChanged,
              selectedEventTypes: this.state.selectedEventTypes,
            }}
            clubCoachesProps={{
              clubCoaches: this.state.clubCoaches,
              selectedClubCoaches: this.state.selectedClubCoaches,
              onClubCoachesChanged: this.handleClubCoachesChanged,
            }}
            teamsProps={{
              teams: this.state.teams,
              selectedTeams: this.state.selectedTeams,
              onSelectedTeamChanged: this.handleSelectedTeamChanged,
            }}
            resourcesProps={{
              resources: this.state.allResources,
              resourceGroups: this.state.resourceGroups,
              selectedResources: this.state.selectedResources,
              onResourceChanged: this.handleSelectedResourceChanged,
            }}
          />

          <EventDialogWrapper
            isVisible={this.state.eventDialogVisible}
            onClose={() => this.setState({ eventDialogVisible: false })}
            onCreate={this.handleCreateEvent}
            onUpdate={this.handleUpdateEvent}
            event={this.state.currentEvent}
            resources={this.state.selectableResourcesMap}
            onDelete={this.handleDeleteEvent}
            isSaving={this.state.isSaving}
            teams={this.state.teams}
            completedTeams={this.state.completedTeams}
            teamsByType={this.state.teamsByType}
            clubCategory={
              clubConfig[this.props.selectedClub.internalName].category
            }
            weekStatus={this.state.weekStatus}
            onSendNotification={this.handleSendNotification}
            sendNotificationAvailable={this.sendNotificationAvailable}
            isXs={this.props.isXs}
            admin={admin}
          />
          <SnackbarNg
            {...this.state.snackbar}
            onClose={() => this.setState({ snackbar: { open: false } })}
          />
          <RecurringActionEventDialog
            action="update"
            isOpen={this.state.isUpdateDialogOpen}
            onClose={() => this.setState({ isUpdateDialogOpen: false })}
            onAction={this.handleRecurringEventChanged}
          />

          <SendNotificationDialog
            open={this.state.isSendNotificationDialogOpen}
            onClose={() =>
              this.setState({ isSendNotificationDialogOpen: false })
            }
            onComplete={this.handleSendNotificationDialogCompleted}
          />

          <CollisionsDialog
            open={this.state.isCollisionsDialogOpen}
            onClose={() => this.setState({ isCollisionsDialogOpen: false })}
            collisions={this.state.collisions}
            onComplete={this.handleIgnoreCollisions}
            source={this.state.collisionSource}
          />
        </Box>
      </TlPaper>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedClub: state.auth.selectedClub,
    user: state.auth.user,
  };
}

export default connect(mapStateToProps)(withIsXs(Calendar));
