import React, { useState } from "react";
import { Grid } from "@mui/material";
import RestoreIcon from "@mui/icons-material/Restore";

import ButtonSx from "../../components/base/ButtonSx";

const styles = {
  percentButton: {
    minWidth: "30px",
  },
};

function PercentItem({ percent, restoreItem, onClick, disabled, selected }) {
  return (
    <Grid item xs={3} sm={2}>
      <ButtonSx
        fullWidth
        disabled={disabled}
        variant={selected ? "contained" : "outlined"}
        sx={{ ...styles.percentButton }}
        onClick={onClick}
      >
        {restoreItem ? <RestoreIcon /> : `${percent}%`}
      </ButtonSx>
    </Grid>
  );
}

export default function DiscountPercent(props) {
  const [selectedPercent, setSelectedPercent] = useState(null);
  const handlePercentClicked = (percent) => () => {
    setSelectedPercent(percent);
    props.onPercentChanged(percent);
  };

  return (
    <Grid item container justifyContent="flex-start" spacing={1}>
      <PercentItem percent={0} restoreItem onClick={handlePercentClicked(0)} />
      {[5, 10, 15, 20, 25, 30, 40, 50, 60, 70, 80].map((percent) => (
        <PercentItem
          key={percent}
          percent={percent}
          onClick={handlePercentClicked(percent)}
          selected={selectedPercent === percent}
          disabled={props.disabled}
        />
      ))}
    </Grid>
  );
}
