import React from "react";
import Chart from "react-apexcharts";

import CountItem from "./CountItem";
import { useMediaQuery } from "@mui/material";

export default function RegisteredPlayersCountItem(props) {
  const isLgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"), {
    noSsr: true,
  });

  const chart = isLgUp && (
    <Chart
      options={{
        chart: { type: "pie" },
        labels: ["ליגה", "בית ספר", "מחנה"],
        legend: { show: false },
        dataLabels: { enabled: false },
      }}
      height={100}
      series={props.distribution}
      type="pie"
    />
  );

  return (
    <CountItem
      isLoading={props.isLoading}
      title="ספורטאים רשומים"
      count={props.count}
      primary
      color="color1"
      linkTo="players?status=active"
      superClub={props.superClub}
      chart={chart}
    />
  );
}
