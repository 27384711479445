import React from "react";
import { Box, CircularProgress } from "@mui/material";

export default function TrainlitLoader() {
  return (
    <Box sx={styles.container}>
      <CircularProgress thickness={1} size={250} sx={styles.progress} />
    </Box>
  );
}

const styles = {
  progress: {
    position: "absolute",
    color: "#000",
    top: -100,
    right: -25,
  },
  container: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 10,
    border: "0px solid",
  },
};
