import { CircularProgress, Typography } from "@mui/material";
import React, { Component } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Link } from "@mui/material";

import EnhancedTable from "../../components/table/EnhancedTable";
import axios from "../../utils/axios";
import TeamAttendanceChart from "./TeamAttendanceChart";
import AbsoluteCenter from "../../components/AbsoluteCenter";

const columns = [
  { id: "name", label: "קבוצה", width: 200 },
  { id: "fillRate", label: "מילוי נוכחות", width: 150 },
  { id: "rate", label: "נוכחות", width: 150 },
  { id: "chart", label: "אירועים" },
];

const minimumFillRate = 80;

export default class Teams extends Component {
  state = { isLoading: true, teams: null };

  componentDidMount = () => {
    if (this.props.visible) {
      this.loadData();
    }
  };

  //lazy load teams
  componentDidUpdate = async (prevProps) => {
    if (!prevProps.visible && this.props.visible && this.state.teams === null) {
      this.loadData();
    }
  };

  loadData = async () => {
    this.setState({ isLoading: true });
    const params = this.props.coachId ? `?coachId=${this.props.coachId}` : "";
    const res = await axios.get(`/attendances/teamsStats${params}`);

    this.setState({
      isLoading: false,
      teams: res.data,
    });
  };

  rowKey = (record) => record.team._id;

  sortValueCell = (columnId, record) => {
    switch (columnId) {
      case "fillRate":
        return this.getFillRate(record);

      case "rate":
        return this.getFillRate(record) >= minimumFillRate
          ? (record.totalAttended /
              (record.totalAttended + record.totalNotAttended)) *
              100
          : 101;
      default:
        return record[columnId];
    }
  };

  getFillRate = (record) => {
    return (
      (record.hasAttendance / (record.hasAttendance + record.noAttendance)) *
      100
    );
  };

  renderCell = (columnId, record) => {
    const team = record.team;
    switch (columnId) {
      case "name":
        return (
          <Link
            component={RouterLink}
            color="primary"
            to={`../teams/${team._id}`}
            underline="hover">
            {team.name}
          </Link>
        );

      case "fillRate":
        return `${Math.round(this.getFillRate(record))}%`;

      case "rate":
        return this.getFillRate(record) >= minimumFillRate
          ? `${Math.round(
              (record.totalAttended /
                (record.totalAttended + record.totalNotAttended)) *
                100
            )}%`
          : "לא זמין";

      case "chart":
        return <TeamAttendanceChart events={record.events} />;

      default:
        return record[columnId];
    }
  };

  renderContent = () => {
    return this.state.teams.length === 0 ? (
      <AbsoluteCenter>
        <Typography variant="h6">לא נמצאו נתונים</Typography>
      </AbsoluteCenter>
    ) : (
      <EnhancedTable
        size="small"
        headers={columns}
        renderCell={this.renderCell}
        sortValueCell={this.sortValueCell}
        defaultSortBy={2}
        defaultSortDirection="asc"
        rows={this.state.teams}
        renderKey={this.rowKey}
      />
    );
  };

  render() {
    return (
      <div>
        {this.state.isLoading ? (
          <AbsoluteCenter>
            <CircularProgress />
          </AbsoluteCenter>
        ) : (
          this.renderContent()
        )}
      </div>
    );
  }
}
