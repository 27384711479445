import React, { useEffect } from "react";
import ReactGA from "react-ga4";
import { Grid } from "@mui/material";
import { connect } from "react-redux";

import SubscriptionsChart from "../SubscriptionsChart";
// import axios from "../../../utils/axios";
// import SuperClubPlayersCounts from "./SuperClubPlayersCounts";
import Counts from "../Counts";

function SuperClubDashboard(props) {
  // const [data, setData] = useState(null);
  // const [isLoading, setIsLoading] = useState(false);

  // const loadData = async () => {
  //   setIsLoading(true);
  //   const res = await axios.get(`/dashboard/statusNG`);
  //   setData(res.data);
  //   setIsLoading(false);
  // };

  // useEffect(() => {
  //   loadData();
  // }, []);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/dashboard/superclub",
      title: "SuperClub Dashboard",
    });
  }, []);

  const hasPaymentsReportsPermission = props.user.permissions.paymentsReports;
  const hasHrPermission = props.user.permissions.hr;

  return (
    <Grid
      container
      spacing={3}
      justifyContent="center"
      alignItems="stretch"
      direction="row"
    >
      <Grid item xs={12}>
        <Counts
          selectedClub={props.selectedClub}
          user={props.user}
          superClub
          hasPaymentsReoprtsPermission={hasPaymentsReportsPermission}
          hasHrPermission={hasHrPermission}
        />
        {/* <SuperClubPlayersCounts isLoading={isLoading} data={data} /> */}
      </Grid>

      <Grid item xs={12}>
        <SubscriptionsChart superClub selectedClub={props.selectedClub} />
      </Grid>
    </Grid>
  );
}

function mapStateToProps(state) {
  return {
    selectedClub: state.auth.selectedClub,
    user: state.auth.user,
  };
}

export default connect(mapStateToProps)(SuperClubDashboard);
