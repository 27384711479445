import React, { useMemo } from "react";
import { TextField, Autocomplete, Paper } from "@mui/material";
import { styled, lighten, darken } from "@mui/system";
import _ from "lodash";

import { teamTypes } from "../../../utils/dictionaries";

function RtlPaper(props) {
  return (
    <Paper {...props} dir="rtl">
      {props.children}
    </Paper>
  );
}

const GroupHeader = styled("div")(({ theme }) => ({
  position: "sticky",
  top: "-8px",
  padding: "4px 10px",
  color: theme.palette.primary.main,
  backgroundColor: lighten(theme.palette.primary.light, 0.85),
  ...theme.applyStyles("dark", {
    backgroundColor: darken(theme.palette.primary.main, 0.8),
  }),
}));

const GroupItems = styled("ul")({
  padding: 0,
});

export default function TeamSelect(props) {
  const teams = useMemo(
    () => _.sortBy(props.teams, (t) => t.teamType),
    [props.teams]
  );

  const { event } = props;
  let selectedTeam;
  selectedTeam = props.teams.find((t) => t._id === event.team);
  if (!selectedTeam) {
    selectedTeam = props.completedTeams.find((t) => t._id === event.team);
  }
  if (!selectedTeam) {
    selectedTeam = null;
  }

  return (
    <>
      <Autocomplete
        options={teams}
        getOptionLabel={(option) => option?.name || null}
        getOptionKey={(option) => option?._id || null}
        groupBy={(option) => option.teamType}
        renderGroup={(params) => (
          <li key={params.key}>
            <GroupHeader>
              {
                (teamTypes[props.clubCategory] || teamTypes.default)[
                  params.group
                ]
              }
            </GroupHeader>
            <GroupItems>{params.children}</GroupItems>
          </li>
        )}
        noOptionsText="לא נמצאו תוצאות"
        PaperComponent={RtlPaper}
        renderInput={(params) => (
          <TextField
            error={props.errors.team !== undefined}
            variant="standard"
            margin="dense"
            {...params}
            label="קבוצה"
            {...props.textFieldParams}
            {...(props.readOnly ? { InputProps: { endAdornment: null } } : {})}
          />
        )}
        onChange={(_, newValue) =>
          props.onFieldUpdated("team", newValue?._id || "")
        }
        value={selectedTeam}
        fullWidth={props.fullWidth}
        disabled={props.disabled}
        readOnly={props.readOnly}
      />
    </>
  );
}
