import React from "react";
import { Alert, DialogActions, DialogContent } from "@mui/material";

import Dialog from "../../../components/Dialog";
import ButtonSx from "../../../components/base/ButtonSx";

export default function DeleteResourceDialog(props) {
  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      onClose={props.onClose}
      open={props.open}
      title="מחיקת מגרש"
    >
      <DialogContent dividers>
        האם ברצונכם למחוק את מגרש &quot;{props.resource?.name}&quot;?
        <Alert sx={{ mt: 2 }} severity="warning">
          שימו לב, כל האירועים במגרש זה ימחקו מהמערכת ולא יהיו זמינים יותר!
        </Alert>
      </DialogContent>
      <DialogActions>
        <ButtonSx onClick={props.onClose}>ביטול</ButtonSx>
        <ButtonSx onClick={props.onDelete}>מחיקה</ButtonSx>
      </DialogActions>
    </Dialog>
  );
}
