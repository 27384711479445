import React from "react";

import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";
import moment from "moment";
import CircleIcon from "@mui/icons-material/FiberManualRecord";

import axios from "../../utils/axios";
import ErrorDialog from "../../components/ErrorDialog";
import AbsoluteCenter from "../../components/AbsoluteCenter";
import SnackbarNg from "../../components/base/SnackbarNg";
import EditFab from "../../components/EditFab";
import ButtonSx from "../../components/base/ButtonSx";

export default class Alerts extends React.Component {
  state = {
    clubAlerts: null,
    isEditing: false,
    isLoading: false,
    editedAlerts: [],
    snackbarOpen: null,
    isErrorDialogOpen: false,
  };

  componentDidUpdate = async (prevProps) => {
    if ((prevProps.player || {})._id !== (this.props.player || {})._id) {
      this.setState({
        editedAlerts: [],
        isEditing: false,
        clubAlerts: null,
      });
    }
  };

  loadClubAlerts = async () => {
    if (!this.state.clubAlerts) {
      this.setState({ isLoading: false });
      const res = await axios.get("/settings/playerAlerts");

      this.setState({ isLoading: false, clubAlerts: res.data });
      return res.data;
    }

    return this.state.clubAlerts;
  };

  handleAlertToggle = (alertId) => {
    this.setState((prevState) => {
      const alerts = [...prevState.editedAlerts];
      const alert = alerts.find((a) => a._id === alertId);
      alert.checked = !alert.checked;

      return {
        editedAlerts: alerts,
      };
    });
  };

  handleStartEditing = async () => {
    this.setState({
      isEditing: true,
    });

    const clubAlerts = await this.loadClubAlerts();

    this.setState({
      editedAlerts: clubAlerts.map((alert) => ({
        ...alert,
        checked: this.props.player.alerts.find((pa) => pa._id === alert._id)
          ? true
          : false,
      })),
    });
  };

  handleCancelEditing = () => {
    this.setState({
      isEditing: false,
      editedAlerts: [],
    });
  };

  handleSaveChanges = async () => {
    try {
      this.setState({ isSaving: true });
      const alerts = this.state.editedAlerts.filter((a) => a.checked);
      const data = {
        alerts: alerts.map((a) => a._id),
      };
      await axios.patch(`/clubPlayers/${this.props.player._id}/alerts`, data);
      this.props.onUpdate(alerts);
      this.setState({
        isSaving: false,
        isEditing: false,
        editedAlerts: [],
        snackbarOpen: moment().format(),
      });
    } catch (error) {
      console.log(error);
      this.setState({
        isErrorDialogOpen: true,
        isSaving: false,
      });
    }
  };

  renderEditAlerts = () => {
    if (this.state.isLoading) {
      return (
        <AbsoluteCenter>
          <CircularProgress />
        </AbsoluteCenter>
      );
    }

    return (
      <div>
        <FormGroup>
          {this.state.editedAlerts.map((alert) => (
            <FormControlLabel
              key={alert._id}
              control={
                <Checkbox
                  checked={alert.checked}
                  onChange={() => this.handleAlertToggle(alert._id)}
                  name={alert.text}
                />
              }
              label={alert.text}
            />
          ))}
        </FormGroup>
        <Stack
          direction="row"
          spacing={1}
          justifyContent="flex-end"
          sx={{ mt: 1 }}
        >
          <ButtonSx
            disabled={this.state.isSaving}
            sx={{ width: 100 }}
            onClick={this.handleCancelEditing}
          >
            ביטול
          </ButtonSx>
          <ButtonSx
            disabled={this.state.isSaving}
            sx={{ width: 100 }}
            onClick={this.handleSaveChanges}
          >
            שמירה
          </ButtonSx>
        </Stack>
      </div>
    );
  };

  renderViewAlerts = () => {
    return (
      <div>
        {this.props.player.alerts.length === 0 ? (
          <AbsoluteCenter>
            <Typography variant="h6">לא קיימות התראות</Typography>
          </AbsoluteCenter>
        ) : (
          <List>
            {this.props.player.alerts.map((alert) => (
              <ListItem key={alert._id}>
                <ListItemText
                  primary={
                    <span style={{ display: "flex", alignItems: "center" }}>
                      <CircleIcon style={{ fontSize: 16, marginLeft: 10 }} />
                      {alert.text}
                    </span>
                  }
                />
              </ListItem>
            ))}
          </List>
        )}
        {this.props.hasHrPlayerParentAdminPermission && (
          <EditFab title="ערוך" onClick={this.handleStartEditing} />
        )}
      </div>
    );
  };

  render() {
    return (
      <>
        {this.state.isEditing
          ? this.renderEditAlerts()
          : this.renderViewAlerts()}

        <SnackbarNg
          snackKey={this.state.snackbarOpen}
          open={this.state.snackbarOpen !== null}
          onClose={() => this.setState({ snackbarOpen: null })}
          message="השינויים נשמרו בהצלחה"
        />
        <ErrorDialog
          open={this.state.isErrorDialogOpen}
          onClose={() => this.setState({ isErrorDialogOpen: false })}
        />
      </>
    );
  }
}
