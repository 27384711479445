const initialState = {
  notificationBarCounters: {},
  seasons: [],
  isLoading: true,
  errors: {},
};

const auth = (state = initialState, action) => {
  switch (action.type) {
    case "SET_NOTIFICATIONS_COUNTER":
      return {
        ...state,
        notificationsCounterLastRequest: action.notificationsCounterLastRequest,
        notificationBarCounters: action.notificationBarCounters,
        notificationsCounterLastClubId: action.notificationsCounterLastClubId,
      };

    case "SET_SEASONS":
      return {
        ...state,
        seasons: action.seasons,
      };

    case "STATUS":
      return {
        ...state,
        isLoading: action.isLoading,
      };

    case "SET_ERROR":
      return {
        ...state,
        errors: { ...state.errors, [action.errorType]: action.error },
      };

    default:
      return state;
  }
};

export default auth;
