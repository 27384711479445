import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Slide,
  Typography,
  Link,
  Breadcrumbs,
  List,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ArrowIcon from "@mui/icons-material/NavigateBeforeSharp";

import ListItem from "../../../components/ListItem";
import TeamLevels from "./TeamLevels";
import ButtonSx from "../../../components/base/ButtonSx";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});

export default function TeamSettings(props) {
  const [selectedSetting, setSelectedSetting] = React.useState(null);
  const [selectedSubSetting, setSelectedSubSetting] = React.useState(null);

  const settings = { levels: "קטגוריות" };

  const toggleDialogOpen = () => {
    // if (update) {
    //   props.onUpdate();
    // }
    setSelectedSetting(null);
    setSelectedSubSetting(null);
    props.onClose();
  };

  const handleSelectSetting = (setting) => (e) => {
    e.preventDefault();
    setSelectedSetting(setting);
    setSelectedSubSetting(null);
  };

  const renderContent = () => {
    switch (selectedSetting) {
      case "levels":
        return <TeamLevels />;

      default:
        return (
          <List>
            {Object.entries(settings).map(([setting, title]) => (
              <ListItem
                key={setting}
                button
                primaryText={title}
                listItemProps={{
                  onClick: handleSelectSetting(setting),
                }}
              />
            ))}
          </List>
        );
    }
  };

  const handleGoBack = (e) => {
    e.preventDefault();
    if (selectedSubSetting) {
      setSelectedSubSetting(null);
    } else {
      setSelectedSetting(null);
    }
  };

  return (
    <Dialog
      open={props.open}
      onClose={toggleDialogOpen}
      fullScreen
      maxWidth="sm"
      sx={styles.dialog}
      dir="rtl"
      TransitionComponent={Transition}
    >
      <DialogTitle>
        <Box sx={styles.titleContainer}>
          הגדרות
          <IconButton size="small" onClick={toggleDialogOpen}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent dividers>
        <Box sx={styles.header}>
          <Breadcrumbs>
            <Link
              color="inherit"
              href="/"
              onClick={handleSelectSetting(null)}
              underline="hover"
            >
              הגדרות
            </Link>
            {selectedSetting &&
              (selectedSubSetting ? (
                <Link
                  color="inherit"
                  href="/"
                  onClick={handleSelectSetting(selectedSetting)}
                  underline="hover"
                >
                  {settings[selectedSetting]}
                </Link>
              ) : (
                <Typography color="textPrimary">
                  {settings[selectedSetting]}
                </Typography>
              ))}
            {selectedSubSetting && (
              <Typography color="textPrimary">
                {selectedSubSetting.name}
              </Typography>
            )}
          </Breadcrumbs>
          {selectedSetting && (
            <ButtonSx
              variant="text"
              onClick={handleGoBack}
              endIcon={<ArrowIcon />}
              sx={{ width: null, color: "#000" }}
            >
              חזור
            </ButtonSx>
          )}
        </Box>
        {renderContent()}
      </DialogContent>
    </Dialog>
  );
}

const styles = {
  dialog: {
    width: 500,
    zIndex: 11,
    marginTop: "72px",
  },
  titleContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
};
