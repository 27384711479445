import React, { useState } from "react";
import {
  DialogActions,
  DialogContent,
  CircularProgress,
  useMediaQuery,
} from "@mui/material";

import axios from "../../../../utils/axios";
import Dialog from "../../../Dialog";
import CancelSubscriptionForm from "./CancelSubscriptionForm";
import ButtonSx from "../../../base/ButtonSx";

export default function CancelSubscriptionDialog(props) {
  const [isSaving, setIsSaving] = useState(false);
  const [response, setResponse] = useState(null);
  const [reason, setReason] = useState("");
  const [cancelledAt, setCancelledAt] = useState(null);
  const [fees, setFees] = useState([]);
  const [nonFeesPrice, setNonFeesPrice] = useState(null);
  const [nonFeesPriceStr, setNonFeesPriceStr] = useState(null);
  const [errors, setErrors] = useState({});
  const [months, setMonths] = useState(null);

  const isSmUp = useMediaQuery((theme) => theme.breakpoints.up("sm"), {
    noSsr: true,
  });

  React.useEffect(() => {
    setReason("");
    setErrors({});
    setIsSaving(false);
    setResponse(null);
    if (props.direct) {
      setFees(
        props.subscription.feeProducts.map((fp) => ({
          ...fp,
          priceStr: fp.price,
        }))
      );
      setNonFeesPrice(props.subscription.price);
      setNonFeesPriceStr(props.subscription.price.toString());
      setMonths(props.subscription.months);
      setErrors({ fees: [] });
    }
  }, [props.subscription, props.direct]);

  const cancel = async () => {
    setIsSaving(true);
    const feePrices = fees.map((fee) => ({
      _id: fee.feeProductId,
      price: fee.price,
    }));
    const data = {
      cancelledAt: cancelledAt.format("YYYY-MM-DD"),
      nonFeesPrice,
      feePrices,
      reason,
      type: props.subscription.type,
    };

    try {
      await axios.delete(`/store/subscriptions/${props.subscription._id}`, {
        data,
      });
      setIsSaving(false);
      setResponse("success");
    } catch (error) {
      setIsSaving(false);
      setResponse("error");
    }
  };

  const handleDateChange = (date, months) => {
    setCancelledAt(date);
    if (props.subscription.type === "direct") {
      if (["season", "camp"].indexOf(props.subscription.newType) > -1) {
        setNonFeesPrice(props.subscription.monthlyPrice * months);
        setNonFeesPriceStr(
          (props.subscription.monthlyPrice * months).toString()
        );
        setMonths(months);
      } else if (months === 0) {
        // fixed price or daily and the cancellation date is similar to the start date
        setNonFeesPrice(0);
        setNonFeesPriceStr("0");
      }
    }
  };

  const validate = () => {
    let valid = true;
    setErrors((prev) => ({
      ...prev,
      reason: false,
      date: false,
      nonFeesPrice: false,
    }));

    if (reason.trim().length === 0) {
      setErrors((prev) => ({ ...prev, reason: true }));
      valid = false;
    }

    if (cancelledAt === null) {
      setErrors((prev) => ({ ...prev, date: true }));
      valid = false;
    }

    if (props.direct) {
      // we need to validate this even though the price is instaValidated since it can be set automatically when changing the date
      if (
        nonFeesPriceStr === "" ||
        !(nonFeesPrice >= 0 && nonFeesPrice <= props.subscription.price)
      ) {
        setErrors((prev) => ({ ...prev, nonFeesPrice: true }));
        valid = false;
      }
    }

    const areFeesValid = !errors.fees?.some((error) => error);
    return valid && areFeesValid && !errors.nonFeesPrice;
  };

  const handleCancel = () => {
    const valid = validate();
    if (valid) {
      if (response === null) {
        cancel();
      } else {
        props.onComplete();
      }
    }
  };

  const handlePriceChange = (priceStr, price) => {
    setNonFeesPrice(price);
    setNonFeesPriceStr(priceStr);
    setErrors((prev) => ({
      ...prev,
      nonFeesPrice:
        priceStr === "" || !(price >= 0 && price <= props.subscription.price),
    }));
  };

  const handleFeePriceChange = (index) => (priceStr, price) => {
    setFees((prev) => {
      const newFees = [...prev];
      newFees[index].priceStr = priceStr;
      newFees[index].price = price;
      return newFees;
    });
    const valid =
      priceStr !== "" && price >= 0 && price <= fees[index].originalPrice;
    setErrors((prev) => {
      const fees = [...prev.fees];
      fees[index] = !valid;
      return { ...prev, fees };
    });
  };

  let content;

  if (isSaving) {
    content = (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress />
      </div>
    );
  } else if (response !== null) {
    switch (response) {
      case "success":
        content = <div>הפעולה בוצעה בהצלחה</div>;
        break;

      default:
        content = <div>הפעולה נכשלה</div>;
        break;
    }
  } else {
    content = (
      <CancelSubscriptionForm
        subscription={props.subscription}
        onDateChange={handleDateChange}
        cancelledAt={cancelledAt}
        setReason={setReason}
        reason={reason}
        fees={fees}
        nonFeesPriceStr={nonFeesPriceStr}
        nonFeesPrice={nonFeesPrice}
        errors={errors}
        onPriceChange={handlePriceChange}
        onFeePriceChange={handleFeePriceChange}
        months={months}
      />
    );
  }

  return (
    <Dialog
      onClose={props.onClose}
      open={props.open}
      title="הפסקת פעילות"
      fullWidth={false}
      closeButton={!isSmUp}
      fullScreen={!isSmUp}
    >
      <DialogContent dividers sx={styles.content}>
        {content}
      </DialogContent>
      <DialogActions>
        {response === null && (
          <ButtonSx onClick={props.onClose} isLoading={isSaving}>
            ביטול
          </ButtonSx>
        )}
        <div>
          <ButtonSx isLoading={isSaving} onClick={handleCancel} debounce>
            אישור
          </ButtonSx>
        </div>
      </DialogActions>
    </Dialog>
  );
}

const styles = {
  content: {
    display: "flex",
    alignItems: "flex-start",
  },
};
