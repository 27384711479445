import React, { useState } from "react";
import { DialogActions, DialogContent, Typography } from "@mui/material";

import Dialog from "../../components/Dialog";
import ButtonSx from "../../components/base/ButtonSx";

export default function CancelOtherPaymentMethodRequestDialog(props) {
  const [isCancelling, setIsCancelling] = useState(false);

  const handleCancelRequest = async () => {
    setIsCancelling(true);
    await props.onCancelRequest();
    setIsCancelling(false);
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      title="ביטול בקשה לתשלום במזומן או צ׳ק"
      fullWidth
    >
      <DialogContent dividers>
        <Typography gutterBottom style={{ marginTop: 10 }}>
          האם ברצונך לבטל את הבקשה?
        </Typography>
      </DialogContent>
      <DialogActions>
        <ButtonSx onClick={props.onClose} disabled={isCancelling}>
          חזרה
        </ButtonSx>
        <ButtonSx
          onClick={handleCancelRequest}
          disabled={isCancelling}
          isLoading={isCancelling}
          debounce
        >
          אישור
        </ButtonSx>
      </DialogActions>
    </Dialog>
  );
}
