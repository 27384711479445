import React from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TablePagination from "@mui/material/TablePagination";
import { Box } from "@mui/material";

import EnhancedTableHead from "./EnhancedTableHead";
import EnhancedRow from "./EnhancedRow";
import CollapsableEnhancedRow from "./CollapsableEnhancedRow";

const styles = {
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    border: "1px solid #EFEFEF",
    borderRadius: "5px",
    backgroundColor: "#FFF",
  },
  table: {
    minWidth: 300,
  },
};

export default function EnhancedTable(props) {
  const [order, setOrder] = React.useState(props.defaultSortDirection || "asc");

  const [orderBy, setOrderBy] = React.useState(
    props.defaultSortBy
      ? props.headers[props.defaultSortBy].id
      : props.headers[0].id
  );
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(
    props.pagination ? props.initialRowsPerPage || 10 : props.rows.length
  );

  function desc(a, b) {
    if (b < a) {
      return -1;
    }
    if (b > a) {
      return 1;
    }
    return 0;
  }

  function stableSort(array, cmp) {
    if (props.sort === false) {
      return array;
    }
    const stabilizedThis = array.map((row, index) => {
      const sortValue = props.sortValueCell
        ? props.sortValueCell(orderBy, row)
        : row[orderBy];
      return [row, sortValue, index];
    });

    stabilizedThis.sort((a, b) => {
      const order = cmp(a[1], b[1]);
      if (order !== 0) return order;
      return a[2] - b[2];
    });

    return stabilizedThis.map((el) => el[0]);
  }

  function getSorting(order, orderBy) {
    return order === "desc"
      ? (a, b) => desc(a, b, orderBy)
      : (a, b) => -desc(a, b, orderBy);
  }

  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
  }

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
    setPage(0);
  }

  const pageRows = props.pagination
    ? [page * rowsPerPage, page * rowsPerPage + rowsPerPage]
    : [0, props.rows.length];

  // const wrapper = props.paperize !== false ? new Paper() : (props) => ({...props.childern});

  const RowComponent = props.CollapsableComponent
    ? CollapsableEnhancedRow
    : EnhancedRow;

  return (
    <Box sx={styles.root}>
      <Box sx={styles.paper}>
        <Box sx={{ overflowX: "auto" }}>
          <Table
            sx={styles.table}
            aria-labelledby="tableTitle"
            size={props.size || "medium"}
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={props.rows.length}
              headers={props.headers}
              collapsableRow={props.CollapsableComponent !== undefined}
              sort={props.sort}
            />
            <TableBody>
              {stableSort(props.rows, getSorting(order, orderBy))
                .slice(...pageRows)
                .map((row) => {
                  return (
                    <RowComponent
                      key={
                        props.renderKey
                          ? props.renderKey(row)
                          : row._id || row.name
                      }
                      row={row}
                      selected={row._id === props.selected}
                      headers={props.headers}
                      renderCell={props.renderCell}
                      CollapsableComponent={props.CollapsableComponent}
                      collapsableComponentProps={
                        props.collapsableComponentProps
                      }
                      onRowClick={props.onRowClick}
                    />
                  );
                })}
            </TableBody>
          </Table>
        </Box>
        {props.pagination && (
          <TablePagination
            rowsPerPageOptions={[10, 50, 100]}
            component="div"
            count={props.rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelDisplayedRows={({ from, to, count }) =>
              `${to}-${from} מתוך ${count}`
            }
            labelRowsPerPage="שורות בעמוד"
          />
        )}
      </Box>
    </Box>
  );
}
