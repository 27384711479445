import React from "react";
import EditIcon from "@mui/icons-material/Edit";
import {
  Grid,
  TextField,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
} from "@mui/material";

import MenuItem from "../../components/base/MenuItem";
import config from "../../config";
import { displayPrice } from "../../utils/helpers";
import CreditCardPaymentsAmountDialog from "../../components/store/CreditCardPaymentsAmountDialog";

class CreditCardForm extends React.Component {
  state = { open: false, isEditFirstPaymentAmountOpen: false };

  handleToken = (e) => {
    if (e.data && e.data.messageType === "newCard") {
      if (e.data.success) {
        this.props.onChange({
          token: e.data.TranzilaTK,
          expiration: `${e.data.expmonth}/${e.data.expyear}`,
          ccResponse: e.data,
          last4: e.data.TranzilaTK.slice(e.data.TranzilaTK.length - 4),
        });
        this.setState({ open: false });
      } else {
        this.setState({ error: true });
      }
    }
  };

  componentWillUnmount = async () => {
    window.removeEventListener("message", this.handleToken);
  };

  componentDidMount = async () => {
    window.addEventListener("message", this.handleToken);
  };

  render() {
    const successUrl = decodeURIComponent(
      `${config.baseUrl}/payments/responder?success=true&`
    );

    const failureUrl = decodeURIComponent(
      `${config.baseUrl}/payments/responder?success=false&`
    );

    let numberOfPayments = [...Array(12).keys()].map((i) => i + 1);

    const terminalName =
      process.env.NODE_ENV === "production"
        ? (this.props.clubConfig.payments || {}).terminalName
        : "tltest";
    const transmode = terminalName === "tltest" ? "AK" : "VK";

    const url = `https://direct.tranzila.com/${terminalName}/iframenew.php?nologo=1&buttonLabel=המשך&sum=1&hidesum=1&currency=1&cred_type=1&tranmode=${transmode}&lang=il&trTextColor=000000&success_url_address=${successUrl}&fail_url_address=${failureUrl}`;

    return <>
      <Dialog
        dir="rtl"
        onClose={() => this.setState({ open: false })}
        open={this.state.open}
      >
        <DialogTitle id="simple-dialog-title">הוספת כרטיס אשראי</DialogTitle>
        <DialogContent dividers>
          {this.state.error ? (
            <div>פרטי האשראי אינם תקינים</div>
          ) : (
            <iframe
              width="100%"
              height="400px"
              title="test"
              style={{
                borderWidth: 0,
              }}
              scrolling="no"
              src={url}
            />
          )}
        </DialogContent>
        {this.state.error && (
          <DialogActions>
            <Button
              onClick={() => this.setState({ open: false, error: false })}
            >
              אישור
            </Button>
          </DialogActions>
        )}
      </Dialog>

      <Grid container spacing={3} style={{ border: "0px solid black" }}>
        <Grid item xs={12}>
          <TextField
            variant="standard"
            id="lastName"
            name="ccNumber"
            label="מספר כרטיס"
            error={this.props.state.errors.cc !== undefined}
            helperText={this.props.state.errors.cc}
            value={
              this.props.state.last4
                ? `xxxx-xxxx-xxxx-${this.props.state.last4}`
                : ""
            }
            onClick={() => {
              this.setState({ open: true });
            }}
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="start"
                  onClick={() => {
                    this.props.onChange({ token: null, ccResponse: null });
                    this.setState({ open: true });
                  }}
                >
                  <EditIcon />
                </InputAdornment>
              ),
            }} />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="standard"
            id="lastName"
            name="lastName"
            label="מספר תשלומים"
            fullWidth
            select
            value={this.props.state.numberOfPayments}
            onChange={(event) =>
              this.props.onChange({ numberOfPayments: event.target.value })
            }
            error={this.props.state.errors.numberOfPayments !== undefined}
            helperText={this.props.state.errors.numberOfPayments}>
            {numberOfPayments.map((i) => (
              <MenuItem value={i} key={i}>
                {i}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        {this.props.state.firstPaymentAmount &&
          this.props.state.numberOfPayments !== 1 && (
            <Grid item xs={12}>
              {this.props.state.firstPaymentAmount ===
              this.props.state.basePaymentAmount ? (
                <span>
                  {this.props.state.numberOfPayments} תשלומים על סך{" "}
                  {displayPrice(this.props.state.basePaymentAmount / 100)}
                </span>
              ) : (
                <span>
                  תשלום ראשון:{" "}
                  {displayPrice(this.props.state.firstPaymentAmount / 100)},{" "}
                  {this.props.state.numberOfPayments - 1} תשלומים נוספים (כל
                  אחד):{" "}
                  {displayPrice(this.props.state.basePaymentAmount / 100)}
                </span>
              )}
              <IconButton
                size="small"
                onClick={() =>
                  this.setState({ isEditFirstPaymentAmountOpen: true })
                }
              >
                <EditIcon />
              </IconButton>
              <CreditCardPaymentsAmountDialog
                open={this.state.isEditFirstPaymentAmountOpen}
                price={this.props.state.firstPaymentAmount}
                onChange={this.props.onChange}
                onClose={() =>
                  this.setState({ isEditFirstPaymentAmountOpen: false })
                }
                maxPrice={
                  this.props.paymentAmount - this.props.state.numberOfPayments
                }
              />
            </Grid>
          )}
      </Grid>
    </>;
  }
}

export default CreditCardForm;
