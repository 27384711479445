import React from "react";
import { DialogActions, DialogContent } from "@mui/material";

import { displayPrice } from "../../../utils/helpers";
import Dialog from "../../Dialog";
import ButtonSx from "../../base/ButtonSx";

export default function DeleteDebtDialog(props) {
  return (
    <Dialog
      onClose={props.onClose}
      open={props.open}
      title="מחיקת חוב"
      fullWidth
    >
      <DialogContent dividers style={{ height: 100 }}>
        האם ברצונך למחוק חוב בסך {displayPrice(props.subscription.debt)}?
      </DialogContent>
      <DialogActions>
        <ButtonSx debounce onClick={() => props.onClose(false)}>
          ביטול
        </ButtonSx>
        <ButtonSx onClick={() => props.onClose(true)}>אישור</ButtonSx>
      </DialogActions>
    </Dialog>
  );
}
