import React from "react";

import {
  List,
  ListItem,
  ListItemButton,
  Collapse,
  useMediaQuery,
  ListItemText,
} from "@mui/material";
import DashboardIcon from "@mui/icons-material/Dashboard";
import SchoolIcon from "@mui/icons-material/School";
import PeopleIcon from "@mui/icons-material/People";
import PersonIcon from "@mui/icons-material/Person";
import CalendarIcon from "@mui/icons-material/CalendarToday";
import BarChartIcon from "@mui/icons-material/BarChartRounded";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCartRounded";
import PersonAddIcon from "@mui/icons-material/PersonAddRounded";
import ExpandMoreIcon from "@mui/icons-material/ExpandMoreRounded";
import ExpandLessIcon from "@mui/icons-material/ExpandLessRounded";
import AccountBoxRoundedIcon from "@mui/icons-material/AccountBoxRounded";
import SettingsIcon from "@mui/icons-material/Settings";
import PlaylistAddCheck from "@mui/icons-material/PlaylistAddCheck";
import AssessmentIcon from "@mui/icons-material/AssessmentOutlined";
import LogoutIcon from "@mui/icons-material/ExitToApp";
import { Link as RouterLink } from "react-router-dom";
import { styled } from "@mui/material/styles";

import clubsConfig from "../config/clubs.js";

const MenuListItem = styled(ListItem)({
  color: "black",
});

const IconConatainer = styled("div")(({ theme }) => ({
  marginRight: "15px",
  display: "flex",
  alignItems: "center",
  color: theme.appBar.backgroundColor,
}));

export function MainListItems(props) {
  const newClubConfig = props.selectedClub.clubConfig;

  const hasRegistrationAdminPermission =
    props.user.permissions.registrationAdmin;
  const hasHrPermission = props.user.permissions.hr;
  const hasPaymentsReportsPermission = props.user.permissions.paymentsReports;

  const renderItem = (item, title, to, Icon) => {
    const selected = props.selectedItem === item;
    return (
      <MenuListItem disablePadding component={RouterLink} to={to}>
        <ListItemButton selected={selected} onClick={props.onClick}>
          <IconConatainer>
            <Icon />
          </IconConatainer>
          <ListItemText
            primaryTypographyProps={
              selected ? { sx: { fontWeight: "bold" } } : {}
            }
            primary={title}
          />
        </ListItemButton>
      </MenuListItem>
    );
  };

  const renderSuperClubSecondaryListItems = () => {
    return (
      <div>
        {renderItem("dashboard", "דשבורד", "", DashboardIcon)}
        {renderItem(
          "accountingDocuments",
          "יצוא להנהח״ש",
          "payments/accountingDocuments",
          AssessmentIcon
        )}

        {renderItem(
          "revenuesReport",
          "הכנסות",
          "reports/revenues",
          AssessmentIcon
        )}
      </div>
    );
  };

  if (newClubConfig.general.superclub) {
    return renderSuperClubSecondaryListItems();
  }

  return (
    <div>
      {(hasHrPermission || hasPaymentsReportsPermission) &&
        renderItem("dashboard", "דשבורד", "", DashboardIcon)}
      {hasHrPermission && (
        <>
          {renderItem("coaches", "מאמנים", "coaches", SchoolIcon)}
          {renderItem("teams", "קבוצות", "teams", PeopleIcon)}
          {renderItem("players", "ספורטאים", "players", PersonIcon)}
          {renderItem("parents", "הורים", "parents", AccountBoxRoundedIcon)}
          {renderItem("attendance", "נוכחות", "attendance", PlaylistAddCheck)}
        </>
      )}
      {props.user.permissions.calendar &&
        renderItem("calendar", "אירועים", "calendar", CalendarIcon)}

      {hasRegistrationAdminPermission &&
        renderItem(
          "newPurchaseSubscription",
          "הרשמה",
          "purchases/new?type=subscription",
          PersonAddIcon
        )}
    </div>
  );
}

export function SecondaryListItems(props) {
  const [itemsStatus, setItemsStatus] = React.useState({});
  const isSmUp = useMediaQuery((theme) => theme.breakpoints.up("sm"), {
    noSsr: true,
  });

  const clubConfig = clubsConfig[props.selectedClub.internalName] || {};
  const paymentsConfig = clubConfig.payments;

  const hasStore = paymentsConfig && paymentsConfig.store;
  const hasPayments = paymentsConfig && paymentsConfig.enabled;
  const newClubConfig = props.selectedClub.clubConfig;
  const hasPaymentsReportsPermission = props.user.permissions.paymentsReports;
  const hasRegistrationAdminPermission =
    props.user.permissions.registrationAdmin;
  const hasRegistrationReportsPermission =
    props.user.permissions.registrationReports;

  const hasStoreAdminPermission = props.user.permissions.storeAdmin;

  const trainlitUser = props.user.email.endsWith("@trainlitapp.com");

  const items = [
    {
      name: "חנות",
      value: "store",
      icon: ShoppingCartIcon,
      hidden:
        !hasStore ||
        (!hasRegistrationAdminPermission && !hasStoreAdminPermission),
      children: [
        {
          name: "ניהול",
          value: "products",
          link: "store/admin/products",
          hidden: !isSmUp || !hasStoreAdminPermission,
        },
        {
          link: "purchases/new?type=merchandise",
          value: "newPurchaseMerchandise",
          name: "רכישת ציוד",
          hidden: !hasRegistrationAdminPermission,
        },
      ],
    },
    {
      name: "דוחות",
      value: "reports",
      icon: BarChartIcon,
      hidden:
        !hasPaymentsReportsPermission &&
        !hasRegistrationAdminPermission &&
        !hasRegistrationReportsPermission,
      children: [
        {
          name: "בקשות מיוחדות",
          value: "subscriptionDiscountRequests",
          link: "subscriptions/discountRequests",
          hidden:
            !newClubConfig.general.discountRequests ||
            !hasRegistrationAdminPermission,
        },
        {
          name: "פורשים",
          value: "quitters",
          link: "reports/quitters",
          hidden: !hasRegistrationReportsPermission,
        },
        {
          name: "פעילות",
          value: "subscriptions",
          link: "reports/subscriptionLogs",
          hidden: !hasRegistrationReportsPermission,
        },
        {
          name: "תשלומים",
          value: "transactions",
          link: "payments/transactions",
          hidden: !hasPayments || !hasPaymentsReportsPermission,
        },
        {
          name: "מסמכים חשבונאיים",
          value: "receipts",
          link: "payments/receipts",
          hidden: !hasPayments || !hasPaymentsReportsPermission,
        },
        {
          name: "הכנסות מפעילות",
          value: "paymentsDistribution",
          link: "reports/paymentsDistribution",
          hidden: !hasPayments || !hasPaymentsReportsPermission,
        },
        // {
        //   name: "הנחות",
        //   value: "discountsReport",
        //   link: "reports/discounts",
        // },
        {
          name: "פירעון תשלומים חודשי",
          value: "monthlyIncomePerProduct",
          link: "reports/monthlyIncomePerProduct",
          hidden:
            !hasPayments ||
            !hasPaymentsReportsPermission ||
            !newClubConfig.monthlyIncomePerProduct,
        },
        {
          name: "פירעון תשלומים",
          value: "incomeReport",
          link: "reports/income",
          hidden: !hasPayments || !hasPaymentsReportsPermission,
        },
        {
          name: "הכנסות",
          value: "revenuesReport",
          link: "reports/revenues",
          hidden: !hasPayments || !hasPaymentsReportsPermission,
        },
        {
          name: "רכישת מוצרים",
          value: "purchasesMerchandise",
          link: "store/purchases/merchandise",
          hidden: !hasStore || !hasRegistrationReportsPermission,
        },
        {
          name: "צ׳קים",
          value: "checks",
          link: "payments/checks",
          hidden: !hasPayments || !hasPaymentsReportsPermission,
        },
        {
          name: "יתרות זכות וחובה",
          value: "debt",
          link: "reports/debt",
          hidden: !hasPayments || !hasRegistrationReportsPermission,
        },
        {
          name: "יצוא להנהלת חשבונות",
          value: "accountingDocuments",
          link: "payments/accountingDocuments",

          hidden:
            !(hasPayments && paymentsConfig.accountingDocumentsExport) ||
            !hasPaymentsReportsPermission,
        },
        {
          name: "הפקדות",
          value: "deposits",
          link: "payments/deposits",

          hidden: !hasPayments || !hasPaymentsReportsPermission,
        },
        {
          name: "דרישות תשלום",
          value: "dealReceipts",
          link: "dealReceipts",
          hidden:
            !newClubConfig.general.dealReceipts ||
            !hasPaymentsReportsPermission,
        },
      ],
    },
    {
      name: "הגדרות",
      value: "settings",
      icon: SettingsIcon,
      hidden: props.user.level !== "admin",
      children: [
        {
          name: "הגדרות כלליות",
          value: "generalSettings",
          link: "settings/generalSettings",
        },
        {
          name: "מסגרות תשלום",
          value: "subscriptionProducts",
          link: "settings/subscriptionProducts",
          hidden: !hasPayments,
        },
        {
          name: "עונות",
          value: "seasons",
          link: "settings/seasons",
          hidden: !(trainlitUser || newClubConfig.seasonsConfig),
        },
        {
          name: "התראות ספורטאים",
          value: "playerAlerts",
          link: "settings/playerAlerts",
        },
        {
          name: "משתמשים",
          value: "users",
          link: "users",
        },
        {
          name: "אתר הורים",
          value: "parentsSite",
          link: "settings/parentsSite",
        },
      ],
    },
  ];

  const openableItems = items.filter((i) => i.children).map((i) => i.value);

  const changeItemStatus = (item, status) => {
    setItemsStatus((prev) => {
      const newItems = { ...prev };
      openableItems.forEach((i) => {
        if (i === item) {
          newItems[i] = status ? status : !newItems[i];
        } else {
          newItems[i] = false;
        }
      });
      return newItems;
    });
  };

  React.useEffect(() => {
    for (let index = 0; index < items.length; index++) {
      const item = items[index];

      if (item.value === props.selectedItem) {
        return; //top level item
      }

      for (
        let childIndex = 0;
        childIndex < (item.children || []).length;
        childIndex++
      ) {
        const child = item.children[childIndex];
        if (child.value === props.selectedItem) {
          changeItemStatus(item.value, true); // open parent
          return;
        }
      }
    }
  }, [props.selectedItem]);

  if (newClubConfig.general.superclub) {
    return <div />;
  }

  const renderItems = (currentItems, nested = false) => {
    const renderedItems = [];

    for (let index = 0; index < currentItems.length; index++) {
      const item = currentItems[index];
      const selected = props.selectedItem === item.value;

      if (!item.hidden) {
        const isOpen = itemsStatus[item.value] || false;
        renderedItems.push(
          <React.Fragment key={item.value}>
            <MenuListItem
              disablePadding
              {...(!item.chlidren && item.link // if top level
                ? { component: RouterLink, to: item.link }
                : {})}
            >
              <ListItemButton
                selected={selected}
                onClick={() => {
                  item.children
                    ? changeItemStatus(item.value)
                    : props.onClick && props.onClick();
                }}
              >
                {item.icon && (
                  <IconConatainer>
                    <item.icon />
                  </IconConatainer>
                )}
                <ListItemText
                  sx={nested ? { pl: 6 } : {}}
                  primaryTypographyProps={
                    selected ? { sx: { fontWeight: "bold" } } : {}
                  }
                  primary={item.name}
                />
                {item.children &&
                  (isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />)}
              </ListItemButton>
            </MenuListItem>
            {item.children && (
              <Collapse in={isOpen} timeout="auto">
                <List component="span" disablePadding dense>
                  {renderItems(item.children, true)}
                </List>
              </Collapse>
            )}
          </React.Fragment>
        );
      }
    }

    return renderedItems;
  };

  return <div>{renderItems(items)}</div>;
}

export function MobileBottomItems(props) {
  const renderItem = (item, title, to, Icon) => {
    const selected = props.selectedItem === item;
    return (
      <MenuListItem disablePadding component={RouterLink} to={to}>
        <ListItemButton selected={selected}>
          <IconConatainer>
            <Icon />
          </IconConatainer>
          <ListItemText
            primaryTypographyProps={
              selected ? { sx: { fontWeight: "bold" } } : {}
            }
            primary={title}
          />
        </ListItemButton>
      </MenuListItem>
    );
  };

  return renderItem("logout", "יציאה", "/logout", LogoutIcon);
}
