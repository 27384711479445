import React, { useEffect, useState, useMemo } from "react";
import moment from "moment";
import ReactGA from "react-ga4";
import { useSelector } from "react-redux";

import Report from "./Report";
import { renderCell, sortValueCell } from "../../lib/tableHelpers";
import { subscriptionLogTypes } from "../../utils/dictionaries";
import { displaySeason } from "../../utils/helpers";

const baseColumns = (user) => [
  { id: "createdAt", label: "זמן", preset: "datetime", minWidth: "140px" },
  {
    id: "type",
    label: "סוג",
    mapping: subscriptionLogTypes,
    preset: "mapping",
  },
  {
    id: "clubPlayer",
    label: "ספורטאי",
    preset: "link",
    noLink: !user.permissions.hr,
  },
  { id: "team", label: "קבוצה", preset: "link", noLink: !user.permissions.hr },
  { id: "season", label: "עונה", baseRenderer: true },
  { id: "transaction", label: "קבלה", preset: "link" },
  { id: "appliedAmount", label: "סכום", preset: "price" },
  {
    id: "feesPrice",
    label: "מחיר דמי רישום",
    preset: "price",
    defaultValue: 0,
  },
  { id: "price", label: "מחיר פעילות", preset: "price" },
  { id: "monthlyPrice", label: "מחיר לחודש", preset: "price" },
  { id: "months", label: "חודשים" },
  {
    id: "feeBalanceDiff",
    label: "דמי רישום",
    preset: "price",
    defaultValue: 0,
  },
  {
    id: "oneChargeDiff",
    label: "פעילות",
    preset: "price",
    defaultValue: 0,
  },
];

const filtersStructure = [
  { type: "date" },
  { preset: "teams" },
  { preset: "season" },
];

export default function PaymentsDistributionOverMonthsReport() {
  const user = useSelector((state) => state.auth.user);
  const seasons = useSelector((state) => state.general.seasons);

  const seasonsDic = useMemo(
    () =>
      Object.fromEntries(seasons.map((s) => [s._id, displaySeason(s.year)])),
    [seasons]
  );

  const [initialFiltersState] = useState({
    date: { from: moment().startOf("day"), to: moment().endOf("day") },
    teams: { all: true },
    season: "all",
  });
  const [columns, setColumns] = useState([...baseColumns(user)]);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/reports/paymentsDistribution",
      title: "Payments Distribution Report",
    });
  }, []);

  const prepareData = ({
    payments,
    firstMonth: firstMonthRaw,
    lastMonth: lastMonthRaw,
  }) => {
    const balanceColumns = [];
    const lastMonth = moment(lastMonthRaw);
    const firstMonth = moment(firstMonthRaw);

    while (firstMonth.isSameOrBefore(lastMonth)) {
      balanceColumns.push({
        id: firstMonth.format("YYMMDD"),
        label: firstMonth.format("DD/MM/YY"),
        balance: true,
        preset: "price",
        baseRenderer: true,
      });
      firstMonth.add(1, "month");
    }
    setColumns([...baseColumns(user), ...balanceColumns]);

    return payments;
  };

  const baseRenderer = (columnId, log, column) => {
    if (column.balance) {
      return columnId in log.balanceDiff ? log.balanceDiff[columnId] : "-";
    }

    switch (columnId) {
      case "transaction":
        return {
          to: `../payments/transactions/${log.transaction._id}`,
          label: log.transaction.receiptNumber,
        };

      case "clubPlayer":
        return {
          to: `../players/${log.clubPlayer._id}`,
          label: log.clubPlayer.name,
        };

      case "team":
        return {
          to: `../teams/${log.team._id}`,
          label: log.team.name,
        };

      case "season":
        return seasonsDic[log.season];

      default:
        return log[columnId];
    }
  };

  return (
    <Report
      title="התפלגות הכנסות מפעילות"
      url="/reports/payments/paymentDistributionOverMonths?"
      prepareData={prepareData}
      initialFiltersState={initialFiltersState}
      filtersStructure={filtersStructure}
      renderCell={renderCell(columns, false, baseRenderer)}
      sortValueCell={sortValueCell(columns, baseRenderer)}
      columns={columns}
      exportFileName="התפלגות הכנסות מפעילות"
      renderCellExport={renderCell(columns, true, baseRenderer)}
      defaultSortBy={0}
      defaultSortDirection={"desc"}
    />
  );
}
