import React, { useState } from "react";
import {
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Badge,
  DialogContent,
  Alert,
  AlertTitle,
  useMediaQuery,
  DialogActions,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import moment from "moment";
import PlayIcon from "@mui/icons-material/PlayArrow";

import { weekStatusStatuses } from "../../utils/dictionaries";
import WeekStatusDialog from "./WeekStatusDialog";
import Dialog from "../../components/Dialog";
import SnackbarNg from "../../components/base/SnackbarNg";
import ButtonSx from "../../components/base/ButtonSx";

import IconButtonTooltip from "../../components/base/IconButtonTooltip";

const StyledBadge = styled(Badge)({
  "& .MuiBadge-badge": {
    top: 2,
    right: 5,
  },
});

export default function WeekStatus(props) {
  const [week, setWeek] = useState(false);
  const [isWeekDialogOpen, setIsWeekDialogOpen] = useState(false);
  const [isWeekStatusDialogOpen, setIsWeekStatusDialogOpen] = useState(false);
  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false); // if open value = weekstatus._id otherwise false
  const isSmUp = useMediaQuery((t) => t.breakpoints.up("sm"), { noSsr: true });

  const weeks = props.weeks;

  const handleClickTooltipToggle = (newStatus) => () => {
    setIsWeekStatusDialogOpen(newStatus);
    if (!newStatus) {
      props.onClose && props.onClose();
    }
  };

  const nextWeekRelevant = () =>
    weeks.nextWeek.status !== "ready" &&
    moment().add(4, "days").isAfter(weeks.nextWeek.startDate);

  const showAlert =
    weeks && (weeks.currentWeek.status !== "ready" || nextWeekRelevant());

  // React.useEffect(() => {
  //   if (weeks && !props.mobile) {
  //     if (weeks.currentWeek.status !== "ready" || nextWeekRelevant()) {
  //       // setIsWeekStatusDialogOpen(true);
  //     }
  //   }
  // }, [weeks]);

  const renderWeekDates = (week) => {
    const format = "DD/MM";
    return `${week.startDate.format(format)} - 
    ${moment(week.startDate).endOf("week").format(format)}`;
  };

  const handleWeekSelect = (week) => () => {
    handleClickTooltipToggle(false)();
    setWeek(week);
    setIsWeekDialogOpen(true);
  };

  const renderAlert = () => {
    if (!weeks) {
      return <div />;
    }

    return (
      <Dialog
        fullWidth
        maxWidth="sm"
        onClose={handleClickTooltipToggle(false)}
        open={isWeekStatusDialogOpen}
        title="סטטוס שבועי"
        closeButton
        fullScreen={!isSmUp}
      >
        <DialogContent dividers>
          <List>
            <ListItem dense sx={{ ...styles.weekItem, mb: 2 }}>
              <ListItemText
                primary={
                  <span>
                    שבוע נוכחי -{" "}
                    <b>{weekStatusStatuses[weeks.currentWeek.status]}</b>
                  </span>
                }
                secondary={renderWeekDates(weeks.currentWeek)}
              />
              {weeks.currentWeek.status === "unready" && (
                <ListItemSecondaryAction>
                  <Button
                    variant="contained"
                    onClick={handleWeekSelect(weeks.currentWeek)}
                  >
                    פתיחת שבוע
                  </Button>
                </ListItemSecondaryAction>
              )}
            </ListItem>

            <ListItem dense sx={styles.weekItem}>
              <ListItemText
                primary={
                  <span>
                    שבוע הבא -{" "}
                    <b>{weekStatusStatuses[weeks.nextWeek.status]}</b>
                  </span>
                }
                secondary={renderWeekDates(weeks.nextWeek)}
              />
              {weeks.nextWeek.status === "unready" && (
                <ListItemSecondaryAction>
                  <Button
                    variant="contained"
                    onClick={handleWeekSelect(weeks.nextWeek)}
                  >
                    פתיחת שבוע
                  </Button>
                </ListItemSecondaryAction>
              )}
            </ListItem>
          </List>
          <Alert severity="info" sx={{ mt: 2 }}>
            <AlertTitle>מה המשמעות של פתיחת השבוע?</AlertTitle>
            <p>
              פתיחת שבוע מודיעה למאמנים, להורים ולספורטאים שלוח האירועים לשבוע
              הבא נפתח ואושר.
            </p>
            <p>
              <b>לפני פתיחת השבוע: </b>
              המאמנים רואים את לוח האירועים עם הודעה שלוח האירועים עבור השבוע
              אינו סופי ועלול להשתנות. ההורים והספורטאים כלל לא רואים את ארועי
              השבוע עד פתיחתו.
            </p>
            <p>
              <b>לאחר פתיחת השבוע: </b>
              כל עדכון, יצירה או ביטול של אירועים ישאל אתכם בעת השינוי האם
              ברצונכם לעדכן את המשתתפים באירוע ע״י שליחת התראה באפליקציה. אם
              תבחרו שלא לעדכן, המשתתפים עדיין יוכלו לראות את השינוי אך לא יקבלו
              התראה על כך.
            </p>
          </Alert>
        </DialogContent>
        <DialogActions>
          <ButtonSx onClick={handleClickTooltipToggle(false)}>סגירה</ButtonSx>
        </DialogActions>
      </Dialog>
    );
  };

  const renderStatus = () => {
    return (
      <>
        {props.mobile ? (
          <>
            <Button
              onClick={handleClickTooltipToggle(true)} // only open
              variant="text"
              startIcon={
                <StyledBadge color="info" variant="dot" invisible={!showAlert}>
                  <PlayIcon />
                </StyledBadge>
              }
            >
              סטטוס שבועי
            </Button>
          </>
        ) : (
          <>
            <IconButtonTooltip
              title="סטטוס שבועי"
              size="small"
              iconButtonProps={{ size: "small" }}
              onClick={handleClickTooltipToggle(true)} // only open
            >
              <PlayIcon
                sx={{ color: showAlert ? "red" : undefined }}
                fontSize={showAlert ? "large" : undefined}
              />
            </IconButtonTooltip>
          </>
        )}
        {renderAlert()}
      </>
    );
  };

  const handleOpenComplete = (weekStatusId) => {
    setIsWeekDialogOpen(false);
    setIsSnackBarOpen(weekStatusId);
    props.onWeekApprove();
  };

  return (
    <div>
      <WeekStatusDialog
        open={isWeekDialogOpen}
        onClose={() => {
          setIsWeekDialogOpen(false);
          setWeek(null);
        }}
        week={week}
        resourceDescription={props.resourceDescription}
        onNavigate={props.onNavigate}
        onUpdateCompleted={props.onUpdateCompleted}
        shouldUpdate={props.shouldUpdate}
        onComplete={handleOpenComplete}
      />
      {renderStatus()}
      <SnackbarNg
        snackKey={isSnackBarOpen}
        open={isSnackBarOpen !== false}
        onClose={() => setIsSnackBarOpen(false)}
        message="השבוע נפתח בהצלחה"
      />
    </div>
  );
}

const styles = {
  weekItem: { border: "1px solid #EEE", borderRadius: 2 },
};
