import React from "react";
import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers/DatePicker";

// Mui DatePicker with format and rtl customizations
export default function DatePicker({ textFieldProps, slotProps, ...props }) {
  return (
    <MuiDatePicker
      format="DD/MM/YYYY"
      slotProps={{
        textField: {
          variant: "standard",
          readOnly: true,
          margin: "dense",
          fullWidth: true,
          placeholder: " ",
          ...textFieldProps,
        },
        popper: { dir: "rtl" },
        dialog: { dir: "rtl" },
        toolbar: {
          hidden: true,
        },
        ...slotProps,
      }}
      {...props}
    />
  );
}
