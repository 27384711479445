import _ from "lodash";
import moment from "moment";
import { handleSubscriptionFieldsChanged } from "../../../../utils/subscriptionHelpers";

export const validate = (subscription) => {
  let isValid = true;
  const errors = {};

  if (subscription.teamId === "") {
    errors.teamId = "שדה חובה";
    isValid = false;
  }

  errors.monthlyPrices = [];
  errors.monthlyPricesError = null;

  subscription.monthlyPrices.forEach((monthlyPrice) => {
    if (monthlyPrice.price < 0 || monthlyPrice.priceStr === "") {
      errors.monthlyPrices.push(true);
      isValid = false;
      errors.monthlyPricesError = "יש להזין מחיר תקין";
    } else {
      errors.monthlyPrices.push(false);
    }
  });

  errors.fees = [];

  subscription.fees.forEach((fee) => {
    if (fee.priceStr === "" || fee.price < 0) {
      isValid = false;
      errors.fees.push("יש להזין מחיר תקין");
    } else {
      errors.fees.push("");
    }
  });

  return { errors, isValid };
};

export const prepareLocalSubscription = (orgSub, product) => {
  const sub = {
    // Do NOT add objects! they need to be cloned
    ..._.pick(orgSub, ["price", "months", "hasFees"]),
    monthlyPrices: orgSub.balance
      .filter((b) => !b.isFee)
      .map((b) => ({
        month: moment(b.dueDate),
        oneCharge: b.oneCharge,
        price: b.amount,
        priceStr: b.amount.toString(),
      })),
    startDate: moment(orgSub.startDate),
    endDate: moment(orgSub.endDate),
    discountReason: orgSub.discountReason || "",
    product,
    pricingId: orgSub.pricing._id,
    errors: { fees: [] },
  };
  sub.fees = orgSub.feeProducts.map((fp) => ({ ...fp, priceStr: fp.price }));

  return sub;
};

export const prepareDataForSubmit = (subscription) => {
  const data = _.pick(subscription, ["discountReason", "price", "pricingId"]);
  data.startDate = subscription.startDate.format("YYYY-MM-DD");
  data.monthlyPrices =
    ["fixedPrice", "daily"].indexOf(
      subscription.product.subscriptionDetails.subscriptionType
    ) > -1
      ? subscription.monthlyPrices.map((p) => ({
          price: p.price,
          oneCharge: true,
        }))
      : subscription.monthlyPrices.map((p) => ({
          month: moment(p.month).format("YYYY-MM-DD"),
          price: p.price,
        }));
  data.feePrices = subscription.fees.map((fee) => ({
    _id: fee.feeProductId,
    price: fee.price,
  }));

  return data;
};

export const handleFeePriceChanged = (index, subscription, priceStr, price) => {
  const fees = [...subscription.fees];
  fees[index].priceStr = priceStr;
  fees[index].price = price;

  return handleSubscriptionFieldsChanged({ fees }, subscription);
};

export const handlePriceChanged = (subscription, month, priceStr, price) => {
  let newPrices;
  if (subscription.monthlyPrices[0].oneCharge) {
    newPrices = [...subscription.monthlyPrices];
    newPrices[0].price = price;
    newPrices[0].priceStr = priceStr;
  } else {
    newPrices = subscription.monthlyPrices.map((monthlyPrice) => {
      if (month.isSame(monthlyPrice.month)) {
        return { month, price, priceStr };
      } else {
        return { ...monthlyPrice };
      }
    });
  }

  return handleSubscriptionFieldsChanged(
    { monthlyPrices: newPrices },
    subscription
  );
};

// updates: {[month]: price}
export const handleSettingsNewPrice = (subscription, priceStr, price) => {
  const newPrices = subscription.monthlyPrices.map((monthlyPrice) => ({
    ...monthlyPrice,
    price,
    priceStr,
  }));

  return handleSubscriptionFieldsChanged(
    { monthlyPrices: newPrices },
    subscription
  );
};
