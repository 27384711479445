import React from "react";
import {
  Grid,
  FormControlLabel,
  Radio,
  Collapse,
  Divider,
  Box,
  Paper,
} from "@mui/material";

import NewPlayerForm from "./NewPlayerForm";
import ExistingPlayerForm from "./ExistingPlayerForm";
import ExistingPlayerFoundDialog from "./ExistingPlayerFoundDialog";
import GuestPlayerForm from "./GuestPlayerForm";
import StepTitle from "../StepTitle";

function Section(props) {
  return (
    <Paper variant="outlined">
      <Box sx={{ p: 2 }}>
        <FormControlLabel
          control={
            <Radio
              checked={props.state.viewType === props.type}
              onChange={() => props.onChange({ viewType: props.type })}
            />
          }
          label={props.label}
        />
      </Box>
      <Collapse in={props.state.viewType === props.type}>
        <Divider />
        <Box sx={{ p: { xs: 1, sm: 2 } }}>{props.children}</Box>
      </Collapse>
    </Paper>
  );
}

export default function PlayerForm(props) {
  return (
    <>
      <StepTitle title="פרטי הספורטאי" purchaseType={props.purchaseType} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Section
            type="existing"
            label="ספורטאי קיים"
            state={props.state}
            onChange={props.onChange}
          >
            <ExistingPlayerForm
              state={props.state}
              onClubPlayerSelected={props.onExistingPlayerSelected}
              onSearch={props.onSearch}
              existingPlayerSearchForm={props.state.existingPlayerSearchForm}
              onChange={props.onChange}
              isLoadingSiblings={props.isLoadingSiblings}
            />
          </Section>
        </Grid>
        <Grid item xs={12}>
          <Section
            type="new"
            label="ספורטאי חדש"
            state={props.state}
            onChange={props.onChange}
          >
            <NewPlayerForm
              state={props.state}
              onChange={props.onChange}
              onPassportNumberChanged={props.onPassportNumberChanged}
              remoteClubConfig={props.remoteClubConfig}
            />
          </Section>
        </Grid>
        {props.allowGuestMode && (
          <Grid item xs={12}>
            <Section
              type="guest"
              label="אורח"
              state={props.state}
              onChange={props.onChange}
            >
              <GuestPlayerForm state={props.state} onChange={props.onChange} />
            </Section>
          </Grid>
        )}
      </Grid>
      <ExistingPlayerFoundDialog
        open={props.state.showPlayerAlreadyExistDialog}
        // onClose can be called by the Dialog when closing it by clicking outside of the popup. so selected can be an event as well (that's why we need to check if selected  === true)
        onClose={(selected) => {
          if (selected === true) {
            props.onChange(
              {
                viewType: "existing", // when coming back to this page go to existing
                showPlayerAlreadyExistDialog: false,
              },
              // making sure onExistingPlayerSelected happens after onChange
              () => {
                props.onExistingPlayerSelected(
                  props.state.searchClubPlayersResults[0]
                );
              }
            );
          } else {
            props.onChange({
              showPlayerAlreadyExistDialog: false,
            });
          }
        }}
        id={props.state.id}
        player={(props.state.searchClubPlayersResults || [])[0]}
      />
    </>
  );
}
