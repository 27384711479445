import React from "react";
import moment from "moment";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
} from "@mui/material";
import _ from "lodash";
import { Link as RouterLink } from "react-router-dom";

import { displayPrice } from "../../utils/helpers";
import TlPaper from "../../components/TlPaper";
import InfoRow from "../../components/InfoRows/InfoRow";

export default function Purchases(props) {
  return (
    <TlPaper title="עסקאות" titleBackground bodySx={{ p: 0, pr: 0, pl: 0 }}>
      <List dense disablePadding>
        {props.purchases.map((purchase) => {
          const transaction = props.transactionsMap.get(purchase._id);

          return (
            <React.Fragment key={purchase._id}>
              <ListItem divider disablePadding>
                <ListItemButton
                  component={RouterLink}
                  to={`../payments/transactions/${transaction._id}`}
                >
                  <ListItemText
                    primary={
                      <strong>
                        {_.join(
                          purchase.products.map((p) => p.product.title),
                          ", "
                        )}
                      </strong>
                    }
                    secondary={
                      <Stack direction="column">
                        <InfoRow
                          title="סכום"
                          value={displayPrice(transaction.amount)}
                        />
                        <InfoRow
                          title="תאריך"
                          value={moment(transaction.createdAt).format("lll")}
                        />
                        <InfoRow
                          title="קבלה"
                          value={transaction.receiptNumber}
                        />
                      </Stack>
                    }
                  />
                </ListItemButton>
              </ListItem>
            </React.Fragment>
          );
        })}
      </List>
    </TlPaper>
  );
}
