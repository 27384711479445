import React, { useMemo } from "react";
import { Alert, AlertTitle, Box, Grid } from "@mui/material";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import _ from "lodash";

import InfoRow from "../../../../InfoRows/InfoRow";
import {
  displayDate,
  displayPrice,
  roundPrice,
} from "../../../../../utils/helpers";
import SubscriptionHeaderItem from "../SubscriptionHeaderItem";

export default function ChangeTeamSummary({
  oldSubscription,
  newSubscription,
  originalSubscription,
}) {
  const newPricing = useMemo(
    () =>
      newSubscription.product.subscriptionDetails.pricings.find(
        (p) => p._id === newSubscription.pricingId
      ),
    [newSubscription]
  );

  const oldSubCost = roundPrice(
    _.sumBy(oldSubscription.fees, "price") + oldSubscription.nonFeesPrice
  );
  const oldSubPaid = Math.min(oldSubCost, originalSubscription.paid);
  const oldSubDebt = roundPrice(oldSubCost - oldSubPaid);

  const newSubCost = roundPrice(
    _.sumBy(newSubscription.fees, "price") + newSubscription.price
  );
  const newSubPaid = roundPrice(originalSubscription.paid - oldSubPaid);
  const newSubDebt = roundPrice(newSubCost - newSubPaid);

  return (
    <div>
      <Box sx={styles.title}>סיכום מעבר הקבוצה</Box>
      <Alert severity="info" sx={{ mb: 2 }}>
        <AlertTitle>
          עבור הקבוצה הנוכחית בוצעו תשלומים בסך{" "}
          <b>{displayPrice(originalSubscription.paid)}</b>
        </AlertTitle>
        {newSubPaid !== 0 && (
          <span>
            המערכת תעביר {displayPrice(newSubPaid)} מהסכום הנ״ל לקבוצה החדשה
          </span>
        )}
      </Alert>
      <Grid container>
        <Grid item xs={12} sx={styles.teamContainer}>
          <div>
            <InfoRow
              titleSx={styles.infoRowTitle}
              title="קבוצה נוכחית"
              value={`${oldSubscription.team.name}${
                originalSubscription.pricing?.title
                  ? ` / ${originalSubscription.pricing.title}`
                  : ""
              }`}
            />
            <InfoRow
              titleSx={styles.infoRowTitle}
              title="תקופת הפעילות"
              value={`${displayDate(
                oldSubscription.cancelledAt
              )} - ${displayDate(oldSubscription.startDate)}`}
            />

            <Grid item container spacing={2} sx={{ mt: 1 }}>
              <Grid item xs={6} md={4} key="cost">
                <SubscriptionHeaderItem
                  title="עלות כוללת"
                  value={displayPrice(oldSubCost)}
                />
              </Grid>
              <Grid item xs={6} md={4} key="paid">
                <SubscriptionHeaderItem
                  title="שולם"
                  value={displayPrice(oldSubPaid)}
                />
              </Grid>
              <Grid item xs={6} md={4} key="debt">
                <SubscriptionHeaderItem
                  title={oldSubDebt < 0 ? "יתרת זכות" : "יתרת חוב"}
                  value={displayPrice(Math.abs(oldSubDebt))}
                />
              </Grid>
            </Grid>
          </div>
        </Grid>

        <Grid
          item
          container
          xs={12}
          justifyContent="center"
          alignItems="center"
        >
          <ArrowDownwardIcon sx={{ my: 2 }} />
        </Grid>
        <Grid item xs={12} sx={styles.teamContainer}>
          <div>
            <InfoRow
              titleSx={styles.infoRowTitle}
              title="קבוצה חדשה"
              value={`${newSubscription.team.name}${
                newPricing.title ? ` / ${newPricing.title}` : ""
              }`}
            />
            <InfoRow
              titleSx={styles.infoRowTitle}
              title="תקופת הפעילות"
              value={`${displayDate(newSubscription.endDate)} -
                ${displayDate(newSubscription.startDate)}`}
            />

            <Grid item container spacing={2} sx={{ mt: 1 }}>
              <Grid item xs={6} md={4} key="cost">
                <SubscriptionHeaderItem
                  title="עלות כוללת"
                  value={displayPrice(newSubCost)}
                />
              </Grid>
              <Grid item xs={6} md={4} key="paid">
                <SubscriptionHeaderItem
                  title="שולם"
                  value={displayPrice(newSubPaid)}
                />
              </Grid>
              <Grid item xs={6} md={4} key="debt">
                <SubscriptionHeaderItem
                  title={newSubDebt < 0 ? "יתרת זכות" : "יתרת חוב"}
                  value={displayPrice(Math.abs(newSubDebt))}
                />
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

const styles = {
  title: {
    width: "100%",
    textAlign: "center",
    fontWeight: "bold",
    fontSize: 16,
    marginBottom: "15px",
  },
  infoRowTitle: {
    width: 100,
  },
  teamContainer: {
    border: "1px solid #ABABAB",
    borderRadius: "5px",
    display: "flex",
    flexDirection: "column",
    flex: 1,
    padding: "10px",
  },
};
