import React from "react";
import {
  Box,
  DialogContent,
  DialogActions,
  Grid,
  CircularProgress,
  FormControl,
  FormGroup,
  FormControlLabel,
  Switch,
  FormHelperText,
} from "@mui/material";
import _ from "lodash";
import validator from "validator";
import InfoIcon from "@mui/icons-material/InfoOutlined";

import axios from "../../utils/axios";
import FormTextField from "../../components/base/FormTextField";
import MenuItem from "../../components/base/MenuItem";
import { getAxiosErrorData } from "../../utils/helpers";
import Dialog from "../../components/Dialog";
import ToolTip from "../../components/base/ToolTip";
import withIsXs from "../../components/routing/withIsXs";
import ButtonSx from "../../components/base/ButtonSx";
import Paper2Sx from "../../components/Paper2Sx";

const styles = {
  subPermissions: {
    ml: 3,
  },
};

function PermissionLabel(props) {
  return (
    <ToolTip arrow placement="left" title={props.title}>
      <span style={{ display: "flex", alignItems: "center" }}>
        {props.children}
        <InfoIcon style={{ marginRight: 5 }} />
      </span>
    </ToolTip>
  );
}

class EditUserDialog extends React.Component {
  state = {
    isLoading: false,
    instantValidate: false,
    errors: {},
    user: null,
  };

  componentDidUpdate = async (prevProps) => {
    if (!prevProps.open && this.props.open) {
      let user;
      if (this.props.user) {
        user = {
          ..._.pick(this.props.user, ["name", "email", "level", "permissions"]),
        };
      } else {
        user = {
          name: "",
          email: "",
          level: "",
          permissions: {
            calendar: false,
            calendarAdmin: false,
            hr: false,
            hrCoachTeamAdmin: false,
            hrPlayerParentAdmin: false,
            registrationAdmin: false,
            registrationReports: false,
            paymentsReports: false,
            storeAdmin: false,
          },
        };
      }
      this.setState({
        user,
        isLoading: false,
      });
    }
  };

  handleFieldChange = async (updates) => {
    const user = {
      ...this.state.user,
      ...updates,
    };

    if (user.level === "admin") {
      user.permissions = {
        calendar: false,
        calendarAdmin: false,
        hr: false,
        hrCoachTeamAdmin: false,
        hrPlayerParentAdmin: false,
        registrationAdmin: false,
        registrationReports: false,
        paymentsReports: false,
        storeAdmin: false,
      };
    }

    await this.setState({ user });
    if (this.state.instantValidate) {
      this.validate();
    }
  };

  validate = () => {
    let isValid = true;
    const errors = {};

    if (this.state.user.name === "") {
      errors.name = "שדה חובה";
      isValid = false;
    } else if (
      this.state.user.name.length < 5 ||
      !this.state.user.name.trim().match(/.+\s.+/)
    ) {
      errors.name = "יש להזין שם מלא";
      isValid = false;
    }

    if (this.state.user.email === "") {
      errors.email = "שדה חובה";
      isValid = false;
    } else if (!validator.isEmail(this.state.user.email)) {
      errors.email = "שדה לא תקין";
      isValid = false;
    }

    if (this.state.user.level === "") {
      errors.level = "שדה חובה";
      isValid = false;
    }

    const { permissions } = this.state.user;

    if (
      this.state.user.level === "user" &&
      !permissions.calendar &&
      !permissions.hr &&
      !permissions.paymentsReports &&
      !permissions.storeAdmin
    ) {
      errors.permissions = "חובה לבחור לפחות הרשאה אחת";
      isValid = false;
    }

    this.setState({ errors, instantValidate: true });
    return isValid;
  };

  handleSave = async () => {
    const valid = this.validate();
    if (valid && !this.state.isLoading) {
      const data = {
        name: this.state.user.name.trim(),
        email: this.state.user.email.trim(),
        level: this.state.user.level,
        permissions: this.state.user.permissions,
      };
      this.setState({ isLoading: true });
      try {
        let userId;
        if (this.props.user) {
          userId = this.props.user._id;
          await axios.put(`/managers/${userId}`, data);
        } else {
          const res = await axios.post(`/managers`, data);
          userId = res.data._id;
        }

        this.props.onComplete(userId, this.props.user ? "edit" : "create");
      } catch (error) {
        const errorData = getAxiosErrorData(error);
        if (((errorData || {}).errors || {}).email === "notUnique") {
          this.setState((prevState) => {
            return {
              errors: {
                ...prevState.errors,
                email: "כתובת האימייל קיימת כבר במערכת",
              },
              isLoading: false,
            };
          });
        }
      }
    }
  };

  handlePermissionToggle = (permission) => () => {
    const subPermissions = {};
    if (permission === "hr" && this.state.user.permissions.hr) {
      subPermissions.hrPlayerParentAdmin = false;
      subPermissions.hrCoachTeamAdmin = false;
      subPermissions.registrationAdmin = false;
      subPermissions.registrationReports = false;
    }

    if (permission === "calendar" && this.state.user.permissions.calendar) {
      subPermissions.calendarAdmin = false;
    }

    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        permissions: {
          ...prevState.user.permissions,
          [permission]: !prevState.user.permissions[permission],
          ...subPermissions,
        },
      },
    }));
  };

  renderPermissions = () => {
    return (
      <Paper2Sx externalTitle="הרשאות">
        <Grid item xs={12}>
          {this.state.errors.permissions && (
            <FormHelperText error>
              {this.state.errors.permissions}
            </FormHelperText>
          )}
          <FormControl variant="standard" component="fieldset">
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={this.state.user.permissions.calendar}
                    onChange={this.handlePermissionToggle("calendar")}
                    name="calendar"
                  />
                }
                label="צפייה בלוח אירועים"
              />
              <Box sx={styles.subPermissions}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={this.state.user.permissions.calendarAdmin}
                      onChange={this.handlePermissionToggle("calendarAdmin")}
                      name="calendarAdmin"
                      disabled={!this.state.user.permissions.calendar}
                    />
                  }
                  label={
                    <PermissionLabel
                      title={
                        <span>
                          <ul>
                            <li>יצירה, עריכה ומחיקה של אירועים</li>
                            <li>ניהול מגרשים</li>
                            <li>שליחת התראות</li>
                          </ul>
                        </span>
                      }
                    >
                      ניהול אירועים
                    </PermissionLabel>
                  }
                />
              </Box>
              <FormControlLabel
                control={
                  <Switch
                    checked={this.state.user.permissions.hr}
                    onChange={this.handlePermissionToggle("hr")}
                    name="hr"
                  />
                }
                label="צפייה בקבוצות, מאמנים, ספורטאים, הורים, נוכחות ועסקאות בודדות"
              />
              <Box sx={styles.subPermissions}>
                <div>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={this.state.user.permissions.hrCoachTeamAdmin}
                        onChange={this.handlePermissionToggle(
                          "hrCoachTeamAdmin"
                        )}
                        name="hrCoachTeamAdmin"
                        disabled={!this.state.user.permissions.hr}
                      />
                    }
                    label="ניהול קבוצות ומאמנים"
                  />
                </div>
                <div>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={
                          this.state.user.permissions.hrPlayerParentAdmin
                        }
                        onChange={this.handlePermissionToggle(
                          "hrPlayerParentAdmin"
                        )}
                        name="hrPlayerParentAdmin"
                        disabled={!this.state.user.permissions.hr}
                      />
                    }
                    label="עדכון פרטי ספורטאים והורים"
                  />
                </div>
                <div>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={this.state.user.permissions.registrationAdmin}
                        onChange={this.handlePermissionToggle(
                          "registrationAdmin"
                        )}
                        name="registrationAdmin"
                        disabled={!this.state.user.permissions.hr}
                      />
                    }
                    label={
                      <PermissionLabel
                        title={
                          <span>
                            <ul>
                              <li>ביצוע תשלומים והחזרים</li>
                              <li>ביטול תשלומים</li>
                              <li>ביצוע רכישות בחנות</li>
                              <li>רישום שחקנים</li>
                              <li>עדכון פרטי רישום</li>
                              <li>הפסקת פעילות ומעבר קבוצה</li>
                              <li>בקשות מיוחדות</li>
                            </ul>
                          </span>
                        }
                      >
                        ביצוע תשלומים וניהול רישום
                      </PermissionLabel>
                    }
                  />
                </div>
                <div>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={
                          this.state.user.permissions.registrationReports
                        }
                        onChange={this.handlePermissionToggle(
                          "registrationReports"
                        )}
                        name="registrationReports"
                        disabled={!this.state.user.permissions.hr}
                      />
                    }
                    label={
                      <PermissionLabel
                        title={
                          <span>
                            <ul>
                              <li>דוח פעילות</li>
                              <li>דוח מוצרים</li>
                              <li>דוח יתרות זכות וחובה</li>
                              <li>דוח פורשים ומעברי קבוצה</li>
                            </ul>
                          </span>
                        }
                      >
                        דוחות רישום
                      </PermissionLabel>
                    }
                  />
                </div>
              </Box>

              <FormControlLabel
                control={
                  <Switch
                    checked={this.state.user.permissions.paymentsReports}
                    onChange={this.handlePermissionToggle("paymentsReports")}
                    name="paymentsReports"
                  />
                }
                label={
                  <PermissionLabel
                    title={
                      <span>
                        <ul>
                          <li>דוח תשלומים</li>
                          <li>דוח מסמכים חשבונאיים</li>
                          <li>דוח הכנסות מפעילות</li>
                          <li>דוח פירעון תשלומים</li>
                          <li>צ׳קים</li>
                          <li>הפקדות</li>
                        </ul>
                      </span>
                    }
                  >
                    דוחות כספיים
                  </PermissionLabel>
                }
              />

              <FormControlLabel
                control={
                  <Switch
                    checked={this.state.user.permissions.storeAdmin}
                    onChange={this.handlePermissionToggle("storeAdmin")}
                    name="storeAdmin"
                  />
                }
                label="ניהול מוצרי החנות"
              />
            </FormGroup>
          </FormControl>
        </Grid>
      </Paper2Sx>
    );
  };

  renderContent = () => {
    const defaultTextFieldParams = {
      form: this.state.user,
      onChange: this.handleFieldChange,
      errors: this.state.errors,
    };

    return (
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <FormTextField
            label="שם מלא"
            field="name"
            {...defaultTextFieldParams}
          />
        </Grid>
        <Grid item xs={12}>
          <FormTextField
            label="אי-מייל"
            field="email"
            {...defaultTextFieldParams}
            textFieldProps={
              this.props.user
                ? { disabled: true, helperText: "לא ניתן לערוך שדה זה" }
                : {}
            }
          />
        </Grid>
        <Grid item xs={12}>
          <FormTextField
            label="סוג משתמש"
            field="level"
            textFieldProps={{ select: true }}
            {...defaultTextFieldParams}
          >
            <MenuItem value="admin">אדמין</MenuItem>
            <MenuItem value="user">משתמש</MenuItem>
          </FormTextField>
        </Grid>

        {this.state.user.level === "user" && (
          <>
            <Grid item xs={12} style={{ height: 20 }} />
            <Grid item xs={12}>
              {this.renderPermissions()}
            </Grid>
          </>
        )}
      </Grid>
    );
  };

  render() {
    return (
      <>
        <Dialog
          fullWidth
          maxWidth="sm"
          onClose={this.props.onClose}
          open={this.props.open}
          title={this.props.user ? "עריכת משתמש" : "הוספת משתמש"}
          fullScreen={this.props.isXs}
        >
          <DialogContent dividers>
            {this.state.user ? this.renderContent() : <CircularProgress />}
          </DialogContent>
          <DialogActions>
            <ButtonSx
              onClick={this.props.onClose}
              disabled={this.state.isLoading}
            >
              ביטול
            </ButtonSx>
            <ButtonSx
              onClick={this.handleSave}
              isLoading={this.state.isLoading}
            >
              אישור
            </ButtonSx>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

export default withIsXs(EditUserDialog);
