import React from "react";
import { Box, Typography } from "@mui/material";

export default function SubscriptionHeaderItem({
  value,
  title,
  bigValueFont,
  sx,
}) {
  return (
    <Box sx={{ ...styles.itemContainer, ...sx }}>
      <div style={{ textAlign: "center" }}>
        <Typography variant={bigValueFont ? "h5" : "h6"}>{value}</Typography>
      </div>
      <div>
        <Typography variant="body1">{title}</Typography>
      </div>
    </Box>
  );
}

const styles = {
  itemContainer: {
    height: 80,
    border: "1px solid #EEE",
    borderRadius: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    flex: 1,
    backgroundColor: "#FFF",
    borderBottomColor: (theme) => theme.appBar.backgroundColor,
    borderBottomWidth: "3px",
  },
};
