import React, { Component } from "react";
import moment from "moment";

import EventDialog from "./EventDialog";

//TODO: get rid of this => not needed anymore
class EventDialogWrapper extends Component {
  constructor(props) {
    super(props);

    const times = [];
    for (let hour = 0; hour < 24; hour++) {
      for (let minunts = 0; minunts < 60; minunts += 15) {
        times.push(moment(`${hour}:${minunts}`, "H:m").format("HH:mm"));
      }
    }

    this.state = {
      times,
    };
  }

  render() {
    return (
      <EventDialog
        key={this.props.event.id}
        times={this.state.times}
        teams={this.props.teams}
        {...this.props}
      />
    );
  }
}

export default EventDialogWrapper;
