import React from "react";
import { DialogActions, DialogContent, DialogContentText } from "@mui/material";

import Dialog from "../../components/Dialog";
import ButtonSx from "../../components/base/ButtonSx";

export default function WeekStatusCollisionsDialog(props) {
  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      maxWidth="sm"
      fullWidth
      title="ישנן התנגשויות אירועים לשבוע זה"
    >
      <DialogContent dividers>
        <DialogContentText>
          האם ברצונך לפתוח את השבוע למרות ההתנגשויות?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <ButtonSx onClick={props.onClose} color="primary" autoFocus>
          חזרה
        </ButtonSx>
        <ButtonSx debounce onClick={props.onComplete} color="primary">
          פתיחה
        </ButtonSx>
      </DialogActions>
    </Dialog>
  );
}
