import React from "react";
import { IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { connect } from "react-redux";
import SortIcon from "@mui/icons-material/Sort";
import _ from "lodash";

import axios from "../../../utils/axios";
import { displayPrice } from "../../../utils/helpers";
import { productStatuses } from "../../../utils/dictionaries";
import Table from "../../../components/table/Table";
import FiltersBar from "../../../components/filters/FiltersBar";
import EditProductDialog from "./EditProductDialog";
import {
  getFiltersFromUrl,
  prepareFilters,
} from "../../../components/filters/prepareFilters";
import oldClubConfig from "../../../config/clubs";
import IconButtonTooltip from "../../../components/base/IconButtonTooltip";
import ProductSortDialog from "./ProductsSortDialog";
import SnackbarNg from "../../../components/base/SnackbarNg";
import withRouter from "../../../components/routing/withRouter";
import TlPaper from "../../../components/TlPaper";
import { AdvancedImage } from "@cloudinary/react";
import { cloudinaryImage } from "../../../utils/cloudinary";

const baseColumns = [
  { id: "actions", label: "", alignment: "center" },
  { id: "title", label: "מוצר" },
  { id: "status", label: "סטטוס" },
  { id: "price", label: "מחיר", alignment: "right" },
  { id: "accountingExportNumber", label: "מפתח חשבון" },
  { id: "priority", label: "מיקום בחנות" },
];

const filtersStructure = [
  {
    name: "active",
    type: "select",
    options: { true: "פעיל", false: "לא פעיל" },
    emptyLabel: "כל הסטטוסים",
    label: "סטטוס",
  },
];

class Products extends React.Component {
  state = {
    isLoading: true,
    products: [],
    activeProducts: [],
    filters: { active: "all" },
    currentProduct: null,
    columns: [...baseColumns],
    isSortDialogOpen: false,
    isSaving: false,
    snackbar: {},
  };

  componentDidMount = async () => {
    const requireAccountingExportNumber = (
      oldClubConfig[this.props.selectedClub.internalName].features || {}
    ).requireAccountingExportNumber;

    const columns = [...baseColumns];
    if (!requireAccountingExportNumber) {
      columns.splice(
        baseColumns.findIndex((c) => c.id === "accountingExportNumber"),
        1
      );
    }

    const filters = getFiltersFromUrl(
      this.props.location,
      this.state.filters,
      filtersStructure
    );

    this.setState(
      {
        requireAccountingExportNumber,
        columns,
        filters,
      },
      () => this.loadData(false)
    );
  };

  // there can be gaps in the products' priority.
  // when making product inactive - we remove its priority but we don't change the other products priority
  fixProductsPriority = (activeProducts) => {
    const products = _.sortBy(activeProducts, "priority");
    for (let index = 0; index < products.length; index++) {
      const product = products[index];
      product.priority = index;
    }
  };

  loadData = async (updateUrl = true) => {
    let filters = prepareFilters(this.state.filters, filtersStructure);
    this.setState({ isLoading: true });
    const res = await axios.get(
      `/store/products?category=merchandise&${filters.join("&")}`
    );
    const products = res.data;
    const activeProducts = products.filter((p) => p.active);
    this.fixProductsPriority(activeProducts);
    this.setState({ products, activeProducts, isLoading: false });

    if (updateUrl) {
      filters = prepareFilters(this.state.filters, filtersStructure);
      this.props.navigate(`?${filters.join("&")}`, { replace: true });
    }
  };

  handleChangeProductOrder = async (products) => {
    let success;
    try {
      this.setState({ isSaving: true });
      await axios.patch(`/store/products/changeOrder`, {
        products: products.map((p) => p._id),
      });
      success = true;
      this.loadData();
    } catch (error) {
      success = false;
    }

    this.setState({
      isSaving: false,
      snackbar: {
        key: _.uniqueId(),
        variant: success ? "success" : "error",
        message: success ? "השינויים נשמרו בהצלחה" : "אירעה שגיאה",
      },
    });
  };

  sortValueCell = (columnId, product) => {
    switch (columnId) {
      case "title":
      case "price":
        return product[columnId];

      case "priority":
        return product.active ? product.priority : 10000;

      default:
        return this.renderCell(columnId, product);
    }
  };

  renderCell = (columnId, product) => {
    let img;
    switch (columnId) {
      case "title":
        img = cloudinaryImage(product.imagePublicId, {
          version: product.imageVersion,
        });
        return (
          <span style={{ display: "flex", alignItems: "center" }}>
            <AdvancedImage
              alt="product"
              cldImg={img}
              style={{ width: 80, height: 80, marginLeft: 10 }}
            />
            <span style={{ display: "flex", flexDirection: "column" }}>
              <span>{product.title}</span>
              <span style={{ color: "rgba(0,0,0,0.54)" }}>
                {product.description}
              </span>
            </span>
          </span>
        );

      case "price":
        return displayPrice(product.price);

      case "status":
        return productStatuses[product.active ? "active" : "inactive"];

      case "actions":
        return (
          <IconButton
            size="small"
            onClick={() => this.setState({ currentProduct: product })}
          >
            <EditIcon />
          </IconButton>
        );

      case "priority":
        return product.active ? product.priority + 1 : "-";

      default:
        return product[columnId];
    }
  };

  handleEditProductCompleted = () => {
    this.setState({
      currentProduct: null,
      snackbar: {
        key: _.uniqueId(),
        variant: "success",
        message: "השינויים נשמרו בהצלחה",
      },
    });
    this.loadData();
  };

  handleFilterChange = (filterName, value) => {
    this.setState({ filters: { ...this.state.filters, [filterName]: value } });
  };

  render() {
    return (
      <TlPaper title="מוצרים" titleBackground>
        <FiltersBar
          filters={filtersStructure}
          onFilterChanged={this.handleFilterChange}
          values={this.state.filters}
          onSearch={this.loadData}
          isSearching={this.state.isLoading}
        />
        <Table
          columns={this.state.columns}
          sortValueCell={this.sortValueCell}
          renderCell={this.renderCell}
          rows={this.state.products}
          title="מוצרים"
          defaultSortBy={1}
          isLoading={this.state.isLoading}
          onAdd={() => this.setState({ currentProduct: {} })}
          customHeaderItems={
            <IconButtonTooltip
              onClick={() => this.setState({ isSortDialogOpen: true })}
              title="עדכון מיקום הפריטים בחנות"
            >
              <SortIcon />
            </IconButtonTooltip>
          }
        />
        <EditProductDialog
          open={this.state.currentProduct !== null}
          onClose={() => this.setState({ currentProduct: null })}
          product={this.state.currentProduct}
          onComplete={this.handleEditProductCompleted}
          requireAccountingExportNumber={
            this.state.requireAccountingExportNumber
          }
        />
        <ProductSortDialog
          isOpen={this.state.isSortDialogOpen}
          onClose={() => {
            this.setState({ isSortDialogOpen: false });
          }}
          products={this.state.activeProducts}
          onSave={this.handleChangeProductOrder}
          isSaving={this.state.isSaving}
        />
        <SnackbarNg
          snackKey={this.state.snackbar}
          open={this.state.snackbar.key !== undefined}
          onClose={() => this.setState({ snackbar: { open: false } })}
          // || success : is required. without it, the snackbar turn into red for a moment when closing it
          severity={this.state.snackbar.variant || "success"}
          message={this.state.snackbar.message}
        />
      </TlPaper>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedClub: state.auth.selectedClub,
  };
}

export default connect(mapStateToProps)(withRouter(Products));
