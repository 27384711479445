import React from "react";

import InfoRow from "../../components/InfoRows/InfoRow";
import { displayDate, displayPrice } from "../../utils/helpers";
import Paper2Sx from "../../components/Paper2Sx";

const styles = {
  title: {
    width: 110,
  },
};

export default function DiscountRequestDetails({
  originalSubscription,
  clubPlayer,
}) {
  return (
    <Paper2Sx externalTitle="פרטי הבקשה">
      <InfoRow title="ספורטאי" value={clubPlayer.name} titleSx={styles.title} />
      <InfoRow
        title="הורה"
        value={`${clubPlayer.parent.name}, ${clubPlayer.parent.phone} `}
        titleSx={styles.title}
      />
      <InfoRow
        title="קבוצה"
        value={`${originalSubscription.team.productName} / ${originalSubscription.team.name}`}
        titleSx={styles.title}
      />
      <InfoRow
        title="מחיר"
        value={`${displayPrice(originalSubscription.totalPrice)}${
          originalSubscription.feesPrice > 0
            ? ` (דמי רישום: ${originalSubscription.feesPrice}, פעילות: ${originalSubscription.price})`
            : ""
        }`}
        titleSx={styles.title}
      />
      <InfoRow
        title="תקופת הפעילות"
        value={`${displayDate(originalSubscription.endDate)} - ${displayDate(
          originalSubscription.startDate
        )}`}
        titleSx={styles.title}
      />
      <InfoRow
        title="פרטי הבקשה"
        value={`${originalSubscription.specialRequest.discountRequest.reason}`}
        titleSx={styles.title}
      />
    </Paper2Sx>
  );
}
