import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";
import moment from "moment";

import axios from "../../utils/axios";
import MenuItem from "../base/MenuItem";
import ButtonSx from "../base/ButtonSx";

export default class ChangeIndirectTeamDialog extends React.Component {
  state = {
    teams: [],
    isLoading: true,
    selectedTeam: "",
  };

  componentDidMount = async () => {
    const res = await axios.get("/teams?hasProduct=false&status=active");
    this.setState({
      isLoading: false,
      teams: res.data,
      selectedTeam: this.props.subscription.team._id,
    });
  };

  handleChange = async () => {
    this.setState({ isLoading: true });
    await axios.patch(
      `/store/subscriptions/${this.props.subscription._id}/changeTeam`,
      {
        type: "indirect",
        newSub: { teamId: this.state.selectedTeam },
        oldSub: {
          reason: "מעבר קבוצה",
          cancelledAt: moment().toISOString(),
        },
      }
    );
    this.props.onComplete();
    this.setState({ isLoading: false });
  };

  render() {
    return (
      <Dialog
        dir="rtl"
        fullWidth
        maxWidth="sm"
        onClose={this.props.onClose}
        open={this.props.open}
      >
        <DialogTitle id="form-dialog-title">החלפת קבוצה</DialogTitle>
        <DialogContent dividers>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div style={{ fontWeight: "bold", marginLeft: 50 }}>קבוצה</div>
            <TextField
              variant="standard"
              select
              value={this.state.selectedTeam}
              onChange={(e) => this.setState({ selectedTeam: e.target.value })}
            >
              {this.state.teams.map((team) => (
                <MenuItem key={team._id} value={team._id}>
                  {team.name}
                </MenuItem>
              ))}
            </TextField>
          </div>
        </DialogContent>
        <DialogActions>
          <ButtonSx onClick={this.props.onClose}>ביטול</ButtonSx>
          <ButtonSx
            onClick={this.handleChange}
            debounce
            isLoading={this.state.isLoading}
            disabled={
              this.state.selectedTeam === this.props.subscription.team._id
            }
          >
            אישור
          </ButtonSx>
        </DialogActions>
      </Dialog>
    );
  }
}
