import React from "react";
import { List, Divider, IconButton, Drawer, Box } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import { MainListItems, SecondaryListItems } from "../listItems";
const drawerWidth = 220;

export default function DrawerDesktop(props) {
  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
        },
      }}
      variant="persistent"
      anchor="left"
      open={props.isOpen}
    >
      <Box
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          zIndex: 10000,
          backgroundColor: "white",
          width: drawerWidth,
        }}
      >
        <props.Header>
          <IconButton onClick={props.onDrawerToggle}>
            <ChevronRightIcon />
          </IconButton>
        </props.Header>
        <Divider />
      </Box>

      <List disablePadding sx={{ mt: "72px" }}>
        <MainListItems
          selectedClub={props.selectedClub}
          selectedItem={props.selectedItem}
          user={props.user}
        />
      </List>
      <Divider />
      <List disablePadding>
        <SecondaryListItems
          selectedClub={props.selectedClub}
          selectedItem={props.selectedItem}
          user={props.user}
        />
      </List>
    </Drawer>
  );
}
