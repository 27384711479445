import React, { useMemo } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Box, Link } from "@mui/material";

import Table from "../../../components/table/Table";
import { displayPrice } from "../../../utils/helpers";

const columns = [
  { id: "team", label: "קבוצה", width: 435 },
  { id: "totalPaid", label: "סה״כ" },
];

export default function RevenuesTeams({ row: subscriptionProduct, ...props }) {
  const renderCell = (columnId, team) => {
    switch (columnId) {
      case "team":
        return (
          <Link
            component={RouterLink}
            color="primary"
            to={`/clubs/${props.clubInternalName}/teams/${team._id}`}
            underline="hover"
          >
            {team.name}
            {team.isProductChanged ? "*" : ""}
          </Link>
        );

      case "totalPaid":
        return displayPrice(team.income.paid);

      default:
        return team[columnId];
    }
  };

  const sortValueCell = (columnId, team) => {
    switch (columnId) {
      case "team":
        return team.name;

      case "totalPaid":
        return team.income.paid;

      default:
        return team[columnId];
    }
  };

  const changedProducts = useMemo(
    () => subscriptionProduct.teams.find((t) => t.isProductChanged),
    [subscriptionProduct.teams]
  );

  return (
    <Box sx={{ p: 2 }}>
      <Table
        noHeader
        columns={columns}
        renderCell={renderCell}
        sortValueCell={sortValueCell}
        rows={subscriptionProduct.teams}
        defaultSortBy={1}
        defaultSortDirection="desc"
        isLoading
      />
      {changedProducts && (
        <div style={{ paddingRight: 10, paddingBottom: 10 }}>
          {" "}
          *המסגרת של קבוצה זו הוחלפה{" "}
        </div>
      )}
    </Box>
  );
}
