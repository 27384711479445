import { createTheme } from "@mui/material/styles";
import { heIL } from "@mui/x-date-pickers/locales";

const theme = createTheme(
  {
    direction: "rtl",
    typography: {
      useNextVariants: true,
    },
    palette: {
      // primary: { main: "#1776d2" },
      // secondary: { main: "#ff4e00" },
      // error: { light: "#d92550", main: "#d92550", dark: "#d92550" },
      // info: { light: "#3f6ad8", main: "#3f6ad8", dark: "#3f6ad8" },
      // success: { light: "#3ac47d", main: "#3ac47d", dark: "#3ac47d" },
      // warning: { light: "#f7b924", main: "#f7b924", dark: "#f7b924" },
    },
    appBar: {
      backgroundColor: "#247BA0",
      color: "#FFF",
    },
    calendar: {
      homeGame: "#369be5",
      awayGame: "#e4c442",
      league: "#38b678",
      school: "#cca6ac",
      collisions: "#d50001",
      placeholder: "#a6a6a6",
      other: "#00bcd4",
      holiday: "#7986cb",
    },
  },
  heIL
);

export default theme;
