import React, { useMemo, useState } from "react";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import EditIcon from "@mui/icons-material/Edit";
import _ from "lodash";
import moment from "moment";
import {
  Box,
  CircularProgress,
  Collapse,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";

import { FaCartShopping } from "react-icons/fa6";
import { FaDollarSign } from "react-icons/fa";

import { displayPrice } from "../../../utils/helpers";
import RevenueCategory from "./RevenueCategory";
import IconButtonTooltip from "../../../components/base/IconButtonTooltip";
import StandaloneDatesPickers from "./StandaloneDatesPickers";
import RevenueChart from "./RevenueChart";
import RevenueIcon from "./RevenueIcon";
import Paper2Sx from "../../../components/Paper2Sx";

export default function RevenueMerchandise(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const { products, totalPaid } = props.merchandise || {};

  const chartData = useMemo(() => {
    const orderProducts = _.orderBy(products, "totalPaid", "desc");
    const top4 = orderProducts.splice(0, 4);
    const series = top4.map((p) => p.totalPaid);
    const labels = top4.map((p) => (p.otp ? "מוצר חד פעמי" : p.product.title));

    if (orderProducts.length > 0) {
      const restOfProducts = _.sumBy(orderProducts, "totalPaid");
      series.push(restOfProducts);
      labels.push(
        orderProducts.length === 1
          ? orderProducts[0].product.title
          : "יתר המוצרים"
      );
    }

    return { series, labels };
  }, [products]);

  const handleDateChanged = (dates) => {
    props.onDateChanged(dates);
    setIsOpen(false);
    setIsEditing(false);
  };

  const handleStartEditing = (e) => {
    e.stopPropagation();
    setIsEditing(true);
  };

  const renderDates = () => {
    return (
      <span>
        <div style={{ position: "relative" }}>
          {!isEditing && (
            <IconButton
              sx={{ position: "absolute", top: 2, left: -30 }}
              size="small"
              onClick={handleStartEditing}
              disabled={props.isLoading}
            >
              <EditIcon />
            </IconButton>
          )}
          {moment(props.dates.from).format("MMM YY")} -{" "}
          {moment(props.dates.to).utcOffset(0).format("MMM YY")}
        </div>
        <StandaloneDatesPickers
          isOpen={isEditing}
          onClose={() => setIsEditing(false)}
          onComplete={handleDateChanged}
          dates={props.dates}
        />
      </span>
    );
  };

  return (
    <Paper2Sx
      paperSx={{ ...styles.container, ...(isOpen ? styles.openContainer : {}) }}
    >
      <Box sx={styles.downloadButton}>
        <IconButtonTooltip
          title="הורדה"
          onClick={props.onDownload}
          disabled={props.isLoading}
        >
          <ArrowDownwardIcon />
        </IconButtonTooltip>
      </Box>
      <Grid container justifyContent="space-between">
        <Grid
          item
          container
          xs={12}
          onClick={() => totalPaid > 0 && !isEditing && setIsOpen(!isOpen)}
          alignItems="center"
          sx={styles.header}
        >
          <Grid item xs>
            <Grid container justifyContent="center">
              <RevenueIcon color="#00e396">
                <FaCartShopping
                  style={{ color: "#FFF", fontSize: 40, paddingTop: "4px" }}
                />
              </RevenueIcon>
              <Grid item container justifyContent="center" xs={12}>
                <Typography variant="h5" component="span" sx={styles.title}>
                  {renderDates()}
                </Typography>
              </Grid>
              <Grid item container justifyContent="center" xs={12}>
                <Typography variant="subtitle1">חנות</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs>
            <Grid container justifyContent="center">
              <RevenueIcon color="#008ffb">
                <FaDollarSign
                  style={{ color: "FFF", fontSize: 40, padding: 2 }}
                />
              </RevenueIcon>
              <Grid item container justifyContent="center" xs={12}>
                {props.isLoading ? (
                  <CircularProgress />
                ) : (
                  <Typography variant="h5" component="span" sx={styles.title}>
                    {displayPrice(totalPaid || 0)}
                  </Typography>
                )}
              </Grid>
              <Grid item container justifyContent="center" xs={12}>
                <Typography variant="subtitle1">הכנסות</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={5}>
            {totalPaid === 0 ? (
              <Box sx={styles.chartTextNoData}>
                <Typography>לא נמכרו מוצרים בתקופה זו</Typography>
              </Box>
            ) : (
              <RevenueChart
                labels={chartData.labels}
                series={chartData.series}
                isLoading={props.isLoading}
              />
            )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Collapse in={isOpen}>
            <RevenueCategory
              row={{ type: "merchandise", products: products || [] }}
              dates={props.dates}
              superClub={props.superClub}
            />
          </Collapse>
        </Grid>
      </Grid>
    </Paper2Sx>
  );
}

const styles = {
  container: {
    minHeight: 170,
    display: "flex",
    alignItems: "center",
    padding: "20px",
    marginBottom: "30px",
    "&:hover": {
      backgroundColor: "#FAFAFA",
    },
    position: "relative",
  },
  openContainer: {
    backgroundColor: "#FAFAFA",
  },
  downloadButton: {
    zIndex: 100,
    position: "absolute",
    top: 5,
    right: 5,
  },
  header: {
    height: 200,
    cursor: "grab",
  },
  chartTextNoData: {
    display: "flex",
    justifyContent: "flex-end",
    paddingRight: "55px",
  },
};
