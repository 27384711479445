import React, { Component } from "react";
import { CircularProgress, List } from "@mui/material";
import _ from "lodash";
import moment from "moment";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import axios from "../../../utils/axios";
import AbsoluteCenter from "../../../components/AbsoluteCenter";
import CalendarResource from "./CalendarResource";
import { DnDreorder, getAxiosErrorData } from "../../../utils/helpers";
import SnackbarNg from "../../../components/base/SnackbarNg";
import DeleteResourceDialog from "./DeleteResourceDialog";
import ButtonSx from "../../../components/base/ButtonSx";

const grid = 8;
const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  borderRadius: 10,
  // padding: 10,
  margin: `0 0 ${grid}px 0`,
  // change background colour if dragging
  background: isDragging ? "#EFEFEF" : "white",
  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "lightblue" : "",
  padding: grid,
  width: "100%",
  height: "100%",
  overflow: "auto",
  borderRadius: 5,
});

export default class CalendarResourceSettings extends Component {
  state = {
    isLoading: false,
    isSaving: false,
    resources: null,
    isEditing: false,
    snackbarKey: null,
    error: null,
    deleteItem: null,
    isDeleteItemDialogOpen: false,
  };

  componentDidMount = async () => {
    this.loadResources(false);
  };

  loadResources = async (silence = true) => {
    this.setState({ isLoading: !silence });
    const res = await axios.get(`/calendar/resources`);

    const allResources = res.data;
    let resources = allResources.filter((r) => !r.parent);
    resources = _.sortBy(resources, "sortId");

    resources = resources.map((resource) => {
      resource.subResources = resource.subResources.map((subResource) =>
        allResources.find((r) => r._id === subResource)
      );
      return resource;
    });

    this.setState({ isLoading: false, resources });
  };

  handleAddResource = (parent) => {
    const resource = { name: "", _id: "new", subResources: [] };
    if (parent) {
      resource.parent = parent._id;
      resource.parentObject = parent;
    }
    this.setState((prevState) => {
      let resources = [resource, ...prevState.resources];
      if (parent) {
        resources = prevState.resources.map((r) => {
          if (r._id === parent._id) {
            r.subResources.push(resource);
          }
          return r;
        });
      }

      return { resources, isEditing: true };
    });
  };

  handleShowDeletePopup = (resource) => () => {
    this.setState({
      deleteItem: resource,
      isDeleteItemDialogOpen: true,
    });
  };

  handleResourceDelete = async () => {
    const resource = this.state.deleteItem;

    try {
      this.setState({ isSaving: true });
      await axios.delete(`/calendar/resources/${resource._id}`);
      this.props.onChange();

      this.setState((prevState) => {
        let resources = prevState.resources
          .filter((r) => r._id !== resource._id)
          .map((r) => {
            if (r._id === resource.parent) {
              r.subResources = r.subResources.filter(
                (sr) => sr._id !== resource._id
              );
            }
            return r;
          });

        if (resource.parent) {
          const parent = resources.find((r) => r._id === resource.parent);
          parent.subResources = parent.subResources.filter(
            (sr) => sr !== resource._id
          );
        }
        return {
          resources,
          isSaving: false,
          snackbarKey: moment().format(),
        };
      });
      return true;
    } catch (error) {
      const errorData = getAxiosErrorData(error);
      console.log(errorData);
      this.setState({ error: "אירעה שגיאה", isSaving: false });
      return false;
    } finally {
      this.setState({ isDeleteItemDialogOpen: false, deleteItem: null });
    }
  };

  renderContent = () => (
    <div>
      <ButtonSx
        fullWidth
        onClick={() => this.handleAddResource()}
        disabled={this.state.isEditing}
      >
        הוספת מגרש חדש
      </ButtonSx>
      {this.renderResourceList()}
    </div>
  );

  handleSetEditing = () => {
    this.setState({ isEditing: true });
  };

  handleCancelEditing = () => {
    this.setState((prevState) => {
      let resources = prevState.resources
        .filter((r) => r._id !== "new")
        .map((r) => {
          r.subResources = r.subResources.filter((sr) => sr._id !== "new");
          return r;
        });

      return { isEditing: false, resources, error: null };
    });
  };

  handleEditingCompleted = async (resource) => {
    // shouldn't happen
    if (resource.name.length < 2) {
      return;
    }

    const success = await this.saveResource(resource);
    if (success) {
      this.handleCancelEditing();
      this.loadResources();
    }
    return success;
  };

  saveResource = async (resource) => {
    try {
      this.setState({ isSaving: true });
      this.props.onChange();
      if (resource._id && resource._id !== "new") {
        await axios.patch(`/calendar/resources/${resource._id}`, {
          name: resource.name,
        });
      } else {
        await axios.post(`/calendar/resources/`, {
          name: resource.name,
          ...(resource.parent ? { parentId: resource.parent } : {}),
        });
      }
      this.setState({ isSaving: false, snackbarKey: moment().format() });
      return true;
    } catch (error) {
      const errorData = getAxiosErrorData(error);
      if ((errorData || {}).error === "notUnique") {
        this.setState({ error: "קיים מגרש עם שם זהה", isSaving: false });
      } else {
        this.setState({ error: "אירעה שגיאה", isSaving: false });
      }
      return false;
    }
  };

  onDragEnd = async (result) => {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    const newItems = DnDreorder(
      this.state.resources,
      source.index,
      destination.index
    );
    this.setState({ resources: newItems });

    try {
      this.props.onChange();
      await axios.post("/calendar/resources/reorder", {
        resources: newItems.map((i) => i._id),
      });
    } catch (error) {
      console.log(error);
    }
  };

  renderResourceList = () => {
    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId="options2">
          {(provided, snapshot) => (
            <List
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {this.state.resources.map((resource, index) => (
                <Draggable
                  key={resource._id}
                  draggableId={resource._id}
                  index={index}
                >
                  {(provided, snapshot) => {
                    return (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                        <CalendarResource
                          key={resource._id}
                          resource={resource}
                          onStartEditing={this.handleSetEditing}
                          index={index}
                          onCancelEditing={this.handleCancelEditing}
                          onCompleteEditing={this.handleEditingCompleted}
                          isSaving={this.state.isSaving}
                          onNameChange={this.handleResourceNameChanged}
                          onAddNewSubResource={this.handleAddResource}
                          disabled={this.state.isEditing || this.state.isSaving}
                          onDelete={this.handleShowDeletePopup}
                          error={this.state.error}
                        />
                      </div>
                    );
                  }}
                </Draggable>
              ))}
              {provided.placeholder}
            </List>
          )}
        </Droppable>
      </DragDropContext>
    );
  };

  render() {
    return (
      <div>
        {this.state.isLoading || !this.state.resources ? (
          <AbsoluteCenter>
            <CircularProgress />
          </AbsoluteCenter>
        ) : (
          <>
            {this.renderContent()}
            <DeleteResourceDialog
              open={this.state.isDeleteItemDialogOpen}
              onClose={() =>
                this.setState({
                  isDeleteItemDialogOpen: false,
                  deleteItem: null,
                })
              }
              onDelete={this.handleResourceDelete}
              resource={this.state.deleteItem}
            />
          </>
        )}
        <SnackbarNg
          snackKey={this.state.snackbarKey}
          open={this.state.snackbarKey !== null}
          onClose={() => this.setState({ snackbarKey: null })}
          severity="success"
          message="הפעולה בוצעה בהצלחה"
        />
      </div>
    );
  }
}
