import React from "react";
import { Grid } from "@mui/material";
import { connect } from "react-redux";
import ReactGA from "react-ga4";

import axios from "../../utils/axios";
import CoachPersonalDetails from "./CoachPersonalDetails";
import clubConfig from "../../config/clubs";
import TeamsAttendance from "../Attendance/Teams";
import withParams from "../../components/routing/withParams";
import TeamsTable from "../Teams/TeamsTable";
import TlPaper from "../../components/TlPaper";

class Coach extends React.Component {
  state = {
    coach: {},
    teams: [],
    isLoading: false,
  };

  componentDidMount = async () => {
    ReactGA.send({
      hitType: "pageview",
      page: "/coaches/:id",
      title: "Coach",
    });

    this.loadData();
  };

  loadData = async () => {
    this.setState({ isLoading: true });
    let res = await axios.get(
      `/clubCoaches/${this.props.params.coachId}?granularity=nonArchivedTeamsCount`
    );
    const coach = res.data;

    res = await axios.get(
      `/teams?clubCoachId=${this.props.params.coachId}&granularity=clubCoach&granularity=playersStats&granularity=seasonYear&granularity=assistants`
    );
    this.setState({ coach, teams: res.data, isLoading: false });
  };

  handleDelete = async () => {
    this.setState({ deleteCoachStatus: "loading" });

    try {
      await axios.delete(`/clubCoaches/${this.state.coach._id}`);
      this.setState({ deleteCoachStatus: "success" });
    } catch (error) {
      this.setState({ deleteCoachStatus: "error" });
    }
  };

  render() {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <CoachPersonalDetails
            coach={this.state.coach}
            onChange={this.loadData}
            editable
            clubCategory={
              clubConfig[this.props.selectedClub.internalName].category
            }
            isLoading={this.state.isLoading}
            deleteStatus={this.state.deleteCoachStatus}
            onDelete={this.handleDelete}
            hasHrCoachTeamAdminPermission={
              this.props.user.permissions.hrCoachTeamAdmin
            }
          />
        </Grid>
        <Grid item xs={12}>
          <TlPaper
            title="קבוצות"
            titleBackground
            isLoading={this.state.isLoading}
          >
            <TeamsTable context="coach" teams={this.state.teams} />
          </TlPaper>
        </Grid>

        <Grid item xs={12}>
          <TlPaper title="נוכחות - חודש אחרון" titleBackground>
            <TeamsAttendance coachId={this.props.params.coachId} visible />
          </TlPaper>
        </Grid>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedClub: state.auth.selectedClub,
    user: state.auth.user,
  };
}

export default connect(mapStateToProps)(withParams(Coach));
