import React, { useState, useEffect } from "react";
import { DialogContent, DialogActions, Grid, TextField } from "@mui/material";
import { connect } from "react-redux";

import oldClubConfig from "../../config/clubs";
import Dialog from "../Dialog";
import PriceInputNG from "../PriceInputNG";
import ButtonSx from "../base/ButtonSx";

function OneTimeProductDialog(props) {
  const [price, setPrice] = useState("");
  const [title, setTitle] = useState("");
  const [accountingExportNumber, setAccountingExportNumber] = useState("");
  const [priceError, setPriceError] = useState(false);
  const [titleError, setTitleError] = useState(false);
  const [accountingExportNumberError, setAccountingExportNumberError] =
    useState(false);
  const [instaValidate, setInstaValidate] = useState(false);
  const requireAccountingExportNumber = (
    oldClubConfig[props.selectedClub.internalName].features || {}
  ).requireAccountingExportNumber;

  useEffect(() => {
    if (props.open) {
      setPrice(props.product ? props.product.price : "");
      setTitle(props.product ? props.product.title : "");
      setAccountingExportNumber(
        props.product ? props.product.accountingExportNumber : ""
      );
    } else {
      setPrice("");
      setTitle("");
      setAccountingExportNumber("");
      setPriceError(false);
      setTitleError(false);
      setAccountingExportNumberError(false);
      setInstaValidate(false);
    }
  }, [props.open, props.product]);

  const validateTitle = (val) => {
    const curTitle = val === undefined ? title : val;
    const isValid = curTitle.trim().length >= 2;
    setTitleError(!isValid);

    return isValid;
  };

  const validatePrice = (val) => {
    const curPrice = val === undefined ? price : val;
    const isValid = parseFloat(curPrice) > 0;

    setPriceError(!isValid);

    return isValid;
  };

  const validateAccountingExportNumber = (val) => {
    const num = val === undefined ? accountingExportNumber : val;
    const isValid = num.length > 2;

    setAccountingExportNumberError(!isValid);

    return isValid;
  };

  const validate = () => {
    let isPriceValid = validatePrice();
    let isTitleValid = validateTitle();
    let isAENValid =
      !requireAccountingExportNumber || validateAccountingExportNumber();
    return isPriceValid && isTitleValid && isAENValid;
  };

  const handleSaveProduct = () => {
    const isValid = validate();

    setInstaValidate(true);
    if (!isValid) {
      return;
    }

    props.onUpdateProduct({
      ...(props.product || {}),
      title,
      price: parseFloat(price),
      accountingExportNumber,
      otp: true,
    });
  };

  return (
    <Dialog
      fullWidth
      onClose={props.onClose}
      open={props.open}
      title="פריט חד פעמי"
      maxWidth="xs"
    >
      <DialogContent dividers>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              variant="standard"
              label="שם הפריט"
              fullWidth
              inputProps={{ maxLength: 60 }}
              value={title}
              onChange={(e) => {
                setTitle(e.target.value);
                if (instaValidate) {
                  validateTitle(e.target.value);
                }
              }}
              error={titleError}
              helperText={titleError ? "יש להזין שם פריט תקין" : null}
            />
          </Grid>

          <Grid item xs={12}>
            <PriceInputNG
              label="מחיר"
              fullWidth
              value={price}
              onChange={(newPrice) => {
                if (instaValidate) {
                  validatePrice(newPrice);
                }
                setPrice(newPrice);
              }}
              error={priceError}
              helperText={priceError ? "יש להזין מחיר תקין" : null}
            />
          </Grid>
          {requireAccountingExportNumber && (
            <Grid item xs={12}>
              <TextField
                variant="standard"
                label="מפתח חשבון"
                fullWidth
                value={accountingExportNumber}
                onChange={(e) => {
                  if (instaValidate) {
                    validateAccountingExportNumber(e.target.value);
                  }
                  setAccountingExportNumber(e.target.value);
                }}
                error={accountingExportNumberError}
                helperText={
                  accountingExportNumberError
                    ? "יש להזין מפתח חשבון תקין"
                    : null
                }
              />
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <ButtonSx onClick={props.onClose}>ביטול</ButtonSx>
        <div>
          <ButtonSx
            disabled={priceError || titleError}
            onClick={handleSaveProduct}
            debounce
          >
            אישור
          </ButtonSx>
        </div>
      </DialogActions>
    </Dialog>
  );
}

function mapStateToProps(state) {
  return {
    selectedClub: state.auth.selectedClub,
  };
}

export default connect(mapStateToProps)(OneTimeProductDialog);
