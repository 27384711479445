import React, { useState } from "react";
import moment from "moment";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Slide,
  CircularProgress,
  Divider,
  Grid,
  Typography,
  IconButton,
  Box,
} from "@mui/material";
import _ from "lodash";
import GoToIcon from "@mui/icons-material/CallMade";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";

import axios from "../../utils/axios";
import AbsoluteCenter from "../../components/AbsoluteCenter";
import WeekStatusCollisionsDialog from "./WeekStatusCollisionsDialog";
import ButtonSx from "../../components/base/ButtonSx";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});

const DayStartDivider = styled(Divider)({
  borderWidth: 1.5,
  borderRadius: 2,
  marginLeft: 10,
  marginRight: 10,
  backgroundColor: "gray",
});

const EventStartDivider = styled(Divider)({
  borderWidth: 1.5,
  borderRadius: 2,
  marginLeft: 10,
  marginRight: 20,
});

const EventDivider = styled(Divider)({
  marginBottom: 6,
  marginTop: 6,
});

const DayDivider = styled(Divider)({
  marginBottom: 6,
  marginTop: 6,
  backgroundColor: "gray",
});

export default function WeekStatusDialog(props) {
  const [collisions, setCollisions] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isCollisionsDialogOpen, setIsCollisionsDialogOpen] = useState(false);

  const endOfWeek = props.week
    ? moment(props.week.startDate).endOf("week")
    : null;

  React.useEffect(() => {
    if (props.week && collisions === null) {
      loadCollisions();
    } else {
      setCollisions(null);
      setIsLoading(false);
      setIsSaving(false);
    }
  }, [props.week]);

  React.useEffect(() => {
    if (props.shouldUpdate && props.open) {
      // update when dialog is open (for editing events)
      loadCollisions();
    }
  }, [props.shouldUpdate]);

  const loadCollisions = async () => {
    const startTime = props.week.startDate.startOf("day").format("YYYY-MM-DD");
    const endTime = endOfWeek.format("YYYY-MM-DD");
    setIsLoading(true);
    const response = await axios.get(
      `/events?from=${startTime}&to=${endTime}&showCollisions=true&onlyCollisions=true`
    );

    let events = response.data;
    events.forEach((e) => {
      e.start = moment(e.start);
    });
    events = _.sortBy(events, (e) => e.start.toDate());
    const days = _.groupBy(events, (e) => moment(e.start).format("DD/MM"));
    setCollisions(days);
    setIsLoading(false);

    props.onUpdateCompleted();
  };

  const handleOpenWeek = async (ignoreCollisions = false) => {
    if (Object.values(collisions).length === 0 || ignoreCollisions) {
      setIsSaving(true);
      const res = await axios.post(
        `/weekStatuses/${props.week.startDate.format("YYYY-MM-DD")}/open`
      );
      setIsSaving(false);
      setCollisions(null);
      setIsLoading(false);
      const weekStatus = res.data;
      props.onComplete(weekStatus._id);
    } else {
      setIsCollisionsDialogOpen(true);
    }
  };

  const handleGoToDay = (day) => () => {
    props.onNavigate(day, "day");
  };

  if (!props.week) {
    return <div />;
  }

  const renderContent = () => {
    const progress = (
      <AbsoluteCenter>
        <CircularProgress />
      </AbsoluteCenter>
    );
    if (isLoading || !collisions) {
      return progress;
    } else {
      return (
        <div>
          {Object.keys(collisions).length > 0
            ? renderCollisions()
            : "האם ברצונכם לפתוח שבוע זה?"}
          {isSaving && progress}
        </div>
      );
    }
  };

  const renderCollisionDayHeader = (events, day) => {
    return (
      <>
        <Grid container>
          <Grid item>
            <DayStartDivider orientation="vertical" />
          </Grid>
          <Grid item sm sx={styles.dayTextContainer}>
            <Box sx={styles.dayEventTitle}>
              <span>יום {day.format("ddd DD/MM")} </span>
            </Box>
          </Grid>
          <Grid item sx={styles.dayLinkContainer}>
            <IconButton size="small" onClick={handleGoToDay(day)}>
              <GoToIcon />
            </IconButton>
          </Grid>
        </Grid>
        <div>
          <DayDivider />
        </div>
      </>
    );
  };

  const renderCollisionDay = (events) => {
    const day = moment(events[0].start).startOf("day");

    return (
      <Box sx={styles.marginBottom} key={day.format()}>
        {renderCollisionDayHeader(events, day)}
        {events.map((event, index) => (
          <React.Fragment key={event._id}>
            <Grid container spacing={1}>
              <Grid item>
                <EventStartDivider orientation="vertical" />
              </Grid>
              <Grid item>
                <Box sx={styles.eventTitle}>{event.title}</Box>
                <Typography variant="body2" color="textSecondary" component="p">
                  {moment(event.end).format("HH:mm")} -{" "}
                  {moment(event.start).format("HH:mm")},{" "}
                  {props.resourceDescription(event)}
                </Typography>
              </Grid>
            </Grid>
            {index < events.length - 1 && (
              <div>
                <EventDivider />
              </div>
            )}
          </React.Fragment>
        ))}
      </Box>
    );
  };

  const renderCollisions = () => {
    const dayGroups = Object.values(collisions);
    return (
      <div>
        <Box sx={styles.marginBottom}>
          <b>נמצאו {_.flatten(dayGroups).length} התנגשויות לשבוע זה</b>
        </Box>
        {dayGroups.map(renderCollisionDay)}
      </div>
    );
  };

  return (
    <>
      <Dialog
        open={props.open}
        onClose={props.onClose}
        fullScreen
        maxWidth="sm"
        sx={styles.dialog}
        dir="rtl"
        TransitionComponent={Transition}
        hideBackdrop
      >
        <DialogTitle>
          <Box sx={styles.titleContainer}>
            פתיחת שבוע {props.week.startDate.format("DD/MM")} -{" "}
            {moment(props.week.startDate).add(6, "days").format("DD/MM")}
            <IconButton size="small" onClick={props.onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent dividers>{renderContent()}</DialogContent>
        <DialogActions>
          <ButtonSx
            onClick={props.onClose}
            color="primary"
            autoFocus
            disabled={isLoading || isSaving}
          >
            חזרה
          </ButtonSx>
          <ButtonSx
            onClick={() => handleOpenWeek()}
            color="primary"
            disabled={isLoading || isSaving}
          >
            פתיחה
          </ButtonSx>
        </DialogActions>
      </Dialog>
      <WeekStatusCollisionsDialog
        open={isCollisionsDialogOpen}
        onClose={() => setIsCollisionsDialogOpen(false)}
        onComplete={() => {
          setIsCollisionsDialogOpen(false);
          handleOpenWeek(true);
        }}
      />
    </>
  );
}

const styles = {
  dialog: {
    zIndex: { sm: 1100 },
    width: { sm: 500 },
    marginTop: { xs: 0, sm: "72px" },
  },
  eventTitle: {
    fontWeight: "500",
  },
  dayEventTitle: {
    fontWeight: "700",
    display: "flex",
    alignItems: "space-between",
    justifyContent: "space-between",
  },
  dayTextContainer: {
    display: "flex",
    alignItems: "center",
  },
  dayLinkContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  marginBottom: {
    marginBottom: "20px",
  },
  titleContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
};
