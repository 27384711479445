import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Slide,
  TextField,
} from "@mui/material";
import moment from "moment";
import validator from "validator";

import axios from "../../utils/axios";
import DealReceiptItemsForm from "./DealReceiptItemsForm";
import InfoRow from "../../components/InfoRows/InfoRow";
import { displayPrice, roundPrice } from "../../utils/helpers";
import DatePicker from "../../components/DatePicker";
import ButtonSx from "../../components/base/ButtonSx";

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction="right"
      ref={ref}
      {...props}
      timeout={{
        enter: 500,
        exit: 500,
      }}
    />
  );
});

const initData = () => ({
  clientName: "",
  clientId: "",
  email: "",
  items: [
    {
      _id: "0",
      description: "",
      price: "",
      priceStr: "",
      quantity: "1",
    },
  ],
  comments: "",
  dueDate: moment(),
});

export default function CreateDealReceiptDialog(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(initData());
  const [errors, setErrors] = useState({});
  const [instaValidate, setInstaValidate] = useState(false);

  const validate = () => {
    let isValid = true;
    const errors = {};

    if (data.clientId.trim().length === 0) {
      errors.clientId = "שדה לא תקין";
      isValid = false;
    }

    if (data.clientName.trim().length < 2) {
      errors.clientName = "שדה לא תקין";
      isValid = false;
    }

    if (data.email.trim().length !== 0 && !validator.isEmail(data.email)) {
      errors.email = "שדה לא תקין";
      isValid = false;
    }

    errors.items = data.items.map((item) => {
      const itemErrors = {};
      if (item.description.trim().length < 2) {
        itemErrors.description = "שדה לא תקין";
        isValid = false;
      }

      if (item.priceStr === "" || item.price === 0) {
        itemErrors.price = "שדה לא תקין";
        isValid = false;
      }

      if (item.quantity === "") {
        itemErrors.quantity = "שדה לא תקין";
        isValid = false;
      }

      return itemErrors;
    });

    setErrors(errors);
    setInstaValidate();
    return isValid;
  };

  const handleSave = async () => {
    const valid = validate();
    if (valid) {
      setIsLoading(true);
      const sendData = { ...data };
      sendData.dueDate = data.dueDate.format("YYYY-MM-DD");
      sendData.items.forEach(
        (item) => (item.quantity = parseInt(item.quantity))
      );
      if (sendData.email.trim().length === 0) {
        delete sendData.email;
      }
      const res = await axios.post(`/other/dealReceipts`, sendData);
      const { url, docNumber } = res.data;
      setIsLoading(false);

      props.onComplete(url, docNumber);
      setData(initData());
    }
  };

  const handleChange = (updates) => {
    if (Object.prototype.hasOwnProperty.call(updates, "clientId")) {
      if (updates.clientId !== "" && !updates.clientId.match(/^\d+$/)) {
        delete updates.clientId;
      }
    }

    setData((prevValue) => ({ ...prevValue, ...updates }));
    if (instaValidate) {
      validate();
    }
  };

  const defaultParams = (field) => ({
    error: Object.prototype.hasOwnProperty.call(errors, field),
    helperText: Object.prototype.hasOwnProperty.call(errors, field)
      ? errors[field]
      : "",
    disabled: isLoading,
    value: data[field],
    onChange: (e) => handleChange({ [field]: e.target.value }),
    margin: "dense",
    fullWidth: true,
  });

  const renderContent = () => {
    const total = roundPrice(
      data.items.reduce(
        (total, item) =>
          // parseFloat(item.priceStr) -> we can use item.price but when priceStr is empty, price is 0. so the calc works (shows 0). but we want it to be NAN
          (total += parseInt(item.quantity) * parseFloat(item.priceStr)),
        0
      )
    );

    return (
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            variant="standard"
            required
            {...defaultParams("clientName")}
            label="לקוח"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            variant="standard"
            required
            {...defaultParams("clientId")}
            label="ת.ז/ח.פ/ע.מ"
          />
        </Grid>
        <Grid item xs={6}>
          <DatePicker
            label="לתשלום עד"
            textFieldProps={{
              required: true,
            }}
            disabled={isLoading}
            disablePast
            value={data.dueDate}
            onChange={(dueDate) => handleChange({ dueDate })}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            variant="standard"
            {...defaultParams("email")}
            label="אימייל"
          />
        </Grid>
        <Grid item xs={6} />
        <Grid item container xs={12}>
          <DealReceiptItemsForm
            items={data.items}
            onItemsChanged={(items) => handleChange({ items })}
            errors={errors}
            isLoading={isLoading}
          />
        </Grid>
        <Grid item container xs={12}>
          <InfoRow
            title='סה"כ לתשלום'
            value={isNaN(total) ? "-" : displayPrice(total)}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <Dialog
      onClose={props.onClose}
      open={props.open}
      fullScreen
      sx={styles.dialog}
      dir="rtl"
      TransitionComponent={Transition}
    >
      <DialogTitle>יצירת דרישת תשלום חדשה</DialogTitle>
      <DialogContent dividers>{renderContent()}</DialogContent>
      <DialogActions>
        <ButtonSx onClick={props.onClose} disabled={isLoading}>
          ביטול
        </ButtonSx>
        <ButtonSx onClick={handleSave} isLoading={isLoading} debounce>
          אישור
        </ButtonSx>
      </DialogActions>
    </Dialog>
  );
}

const styles = {
  dialog: {
    width: 700,
    zIndex: 1100,
  },
};
