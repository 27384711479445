import React from "react";
import { Grid, TextField } from "@mui/material";

import MenuItem from "../../../../components/base/MenuItem";
import FormTextField from "../../../../components/base/FormTextField";
import PriceInputNg from "../../../../components/PriceInputNG";

export default function SubscriptionProductPriceFields(props) {
  const { subscriptionProduct } = props;
  const { onChange, errors } = props.defaultTextFieldParams;
  const { subscriptionType } = subscriptionProduct;

  const renderPricingType = () => {
    let value = "";
    if (subscriptionProduct.singlePricing) {
      value = "true";
    } else if (subscriptionProduct.singlePricing === false) {
      value = "false";
    }

    return (
      <TextField
        label="סוג תמחור"
        fullWidth
        variant="standard"
        margin="dense"
        value={value}
        select
        onChange={(e) => onChange({ singlePricing: e.target.value === "true" })}
        error={errors.singlePricing !== undefined}
        helperText={errors.singlePricing || " "}
        disabled={!subscriptionProduct.subscriptionType}
        inputProps={{ readOnly: !props.editable }}
      >
        <MenuItem value="true">
          {subscriptionType === "fixedPrice" ? "מחיר אחיד" : "מחיר חודשי אחיד"}
        </MenuItem>
        <MenuItem value="false">מספר תמחורים</MenuItem>
      </TextField>
    );
  };

  const renderPrice = () => {
    return (
      <PriceInputNg
        label={
          ["season", "camp"].indexOf(subscriptionType) > -1
            ? "מחיר חודשי"
            : "מחיר"
        }
        onChange={(unitPrice, unitPriceStr) =>
          onChange({
            pricings: [
              { ...subscriptionProduct.pricings[0], unitPrice, unitPriceStr },
            ],
          })
        }
        value={
          subscriptionProduct.singlePricing === true
            ? subscriptionProduct.pricings[0]?.unitPriceStr || ""
            : ""
        }
        disabled={subscriptionProduct.singlePricing !== true}
        inputProps={{ readOnly: props.completed }}
        margin="dense"
        error={errors.unitPrice !== undefined}
        helperText={errors.unitPrice || " "}
      />
    );
  };

  const renderNumberOfPaymentsForSeasonOrCamp = () => (
    <FormTextField
      label="מספר תשלומים"
      field="numberOfPayments"
      {...props.defaultTextFieldParams}
      textFieldProps={{
        disabled: !subscriptionProduct.subscriptionType || props.completed,
        select: true,
        SelectProps: { displayEmpty: true },
        ...(subscriptionProduct.subscriptionType === "season" // there is an issue with the empty value. the label stays as placeholder
          ? { InputLabelProps: { shrink: true } }
          : {}),
      }}
    >
      {subscriptionProduct.subscriptionType === "season" ? (
        <MenuItem value="">עד מספר חודשי הפעילות</MenuItem>
      ) : (
        [...Array(8).keys()].map((index) => (
          <MenuItem key={index} value={index + 1}>
            עד {index + 1} תשלומים
          </MenuItem>
        ))
      )}
    </FormTextField>
  );

  const renderNumberOfPayments = () => (
    <FormTextField
      label="מספר תשלומים"
      field="numberOfPayments"
      {...props.defaultTextFieldParams}
      textFieldProps={{
        disabled: !subscriptionProduct.subscriptionType || props.completed,
        select: true,
        SelectProps: { displayEmpty: true },
      }}
    >
      {[...Array(12).keys()].map((index) => (
        <MenuItem key={index} value={index + 1}>
          עד {index + 1} תשלומים
        </MenuItem>
      ))}
    </FormTextField>
  );

  const renderParentsPriceAutoUpdate = () => (
    <FormTextField
      label="עדכון מחיר אוטומטי להורים"
      field="parentsPriceAutoUpdate"
      {...props.defaultTextFieldParams}
      textFieldProps={{
        disabled: !subscriptionProduct.subscriptionType,
        inputProps: { readOnly: props.completed },
        select: true,
        error: props.errors.parentsPriceAutoUpdate !== undefined,
      }}
    >
      <MenuItem value="false">מחיר לא מתעדכן</MenuItem>
      <MenuItem value="true">מחיר מתעדכן ב-1 וב-15 לחודש</MenuItem>
    </FormTextField>
  );

  switch (subscriptionType) {
    case "":
    case "season":
      return (
        <>
          <Grid item xs={12} md={6} sx={styles.gridItem}>
            {renderPricingType()}
          </Grid>
          <Grid item xs={12} md={6} sx={styles.gridItem}>
            {renderPrice()}
          </Grid>
          <Grid item xs={12} md={6} sx={styles.gridItem}>
            {renderNumberOfPaymentsForSeasonOrCamp()}
          </Grid>
          <Grid item xs={12} md={6} sx={styles.gridItem}>
            {renderParentsPriceAutoUpdate()}
          </Grid>
        </>
      );

    case "camp":
      return (
        <>
          <Grid item xs={12} md={6} sx={styles.gridItem}>
            {renderPrice()}
          </Grid>
          <Grid item xs={12} md={6} sx={styles.gridItem}>
            {renderNumberOfPaymentsForSeasonOrCamp()}
          </Grid>
          <Grid item xs={12} md={6} sx={styles.gridItem}>
            {renderParentsPriceAutoUpdate()}
          </Grid>
        </>
      );

    case "fixedPrice":
      return (
        <>
          <Grid item xs={12} md={6} sx={styles.gridItem}>
            {renderPricingType()}
          </Grid>
          <Grid item xs={12} md={6} sx={styles.gridItem}>
            {renderPrice()}
          </Grid>
          <Grid item xs={12} md={6} sx={styles.gridItem}>
            {renderNumberOfPayments()}
          </Grid>
        </>
      );

    case "daily":
      return (
        <>
          <Grid item xs={12} md={6} sx={styles.gridItem}>
            {renderNumberOfPayments()}
          </Grid>
        </>
      );

    default:
      break;
  }
}

const styles = {
  gridItem: {
    paddingLeft: "5px",
    paddingRight: "5px",
  },
};
