import React from "react";
import moment from "moment";
import { connect } from "react-redux";
import ReactGA from "react-ga4";

import axios from "../../../utils/axios";
import FiltersBar from "../../../components/filters/FiltersBar";
import {
  prepareFilters,
  getFiltersFromUrl,
} from "../../../components/filters/prepareFilters";
import ReceiptsTable from "./ReceiptsTable";
import withRouter from "../../../components/routing/withRouter";
import TlPaper from "../../../components/TlPaper";

const filtersStructure = [
  { type: "date" },
  {
    name: "receiptNumber",
    type: "text",
    label: "מספר קבלה",
  },
];

class Receipts extends React.Component {
  state = {
    transactions: [],
    filters: {
      transactionType: "all",
      date: { from: moment().startOf("day"), to: moment().endOf("day") },
      receiptNumber: "",
    },
    isLoading: false,
  };

  componentDidMount = async () => {
    ReactGA.send({
      hitType: "pageview",
      page: "/payments/receipts",
      title: "Receipts",
    });

    const filters = getFiltersFromUrl(
      this.props.location,
      this.state.filters,
      filtersStructure
    );
    await this.setState({ filters });
    this.loadData(false);
  };

  loadData = async (updateUrl = true) => {
    let filters = prepareFilters(this.state.filters, filtersStructure);
    this.setState({ isLoading: true });
    const res = await axios.get(`/payments/receipts?${filters.join("&")}`);
    const transactions = res.data;
    this.setState({ transactions, isLoading: false });

    if (updateUrl) {
      filters = prepareFilters(this.state.filters, filtersStructure);
      this.props.navigate(`?${filters.join("&")}`, { replace: true });
    }
  };

  handleFilterChange = (filterName, value) => {
    this.setState({ filters: { ...this.state.filters, [filterName]: value } });
  };

  render() {
    return (
      <TlPaper title="מסמכים חשבונאיים" fullPage titleBackground>
        <FiltersBar
          filters={filtersStructure}
          onFilterChanged={this.handleFilterChange}
          values={this.state.filters}
          onSearch={this.loadData}
          isSearching={this.state.isLoading}
        />
        <div style={{ margin: 10 }}>
          <ReceiptsTable
            transactions={this.state.transactions}
            isLoading={this.state.isLoading}
          />
        </div>
      </TlPaper>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedClub: state.auth.selectedClub,
  };
}

export default connect(mapStateToProps)(withRouter(Receipts));
