import React, { useState } from "react";
import { DialogActions, DialogContent, Typography } from "@mui/material";

import Dialog from "../../components/Dialog";
import ButtonSx from "../../components/base/ButtonSx";

export default function CompleteActivityDialog(props) {
  const [isLoading, setIsLoading] = useState(false);

  const handleCompleteActivity = () => {
    setIsLoading(true);
    props.onCompleteActivity();
  };
  return (
    <Dialog
      onClose={props.onClose}
      open={props.open}
      title="סיום פעילות"
      fullWidth
    >
      <DialogContent dividers>
        <Typography gutterBottom>
          האם אתם רוצים לסיים את פעילות קבוצת
          <strong> &quot;{props.team.name}&quot;</strong>?
        </Typography>
        <Typography>שימו לב, פעולה זו לא ניתנת לביטול ולאחר השלמתה:</Typography>
        <ul>
          <li>פעילות כל שחקני הקבוצה תסתיים</li>
          <li>לא ניתן יהיה לרשום ספורטאים חדשים לקבוצה</li>
          <li>לא ניתן יהיה לעדכן או ליצור אירועים</li>
        </ul>
      </DialogContent>
      <DialogActions>
        <ButtonSx onClick={props.onClose}>ביטול</ButtonSx>
        <ButtonSx
          debounce
          onClick={handleCompleteActivity}
          isLoading={isLoading}
        >
          סיום פעילות
        </ButtonSx>
      </DialogActions>
    </Dialog>
  );
}
