import React, { useState } from "react";
import {
  Box,
  CircularProgress,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";

import Dialog from "../../components/Dialog";
import axios from "../../utils/axios";
import DiscountRequestDetails from "./DiscountRequestDetails";
import {
  prepareLocalSubscription,
  validate,
  prepareDataForSubmit,
  handleFeePriceChanged,
  handlePriceChanged,
  handleSettingsNewPrice,
} from "../../components/store/Subscriptions/Direct/editDirectSubscriptionHelper";
import EditDirectSubscriptionForm from "../../components/store/Subscriptions/Direct/EditDirectSubscriptionForm";
import { handleSubscriptionFieldsChanged } from "../../utils/subscriptionHelpers";
import SubscriptionCostSummary from "../../components/store/Subscriptions/Direct/SubscriptionCostSummary";
import withIsXs from "../../components/routing/withIsXs";
import { roundPrice } from "../../utils/helpers";
import _ from "lodash";
import ButtonSx from "../../components/base/ButtonSx";
import Paper2Sx from "../../components/Paper2Sx";

function DiscountRequestDialog(props) {
  const [subscription, setSubscription] = useState(null);
  const [accepted, setAccepted] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const { clubPlayer } = props;
  const originalSubscription = props.subscription;

  const resetSubscription = () => {
    setSubscription(
      props.subscription
        ? prepareLocalSubscription(
            props.subscription,
            props.subscription.product
          )
        : null
    );
  };

  const handleFieldsChanged = (updates) => {
    setSubscription(handleSubscriptionFieldsChanged(updates, subscription));
  };

  const handlePriceChangedLocal = (month, newPriceStr, newPrice) => {
    setSubscription((prev) =>
      handlePriceChanged(prev, month, newPriceStr, newPrice)
    );
  };

  const handleSettingNewPriceLocal = (priceStr, price) => {
    setSubscription((prev) => handleSettingsNewPrice(prev, priceStr, price));
  };

  const handleFeePriceChangedLocal = (index) => (priceStr, price) => {
    setSubscription((prev) =>
      handleFeePriceChanged(index, prev, priceStr, price)
    );
  };

  const validateLocal = () => {
    let { errors, isValid } = validate(subscription);

    const feesPrice = roundPrice(_.sumBy(subscription.fees, "price"));
    const totalPrice = roundPrice(subscription.price + feesPrice);

    const isSamePricing =
      subscription.pricingId === originalSubscription.pricing._id;

    if (isSamePricing && totalPrice >= originalSubscription.totalPrice) {
      isValid = false;
      errors.totalPrice = "יש להזין מחיר נמוך מהמחיר המקורי";
    }
    return { errors, isValid };
  };

  React.useEffect(() => {
    setAccepted("");
    resetSubscription();
  }, [props.subscription, props.open]);

  const handleSave = async () => {
    const { errors, isValid } = validateLocal();
    setSubscription({ ...subscription, errors });
    if (accepted === false || isValid) {
      const data = {
        accepted,
        ...(accepted ? prepareDataForSubmit(subscription) : {}),
      };

      setIsSaving(true);
      await axios.patch(
        `/store/subscriptions/${originalSubscription._id}/discountRequestNg`,
        data
      );
      setIsSaving(false);
      props.onComplete(accepted);
    }
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      title="בקשה מיוחדת"
      fullWidth
      maxWidth="lg"
      fullScreen={props.isXs}
    >
      {originalSubscription && (
        <DialogContent dividers>
          <Grid container spacing={2} alignItems="stretch">
            <Grid
              item
              container
              xs={12}
              sm={5}
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                <Typography variant="h6">האם ברצונך לאשר את הבקשה?</Typography>
              </Grid>
              <Grid item>
                <FormControl variant="standard" component="fieldset">
                  <RadioGroup
                    row
                    value={accepted === "" ? "" : accepted ? "true" : "false"}
                    onChange={(e) => {
                      setAccepted(e.target.value === "true");
                      if (e.target.value === "false") {
                        resetSubscription();
                      }
                    }}
                  >
                    <FormControlLabel
                      value="true"
                      control={<Radio />}
                      label="כן"
                    />
                    <FormControlLabel
                      value="false"
                      control={<Radio />}
                      label="לא"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
            <Grid item xs={false} sm={7} />
            <Grid item container xs={12} alignItems="stretch" spacing={2}>
              <Grid item xs={12} sm={6}>
                <Box sx={styles.rightSideContainer}>
                  <DiscountRequestDetails
                    originalSubscription={originalSubscription}
                    clubPlayer={clubPlayer}
                  />
                  <div style={{ height: 40 }} />
                  {subscription && (
                    <SubscriptionCostSummary
                      subscription={subscription}
                      title="סיכום עלויות"
                    />
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} sx={styles.leftSideContainer}>
                <Paper2Sx
                  externalTitle="פרטי הרישום"
                  paperSx={styles.costsPaper}
                  sx={styles.costPaperContainer}
                >
                  {subscription ? (
                    <EditDirectSubscriptionForm
                      subscription={subscription}
                      onFieldsChanged={handleFieldsChanged}
                      onPriceChanged={handlePriceChangedLocal}
                      onSettingNewPrice={handleSettingNewPriceLocal}
                      onFeePriceChange={handleFeePriceChangedLocal}
                      disabled={accepted !== true}
                    />
                  ) : (
                    <CircularProgress />
                  )}
                </Paper2Sx>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      )}
      <DialogActions>
        <ButtonSx
          onClick={props.onClose}
          disabled={props.isLoading}
          isLoading={isSaving}
        >
          ביטול
        </ButtonSx>
        <ButtonSx
          onClick={handleSave}
          disabled={accepted === ""}
          isLoading={isSaving}
          debounce
        >
          שמירה
        </ButtonSx>
      </DialogActions>
    </Dialog>
  );
}

const styles = {
  rightSideContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
  },
  leftSideContainer: {
    display: "flex",
    flex: 1,
  },
  costsPaper: {
    display: "flex",
    flex: 1,
  },
  costPaperContainer: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
};

export default withIsXs(DiscountRequestDialog);
