import React from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  TextField,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import Grid from "@mui/material/Unstable_Grid2";

import PriceInputNg from "../../../../components/PriceInputNG";

export default function SubscriptionProductFeeItem(props) {
  const { fee, index, editable, header, selectedCell, errors } = props;

  const handleCellFocus = (cell) => () => {
    !props.completed && props.onCellSelected(cell);
  };

  const cellStyle = (cell) => {
    const style = {
      padding: "1px",
      pl: 1,
    };

    if (errors && errors[cell]) {
      style.border = "1px solid red";
    } else if (selectedCell === cell) {
      style.border = "1px solid #0088ff";
    }
    return style;
  };

  const textFieldProps = {
    variant: "standard",
    fullWidth: true,
    margin: "dense",
    InputProps: { disableUnderline: true, readOnly: props.completed },
  };

  return (
    <Box sx={{ ...styles.container, maxHeight: header ? "30px" : "48px" }}>
      <Grid container alignItems="center" sx={{ flex: 1 }}>
        <Grid xs="auto">
          <Box sx={{ width: "30px" }}>
            {editable && !header && <DragIndicatorIcon sx={{ mt: 1 }} />}
          </Box>
        </Grid>
        <Grid xs sx={cellStyle("description")}>
          {header ? (
            <Box sx={styles.headerTitle}>תיאור</Box>
          ) : (
            <TextField
              {...textFieldProps}
              value={fee.description}
              fullWidth
              onChange={props.onChange("description", index)}
              inputProps={{ maxLength: 40 }}
              error={errors?.description !== undefined}
              onFocus={handleCellFocus("description")}
              onBlur={handleCellFocus(null)}
            />
          )}
        </Grid>
        <Grid xs={2} sx={cellStyle("price")}>
          {header ? (
            <Box sx={styles.headerTitle}>מחיר</Box>
          ) : (
            <PriceInputNg
              {...textFieldProps}
              value={fee.priceStr}
              onChange={props.onPriceChange(index)}
              error={errors?.price !== undefined}
              onFocus={handleCellFocus("price")}
              onBlur={handleCellFocus(null)}
            />
          )}
        </Grid>
        {props.requireAccountingExportNumber && (
          <Grid xs={2} sx={cellStyle("aen")}>
            {header ? (
              <Box sx={styles.headerTitle}>מפתח חשבון</Box>
            ) : (
              <TextField
                {...textFieldProps}
                fullWidth
                value={fee.aen}
                onChange={props.onChange("aen", index)}
                inputProps={{ maxLength: 40 }}
                error={errors?.aen !== undefined}
                onFocus={handleCellFocus("aen")}
                onBlur={handleCellFocus(null)}
              />
            )}
          </Grid>
        )}

        <Grid xs={2} sx={cellStyle("title")}>
          {header ? (
            <Box sx={styles.headerTitle}>מוצר למסירה</Box>
          ) : (
            <FormControlLabel
              control={
                <Checkbox
                  checked={fee.deliverable}
                  onChange={props.onDeliverabilityChange(index)}
                  disabled={props.completed}
                  color="primary"
                  sx={{ ml: "2px" }}
                />
              }
            />
          )}
        </Grid>
      </Grid>

      <Box sx={{ width: 50, display: "flex", justifyContent: "flex-end" }}>
        {!header && (
          <IconButton onClick={props.onDelete} disabled={!editable}>
            <DeleteIcon />
          </IconButton>
        )}
      </Box>
    </Box>
  );
}

const styles = {
  container: {
    border: "1px solid #EEE",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flex: 1,
  },
  headerTitle: {
    pl: "1px",
  },
};
