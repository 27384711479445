import React from "react";

import Grid from "@mui/material/Grid";
import _ from "lodash";
import { connect } from "react-redux";
import ReactGA from "react-ga4";

import axios from "../../utils/axios";
import TeamAttendance from "./TeamAttendance";
import TeamDetails from "./TeamDetails";
import RegisteredPlayers from "./RegisteredPlayers";
import UnregisteredPlayers from "./UnregisteredPlayers";
import clubConfig from "../../config/clubs";
import SnackbarNg from "../../components/base/SnackbarNg";
import PageNotFound from "../../components/PageNotFound";
import SubscriptionProductDetails from "./SubscriptionProductDetails";
import * as Actions from "../../store/actions";
import withParams from "../../components/routing/withParams";
import Quitters from "./Quitters";
import TeamSubscriptionLogsDialog from "./TeamSubscriptionLogsDialog";
import withBreakpoints from "../../components/routing/withBreakpoints";
import TlPaper from "../../components/TlPaper";

class Team extends React.Component {
  state = {
    team: {},
    isLoading: true,
    selectedTab: -1,
    snackbar: {
      open: false,
      type: null,
    },
    isSubscriptionLogsDialogOpen: false,
  };

  componentDidMount = () => {
    ReactGA.send({
      hitType: "pageview",
      page: "/teams/:id",
      title: "Team",
    });
    this.loadData();
  };

  componentDidUpdate = async (prevProps) => {
    if (prevProps.params.teamId !== this.props.params.teamId) {
      this.loadData();
    }
  };

  normalizeSubscriptions = (sub) => {
    const { clubPlayer } = sub;
    delete sub.clubPlayer;
    return {
      ...clubPlayer,
      subscriptions: [sub],
    };
  };

  loadData = async () => {
    this.setState({ isLoading: true });
    let team;
    let subscriptions;
    let notFound = false;
    const promises = [];
    promises.push(
      axios
        .get(
          `/teams/${this.props.params.teamId}?granularity=clubCoach&granularity=assistants&granularity=productAndSubProducts&granularity=season&granularity=canChangeProduct`
        )
        .then((res) => {
          team = res.data;
        })
        .catch((error) => {
          if (error.response && error.response.status === 404) {
            notFound = true;
          }
        })
    );

    promises.push(
      axios
        .get(`/teams/${this.props.params.teamId}/subscriptions`)
        .then((res) => {
          subscriptions = res.data;
        })
        .catch(() => {})
    );

    await Promise.all(promises);

    if (notFound) {
      this.setState({ teamNotFound: true });
      return;
    }

    const registeredPlayers = _.sortBy(
      subscriptions.registered.map(this.normalizeSubscriptions),
      "name"
    );

    const quitters = _.sortBy(
      subscriptions.quitters.map(this.normalizeSubscriptions),
      "name"
    );
    let players = _.sortBy(
      _.uniqBy([...registeredPlayers, ...quitters], "_id"),
      "name"
    );

    const unregisteredPlayers = _.sortBy(subscriptions.trial, "name");

    this.setState({
      team,
      players,
      registeredPlayers,
      quitters,
      unregisteredPlayers,
      isLoading: false,
    });
  };

  handleArchive = async () => {
    try {
      await axios.patch(`/teams/${this.state.team._id}/archive`);
      this.setState({ snackbar: { open: true, type: "success" } });
      this.loadData();
    } catch (error) {
      this.setState({ snackbar: { open: true, type: "error" } });
    }
  };

  handleCompleteActivity = async () => {
    try {
      await axios.patch(`/teams/${this.state.team._id}/completeActivity`);
      this.setState({ snackbar: { open: true, type: "success" } });
      this.loadData();
      this.props.loadNotifications();
    } catch (error) {
      this.setState({ snackbar: { open: true, type: "error" } });
    }
  };

  handleDelete = async () => {
    try {
      await axios.delete(`/teams/${this.state.team._id}`);
      // this.setState({ snackbar: { open: true, type: "success" } });
      return true;
    } catch (error) {
      // this.setState({ snackbar: { open: true, type: "error" } });
      return false;
    }
  };

  render() {
    const teamId = this.props.params.teamId;

    if (this.state.teamNotFound) {
      return <PageNotFound>הקבוצה שחיפשתם לא קיימת</PageNotFound>;
    }

    return (
      <>
        <TeamSubscriptionLogsDialog
          open={this.state.isSubscriptionLogsDialogOpen}
          onClose={() => this.setState({ isSubscriptionLogsDialogOpen: false })}
          team={this.state.team}
          players={this.state.players}
        />
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} sx={{ display: "flex" }}>
            <TeamDetails
              isLoading={this.state.isLoading}
              team={this.state.team}
              onChange={this.loadData}
              clubCategory={
                clubConfig[this.props.selectedClub.internalName].category
              }
              registeredPlayers={this.state.registeredPlayers || []}
              onArchive={this.handleArchive}
              onCompleteActivity={this.handleCompleteActivity}
              onDelete={this.handleDelete}
              user={this.props.user}
            />
          </Grid>

          <Grid item xs={12} sm={6} sx={{ display: "flex" }}>
            <SubscriptionProductDetails
              team={this.state.team}
              isLoading={this.state.isLoading}
            />
          </Grid>

          <Grid item xs={12}>
            <RegisteredPlayers
              team={this.state.team}
              players={this.state.registeredPlayers}
              isLoading={this.state.isLoading}
              directPayments={this.state.team.productId}
              onComplete={this.loadData}
              onSubscriptionLogsClicked={() =>
                this.setState({ isSubscriptionLogsDialogOpen: true })
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Quitters
              team={this.state.team}
              players={this.state.quitters}
              isLoading={this.state.isLoading}
              directPayments={this.state.team.productId}
              onComplete={this.loadData}
            />
          </Grid>
          {this.props.isMatching && (
            <>
              <Grid item xs={12}>
                <UnregisteredPlayers
                  team={this.state.team}
                  players={this.state.unregisteredPlayers}
                  isLoading={this.state.isLoading}
                  directPayments={this.state.team.productId}
                  onComplete={this.loadData}
                />
              </Grid>
              <Grid item xs={12}>
                <TlPaper title="נוכחות" titleBackground>
                  <TeamAttendance
                    teamId={teamId}
                    players={this.state.registeredPlayers}
                    isLoading={this.state.isLoading}
                  />
                </TlPaper>
              </Grid>
            </>
          )}
        </Grid>
        <SnackbarNg
          snackKey={this.state.team._id}
          open={this.state.snackbar.open}
          onClose={() => this.setState({ snackbar: { open: false } })}
          severity={this.state.snackbar.type}
          message={
            this.state.snackbar.type === "success"
              ? "הפעולה בוצעה בהצלחה"
              : "אירעה שגיאה. אנא נסו מאוחר יותר"
          }
        />
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedClub: state.auth.selectedClub,
    user: state.auth.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loadNotifications: () =>
      dispatch(Actions.general.loadNotificationBarCounters()),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withBreakpoints("mdUp", withParams(Team)));
