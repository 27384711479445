import React, { useEffect, useMemo, useState } from "react";
import {
  Checkbox,
  DialogActions,
  DialogContent,
  Grid,
  TableContainer,
  useMediaQuery,
} from "@mui/material";
import moment from "moment";

import FiltersBar from "../../../components/filters/FiltersBar";
import axios from "../../../utils/axios";
import ButtonSx from "../../../components/base/ButtonSx";
import InfoRow from "../../../components/InfoRows/InfoRow";
import {
  displayDate,
  displayDateTime,
  displayPrice,
  roundPrice,
} from "../../../utils/helpers";
import DepositConfirmationDialog from "./DepositConfirmationDialog";
import { prepareFilters } from "../../../components/filters/prepareFilters";
import Table from "../../../components/table/Table";
import { renderCell, sortValueCell } from "../../../lib/tableHelpers";
import Paper2Sx from "../../../components/Paper2Sx";
import Dialog from "../../../components/Dialog";

const filtersStructure = [
  { type: "date", calendarProps: { disableFuture: true } },
];

export default function CreateDepositDialog(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);
  const [data, setData] = useState([]);
  const [filters, setFilters] = useState({
    date: {
      from: moment().add(-1, "month").startOf("day"),
      to: moment().endOf("day"),
    },
  });
  const isMdUp = useMediaQuery((t) => t.breakpoints.up("md"), { noSsr: true });

  useEffect(() => {
    if (props.open) {
      loadData();
    } else {
      setData([]);
      setIsLoading(false);
    }
  }, [props.open]);

  const checkedCount = useMemo(
    () => Object.values(data).filter((i) => i.checked).length,
    [data]
  );

  const loadData = async () => {
    setIsLoading(true);
    const preparedFilters = prepareFilters(filters, filtersStructure);

    const res = await axios.get(
      `/payments/deposits/candidates?${preparedFilters.join("&")}`
    );

    // datum.index is used as the row index
    res.data.forEach((d, i) => (d.rowId = i));

    setData(res.data);
    setIsLoading(false);
  };

  const handleSave = async (depositDate) => {
    setIsLoading(true);
    const cashTransactions = data.filter(
      (i) => i.kind === "CashTransaction" && i.checked
    );
    const checkTransactions = data.filter(
      (i) => i.kind === "CheckTransaction" && i.checked
    );
    const sendData = {
      depositDate: depositDate.format("YYYY-MM-DD"),
      cashTransactions: cashTransactions.map((i) => i._id),
      checkTransactions: checkTransactions.map((i) => ({
        _id: i._id,
        checkId: i.checks._id,
      })),
    };

    await axios.post(`/payments/deposits`, sendData);
    setIsLoading(false);
    setIsConfirmationDialogOpen(false);
    props.onComplete();
  };

  const renderHeaderCheckBox = () => {
    return (
      <span>
        <Checkbox
          indeterminate={checkedCount > 0 && checkedCount < data.length}
          checked={data.length > 0 && checkedCount === data.length}
          onClick={handleSelectAll}
          inputProps={{ "aria-label": "select all" }}
        />
        תאריך ושעה
      </span>
    );
  };

  const handleSelectAll = (e) => {
    e.stopPropagation();
    const checked = checkedCount !== data.length;
    setData((prevData) => prevData.map((datum) => ({ ...datum, checked })));
  };

  const handleItemSelectToggle = (record) => () => {
    setData((prevData) =>
      prevData.map((datum) => {
        return datum._id === record._id &&
          (datum.kind === "CashTransaction" ||
            datum.checks._id === record.checks?._id)
          ? { ...datum, checked: !datum.checked }
          : { ...datum };
      })
    );
  };

  const columns = () => [
    {
      id: "createdAt",
      label: renderHeaderCheckBox(),
      minWidth: 180,
      baseRenderer: true,
    },
    { id: "details", label: "פרטים", baseRenderer: true, minWidth: 160 },
    { id: "amount", label: "סכום", preset: "price", baseRenderer: true },
    { id: "transactionId", label: "קבלה", preset: "link" },
    { id: "customer", label: "לקוח", baseRenderer: true },
  ];

  const baseRenderer = (columnId, record) => {
    const check = record.checks;
    switch (columnId) {
      case "createdAt":
        return (
          <span>
            <Checkbox
              checked={record.checked === true}
              onClick={handleItemSelectToggle(record)}
            />
            {record.kind === "CheckTransaction"
              ? displayDate(record.checks.date)
              : displayDateTime(record.createdAt)}
          </span>
        );
      case "transactionId":
        return {
          to: `../payments/transactions/${record._id}`,
          label: record.receiptNumber,
        };

      case "customer":
        return record.payer?.name;

      case "details":
        return record.kind === "CheckTransaction"
          ? `צ׳ק מספר ${check.checkNumber}, ${check.bank}-${check.branch}-${check.accountNumber}`
          : "מזומן";

      case "amount":
        return record.kind === "CheckTransaction"
          ? check.amount
          : record.amount;

      default:
        return record[columnId];
    }
  };

  const sortRenderer = (columnId, record) => {
    switch (columnId) {
      case "createdAt":
        return record.kind === "CheckTransaction"
          ? record.checks.date
          : record.createdAt;

      default:
        return record[columnId];
    }
  };

  const handleFilterChange = (filterName, value) => {
    setFilters((prevValue) => ({ prevValue, [filterName]: value }));
  };

  const currentColumns = columns();
  const sums = useMemo(
    () =>
      data
        .filter((i) => i.checked)
        .reduce(
          (sum, cur) => {
            if (cur.kind === "CashTransaction") {
              sum.cash.count += 1;
              sum.cash.amount += cur.amount;
            } else {
              sum.check.count += 1;
              sum.check.amount += cur.checks.amount;
            }
            return sum;
          },
          {
            cash: { amount: 0, count: 0 },
            check: { amount: 0, count: 0 },
          }
        ),
    [data]
  );

  const renderContent = () => (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper2Sx fullPage externalTitle="חיפוש צ׳קים ועסקאות מזומן להפקדה">
            <FiltersBar
              filters={filtersStructure}
              onFilterChanged={handleFilterChange}
              values={filters}
              onSearch={loadData}
              isSearching={isLoading}
              autoSizing={isMdUp ? 4 : 12}
            />
            <TableContainer sx={styles.tableContainer}>
              <Table
                columns={currentColumns}
                renderCell={renderCell(currentColumns, false, baseRenderer)}
                sortValueCell={sortValueCell(
                  currentColumns,
                  baseRenderer,
                  sortRenderer
                )}
                renderKey={(row) => row.rowId}
                rows={data}
                title={"פריטים"}
                isLoading={isLoading}
                stickyHeader
              />
            </TableContainer>
          </Paper2Sx>
        </Grid>
        <Grid item container xs={12} justifyContent="space-around">
          <Grid item xs>
            <InfoRow
              title="מזומן"
              value={`${displayPrice(sums.cash.amount)} (${sums.cash.count})`}
            />
          </Grid>
          <Grid item xs>
            <InfoRow
              title="צ׳קים"
              value={`${displayPrice(sums.check.amount)} (${sums.check.count})`}
            />
          </Grid>
          <Grid item xs>
            <InfoRow
              title='סה"כ'
              value={`${displayPrice(
                roundPrice(sums.check.amount + sums.cash.amount)
              )} (${sums.check.count + sums.cash.count})`}
            />
          </Grid>
        </Grid>
      </Grid>
      <DepositConfirmationDialog
        open={isConfirmationDialogOpen}
        onClose={() => setIsConfirmationDialogOpen(false)}
        onComplete={handleSave}
        sums={sums}
        isLoading={isLoading}
      />
    </>
  );

  return (
    <Dialog
      onClose={props.onClose}
      open={props.open}
      fullScreen
      sx={styles.dialog}
      title="יצירת הפקדה חדשה"
      closeButton
    >
      <DialogContent dividers>{renderContent()}</DialogContent>
      <DialogActions>
        <ButtonSx
          sx={styles.button}
          onClick={props.onClose}
          disabled={isLoading}
        >
          ביטול
        </ButtonSx>
        <ButtonSx
          sx={styles.button}
          onClick={() => setIsConfirmationDialogOpen(true)}
          isLoading={isLoading}
          debounce
          disabled={checkedCount === 0}
        >
          המשך
        </ButtonSx>
      </DialogActions>
    </Dialog>
  );
}

const styles = {
  dialog: {
    width: { xs: undefined, md: 900 },
    zIndex: 1100,
  },
  tableContainer: {
    maxHeight: "calc(100vh - 300px)",
  },
  button: {
    width: 100,
  },
};
