import React from "react";
import { TextField, Popover, Grid } from "@mui/material";

export default function AmountFilter(props) {
  const [amountOpen, setAmountOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  let value = "";
  if (props.amounts.from !== "" && props.amounts.to !== "") {
    value = `\u20AA${props.amounts.from} - \u20AA${props.amounts.to}`;
  } else if (props.amounts.from !== "") {
    value = `יותר מ ${props.amounts.from} \u20AA`;
  } else if (props.amounts.to !== "") {
    value = `פחות מ ${props.amounts.to} \u20AA`;
  }

  return (
    <>
      <TextField
        ref={anchorRef}
        id="outlined-select-currency"
        label="סכום"
        size="small"
        onClick={() => setAmountOpen(true)}
        InputProps={{ readOnly: true }}
        value={value}
        fullWidth={props.fullWidth}
      />
      <Popover
        dir="rtl"
        open={amountOpen}
        anchorEl={anchorRef.current}
        onClose={() => {
          setAmountOpen(false);
          props.onFilterChanged();
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Grid
          container
          spacing={1}
          sx={{ justifyContent: "center", width: 250 }}
        >
          <Grid item>
            <TextField
              label="מ"
              size="small"
              margin="dense"
              sx={{ width: 100 }}
              type="number"
              value={props.tempAmounts.from}
              onChange={(event) => {
                props.onAmountChanged("from", event.target.value);
              }}
            />
          </Grid>
          <Grid item>
            <TextField
              label="עד"
              margin="dense"
              size="small"
              sx={{ width: 100 }}
              type="number"
              value={props.tempAmounts.to}
              onChange={(event) =>
                props.onAmountChanged("to", event.target.value)
              }
            />
          </Grid>
        </Grid>
      </Popover>
    </>
  );
}
