import React from "react";
import { Navigate } from "react-router-dom";
import { connect } from "react-redux";
import { useEffect } from "react";

import * as Actions from "./../../store/actions";

function NavigateToDefaultClub(props) {
  useEffect(() => {
    if (!props.selectedClub) {
      props.changeToLastClub();
    }
  }, [props.selectedClub]);

  if (props.selectedClub) {
    return (
      <Navigate to={`/clubs/${props.selectedClub.internalName}`} replace />
    );
  } else {
    return <div />;
  }
}

function mapStateToProps(state) {
  return {
    selectedClub: state.auth.selectedClub,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changeToLastClub: () => dispatch(Actions.auth.changeToLastClub()),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NavigateToDefaultClub);
