import React from "react";
import { useMediaQuery, Typography, Box } from "@mui/material";

export default function StepTitle({ title, purchaseType }) {
  const isSmUp = useMediaQuery((theme) => theme.breakpoints.up("sm"), {
    noSsr: true,
  });

  if (isSmUp) {
    return <Box sx={{ height: 30 }} />;
  }

  let prefix = "";
  if (purchaseType === "merchandise") {
    prefix = "חנות - ";
  } else if (purchaseType === "subscription") {
    prefix = "הרשמה - ";
  }

  return (
    <Typography variant="h6" sx={styles.text}>
      {prefix}
      {title}
    </Typography>
  );
}

const styles = {
  text: {
    padding: "20px",
    paddingBottom: "10px",
    marginTop: "-25px",
    textAlign: "center",
  },
};
