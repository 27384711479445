import React from "react";
import { Box, CircularProgress } from "@mui/material";
import moment from "moment";

import InfoRow from "../../InfoRows/InfoRow";
import CancelSubscriptionDialog from "./CancelSubscription/CancelSubscriptionDialog";
import ChangeIndirectTeamDialog from "../../players/ChangeIndirectTeamDialog";
import RemoveTrialPlayerFromTeamDialog from "../../players/RemoveTrialPlayerFromTeamDialog";
import SubscriptionDialog from "./SubscriptionDialog";
import ButtonSx from "../../base/ButtonSx";

export default function IndirectSubscription(props) {
  const [isCancelDialogOpen, setIsCancelDialogOpen] = React.useState(false);
  const [isChangeTeamDialogOpen, setIsChangeTeamDialogOpen] =
    React.useState(false);
  const [isRemoveFromTeamDialogOpen, setIsRemoveFromTeamDialogOpen] =
    React.useState(false);

  const teamRow = (
    <>
      <InfoRow
        title="קבוצה"
        value={props.subscription.team.name}
        linkTo={`../teams/${props.subscription.team._id}`}
        editable={
          props.subscription &&
          !props.subscription.trial &&
          props.subscription.status === "active"
        }
        editType="dialog"
        onEdit={() => setIsChangeTeamDialogOpen(true)}
      />
      <ChangeIndirectTeamDialog
        player={props.player}
        subscription={props.subscription}
        open={isChangeTeamDialogOpen}
        onClose={() => setIsChangeTeamDialogOpen(false)}
        onComplete={() => {
          props.onComplete();
          setIsChangeTeamDialogOpen(false);
        }}
      />
    </>
  );

  const renderRegistered = () => {
    return (
      <>
        <CancelSubscriptionDialog
          open={isCancelDialogOpen}
          onClose={() => setIsCancelDialogOpen(false)}
          onComplete={() => {
            props.onComplete();
            setIsCancelDialogOpen(false);
          }}
          subscription={props.subscription}
          direct={false}
        />

        {teamRow}
        {props.subscription.cancelledAt && (
          <>
            <InfoRow
              title="סיום פעילות"
              value={moment(props.subscription.cancelledAt).format("ll")}
            />
            <InfoRow
              title="סיבת ביטול"
              value={props.subscription.cancellationReason}
            />
          </>
        )}

        {props.subscription.status === "active" && (
          <Box sx={styles.actionsRow}>
            <ButtonSx
              variant="outlined"
              sx={styles.button}
              onClick={() => setIsCancelDialogOpen(true)}
            >
              הפסקת פעילות
            </ButtonSx>
          </Box>
        )}
      </>
    );
  };

  const renderUnregistered = () => {
    const trialAttendances = props.player.trailInfo
      ? props.player.trailInfo.attendances || 0
      : 0;
    return (
      <>
        <InfoRow title="שיעורי ניסיון" value={trialAttendances} />
        <Box sx={styles.actionsRow}>
          {props.subscription.team && (
            <>
              <ButtonSx
                margin="dense"
                onClick={() => setIsRemoveFromTeamDialogOpen(true)}
                sx={{ marginLeft: "15px" }}
              >
                הסרה מהקבוצה
              </ButtonSx>
              <RemoveTrialPlayerFromTeamDialog
                player={props.player}
                team={props.subscription.team}
                open={isRemoveFromTeamDialogOpen}
                onClose={() => setIsRemoveFromTeamDialogOpen(false)}
                onComplete={() => {
                  props.onComplete(null, true);
                  setIsRemoveFromTeamDialogOpen(false);
                }}
              />
            </>
          )}
        </Box>
      </>
    );
  };

  return props.isLoading ? (
    <CircularProgress />
  ) : (
    <Box sx={styles.container}>
      <SubscriptionDialog
        open={props.open}
        onClose={props.onClose}
        isLoading={props.isLoading}
        title={props.subscription?.team.name}
        content={
          props.subscription && !props.subscription.trial
            ? renderRegistered()
            : renderUnregistered()
        }
      />
    </Box>
  );
}

const styles = {
  container: {
    border: "0.5px solid #EFEFEF",
    borderRadius: "10px",
    padding: "10px",
    margin: "10px",
  },
  button: {
    width: 200,
    margin: "5px",
  },
  actionsRow: {
    marginTop: "20px",
    display: "flex",
    justifyContent: "flex-end",
  },
};
