import React from "react";
import {
  Grid,
  Backdrop,
  CircularProgress,
  Divider,
  Checkbox,
  FormControlLabel,
} from "@mui/material";

import PaymentMethod from "./PaymentMethod";
import StepTitle from "../../containers/Purchase/StepTitle";
import { displayPrice } from "../../utils/helpers";
import Paper2Sx from "../Paper2Sx";

export default class PaymentForm extends React.Component {
  state = { open: false };

  render() {
    const amount = this.props.cart.totals.totalPaymentAmounts;
    return (
      <>
        <StepTitle title="פרטי תשלום" purchaseType={this.props.purchaseType} />
        <Paper2Sx paperSx={{ p: "10px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div style={{ fontSize: 18, padding: 10 }}>
                סה״כ לתשלום: <b>{displayPrice(amount)}</b>
              </div>
              <Divider
                style={{ marginLeft: -10, marginRight: -10, marginTop: 5 }}
              />
            </Grid>

            <Grid item xs={12}>
              <PaymentMethod
                open={this.state.open}
                state={this.props.state}
                onChange={this.props.onChange}
                products={this.props.products}
                cart={this.props.cart}
                store={this.props.store}
                clubConfig={this.props.clubConfig}
                disabled={amount === 0}
                availablePaymentMethods={this.props.availablePaymentMethods}
                purchaseType={this.props.purchaseType}
                paymentAmount={amount}
              />
            </Grid>
            {this.props.remoteClubConfig.donationReceipts && (
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={amount === 0}
                      checked={this.props.state.donationReceipt}
                      onChange={(e) =>
                        this.props.onChange({
                          donationReceipt: e.target.checked,
                        })
                      }
                    />
                  }
                  label="קבלה על תרומה"
                />
              </Grid>
            )}
          </Grid>
        </Paper2Sx>
        <Backdrop open={this.props.isSending}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </>
    );
  }
}
