import React from "react";
import { Grid, Divider, Paper } from "@mui/material";
import moment from "moment";

import clubConfig from "../../config/clubs";
import axios from "../../utils/axios";
import { displayPriceTruncated } from "../../utils/helpers";
import CountItem from "./CountItem";
import ChartItem from "./ChartItem";
import RegisteredPlayersCountItem from "./RegisteredPlayersCountItem";
import withBreakpoints from "../../components/routing/withBreakpoints";
import TlPaper from "../../components/TlPaper";

class Counts extends React.Component {
  state = {
    registrationStatus: {},
    registeredPlayersByTeamType: [],
    transactions: {},
    teams: {},
    isLoading: true,
  };

  prepareTransactionDataForChartByType = (data, summaryType) => {
    return ["CreditCard", "Check", "Cash", "WireTransfer"].map(
      (type) => data[`${type}_${summaryType}`]
    );
  };

  prepareTransactionDataForChartBySource = (data, summaryType) => {
    return ["web", "backOffice"].map((type) => data[`${type}_${summaryType}`]);
  };

  prepareRegisteredPlayersByTeamType = (data) => {
    return ["league", "school", "camp"].map((type) => data[type] || 0);
  };

  componentDidMount = async () => {
    this.setState({ isLoading: true });

    const res = await axios.get(`/dashboard/statusNG`);
    const transactions = res.data.transactions;

    this.setState({
      teams: res.data.teams,
      registrationStatus: res.data.clubPlayers,
      registeredPlayersByTeamType: this.prepareRegisteredPlayersByTeamType(
        res.data.registeredPlayersByTeamType
      ),
      transactions: {
        total: transactions.total,
        lastDay: transactions.lastDay,
        last7Days: transactions.last7Days,
        byTypeCount: this.prepareTransactionDataForChartByType(
          transactions.byType,
          "count"
        ),
        byTypeTotal: this.prepareTransactionDataForChartByType(
          transactions.byType,
          "total"
        ),
        bySourceCount: this.prepareTransactionDataForChartBySource(
          transactions.bySource,
          "count"
        ),
        bySourceTotal: this.prepareTransactionDataForChartBySource(
          transactions.bySource,
          "total"
        ),
      },
      isLoading: false,
      fromTime: moment(
        this.props.selectedClub.clubConfig.dashboardTransactionStartDate
      ),
    });
  };

  render() {
    const config = clubConfig[this.props.selectedClub.internalName];
    const hasPayments = config.payments.enabled;
    const hideTotals =
      config.hideTotals === true &&
      this.props.user &&
      this.props.user.level && //until auto reloading user
      this.props.user.level !== "admin";

    const showPayments =
      hasPayments && !hideTotals && this.props.hasPaymentsReoprtsPermission;

    return (
      <>
        <Paper>
          <Grid container>
            {this.props.hasHrPermission && (
              <Grid item xs={12} container>
                <Grid item xs={6} md={4} sx={{ borderRight: "1px solid #EEE" }}>
                  <RegisteredPlayersCountItem
                    isLoading={this.state.isLoading}
                    count={this.state.registrationStatus.registered || 0}
                    distribution={this.state.registeredPlayersByTeamType}
                    superClub={this.props.superClub}
                  />
                </Grid>
                {this.props.isMatching && (
                  <>
                    <Grid
                      item
                      xs={6}
                      md={4}
                      sx={{ borderRight: "1px solid #EEE" }}
                    >
                      <CountItem
                        isLoading={this.state.isLoading}
                        title="ספורטאים לא רשומים"
                        count={this.state.registrationStatus.trial || 0}
                        color="color1"
                        linkTo="players?status=trial"
                        superClub={this.props.superClub}
                      />
                    </Grid>
                  </>
                )}
                <Grid item xs={6} md={4}>
                  <CountItem
                    isLoading={this.state.isLoading}
                    title="קבוצות"
                    count={this.state.teams.count}
                    color="color1"
                    linkTo="teams"
                    superClub={this.props.superClub}
                  />
                </Grid>
              </Grid>
            )}
            {showPayments && (
              <>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12} container>
                  {this.props.isMatching && (
                    <>
                      <Grid
                        item
                        xs={6}
                        md={4}
                        sx={{ borderRight: "1px solid #EEE" }}
                      >
                        <CountItem
                          isLoading={this.state.isLoading}
                          title="סה״כ עסקאות"
                          count={displayPriceTruncated(
                            this.state.transactions.total || 0
                          )}
                          primary
                          color="color2"
                          linkTo={
                            this.state.fromTime
                              ? `payments/transactions?dateFrom=${this.state.fromTime.format(
                                  "YYYY-MM-DD"
                                )}&dateTo=${moment().format("YYYY-MM-DD")}`
                              : null
                          }
                          superClub={this.props.superClub}
                        />
                      </Grid>
                    </>
                  )}
                  <Grid
                    item
                    xs={6}
                    md={4}
                    sx={{ borderRight: "1px solid #EEE" }}
                  >
                    <CountItem
                      isLoading={this.state.isLoading}
                      title="7 ימים אחרונים"
                      count={displayPriceTruncated(
                        this.state.transactions.last7Days || 0
                      )}
                      color="color2"
                      linkTo={`payments/transactions?dateFrom=${moment()
                        .add(-6, "days")
                        .format("YYYY-MM-DD")}&dateTo=${moment().format(
                        "YYYY-MM-DD"
                      )}`}
                      superClub={this.props.superClub}
                    />
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <CountItem
                      isLoading={this.state.isLoading}
                      title="היום"
                      count={displayPriceTruncated(
                        this.state.transactions.lastDay || 0
                      )}
                      color="color2"
                      linkTo={`payments/transactions?dateFrom=${moment().format(
                        "YYYY-MM-DD"
                      )}&dateTo=${moment().format("YYYY-MM-DD")}`}
                      superClub={this.props.superClub}
                    />
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </Paper>
        {showPayments && this.props.isMatching && (
          <TlPaper title="התפלגות תשלומים" sx={{ mt: 5 }} titleBackground>
            <Grid container>
              <Grid item xs={5}>
                <ChartItem
                  isLoading={this.state.isLoading}
                  series={this.state.transactions.bySourceCount}
                  labels={["הורים", "מועדון"]}
                  title="התפלגות לפי מבצע התשלום"
                />
              </Grid>

              <Grid item xs={2} />
              <Grid item xs={5}>
                <ChartItem
                  isLoading={this.state.isLoading}
                  series={this.state.transactions.byTypeCount}
                  labels={["כרטיס אשראי", "צ׳ק", "מזומן", "העברה בנקאית"]}
                  title="התפלגות לפי אמצעי התשלום"
                />
              </Grid>
            </Grid>
          </TlPaper>
        )}
      </>
    );
  }
}

export default withBreakpoints("mdUp", Counts);
