import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Slide,
  Typography,
  Link,
  Breadcrumbs,
  List,
  Button,
  Box,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import SettingsIcon from "@mui/icons-material/Settings";
import ArrowIcon from "@mui/icons-material/NavigateBeforeSharp";

import IconButtonTooltip from "../../../components/base/IconButtonTooltip";
import CalendarResources from "./CalendarResources";
import ResourceGroups from "./ResourceGroups";
import ListItem from "../../../components/ListItem";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});

export default function CalendarSettings(props) {
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const [selectedSetting, setSelectedSetting] = React.useState(null);
  const [selectedSubSetting, setSelectedSubSetting] = React.useState(null);
  const [hasUpdated, setHasUpdated] = useState(false);

  const settings = { resources: "מגרשים", resourceGroups: "קבוצות מגרשים" };

  const toggleDialogOpen = () => {
    if (isDialogOpen && hasUpdated) {
      props.onUpdate();
    }
    setIsDialogOpen(!isDialogOpen);
    setSelectedSetting(null);
    setSelectedSubSetting(null);
    setHasUpdated(false);
  };

  const handleSelectSetting = (setting) => (e) => {
    e.preventDefault();
    setSelectedSetting(setting);
    setSelectedSubSetting(null);
  };

  const handleChange = () => {
    setHasUpdated(true);
  };

  const renderContent = () => {
    switch (selectedSetting) {
      case "resources":
        return <CalendarResources onChange={handleChange} />;

      case "resourceGroups":
        return (
          <ResourceGroups
            resource={props.resources}
            selectedGroup={selectedSubSetting}
            onSelectSubSetting={(group) => setSelectedSubSetting(group)}
            onChange={handleChange}
          />
        );

      default:
        return (
          <List
            disablePadding
            sx={{ border: "1px solid #DDD", borderRadius: 1, mt: 1 }}
          >
            {Object.entries(settings).map(([setting, title]) => (
              <ListItem
                divider={setting === "resources"}
                key={setting}
                button
                primaryText={title}
                listItemProps={{
                  onClick: handleSelectSetting(setting),
                }}
              />
            ))}
          </List>
        );
    }
  };

  const handleGoBack = (e) => {
    e.preventDefault();
    if (selectedSubSetting) {
      setSelectedSubSetting(null);
    } else {
      setSelectedSetting(null);
    }
  };

  return (
    <>
      <IconButtonTooltip
        title="הגדרות"
        onClick={toggleDialogOpen}
        tooltipProps={{ arrow: true }}
        iconButtonProps={{ size: "small" }}
      >
        <SettingsIcon />
      </IconButtonTooltip>
      <Dialog
        open={isDialogOpen}
        onClose={toggleDialogOpen}
        fullScreen
        maxWidth="sm"
        sx={styles.dialog}
        dir="rtl"
        TransitionComponent={Transition}
      >
        <DialogTitle>
          <Box sx={styles.titleContainer}>
            הגדרות
            <IconButton size="small" onClick={toggleDialogOpen}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>

        <DialogContent dividers>
          <Box sx={styles.header}>
            <Breadcrumbs>
              <Link
                color="inherit"
                href="/"
                onClick={handleSelectSetting(null)}
                underline="hover"
              >
                הגדרות
              </Link>
              {selectedSetting &&
                (selectedSubSetting ? (
                  <Link
                    color="inherit"
                    href="/"
                    onClick={handleSelectSetting(selectedSetting)}
                    underline="hover"
                  >
                    {settings[selectedSetting]}
                  </Link>
                ) : (
                  <Typography color="textPrimary">
                    {settings[selectedSetting]}
                  </Typography>
                ))}
              {selectedSubSetting && (
                <Typography color="textPrimary">
                  {selectedSubSetting.name}
                </Typography>
              )}
            </Breadcrumbs>
            {selectedSetting && (
              <Button onClick={handleGoBack} endIcon={<ArrowIcon />}>
                חזור
              </Button>
            )}
          </Box>
          {renderContent()}
        </DialogContent>
      </Dialog>
    </>
  );
}

const styles = {
  dialog: {
    width: 600,
    zIndex: 11,
    marginTop: "72px",
  },
  titleContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
};
