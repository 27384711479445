import React, { useState, useEffect } from "react";
import { DialogActions, DialogContent } from "@mui/material";
import _ from "lodash";

import Dialog from "../../../components/Dialog";
import EditDirectSubscriptionForm from "../../../components/store/Subscriptions/Direct/EditDirectSubscriptionForm";
import ButtonSx from "../../../components/base/ButtonSx";
import { validate } from "../../../components/store/Subscriptions/Direct/editDirectSubscriptionHelper";

export default function EditNewDirectSubscriptionPrices(props) {
  const [currentSubscription, setCurrentSubscription] = useState(null);

  useEffect(() => {
    const { subscription } = props;
    if (props.open) {
      setCurrentSubscription({
        product: subscription.product,
        startDate: subscription.startDate,
        pricingId: subscription.pricingId,
        fees: subscription.fees.map((f) => ({ ...f })),
        monthlyPrices: subscription.monthlyPrices.map((f) => ({ ...f })),
        price: _.sumBy(subscription.monthlyPrices, "price"),
        hasFees: subscription.hasFees,
        discountReason: subscription.discountReason,
        errors: { fees: [] },
      });
    } else {
      setCurrentSubscription(null);
    }
  }, [props.open, props.subscription]);

  const handleEditCompleted = () => {
    const { isValid, errors } = validate(currentSubscription);
    setCurrentSubscription({ ...currentSubscription, errors });

    if (isValid) {
      props.onComplete(currentSubscription);
    }
  };

  const handleFeeChange = (index) => (priceStr, price) => {
    setCurrentSubscription((prev) => {
      return {
        ...prev,
        fees: prev.fees.map((f, i) => {
          return index === i ? { ...f, price, priceStr } : { ...f };
        }),
      };
    });
  };

  const handleMonthlyPriceChange = (month, priceStr, price) => {
    setCurrentSubscription((prev) => {
      const monthlyPrices = prev.monthlyPrices.map((monthlyPrice) => {
        if (month.isSame(monthlyPrice.month)) {
          return { ...monthlyPrice, price, priceStr };
        }
        return { ...monthlyPrice };
      });

      return {
        ...prev,
        monthlyPrices,
        price: _.sumBy(monthlyPrices, "price"),
      };
    });
  };

  const handleSettingNewMonthlyPrice = (priceStr, price) => {
    setCurrentSubscription((prev) => {
      const monthlyPrices = prev.monthlyPrices.map((monthlyPrice) => {
        return { ...monthlyPrice, price, priceStr };
      });

      return {
        ...prev,
        monthlyPrices,
        price: _.sumBy(monthlyPrices, "price"),
      };
    });
  };

  const handleDiscountReasonChange = (updates) => {
    // nothing else should be changed. just in case...
    if (Object.prototype.hasOwnProperty.call(updates, "discountReason")) {
      setCurrentSubscription((prev) => ({
        ...prev,
        discountReason: updates.discountReason,
      }));
    }
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      title="עדכון עלויות"
      closeButton
      maxWidth="sm"
      fullWidth
    >
      <DialogContent dividers>
        {currentSubscription && (
          <EditDirectSubscriptionForm
            subscription={currentSubscription}
            onFieldsChanged={handleDiscountReasonChange}
            onPriceChanged={handleMonthlyPriceChange}
            onSettingNewPrice={handleSettingNewMonthlyPrice}
            onFeePriceChange={handleFeeChange}
            summary
            pricesOnly
          />
        )}
      </DialogContent>
      <DialogActions>
        <ButtonSx variant="outlined" onClick={props.onClose}>
          ביטול
        </ButtonSx>
        <ButtonSx variant="outlined" onClick={handleEditCompleted} debounce>
          אישור
        </ButtonSx>
      </DialogActions>
    </Dialog>
  );
}
