import React from "react";
import {
  Avatar,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemButton,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import PersonIcon from "@mui/icons-material/Person";

import TlPaper from "../../components/TlPaper";

function PlayersDetails(props) {
  const players = props.players || [];

  return (
    <TlPaper
      title="ספורטאים"
      isLoading={props.isLoading}
      titleBackground
      bodySx={{ p: 0, pr: 0, pl: 0 }}
    >
      <>
        <List disablePadding>
          {players.map((player) => (
            <ListItem key={player._id} disablePadding>
              <ListItemButton
                disablePadding
                component={RouterLink}
                to={`../players/${player._id}`}
              >
                <ListItemAvatar>
                  <Avatar>
                    <PersonIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={player.name} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </>
    </TlPaper>
  );
}

export default PlayersDetails;
