import React from "react";
import moment from "moment";
import { Alert, Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { connect } from "react-redux";
import ReactGA from "react-ga4";

import { displayDate, displayPrice } from "../../utils/helpers";
import axios from "../../utils/axios";
import { checkStatuses } from "../../utils/dictionaries";
import FiltersBar from "../../components/filters/FiltersBar";
import {
  prepareFilters,
  getFiltersFromUrl,
} from "../../components/filters/prepareFilters";
import Table from "../../components/table/Table";
import { exportTable } from "../../lib/tableHelpers";
import withRouter from "../../components/routing/withRouter";
import ReturnChecksDialog from "../../components/store/ReturnChecks/ReturnChecksDialog";
import ButtonSx from "../../components/base/ButtonSx";
import TlPaper from "../../components/TlPaper";

const filtersStructure = [
  { type: "date" },
  { type: "amount" },
  {
    name: "receiptNumber",
    type: "text",
    label: "מספר קבלה",
  },
  {
    name: "payerName",
    type: "text",
    label: "שם הלקוח",
  },
  {
    name: "checkNumber",
    type: "text",
    label: "מספר צ׳ק",
  },
  {
    name: "status",
    type: "select",
    options: {
      pending: "לא הופקד",
      deposited: "הופקד",
      cancelled: "מבוטל",
      refund: "החזר",
      bounced: "צ׳ק חוזר",
    },
    emptyLabel: "כל הסטטוסים",
    label: "סטטוס",
  },
];

class Checks extends React.Component {
  state = {
    checks: [],
    isLoading: true,
    isSaving: false,
    filters: {
      status: "all",
      checkNumber: "",
      payerName: "",
      amount: { from: "", to: "" },
      date: { from: moment().startOf("month"), to: moment().endOf("month") },
      receiptNumber: "",
    },
    currentCheckForReturn: null,
  };

  columns = [
    {
      id: "checkNumber",
      label: "מספר צ׳ק",
      minWidth: 150,
      padding: "checkbox",
    },
    { id: "date", label: "תאריך" },
    { id: "amount", label: "סכום" },
    { id: "payerName", label: "שם הלקוח" },
    { id: "receiptNumber", label: "קבלה" },
    { id: "status", label: "סטטוס" },
  ];

  componentDidMount = async () => {
    ReactGA.send({
      hitType: "pageview",
      page: "/payments/checks",
      title: "Checks Report",
    });

    if (this.props.user.permissions.registrationAdmin) {
      this.columns.push({ id: "actions", label: "פעולות", baseRenderer: true });
    }
    const filters = getFiltersFromUrl(
      this.props.location,
      this.state.filters,
      filtersStructure
    );
    await this.setState({ filters });
    this.loadData(false);
  };

  loadData = async (updateUrl = true) => {
    this.setState({ isLoading: true });
    const filters = prepareFilters(this.state.filters, filtersStructure);
    const res = await axios.get(`/payments/checks?${filters.join("&")}`);
    this.setState({ checks: res.data, isLoading: false });

    if (updateUrl) {
      this.props.navigate(`?${filters.join("&")}`, { replace: true });
    }
  };

  handleOpenReturnCheckDialog = (check) => () => {
    this.setState({ currentCheckForReturn: check });
  };

  renderCell = (columnId, check) => {
    switch (columnId) {
      case "checkNumber":
        return check.checkNumber;

      case "date":
        return displayDate(check.date);

      case "amount":
        return displayPrice(check.amount);

      case "status":
        return checkStatuses[check.status];

      case "payerName":
        return check.payer ? check.payer.name : null;

      case "receiptNumber":
        return (
          <Link
            component={RouterLink}
            color="primary"
            to={`../payments/transactions/${check.transactionId}`}
            underline="hover"
          >
            {check.receiptNumber}
          </Link>
        );

      case "actions":
        return check.status === "deposited" &&
          check.transactionStatus === "settled" ? (
          <ButtonSx
            sx={styles.returnCheckButton}
            onClick={this.handleOpenReturnCheckDialog(check)}
          >
            החזר צ׳ק
          </ButtonSx>
        ) : (
          <span />
        );

      default:
        return check[columnId];
    }
  };

  handleFilterChange = (filterName, value) => {
    this.setState({
      filters: {
        ...this.state.filters,
        [filterName]: value,
      },
    });
  };

  renderCellExport = (columnId, check) => {
    switch (columnId) {
      case "receiptNumber":
        return check.receiptNumber;

      case "amount":
        return check.amount;

      case "actions":
        return "";

      default:
        return this.renderCell(columnId, check);
    }
  };

  handleExportTable = () => {
    exportTable(
      this.state.checks,
      this.columns,
      this.renderCellExport,
      "csv",
      "צקים"
    );
  };

  render() {
    return (
      <TlPaper title="צ׳קים" fullPage titleBackground>
        <div>
          <FiltersBar
            filters={filtersStructure}
            onFilterChanged={this.handleFilterChange}
            values={this.state.filters}
            onSearch={this.loadData}
            isSearching={this.state.isLoading}
          />
        </div>

        <Alert severity="info">הפקדת צ׳קים מתבצעת במסך הפקדות</Alert>
        <Table
          title="צ׳קים"
          columns={this.columns}
          renderCell={this.renderCell}
          rows={this.state.checks}
          onExport={this.handleExportTable}
          isLoading={this.state.isLoading}
          defaultSortBy={1}
          defaultSortDirection="desc"
        />
        <ReturnChecksDialog
          open={this.state.currentCheckForReturn !== null}
          onClose={() => this.setState({ currentCheckForReturn: null })}
          onComplete={() => {
            this.setState({ currentCheckForReturn: null });
            this.loadData();
          }}
          transactionId={this.state.currentCheckForReturn?.transactionId}
          allowMerchandisePartialRefund
        />
      </TlPaper>
    );
  }
}

const styles = {
  returnCheckButton: {
    height: 20,
  },
};

function mapStateToProps(state) {
  return {
    user: state.auth.user,
  };
}

export default connect(mapStateToProps)(withRouter(Checks));
