import React, { useMemo } from "react";
import { TextField } from "@mui/material";
import moment from "moment";

import { maxPeriod, renderDateOptions } from "./subscriptionProductsHelper";

export default function SubscriptionProductEndDate(props) {
  const { subscriptionProduct, errors, onChange } = props;
  const { subscriptionType } = subscriptionProduct;

  const options = useMemo(() => {
    if (["season", "camp"].indexOf(subscriptionType) > -1) {
      let startDate;
      if (subscriptionProduct.startDate) {
        startDate = moment(subscriptionProduct.startDate).startOf("month");
      } else {
        startDate = moment().startOf("month");
      }
      return renderDateOptions(startDate, maxPeriod, "end");
    }
    return null;
  }, [subscriptionType, subscriptionProduct?.startDate]);

  const monthEndDate = useMemo(
    () =>
      subscriptionProduct?.endDate
        ? subscriptionProduct.endDate.format("YYYY-MM-DD")
        : "",
    [subscriptionProduct?.endDate]
  );

  const handleMonthDateChange = (e) => {
    onChange({ endDate: moment(e.target.value) });
  };

  switch (subscriptionType) {
    case "":
    case "season":
    case "camp":
      return (
        <TextField
          select
          fullWidth
          margin="dense"
          variant="standard"
          label="תאריך סיום"
          value={monthEndDate}
          disabled={subscriptionType === ""}
          error={errors?.endDate !== undefined}
          helperText={errors?.endDate || " "}
          onChange={handleMonthDateChange}
          inputProps={{ readOnly: !props.editable }}
        >
          {options}
        </TextField>
      );

    default:
      break;
  }
}
