import React from "react";
import { Tooltip, Fab, Box } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

const styles = {
  container: {
    position: "absolute",
    right: 10,
    bottom: 10,
  },
};

export default function EditFab(props) {
  return (
    <Box sx={styles.container}>
      <Tooltip title={props.tooltip}>
        <Fab
          color="primary"
          aria-label="Add"
          size="small"
          onClick={props.onClick}
        >
          <EditIcon />
        </Fab>
      </Tooltip>
    </Box>
  );
}
