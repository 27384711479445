import React, { useState, useEffect } from "react";
import {
  TextField,
  CircularProgress,
  ListItem,
  ListItemText,
  Divider,
  Box,
  SpeedDial,
  SpeedDialIcon,
  SpeedDialAction,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import SettingsIcon from "@mui/icons-material/Settings";

import ButtonSx from "../../../components/base/ButtonSx";

export default function CalendarResource(props) {
  const [tempName, setTempName] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const { resource } = props;

  useEffect(() => {
    if (!isEditing && resource._id === "new") {
      if (resource.parentObject) {
        setTempName(
          `${resource.parentObject.name} ${resource.parentObject.subResources.length}`
        );
      }
      setIsEditing(true);
    }
  }, [resource]);

  const onStartEditing = () => {
    props.onStartEditing();
    setTempName(resource.name);
    setIsEditing(true);
  };

  const onCancelEditing = () => {
    props.onCancelEditing();
    setTempName("");
    setIsEditing(false);
  };

  const handleSave = async () => {
    if (await props.onCompleteEditing({ ...resource, name: tempName })) {
      setTempName("");
      setIsEditing(false);
    }
  };

  const renderPrimary = () => {
    if (!isEditing) {
      return <div>{resource.name}</div>;
    }

    return (
      <TextField
        variant="standard"
        value={tempName}
        onChange={(e) => setTempName(e.target.value)}
        placeholder="שם המגרש"
        error={props.error !== null}
        helperText={props.error}
        style={{ width: 250 }}
        autoFocus
      />
    );
  };

  const disabled = !isEditing && props.disabled;

  const renderResource = () => (
    <ListItem sx={{ height: 50 }} {...props.listItemProps}>
      <Divider
        orientation="vertical"
        sx={{ ...styles.divider, ml: resource.parent ? 3 : 0 }}
      />
      <ListItemText>{renderPrimary()}</ListItemText>
      {props.isSaving && <CircularProgress />}
      {!props.isSaving && isEditing && (
        <div>
          <ButtonSx
            variant="outlined"
            onClick={onCancelEditing}
            disabled={disabled}
            sx={{ ...styles.button, mr: 1 }}
          >
            ביטול
          </ButtonSx>
          <ButtonSx
            variant="outlined"
            onClick={handleSave}
            disabled={disabled}
            sx={styles.button}
          >
            שמירה
          </ButtonSx>
        </div>
      )}
    </ListItem>
  );

  const renderSubResources = () => {
    return resource.subResources.map((sr, i) => (
      <CalendarResource
        key={sr._id}
        {...props}
        resource={sr}
        subResources={[]}
        index={props.index + i + 1}
      />
    ));
  };

  const actions = [
    {
      icon: <EditIcon />,
      name: "עריכה",
      onClick: onStartEditing,
    },
  ];

  if (resource.subResources?.length === 0) {
    actions.push({
      icon: <DeleteIcon />,
      name: "מחיקה",
      onClick: props.onDelete(resource),
    });
  }

  if (!resource.parent) {
    actions.push({
      icon: <AddIcon />,
      name: "הוספת תת-מגרש",
      onClick: () => props.onAddNewSubResource(resource),
    });
  }

  const speedDial = (
    <SpeedDial
      ariaLabel="SpeedDial basic example"
      sx={styles.speedDial}
      icon={<SpeedDialIcon icon={<SettingsIcon />} size="small" />}
      direction="left"
      FabProps={{ size: "small", disabled: props.disabled && !isEditing }}
    >
      {actions.map((action) => (
        <SpeedDialAction
          key={action.name}
          icon={action.icon}
          tooltipTitle={action.name}
          FabProps={{ onClick: action.onClick }}
        />
      ))}
    </SpeedDial>
  );

  if (resource.subResources.length > 0) {
    return (
      <Box sx={styles.container}>
        {!isEditing && speedDial}
        {renderResource()}
        {[...renderSubResources()]}
      </Box>
    );
  }

  return (
    <Box sx={resource.parent ? { position: "relative" } : styles.container}>
      {!isEditing && speedDial}
      {renderResource()}
    </Box>
  );
}

const styles = {
  container: {
    border: "1px solid #DDD",
    borderRadius: 1,
    position: "relative",
  },
  button: {
    minWidth: 80,
  },
  divider: {
    mr: 2,
    border: "2px solid #247BA0",
    borderRadius: 2,
    height: 36,
    my: "2px",
    opacity: 0.5,
  },
  speedDial: {
    position: "absolute",
    right: 5,
    top: -2,
  },
};
