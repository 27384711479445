import { Cloudinary } from "@cloudinary/url-gen";
import { scale, thumbnail } from "@cloudinary/url-gen/actions/resize";

export const cloudinary = new Cloudinary({
  cloud: { cloudName: "dmixnaxp6" },
});

export const cloudinaryImage = (publicId, options = {}) => {
  const img = cloudinary.image(publicId);
  if (options.version) {
    img.setVersion(options.version);
  }

  switch (options.size) {
    case "original":
      break;

    case "custom":
      img.resize(scale().width(options.width).height(options.height));
      break;

    default:
      img.resize(thumbnail().width(150).height(150));
  }

  return img;
};
