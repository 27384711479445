import React from "react";
import { Link, Grid, Fab, Checkbox, Stack, Box } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import moment from "moment";
import { Link as RouterLink } from "react-router-dom";
import InfoIcon from "@mui/icons-material/InfoOutlined";

import axios from "../../../utils/axios";
import InfoRow from "../../InfoRows/InfoRow";
import StandalonePaymentForm from "../StandalonePaymentForm";
import RefundDialog from "../Refund/RefundDialog";
import VoidDialog from "../VoidDialog";
import CancelSubscriptionDialog from "./CancelSubscription/CancelSubscriptionDialog";
import { displayPrice } from "../../../utils/helpers";
import {
  transactionKinds,
  transactionStatuses,
} from "../../../utils/dictionaries";
import RemoveTrialPlayerFromTeamDialog from "../../players/RemoveTrialPlayerFromTeamDialog";
import EditDirectSubscriptionDialog from "./Direct/EditDirectSubscriptionDialog";
import DeleteDebtDialog from "./DeleteDebtDialog";
import Table from "../../table/Table";
import SubscriptionHeader from "./Direct/SubscriptionHeader";
import DiscountRequestDialog from "../../../containers/Subscriptions/DiscountRequestDialog";
import ChangeTeamDialog from "./Direct/ChangeTeam/ChangeTeamDialog";
import ButtonSx from "../../base/ButtonSx";
import SubscriptionCosts from "./SubscriptionCosts";
import ResponsiveContainer from "../../ResponsiveContainer";
import SubscriptionDialog from "./SubscriptionDialog";
import TlPaper from "../../TlPaper";
import TooltipSx from "../../base/TooltipSx";

const styles = {
  actionButton: {
    margin: "5px",
    width: {
      xs: 120,
      md: 150,
    },
  },
};

export default class DirectSubscription extends React.Component {
  state = {
    isPaymentDialogOpen: false,
    isVoidDialogOpen: false,
    isRefundDialogOpen: false,
    isCancelDialogOpen: false,
    isEditDialogOpen: false,
    isChangeTeamDialogOpen: false,
    isDeleteDebtDialogOpen: false,
    isRemoveFromTeamDialogOpen: false,
    partialTransaction: false,
    isDiscountDialogOpen: false,
    isCancellingOtherPaymentMethodRequest: false,
  };

  componentDidMount = () => {
    if (this.props.currentSubscriptionOpenEdit) {
      this.setState({ isEditDialogOpen: true });
    }
  };

  componentDidUpdate = async (prevProps) => {
    if (this.props.transactions?.length > 0 && !prevProps.transactions) {
      this.prepareData();
    }
  };

  handleDeleteDebt = async (deleteDebt) => {
    if (deleteDebt) {
      await axios.patch(
        `/store/subscriptions/${this.props.subscription._id}/deleteDebt`
      );
      this.props.onComplete({
        status: "success",
        message: "החוב נוכה בהצלחה",
        subscription: this.props.subscription,
      });
    }

    this.setState({
      isDeleteDebtDialogOpen: false,
    });
  };

  prepareData = () => {
    let partialTransaction = false;
    for (let index = 0; index < this.props.transactions.length; index++) {
      const transaction = this.props.transactions[index];

      const subscriptionApplication =
        this.props.subscription.payments.find(
          (p) => p.payment._id === transaction.payment._id
        ) || {};

      if (
        // transaction include payment for more things than this subscription
        subscriptionApplication.applicableAmount -
          subscriptionApplication.refundMergedAmount !==
        transaction.amount
      ) {
        partialTransaction = true;
        break;
      }
    }

    this.setState({ partialTransaction });
  };

  showRefundDialog = (transaction) => {
    this.setState({
      refund: { transaction },
      isRefundDialogOpen: true,
    });
  };

  showVoidDialog = (transaction) => {
    this.setState({
      void: { transaction },
      isVoidDialogOpen: true,
    });
  };

  columns = [
    { id: "date", label: "תאריך", width: 140 },
    { id: "amount", label: "סכום" },
    { id: "status", label: "סטטוס" },
    { id: "paymentMethod", label: "אמצעי תשלום" },
    { id: "receiptNumber", label: "מס׳ קבלה" },
  ];

  mobileColumns = [
    { id: "date", label: "תאריך", width: 140 },
    { id: "amount", label: "סכום" },
    { id: "receiptNumber", label: "קבלה" },
  ];

  renderCell = (columnId, transaction) => {
    let subscriptionApplication, amount, val, paymentForOtherProducts;
    switch (columnId) {
      case "date":
        return moment(transaction.createdAt).format("DD/MM/YY LT");

      case "amount":
        subscriptionApplication =
          this.props.subscription.payments.find(
            (p) => p.payment._id === transaction.payment._id
          ) || {};
        //|| transaction.amount - can only happen for declined payments
        amount =
          "applicableAmount" in subscriptionApplication
            ? subscriptionApplication.applicableAmount -
              subscriptionApplication.refundMergedAmount
            : transaction.amount;
        val = displayPrice(amount);
        paymentForOtherProducts = amount !== transaction.amount;

        return ["cancelled", "declined"].indexOf(transaction.status) > -1 ? (
          <s>
            {paymentForOtherProducts ? "*" : ""}
            {val}
          </s>
        ) : (
          `${paymentForOtherProducts ? "*" : ""}${val}`
        );

      case "status":
        return <strong>{transactionStatuses[transaction.status]}</strong>;

      case "paymentMethod":
        return transactionKinds[transaction.kind];

      case "comment":
        return transaction.payment.comment;

      case "receiptNumber":
        return (
          <Link
            component={RouterLink}
            color="primary"
            to={`../payments/transactions/${transaction._id}`}
            underline="hover"
          >
            {transaction.receiptNumber}
          </Link>
        );

      case "actions":
        switch (transaction.status) {
          case "approved":
            return transaction.amount > 0 ? (
              <ButtonSx
                margin="dense"
                onClick={() => this.showVoidDialog(transaction)}
              >
                ביטול
              </ButtonSx>
            ) : null;

          case "settled":
            return transaction.amount > 0 &&
              transaction.payment.refundableAmount > 0 ? (
              <ButtonSx
                margin="dense"
                onClick={() => this.showRefundDialog(transaction)}
              >
                החזר
              </ButtonSx>
            ) : null;

          default:
            break;
        }
        break;

      default:
        return transaction[columnId];
    }
  };

  renderPayments = () => {
    const params = {
      noHeader: true,
      columns: this.columns,
      renderCell: this.renderCell,
      rows: this.props.transactions,
      isLoading: this.props.isLoading,
      sort: false,
    };

    return (
      <TlPaper
        noMinHeight
        title="תשלומים"
        titleBackground
        bodySx={{ pl: 0, pr: 0, p: 0, m: 0 }}
        collapsible
      >
        <ResponsiveContainer
          desktopContent={<Table {...params} />}
          mobileContent={<Table {...params} columns={this.mobileColumns} />}
        />
        {this.props.transactions.length === 0 && (
          <div style={{ marginRight: 5, marginBottom: 5 }}>
            לא בוצעו תשלומים עבור רישום זה
          </div>
        )}
        {this.state.partialTransaction && (
          <Box sx={{ m: 1 }}>
            * עסקה זו כוללת תשלום על מוצרים נוספים. הסכום הנ״ל שולם לטובת
            הפעילות במסגרת זו בלבד
          </Box>
        )}
      </TlPaper>
    );
  };

  renderUnregistered = () => (
    <>
      <InfoRow
        title="שיעורי ניסיון"
        value={this.props.player.trialInfo.attendances}
      />
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ flex: 1 }}
      >
        {this.props.subscription.team && (
          <>
            <ButtonSx
              margin="dense"
              onClick={() =>
                this.setState({ isRemoveFromTeamDialogOpen: true })
              }
              sx={{ ml: "15px" }}
            >
              הסרה מהקבוצה
            </ButtonSx>
            <RemoveTrialPlayerFromTeamDialog
              player={this.props.player}
              team={this.props.subscription.team}
              open={this.state.isRemoveFromTeamDialogOpen}
              onClose={() =>
                this.setState({ isRemoveFromTeamDialogOpen: false })
              }
              onComplete={() => {
                this.props.onComplete(null, true);
                this.setState({ isRemoveFromTeamDialogOpen: false });
              }}
            />
          </>
        )}
      </Stack>
    </>
  );

  handleCloseOtherPaymentMethodRequest = async () => {
    this.setState({ isCancellingOtherPaymentMethodRequest: true });
    await axios.post(
      `/store/subscriptions/${this.props.subscription._id}/closeSpecialRequest`,
      { specialRequestType: "otherPaymentMethod" }
    );
    this.setState({ isCancellingOtherPaymentMethodRequest: true });
    this.props.onComplete();
  };

  renderBottomActions = () => {
    const { subscription, user } = this.props;

    if (!user.permissions.registrationAdmin) {
      return null;
    }

    return (
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ flex: 1 }}
      >
        <div>
          {subscription.status === "active" && (
            <ButtonSx
              variant="outlined"
              sx={styles.actionButton}
              onClick={() => this.setState({ isCancelDialogOpen: true })}
            >
              סיום פעילות
            </ButtonSx>
          )}

          {subscription.status === "pendingOtherPaymentMethod" && (
            <ButtonSx
              sx={styles.actionButton}
              onClick={this.handleCloseOtherPaymentMethodRequest}
              isLoading={this.state.isCancellingOtherPaymentMethodRequest}
              debounce
            >
              ביטול הבקשה
            </ButtonSx>
          )}

          <TooltipSx
            title={
              this.props.subscription.team.status === "archived"
                ? "לא ניתן לשלם עבור קבוצה בססטוס ארכיון"
                : ""
            }
            arrow
            placement="left"
          >
            {/* the span is required here because the button is disabled */}
            <span>
              <ButtonSx
                variant="outlined"
                sx={styles.actionButton}
                onClick={() => this.setState({ isPaymentDialogOpen: true })}
                disabled={this.props.subscription.team.status === "archived"}
              >
                תשלום
              </ButtonSx>
            </span>
          </TooltipSx>
        </div>
        {(subscription.status === "active" ||
          subscription.status === "pendingOtherPaymentMethod") && (
          <>
            <Fab
              size="small"
              color="primary"
              onClick={() => this.setState({ isEditDialogOpen: true })}
            >
              <EditIcon />
            </Fab>
            <EditDirectSubscriptionDialog
              isOpen={this.state.isEditDialogOpen}
              onClose={(switchToChangeTeam = false) => {
                this.setState({
                  isEditDialogOpen: false,
                  isChangeTeamDialogOpen: switchToChangeTeam,
                });
              }}
              onComplete={() => {
                this.setState({ isEditDialogOpen: false });
                this.props.onComplete(null, true);
              }}
              subscription={subscription}
            />

            <ChangeTeamDialog
              isOpen={this.state.isChangeTeamDialogOpen}
              onClose={() => this.setState({ isChangeTeamDialogOpen: false })}
              onComplete={() => {
                this.setState({ isChangeTeamDialogOpen: false });
                // snackDetails, reloadUser (need to update clubPlayer.activeTeams in order to prevent double registration)
                this.props.onComplete(
                  {
                    status: "success",
                    message: "מעבר הקבוצה בוצע בהצלחה",
                    subscription: this.props.subscription,
                  },
                  true
                );
              }}
              subscription={subscription}
              clubPlayer={this.props.player}
            />
          </>
        )}

        {subscription.status === "pendingDiscount" && (
          <>
            <ButtonSx
              variant="outlined"
              sx={{ margin: "5px", width: 155 }}
              onClick={() => this.setState({ isDiscountDialogOpen: true })}
            >
              אישור/דחיית הבקשה
            </ButtonSx>
            <DiscountRequestDialog
              subscription={this.props.subscription}
              clubPlayer={this.props.player}
              open={this.state.isDiscountDialogOpen}
              onClose={() => this.setState({ isDiscountDialogOpen: false })}
              onComplete={() => {
                this.props.onComplete({
                  status: "success",
                  message: "הבקשה עודכנה בהצלחה",
                  subscription: this.props.subscription,
                });
              }}
            />
          </>
        )}

        {/* if there are no balances debt will fail */}
        {(subscription.status === "cancelled" ||
          subscription.status === "completed") &&
          subscription.debt !== 0 &&
          subscription.balance.length > 0 && (
            <>
              <ButtonSx
                variant="outlined"
                sx={styles.actionButton}
                onClick={() => this.setState({ isDeleteDebtDialogOpen: true })}
              >
                מחיקת חוב
              </ButtonSx>

              <DeleteDebtDialog
                subscription={subscription}
                onClose={this.handleDeleteDebt}
                open={this.state.isDeleteDebtDialogOpen}
              />
            </>
          )}
      </Stack>
    );
  };

  renderRegistered = () => {
    const { subscription } = this.props;
    return (
      <>
        <CancelSubscriptionDialog
          open={this.state.isCancelDialogOpen}
          onClose={() => this.setState({ isCancelDialogOpen: false })}
          onComplete={() => {
            this.props.onComplete();
            this.setState({
              isCancelDialogOpen: false,
            });
          }}
          subscription={subscription}
          transactions={this.props.transactions}
          direct
        />
        <VoidDialog
          open={this.state.isVoidDialogOpen}
          onClose={() => this.setState({ isVoidDialogOpen: false })}
          onComplete={() => {
            this.props.onComplete();
            this.setState({ isVoidDialogOpen: false });
          }}
          subscriptionId={subscription._id}
          transaction={this.state.void ? this.state.void.transaction : null}
        />

        <RefundDialog
          open={this.state.isRefundDialogOpen}
          onClose={() => this.setState({ isRefundDialogOpen: false })}
          onComplete={() => {
            this.props.onComplete();
            this.setState({ isRefundDialogOpen: false });
          }}
          transaction={this.state.refund ? this.state.refund.transaction : null}
        />

        <StandalonePaymentForm
          subscription={subscription}
          player={this.props.player}
          open={this.state.isPaymentDialogOpen}
          onClose={() => {
            this.setState({ isPaymentDialogOpen: false });
          }}
          onComplete={() => {
            this.setState({ isPaymentDialogOpen: false });
            this.props.onComplete();
          }}
        />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <SubscriptionHeader subscription={subscription} />
          </Grid>

          {this.props.clubConfig.general.autoDebtAlert &&
            subscription.status !== "completed" &&
            subscription.status !== "cancelled" && (
              <Grid item xs={12}>
                <Stack direction="row" alignItems="center">
                  <Checkbox
                    checked={subscription.autoDebtAlert}
                    onChange={this.props.onToggleAutoDebtAlert}
                  />
                  <TooltipSx
                    title="במידה וישנה יתרת חוב (מעל 1 ש״ח) תופיע התראת חוב באפליקציית המאמנים"
                    arrow
                    sx={{ maxWidth: "none" }}
                  >
                    <Stack direction="row" alignItems="center">
                      התראת חוב אוטומטית למאמן
                      <InfoIcon style={{ marginRight: 5 }} />
                    </Stack>
                  </TooltipSx>
                </Stack>
              </Grid>
            )}
          <Grid item xs={12}>
            {this.renderPayments()}
          </Grid>
          <Grid item xs={12}>
            <SubscriptionCosts subscription={subscription} />
          </Grid>
        </Grid>
      </>
    );
  };

  render() {
    const { subscription } = this.props;
    const title = `${subscription?.team.name}${
      subscription.pricing?.title ? ` - ${subscription.pricing?.title}` : ""
    }`;
    return (
      <SubscriptionDialog
        open={this.props.open}
        onClose={this.props.onClose}
        isLoading={this.props.isLoading}
        title={title}
        content={
          this.props.isLoading ? (
            <span />
          ) : this.props.subscription && !this.props.subscription.trial ? (
            this.renderRegistered()
          ) : (
            this.renderUnregistered()
          )
        }
        actions={
          this.props.isLoading ? (
            <span />
          ) : this.props.subscription && !this.props.subscription.trial ? (
            this.renderBottomActions()
          ) : null
        }
      />
    );
  }
}
